import * as types from '../actionTypes';

const initialState = { 
	buttonLoading: false,
}
 
export const profileReducer = (state = initialState, action) => {
		
    switch (action.type) {			
        case types.BUTTON_LOADING:
            return { ...state, buttonLoading: action.payload }	
        default:
            return state
    }
}

