import './MainAbout.css';
import React,{useState,useEffect,Fragment} from 'react';
import {Link,useNavigate} from 'react-router-dom';
import { CSSTransition } from "react-transition-group";

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

import { Box,Backdrop,CircularProgress,Button } from '@mui/material';

import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from '@mui/icons-material/Warning';

import {useComponentWillMount} from '../../Utils/customFunctions';
import Header from '../../Components/Layout/Header';
import If from '../../Components/Common/If';

import logo from '../../Assets/Images/logoTypeWOslogan.svg'; // Tell Webpack this JS file uses this image
import backgroundHexagon from '../../Assets/Images/backgroundHexagon.png'; // Tell Webpack this JS file uses this image

// Load Actions
import * as AppActions from '../../Store/Actions/App'

const About = props => {



const screenStyle = {
  alignItems: "center",
  justifyContent: "top",
  width:"100vw",
  minHeight:"100vh",
  display:"flex",
  flexDirection:"column",
}; 

const screenStyleLoading = {
  alignItems: "middle",
  justifyContent: "center",
  width:"100vw",
  height:"100vh",
  display: "flex",
  flexDirection: "column"
}; 

const screenStyleDialog = {
  alignItems: "center",
  justifyContent: "top",
  width:"100vw",
  display:"flex",
  flexDirection:"column",
  backgroundColor:"#FFFFFF"
}; 


// set vars from props
// Remember to add those who come from Redux
const { 
	innerLoading,
	setInnerLoading
} = props;

// set Use of history for PUSH 
let navigate = useNavigate();

// Set internal state
const [contentVisible,setContentState] = useState(false);
const [loadingMessage,setLoadingMessage] = useState("Carregando...");
const [loading,setLoading] = useState(true);

// Simulate states
const registerMobile = false;
const registerEmail = true;
const activationDone = false;
  
// Run after mount component
useEffect(() => {
	
  console.log("Montou o componente MainHome....");
  
  // Set Backdrop Loading over interface
  //setInnerLoading(true); 
   
  // Show content after load for animation
 // setContentState(true);
 
 // Set Content Loading ON
 //setInnerLoading(true);
 //setContentState(true);
 
 // Simulate Delay
 setTimeout(function() {
	 
	  // Set Content Loading OFF
	  setLoading(false);
	  
	  // Set Content Ready to show
	  setContentState(true);
	
 },2000);

  // set scroll to top
  window.scrollTo(0, 1);
    
  // Runs when UNMOUNT component
  return () => {
    console.log("Desmontou o componente MainHome...");
  };
  
}, []); // end useEffect


// Will Mount Component HOOK
useComponentWillMount(() => {
	
    // Set session of app
  var sessionCheck = sessionStorage.getItem('@motuum/appLaunched');
  
  // if session is not created, redirect to bootstrap. (avoid direct access using url)
  if (!sessionCheck) {
	  // eslint-disable-next-line no-restricted-globals
	  navigate("/");
  } // end if	
      
}, []); // end useComponentWillMount

const handleClose = () => {
	setInnerLoading(false);
};

	return (
	<Fragment>
			
			<Header title="Sobre o App Motuum" buttons={{support:false,menu:false,back:true,close:false,rightFake:true}} />
			
			{/* Inner Loading */}
				{loading &&
					<Box className="loadingContent" style={{...screenStyleLoading}}>
							<Box mt={0} mx={0}>								
								<CircularProgress /> 
								<div> Carregando...Aguarde!</div>								
							</Box>	
					</Box>
				 }	

			
			<CSSTransition in={contentVisible} timeout={1000} classNames="fade"	unmountOnExit>	
			
				<Box className="mainHome" style={{...screenStyle}} m={0}>

						<Box style={{alignItems:"center",display:"flex",flexDirection:"column"}} mt={13} ml={3} mr={3}>		
						
							<Box mt={1} mb={1} style={{textAlign:"center",lineHeight:"38px"}}>						
								<div style={{fontFamily:"Roboto",fontWeight:"100",fontSize:"42px",color:"#003CFA"}}>
									  SOBRE A<br /> MOTUUM
								</div>								 	
							</Box>		
							
							<Box p={2} pt={0} >

								
										<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Qui convenit? Est, ut dicis, inquam. Atqui eorum nihil est eius generis, ut sit in fine atque extrerno bonorum. Ut scias me intellegere, primum idem esse dico voluptatem, quod ille don. Et quod est munus, quod opus sapientiae? Duo Reges: constructio interrete. Quis istud possit, inquit, negare? In qua quid est boni praeter summam voluptatem, et eam sempiternam? </p>

										<p>Equidem etiam Epicurum, in physicis quidem, Democriteum puto. Callipho ad virtutem nihil adiunxit nisi voluptatem, Diodorus vacuitatem doloris. Ut scias me intellegere, primum idem esse dico voluptatem, quod ille don. Universa enim illorum ratione cum tota vestra confligendum puto. Illis videtur, qui illud non dubitant bonum dicere -; Quippe: habes enim a rhetoribus; <mark>In schola desinis.</mark> </p>

										<ul>
											<li>Et non ex maxima parte de tota iudicabis?</li>
											<li>Et hi quidem ita non sola virtute finem bonorum contineri putant, ut rebus tamen omnibus virtutem anteponant;</li>
											<li>Qua tu etiam inprudens utebare non numquam.</li>
											<li>Comprehensum, quod cognitum non habet?</li>
											<li>Sed ad bona praeterita redeamus.</li>
											<li>Ut alios omittam, hunc appello, quem ille unum secutus est.</li>
										</ul>


										<ol>
											<li>Quamquam non negatis nos intellegere quid sit voluptas, sed quid ille dicat.</li>
											<li>Sed tamen omne, quod de re bona dilucide dicitur, mihi praeclare dici videtur.</li>
											<li>Cur, nisi quod turpis oratio est?</li>
											<li>Nosti, credo, illud: Nemo pius est, qui pietatem-;</li>
											<li>Quarum ambarum rerum cum medicinam pollicetur, luxuriae licentiam pollicetur.</li>
										</ol>


										<p>Si enim non fuit eorum iudicii, nihilo magis hoc non addito illud est iudicatum-. Quis istud possit, inquit, negare? Hoc est non modo cor non habere, sed ne palatum quidem. Quis tibi ergo istud dabit praeter Pyrrhonem, Aristonem eorumve similes, quos tu non probas? Quod quidem nobis non saepe contingit. Facile est hoc cernere in primis puerorum aetatulis. Nam, ut sint illa vendibiliora, haec uberiora certe sunt. Etsi ea quidem, quae adhuc dixisti, quamvis ad aetatem recte isto modo dicerentur. Quid enim de amicitia statueris utilitatis causa expetenda vides. Sit enim idem caecus, debilis. </p>

										<h1>Um plataforma que vai resolver os seus problemas de TI</h1>

										<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Qui convenit? Est, ut dicis, inquam. Atqui eorum nihil est eius generis, ut sit in fine atque extrerno bonorum. Ut scias me intellegere, primum idem esse dico voluptatem, quod ille don. Et quod est munus, quod opus sapientiae? Duo Reges: constructio interrete. Quis istud possit, inquit, negare? In qua quid est boni praeter summam voluptatem, et eam sempiternam? </p>

										<p>Equidem etiam Epicurum, in physicis quidem, Democriteum puto. Callipho ad virtutem nihil adiunxit nisi voluptatem, Diodorus vacuitatem doloris. Ut scias me intellegere, primum idem esse dico voluptatem, quod ille don. Universa enim illorum ratione cum tota vestra confligendum puto. Illis videtur, qui illud non dubitant bonum dicere -; Quippe: habes enim a rhetoribus; <mark>In schola desinis.</mark> </p>

										<ul>
											<li>Et non ex maxima parte de tota iudicabis?</li>
											<li>Et hi quidem ita non sola virtute finem bonorum contineri putant, ut rebus tamen omnibus virtutem anteponant;</li>
											<li>Qua tu etiam inprudens utebare non numquam.</li>
											<li>Comprehensum, quod cognitum non habet?</li>
											<li>Sed ad bona praeterita redeamus.</li>
											<li>Ut alios omittam, hunc appello, quem ille unum secutus est.</li>
										</ul>


										<ol>
											<li>Quamquam non negatis nos intellegere quid sit voluptas, sed quid ille dicat.</li>
											<li>Sed tamen omne, quod de re bona dilucide dicitur, mihi praeclare dici videtur.</li>
											<li>Cur, nisi quod turpis oratio est?</li>
											<li>Nosti, credo, illud: Nemo pius est, qui pietatem-;</li>
											<li>Quarum ambarum rerum cum medicinam pollicetur, luxuriae licentiam pollicetur.</li>
										</ol>

										<p>Si enim non fuit eorum iudicii, nihilo magis hoc non addito illud est iudicatum-. Quis istud possit, inquit, negare? Hoc est non modo cor non habere, sed ne palatum quidem. Quis tibi ergo istud dabit praeter Pyrrhonem, Aristonem eorumve similes, quos tu non probas? Quod quidem nobis non saepe contingit. Facile est hoc cernere in primis puerorum aetatulis. Nam, ut sint illa vendibiliora, haec uberiora certe sunt. Etsi ea quidem, quae adhuc dixisti, quamvis ad aetatem recte isto modo dicerentur. Quid enim de amicitia statueris utilitatis causa expetenda vides. Sit enim idem caecus, debilis. </p>
					
								
							</Box>
							
						</Box>
														
				</Box>
			
			</CSSTransition> 			
			
				
	</Fragment>
)};

const mapStateToProps = store => ({
  innerLoading:store.appReducer.innerLoading
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AppActions }, dispatch);

export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(About);
 
