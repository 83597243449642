import React,{useState,useEffect,Fragment} from 'react';

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

import { Box,Divider,Typography,Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';

import Info from '@mui/icons-material/ErrorOutline';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AccountTreeIcon from '@mui/icons-material/AccountTree'; 
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ScheduleIcon from '@mui/icons-material/Schedule';
import WarningIcon from '@mui/icons-material/Warning';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// Load Actions
import * as AppActions from '../../Store/Actions/App'

const mapStateToProps = store => ({
  innerLoading:store.appReducer.innerLoading,
  dialog:store.appReducer.dialog
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AppActions }, dispatch);
    
// Transition Slide for Dialog SLIDE UP	
const TransitionSlideUp = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;  
});	
	

/* Extra  Dialog Component START */
export const AddressDetailDialog = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {
		

const screenStyleDialog = {
  alignItems: "stretch",
  justifyContent: "top",
  width:"100vw",
  minHeight:"100vh",
  display:"flex",
  flexDirection:"column",
  backgroundColor:"#FFF"
}; 
	
// set classes var
// const classes = useStyles()();	

const { 
	innerLoading,
	setInnerLoading,
	setDialog,
	SelectedOngoingServiceOrder,
	dialog
} = props;	

const [address,setAddress] = useState("");

var formatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});



// Run after mount component
useEffect(() => {
	
	// Set address for STATIC MAP dinamically
	//let addressText = SelectedOngoingServiceOrder.ServiceOrderAddress.fullAddress;
	//let finalAddress = "https://maps.googleapis.com/maps/api/staticmap?center="+addressText+"&zoom=16&size=600x600&maptype=roadmap&markers=color:blue%7Clabel:S%7C40.702147,-74.015794&markers=color:green%7Clabel:G%7C40.711614,-74.012318&markers=color:red%7Clabel:C%7C40.718217,-73.998284&key=AIzaSyBonsqBhtULopV9-9yST-FkUrTYPFLCsLE";

	var addressText = SelectedOngoingServiceOrder.ServiceOrderAddress.streetType+" "+SelectedOngoingServiceOrder.ServiceOrderAddress.streetName+", "+SelectedOngoingServiceOrder.ServiceOrderAddress.streetNumber+", "+SelectedOngoingServiceOrder.ServiceOrderAddress.suburb+", "+SelectedOngoingServiceOrder.ServiceOrderAddress.city+", "+SelectedOngoingServiceOrder.ServiceOrderAddress.state+", Brazil";
	
	var finalAddress = "https://maps.googleapis.com/maps/api/staticmap?center="+addressText+"&zoom=16&size=600x600&maptype=roadmap&key=AIzaSyBonsqBhtULopV9-9yST-FkUrTYPFLCsLE";
 
	setAddress(finalAddress);

	
}, []); // end useEffect



	return (
	<div>
		  <Dialog fullScreen open={dialog.target == 'addressDetail' && dialog.status} onClose={()=>setDialog('addressDetail',false)} TransitionComponent={TransitionSlideUp}>
			<AppBar style={{position: 'fixed'}}>
			  <Toolbar>
				<IconButton edge="start" color="inherit" onClick={() => setDialog('addressDetail',false)} aria-label="close">
				  <CloseIcon />
				</IconButton>
				<Typography variant="h6" style={{marginLeft: "10px",flex: 1}}>
				  Endereço aproximado do Local
				</Typography>
				</Toolbar>
			</AppBar>

			<Box style={{...screenStyleDialog}} m={0}>
			
				<Box mt={8} ml={0} mr={0}>

							<Box mt={2} mb={2} style={{textAlign:"center",alignItems:"stretch",justifyContent:"center",display:"flex",flexDirection:"column"}}>
							
								<img src={address} style={{width:"100%",height:"auto",backgroundColor:"whitesmoke"}} />
									
							</Box>
			
					<Divider style={{marginBottom:"5px"}} />
						
					<Box style={{display:"flex",flexDirection:"row",justifyContent:"center"}} ml={1} mr={1} mb={5} mt={2}>						
						
						<Button color="secondary" variant="outlined" style={{flex:1,height:"48px",marginRight:"10px"}} onClick={()=>setDialog('addressDetail',false)}>
						 Fechar
						</Button>		
													
					</Box>			
			
				</Box>				
			
			</Box>

		  </Dialog>
		</div>
		
	);
	
});



