import * as types from '../actionTypes';

const initialState = { 
	buttonLoading: false,
	loggedIn:false,
	loggedOff:false
}
 
export const loginReducer = (state = initialState, action) => {
		
    switch (action.type) {
		case types.SET_LOGIN:
			return { ...state,loggedIn: action.payload }	
		case types.SET_LOGOFF:
			return { ...state,loggedOff: action.payload }				
        case types.BUTTON_LOADING:
            return { ...state, buttonLoading: action.payload }	
        default:
            return state
    }
}

