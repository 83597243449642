import * as types from '../actionTypes';

const initialState = { 
	leftDrawer: false,
	innerLoading: false,
	dialog:{},
	userData:{},
	chatData:{},
	scheduledInfo:{},
	termsContent:null,
	CustomerCancelTax:null,
	CustomerAlterTax:null,
	chatChannel:null,
	chatChannelId:null,
	chatCustomerId:null,
	chatProviderId:null
}

 
export const appReducer = (state = initialState, action) => {
		
    switch (action.type) {
        case types.INNER_LOADING:
            return { ...state, innerLoading: action.payload }	
        case types.SET_DIALOG:
            return { ...state, dialog: action.payload }	
        case types.TOGGLE_DRAWER:
		    return { ...state, leftDrawer: action.payload }	
		case types.SET_USERDATA:
			return { ...state, userData: action.payload }	
		case types.SET_SCHEDULE:
			return { ...state, scheduledInfo: action.payload }	
		case types.SET_TERMSCONTENT:
			return { ...state, termsContent: action.payload }
		case types.SET_CUSTOMER_CANCELTAX:
			return { ...state, CustomerCancelTax: action.payload }	
		case types.SET_CUSTOMER_ALTERTAX:
			return { ...state, CustomerAlterTax: action.payload }
		case types.SET_CHAT_DATA:
			return { ...state, chatData: action.payload }	
		case types.SET_CHAT_CHANNEL_ID:
			return { ...state, chatChannelId: action.payload }				
		case types.SET_CHAT_CHANNEL:
			return { ...state, chatChannel: action.payload }	
		case types.SET_CHAT_CUSTOMER:
			return { ...state, chatCustomerId: action.payload }	
		case types.SET_CHAT_PROVIDER:
			return { ...state, chatProviderId: action.payload }				
        default:
            return state
    }
}