import './Header.css';
import React,{useState,useEffect} from 'react';
import {  useNavigate,useLocation } from "react-router-dom";

import { CSSTransition } from "react-transition-group";

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import iconHomeBlue from '../../Assets/Images/iconHome-blue.svg'; // Tell Webpack this JS file uses this image
import iconHomeGray from '../../Assets/Images/iconHome-gray.svg'; // Tell Webpack this JS file uses this image

import iconOngoingGray from '../../Assets/Images/iconOngoing-gray.svg'; // Tell Webpack this JS file uses this image
import iconOngoingBlue from '../../Assets/Images/iconOngoing-blue.svg'; // Tell Webpack this JS file uses this image

import iconHelpGray from '../../Assets/Images/iconHelp-gray.svg'; // Tell Webpack this JS file uses this image
import iconHelpBlue from '../../Assets/Images/iconHelp-blue.svg'; // Tell Webpack this JS file uses this image

import iconAgendaGray from '../../Assets/Images/iconAgenda-gray.svg'; // Tell Webpack this JS file uses this image
import iconAgendaBlue from '../../Assets/Images/iconAgenda-blue.svg'; // Tell Webpack this JS file uses this image

// import logo from '../../Assets/Images/logoTypeWOslogan.svg'; // Tell Webpack this JS file uses this image
import logo from '../../Assets/Images/logo_motuum-white.svg'; // Tell Webpack this JS file uses this image
import helpIcon from '../../Assets/Images/help-icon-blue.svg'; // Tell Webpack this JS file uses this image
import backgroundHexagon from '../../Assets/Images/backgroundHexagon.png'; // Tell Webpack this JS file uses this image
import backgroundHeaderImage from '../../Assets/Images/backgroundHeader.png'; // Tell Webpack this JS file uses this image

import * as AppActions from '../../Store/Actions/App'

import {
	AppBar,
	Drawer,
	Toolbar,
	Typography,
	IconButton,
    Box,
	Divider,
	Button,
	TextField,
	InputAdornment
} from '@mui/material';

const Bar = props => {

// Set var from props
const { 
	page,
	setDialog,
	dialog
} = props;


const location = useLocation();	
let navigate =  useNavigate();

// After mount component
useEffect(() => {

  
}, []);


return (
<Box style={{width:"100vw",height:"40px",position:"fixed",bottom:"0",backgroundImage:"url(/static/media/backgroundHexagon.bd7f9fac.png)",    backgroundSize:"cover",backgroundPosition:"center center"}}>
    <Box style={{backgroundColor:"transparent",display:"flex",width:"92%",alignItems:"center",position:"fixed",bottom:0,height:"80px",borderRadius:"25px",margin:"15px",backgroundColor:"#ffffff",boxShadow: "0px -10px 15px 0px #0000001A"}} onClick={()=>console.log(true)}>
	
		<Box style={{flex:1,display:"flex",flexDirection:"row",justifyContent:"space-around"}}>
				
			<Box style={{display:"flex",flexDirection:"row",justifyContent:"space-evenly",alignItems:"center",width:"100%"}}>										  
						<div style={{paddingTop:"3px",fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px",color:page == "home" ? "#003cfa" : "#636466",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}} onClick={() => navigate("/home")}>
						  
						  <div>
							<img src={page == "home" ? iconHomeBlue : iconHomeGray} atl="Sucesso" style={{marginLeft:"auto",marginRight:"auto",width:"28px",height:"auto"}} />	
						  </div>
						  
						  <div style={{fontFamily:"Roboto",fontWeight:"normal",fontSize:"9px"}}>
						  Home
						  </div>											  
						  
						</div>

						<div style={{paddingTop:"3px",fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px",color:page == "ongoing" ? "#003cfa" : "#636466",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}} onClick={() => navigate("/ongoingServices")}>
						  <div>
							<img src={page == "ongoing" ? iconOngoingBlue : iconOngoingGray} atl="Sucesso" style={{marginLeft:"auto",marginRight:"auto",width:"28px",height:"auto"}} />	
						  </div>
						  
						  <div style={{fontFamily:"Roboto",fontWeight:"normal",fontSize:"9px"}}>
						  Em andamento
						  </div>	
						</div>

						<div style={{paddingTop:"3px",fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px",color:page == "help" ? "#003cfa" : "#636466",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}} onClick={() => navigate("/support")}>
						  <div>
							<img src={page == "help" ? iconHelpBlue : iconHelpGray} atl="Sucesso" style={{marginLeft:"auto",marginRight:"auto",width:"28px",height:"auto"}} />	
						  </div>
						  
						  <div style={{fontFamily:"Roboto",fontWeight:"normal",fontSize:"9px"}}>
						  Ajuda
						  </div>	
						</div>

						<div style={{paddingTop:"3px",fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px",color:page == "scheduled" ? "#003cfa" : "#636466",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}} onClick={() => navigate("/scheduledServices")}>
						  <div>
							<img src={page == "scheduled" ? iconAgendaBlue : iconAgendaGray} atl="Sucesso" style={{marginLeft:"auto",marginRight:"auto",width:"28px",height:"auto"}} />	
						  </div>
						  
						  <div style={{fontFamily:"Roboto",fontWeight:"normal",fontSize:"9px"}}>
						  Agendados
						  </div>	
						</div>											
						
			</Box>	

		</Box>								
			
	</Box>	
</Box>	
)

};

// Bind States to Props
const mapStateToProps = store => ({
  leftDrawer:store.appReducer.leftDrawer,
  dialog:store.appReducer.dialog,
  userData:store.appReducer.userData
});

// Bind Actions to Propos
const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AppActions }, dispatch);
  

export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(Bar);
