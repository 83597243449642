import './MainHireService.css';
import React,{useState,useEffect,Fragment} from 'react';
import {Link,useNavigate} from 'react-router-dom';
import { CSSTransition } from "react-transition-group";
import moment from 'moment';
import 'moment/locale/pt-br';
import * as config from '../../Config/api.js';

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

import { Box,Backdrop,CircularProgress,Button,Divider,Avatar } from '@mui/material';

import AnimateHeight from 'react-animate-height';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from '@mui/icons-material/Warning';
import Fade from '@mui/material/Fade';
import Slide from '@mui/material/Slide';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import plusIconBlue from '../../Assets/Images/plusIconBlue.svg'; 
import lessIconBlue from '../../Assets/Images/lessIconBlue.svg'; 
import zoomIconBlue from '../../Assets/Images/zoomIconBlue.svg'; 

import {useComponentWillMount} from '../../Utils/customFunctions';
import Header from '../../Components/Layout/Header';
import If from '../../Components/Common/If';
import {ServiceDetailDialog} from '../SearchService/DialogServiceDetail';

import logo from '../../Assets/Images/logoTypeWOslogan.svg'; // Tell Webpack this JS file uses this image
import backgroundHexagon from '../../Assets/Images/backgroundHexagon.png'; // Tell Webpack this JS file uses this image
import headerCategory1 from '../../Assets/Images/headerSimpleCategory1.png'; // Tell Webpack this JS file uses this image
import desktop from '../../Assets/Images/desktop.svg'; // Tell Webpack this JS file uses this image

// Load Actions
import * as AppActions from '../../Store/Actions/App'

const HireServiceDone= props => {


const screenStyle = {
  alignItems: "stretch",
  justifyContent: "top",
  width:"100vw",
  minHeight:"100vh",
  display:"flex",
  flexDirection:"column",
  backgroundImage: "url(" + backgroundHexagon + ")",
  backgroundSize:"cover",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"center"
}; 


const screenStyleBackgroundImage = {
 /* backgroundImage: "url(" + headerCategory1 + ")",*/
 backgroundColor:"#BBB",
  backgroundSize:"auto",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"center",
  minHeight:"59px",
  width:"100%"
};


const screenStyleLoading = {
  alignItems: "middle",
  justifyContent: "center",
  width:"100vw",
  height:"100vh",
  display: "flex",
  flexDirection: "column"
}; 

// set classes var
// const classes = useStyles()();

// set vars from props
// Remember to add those who come from Redux
const { 
	innerLoading,
	setInnerLoading,
	setDialog,
	hireServiceService,
	hireServiceSchedule,
	hireServiceAddress,
	hireServicePayment,
	hireServiceInvoice,
	hireServiceExtraInfo,
	buttonLoading,
	setButtonLoading	
} = props;

// set Use of history for PUSH 
let navigate = useNavigate();


// Set internal state
const [contentVisible,setContentState] = useState(false);
const [loadingMessage,setLoadingMessage] = useState("Carregando...");

const periodArray = [];
periodArray[1] = "09:00 ?s 13:00 (manhã)";
periodArray[2] = "13:00 ?s 18:00 (tarde)";
periodArray[3] = "18:00 ?s 21:00 (noite)";
 
var tmpDate = hireServiceSchedule.selectedDate ? hireServiceSchedule.selectedDate:null;
var formattedDate = tmpDate ? moment(tmpDate).locale('pt-br').format("DD/MM/YYYY (dddd)"):"Carregando...";
const [scheduledDate,setScheduledDate ] = useState(formattedDate);

var formattedDateNoWeekValue = tmpDate ? moment(tmpDate).locale('pt-br').format("DD/MM/YYYY"):"Carregando...";
const [formattedDateNoWeek,setFormattedDateNoWeek ] = useState(formattedDateNoWeekValue);

const [scheduledPeriod,setScheduledPeriod ] = useState("Carregando...");

const [serviceName,setServiceName ] = useState(hireServiceService.name ? hireServiceService.name:"Carregando...");
const [serviceDescription,setServiceDescription ] = useState(hireServiceService.description ? hireServiceService.description:"Carregando...");
const [serviceWarranty,setServiceWarranty ] = useState(hireServiceService.warrantyTerm ? hireServiceService.warrantyTerm:"Carregando...");


// Retrieve from Session
var result = sessionStorage.getItem("@motuum/selectedProvider", undefined,true);
var providerObj = result ?JSON.parse(result):{};

// Set Provider data
const [providerCompanyName,setProviderCompanyName ] = useState(providerObj.companyName ? providerObj.companyName:"Carregando...");
const [providerEmployeeName,setProviderEmployeeName ] = useState(providerObj.employeeName ? providerObj.employeeName:null);

const [providerEmployeeDocument,setProviderEmployeeDocument ] = useState(providerObj.ProviderCompanyEmployee.document ? providerObj.ProviderCompanyEmployee.document:null);
const [providerEmployeeDocumentAlt,setProviderEmployeeDocumentAlt ] = useState(providerObj.ProviderCompanyEmployee.documentAlt ? providerObj.ProviderCompanyEmployee.documentAlt+" ("+providerObj.ProviderCompanyEmployee.documentAltInfo+")":null);
const [providerEmployeeDocumentAltFlag,setProviderEmployeeDocumentAltFlag ] = useState(providerObj.ProviderCompanyEmployee.document ? true:null);

const [providerAvatar,setProviderAvatar ] = useState(providerObj.avatar ? providerObj.avatar:null);
const [providerId,setProviderId ] = useState(providerObj.providerId ? providerObj.providerId:null);
const [avatarImage,setAvatarImage ] = useState(null);

const [heightPanel,setHeightPanel] = useState(0);

// Run after mount component
useEffect(() => {
	
	// Avoid user to go back on browser or hardware android butto
	/*window.navigateState(null, document.title, window.location.href);
	window.addEventListener('popstate', function(event) {
	window.navigateState(null, document.title, window.location.href);
	});*/
		
	const periodArray = new Array();
	periodArray[1] = "09:00 ?s 13:00 (manhã)";
	periodArray[2] = "13:00 ?s 18:00 (tarde)";
	periodArray[3] = "18:00 ?s 21:00 (noite)";
	
	
	if (hireServiceSchedule.scheduledType == 1) {
		var periodLabel = periodArray[hireServiceSchedule.scheduledPeriod];
	} //end if
	else if (hireServiceSchedule.scheduledType == 2) {
		var periodLabel = "HOJE das "+periodArray[hireServiceSchedule.scheduledPeriod];
	} //end if	
	else {
		var periodLabel = "HOJE com urgência em até 2h";
	} // end else
	setScheduledPeriod(periodLabel);
	
	// Rediredct if there is no searching
	var searchingStatus = sessionStorage.getItem("@motuum/searchingProvider");
	if (!searchingStatus) {	  
		navigate("/hireService");  
		return;
	} // end if	

	// Set avatar URL// Avatar URL
	var avatarImageUrl = config.API_URL+"/provider/avatar/retrieve/"+providerId+"/"+providerAvatar+"";
	setAvatarImage(avatarImageUrl);
	
	// Set Content Loading ON
	//setInnerLoading(true);
	setContentState(true);

	// set scroll to top
	window.scrollTo(0, 1);

	// Runs when UNMOUNT component
	return () => {

		// unmount...

	};
  
}, []); // end useEffect


// Will Mount Component HOOK
useComponentWillMount(() => {
	
    // Set session of app
  var sessionCheck = sessionStorage.getItem('@motuum/appLaunched');
  
  // if session is not created, redirect to bootstrap. (avoid direct access using url)
  if (!sessionCheck) {
	  navigate("/");
  } // end if	
      
}, []); // end useComponentWillMount

const handleClose = () => {
	setInnerLoading(false);
};


	return (
	<Fragment>
			
			<Header title={hireServiceService.name} buttons={{support:false,serviceHire:true,serviceName:hireServiceService.name,serviceCategory:hireServiceService.breadcrumb,leftFake:true,rightFake:true,menu:false,close:false,back:false}}  />
			
			<Fade in={contentVisible} timeout={{enter:1000,exit:300}}  mountOnEnter unmountOnExit>				
			
				<Box className="mainHome" style={{...screenStyle}} m={0}>
					
						<Box style={{marginTop:"190px",marginBottom:"20px"}}>						
							<div style={{fontFamily:"Roboto",fontWeight:"100",fontSize:"42px",color:"#003CFA",lineHeight:"38px"}}>
								  PROFISSIONAL ENCONTRADO
							</div>								 	
						</Box>	

						<Box style={{alignItems:"center",display:"flex",flexDirection:"column",paddingRight:"50px",paddingLeft:"50px"}} mt={1} >
								
								<div style={{fontFamily:"Roboto",fontWeight:"regular",fontSize:"16px",color:"#636466",lineHeight:"22px"}}>O serviço será agendado de acordo com as informações fornecidas.</div>							
							
						</Box>
						
						<Box style={{marginTop:"30px",alignItems:"stretch",display:"flex",flexDirection:"column",justifyContent:"center",paddingRight:"30px",paddingLeft:"30px"}}>
						 
								<Slide direction="down" in={true} timeout={{ enter: 2000, exit: 300 }}   mountOnEnter unmountOnExit>
									<Card style={{minHeight:"230px",width:"100%",marginBottom:"20px",backgroundColor:"#ffffff",boxShadow: "0px 10px 20px 0px #0000002A",borderRadius:"10px",position:"relative"}} onClick={(target)=>navigate("/scheduledServices")}>
								 
										<CardContent style={{padding:"10px",paddingBottom:"0px"}}>
														
											<Box style={{display:"flex",flexdirection:"row",alignItems:"center",justifyContent:"space-between",marginTop:"10px",marginLeft:"15px",marginRight:"15px"}}>
												
												<Box style={{float:"left",textAlign:"left"}}>										
													<h3 style={{color:"#003CFA",fontFamily:"Roboto",fontWeight:"bold",fontSize:"16px",marginTop:"0px",marginBottom:"2px",lineHeight:"18px"}}>Serviço Solicitado</h3>
													<div style={{color:"#636466",fontFamily:"Roboto",fontWeight:"bold",fontSize:"12px",lineHeight:"18px"}}>
													{serviceName}
													</div>	
												</Box>
												
												<div style={{textAlign:"right",float:"right",maxWidth:"50%",maxHeight:"38px",overflow:"hidden"}}>
													<h3 style={{color:"#003CFA",fontFamily:"Roboto",fontWeight:"bold",fontSize:"12px",marginTop:"0px",marginBottom:"2px",lineHeight:"18px"}}>{formattedDateNoWeek}</h3>
													<div style={{color:"#636466",fontFamily:"Roboto",fontWeight:"normal",fontSize:"12px",lineHeight:"18px"}}>
														<span dangerouslySetInnerHTML={{
														__html: scheduledPeriod
													  }}></span>
													</div>
												</div>														
											
											</Box>
											
											<Divider style={{marginBottom:"20px",marginTop:"20px",marginLeft:"15px",marginRight:"15px"}} />
											
											<Box style={{display:"flex",flexdirection:"row",alignItems:"center",justifyContent:"space-between",marginTop:"10px",marginLeft:"15px",marginRight:"15px"}}>
												
												<Box style={{float:"left",textAlign:"left"}}>										
													<h3 style={{color:"#003CFA",fontFamily:"Roboto",fontWeight:"bold",fontSize:"16px",marginTop:"0px",marginBottom:"2px",lineHeight:"18px"}}>{providerEmployeeName}</h3>
													<div style={{color:"#636466",fontFamily:"Roboto",fontWeight:"bold",fontSize:"12px",lineHeight:"18px"}}>
													{providerCompanyName}
													</div>	
												</Box>
												
												<div style={{textAlign:"right",float:"right"}}>
														<Avatar src={avatarImage} variant="square" style={{width:"40px",height:"40px",borderRadius:"50px"}} />	
												</div>														
											
											</Box>		

											<Box style={{textAlign:"left",padding:"0px",marginTop:"10px",color:"#636466",fontFamily:"Roboto",fontWeight:"regular",fontSize:"12px",marginLeft:"15px",marginRight:"15px"}}>
																									
													{providerEmployeeDocumentAltFlag &&
													<>
													RG: {providerEmployeeDocumentAlt} <br />
													</>
													}
													CPF: {providerEmployeeDocument}
														
											</Box>											
											
										  </CardContent>
									
									<CardActions style={{backgroundColor:"#003CFA10",padding:"2px", position:"absolute",bottom:"0",width:"100%"}}>
											  
									  <div style={{flex:1,textAlign:"center"}}>
											<Button style={{color:"#003CFA",fontFamily:"Roboto",fontWeight:"normal",fontSize:"12px"}} size="small" onClick={()=>console.log("pressed...") }>										
												<img src={zoomIconBlue} style={{width:"24px",height:"24px",marginRight:"5px"}} />
												<div>Detalhes do serviço</div>										
											</Button>
										</div>
										
									 </CardActions>										

								</Card>		
							</Slide>
								
						</Box>	
						
					<Box style={{backgroundColor:"transparent",display:"flex",width:"100%",alignItems:"center",position:"fixed",bottom:0,height:"100px",backgroundColor:"#ffffff",boxShadow: "0px -10px 15px 0px #0000001A"}} onClick={()=>console.log(true)}>		
							<Box style={{flex:1,display:"flex",flexDirection:"row",justifyContent:"center"}}>
									
										<Button color="secondary" variant="contained" style={{width:"76%",height:"53px",fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px",color:"#FFF",borderRadius:"10px",paddingLeft:"30px",paddingRight:"30px"}} component={Link} to="/home">
										  OK
										</Button>	
						
							</Box>								
								
					</Box>							
														
				</Box>
				
			</Fade> 				
			
			<ServiceDetailDialog />
				
	</Fragment>
)};

const mapStateToProps = store => ({
	innerLoading:store.appReducer.innerLoading,
	scheduledInfo:store.appReducer.scheduledInfo,
	hireServiceService:store.serviceReducer.hireServiceService,
	hireServiceSchedule:store.serviceReducer.hireServiceSchedule,
	hireServiceAddress:store.serviceReducer.hireServiceAddress,
	hireServicePayment:store.serviceReducer.hireServicePayment,
	hireServiceInvoice:store.serviceReducer.hireServiceInvoice,
	hireServiceExtraInfo:store.serviceReducer.hireServiceExtraInfo,
	buttonLoading:store.serviceReducer.buttonLoading  
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AppActions }, dispatch);

export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(HireServiceDone);
 
