import './MainLogin.css';
import React,{useState,useEffect,Fragment} from 'react';
import {Link,useNavigate} from 'react-router-dom';

import {
  osVersion,
  osName,
  fullBrowserVersion,
  mobileVendor,
  mobileModel,
  deviceType,
  isBrowser,
  isMobile
} from "react-device-detect";

import PropTypes from 'prop-types';
import { CSSTransition } from "react-transition-group";

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import InputMask from "react-input-mask";

// // import LiveChat from 'react-livechat'

// Material UI Import
import { Box,CircularProgress,Typography,Button,InputAdornment,IconButton,TextField,AppBar,Toolbar } from '@mui/material';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CloseIcon from '@mui/icons-material/Close';

import Slide from '@mui/material/Slide';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import {useComponentWillMount} from '../../Utils/customFunctions';
import Header from '../../Components/Layout/Header';
import If from '../../Components/Common/If';
import {SupportComponent} from '../Support/SupportComponentHeader';

// import logo from '../../Assets/Images/logoTypeWOslogan.svg'; // Tell Webpack this JS file uses this image
import logo from '../../Assets/Images/logo_motuum-white.svg'; // Tell Webpack this JS file uses this image
import helpIcon from '../../Assets/Images/help-icon-blue.svg'; // Tell Webpack this JS file uses this image
import backgroundHexagon from '../../Assets/Images/backgroundHexagon.png'; // Tell Webpack this JS file uses this image
import backgroundHeaderImage from '../../Assets/Images/backgroundHeader.png'; // Tell Webpack this JS file uses this image

// Load Actions
import * as AppActions from '../../Store/Actions/App'
import * as LoginActions from '../../Store/Actions/Login'

const Login = props => {



const screenStyle = {
  alignItems: "stretch",
  justifyContent: "top",
  width:"100vw",
  minHeight:"100vh",
  display:"flex",
  flexDirection:"column",
  alignItems:"stretch",
  justifyContent:"center",
  backgroundImage: "url(" + backgroundHexagon + ")",
  backgroundSize:"cover",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"center"
}; 

const backgroundHeader = {
  backgroundImage: "url(" + backgroundHeaderImage + ")",
  backgroundSize:"cover",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"center"
}; 

const screenStyleLoading = {
  alignItems: "middle",
  justifyContent: "center",
  width:"100vw",
  height:"100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent:"flex-start"
}; 

// set classes var
// // const classes = useStyles()();
const classes = {};

// Set functions
const handleChange = (event, newValue) => {
setValue(newValue);
};

// Set TABPANEL
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
	  hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

// set vars from props
// Remember to add those who come from Redux
const { 
	innerLoading,
	setInnerLoading,
	setDialog,
	buttonLoading,
	setButtonLoading,
	processLogin	
} = props;

// set Use of history for PUSH 
var navigate = useNavigate();

// Set internal state
const [contentVisible,setContentState] = useState(false);
const [loadingMessage,setLoadingMessage] = useState("Carregando...");
const [value, setValue] = useState(0); // state for tab

const [loginType, setLoginType] = useState("email"); // state for tab
const [dialogAlert, setAlertDialog] = useState(false); // state for tab
const [dialogMessage, setDialogMessage] = useState(""); // state for tab
const [dialogTitle, setDialogTitle] = useState("Verifique as informações"); // state for tab

const [email, setEmail] = useState(""); // state for input fields
const [mobilePhone, setMobilePhone] = useState(""); // state for input fields
const [password, setPassword] = useState(""); // state for input fields
  
  
// Run after mount component
useEffect(() => {
	
	// reset recover session
	sessionStorage.removeItem("@motuum/recoverToken");
	sessionStorage.removeItem("recoverPasswordStatus");	
	
	// Retrieve User Data Obj. 
	var result = localStorage.getItem("@motuum/userData", undefined,true);
	var userData = JSON.parse(result);  

	  // If token dont exist, redirect to onboarding or register
	  if (userData) {
		  navigate("/home");
		  return;
	  } // end if
  
  // Turn off button loading
  setButtonLoading(false);
 
 // Set Content Loading ON
 setContentState(true);

  // set scroll to top
  window.scrollTo(0, 1);
    
  // Runs when UNMOUNT component
  return () => {
    
	// unmount...	
	
  };
  
}, []); // end useEffect


// Will Mount Component HOOK
useComponentWillMount(() => {
	
    // Set session of app
  var sessionCheck = sessionStorage.getItem('@motuum/appLaunched');
  
  // if session is not created, redirect to bootstrap. (avoid direct access using url)
  if (!sessionCheck) {
	  navigate("/");
  } // end if	
      
}, []); // end useComponentWillMount


// Prcess Login
const processLoginAction = (targetLoginType,value) => {
	
	// Set Loading Buton On
	setButtonLoading(true);

	if (targetLoginType == "email") {				
		// set scope email
		setEmail(value.email);
		setPassword(value.password);
	} // end if
	else {		
		// set scope email
		setMobilePhone(value.mobilePhone);
		setPassword(value.password);		
	}			

	// Process LOGIN 
	// ...
	var data = {
		email:value.email,
		mobilePhone:value.mobilePhone ? value.mobilePhone.replace(/\D/g,""):"",
		password:value.password,
		loginType:targetLoginType
	}
	var props = {
		navigate:navigate,
		setAlertDialog:setAlertDialog,
		setDialogMessage:setDialogMessage,
		setDialogTitle:setDialogTitle
	}	
	processLogin(data,props);

};

	return (
	<Fragment>
		
			<CSSTransition in={contentVisible} timeout={1000} classNames="fade"	unmountOnExit>	
			
				<Box className="mainLogin" style={{...screenStyle}} m={0}>
							
						<Box style={{height:"210px",alignItems:"center",display:"flex",backgroundColor:"#003CFA",...backgroundHeader}}>	
						
							<Box style={{flex:1,alignItems:"center",display:"flex",marginTop:"9%"}}>						
									<img src={logo} alt="Logomarca Motuum" style={{marginLeft:"auto",marginRight:"auto",width:"76%",height:"auto"}} />
							</Box>	
							
						</Box>
						
						<Box style={{flex:1,backgroundColor:"transparent",display:"flex",flexDirection:"column",alignItems:"top",paddingLeft:"35px",paddingRight:"35px",marginBottom:"120px"}}>	
								
							<Box   ml={2} mr={2} style={{flex:1}} 
								sx={{
									flexGrow: 1,
									backgroundColor: "transparent",
							}}>
								<Box style={{marginTop:"30px",marginBottom:"20px"}}>						
									<div style={{fontFamily:"Roboto",fontWeight:"100",fontSize:"42px",color:"#003CFA"}}>
										  LOGIN
									</div>								 	
								</Box>	
	
								<FormEmail
									processLoginAction={processLoginAction}
									buttonLoading={buttonLoading}
									setButtonLoading={setButtonLoading}
									email={email}
									password={password}
									dialogAlert={dialogAlert}
									setAlertDialog={setAlertDialog}
									setDialogTitle={setDialogTitle}
									setDialogMessage={setDialogMessage}									
								/>							  

								<Box style={{backgroundColor:"transparent",justifyContent:"center",alignItems:"flex-start",display:"flex",flexDirection:"row",marginTop:"10px"}}>	
								
										<Button  style={{flex:1.5,color:"#636466",fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px",textTransform:"none"}} component={Link} to="/forgotpassword">Esqueci minha senha</Button>	
										
										<Button  style={{flex:1,color:"#003CFA",fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px",textTransform:"none"}} component={Link} to="/register">Cadastre-se</Button>	
										
								</Box>							  
							  
							</Box>
							
								
						</Box>
						

						<Box style={{backgroundColor:"transparent",display:"flex",width:"100%",alignItems:"center",position:"fixed",bottom:0,height:"80px",backgroundColor:"#ffffff",boxShadow: "0px -10px 15px 0px #0000001A"}} onClick={()=>setDialog('helpComplete',true)}>		

							<Box style={{flex:1,display:"flex",flexDirection:"row",justifyContent:"space-around"}}>
									
								<Box style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>						
										
											<div style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
												<img src={helpIcon} alt="Ajuda" style={{width:"25px",height:"auto"}} />
											</div>
										  
											<div style={{marginLeft:"5px",paddingTop:"3px",fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px",color:"#003cfa",}}>
											  Ajuda
											</div>	
											
						
								</Box>	

							</Box>								
								
						</Box>							
								
				</Box>
			
			</CSSTransition>    

			{/*<LiveChat license={14088816} />*/}
			
			<HelpDialog />
			
			<AlertDialog 
				dialogAlert={dialogAlert}
				setAlertDialog={setAlertDialog}
				dialogTitle={dialogTitle}
				dialogMessage={dialogMessage}
			/>		
				
	</Fragment>
)};


const FormEmail = (props) => {
	
const [showPassword, setShowPassword] = useState(false);
const handleClickShowPassword = () => setShowPassword(!showPassword);
const handleMouseDownPassword = () => setShowPassword(!showPassword);	
	
const [email, setEmail] = useState(props.email); // state for input fields
const [mobilePhone, setMobilePhone] = useState(props.mobilePhone); // state for input fields
const [loginType, setLoginType] = useState(""); // set login type
const [password, setPassword] = useState(props.password); // state for input fields
		
const sendLogin = () => {	
			
	// Check if Email is filled
	if (!email) {		
		props.setAlertDialog(true);
		props.setDialogMessage("Digite o email ou telefone para prosseguir.");
		props.setDialogTitle("Verifique as informações");	
		props.setButtonLoading(false);
		return false;				
	} // end if

	// Check if password is filled
	if (!password) {		
		props.setAlertDialog(true);
		props.setDialogMessage("Digite a sua senha para prosseguir.");
		props.setDialogTitle("Verifique as informações");	
		props.setButtonLoading(false);
		return false;	
	} // end if
	
	// cleanPhoneMask
	var value = email.replace("(","").replace(")","").replace("-","").replace(" ","");

	// Check if string only contains numbers
	var isNum = /^\d+$/.test(value);

	// if yes, its a mobilePhone Check integrity. 
	if (isNum) {
		
		// Check if mobile phone has ddd + number
		var isValid = /^\s*(\d{2}|\d{0})[-. ]?(\d{5}|\d{4})[-. ]?(\d{4})[-. ]?\s*$/.test(value);	
		var phoneSize = value.replace(/\D/g,"").length;

		if (!isValid || phoneSize < 10) {
			props.setAlertDialog(true);
			props.setDialogMessage("Digite um telefone válido para prosseguir. Utilize apenas números. Lembre-se do DDD com 2 dígitos e o telefone em seguida. ");
			props.setDialogTitle("Verifique as informações");	
			props.setButtonLoading(false);
			return false; 
		} // end if
		
		setLoginType("mobilePhone");
		var loginTypeFlag = "mobilePhone";
	} // end if
	// If no, its an email
	else {

		// Check if Email is valid
		let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
		if (reg.test(value) === false) {
			props.setAlertDialog(true);
			props.setDialogMessage("Digite um email válido para prosseguir.");
			props.setDialogTitle("Verifique as informações");	
			props.setButtonLoading(false);
			return false;			
		} // end if	
		
		setLoginType("email");
		var loginTypeFlag = "email";
	} // end else


    // Send proper login and values
	if (loginTypeFlag == 'email') {
		props.processLoginAction('email',{email:email,password:password});
	} // end if
	else {
		props.processLoginAction("mobilePhone",{mobilePhone:email,password:password}) 
	} // end else
 
};		
		
const handleKeypress = e => {	
	 //it triggers by pressing the enter key  
	if (e.key === "Enter") {
	  props.processLoginAction("email",{email:email,password:password})
	}
};		
	
	return (
	<form id="formLoginEmail"  noValidate autoComplete="off">
	
		<TextField
		  label="Email ou Celular"
		  style={{ margin: 10,marginLeft:0,marginRight:0,backgroundColor:"#fff",width:"100%",fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px",}}
		  placeholder="Informe o email ou celular cadastrado"
		  required
		  variant="outlined"
		  type="email"
		  onKeyPress={handleKeypress}
		  value={email}
		  onChange={(event) => {
			  setEmail(event.target.value)
		  }}			    
		/>
		
		<TextField
		  id="loginPassword"
		  label="Senha"
		  style={{ margin: 10,marginLeft:0,marginRight:0,backgroundColor:"#fff",width:"100%",fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px",}}
		  placeholder="Informe a sua senha"
		  required
		  variant="outlined"
		  type={showPassword ? "text" : "password"} 
		  InputProps={{ // <-- This is where the toggle button is added.
			endAdornment: (
			  <InputAdornment position="end">
				<IconButton
				  aria-label="toggle password visibility"
				  onClick={handleClickShowPassword}
				  onMouseDown={handleMouseDownPassword}
				>
				  {showPassword ? <Visibility /> : <VisibilityOff />}
				</IconButton>
			  </InputAdornment>
			)
		  }}
		onKeyPress={handleKeypress}		  
		value={password}
		  onChange={(event) => {
			  setPassword(event.target.value)
		  }}
	  
		  
		/>
		<Button variant="contained"  color="primary" size="large" style={{width:"100%",height:"54px",marginTop:"10px",backgroundColor:"#555555",color:"#FFF",fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px"}} onClick={() => sendLogin() }>
			{ !props.buttonLoading &&
			   <div>Entrar</div>
			}
			
			{ props.buttonLoading &&
			   <>
			   <CircularProgress size={30} color="inherit" />
			   <span style={{marginLeft:"10px"}}> Autenticando</span>
			   </>
			}	
		</Button>	
	  
	</form>
	);
	
	
} // end export const

const FormMobilePhone = (props) => {
	
const [showPassword, setShowPassword] = useState(false);
const handleClickShowPassword = () => setShowPassword(!showPassword);
const handleMouseDownPassword = () => setShowPassword(!showPassword);	
	
const [mobilePhone, setMobilePhone] = useState(props.mobilePhone); // state for input fields
const [password, setPassword] = useState(props.password); // state for input fields
	
const handleKeypress = e => {	
	 //it triggers by pressing the enter key  
	if (e.key === "Enter") {
	  props.processLoginAction("mobilePhone",{mobilePhone:mobilePhone,password:password})
	}
};		
	
	return (
		<form id="formLoginPhone" noValidate autoComplete="off">

		<InputMask
            mask="(99) 99999-9999"
			onKeyPress={handleKeypress}
            value={mobilePhone}
			  onChange={(event) => {
				  setMobilePhone(event.target.value)
			  }}
          >
		  {(inputProps) => (
			<TextField
			  label="Telefone Celular"
			  style={{ margin: 10,marginLeft:0,marginRight:0,backgroundColor:"#fff",width:"94%"}}
			  placeholder="Telefone no formato (XX) 9XXXX-XXXX"
			  required
			  size="small"
			  variant="outlined"
			 />
		  )}
        </InputMask>

			
			<TextField
			  id="loginPasswordPhone"
			  label="Senha"
			  style={{ margin: 10,marginLeft:0,marginRight:0,backgroundColor:"#fff",width:"94%" }}
			  placeholder="Informe a sua senha"
			  required
			  size="small"
			  variant="outlined"
			  type={showPassword ? "text" : "password"} 
			  
			  InputProps={{ // <-- This is where the toggle button is added.
				endAdornment: (
				  <InputAdornment position="end">
					<IconButton
					  aria-label="toggle password visibility"
					  onClick={handleClickShowPassword}
					  onMouseDown={handleMouseDownPassword}
					>
					  {showPassword ? <Visibility /> : <VisibilityOff />}
					</IconButton>
				  </InputAdornment>
				)
			  }}
			onKeyPress={handleKeypress}
			value={password}
			  onChange={(event) => {
				  setPassword(event.target.value)
			  }}									  
			  
			/>
			
			<Button variant="contained"  color="primary" size="large" style={{width:"100%",height:"48px",marginTop:"10px",backgroundColor:"#555555",color:"#FFF"}} onClick={() => props.processLoginAction("mobilePhone",{mobilePhone:mobilePhone,password:password}) }>
				{ !props.buttonLoading &&
				   <div>Entrar no aplicativo</div>
				}
				
				{ props.buttonLoading &&
				   <>
				   <CircularProgress size={30} color="inherit" />
				   <span style={{marginLeft:"10px"}}> Verificando dados...</span>
				   </>
				}	
			</Button>
		  
		</form>	
	);
	
} // end export const

const AlertDialog = (props) => {
	
	return (
		 <Dialog
			open={props.dialogAlert}
			onClose={() => props.setAlertDialog(false)} 
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		  >
		  <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
			<DialogContent>
			  <DialogContentText id="alert-dialog-description">
			  {props.dialogMessage}
			  </DialogContentText>
			</DialogContent>
			<DialogActions>
			  <Button onClick={() => props.setAlertDialog(false)} color="primary" autoFocus>
				OK
			  </Button>
			</DialogActions>			
		  </Dialog>
	  )
	
	
} // end AlertDialog


const mapStateToProps = store => ({
  innerLoading:store.appReducer.innerLoading,
  dialog:store.appReducer.dialog,
  buttonLoading:store.loginReducer.buttonLoading,  
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AppActions, ...LoginActions }, dispatch);
  

// Transition Slide for Dialog SLIDE UP	
const TransitionSlideUp = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;  
});	

/* Extra  Dialog Component START */
export const HelpDialog = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {
		

const backgroundHeaderModal = {
  backgroundImage: "url(" + backgroundHeaderImage + ")",
  backgroundSize:"cover",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"center",
  display:"flex",
  alignItems:"center",
  justifyContent:"center"
}; 		
	


const screenStyleDialog = {
  alignItems: "center",
  justifyContent: "top",
  width:"100vw",
  minHeight:"100vh",
  display:"flex",
  flexDirection:"column",
  backgroundColor:"#F0F0F0"
}; 
	
// set classes var
//// const classes = useStyles()();	
const classes = {};

const { 
	innerLoading,
	setInnerLoading,
	setDialog,
	dialog
} = props;	

	return (
	<div>
		  <Dialog fullScreen open={dialog.target == 'helpComplete' && dialog.status} onClose={()=>setDialog('helpComplete',false)} TransitionComponent={TransitionSlideUp}>
		  
			<AppBar  sx={{
				position: 'fixed',
				height:'80px',
				...backgroundHeaderModal
			}}>
			  <Toolbar style={{width:"100%"}}>
				  <div style={{
							marginLeft: 10,
							flex: 1,
							textAlign:"center",
							display:"flex",
							justifyContent:"center",
							alignItems:"center"
					}}>
					  <img src={logo} alt="Motuum" style={{marginLeft:"auto",marginRight:"auto",width:"40%",height:"auto"}} />
				  </div>
				</Toolbar>
			</AppBar>

			<Box style={{...screenStyleDialog,backgroundColor:"#FFF",paddingLeft:"20px",paddingRight:"20px"}} m={0}>
			
				<Box mt={10} ml={0} mr={0}>
					
					<Box pt={2}>

						<SupportComponent simple="true" screen="login" setDialog={setDialog} dialog={dialog} />
						
						<Box style={{backgroundColor:"transparent",display:"flex",flexDirection:"row",justifyContent:"center"}} ml={0} mr={0} mb={5} mt={0} pl={7} pr={7}>						
							
							<Button color="primary" variant="outlined" style={{width:"100%",height:"54px",borderRadius:"10px",fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px",}} onClick={()=>setDialog('helpComplete',false)}>
							 Fechar
							</Button>		
								
						</Box>							

					</Box>
			
				</Box>				
			
			</Box>

		  </Dialog>
		</div>
		
	);
	
});
  

export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(Login);






