import './MainHireService.css';
import React,{useState,useEffect,useRef,Fragment} from 'react';
import {Link,useNavigate} from 'react-router-dom';
import { CSSTransition } from "react-transition-group";

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

import { Box,Backdrop,CircularProgress,Button,IconButton } from '@mui/material';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';


import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from '@mui/icons-material/Warning';
import Fade from '@mui/material/Fade';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

import {useComponentWillMount} from '../../Utils/customFunctions';
import Header from '../../Components/Layout/Header';
import If from '../../Components/Common/If';

import Countdown from "react-countdown";

import logo from '../../Assets/Images/logoTypeWOslogan.svg'; // Tell Webpack this JS file uses this image
import backgroundHexagon from '../../Assets/Images/backgroundHexagon.png'; // Tell Webpack this JS file uses this image
import headerCategory1 from '../../Assets/Images/headerSimpleCategory1.png'; // Tell Webpack this JS file uses this image

// Load Actions
import * as AppActions from '../../Store/Actions/App'
import * as ServiceActions from '../../Store/Actions/Service'

const HireServiceSearching = props => {


const screenStyle = {
  alignItems: "stretch",
  justifyContent: "top",
  width:"100vw",
  minHeight:"100vh",
  display:"flex",
  flexDirection:"column",
  /*backgroundImage: "url(" + backgroundHexagon + ")",*/
  backgroundColor:"#9abae5",
  backgroundSize:"cover",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"center"
}; 


const screenStyleBackgroundImage = {
  backgroundImage: "url(" + headerCategory1 + ")",
  backgroundSize:"auto",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"center",
  minHeight:"59px",
  width:"100%"
};

const screenStyleLoading = {
  alignItems: "middle",
  justifyContent: "center",
  width:"100vw",
  height:"100vh",
  display: "flex",
  flexDirection: "column"
}; 

// set classes var
// const classes = useStyles()();

// set vars from props
// Remember to add those who come from Redux
const { 
	innerLoading,
	setInnerLoading,
	setDialog,
	dialog,
	setService,
	scheduledInfo,
	resetHireService,
	hireServiceService,
	hireServiceSchedule,
	hireServiceAddress,
	hireServicePayment,
	hireServiceInvoice,
	hireServiceExtraInfo,
	buttonLoading,
	setButtonLoading,
	checkServiceOfferStatus,
	cancelServiceOffer,
	counterTimer,
	setCounterTimer
} = props;

// set Use of history for PUSH 
let navigate = useNavigate();

// Set internal state
const [contentVisible,setContentState] = useState(false);
const [screenStatus,setScreenStatus] = useState('searching');

const [snack,setSnack] = useState(false); // state for tab
const [snackMessage, setSnackMessage] = useState(""); // state for tab

const [dialogMessage, setDialogMessage] = useState(""); // state for tab
const [alert, setAlert] = useState(false); // state for tab

const [searchStatus, setSearchStatus] = useState(0); // state for tab

const [timer,setTimer] = useState(true);
const [timerId,setTimerId] = useState(null);
const [ServiceOfferId,setServiceOfferId] = useState(null);
const [ServiceQuotationId,setServiceQuotationId] = useState(null);

const [address,setAddress] = useState("");

/*
console.log("hireServiceService: "+JSON.stringify(hireServiceService,null,2));
console.log("hireServiceSchedule: "+JSON.stringify(hireServiceSchedule,null,2));
console.log("hireServiceAddress: "+JSON.stringify(hireServiceAddress,null,2));
console.log("hireServicePayment: "+JSON.stringify(hireServicePayment,null,2));
console.log("hireServiceInvoice: "+JSON.stringify(hireServiceInvoice,null,2));
console.log("hireServiceExtraInfo: "+JSON.stringify(hireServiceExtraInfo,null,2));*/

// Simulate states
const registerMobile = false;
const registerEmail = true;
const activationDone = false;

// Random component


// Renderer callback with condition
const renderer = ({ hours, minutes, seconds, completed }) => {

  var minutes = ("00"+minutes).slice(-2);
  var seconds = ("00"+seconds).slice(-2);

  if (completed) {
    // Render a complete state
	
	 // Hide Searching screen with null value
	 setScreenStatus('');

	 // Show Not Found Screen after 1 seconds
	 setTimeout(function() {
			setScreenStatus('notfound');
	},400)

	 return (
	  <div style={{fontFamily:"Roboto",fontWeight:"bold",fontSize:"18px",color:"#003CFA"}}>
			Encerrado...
      </div>
	  );

  } else {
    // Render a countdown
    return (
      <div style={{fontFamily:"Roboto",fontWeight:"bold",fontSize:"18px",color:"#003CFA"}}>
        {minutes}:{seconds}
      </div>
    );
  }
};

const tryAgain = () => {
	
	  // Restart Check Service Offer
	 startCheckServiceOfferRoutine();
	
	 // Hide Searching screen with null value
	 setScreenStatus('reset');
	 
	 // Show Not Found Screen after 1 seconds
	 setTimeout(function() {
			setScreenStatus('searching');
	},400);
	
}

const counterControl = useRef(null);

const startCheckServiceOfferRoutine = () => {
	
	// Retrieve Service Offer Id from session
	var serviceOfferId = sessionStorage.getItem("@motuum/serviceOfferId");	
	
  	// Start background routine to check for ServiceOffer Change
	setTimer(true);
	var counter = 0;
	var checkOfferTimer = setInterval(function() {
		
		// count times executed
		counter++;

		// Stope after 20 executions
		if (counter == 20) {
			clearInterval(checkOfferTimer);
		} // end if
		
			// Set data and Retrieve service offfers
			let data = {
				serviceOfferId:serviceOfferId
			}
			
			let props = {
					navigate:navigate,
					setDialogMessage:setDialogMessage,
					setAlert:setAlert,
					timerId:checkOfferTimer,
					setSnack:setSnack,
					setSnackMessage:setSnackMessage,
					setSearchStatus:setSearchStatus,
					setScreenStatus:setScreenStatus
				}		
			checkServiceOfferStatus(data,props);

	},20000); // Run every X seconds	

	// Set Timer ID to sue Later
	setTimerId(checkOfferTimer);	
	
} // end 

const stopCheckServiceOfferRoutine = () => {
	
	// Set Timer off
	setTimer(false);
	
	// Clear Timer
	clearInterval(timerId);
	
} // end


// Change Step Function Next and Previous
const toggleDialog = (target,status) => {

	// set which dialog to open or close
	setDialog(target,status);

};

const cancelServiceOfferAction = () => {
	
	// Set loading button on
	setButtonLoading(true);

	// Cancel Background Routine Timer
	stopCheckServiceOfferRoutine();

	// set data
	var data = {
		serviceOfferId:ServiceOfferId,
		serviceQuotationId:ServiceQuotationId
	};	

	//[ DISPATCH ACTION ] checkEmail(NEXT STEP, DATA ,navigation OBJ);
	//When create Service Quotation might approved paymento on IUGU and also create billing
	// If Payment Fail, might alert user to try another credit card...
	var props = {
		navigate:navigate,
		setDialogMessage:setDialogMessage,
		setAlert:setAlert,
		setButtonLoading:setButtonLoading
	}		
	cancelServiceOffer(data,props);	
	
}

// Run after mount component
useEffect(() => {
	
 // Avoid user to go back on browser or hardware android butto
  /*window.navigateState(null, document.title, window.location.href);
  window.addEventListener('popstate', function(event) {
    window.navigateState(null, document.title, window.location.href);
  });*/
    
	// Rediredct if there is no searching
	var searchingStatus = sessionStorage.getItem("@motuum/searchingProvider");
	if (!searchingStatus) {	  
	navigate("/hireService");  
	return;
	} // end if	
	
  // Set button off
  setButtonLoading(false);
	  
  // Set address for STATIC MAP dinamically
	//let addressText = hireServiceAddress.fullAddress ? hireServiceAddress.fullAddress: "Rio%20de%20Janeiro,%20RJ,%20Barra%20da%20Tijuca,Citta%20America";
  
	var addressText = hireServiceAddress.streetType+" "+hireServiceAddress.streetName+", "+hireServiceAddress.streetNumber+", "+hireServiceAddress.suburb+", "+hireServiceAddress.city+", "+hireServiceAddress.state+", Brazil";
	
	var finalAddress = "https://maps.googleapis.com/maps/api/staticmap?center="+addressText+"&zoom=16&size=600x800&maptype=roadmap&style=feature:all|element:labels.text.fill|color:0xffffff&style=feature:all|element:labels.text.stroke|color:0x9ABAE5|lightness:13&style=feature:administrative|element:geometry.fill|color:0x9ABAE5&style=feature:administrative|element:geometry.stroke|color:0x144b53|lightness:14|weight:1.4&style=feature:landscape|element:all|color:0x9ABAE5&style=feature:poi|element:geometry|color:0x0c4152&style=feature:road.highway|element:geometry.fill|color:0x9ABAE5&style=feature:road.highway|element:geometry.stroke|color:0x0b434f&style=feature:road.arterial|element:geometry.fill|color:0xF7F7F7&style=feature:road.arterial|element:geometry.stroke|color:0xF7F7F7|lightness:6&style=feature:road.local|element:geometry|color:0xF7F7F7|lightness:1&style=feature:water|element:all|color:0x8db4e6&style=feature:poi|visibility:off&key=AIzaSyBdE3uu0p9cJ_961a03aXMo0kGSzuUfTVE";  

  
  setAddress(finalAddress);

	// start Check offer Status Timer
	startCheckServiceOfferRoutine(); 
	
	// Set Service Offer Id on State
	var serviceOfferIdRes = sessionStorage.getItem("@motuum/serviceOfferId");	
	setServiceOfferId(serviceOfferIdRes);
	
	// Set Service Quotation Id on State
	var serviceQuotationIdRes = sessionStorage.getItem("@motuum/quotationId");	
	setServiceQuotationId(serviceQuotationIdRes);	

	// Set Content Loading ON
	//setInnerLoading(true);
	setContentState(true);

  // set scroll to top
  window.scrollTo(0, 1);
    
  // Runs when UNMOUNT component
  return () => {
    stopCheckServiceOfferRoutine();
  };
  
}, []); // end useEffect


// Will Mount Component HOOK
useComponentWillMount(() => {
	
    // Set session of app
  var sessionCheck = sessionStorage.getItem('@motuum/appLaunched');
  
  // if session is not created, redirect to bootstrap. (avoid direct access using url)
  if (!sessionCheck) {
	  navigate("/");
  } // end if	
      
}, []); // end useComponentWillMount


	return (
	<Fragment>
							
			<Header title={hireServiceService.name} buttons={{support:false,serviceHire:true,serviceName:hireServiceService.name,serviceCategory:hireServiceService.breadcrumb,leftFake:true,rightFake:true,menu:false,close:false,back:false}}  />
			
			<Fade in={screenStatus == 'searching'} timeout={{enter:1000,exit:300}}  mountOnEnter unmountOnExit>	
			<div>
				<Box className="mainHome" style={{...screenStyle}} m={0}>
						
						<Box style={{alignItems:"flex-start",justifyContent:"center",display:"flex",position:"relative",marginTop:"150px"}} mt={2}>			
							
							
							<Box style={{width:"100%",position:"absolute",top:"30px",zIndex:"999"}}>	
									<div style={{fontFamily:"Roboto",fontWeight:"390",fontSize:"42px",color:"#003CFA",lineHeight:"38px", backgroundColor:" rgb(255,255,255,0.7)", padding:"10px"}}>
										  BUSCANDO PROFISSIONAL!
									</div>									
							</Box>	
														
						<div className="razar" style={{zIndex:"99"}}>
						  <div className="ringbase ring1"></div>
						  <div className="ringbase ring2"></div>
						  <div className="ringbase ring3"></div>
						  <div className="pulse"></div>
	
						  <div className="dot pos1"></div>
						  <div className="dot pos2"></div>
						</div>							
														
							
						<img src={address} style={{width:"100%",height:"auto",filter:"grayscale(100%)",minHeight:"400px",backgroundColor:"whitesmoke"}} />
								
								
						</Box>						
						
														
				</Box>
				

				<Box style={{backgroundColor:"transparent",display:"flex",width:"100%",height:"148px",alignItems:"center",position:"fixed",bottom:0,backgroundColor:"#ffffff",boxShadow: "0px -10px 15px 0px #0000001A",zIndex:"9"}}>		

						<Box style={{width:"100%"}}>
								
								<Box style={{width:"100%",zIndex:"999",marginTop:"20px",marginBottom:"20px"}}>	
									<div style={{width:"50%",marginLeft:"auto",marginRight:"auto"}}>									
										<Countdown date={Date.now() + 300000} renderer={renderer} ref={counterControl} />										
									</div>	
								</Box>													
							
								<Box style={{display:"flex",flexDirection:"row",justifyContent:"center"}} ml={0} mr={0} mb={2} mt={1}>			

									<Button  variant="outlined" color="primary" size="large" style={{width:"76%",height:"53px",marginBottom:"10px",borderRadius:"10px",fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px"}} disabled={buttonLoading} onClick={(target,status) => toggleDialog('confirm',true)}>
										{ !buttonLoading &&
										   <div> Cancelar </div>
										}									
										{ buttonLoading &&
											<>
											   <CircularProgress size={30} color="inherit" />
											   <span style={{marginLeft:"10px"}}> Processando...</span>
										   </>
										}	
									</Button>	
											
								</Box>	


						</Box>								
							
				</Box>					
			</div>
			</Fade> 		

			<Fade in={screenStatus == 'notfound'} timeout={{enter:1000,exit:300}}  mountOnEnter unmountOnExit>				
			<div>
				<Box className="mainHome" style={{...screenStyle,backgroundImage: "url(" + backgroundHexagon + ")"}} m={0}>
					
						{/* Category Block */}
						<Box style={{marginTop:"190px"}} mb={0} >
						
								<div style={{paddingRight:"30px",paddingLeft:"30px",fontFamily:"Roboto",fontWeight:"390",fontSize:"40px",color:"#003CFA",lineHeight:"38px"}}>
									NENHUM PROFISSIONAL<br />ESTÁ DISPONÍVEL
								</div>	
								
						</Box>
						
						<Box style={{marginTop:"20px",marginBottom:"10px",alignItems:"center",display:"flex",flexDirection:"column",paddingRight:"30px",paddingLeft:"30px"}} mt={1} >
								
								<div style={{fontFamily:"Roboto",fontWeight:"regular",fontSize:"16px",color:"#636466",lineHeight:"22px"}}>Não encontramos um profissional. Você pode tentar mais uma vez ou desistir da solicitação de serviço.</div>							
							
						</Box>						
						
						<Box mt={2} style={{flexBasis:"20vh",alignItems:"stretch",display:"flex",flexDirection:"row"}}>			
									
							<SentimentVeryDissatisfiedIcon style={{flex:1,color: "#999",fontSize:"12rem"}} />
								
						</Box>	

						
														
				</Box>
				
				<Box style={{backgroundColor:"transparent",display:"flex",width:"100%",height:"158px",alignItems:"center",justifyContent:"center",position:"fixed",bottom:0,backgroundColor:"#ffffff",boxShadow: "0px -10px 15px 0px #0000001A",zIndex:"9"}}>		

						<Box style={{width:"100%"}}>
							
								<Box style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}} ml={0} mr={0}>			
								
									<Button variant="outlined"  color="primary" size="large" style={{width:"76%",height:"53px",marginBottom:"10px",borderRadius:"10px",fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px"}} onClick={tryAgain}>
									 Tentar novamente
									</Button>
																
									<Button variant="contained" color="secondary" size="large" style={{width:"76%",height:"53px",marginBottom:"10px",borderRadius:"10px",fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px"}} onClick={()=> navigate("/home") }>
									 Sair
									</Button>									

								</Box>	


						</Box>								
							
				</Box>					
			</div>	
			</Fade> 	

			<SnackNotification 
				snack={snack}
				setSnack={setSnack}
				snackMessage={snackMessage}
			/>	

			<ConfirmDialog cancelServiceOfferAction={cancelServiceOfferAction} />	

			<AlertDialog 
				alert={alert}
				setAlert={setAlert}
				dialogMessage={dialogMessage}
			/>			
				
	</Fragment>
)};

const SnackNotification = (props) => {

	return (
		<Snackbar
			anchorOrigin={{
			  vertical: 'bottom',
			  horizontal: 'left',
			}}
			open={props.snack}
			autoHideDuration={4000}
			onClose={() => props.setSnack(false)}
		  >
		    <div style={{borderRadius:10,borderWidth:"1px",borderColor:"#222",padding:10,display:"flex",flexDirection:"row",alignItems:"center",flex:1,backgroundColor:"#666"}}>
				
				<div style={{flex:6,fontSize:"14px",textAlign:"left",color:"#fff"}}>{props.snackMessage}</div>
				
				<IconButton style={{flex:1,color:"#fff"}} size="small" aria-label="close" color="inherit" onClick={() => props.setSnack(false)}>
				  <CloseIcon fontSize="small" />
				</IconButton>
			  			
			</div>
		  </Snackbar>
	  )	
	
} // end AlertDialog


export const AlertDialog = (props) => {
	
	return (
		 <Dialog
			open={props.alert} 
			onClose={()=>props.setAlert(false)}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		  >
		  <DialogTitle id="alert-dialog-title">Mensagem do sistema</DialogTitle>
			<DialogContent>
			  <DialogContentText id="alert-dialog-description">
			  {props.dialogMessage}
			  </DialogContentText>
			</DialogContent>
			<DialogActions>
			  <Button onClick={() => props.setAlert(false)} color="primary" autoFocus>
				OK
			  </Button>
			</DialogActions>			
		  </Dialog>
	  )
	
} // end AlertDialog

const mapStateToProps = store => ({
	innerLoading:store.appReducer.innerLoading,
	dialog:store.appReducer.dialog,
	scheduledInfo:store.appReducer.scheduledInfo,
	hireServiceService:store.serviceReducer.hireServiceService,
	hireServiceSchedule:store.serviceReducer.hireServiceSchedule,
	hireServiceAddress:store.serviceReducer.hireServiceAddress,
	hireServicePayment:store.serviceReducer.hireServicePayment,
	hireServiceInvoice:store.serviceReducer.hireServiceInvoice,
	hireServiceExtraInfo:store.serviceReducer.hireServiceExtraInfo,
	buttonLoading:store.serviceReducer.buttonLoading,
	counterTimer:store.serviceReducer.counterTimer
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AppActions,...ServiceActions }, dispatch);


/* Confirm Alert Dialog Component START */
export const ConfirmDialog = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {
	
const screenStyleDialog = {
  alignItems: "stretch",
  justifyContent: "top",
  width:"100vw",
  minHeight:"100vh",
  display:"flex",
  flexDirection:"column",
  backgroundColor:"#FFF"
}; 
	
// set classes var
// const classes = useStyles()();	

const { 
	setDialog,
	dialog
} = props;	

const confirmAction = (value) => {

	// Close Dialog
	setDialog('confirm',false);
	
	// execute service processing...
	props.cancelServiceOfferAction();
	
};	

	return (
		<div>
		  <Dialog
			open={dialog.target == 'confirm' && dialog.status} onClose={()=>setDialog('confirm',false)}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		  >
			<DialogTitle id="alert-dialog-title">{"Confirmação de Cancelamento"}</DialogTitle>
			<DialogContent>
			  <DialogContentText id="alert-dialog-description">
				Tem certeza que quer cancelar sua solicitação de serviço? Ao prosseguir a sua solicitação será cancelada e os profissionais seráo notificados. O processo é irreversível.  
			  </DialogContentText>
			  
			  <DialogContentText id="alert-dialog-description">
				 Se desejar, abra outra solicitação de serviço. 
			  </DialogContentText>			  
			  
			</DialogContent>
			<DialogActions>
			  <Button onClick={()=>setDialog('confirm',false)} color="primary">
				Cancelar
			  </Button>
			  <Button color="primary" onClick={()=>confirmAction()} autoFocus>
				Confirmar
			  </Button>
			</DialogActions>
		  </Dialog>
		</div>
	);
	
});


export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(HireServiceSearching);
 
