import React from 'react';
import axios from 'axios';
import moment from 'moment';
import {jwtDecode} from "jwt-decode";
import * as types from '../actionTypes';
import * as config from '../../Config/api.js';

export const setButtonLoading  = (status) => { 
	return { 
		type: types.BUTTON_LOADING,
		payload: status 
	}
};

export const setService = (value) => {
	return {
		type: types.SET_SERVICE,
		payload: value
	}	
};

export const setSchedule = (value) => {
	return {
		type: types.SET_SCHEDULE,
		payload: value
	}	
};

export const setAddress = (value) => {	
	return {
		type: types.SET_ADDRESS,
		payload: value
	}	
};

export const setPayment = (value) => {
	return {
		type: types.SET_PAYMENT,
		payload: value
	}	
};

export const setInvoice = (value) => {
	return {
		type: types.SET_INVOICE,
		payload: value
	}	
};

export const setExtraInfo = (value) => {
	return {
		type: types.SET_EXTRAINFO,
		payload: value
	}	
};

export const setAddressesCards = (value) => {
	return {
		type: types.SET_ADDRESSES_CARDS,
		payload: value
	}	
};

export const setCreditCards = (value) => {
	return {
		type: types.SET_CREDIT_CARDS,
		payload: value
	}	
};

export const setInvoices = (value) => {
	return {
		type: types.SET_INVOICES,
		payload: value
	}	
};

export const setSearchResults = (value) => {
	return {
		type: types.SET_SEARCH_RESULT,
		payload: value
	}	
};

export const setCounterTime = (value) => {
	return {
		type: types.SET_COUNTERTIMER,
		payload: value
	}	
};

export const setScheduledServiceOrders = (value) => {
	return {
		type: types.SET_SCHEDULEDSERVICES,
		payload: value
	}	
};

export const setExecutedServiceOrders = (value) => {
	return {
		type: types.SET_EXECUTEDSERVICES,
		payload: value
	}	
};

export const setOngoingServiceOrders = (value) => {
	return {
		type: types.SET_ONGOINGSERVICES,
		payload: value
	}	
};

export const setCancelledServiceOrders = (value) => {
	return {
		type: types.SET_SCHEDULEDSERVICES_CANCELLED,
		payload: value
	}	
};

export const setSelectedScheduledServiceOrder = (value) => {
	return {
		type: types.SET_SELECTED_SCHEDULEDSERVICE,
		payload: value
	}	
};

export const setSelectedExecutedServiceOrder = (value) => {
	return {
		type: types.SET_SELECTED_EXECUTEDSERVICE,
		payload: value
	}	
};

export const setSelectedOngoingServiceOrder = (value) => {
	return {
		type: types.SET_SELECTED_ONGOINGSERVICE,
		payload: value
	}	
};

export const setSelectedCancelledServiceOrder = (value) => {
	return {
		type: types.SET_SELECTED_CANCELLEDSERVICE,
		payload: value
	}	
};

export const setCategories = (value) => {
	return {
		type: types.SET_CATEGORIES,
		payload: value
	}	
};

export const setCategoryBreadCrumb = (value) => {
	return {
		type: types.SET_CATEGORY_BREADCRUMB,
		payload: value
	}	
};

export const resetHireService = () => {

	return {
		type: types.RESET_SERVICE_HIRE,
		payload: {}
	}	

};

// Convert base64 to blob
export const b64toBlob = (b64Data, contentType='', sliceSize=2048) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
}

export const createServiceQuotation = (data,props)  => {
	
		return dispatch => {
		
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = localStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
				// decode JWT 
				var decoded = jwtDecode(userObj.userToken);
				
				// Set CustomerUserId
				const CustomerUserId = decoded.CustomerUserId;
	
					// Prepare for user creation
					const preparedData = {};
					
					// Set main attributes
					preparedData.categoryId = data.hireServiceService.categoryId;
					preparedData.serviceId = data.hireServiceService.id;
					preparedData.userEmail = data.userEmail;
					preparedData.userMobilePhone = data.userMobilePhone;
					preparedData.PaymentGatewayCustomerId = data.PaymentGatewayCustomerId ? data.PaymentGatewayCustomerId : null;
										
					// service schedule date and payment method
					preparedData.scheduledType = data.hireServiceSchedule.scheduledType;
					preparedData.scheduledDate = moment(data.hireServiceSchedule.selectedDate).format("YYYY-MM-DD");
					preparedData.scheduledPeriod = data.hireServiceSchedule.scheduledPeriod;
					//preparedData.paymentMethod = data.hireServicePayment.paymentType;
					preparedData.paymentMethod = 1; // force credit card
					
					// service price
					// TO-DO --- get price according to REGION...
					preparedData.serviceBasisPrice = data.hireServiceService.Price[0].price;
					preparedData.serviceUrgentFee = data.hireServiceService.Price[0].urgentTax;
					preparedData.serviceDiscount = data.hireServiceService.serviceDiscount ? data.hireServiceService.serviceDiscount:0;
					preparedData.serviceTotalPrice = data.hireServiceSchedule.scheduledType == 3 ? (preparedData.serviceBasisPrice + preparedData.serviceUrgentFee) : data.hireServiceService.Price[0].price;
					//preparedData.serviceTotalPrice = data.hireServiceService.serviceTotalPrice ? data.hireServiceService.serviceTotalPrice : data.hireServiceService.Price.price;
					
					// service address
					preparedData.serviceAddress = {};
						preparedData.serviceAddress.CustomerAddressId = data.hireServiceAddress.addressId;
						preparedData.serviceAddress.label = data.hireServiceAddress.label;
						preparedData.serviceAddress.streetType = data.hireServiceAddress.streetType;
						preparedData.serviceAddress.streetName = data.hireServiceAddress.streetName;
						preparedData.serviceAddress.streetNumber = data.hireServiceAddress.streetNumber;
						preparedData.serviceAddress.streetComplement = data.hireServiceAddress.streetComplement;
						preparedData.serviceAddress.postalCode = data.hireServiceAddress.postalCode;
						preparedData.serviceAddress.suburb = data.hireServiceAddress.suburb;
						preparedData.serviceAddress.city = data.hireServiceAddress.city;
						preparedData.serviceAddress.state = data.hireServiceAddress.state;
						preparedData.serviceAddress.country = data.hireServiceAddress.country;
						preparedData.serviceAddress.fullAddress = data.hireServiceAddress.fullAddress;
						preparedData.serviceAddress.googleCoordinates = data.hireServiceAddress.googleCoordinates;
						preparedData.serviceAddress.googlePlaceId = data.hireServiceAddress.googlePlaceId;				
					
					// service credit card or PIX - FORCE CREDIT CARD...
					//if (data.hireServicePayment.paymentType == 1) {
					preparedData.serviceCreditcard = {};
						preparedData.serviceCreditcard.CustomerCreditcardId = data.hireServicePayment.id;
						preparedData.serviceCreditcard.holderName = data.hireServicePayment.holderName;
						preparedData.serviceCreditcard.referenceNumber = data.hireServicePayment.referenceNumber;
						preparedData.serviceCreditcard.expirationDate = data.hireServicePayment.expirationDate;
						preparedData.serviceCreditcard.cardFlag = data.hireServicePayment.cardFlag;
						preparedData.serviceCreditcard.token = data.hireServicePayment.token ? data.hireServicePayment.token:null;
						preparedData.serviceCreditcard.PaymentGatewayPaymentMethodId = data.hireServicePayment.PaymentGatewayPaymentMethodId ? data.hireServicePayment.PaymentGatewayPaymentMethodId:null;
					//} // end if
										
					// Assume that is PIX method of payment (TO BE IMPLEMENTED...)
					/*else {
						preparedData.servicePix = {};
							preparedData.servicePix.qrcode = data.hireServicePayment.qrcode;
							preparedData.servicePix.qrcodeText = data.hireServicePayment.qrcodeText;						
					}*/ // end else
						
					// set billing info
					preparedData.serviceBillingInfo = {}
						preparedData.serviceBillingInfo.CustomerBillingDocumentId = data.hireServiceInvoice.invoiceId;
						preparedData.serviceBillingInfo.type = data.hireServiceInvoice.type == "PF" ? 1 : 2;
						preparedData.serviceBillingInfo.billingName = data.hireServiceInvoice.billingName;
						preparedData.serviceBillingInfo.billingDocument = data.hireServiceInvoice.billingDocument;
						preparedData.serviceBillingInfo.CustomerAddressId = data.hireServiceInvoice.CustomerAddressId;
						// set billing address
						preparedData.serviceBillingInfo.streetType = data.hireServiceInvoice.CustomerAddress.streetType;
						preparedData.serviceBillingInfo.streetName = data.hireServiceInvoice.CustomerAddress.streetName;
						preparedData.serviceBillingInfo.streetNumber = data.hireServiceInvoice.CustomerAddress.streetNumber;
						preparedData.serviceBillingInfo.streetComplement = data.hireServiceInvoice.CustomerAddress.streetComplement;
						preparedData.serviceBillingInfo.postalCode = data.hireServiceInvoice.CustomerAddress.postalCode;
						preparedData.serviceBillingInfo.suburb = data.hireServiceInvoice.CustomerAddress.suburb;
						preparedData.serviceBillingInfo.city = data.hireServiceInvoice.CustomerAddress.city;
						preparedData.serviceBillingInfo.state = data.hireServiceInvoice.CustomerAddress.state;
						preparedData.serviceBillingInfo.country = data.hireServiceInvoice.CustomerAddress.country;
						preparedData.serviceBillingInfo.fullAddress = data.hireServiceInvoice.CustomerAddress.fullAddress;
					
					// set meta information (extra information)
					
					// Configura form data for upload
					const formData = new FormData();
										
					preparedData.serviceMetaInformation = [];					
						for (var i = 0;i < data.hireServiceExtraInfo.length; i++) {	

							// if its image field
							if (data.hireServiceExtraInfo[i].fieldType == "images") {
								
								var itemValue = [];
								for (var z = 0; z < data.hireServiceExtraInfo[i].fieldValue.length;z++) {
									
									var obj = {
										id: data.hireServiceExtraInfo[i].fieldValue[z].id,
										name:"extraImage"
									}								
									itemValue.push(obj);
									
									// extract base64 data from header
									var tmpFile = data.hireServiceExtraInfo[i].fieldValue[z].src.split(",");
									
									const contentType = 'image/jpeg';
									var b64Data = tmpFile[1];
									var blob = b64toBlob(b64Data, contentType); // convert base64 to blob

									// append image to formData
									formData.append('images',blob,'image.jpg');			
									
								} // end for							
								
							} // end if
							else {
								var itemValue = data.hireServiceExtraInfo[i].fieldValue;
							} // end else
						
						var extraInfo = {
							templateId:data.hireServiceExtraInfo[i].templateId,
							fieldId:data.hireServiceExtraInfo[i].fieldId,
							fieldType:data.hireServiceExtraInfo[i].fieldType,
							fieldLabel:data.hireServiceExtraInfo[i].fieldLabel,
							fieldValue:itemValue,
							fieldOrder:data.hireServiceExtraInfo[i].fieldOrder							
						}				
						preparedData.serviceMetaInformation.push(extraInfo);					
					} // end for
					
					// Set Service
					preparedData.service = {};
						preparedData.service.serviceId = data.hireServiceService.id;
						preparedData.service.name = data.hireServiceService.name;
						preparedData.service.description = data.hireServiceService.description;
						preparedData.service.shortDescription = data.hireServiceService.shortDescription;
						preparedData.service.icon = data.hireServiceService.icon;
						preparedData.service.effort = data.hireServiceService.effort;
						preparedData.service.effortUnity = data.hireServiceService.effortUnity;
						preparedData.service.warrantyTerm = data.hireServiceService.warrantyTerm;

					// append JSON Dara as string to be converted at API
					formData.append("jsonData", JSON.stringify(preparedData)); // add json 
 
					// Connect to CREATE QUOTATION Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/service/quotation/create',
						   method : 'POST',
						   data   : formData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json',
							'Content-Type': 'multipart/form-data'
						}
					})				
					
					.then((response) => {
		
					// If an error occured. Check error is blocking or non-blocking.
					if (response.data.error) {						
						dispatch(setButtonLoading(false));
						props.setDialogMessage('Ocorreu um erro ao criar sua solicitação de serviço. Tente novamente. [1]');		
						props.setAlert(true);							
						return;					
					} // end if	*/
			
					if (response.data.success) {
						
					// Check if PRE-AUTH wads denied on IUGU. 
					// If an error occured. Check error is blocking or non-blocking.
					if (response.data.paymentDenied) {						
						dispatch(setButtonLoading(false));
						props.setDialogTitle("Transação Negada!");	
						props.setDialogMessage('A pré-autorização do cartão de crédito fornecido foi NEGADA. Normalmente esse problema ocorre devido a dados errados do cartão, por falta de saldo ou algum problema com a emissor. Insira ou selecione outro cartão para continuar.');		
						props.setAlert(true);							
						return;					
					} // end if	

					// Check if there is an error with token
					// If an error occured. Check error is blocking or non-blocking.
					if (response.data.tokenNotFound) {						
						dispatch(setButtonLoading(false));
						props.setDialogTitle("Transação Negada!");	
						props.setDialogMessage('Ocorreu um erro com o cartão de crédito informado. Insira novamente o cartão de crédito ou reinicie o processo.');		
						props.setAlert(true);							
						return;					
					} // end if	
									
						// save User Session Id on Session Storage
						sessionStorage.setItem("@motuum/searchingProvider",1);	
						sessionStorage.setItem("@motuum/serviceOfferId",response.data.serviceOfferId);	
						sessionStorage.setItem("@motuum/quotationId",response.data.quotationId);	
					
						// Navigate to next step
						props.navigate("/hireService/searching",{serviceOfferId:response.data.serviceOfferId});						
											
					} // end if
					
					// If any other error, what do do???
					// ...
						
					}, (error) => {											
						dispatch(setButtonLoading(false));
						props.setDialogMessage('Ocorreu um erro de resposta do servidor. Tente novamente.');
						props.setAlert(true);							
						return;
											
					})
					.catch(error=> {							
						dispatch(setButtonLoading(false));
						props.setDialogMessage('Ocorreu um erro geral ao se comunicar com a API. Verifique sua conexão e tente novamente.');
						props.setAlert(true);									
						return;
					});

		}
	
}
export const checkServiceOfferStatus  = (data,props) => {

		return dispatch => {

					// Check if there is a local USER OBEJECt with a TOKEN
					var result = localStorage.getItem("@motuum/userData", undefined,true);
					var userObj = JSON.parse(result);  
					
					// decode JWT 
					var decoded = jwtDecode(userObj.userToken);
					
					// Set CustomerUserId
					const CustomerUserId = decoded.CustomerUserId;			
					
					// prepare data to send
					var sendData = {
						serviceOfferId:data.serviceOfferId
					} // end if
	
					// Connect to REGISTER Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/service/checkServiceOfferStatus',
						   method : 'POST',
						   data: sendData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})				
					
					.then((response) => {
													
							// If an error occured. Check error is blocking or non-blocking.
							if (response.data.error) {					
								props.setDialogMessage('Ocorreu um erro ao verificar o status da oferta de serviço. Tente novamente. [1]');		
								props.setAlert(true);							
								return;						
							} // end if	*/
														
							if (response.data.success) {
											
								// if Status = 0 
								if (response.data.status === 0) {

									//props.setSnackMessage('Aguardando aceite de profissionais...');		
									//props.setSnack(true);																						
									return;
									
								} // end if								
																	
								// if status == 1 (SELECTING PROVIDER)
								if (response.data.status === 1) {
									//props.setSnackMessage('Definindo profissional...');		
									//props.setSnack(true);									
									return;									
								} // end if	
									
								// if status == 2 (SELECTED PROVIDER)
								if (response.data.status === 2) {

									//props.setSnackMessage('Profissional foi escolhido...');		
									//props.setSnack(true);										
									
									// Store locally (SESSION) result of operation ( 1 = done with success)
									sessionStorage.setItem("@motuum/searchingProvider",1);
									
									// Select selected employeeName
									var array = response.data.ProviderCompany.ProviderProfileCompanyEmployees; // clone
									var index = array.filter(item => item.id == response.data.selectedProviderUserId);	
									var selectedEmployee = index[0];

									// Retrieve data from selected ProviderUserId
									var userObj = {
										employeeName:response.data.ProviderCompleteName,
										companyName:response.data.ProviderCompany.realName,
										ProviderCompanyEmployee:selectedEmployee,
										avatar:response.data.ProviderAvatar,
										providerId:response.data.ProviderUserId
									}
									const saveObj = JSON.stringify(userObj);
									
									// save User Data on Local Storage
									sessionStorage.setItem("@motuum/selectedProvider",saveObj);
									
									// Clear Timer 
									clearInterval(props.timerId);
									
									// Redirect to success page
									props.navigate("/hireService/done",{ProviderUserId:response.data.selectedProviderUserId});
									return;
									
								} // end if		
								
								// if status == 2 (NO PROVIDER)
								if (response.data.status === 3) {
									
									// set session NOT FOUND PROVIDER  ( 2 = not found provider)
									sessionStorage.setItem("@motuum/searchingProvider",2);

									// Set Searching status
									props.setSearchStatus(2);
									props.setScreenStatus('notfound');
									
									// Clear Timer 
									clearInterval(props.timerId);
																		
									return;
									
								} // end if	

								// if status == 9 (CANCELLED BY USER)
								if (response.data.status === 9) {
									
									// set session NOT FOUND PROVIDER  ( 2 = not found provider)
									sessionStorage.setItem("@motuum/searchingProvider",9);

									// Set Searching status
									props.setSearchStatus(9);
									
									props.setDialogMessage("Oferta de serviço foi cancelada!");		
									props.setAlert(true);	
									
									// Clear Timer 
									clearInterval(props.timerId);
																		
									return;
									
								} // end if									
																
							} // end if				
					
					}, (error) => {
						var alertMessage = (error.code == "ECONNABORTED") ? 
						"Ocorreu um erro ao se comunicar com a API. Verifique se está com acesso a internet.":
						"Ocorreu um erro ao se comunicar com a API. Verifique o email e tente novamente.";					
						props.setDialogMessage(alertMessage);		
						props.setAlert(true);	
						return;											
					})
					.catch(error=> {
						props.setDialogMessage("Ocorreu um erro geral ao se comunicar com a API. Verifique sua conexão e tente novamente.");
						props.setAlert(true);	
						return;
					});
					
	} // end dispatch
		
};

export const cancelServiceOffer  = (data,props) => {

		return dispatch => {

					// Check if there is a local USER OBEJECt with a TOKEN
					var result = localStorage.getItem("@motuum/userData", undefined,true);
					var userObj = JSON.parse(result);  
					
					// decode JWT 
					var decoded = jwtDecode(userObj.userToken);
					
					// Set CustomerUserId
					const CustomerUserId = decoded.CustomerUserId;			

					// prepare data to send
					var sendData = {
						serviceOfferId:data.serviceOfferId,
						serviceQuotationId:data.serviceQuotationId,
						cancelReason:"Desistência da solicitação."
					} // end if
	
					// Connect to REGISTER Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/service/cancelServiceOffer',
						   method : 'PUT',
						   data: sendData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})				
					
					.then((response) => {
						
							
							// If an error occured. Check error is blocking or non-blocking.
							if (response.data.error) {					
								dispatch(setButtonLoading(false));
								alert('Ocorreu um erro ao concorrer com a oferta de serviço selecionada. Tente novamente. [1]');
								return;					
							} // end if	*/
														
							if (response.data.success) {
														
								// Turn button loading off
								dispatch(setButtonLoading(false));
							
								// Navigate to next step
								props.navigate("/home");
								return;
																
							} // end if				
					
					
					}, (error) => {

						// Turn button loading off
						dispatch(setButtonLoading(false));
						
						// verify if timeout happened
						var alertMessage = (error.code == "ECONNABORTED") ? 
						"Ocorreu um erro ao se comunicar com a API. Verifique se está com acesso a internet.":
						"Ocorreu um erro ao se comunicar com a API. Verifique o email e tente novamente.";					
						alert(alertMessage);
						return;			
					})
					.catch(error=> {
						
						dispatch(setButtonLoading(false));
						alert('Ocorreu um erro geral ao se comunicar com a API. Verifique sua conexão e tente novamente.');
						return;						
					});
				

		
	} // end dispatch
		
};

export const retrieveServiceOrders = (data,props)  => {
	
		return dispatch => {
		
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = localStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
				// decode JWT 
				var decoded = jwtDecode(userObj.userToken);
				
				// Set CustomerUserId
				const CustomerUserId = decoded.CustomerUserId;
	
					// Prepare for user creation
					const preparedData = {
						status:data.status,
						page:data.page ? data.page : 0,
						limit:data.limit ? data.limit : 10
					};	
					
					// Connect to CREATE QUOTATION Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/service/order/list',
						   method : 'POST',
						   data: preparedData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})				
					
					.then((response) => {
		/*
console.log("\n ====================================== \n");		
console.log("[response.data]: "+JSON.stringify(response.data,null,2));
console.log("\n ====================================== \n");		*/						
							
					// If an error occured. Check error is blocking or non-blocking.
					if (response.data.error) {						
						dispatch(setButtonLoading(false));
						props.setDialogMessage('Ocorreu um erro ao buscar seus serviços agendados. Tente novamente. [1]');		
						props.setAlert(true);							
						return;					
					} // end if	*/
			
					if (response.data.success) {
						
						dispatch(setButtonLoading(false));
						
						// add little delay for UX flow
						setTimeout(function() {							
							props.setContentState(true);							
						},100);
													
							// If Schedules services (status = 0)
							if (data.status.includes(0)) {								
								dispatch(setScheduledServiceOrders(response.data.services));
								return;
							} // end if
							// If Ongoing services (status = 1 || 2 || 3)
							else if (data.status.includes(1) || data.status.includes(2) || data.status.includes(3)) {
								dispatch(setOngoingServiceOrders(response.data.services));
								return;								
							} // end if
							// If executed services (status = 4)
							else if (data.status.includes(4) || data.status.includes(5)) {
								dispatch(setExecutedServiceOrders(response.data.services));
								return;								
							} // end if
							// If Cancelled services (status = 99)
							else if (data.status == 99) {
								dispatch(setCancelledServiceOrders(response.data.services));
								return;								
							} // end if							

							
					} // end if
					
					// If any other error, what do do???
					// ...
						
					}, (error) => {											
						dispatch(setButtonLoading(false));
						props.setDialogMessage('Ocorreu um erro de resposta do servidor. Tente novamente.');
						props.setAlert(true);							
						return;
											
					})
					.catch(error=> {	
					
						dispatch(setButtonLoading(false));
						props.setDialogMessage('Ocorreu um erro geral ao se comunicar com a API. Verifique sua conexão e tente novamente.');
						props.setAlert(true);									
						return;
					});

		}
	
}

export const refreshServiceOrders = (data)  => {
	
		return dispatch => {
		
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = localStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
				// decode JWT 
				var decoded = jwtDecode(userObj.userToken);
				
				// Set CustomerUserId
				const CustomerUserId = decoded.CustomerUserId;
	
					// Prepare for user creation
					const preparedData = {
						status:data.status,
						page:data.page ? data.page : 0,
						limit:data.limit ? data.limit : 10
					};	
					
					// Connect to CREATE QUOTATION Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/service/order/list',
						   method : 'POST',
						   data: preparedData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})				
					
					.then((response) => {
							
					// If an error occured. Check error is blocking or non-blocking.
					if (response.data.error) {						
						console.log('Ocorreu um erro ao buscar seus serviços agendados. Tente novamente. [1]');		
						return;					
					} // end if	*/
			
					if (response.data.success) {
													
							// If Schedules services (status = 0)
							if (data.status.includes(0)) {								
								dispatch(setScheduledServiceOrders(response.data.services));
								return;
							} // end if
							// If Ongoing services (status = 1 || 2 || 3)
							else if (data.status.includes(1) || data.status.includes(2) || data.status.includes(3)) {
								dispatch(setOngoingServiceOrders(response.data.services));
								return;								
							} // end if
							// If executed services (status = 4)
							else if (data.status.includes(4) || data.status.includes(5)) {
								dispatch(setExecutedServiceOrders(response.data.services));
								return;								
							} // end if
							// If Cancelled services (status = 99)
							else if (data.status == 99) {
								dispatch(setCancelledServiceOrders(response.data.services));
								return;								
							} // end if							

							
					} // end if
					
					// If any other error, what do do???
					// ...
						
					}, (error) => {											
						console.log('Ocorreu um erro de resposta do servidor. Tente novamente.');						
						return;
											
					})
					.catch(error=> {	
						console.log('Ocorreu um erro geral ao se comunicar com a API. Verifique sua conexão e tente novamente.');								
						return;
					});

		}
	
}

export const retrieveCancelledServiceOrders = (data,props)  => {
	
		return dispatch => {
		
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = localStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
				// decode JWT 
				var decoded = jwtDecode(userObj.userToken);
				
				// Set CustomerUserId
				const CustomerUserId = decoded.CustomerUserId;
	
					// Prepare for user creation
					const preparedData = {
						status:99,
						page:data.page ? data.page : 0,
						limit:data.limit ? data.limit : 10
					};	
					
					// Connect to CREATE QUOTATION Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/service/order/list',
						   method : 'POST',
						   data: preparedData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})				
					
					.then((response) => {
							
					// If an error occured. Check error is blocking or non-blocking.
					if (response.data.error) {						
						dispatch(setButtonLoading(false));
						props.setDialogMessage('Ocorreu um erro ao buscar seus serviços agendados. Tente novamente. [1]');		
						props.setAlert(true);							
						return;					
					} // end if	*/
			
					if (response.data.success) {
						
						dispatch(setButtonLoading(false));
						
						// add little delay for UX flow
						setTimeout(function() {							
							props.setContentState(true);							
						},100);
							
							// If Schedules services (status = 0)
							if (data.status.includes(0)) {								
								dispatch(setScheduledServiceOrders(response.data.services));
								return;
							} // end if
							// If Ongoing services (status = 1 || 2 || 3)
							else if (data.status.includes(1) || data.status.includes(2) || data.status.includes(3)) {
								dispatch(setOngoingServiceOrders(response.data.services));
								return;								
							} // end if
							// If executed services (status = 4)
							else if (data.status.includes(4) || data.status.includes(5)) {
								dispatch(setExecutedServiceOrders(response.data.services));
								return;								
							} // end if
							// If Cancelled services (status = 99)
							else if (data.status == 99) {
								dispatch(setCancelledServiceOrders(response.data.services));
								return;								
							} // end if							

					} // end if
					
					// If any other error, what do do???
					// ...
						
					}, (error) => {											
						dispatch(setButtonLoading(false));
						props.setDialogMessage('Ocorreu um erro de resposta do servidor. Tente novamente.');
						props.setAlert(true);							
						return;
											
					})
					.catch(error=> {							
						dispatch(setButtonLoading(false));
						props.setDialogMessage('Ocorreu um erro geral ao se comunicar com a API. Verifique sua conexão e tente novamente.');
						props.setAlert(true);									
						return;
					});

		}
	
}

export const retrieveServiceOrder = (data,props)  => {
	
		return dispatch => {
		
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = localStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
				// decode JWT 
				var decoded = jwtDecode(userObj.userToken);
				
				// Set CustomerUserId
				const CustomerUserId = decoded.CustomerUserId;
	
					// Prepare for user creation
					const preparedData = {
						serviceOrderId:data.serviceOrderId,
						status:data.status ? data.status : [0]
					};	
					
					// Connect to CREATE QUOTATION Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/service/order/retrieve',
						   method : 'POST',
						   data: preparedData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})				
					
					.then((response) => {
							
					// If an error occured. Check error is blocking or non-blocking.
					if (response.data.error) {						
						dispatch(setButtonLoading(false));
						props.setDialogMessage('Ocorreu um erro ao buscar seus serviços agendados. Tente novamente. [1]');		
						props.setAlert(true);							
						return;					
					} // end if	*/
			
					if (response.data.success) {
						
						dispatch(setButtonLoading(false));

						// If sat least one result
						if (response.data.service) {

							// add little delay for UX flow
							/*setTimeout(function() {							
								props.setContentState(true);							
							},100);*/	

							// If Schedules services (status = 0)
							if (data.status.includes(0)) {								
								dispatch(setSelectedScheduledServiceOrder(response.data.service));
								props.setContentState(true);
								return;
							} // end if
							// If Ongoing services (status = 1 || 2 || 3)
							else if (data.status.includes(1) || data.status.includes(2) || data.status.includes(3)) {
								dispatch(setSelectedOngoingServiceOrder(response.data.service));
								props.setContentState(true);
								return;								
							} // end if
							// If executed services (status = 4)
							else if (data.status.includes(4) || data.status.includes(5)) {
								dispatch(setSelectedExecutedServiceOrder(response.data.service));
								props.setContentState(true);
								return;								
							} // end if
							// If Cancelled services (status = 99)
							else if (data.status == 99) {
								dispatch(setSelectedCancelledServiceOrder(response.data.service));
								props.setContentState(true);
								return;								
							} // end if									
							
						} // end if
						else {
							
							// add little delay for UX flow
							setTimeout(function() {							
								props.setContentNotFoundVisible(true);							
							},100);							
							
							dispatch(setButtonLoading(false));
							props.setDialogMessage('Não foi possível encontrar o serviço selecionado.');
							props.setAlert(true);							
							return;							
							
						} // end else
							
					} // end if
					
					// If any other error, what do do???
					// ...
						
					}, (error) => {											
						dispatch(setButtonLoading(false));
						props.setDialogMessage('Ocorreu um erro de resposta do servidor. Tente novamente.');
						props.setAlert(true);							
						return;
											
					})
					.catch(error=> {							
						dispatch(setButtonLoading(false));
						props.setDialogMessage('Ocorreu um erro geral ao se comunicar com a API. Verifique sua conexão e tente novamente.');
						props.setAlert(true);									
						return;
					});

		}
	
}

export const refreshServiceOrder = (data)  => {
	
		return dispatch => {
		
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = localStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
				// decode JWT 
				var decoded = jwtDecode(userObj.userToken);
				
				// Set CustomerUserId
				const CustomerUserId = decoded.CustomerUserId;
	
					// Prepare for user creation
					const preparedData = {
						serviceOrderId:data.serviceOrderId,
						status:data.status ? data.status : [0]
					};	
					
					// Connect to CREATE QUOTATION Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/service/order/retrieve',
						   method : 'POST',
						   data: preparedData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})				
					
					.then((response) => {
							
					// If an error occured. Check error is blocking or non-blocking.
					if (response.data.error) {						
						console.log('Ocorreu um erro ao buscar seus serviços agendados. Tente novamente. [1]');		
						return;					
					} // end if	*/
			
					if (response.data.success) {
						
						// If sat least one result
						if (response.data.service) {
							
							// If Schedules services (status = 0)
							if (data.status.includes(0)) {								
								dispatch(setSelectedScheduledServiceOrder(response.data.service));
								return;
							} // end if
							// If Ongoing services (status = 1 || 2 || 3)
							else if (data.status.includes(1) || data.status.includes(2) || data.status.includes(3)) {
								dispatch(setSelectedOngoingServiceOrder(response.data.service));
								return;								
							} // end if
							// If executed services (status = 4)
							else if (data.status.includes(4) || data.status.includes(5)) {
								dispatch(setSelectedExecutedServiceOrder(response.data.service));
								return;								
							} // end if
							// If Cancelled services (status = 99)
							else if (data.status == 99) {
								dispatch(setSelectedCancelledServiceOrder(response.data.service));
								return;								
							} // end if									
							
						} // end if
						else {
							
							console.log('Não foi possível encontrar o serviço selecionado.');					
							return;							
							
						} // end else
							
					} // end if
					
					// If any other error, what do do???
					// ...
						
					}, (error) => {											
						console.log('Ocorreu um erro de resposta do servidor. Tente novamente.');
						return;
											
					})
					.catch(error=> {							
						console.log('Ocorreu um erro geral ao se comunicar com a API. Verifique sua conexão e tente novamente.');
						return;
					});

		}
	
}

export const cancelScheduledService = (data,props)  => {
	
		return dispatch => {
		
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = localStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
				// decode JWT 
				var decoded = jwtDecode(userObj.userToken);
				
				// Set CustomerUserId
				const CustomerUserId = decoded.CustomerUserId;
	
					// Prepare for user creation
					const preparedData = {
						serviceOrderId:data.ServiceOrderId,
						cancelReason:data.cancelReason,
						cancelTax:data.cancelTax
					};	 
		
					// Connect to CREATE QUOTATION Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/service/order/cancel',
						   method : 'PUT',
						   data: preparedData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})				
					
					.then((response) => {
							
						// If an error occured. Check error is blocking or non-blocking.
						if (response.data.error) {						
							props.setLoadingCancel(false);
							props.setDialogTitle("Ops! algo deu errado :-(");
							props.setDialogMessage('Ocorreu um erro ao tentar cancelar o serviço agendado. Tente novamente. [1]');		
							props.setAlert(true);							
							return;					
						} // end if	*/
				
						if (response.data.success) {
						
							if (data.cancelTax) {
								props.setLoadingCancel(false);
								props.setDialogAction(true);
								props.setDialogTitle("Tudo certo!");
								props.setDialogMessage('O serviço agendado foi cancelado com sucesso! O profissional foi notificado e a fatura de cobran?a cancelada. A taxa de cancelamento ser? cobrada no seu cartão de crédito, conforme regras.');
								props.setAlert(true);
								props.setDialog('cancelservice',false);
								return;
							} // end if
							else {							
								props.setLoadingCancel(false);
								props.setDialogAction(true);
								props.setDialogTitle("Tudo certo!");
								props.setDialogMessage('O serviço agendado foi cancelado com sucesso! O profissional foi notificado e a fatura de cobran?a cancelada.');
								props.setAlert(true);
								props.setDialog('cancelservice',false);								
								return;								
							} // end else 
							
						} // end if
						
					}, (error) => {											
						props.setLoadingCancel(false);
						props.setDialogTitle("Ops! algo deu errado :-(");
						props.setDialogMessage('Ocorreu um erro de resposta do servidor. Tente novamente.');
						props.setAlert(true);							
						return;
											
					})
					.catch(error=> {							
						props.setLoadingCancel(false);
						props.setDialogTitle("Ops! algo deu errado :-(");
						props.setDialogMessage('Ocorreu um erro geral ao se comunicar com a API. Verifique sua conexão e tente novamente.');
						props.setAlert(true);									
						return;
					});
		}
}

export const cancelOngoingService = (data,props)  => {
	
		return dispatch => {
		
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = localStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
				// decode JWT 
				var decoded = jwtDecode(userObj.userToken);
				
				// Set CustomerUserId
				const CustomerUserId = decoded.CustomerUserId;
	
					// Prepare for user creation
					const preparedData = {
						serviceOrderId:data.ServiceOrderId,
						cancelReason:data.cancelReason,
						cancelTax:data.cancelTax
					};	 
		
					// Connect to CREATE QUOTATION Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/service/order/cancel',
						   method : 'PUT',
						   data: preparedData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})				
					
					.then((response) => {
							
						// If an error occured. Check error is blocking or non-blocking.
						if (response.data.error) {						
							props.setLoadingCancel(false);
							props.setDialogTitle("Ops! algo deu errado :-(");
							props.setDialogMessage('Ocorreu um erro ao tentar cancelar o serviço em andamento. Tente novamente. [1]');		
							props.setAlert(true);							
							return;					
						} // end if	*/
				
						if (response.data.success) {
						
							if (data.cancelTax) {
								props.setLoadingCancel(false);
								props.setDialogAction(true);
								props.setDialogTitle("Tudo certo!");
								props.setDialogMessage('O serviço em andamento foi cancelado com sucesso! O profissional foi notificado e a fatura de cobran?a cancelada. A taxa de cancelamento ser? cobrada no seu cartão de crédito, conforme regras.');
								props.setAlert(true);
								props.setDialog('cancelservice',false);
								return;
							} // end if
							else {							
								props.setLoadingCancel(false);
								props.setDialogAction(true);
								props.setDialogTitle("Tudo certo!");
								props.setDialogMessage('O serviço em andamento foi cancelado com sucesso! O profissional foi notificado e a fatura de cobran?a cancelada.');
								props.setAlert(true);
								props.setDialog('cancelservice',false);								
								return;								
							} // end else 
							
						} // end if
						
					}, (error) => {											
						props.setLoadingCancel(false);
						props.setDialogTitle("Ops! algo deu errado :-(");
						props.setDialogMessage('Ocorreu um erro de resposta do servidor. Tente novamente.');
						props.setAlert(true);							
						return;
											
					})
					.catch(error=> {							
						props.setLoadingCancel(false);
						props.setDialogTitle("Ops! algo deu errado :-(");
						props.setDialogMessage('Ocorreu um erro geral ao se comunicar com a API. Verifique sua conexão e tente novamente.');
						props.setAlert(true);									
						return;
					});
		}
}

export const finishOngoingService = (data,props)  => {
	
		return dispatch => {
		
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = localStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
				// decode JWT 
				var decoded = jwtDecode(userObj.userToken);
				
				// Set CustomerUserId
				const CustomerUserId = decoded.CustomerUserId;
	
					// Prepare for user creation
					const preparedData = {
						serviceOrderId:data.ServiceOrderId,
						finishReason:data.finishReason,
						acceptProblem:data.acceptProblem ? true : false
					};	 
		
					// Connect to CREATE QUOTATION Endpoint
					
					if (data.acceptProblem) {
						var endpoint = '/api/v1/service/order/finishWithIssue'
					} // end if
					else {
						var endpoint = '/api/v1/service/order/finish'
					} // end else
					
					axios({
						   url    : config.API_URL+endpoint,
						   method : 'POST',
						   data: preparedData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})
					.then((response) => {
							
						// If an error occured. Check error is blocking or non-blocking.
						if (response.data.error) {						
							props.setLoadingFinish(false);
							props.setDialogTitle("Ops! algo deu errado :-(");
							props.setDialogMessage('Ocorreu um erro ao tentar encerrar o serviço em andamento. Tente novamente. [1]');		
							props.setAlert(true);							
							return;					
						} // end if	*/
				
						if (response.data.success) {
						
							if (!data.acceptProblem) {
								props.setLoadingFinish(false);
								props.setDialogAction(true);
								props.setDialogActionUrl("executedServices");
								props.setDialogTitle("Tudo certo!");
								props.setDialogMessage('O serviço em andamento foi encerado com sucesso! O profissional foi notificado e a cobran?a do serviço foi finalizada.');
								props.setAlert(true);
								props.setDialog('finishservice',false);
								return;
							} // end if
							else {							
								props.setLoadingFinish(false);
								props.setDialogAction(true);
								props.setDialogActionUrl("executedServices");
								props.setDialogTitle("Tudo certo!");
								props.setDialogMessage('O serviço em andamento foi encerrado! O profissional foi notificado e o serviço está em disputa pois você definiu que teve problemas. Nenhum pagamento ser? realizado até que o caso seja analisado.');
								props.setAlert(true);
								props.setDialog('finishservice',false);								
								return;					
							} // end else 
							
						} // end if
						
					}, (error) => {											
						props.setLoadingFinish(false);
						props.setDialogTitle("Ops! algo deu errado :-(");
						props.setDialogMessage('Ocorreu um erro de resposta do servidor. Tente novamente.');
						props.setAlert(true);							
						return;
											
					})
					.catch(error=> {							
						props.setLoadingFinish(false);
						props.setDialogTitle("Ops! algo deu errado :-(");
						props.setDialogMessage('Ocorreu um erro geral ao se comunicar com a API. Verifique sua conexão e tente novamente.');
						props.setAlert(true);									
						return;
					});
		}
}

export const updateScheduledService = (data,props)  => {
	
		return dispatch => {
		
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = localStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
				// decode JWT 
				var decoded = jwtDecode(userObj.userToken);
				
				// Set CustomerUserId
				const CustomerUserId = decoded.CustomerUserId;
	
					// Prepare for user creation
					const preparedData = {
						serviceOrderId:data.ServiceOrderId,
						updateReason:data.updateReason,
						updateTax:data.updateTax,
						scheduledDate:data.selectedDate,
						scheduledPeriod:data.selectedPeriod
					};	 
					// Connect to CREATE QUOTATION Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/service/order/update',
						   method : 'PUT',
						   data: preparedData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})				
					
					.then((response) => {
							
						// If an error occured. Check error is blocking or non-blocking.
						if (response.data.error) {						
							props.setLoadingUpdate(false);
							props.setDialogTitle("Ops! algo deu errado :-(");
							props.setDialogMessage('Ocorreu um erro ao tentar alterar o serviço agendado. Tente novamente. [1]');		
							props.setAlert(true);							
							return;					
						} // end if	*/
				
						if (response.data.success) {
						
							// If Conflict, open confirma box to select other date OR cancel de service.
							if (response.data.conflict) {
								props.setLoadingUpdate(false);
								props.setDialogTitle("Ops! temos um problema :-(");
								props.setDialogMessage('Infelizmente o profissional não tem disponibilidade para a nova data e per?odo que você selecionou. Você pode tentar alterar para uma nova data ou cancelar o serviço agendado.');		
								props.setDialog('updateServicePrompt',true);
								return;								
							} // end if
						
							if (data.updateTax) {
								props.setLoadingUpdate(false);
								props.setDialogAction(true);
								props.setDialogTitle("Tudo certo!");
								props.setDialogMessage('O serviço agendado foi alterado com sucesso! O profissional foi notificado. A taxa de alteração ser? cobrada no seu cartão de crédito, conforme regras.');
								props.setAlert(true);
								props.setDialog('updateservice',false);
								return;
							} // end if
							else {							
								props.setLoadingUpdate(false);
								props.setDialogAction(true);
								props.setDialogTitle("Tudo certo!");
								props.setDialogMessage('O serviço agendado foi alterado com sucesso! O profissional foi notificado.');
								props.setAlert(true);
								props.setDialog('updateservice',false);								
								return;								
							} // end else 
							
						} // end if
						
					}, (error) => {											
						props.setLoadingUpdate(false);
						props.setDialogTitle("Ops! algo deu errado :-(");
						props.setDialogMessage('Ocorreu um erro de resposta do servidor. Tente novamente.');
						props.setAlert(true);							
						return;
											
					})
					.catch(error=> {							
						props.setLoadingUpdate(false);
						props.setDialogTitle("Ops! algo deu errado :-(");
						props.setDialogMessage('Ocorreu um erro geral ao se comunicar com a API. Verifique sua conexão e tente novamente.');
						props.setAlert(true);									
						return;
					});
		}
}
	
export const retrieveCategories = (data,props)  => {
	
		return dispatch => {
		
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = localStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
				// decode JWT 
				var decoded = jwtDecode(userObj.userToken);
				
				// Set CustomerUserId
				const CustomerUserId = decoded.CustomerUserId;
	
					// Prepare for user creation
					const preparedData = {
						parentId:data.parentId
					};	
					
					// Connect to CREATE QUOTATION Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/service/retrieveCategories',
						   method : 'POST',
						   data: preparedData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})				
					
					.then((response) => {
						

					// If an error occured. Check error is blocking or non-blocking.
					if (response.data.error) {						
						dispatch(setButtonLoading(false));
						props.setDialogMessage('Ocorreu um erro ao buscar as categorias. Tente novamente. [1]');		
						props.setAlert(true);							
						return;					
					} // end if	*/
			
					if (response.data.success) {
												
						dispatch(setButtonLoading(false));
						
						// add little delay for UX flow
						setTimeout(function() {							
							props.setContentState(true);							
						},100);

						
						// If sat least one result
						if (response.data.categoriesTree.length > 0) {
						
							dispatch(setCategories(response.data.categoriesTree));
							return;
							
						} // end if
							
					} // end if
					
					// If any other error, what do do???
					// ...
						
					}, (error) => {											
						dispatch(setButtonLoading(false));
						props.setDialogMessage('Ocorreu um erro de resposta do servidor. Tente novamente.');
						props.setAlert(true);							
						return;
											
					})
					.catch(error=> {							
						dispatch(setButtonLoading(false));
						props.setDialogMessage('Ocorreu um erro geral ao se comunicar com a API. Verifique sua conexão e tente novamente.');
						props.setAlert(true);									
						return;
					});

		}
	
}

export const retrieveHighlightService = (props)  => {
	
		return dispatch => {
		
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = localStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
				// decode JWT 
				var decoded = jwtDecode(userObj.userToken);
				
				// Set CustomerUserId
				const CustomerUserId = decoded.CustomerUserId;
	
	
					// Connect to CREATE QUOTATION Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/service/retrieveHighlight',
						   method : 'GET',
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})				
					
					.then((response) => {
						

					// If an error occured. Check error is blocking or non-blocking.
					if (response.data.error) {						
						props.setHighlightCard({});						
						return;					
					} // end if	*/
			
					if (response.data.success) {
												
						props.setHighlightCard(response.data.service);
							
					} // end if
					
					// If any other error, what do do???
					// ...
						
					}, (error) => {											
						props.setHighlightCard({});						
						return;				
					})
					.catch(error=> {							
						props.setHighlightCard({});						
						return;	
					});

		}
	
}

export const searchServicesByKeyword = (data,props)  => {
	
		return dispatch => {
		
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = localStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
				// decode JWT 
				var decoded = jwtDecode(userObj.userToken);
				
				// Set CustomerUserId
				const CustomerUserId = decoded.CustomerUserId;
	
					// Prepare for user creation
					const preparedData = {
						keywords:data.keywords
					};	
					
					// Connect to CREATE QUOTATION Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/service/searchServices',
						   method : 'POST',
						   data: preparedData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})				
					
					.then((response) => {

					// If an error occured. Check error is blocking or non-blocking.
					if (response.data.error) {						
						dispatch(setButtonLoading(false));
						props.setDialogMessage('Ocorreu um erro ao buscar serviços por palavra-chave. Tente novamente. [1]');		
						props.setAlert(true);							
						return;					
					} // end if	*/
			
					if (response.data.success) {
												
						dispatch(setButtonLoading(false));
						
						// add little delay for UX flow
						setTimeout(function() {							
							props.setSearchState(true);							
						},2000);
						
						// If sat least one result
						if (response.data.services.length > 0) {
						
							props.setSearchResult(response.data.services);						
							dispatch(setSearchResults(response.data.services));
							return;
							
						} // end if
							
					} // end if
					
					// If any other error, what do do???
					// ...
						
					}, (error) => {											
						dispatch(setButtonLoading(false));
						props.setDialogMessage('Ocorreu um erro de resposta do servidor. Tente novamente.');
						props.setAlert(true);							
						return;
											
					})
					.catch(error=> {							
						dispatch(setButtonLoading(false));
						props.setDialogMessage('Ocorreu um erro geral ao se comunicar com a API. Verifique sua conexão e tente novamente.');
						props.setAlert(true);									
						return;
					});

		}
	
}

export const retrieveCategoryBreadCrumb = (data,props)  => {
	
		return dispatch => {
		
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = localStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
				// decode JWT 
				var decoded = jwtDecode(userObj.userToken);
				
				// Set CustomerUserId
				const CustomerUserId = decoded.CustomerUserId;
	
					// Prepare for user creation
					const preparedData = {
						categoryId:data.categoryId
					};	
					
					// Connect to CREATE QUOTATION Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/service/retriveCategoryBreadCrumb',
						   method : 'POST',
						   data: preparedData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})	
					.then((response) => {
							
					// If an error occured. Check error is blocking or non-blocking.
					if (response.data.error) {						
							dispatch(setCategoryBreadCrumb("Categoria"));		
					} // end if	*/
			
					if (response.data.success) {

							dispatch(setCategoryBreadCrumb(response.data.breadcrumb));		
							return;
							
					} // end if
					
					// If any other error, what do do???
					// ...
						
					}, (error) => {											
						dispatch(setCategoryBreadCrumb("Categoria"));						
						return;											
					})
					.catch(error=> {							
						dispatch(setCategoryBreadCrumb("Categoria"));								
						return;
					});

		}
	
}
	
export const saveAddress = (data,props)  => {
	
		return dispatch => {
		
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = localStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
				// decode JWT 
				var decoded = jwtDecode(userObj.userToken);
				
				// Set CustomerUserId
				const CustomerUserId = decoded.CustomerUserId;
	
					// Prepare for user creation
					const preparedData = {};	
					preparedData.label = data.label;
					preparedData.streetType = data.streetType;
					preparedData.streetName = data.streetName;
					preparedData.streetNumber = data.streetNumber;
					preparedData.streetComplement = data.streetComplement;
					preparedData.postalCode = data.postalCode;
					preparedData.suburb = data.suburb;
					preparedData.city = data.city;
					preparedData.state = data.state;
					preparedData.country = data.country;
					preparedData.fullAddress = data.fullAddress;
					preparedData.googleCoordinates = data.googleCoordinates;
					preparedData.googlePlaceId = data.googlePlaceId;						
					preparedData.mainAddress = data.mainAddress;
					
					
					// Connect to CREATE QUOTATION Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/user/address/create',
						   method : 'POST',
						   data: preparedData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})	
					.then((response) => {
							
					// If an error occured. Check error is blocking or non-blocking.
					if (response.data.error) {						

						// Alert error creating address
						props.setDialogMessage("Ocorreu um erro ao gravar o endereço. Tente novamente.[1]");
						props.setAlert(true);
						props.setSavingAddress(false);
						return;
		
					} // end if	*/
			
					if (response.data.success) {

							// Update Address Id
							props.setSelectedAddressId(response.data.address.id);
							
							// Add card to current cards aray
							var finalCards = [...props.addressesCards,response.data.address];
							props.setAddressesCard(finalCards);
							
							// send to redux
							dispatch(setAddressesCards(finalCards));
							
							// turn off loading button
							props.setSavingAddress(false);
							
							// Clean Screen to show new address
							props.setComplementNewAddress(false);
							props.setAddressesCatalog(true);
							props.cleanAddress();
							
							props.setDialogTitle("Cadastro finalizado!");
							props.setDialogMessage("Endereço cadastrado com sucesso. Selecione o endereço criado. Para gerenciar seus endereços entre em Catélogo de endereços no menu.");
							props.setAlert(true);
							return;
							
					} // end if

						
					}, (error) => {											
						
						// Alert error creating address
						props.setDialogMessage("Ocorreu um erro ao gravar o endereço. Tente novamente. [2]");
						props.setAlert(true);
						props.setSavingAddress(false);
						return;
											
					})
					.catch(error=> {							
						// Alert error creating address
						props.setDialogMessage("Ocorreu um erro ao gravar o endereço. Tente novamente. [3]");
						props.setAlert(true);
						props.setSavingAddress(false);
						return;
					});

		}
	
}
	
export const retrieveAddresses = (props)  => {
	
		return dispatch => {
		
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = localStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
				// decode JWT 
				var decoded = jwtDecode(userObj.userToken);
				
				// Set CustomerUserId
				const CustomerUserId = decoded.CustomerUserId;
	
					
					// Connect to CREATE QUOTATION Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/user/address/list',
						   method : 'GET',
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})	
					.then((response) => {
							
					// If an error occured. Check error is blocking or non-blocking.
					if (response.data.error) {						

						// Alert error creating address
						props.setDialogMessage("Ocorreu um erro ao buscar os endereços. Tente novamente.[1]");
						props.setAlert(true);
						props.setLoadingAddress(false);
						return;
		
					} // end if	*/
			
					if (response.data.success) {
							
							// Add card to current cards aray
							var finalCards = response.data.addresses;	

							if (finalCards.length > 0) {
								props.setAddressesCard(finalCards);
								props.setAddressesCatalog(true);
							} // end ids
							
							// send to redux
							dispatch(setAddressesCards(finalCards));
							
							// turn off loading button
							props.setLoadingAddress(false);

							return;
							
					} // end if

						
					}, (error) => {											
						
						// Alert error creating address
						props.setDialogMessage("Ocorreu um erro ao buscar os endereços. Tente novamente. [2]");
						props.setAlert(true);
						props.setLoadingAddress(false);
						return;
											
					})
					.catch(error=> {							
						// Alert error creating address
						props.setDialogMessage("Ocorreu um erro ao buscar os endereços. Tente novamente. [3]");
						props.setAlert(true);
						props.setLoadingAddress(false);
						return;
					});

		}
	
}
	
export const saveCard = (data,props)  => {
	
		return dispatch => {
		
				// If user selected to NOT STORE credit card, just update interface with new card for selection
				/*if(!data.storeCreditcard) {
			
					// Update Address Id
					var randomId = Math.floor(Math.random() * 1000);
														
					// Add card to current cards aray
					var currentCard = {
						id:randomId,
						holderName:data.creditcardHolder,
						referenceNumber:data.creditcardNumber,
						expirationDate:data.expirationDate,
						cardFlag:data.creditCardFlag,
						status:"1",
						mainCreditcard:"0",
						paymentType:"1",
						stored:"0",
						token:data.creditcardToken,
						PaymentGatewayPaymentMethodId:""
					}
					var finalCards = [...props.creditCards,currentCard];
					props.setCreditCard(finalCards);
					
					// send to redux
					dispatch(setCreditCards(finalCards));
					
					// Clean Screen to show new address
					props.setComplementNewCard(false);
					props.setCreditCardCatalog(true);
					
					props.setDialogTitle("Cadastro finalizado!");
					props.setDialogMessage("Cartão de crédito cadastrado com sucesso. Selecione o cartão criado. Ele s? ser? utilizado para essa solicitação.");
					props.setAlert(true);
					
					// turn off loading button
					props.setSavingCard(false);					
					
					return;
					
				} // end if*/
				
			// If user selected to STORE credit card
		
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = localStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
				// decode JWT 
				var decoded = jwtDecode(userObj.userToken);
				
				// Set CustomerUserId
				const CustomerUserId = decoded.CustomerUserId;
			
					// Prepare for user creation
					const preparedData = {};						
					preparedData.holderName = data.creditcardHolder;
					preparedData.referenceNumber = data.creditcardNumber;
					preparedData.expirationDate = data.expirationDate;
					preparedData.gatewayHash = data.creditcardToken;
					preparedData.cardFlag = data.creditCardFlag;
					preparedData.storeCreditcard = true;
					preparedData.stored = data.storeCreditcard;
					preparedData.mainCreditcard = false;	
					
					// Connect to CREATE QUOTATION Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/user/creditcard/create',
						   method : 'POST',
						   data: preparedData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})	
					.then((response) => {
							
					// If an error occured. Check error is blocking or non-blocking.
					if (response.data.error) {						

						// Alert error creating address
						props.setDialogMessage("Ocorreu um erro ao gravar o cartão de crédito. Tente novamente.[1]");
						props.setAlert(true);
						props.setSavingCard(false);
						return;
		
					} // end if	*/
			
					if (response.data.success) {

							// Add card to current cards aray
							var currentCard = {
								id:response.data.creditcard.id,
								holderName:data.creditcardHolder,
								referenceNumber:data.creditcardNumber,
								expirationDate:data.expirationDate,
								cardFlag:data.creditCardFlag,
								status:"1",
								mainCreditcard:"0",
								paymentType:"1",
								stored:data.storeCreditcard ? "1":"0",
								token:"",
								PaymentGatewayPaymentMethodId:response.data.creditcard.PaymentGatewayPaymentMethodId
							}
							var finalCards = [...props.creditCards,currentCard];
							props.setCreditCard(finalCards);
							
							// send to redux
							dispatch(setCreditCards(finalCards));

							// Clean Screen to show new address
							props.setComplementNewCard(false);
							props.setCreditCardCatalog(true);
							
							props.setDialogTitle("Cadastro finalizado!");
							props.setDialogMessage("Cartão de crédito cadastrado com sucesso. Selecione o cartão criado. O cartão está disponível para utilizar novamente, sem digitar os dados.");
							props.setAlert(true);
							
							// turn off loading button
							props.setSavingCard(false);
							
							return;		
							
					} // end if

						
					}, (error) => {											
						
						// Alert error creating address
						props.setDialogMessage("Ocorreu um erro ao gravar o cartão de crédito. Tente novamente. [2]");
						props.setAlert(true);
						props.setSavingCard(false);
						return;
											
					})
					.catch(error=> {							
						// Alert error creating address
						props.setDialogMessage("Ocorreu um erro ao gravar o cartão de crédito. Tente novamente. [3]");
						props.setAlert(true);
						props.setSavingCard(false);
						return;
					});

		}
	
}
	
export const retrieveCards = (props)  => {
	
		return dispatch => {
		
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = localStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
				// decode JWT 
				var decoded = jwtDecode(userObj.userToken);
				
				// Set CustomerUserId
				const CustomerUserId = decoded.CustomerUserId;
	
					// Connect to CREATE QUOTATION Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/user/creditcard/list',
						   method : 'GET',
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})	
					.then((response) => {
							
					// If an error occured. Check error is blocking or non-blocking.
					if (response.data.error) {						

						// Alert error creating address
						props.setDialogMessage("Ocorreu um erro ao buscar os cartões. Tente novamente.[1]");
						props.setAlert(true);
						props.setLoadingCards(false);
						return;
		
					} // end if	*/
			
					if (response.data.success) {			

							// Add card to current cards aray
							var receivedCards = response.data.creditcards;	
							
							// Only filter not stored credicards...cause stored is coming from database...
							var array = [...props.creditCards]; // clone
							var index = array.filter(item => item.stored == 0);	
							var finalCards = [...index,...receivedCards];
							
							if (finalCards.length > 0) {
								props.setCreditCard(finalCards);
								props.setCreditCardCatalog(true);
								dispatch(setCreditCards(finalCards));								
							} // end ids
							
							// turn off loading button
							props.setLoadingCards(false);

							return;
							
					} // end if

						
					}, (error) => {											
						
						// Alert error creating address
						props.setDialogMessage("Ocorreu um erro ao buscar os cartões. Tente novamente. [2]");
						props.setAlert(true);
						props.setLoadingCards(false);
						return;
											
					})
					.catch(error=> {	
						// Alert error creating address
						props.setDialogMessage("Ocorreu um erro ao buscar os cartões. Tente novamente. [3]");
						props.setAlert(true);
						props.setLoadingCards(false);
						return;
					});

		}
	
}

export const searchAddressByCep = (data,props) => {

		return dispatch => {
		
					// Connect to eexternal API
					axios({
						   url    : 'https://viacep.com.br/ws/'+data.postalCode+'/json/',
						   method : 'GET',
						   headers: {
							Accept: 'application/json'
						}
					})	
					.then((response) => {

							// Set address fields
							props.setStreet(response.data.logradouro);
							props.setSuburb(response.data.bairro);
							props.setCity(response.data.localidade);
							props.setState(response.data.uf);

							// turn off loading button
							props.setLoadingAddress(false);
							return;
						
					}, (error) => {											
						
						// Alert error creating address
						props.setDialogMessage("Ocorreu um erro ao buscar  o endereço com o CEP fornecido. Tente novamente. [2]");
						props.setAlert(true);
						props.setLoadingAddress(false);
						return;
											
					})
					.catch(error=> {	
						// Alert error creating address
						props.setDialogMessage("Ocorreu um erro ao buscar  o endereço com o CEP fornecido. Tente novamente. [3]");
						props.setAlert(true);
						props.setLoadingAddress(false);
						return;
					});

		}
	
}

export const saveInvoice = (data,props)  => {
	
		return dispatch => {
			
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = localStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
				// decode JWT 
				var decoded = jwtDecode(userObj.userToken);
				
				// Set CustomerUserId
				const CustomerUserId = decoded.CustomerUserId;
							
					// Prepare for user creation
					const preparedData = {};													
					preparedData.billingType =  data.invoiceType;
					preparedData.billingName = data.billingName;
					preparedData.billingDocument = data.billingDocument;
			
					// if selected to use same address as serviceSchedule
					if (data.selectedInvoice) {

						preparedData.id = data.hireServiceAddress.id;
						preparedData.streetType = data.hireServiceAddress.streetType;
						preparedData.streetName = data.hireServiceAddress.streetName;
						preparedData.streetNumber = data.hireServiceAddress.streetNumber;
						preparedData.streetComplement = data.hireServiceAddress.streetComplement;
						preparedData.postalCode = data.hireServiceAddress.postalCode;
						preparedData.suburb = data.hireServiceAddress.suburb;
						preparedData.city = data.hireServiceAddress.city;
						preparedData.state = data.hireServiceAddress.state;
						preparedData.fullAddress =	data.hireServiceAddress.fullAddress;
						
					} // end if
					else {
						
						// set Full Address
						var fullAddress = data.street+", "+data.streetNumber+", "+data.streetComplement+", "+data.city+", "+data.state+", "+data.suburb+", Brasil ";
						
						// Extract streeType
						var tmp = data.street.split(" ");
						preparedData.id = null;
						preparedData.streetType = tmp[0];
						preparedData.streetName = data.street;
						preparedData.streetNumber = data.streetNumber;
						preparedData.streetComplement = data.streetComplement;
						preparedData.postalCode = data.postalCode;
						preparedData.suburb = data.suburb;
						preparedData.city = data.city;
						preparedData.state = data.state;
						preparedData.fullAddress =	fullAddress;						
						
					} // end else
					
					// Connect to CREATE QUOTATION Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/user/billingdocument/create',
						   method : 'POST',
						   data: preparedData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})	
					.then((response) => {
							
					// If an error occured. Check error is blocking or non-blocking.
					if (response.data.error) {						

						// Alert error creating address
						props.setDialogMessage("Ocorreu um erro ao gravar o cartão de crédito. Tente novamente.[1]");
						props.setAlert(true);
						props.setSavingInvoice(false);
						return;
		
					} // end if	*/
			
			
					if (response.data.success) {

							// Add card to current cards aray
							var CustomerAddress = {
								id: response.data.CustomerAddressId,
								streetType: preparedData.streetType,
								streetName: preparedData.streetName,
								streetNumber: preparedData.streetNumber,
								streetComplement: preparedData.streetComplement,
								postalCode: preparedData.postalCode,
								suburb: preparedData.suburb,
								city: preparedData.city,
								state: preparedData.state,
								country:"BR",
								fullAddress: preparedData.fullAddress
							}
							var currentCard = {
								id:response.data.invoiceId,
								type: preparedData.billingType,
								billingName: preparedData.billingName,
								billingDocument: preparedData.billingDocument,
								CustomerAddressId: response.data.CustomerAddressId,
								CustomerAddress:CustomerAddress
							}
							
							// If updated, dont add to cards, just updated current card
							if (response.data.updated) {
															
								var array = [...props.invoices]; // clone
								var index = array.filter(item => item.id != response.data.invoiceId);
																
								var finalCards = [...index,currentCard];
								props.setInvoices(finalCards);
								
								// send to redux
								dispatch(setInvoices(finalCards));

								// Clean Screen to show new address
								props.setComplementNewInvoice(false);
								props.setInvoiceCatalog(true);
								
								props.setDialogTitle("Cadastro finalizado!");
								props.setDialogMessage("Dado de faturamento atualizado com sucesso. Selecione na lista o dado desejado.");
								props.setAlert(true);
								
								// turn off loading button
								props.setSavingInvoice(false);								
								
								return;
							} // end if
					
							
							// Add new card to list
							var finalCards = [...props.invoices,currentCard];
							props.setInvoices(finalCards);
							
							// send to redux
							dispatch(setInvoices(finalCards));

							// Clean Screen to show new address
							props.setComplementNewInvoice(false);
							props.setInvoiceCatalog(true);
							
							props.setDialogTitle("Cadastro finalizado!");
							props.setDialogMessage("Dados de faturamento cadastrado com sucesso. Selecione na lista a informa??o adicionada.");
							props.setAlert(true);
							
							// turn off loading button
							props.setSavingInvoice(false);
							
							return;		
							
					} // end if

						
					}, (error) => {											
						
						// Alert error creating address
						props.setDialogMessage("Ocorreu um erro ao gravar o cartão de crédito. Tente novamente. [2]");
						props.setAlert(true);
						props.setSavingInvoice(false);
						return;
											
					})
					.catch(error=> {
						
						// Alert error creating address
						props.setDialogMessage("Ocorreu um erro ao gravar o cartão de crédito. Tente novamente. [3]");
						props.setAlert(true);
						props.setSavingInvoice(false);
						return;
					});

		}
	
}
	
export const retrieveInvoices = (props)  => {
	
		return dispatch => {
		
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = localStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
				// decode JWT 
				var decoded = jwtDecode(userObj.userToken);
				
				// Set CustomerUserId
				const CustomerUserId = decoded.CustomerUserId;
	
					// Connect to CREATE QUOTATION Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/user/billingdocument/list',
						   method : 'GET',
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})	
					.then((response) => {
						
					// If an error occured. Check error is blocking or non-blocking.
					if (response.data.error) {						

						// Alert error creating address
						props.setDialogMessage("Ocorreu um erro ao buscar dados do faturamento. Tente novamente.[1]");
						props.setAlert(true);
						props.setLoadingInvoices(false);
						return;
		
					} // end if	*/
			
					if (response.data.success) {			

							// Add card to current cards aray
							var finalCards = response.data.invoices;

							if (finalCards.length > 0) {
								props.setInvoices(finalCards);
								props.setInvoiceCatalog(true);
								dispatch(setInvoices(finalCards));								
							} // end ids
							
							// turn off loading button
							props.setLoadingInvoices(false);

							return;
							
					} // end if

						
					}, (error) => {											
						
						// Alert error creating address
						props.setDialogMessage("Ocorreu um erro ao buscar dados do faturamento. Tente novamente. [2]");
						props.setAlert(true);
						props.setLoadingInvoices(false);
						return;
											
					})
					.catch(error=> {	
						// Alert error creating address
						props.setDialogMessage("Ocorreu um erro ao buscar dados do faturamento. Tente novamente. [3]");
						props.setAlert(true);
						props.setLoadingInvoices(false);
						return;
					});

		}
	
}

export const retrieveProviderLocation = (data,props)  => {
	
		return dispatch => {
		
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = localStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
				// decode JWT 
				var decoded = jwtDecode(userObj.userToken);
				
				// Set CustomerUserId
				const CustomerUserId = decoded.CustomerUserId;
	
					// Prepare for user creation
					const preparedData = {
						serviceOrderId:data.ServiceOrderId,
						ProviderUserId:data.ProviderUserId
					};	
					
					// Connect to CREATE QUOTATION Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/service/order/retrieveLocation',
						   method : 'POST',
						   data: preparedData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})				
					
					.then((response) => {
							
					// If an error occured. Check error is blocking or non-blocking.
					if (response.data.error) {						
						//props.setSnackMessage('Erro ao buscar localiza??o do profissional');		
						//props.setSnack(true);	
						return;					
					} // end if	*/

					if (response.data.success) {
						
						props.setCurrentLocation(response.data.currentLocation);
						props.setArriveEstimation(response.data.estimateArriveDate);
						return;
							
					} // end if
					
					// If any other error, what do do???
					// ...
						
					}, (error) => {	

						console.log("[error [1]: "+JSON.stringify(error.message));
					
						//alert("ERRO [1]");
						//props.setSnackMessage('Erro ao buscar localiza??o do profissional. API [1]');		
						//props.setSnack(true);							
						return;
											
					})
					.catch(error=> {
						
						console.log("[error [2]: "+JSON.stringify(error.message));
						
						//alert("ERRO [2]");
						//props.setSnackMessage('Erro ao buscar localiza??o do profissional. API [2]');		
						//props.setSnack(true);										
						return;
					});

		}
	
}
	
export const updateCustomerRatingServiceOrder = (data,props)  => {
	
		return dispatch => {
			

				// Check if there is a local USER OBEJECt with a TOKEN
				var result = localStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
				// decode JWT 
				var decoded = jwtDecode(userObj.userToken);
				
				// Set CustomerUserId
				const CustomerUserId = decoded.CustomerUserId;
								
					// prepare data to send
					var sendData = {
						serviceOrderId:data.ServiceOrderId,
						generalRating: data.generalRating,
						specificRating: data.specificRating
					} // end if
	
					// Connect to REGISTER Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/service/order/updateRating',
						   method : 'PUT',
						   data: sendData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})				
					
					.then((response) => {
							
							// If an error occured. Check error is blocking or non-blocking.
							if (response.data.error) {					
								props.setLoadingRating(false);
								props.setDialogMessage("Ocorreu um erro ao atualizar a qualifica??o. Tente novamente.[1]");
								props.setAlert(true);
								return;								
							} // end if	*/
														
							if (response.data.success) {
								
								// Turn button loading off
								props.setLoadingRating(false);

								props.setDialog('updateRatingProvider',false);

								props.setDialogTitle("Tudo certo!");
								props.setDialogMessage("Você alterou a qualifica??o do profissional referente a esse serviço. Caso queira alterar novamente, você pode faz?-la até o fim do dia de hoje.");
								props.setAlert(true); 
								
							 	// Set data and Retrieve service offfers FIRST TIME
							 	// Retrieve service order to refresh again on interface
								let dataParam = {
									status:[4,5],
									serviceOrderId:data.ServiceOrderId
								}								
								props.refreshServiceOrder(dataParam);			
											 												
							} // end if				
					
					
					}, (error) => {
						
						props.setLoadingRating(false);
						
						// verify if timeout happened
						var alertMessage = (error.code == "ECONNABORTED") ? 
						"Ocorreu um erro ao se comunicar com a API. Verifique se está com acesso a internet.":
						"Ocorreu um erro ao se comunicar com a API. Verifique e tente novamente.";					
						props.setDialogMessage(alertMessage);
						props.setAlert(true);
						return;				
					})
					.catch(error=> {
						
						props.setLoadingRating(false);
						props.setDialogMessage('Ocorreu um erro geral ao se comunicar com a API. Verifique sua conexão e tente novamente.');
						props.setAlert(true);
						return;									

					});
				
			
		
	} // end dispatch

}	
		
export const addCustomerRatingServiceOrder = (data,props)  => {
	
		return dispatch => {
			
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = localStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
				// decode JWT 
				var decoded = jwtDecode(userObj.userToken);
				
				// Set CustomerUserId
				const CustomerUserId = decoded.CustomerUserId;
					
					// prepare data to send
					var sendData = {
						serviceOrderId:data.ServiceOrderId,
						generalRating: data.generalRating,
						specificRating: data.specificRating
					} // end if
	
					// Connect to REGISTER Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/service/order/addRating',
						   method : 'POST',
						   data: sendData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})				
					
						.then((response) => {
							
							// If an error occured. Check error is blocking or non-blocking.
							if (response.data.error) {					
								props.setLoadingRating(false);
								props.setDialogMessage("Ocorreu um erro ao atualizar a qualifica??o. Tente novamente.[1]");
								props.setAlert(true);
								return;								
							} // end if	*/
														
							if (response.data.success) {
								
								// Turn button loading off
								props.setLoadingRating(false);

								props.setDialog('addRatingProvider',false);

								props.setDialogTitle("Tudo certo!");
								props.setDialogMessage("Você finalizou a qualifica??o do profissional referente a esse serviço. Caso queira alterar, você pode faz?-la até o fim do dia de hoje.");
								props.setAlert(true);
								props.setRatingEnabled(true); 
								
							 	// Retrieve service order to refresh again on interface
								let dataParam = {
									status:[4,5],
									serviceOrderId:data.ServiceOrderId
								}								
								props.refreshServiceOrder(dataParam);		
											 												
							} // end if				
					
					
					}, (error) => {
						
						props.setLoadingRating(false);
						
						// verify if timeout happened
						var alertMessage = (error.code == "ECONNABORTED") ? 
						"Ocorreu um erro ao se comunicar com a API. Verifique se está com acesso a internet.":
						"Ocorreu um erro ao se comunicar com a API. Verifique e tente novamente.";					
						props.setDialogMessage(alertMessage);
						props.setAlert(true);
						return;				
					})
					.catch(error=> {
						
						props.setLoadingRating(false);
						props.setDialogMessage('Ocorreu um erro geral ao se comunicar com a API. Verifique sua conexão e tente novamente.');
						props.setAlert(true);
						return;									

					});
				
				
		
	} // end dispatch

}	

export const createServiceComplaint = (data,props)  => {
	
		return dispatch => {
				
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = localStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
				// decode JWT 
				var decoded = jwtDecode(userObj.userToken);
				
				// Set CustomerUserId
				const CustomerUserId = decoded.CustomerUserId;
			
					// Prepare for user creation
					const preparedData = {};						
					preparedData.type = data.problemType;
					preparedData.categoryId = data.selectProblem;
					preparedData.subCategoryId = data.selectSubProblem;
					preparedData.title = data.title;
					preparedData.details = data.description;
					preparedData.actionToBeTaken = data.actionToTake;
					preparedData.serviceOrderId = data.serviceOrderId;

					// Connect to CREATE COMPLAINT Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/service/complaint/create',
						   method : 'POST',
						   data: preparedData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})	
					.then((response) => {
							
					// If an error occured. Check error is blocking or non-blocking.
					if (response.data.error) {						

						// Alert error creating address
						props.setLoading(false);
						props.setDialogTitle("Ops! Ocorreu um erro!");
						props.setDialogMessage("Ocorreu um erro ao criar sua solicitação. Tente novamente.[1]");
						props.setAlert(true);
						return;
		
					} // end if	*/
			
					if (response.data.success) {
							
							// set loading off
							props.setLoading(false);
							
							// Set redirect action true
							if (data.serviceOrderId) {
								props.setDialogAction(true);
							} // end if
							
							// Load data
							props.loadData();
							
							props.setDialogTitle("Pronto! tudo certo!");
							props.setDialogMessage("A sua solicitação foi criada com sucesso. Em breve estaremos analisando e retornando. Retorne a esse local para ver atualiza??es ou verifique seu email.");
							props.setAlert(true);
							
							return;		
							
					} // end if
						
					}, (error) => {											
						
						// Alert error creating address
						props.setLoading(false);
						props.setDialogTitle("Ops! Ocorreu um erro!");
						props.setDialogMessage("Ocorreu um erro ao criar sua solicitação. Tente novamente. [2]");
						props.setAlert(true);
						return;
											
					})
					.catch(error=> {							
						// Alert error creating address
						props.setLoading(false);
						props.setDialogMessage("Ocorreu um erro ao criar sua solicitação. Tente novamente. [3]");
						props.setAlert(true);
						return;
					});

		}
	
}

export const updateServiceComplaint = (data,props)  => {
	
		return dispatch => {
				
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = localStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
				// decode JWT 
				var decoded = jwtDecode(userObj.userToken);
				
				// Set CustomerUserId
				const CustomerUserId = decoded.CustomerUserId;
			
					// Prepare for user creation
					const preparedData = {};						
					preparedData.serviceOrderId = data.serviceOrderId;
					preparedData.serviceComplaintId = data.serviceComplaintId;
					preparedData.newStatus = 3;
					
					// Change URL depending of the received parameter
					if (data.serviceOrderId) {
						var url = config.API_URL+'/api/v1/service/complaint/update';
					} // end if
					else {
						var url = config.API_URL+'/api/v1/service/complaint/updateStatus';
					} // end else					

					axios({
						   url    : url,
						   method : 'PUT',
						   data: preparedData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})	
					.then((response) => {
							
					// If an error occured. Check error is blocking or non-blocking.
					if (response.data.error) {						

						// Alert error creating address
						props.setLoading(false);
						props.setDialogTitle("Ops! Ocorreu um erro!");
						props.setDialogMessage("Ocorreu um erro ao atualizar sua solicitação. Tente novamente.[1]");
						props.setAlert(true);
						return;
		
					} // end if	*/
			
					if (response.data.success) {
							
							// set loading off
							props.setLoading(false);
							
							// relaod data
							props.loadData();
							
							// Set redirect action true
							props.setDialogAction(true);
							
							props.setDialogTitle("Pronto! tudo certo!");
							props.setDialogMessage("A sua solicitação foi encerrada com sucesso. Caso queira voltar ao assunto abra outra solicitação.");
							props.setAlert(true);
							
							return;		
							
					} // end if
						
					}, (error) => {											
						
						// Alert error creating address
						props.setLoading(false);
						props.setDialogTitle("Ops! Ocorreu um erro!");
						props.setDialogMessage("Ocorreu um erro ao atualizar sua solicitação. Tente novamente. [2]");
						props.setAlert(true);
						return;
											
					})
					.catch(error=> {							
						// Alert error creating address
						props.setLoading(false);
						props.setDialogMessage("Ocorreu um erro ao atualizar sua solicitação. Tente novamente. [3]");
						props.setAlert(true);
						return;
					});

		}
	
}

export const createServiceComplaintReply = (data,props)  => {
	
		return dispatch => {
				
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = localStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
				// decode JWT 
				var decoded = jwtDecode(userObj.userToken);
				
				// Set CustomerUserId
				const CustomerUserId = decoded.CustomerUserId;
			
					// Prepare for user creation
					const preparedData = {};						
					preparedData.serviceComplaintCommentId = data.serviceComplaintCommentId;
					preparedData.serviceComplaintId = data.serviceComplaintId;
					preparedData.reply = data.comment;

					axios({
						   url    : config.API_URL+'/api/v1/service/complaint/reply',
						   method : 'PUT',
						   data: preparedData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})	
					.then((response) => {
							
					// If an error occured. Check error is blocking or non-blocking.
					if (response.data.error) {						

						// Alert error creating address
						props.setLoading(false);
						props.setDialogTitle("Ops! Ocorreu um erro!");
						props.setDialogMessage("Ocorreu um erro ao gravar seu coment?rio ou resposta. Tente novamente.[1]");
						props.setAlert(true);
						return;
		
					} // end if	*/
			
					if (response.data.success) {
						
							// If its closed
							if (response.data.closedComplaint) {
								props.setLoading(false);
									props.setDialogTitle("Ops! Ocorreu um probleminha!");
									props.setDialogMessage("Não foi possível adicionar um coment?rio ou resposta pois a sua solicitação está fechada. Você pode abrir outra, se necess?rio.");
									props.setAlert(true);
								return;	
							} // end if
						
							
							// set loading off
							props.setLoading(false);
							
							// Set redirect action true
							//props.setDialogAction(true);
							
							// Clean comment form
							props.setComment("");							
							
							// reload data
							props.loadData();
							
							props.setDialogTitle("Pronto! tudo certo!");
							props.setDialogMessage("O seu coment?rio ou resposta foi inserido com sucesso.");
							props.setAlert(true);
							
							return;		
							
					} // end if
						
					}, (error) => {											
						
						// Alert error creating address
						props.setLoading(false);
						props.setDialogTitle("Ops! Ocorreu um erro!");
						props.setDialogMessage("Ocorreu um erro ao gravar seu coment?rio ou resposta. Tente novamente. [2]");
						props.setAlert(true);
						return;
											
					})
					.catch(error=> {							
						// Alert error creating address
						props.setLoading(false);
						props.setDialogMessage("Ocorreu um erro ao gravar seu coment?rio ou resposta. Tente novamente. [3]");
						props.setAlert(true);
						return;
					});

		}
	
}
	
export const retrieveServiceComplaints = (data,props)  => {
	
		return dispatch => {
			
					// Check if there is a local USER OBEJECt with a TOKEN
					var result = localStorage.getItem("@motuum/userData", undefined,true);
					var userObj = JSON.parse(result);  
					
					// decode JWT 
					var decoded = jwtDecode(userObj.userToken);
					
					// Set CustomerUserId
					const CustomerUserId = decoded.CustomerUserId;
					
					// Set data
					var preparedData = {
						serviceOrderId:data.serviceOrderId
					}	
	
					// Connect to CREATE QUOTATION Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/service/complaint/list',
						   method : 'POST',
						   data:preparedData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})	
					.then((response) => {
						
					// If an error occured. Check error is blocking or non-blocking.
					if (response.data.error) {	
						props.setLoading(false);					
						props.setReportProblem({});						
						return;					
					} // end if	*/
			
					if (response.data.success) {

						// set false if any results
						if (response.data.complaints.length > 0) {
							props.setNewReport(false);
						} // end if
												
						props.setReportProblem(response.data.complaints);
						
						// set loading off
						props.setLoading(false);						
							
					} // end if
					
					// If any other error, what do do???
					// ...
						
					}, (error) => {
						props.setLoading(false);						
						props.setReportProblem([]);						
						return;				
					})
					.catch(error=> {
						props.setLoading(false);						
						props.setReportProblem([]);						
						return;	
					});

		}
	
}
	
export const retrieveServiceComplaint = (data,props)  => {
	
		return dispatch => {
			
					// Check if there is a local USER OBEJECt with a TOKEN
					var result = localStorage.getItem("@motuum/userData", undefined,true);
					var userObj = JSON.parse(result);  
					
					// decode JWT 
					var decoded = jwtDecode(userObj.userToken);
					
					// Set CustomerUserId
					const CustomerUserId = decoded.CustomerUserId;
					
					// Set data
					var preparedData = {
						serviceComplaintId:data.serviceComplaintId
					}	
	
					// Connect to CREATE QUOTATION Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/service/complaint/retrieve',
						   method : 'POST',
						   data:preparedData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})	
					.then((response) => {
						
	
					// If an error occured. Check error is blocking or non-blocking.
					if (response.data.error) {	
						props.setLoading(false);					
						props.setReportProblem({});						
						return;					
					} // end if	*/
			
					if (response.data.success) {
															
						props.setReportProblem(response.data.complaint);
						
						// set loading off
						props.setLoading(false);						
							
					} // end if
					
					// If any other error, what do do???
					// ...
						
					}, (error) => {
						props.setLoading(false);						
						props.setReportProblem([]);						
						return;				
					})
					.catch(error=> {
						props.setLoading(false);						
						props.setReportProblem([]);						
						return;	
					});

		}
	
}
	
export const retrieveComplaintCategories = (data,props)  => {
	
		return dispatch => {
			
					// Check if there is a local USER OBEJECt with a TOKEN
					var result = localStorage.getItem("@motuum/userData", undefined,true);
					var userObj = JSON.parse(result);  
					
					// decode JWT 
					var decoded = jwtDecode(userObj.userToken);
					
					// Set CustomerUserId
					const CustomerUserId = decoded.CustomerUserId;
					
					// Set data
					var preparedData = {
						categoryId:"0"
					}	
	
					// Connect to CREATE QUOTATION Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/service/complaint/category/list',
						   method : 'POST',
						   data:preparedData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})	
					.then((response) => {
						
					// If an error occured. Check error is blocking or non-blocking.
					if (response.data.error) {						
						props.setProblemCategories({});						
						return;					
					} // end if	*/
			
					if (response.data.success) {
												
						props.setProblemCategories(response.data.categories);
							
					} // end if
					
					// If any other error, what do do???
					// ...
						
					}, (error) => {											
						props.setProblemCategories({});						
						return;				
					})
					.catch(error=> {							
						props.setProblemCategories({});						
						return;	
					});

		}
	
}

export const retrieveComplaintSubCategories = (data,props)  => {
	
		return dispatch => {
			
					// Check if there is a local USER OBEJECt with a TOKEN
					var result = localStorage.getItem("@motuum/userData", undefined,true);
					var userObj = JSON.parse(result);  
					
					// decode JWT 
					var decoded = jwtDecode(userObj.userToken);
					
					// Set CustomerUserId
					const CustomerUserId = decoded.CustomerUserId;
					
					// Set data
					var preparedData = {
						categoryId:data.categoryId
					}	
	
					// Connect to CREATE QUOTATION Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/service/complaint/category/list',
						   method : 'POST',
						   data:preparedData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})	
					.then((response) => {
						
					// If an error occured. Check error is blocking or non-blocking.
					if (response.data.error) {						
						props.setProblemSubCategories({});						
						return;					
					} // end if	*/
			
					if (response.data.success) {
												
						props.setProblemSubCategories(response.data.categories);
							
					} // end if
					
					// If any other error, what do do???
					// ...
						
					}, (error) => {											
						props.setProblemSubCategories({});						
						return;				
					})
					.catch(error=> {							
						props.setProblemSubCategories({});						
						return;	
					});

		}
	
}

export const createTicket = (data,props)  => {
	
		return dispatch => {
				
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = localStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
				// decode JWT 
				var decoded = jwtDecode(userObj.userToken);
				
				// Set CustomerUserId
				const CustomerUserId = decoded.CustomerUserId;
			
					// Prepare for user creation
					const preparedData = {};						
					preparedData.name = data.name;
					preparedData.email = data.email;
					preparedData.subject = data.subject;
					preparedData.description = data.description;
					preparedData.priority = data.priority;					

					// Connect to CREATE TICKET Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/user/ticket/create',
						   method : 'POST',
						   data: preparedData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})	
					.then((response) => {
							
					// If an error occured. Check error is blocking or non-blocking.
					if (response.data.error) {						

						// Alert error creating address
						props.setLoading(false);
						props.setDialogTitle("Ops! Ocorreu um erro!");
						props.setDialogMessage("Ocorreu um erro ao criar seu chamado. Tente novamente.[1]");
						props.setAlert(true);
						return;
		
					} // end if	*/
			
					if (response.data.success) {
							
							// set loading off
							props.setLoading(false);
							
							// Load data
							props.loadData();
							
							props.setDialogTitle("Pronto! tudo certo!");
							props.setDialogMessage("O seu chamado foi criado com sucesso. Você receberá um email quando respondermos e poderá interagir diretamente por email.");
							props.setAlert(true);
							
							return;		
							
					} // end if
						
					}, (error) => {											
						
						// Alert error creating address
						props.setLoading(false);
						props.setDialogTitle("Ops! Ocorreu um erro!");
						props.setDialogMessage("Ocorreu um erro ao criar seu chamado. Tente novamente. [2]");
						props.setAlert(true);
						return;
											
					})
					.catch(error=> {							
						// Alert error creating address
						props.setLoading(false);
						props.setDialogMessage("Ocorreu um erro ao criar seu chamado. Tente novamente. [3]");
						props.setAlert(true);
						return;
					});

		}
	
}

export const updateTicket = (data,props)  => {
	
		return dispatch => {
				
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = localStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
				// decode JWT 
				var decoded = jwtDecode(userObj.userToken);
				
				// Set CustomerUserId
				const CustomerUserId = decoded.CustomerUserId;
			
					// Prepare for user creation
					const preparedData = {};						
					preparedData.ticketId = data.ticketId;
					preparedData.newStatus = data.newStatus;

					axios({
						   url    : config.API_URL+'/api/v1/user/ticket/update',
						   method : 'PUT',
						   data: preparedData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})	
					.then((response) => {
							
					// If an error occured. Check error is blocking or non-blocking.
					if (response.data.error) {						

						// Alert error creating address
						props.setLoading(false);
						props.setDialogTitle("Ops! Ocorreu um erro!");
						props.setDialogMessage("Ocorreu um erro ao atualizar seu chamado. Tente novamente.[1]");
						props.setAlert(true);
						return;
		
					} // end if	*/
			
					if (response.data.success) {
							
							// set loading off
							props.setLoading(false);
							
							// relaod data
							props.loadData();
							
							// Set redirect action true
							props.setDialogAction(true);
							
							props.setDialogTitle("Pronto! tudo certo!");
							props.setDialogMessage("O seu chamado foi encerrado com sucesso. Caso queira reabrir envie uma nova resposta via email.");
							props.setAlert(true);
							
							return;		
							
					} // end if
						
					}, (error) => {											
						
						// Alert error creating address
						props.setLoading(false);
						props.setDialogTitle("Ops! Ocorreu um erro!");
						props.setDialogMessage("Ocorreu um erro ao atualizar seu chamado. Tente novamente. [2]");
						props.setAlert(true);
						return;
											
					})
					.catch(error=> {							
						// Alert error creating address
						props.setLoading(false);
						props.setDialogMessage("Ocorreu um erro ao atualizar seu chamado. Tente novamente. [3]");
						props.setAlert(true);
						return;
					});

		}
	
}

export const createTicketNote = (data,props)  => {
	
		return dispatch => {
				
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = localStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
				// decode JWT 
				var decoded = jwtDecode(userObj.userToken);
				
				// Set CustomerUserId
				const CustomerUserId = decoded.CustomerUserId;
			
					// Prepare for user creation
					const preparedData = {};						
					preparedData.ticketId = data.ticketId;
					preparedData.comment = data.comment;
					preparedData.user_id = data.user_id;

					axios({
						   url    : config.API_URL+'/api/v1/user/ticket/note',
						   method : 'PUT',
						   data: preparedData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})	
					.then((response) => {
							
					// If an error occured. Check error is blocking or non-blocking.
					if (response.data.error) {						

						// Alert error creating address
						props.setLoading(false);
						props.setDialogTitle("Ops! Ocorreu um erro!");
						props.setDialogMessage("Ocorreu um erro ao adicionar um coment?rio. Tente novamente.[1]");
						props.setAlert(true);
						return;
		
					} // end if	*/
			
					if (response.data.success) {
							
							// set loading off
							props.setLoading(false);
							
							// relaod data
							props.loadData();
							
							// clean comment fieldI
							props.setComment("");
													
							props.setDialogTitle("Pronto! tudo certo!");
							props.setDialogMessage("O seu coment?rio foi adicionado ao chamado com sucesso.");
							props.setAlert(true);
							return;		
							
					} // end if
						
					}, (error) => {											
						
						// Alert error creating address
						props.setLoading(false);
						props.setDialogTitle("Ops! Ocorreu um erro!");
						props.setDialogMessage("Ocorreu um erro ao adicionar um coment?rio. Tente novamente. [2]");
						props.setAlert(true);
						return;
											
					})
					.catch(error=> {							
						// Alert error creating address
						props.setLoading(false);
						props.setDialogMessage("Ocorreu um erro ao adicionar um coment?rioo. Tente novamente. [3]");
						props.setAlert(true);
						return;
					});

		}
	
}

export const retrieveTickets = (data,props)  => {
	
		return dispatch => {
			
					// Check if there is a local USER OBEJECt with a TOKEN
					var result = localStorage.getItem("@motuum/userData", undefined,true);
					var userObj = JSON.parse(result);  
					
					// decode JWT 
					var decoded = jwtDecode(userObj.userToken);
					
					// Set CustomerUserId
					const CustomerUserId = decoded.CustomerUserId;
					
					// Set data
					var preparedData = {
						FreshDeskRequesterId:data.FreshDeskRequesterId
					}	
	
					// Connect to CREATE QUOTATION Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/user/ticket/list',
						   method : 'POST',
						   data:preparedData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})	
					.then((response) => {
 
					

					// If an error occured. Check error is blocking or non-blocking.
					if (response.data.error) {	
						props.setLoading(false);					
						props.setTickets([]);						
						return;					
					} // end if	*/
			
					if (response.data.success) {

						// set false if any results
						if (response.data.tickets.length > 0) {
							props.setNewTicket(false);
						} // end if
												
						props.setTickets(response.data.tickets);
						
						// set loading off
						props.setLoading(false);						
							
					} // end if
					
					// If any other error, what do do???
					// ...
						
					}, (error) => {
						props.setLoading(false);						
						props.setTickets([]);						
						return;				
					})
					.catch(error=> {
						props.setLoading(false);						
						props.setTickets([]);						
						return;	
					});

		}
	
}
	
export const retrieveTicket = (data,props)  => {
	
		return dispatch => {
			
					// Check if there is a local USER OBEJECt with a TOKEN
					var result = localStorage.getItem("@motuum/userData", undefined,true);
					var userObj = JSON.parse(result);  
					
					// decode JWT 
					var decoded = jwtDecode(userObj.userToken);
					
					// Set CustomerUserId
					const CustomerUserId = decoded.CustomerUserId;
					
					// Set data
					var preparedData = {
						ticketId:data.ticketId
					}	
	
					// Connect to CREATE QUOTATION Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/user/ticket/retrieve',
						   method : 'POST',
						   data:preparedData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})	
					.then((response) => {

	
					// If an error occured. Check error is blocking or non-blocking.
					if (response.data.error) {	
						props.setLoading(false);					
						props.setTicket({});						
						return;					
					} // end if	*/
			
					if (response.data.success) {
															
						props.setTicket(response.data.ticket);
						
						// set loading off
						props.setLoading(false);						
							
					} // end if
					
					// If any other error, what do do???
					// ...
						
					}, (error) => {
						props.setLoading(false);						
						props.setTicket({});						
						return;				
					})
					.catch(error=> {
						props.setLoading(false);						
						props.setTicket({});						
						return;	
					});

		}
	
}
	
export const deleteTicket = (data,props)  => {
	
		return dispatch => {
			
					// Check if there is a local USER OBEJECt with a TOKEN
					var result = localStorage.getItem("@motuum/userData", undefined,true);
					var userObj = JSON.parse(result);  
					
					// decode JWT 
					var decoded = jwtDecode(userObj.userToken);
					
					// Set CustomerUserId
					const CustomerUserId = decoded.CustomerUserId;
					
					// Set data
					var preparedData = {
						serviceComplaintId:data.serviceComplaintId
					}	
	
					// Connect to CREATE QUOTATION Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/user/ticket/delete',
						   method : 'PUT',
						   data:preparedData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})	
					.then((response) => {
						
	
					// If an error occured. Check error is blocking or non-blocking.
					if (response.data.error) {	
						props.setLoading(false);					
						props.setReportProblem({});						
						return;					
					} // end if	*/
			
					if (response.data.success) {
															
						props.setReportProblem(response.data.complaint);
						
						// set loading off
						props.setLoading(false);						
							
					} // end if
					
					// If any other error, what do do???
					// ...
						
					}, (error) => {
						props.setLoading(false);						
						props.setReportProblem([]);						
						return;				
					})
					.catch(error=> {
						props.setLoading(false);						
						props.setReportProblem([]);						
						return;	
					});

		}
	
}
	
