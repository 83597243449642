import './MainProfileManage.css';
import React,{useState,useEffect,Fragment} from 'react';
import {Link,useNavigate} from 'react-router-dom';

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

import { Box,Backdrop,CircularProgress,Typography,Button,InputAdornment,IconButton,TextField,Divider } from '@mui/material';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import WarningIcon from '@mui/icons-material/Warning';
import LockIcon from '@mui/icons-material/Lock';
import Slide from '@mui/material/Slide';
import Fade from '@mui/material/Fade';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Danger from '@mui/icons-material/Error';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';

import InputMask from "react-input-mask";

import NiceInputPassword from 'react-nice-input-password';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


// Load Actions
import * as AppActions from '../../Store/Actions/App'
import * as ProfileActions from '../../Store/Actions/Profile'

const mapStateToProps = store => ({
  innerLoading:store.appReducer.innerLoading,
  UserData:store.appReducer.userData,
  dialog:store.appReducer.dialog
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AppActions,...ProfileActions }, dispatch);
    
// Transition Slide for Dialog SLIDE UP	
const TransitionSlideUp = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;  
});	
	

/* Extra  Dialog Component START */
export const PasswordChangeForm = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {



// set vars from props
// Remember to add those who come from Redux
const { 
	setDialog,
	dialog,
	UserData,
	updateProfilePassword
} = props;	


// set Use of history for PUSH 
let navigate = useNavigate();

const [passwordCurrent, setPasswordCurrent] = useState(""); // state for input fields
const [password, setPassword] = useState(""); // state for input fields
const [passwordConfirm, setPasswordConfirm] = useState(""); // state for input fields

// password view control 
const [showPasswordCurrent, setShowPasswordCurrent] = useState(false);
const handleClickShowPasswordCurrent = () => setShowPasswordCurrent(!showPasswordCurrent);
const handleMouseDownPasswordCurrent = () => setShowPasswordCurrent(!showPasswordCurrent);

// password view control 
const [showPassword, setShowPassword] = useState(false);
const handleClickShowPassword = () => setShowPassword(!showPassword);
const handleMouseDownPassword = () => setShowPassword(!showPassword);

const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
const handleClickShowPasswordConfirm = () => setShowPasswordConfirm(!showPasswordConfirm);
const handleMouseDownPasswordConfirm = () => setShowPasswordConfirm(!showPasswordConfirm);


const [dialogAction, setDialogAction] = useState(false); // state for tab
const [dialogAlert, setAlertDialog] = useState(false); // state for tab
const [dialogMessage, setDialogMessage] = useState(""); // state for tab
const [dialogTitle, setDialogTitle] = useState("Verifique as informações"); // state for tab
const [alert, setAlert] = useState(false); // state for tab
const [confirm, setConfirm] = useState(false); // state for tab

const [loading, setLoading] = useState(false); 


// set classes var
// const classes = useStyles()();

  
/* Passsword Strenght bar START*/
const levelBarCss = (level) => ({
  height: "8px",
  width: level > 0 ? `${(100 / 4) * level}%` : "100%",
  marginBottom: 10,
  transition: "width 0.5s ease",
  backgroundColor: ["#EFEFEF", "red", "orange", "yellow", "green"][level],
  borderRadius: 0
});

const CustomLevelBar = (levels) => <div style={levelBarCss(levels)} />;

 const securityLevels = [
      {
        descriptionLabel: <Typography>1 number</Typography>,
        validator: /.*[0-9].*/
      },
      {
        descriptionLabel: <Typography>1 lowercase letter</Typography>,
        validator: /.*[a-z].*/
      },
      {
        descriptionLabel: <Typography>1 uppercase letter</Typography>,
        validator: /.*[A-Z].*/
      },
      {
        descriptionLabel: <Typography>6 of length</Typography>,
        validator: /^.{6,}$/
      }
    ];
/* Passsword Strenght bar END*/  	



const confirmAction = () => {
		
	if ( !passwordCurrent || !password || !passwordConfirm) {
		setDialogTitle("Verifique as informações");
		setDialogMessage("Você deve preencher todos os campos para prosseguir.");	
		setAlert(true);	
		return false;	
	} // end if	

	if ( passwordCurrent == password ) {
		setDialogTitle("Verifique as informações");
		setDialogMessage("A sua senha atual é igual a nova senha. Verifique.");	
		setAlert(true);	
		return false;	
	} // end if		
				
	// Check if terms was accepted
	if (password != passwordConfirm) {	
		setDialogTitle("Verifique as informações");
		setDialogMessage("A sua nova senha não é igual a confirmação da mesma. Verifique.");
		setAlert(true);	
		return false;		
	} // end if		
	
	
	setDialogMessage("Tem certeza que deseja prosseguir com a alteração de sua senha? Ao alterar a senha é necessário fazer o login no aplicativo novamente.");		
	// set title
	setDialogTitle("Tem certeza que quer prosseguir?");

	// Open confirmation alert
	setConfirm(true);	
	
}

const saveAction = (props) => {
	
	setLoading(true);
	
	// Retrieve Scheduled Services
	var data = {	
		passwordCurrent,
		password		
	}
	var props = {
		setAlert:setAlert,
		setDialogMessage:setDialogMessage,
		setDialogTitle:setDialogTitle,
		setLoading:setLoading,
		setDialogAction:setDialogAction
	}	
	updateProfilePassword(data,props);	
	
}

const handleKeypress = e => {	
	 //it triggers by pressing the enter key  
	if (e.key === "Enter") {
	  saveAction(props);
	}
};	

// Set functions
const handleChange = (event) => {
	
//const newValue = "A";
const newValue = event.value;
	
setPassword(newValue);
};


// Set functions
const handleChangeConfirm = (event) => {
	
//const newValue = "A";
const newValue = event.value;
	
setPasswordConfirm(newValue);
};


// Set functions
const handleChangeCurrent = (event) => {

const newValue = event.value;
	
setPasswordCurrent(newValue);
};


	return (
	<Fragment>
		<Box ml={3} mr={3} mb={1} style={{marginTop:"10px",flex:1,alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>    
			
			<div> 
				<div style={{fontFamily:"Roboto",fontSize:"16px",color:"#636466",marginRight:"10px"}}>Caso altere a senha será necessário fazer a autenticação novamente.</div>
			</div>
		</Box>	
		
		<form id="formLoginPhone"  noValidate autoComplete="off">

		<Box pl={2} pr={2}>
	
			<TextField
			  id="loginPasswordPhoneCurrent"
			  style={{ margin: 10,marginLeft:0,marginRight:0,backgroundColor:"#fff",width:"100%",borderRadius:"10px" }}
			  placeholder="Senha atual"
			  required
			  variant="outlined"
			  type={showPasswordCurrent ? "text" : "password"} 									  
			 InputProps={{
					  startAdornment: (
						<InputAdornment position="start">
						  <LockIcon style={{color:"#ccc"}} />
						</InputAdornment>
					  ),
					   endAdornment: (<IconButton style={{marginRight:"-10px"}} aria-label="toggle password visibility" onClick={handleClickShowPasswordCurrent} onMouseDown={handleMouseDownPasswordCurrent} >
					  {showPasswordCurrent ? <Visibility /> : <VisibilityOff />}
					  </IconButton>)
					}}	
			  value={passwordCurrent}	
			  onChange={(event) => {
				  setPasswordCurrent(event.target.value)
			  }}					  
			  
			/>
			

			<h3 style={{fontWeight:"normal",fontFamily:"Roboto",fontSize:"16px",color:"#003CFA"}}> CRIE SUA NOVA SENHA </h3>
			
			<NiceInputPassword
					  placeholder="Senha"
					  InputComponent={TextField}
					  InputComponentProps={{
						onKeyPress:handleKeypress,  
						type:showPassword ? "text":"password", 
						style:{ margin: 0,marginTop:"5px",marginLeft:0,marginRight:0,backgroundColor:"#fff",width:"100%",borderRadius:"10px" },
						variant: "outlined",
						InputProps: {
						  startAdornment: <LockIcon style={{marginRight:"10px",color:"#ccc"}} />,
						  endAdornment: <IconButton style={{marginRight:"-10px"}} aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} >
						  {showPassword ? <Visibility /> : <VisibilityOff />}
						  </IconButton>
						}
					  }}
					  showSecurityLevelBar
					  renderLevelBarComponent={CustomLevelBar}
					  onChange={handleChange}
					  value={password}
					  securityLevels={securityLevels}
					/>		

			 
			<TextField
			  style={{ marginTop: "0px",marginBottom:"10px",marginLeft:0,marginRight:0,backgroundColor:"#fff",width:"100%",borderRadius:"10px"}}
			  placeholder="Confirmar senha"
			  variant="outlined"
			  type={showPasswordConfirm ? "text":"password"}
			  onKeyPress={handleKeypress}
			  value={passwordConfirm}
			  onChange={(event) => {
				  setPasswordConfirm(event.target.value)
			  }}										  
			  InputProps={{
				  startAdornment: (
					<InputAdornment position="start">
					  <LockIcon style={{color:"#ccc"}} />
					</InputAdornment>
				  ),
				   endAdornment: (<IconButton style={{marginRight:"-10px"}} aria-label="toggle password visibility" onClick={handleClickShowPasswordConfirm} onMouseDown={handleMouseDownPasswordConfirm} >
				  {showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
				  </IconButton>)
				}}	
			 />								
					
			<Button readonly={loading} variant="contained" color="primary" size="large" style={{width:"100%",height:"53px",marginTop:"10px",borderRadius:"10px"}} onClick={()=>confirmAction()}>
				{ !loading &&
				   <div>SALVAR</div>
				}
				
				{ loading &&
				<>
				   <CircularProgress size={30} color="inherit" />
				   <span style={{marginLeft:"10px"}}>Alterando...</span>
				</>
				}
			</Button>	

		</Box>
		  
		</form>
		
			<ConfirmDialog 
				saveAction={saveAction}
				dialog={dialog}
				confirm={confirm}
				setConfirm={setConfirm}
				setDialog={setDialog}
				dialogTitle={dialogTitle}
				dialogMessage={dialogMessage}
				setDialogTitle={setDialogTitle}
				setDialogMessage={setDialogMessage}
			/>	

			<AlertDialog 
				alert={alert}
				setAlert={setAlert}
				dialogMessage={dialogMessage}
				dialogTitle={dialogTitle}
				history={navigate}
				dialogAction={dialogAction}
			/>			
		
	</Fragment>	
	)	
	
});

const AlertDialog = (props) => {
	
	return (
		 <Dialog
			open={props.alert} 
			onClose={!props.dialogAction ? () => { props.setAlert(false) } : () => { props.setAlert(false);props.navigate("/login") }  }			
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		  >
		  <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
			<DialogContent>
			  <DialogContentText id="alert-dialog-description">
			  {props.dialogMessage}
			  </DialogContentText>
			</DialogContent>
			<DialogActions>
			  <Button onClick={!props.dialogAction ? () => { props.setAlert(false) } : () => { props.setAlert(false);props.navigate("/login") }  } color="primary" autoFocus>
				OK
			  </Button>
			</DialogActions>			
		  </Dialog>
	  )
	
} // end AlertDialog

const ConfirmDialog = (props) => {
	
	return (
		 <Dialog
			open={props.confirm}
			onClose={ ()=>props.setConfirm(false) } 
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		  >
		  <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
			<DialogContent>
			  <DialogContentText id="alert-dialog-description">
			  {props.dialogMessage}
			  </DialogContentText>
			</DialogContent>
			<DialogActions>

			  <Button onClick={ ()=>props.setConfirm(false) } color="primary" autoFocus>
				ABORTAR
			  </Button>			

			  <Button onClick={ ()=> {props.setConfirm(false);props.saveAction(props); } } color="primary" autoFocus>
				CONFIRMAR 
			  </Button>
				
			</DialogActions>			
		  </Dialog>
	  )
		
} // end AlertDialog





