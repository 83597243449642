import * as types from '../actionTypes';

const initialState = { 
	buttonLoading: true,
	addressLoading:true,
	addressesCards:[],
}
 
export const addressReducer = (state = initialState, action) => {
		
    switch (action.type) {
		case types.SET_ADDRESSES_CARDS:
            return { ...state, addressesCards: action.payload }			
        default:
            return state
    }
}