import './MainProfile.css';
import React,{useState,useEffect,Fragment} from 'react';
import {Link,useNavigate} from 'react-router-dom';

import PropTypes from 'prop-types';
import { CSSTransition } from "react-transition-group";

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

// Material UI Import
import { Box,CircularProgress,Typography,Button,IconButton,TextField,FormControl  } from '@mui/material';


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';


import {useComponentWillMount} from '../../Utils/customFunctions';
import Header from '../../Components/Layout/Header';
import If from '../../Components/Common/If';

import logo from '../../Assets/Images/logoTypeWOslogan.svg'; // Tell Webpack this JS file uses this image
import backgroundHexagon from '../../Assets/Images/backgroundHexagon.png'; // Tell Webpack this JS file uses this image


// Load Actions
import * as AppActions from '../../Store/Actions/App'
import * as RegisterActions from '../../Store/Actions/Register'

const ProfileStep32 = props => {


const screenStyle = {
  alignItems: "stretch",
  justifyContent: "top",
  width:"100vw",
  minHeight:"100vh",
  display:"flex",
  flexDirection:"column",
  backgroundImage: "url(" + backgroundHexagon + ")",
  backgroundSize:"cover",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"fixed"
}; 

const screenStyleLoading = {
  alignItems: "middle",
  justifyContent: "center",
  width:"100vw",
  height:"100vh",
  display: "flex",
  flexDirection: "column"
}; 

const styles = {
  indicator: {
    backgroundColor: '#A255A0',
  },
};

// Set functions
const handleChange = (event, newValue) => {
setValue(newValue);
};

// Set TABPANEL
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
	  hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

// set classes var
// const classes = useStyles()();


// set vars from props
// Remember to add those who come from Redux
const { 
	registerDataStep0,
	registerDataStep1,
	registerDataStep2,
	registerDataStep3,
	innerLoading,
	setInnerLoading,
	buttonLoading,
	setButtonLoading,
	setRegister,
	processStep
} = props;

// set Use of history for PUSH 
let navigate = useNavigate();

// Set internal state
const [contentVisible,setContentState] = useState(false);
const [loadingMessage,setLoadingMessage] = useState("Carregando...");
const [value, setValue] = useState(0); // state for tab

const registerMobile = false;
const registerEmail = true;
  
// Run after mount component
useEffect(() => {
	
  console.log("Montou o componente MainHome....");
  
  // Set Backdrop Loading over interface
  //setInnerLoading(true); 
   
  // Show content after load for animation
 // setContentState(true);
 
 // Set Content Loading ON
 //setInnerLoading(true);
 setContentState(true);
 
 // Simulate Delay
 setTimeout(function() {
	 
	  // Set Content Loading OFF
	  //setInnerLoading(false);
	  
	  // Set Content Ready to show
	  //setContentState(true);
	
 },3000);

  // set scroll to top
  window.scrollTo(0, 1);
    
  // Runs when UNMOUNT component
  return () => {
    console.log("Desmontou o componente MainHome...");
  };
  
}, []); // end useEffect


// Will Mount Component HOOK
useComponentWillMount(() => {
	
    // Set session of app
  var sessionCheck = sessionStorage.getItem('@motuum/appLaunched');
  
  // if session is not created, redirect to bootstrap. (avoid direct access using url)
  if (!sessionCheck) {
	  navigate("/");
  } // end if	
      
}, []); // end useComponentWillMount

const handleClose = () => {
	setInnerLoading(false);
};

	return (
	<Fragment>
			
			<Header title="Cadastrar Perfil" buttons={{support:true,menu:false,close:false,back:true}} /> 
			
			<CSSTransition in={contentVisible} timeout={1000} classNames="fade"	unmountOnExit>	
			
				<Box className="mainProfile" style={{...screenStyle}} m={0}>
							
						<Box style={{marginBottom:"20px",backgroundColor:"transparent",alignItems:"center",display:"flex",flexDirection:"column"}} mt={7} pl={3} pr={3}>

								<h5 style={{fontSize:"1rem",color:"#A255A0"}}>Ative seu  na perfl na Motuum</h5>
								
								<div style={{fontSize:"0.9rem",color:"#555555"}}>Para solicitar profissionais, é necessário que ative o seu perfil através da confirmação do seu telefone ou email.</div>
								
						</Box>
						
						<Box style={{backgroundColor:"transparent",display:"flex",alignItems:"top"}} pb={15}>							
								
							<Box style={{flexGrow: 1,backgroundColor: "transparent" }} ml={2} mr={2} style={{flex:1}}>
								  
								  <div>
								  
									<If test={registerMobile}>
										<TextField
										  id="mobilePhoneRegister"
										  label="Telefone Celular"
										  style={{ margin: 10,marginLeft:0,marginRight:0,backgroundColor:"#fff",width:"100%"}}
										  placeholder="Telefone no formato (XX) 9XXXX-XXXX"
										  required
										  size="small"
										  variant="outlined"
										  type="text"
										  value="(21) 983412-2800"
										 />
									</If>
										
									<If test={registerEmail}>
										<TextField
										  disabled
										  id="loginEmailRegister"
										  label="Email"
										  style={{ margin: 10,marginLeft:0,marginRight:0,backgroundColor:"#fff",width:"100%"}}
										  placeholder="Informe o email cadastrado"
										  size="small"
										  variant="outlined"
										  type="email"
										  value="fmoreno@realweb.com.br"
										/>
									</If>								  
								  
								  </div>

									<div>							  
							  
							          <h4 style={{marginBottom:"5px"}}> Verifique sua caixa de email... </h4>
							  
										<TextField
										  id="verificationCode"
										  label="Digite os 6 números recebidos"
										  style={{ margin: 10,marginLeft:0,marginRight:0,backgroundColor:"#fff",width:"100%"}}
										  placeholder="Digite os 6 números recebidos"
										  required
										  variant="outlined"
										  type="text"
										 />

									</div>
							  
									<div style={{display:"flex",flexDirection:"row"}}>
									
										<Button color="secondary" style={{width:"45%",height:"48px",marginTop:"10px"}} component={Link} to="/register/step4">Ativar depois</Button>


										<Button variant="contained" color="primary" size="large" style={{width:"55%",height:"48px",marginTop:"10px",backgroundColor:"#bbb",color:"#999"}} component={Link} to="/register/step4">
										 Ativar Perfil
										</Button>
										{/*backgroundColor:"#555555",color:"#FFF"*/}

									</div>	

									<div style={{marginTop:"10px",display:"flex",flexDirection:"column"}}>
									
										<Button color="secondary" style={{height:"48px",marginTop:"10px",marginBottom:"0px"}}>Reenviar c?digo</Button>
										<small style={{color:"gray",marginTop:"0px"}}>Reenvie caso não tenha recebido em até 5 minutos</small>

									</div>							  
							  
							</Box>
								
						</Box>
						
								
				</Box>
			
			</CSSTransition> 
			
				
	</Fragment>
)};
 
const mapStateToProps = store => ({
  innerLoading:store.appReducer.innerLoading,
  buttonLoading:store.registerReducer.buttonLoading,
  registerDataStep0:store.registerReducer.registerDataStep0,
  registerDataStep1:store.registerReducer.registerDataStep1,
  registerDataStep2:store.registerReducer.registerDataStep2,
  registerDataStep3:store.registerReducer.registerDataStep3,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AppActions, ...RegisterActions }, dispatch);

export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(ProfileStep32);
 