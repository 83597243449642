import './MainCreditCards.css';
import React,{useState,useEffect,useRef,Fragment} from 'react';
import {Link,useNavigate} from 'react-router-dom';
import { CSSTransition } from "react-transition-group";

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

import { Box,Backdrop,CircularProgress,Button,Divider,Avatar,FormControlLabel,IconButton,TextField,Typography,InputAdornment } from '@mui/material';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Checkbox from '@mui/material/Checkbox';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import InputMask from "react-input-mask";
import { Number, Cvc, Expiration } from "react-credit-card-primitives";

import Slide from '@mui/material/Slide';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import Fade from '@mui/material/Fade';
import DeleteIcon from '@mui/icons-material/Delete';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import CloseIcon from '@mui/icons-material/Close';
import HttpsIcon from '@mui/icons-material/Https';

import {useComponentWillMount} from '../../Utils/customFunctions';
import Header from '../../Components/Layout/Header';
import If from '../../Components/Common/If';

import Countdown from "react-countdown";

import logo from '../../Assets/Images/logoTypeWOslogan.svg'; // Tell Webpack this JS file uses this image
import backgroundHexagon from '../../Assets/Images/backgroundHexagon.png'; // Tell Webpack this JS file uses this image
import desktop from '../../Assets/Images/desktop.svg'; // Tell Webpack this JS file uses this image
import router from '../../Assets/Images/router.svg'; // Tell Webpack this JS file uses this image

import visa from '../../Assets/Images/visa.svg'; // Tell Webpack this JS file uses this image
import mastercard from '../../Assets/Images/mastercard.svg'; // Tell Webpack this JS file uses this image
import amex from '../../Assets/Images/amex.svg'; // Tell Webpack this JS file uses this image
import diners from '../../Assets/Images/diners.svg'; // Tell Webpack this JS file uses this image
import elo from '../../Assets/Images/elo.svg'; // Tell Webpack this JS file uses this image
import genericFlag from '../../Assets/Images/genericFlag.svg'; // Tell Webpack this JS file uses this image


// Load Actions
import * as AppActions from '../../Store/Actions/App'
import * as CreditcardActions from '../../Store/Actions/Creditcards'

const CreditCards = props => {


const screenStyle = {
  alignItems: "stretch",
  justifyContent: "top",
  width:"100vw",
  minHeight:"100vh",
  display:"flex",
  flexDirection:"column",
  backgroundImage: "url(" + backgroundHexagon + ")",
  backgroundSize:"cover",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"center"
}; 


const screenStyleLoading = {
  alignItems: "middle",
  justifyContent: "center",
  width:"100vw",
  height:"100vh",
  display: "flex",
  flexDirection: "column"
}; 

const backgroundStyle = {
  backgroundImage: "url(" + backgroundHexagon + ")",
  backgroundSize:"cover",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"center"
}; 


// set vars from props
// Remember to add those who come from Redux
const { 
	retrieveCards,
	creditCardsItens,
	deleteCreditcard,
	dialog,
	setDialog	
} = props;

// set Use of history for PUSH 
let navigate = useNavigate();

// Set internal state
const [contentVisible,setContentState] = useState(false);
const [loadingMessage,setLoadingMessage] = useState("Carregando...");
const [screenStatus,setScreenStatus] = useState('searching');
const [acceptTerms, setAcceptTerms] = useState(false); // state for tab

const [cardId, setCardId] = useState(null); 
const [paymentToken, setPaymentToken] = useState(null); 

const [dialogTitle, setDialogTitle] = useState("Tem certeza que quer excluir?"); // state for tab
const [dialogMessage, setDialogMessage] = useState("Tem certeza que quer excluir o cartão de crédito selecionado?"); // state for tab
const [alert, setAlert] = useState(false); // state for tab
const [dialogAlert, setAlertDialog] = useState(false); // state for tab

const [savingCard, setSavingcard] = useState(false);
const [loadingCards, setLoadingCards] = useState(true);
const [loadingButton, setLoadingButton] = useState(false);

const [cards,setCards] = useState(creditCardsItens ? creditCardsItens:[]);

// Run after mount component
useEffect(() => {
  
	// Retrieve Addresses from API
	var propsParam = {
		setContentState:setContentState,
		setCards:setCards,
		setAlert:setAlert,
		setDialogMessage:setDialogMessage,
		setDialogTitle:setDialogTitle,
		setLoadingCards:setLoadingCards
	}		
	retrieveCards(propsParam);    
 
  // set scroll to top
  window.scrollTo(0, 1);
    
  // Runs when UNMOUNT component
  return () => {
		// unmounted...
  };
  
}, []); // end useEffect


// Will Mount Component HOOK
useComponentWillMount(() => {
	
    // Set session of app
  var sessionCheck = sessionStorage.getItem('@motuum/appLaunched');
  
  // if session is not created, redirect to bootstrap. (avoid direct access using url)
  if (!sessionCheck) {
	  navigate("/");
  } // end if	
      
}, []); // end useComponentWillMount


const deleteCardPrompt = (cardId,paymentToken) => {
	
	setCardId(cardId);		
	setPaymentToken(paymentToken);		
	setDialogTitle("Tem certeza que quer prosseguir?");
	setDialogMessage("Tem certeza que quer excluir o cartão selecionado? Essa ação é irreversível.");	
	setDialog('deleteCardPrompt',true);	
	
}

const deleteCardAction = () => {
	
	setLoadingButton(true);

	// Retrieve Addresses from API
	var propsParam = {
		setDialog:setDialog,
		setAlert:setAlert,
		setDialogMessage:setDialogMessage,
		setDialogTitle:setDialogTitle,
		setLoadingButton:setLoadingButton,
		cards:cards,
		setCards:setCards
	}
	var data = {
		cardId:cardId,
		paymentToken:paymentToken
	}	
	deleteCreditcard(data,propsParam);  
	
} 

	return (
	<Fragment>
			
			<Header title="Cartões de Crédito" buttons={{back:true,support:false,menu:false,close:false,fakeLeft:true}} />
			
			{/* Inner Loading */}
			{loadingCards &&
					<Box className="loadingContent" style={{...screenStyleLoading}}>
							<Box mt={0} mx={0}>								
								<CircularProgress /> 
								<div> Carregando...Aguarde!</div>								
							</Box>	
					</Box>
			}	

			<Fade in={contentVisible} timeout={{enter:1000,exit:300}}  mountOnEnter unmountOnExit>	
			
				<Box className="mainHome" style={{...screenStyle}} mt={0}>	

						<Box pl={2} pr={2} mt={11} style={{alignItems:"stretch",display:"flex",flexDirection:"column",justifyContent:"center",paddingBottom:"120px",paddingLeft:"55px",paddingRight:"55px"}}>	
						
							<Box style={{marginTop:"30px",marginBottom:"30px"}}>						
								<div style={{fontFamily:"Roboto",fontWeight:"100",fontSize:"42px",color:"#003CFA",lineHeight:"38px"}}>
									  CARTÕES DE CRÉDITO
								</div>								 	
							</Box>	
						
						
							{cards.map((item, index) => {
								
										if (item.cardFlag == "visa") {
											var flag = visa;
										} // end if
										else if  (item.cardFlag == "mastercard") {
											var flag = mastercard;
										} // end else if
										else if  (item.cardFlag == "amex") {
											var flag = amex;
										} // end else if	
										else if  (item.cardFlag == "diners") {
											var flag = diners;
										} // end else if	
										else if  (item.cardFlag == "elo") {
											var flag = elo;
										} // end else if	
										else if  (item.cardFlag == "generic") {
											var flag = genericFlag;
										} // end else if									
								
									return (
									
									<Card key={index} style={{height:"180px",width:"100%",marginBottom:"20px",backgroundColor:"#ffffff",boxShadow: "0px 10px 20px 0px #0000002A",borderRadius:"10px"}}>
									
									  <CardContent style={{padding:"5px"}}>
										
										<Box style={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"start"}}>
											
											<Box style={{textAlign:"right",width:"100%",paddingTop:"12px",paddingBottom:"12px"}} pl={2} pr={2}>										
												<img src={flag} alt="{item.cardFlag}" style={{width:"auto",height:"35px"}} />
											</Box>
											
											<Box m={0} pl={2} pr={2} style={{width:"100%"}}>										
												
													<div style={{textAlign:"left",color:"#636466"}}>
																											
														<div style={{fontFamily:"Roboto",fontWeight:"bold",fontSize:"16px",letterSpacing:"5px",marginBottom:"5px"}}>{item.referenceNumber}</div>
														
														<div style={{fontFamily:"Roboto",fontWeight:"bold",fontSize:"12px",textTransform: "uppercase"}}>{item.holderName}</div>
														
														<div style={{fontFamily:"Roboto",fontWeight:"normal",fontSize:"12px"}}>{item.expirationDate}</div>
													
													</div>											
												
											</Box>	
										
										</Box>
										
										
										
										
									  </CardContent>
									  
										<CardActions style={{display:"flex",flexDirection:"row",backgroundColor:"#E3000010",padding:"2px",marginTop:"11px"}}>
												  
										  <div style={{flex:1,textAlign:"center"}}>
												<Button style={{color:"#E30000",fontFamily:"Roboto",fontWeight:"normal",fontSize:"12px"}} size="small" onClick={()=>deleteCardPrompt(item.id,item.PaymentGatewayPaymentMethodId) }>										
													<DeleteIcon style={{fontSize:"20px",color: '#E30000',marginRight:"5px"}} />
													<div>Excluir Cartão</div>										
												</Button>
											</div>
											
										  </CardActions>

									</Card>
									
								)}
							
							
							)}	


							{/* If there is no result */}
							{cards.length == 0 &&
								<div style={{height:"70vh",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center"}}>
								
									<div style={{flex:1.5,display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"flex-end",marginBottom:10}}>
									  <NotInterestedIcon style={{fontSize:"7rem",color: "#DDD"}} />
									</div>
									
									<div style={{flex:1.5,display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"flex-start"}}>
										<div style={{fontSize:20,color:"silver",textAlign:"center"}}>Nenhum cartão armazenado</div>
									</div>													
								
								</div>				
							}								

						</Box>	

				
						{/*/<Box style={{...backgroundStyle,padding:"15px",width:"100%",position:"fixed",bottom:"0px",display:"flex",flexDirection:"row",justifyContent:"center"}}>							
									
									<Button color="secondary" variant="contained" style={{width:"80%",height:"48px"}} onClick={()=>setDialog('payment',true)}>
									  Adicionar novo cartão de crédito
									</Button>
																
						</Box>	*/}
						
						<Box style={{backgroundColor:"transparent",display:"flex",width:"100%",alignItems:"center",position:"fixed",bottom:0,height:"110px",backgroundColor:"#ffffff",boxShadow: "0px -10px 15px 0px #0000001A"}} onClick={()=>console.log(true)}>		

							<Box style={{flex:1,display:"flex",flexDirection:"row",justifyContent:"space-around"}}>
									
								<Box style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>	
									<Button color="secondary" variant="contained" style={{width:"100%",height:"54px",fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px"}} onClick={()=>setDialog('payment',true)}>
									  Adicionar cartão de crédito
									</Button>
						
								</Box>	

							</Box>								
								
						</Box>							
						
				</Box>
			
			</Fade> 

			<AlertDialog 
				alert={alert}
				setAlert={setAlert}
				dialogTitle={dialogTitle}
				dialogMessage={dialogMessage}
			/>		

			<ConfirmDialog 
				dialog={dialog}
				setDialog={setDialog}
				dialogTitle={dialogTitle}
				dialogMessage={dialogMessage}
				loadingButton={loadingButton}
				setLoadingButton={setLoadingButton}
				deleteCardAction={deleteCardAction}
			/>		

			<PaymentDialog
				setAlert={setAlert}
				setDialogMessage={setDialogMessage}
				setDialogTitle={setDialogTitle}
				cards={cards}
				setCards={setCards}
			/>
			
	</Fragment>
)};


export const ConfirmDialog = (props) => {
	
	return (
		 <Dialog
			open={props.dialog.target == 'deleteCardPrompt' && props.dialog.status}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		  >
		  <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
			<DialogContent>
			  <DialogContentText id="alert-dialog-description">
			  {props.dialogMessage}
			  </DialogContentText>
			</DialogContent>
			<DialogActions>

			  <Button disabled={props.loadingButton} onClick={ ()=>props.setDialog('deleteCardPrompt',false) } color="primary" autoFocus>
				FECHAR
			  </Button>			

			  <Button disabled={props.loadingButton} onClick={ ()=> { props.deleteCardAction(); } } color="primary" autoFocus>				
				{ !props.loadingButton &&
				   <div>CONFIRMAR</div>
				}
				
				{ props.loadingButton &&
				<>
				   <CircularProgress size={30} color="inherit" />
				   <span style={{marginLeft:"10px"}}>Aguarde...</span>
				</>
				}				
			  </Button>
				
			</DialogActions>			
		  </Dialog>
	  )
		
} // end AlertDialog

export const AlertDialog = (props) => {
	
	return (
		 <Dialog
			open={props.alert} 
			onClose={()=>props.setAlert(false)}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		  >
		  <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
			<DialogContent>
			  <DialogContentText id="alert-dialog-description">
			  {props.dialogMessage}
			  </DialogContentText>
			</DialogContent>
			<DialogActions>
			  <Button onClick={() => props.setAlert(false)} color="primary" autoFocus>
				OK
			  </Button>
			</DialogActions>			
		  </Dialog>
	  )
	
} // end AlertDialog


const mapStateToProps = store => ({
  innerLoading:store.appReducer.innerLoading,
  dialog:store.appReducer.dialog,
  creditCardsItens:store.addressReducer.creditCards,  
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AppActions, ...CreditcardActions }, dispatch);

// Transition Slide for Dialog SLIDE UP	
const TransitionSlideUp = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;  
});	  
  
/* Payment Dialog Component START */
export const PaymentDialog = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {
	

const screenStyleDialog = {
  alignItems: "stretch",
  justifyContent: "top",
  width:"100vw",
  minHeight:"100vh",
  display:"flex",
  flexDirection:"column",
  backgroundColor:"#F0F0F0"
}; 

const screenStyleLoading = {
  alignItems: "middle",
  justifyContent: "center",
  width:"100vw",
  height:"100vh",
  display: "flex",
  flexDirection: "column"
};

const { 
	setDialog,
	dialog,
	creditCardsItens,
	saveCard,
} = props;	


const [savingCard, setSavingCard] = useState(false);
const [loadingCards, setLoadingCards] = useState(true);

const [restrictAmex, setRestrictAmex] = useState(false);
const [maskedCvc, setMaskedCvc] = useState(false);

const [creditcardDate, setCreditcardDate] = useState("");
const [creditcardHolder, setCreditcardHolder] = useState("");
const [creditcardCvv, setCreditcardCvv] = useState("");
const [creditcardNumber, setCreditcardNumber] = useState("");
const [creditcardFlag, setCreditcardFlag] = useState("");
const [creditcardToken, setCreditcardToken] = useState("");

// Set existing addresses 
const [creditCards,setCreditCard] = useState(creditCardsItens ? creditCardsItens:[]);

const [complementNewCard, setComplementNewCard] = useState(false);

// useEffect only for dialgo changes
useEffect(() => {
	
	// execute only if this modal
	if (dialog.target == 'payment' && dialog.status) {
		
		// Setup IUGU
		window.Iugu.setAccountID("54EB3E591DFC322DF6B8D3FBBDAC7E0E");
		window.Iugu.setTestMode(true);	

		// Clean form
		setCreditcardDate("");
		setCreditcardHolder("");
		setCreditcardCvv("");
		setCreditcardNumber("");
		setCreditcardFlag("");
		setCreditcardToken("");		
		
	}  // end if

}, [dialog]); // end useEffect only for dialgo changes

// Generate Dummy token to simulate IUGU return
const _generateDummyToken = (data) => {
	
	// try and catch
	try {

		// Função auxiliar para converter um ArrayBuffer para uma string hexadecimal
		function bufferToHex(buffer) {
			return [...new Uint8Array(buffer)]
				.map(b => b.toString(16).padStart(2, '0'))
				.join('');
		}

		// Ambiente do navegador
		const buffer = new Uint8Array(data / 2);
		window.crypto.getRandomValues(buffer);
		return bufferToHex(buffer.buffer);
	
	
	} // end try
	catch(e) {

		
		console.log("[ERRO TOKEN e.message]--->"+JSON.stringify(e.message,null,2));
		return "ERROR";
		
	} // end catch

}

const saveCardAction = (props) => {

	// Check if required fields are OK
	if (!creditcardNumber || !creditcardDate || !creditcardHolder || !creditcardCvv) {	
		props.setDialogTitle("Verifique as informações");	
		props.setDialogMessage("Preencha todos os campos marcados com * para prosseguir.");
		props.setAlert(true);	
		return false;		
	} // end if	
	
	// Validate creditcard number
	var creditCardNumberCheck = window.Iugu.utils.validateCreditCardNumber(creditcardNumber);
	if (!creditCardNumberCheck) {	
		props.setDialogTitle("Verifique os dados do cartão");	
		props.setDialogMessage("Verifique o número do cartão de crédito informado.");
		props.setAlert(true);	
		return false;		
	} // end if		
	
	// Validate expiraiton date
	var tmp = creditcardDate.split("/");
	var expirationMonth = tmp[0];
	var expirationYear = tmp[1];
	var creditCardExpirationCheck = window.Iugu.utils.validateExpiration(expirationMonth,expirationYear);
	if (!creditCardExpirationCheck) {	
		props.setDialogTitle("Verifique os dados do cartão");		
		props.setDialogMessage("Verifique a data de vencimento do cartão de crédito informado.");
		props.setAlert(true);	
		return false;		
	} // end if	

	// Validate expiraiton date
	var creditCardCVVCheck = window.Iugu.utils.validateCVV(creditcardCvv,creditcardFlag);
	if (!creditCardCVVCheck) {	
		props.setDialogTitle("Verifique os dados do cartão");		
		props.setDialogMessage("Verifique código de segurança do cartão de crédito informado.");
		props.setAlert(true);	
		return false;		
	} // end if			
	
	// Split first name from lastname
	var tmp = creditcardHolder.trim();
	var tmp2 = tmp.split(" ");
	var firstName = tmp2[0].replace(/[|&;$#%@"<>()+,]/g, "");; // set first name
	var lastName = tmp.substr(tmp2[0].length + 1,(tmp.length - tmp2[0].length) ).replace(/[|&;$%@"<>()+,]/g, ""); 
	
	// Try to create IUGU token
	var cc = window.Iugu.CreditCard(creditcardNumber,expirationMonth, expirationYear,firstName,lastName,creditcardCvv);

	
	console.log("[IUGU CC]--->"+JSON.stringify(cc,null,2));

	
	// Set loading on...
	setSavingCard(true);
	
	//window.Iugu.createPaymentToken(cc, function(response) {

	// Simulate token creation
	var ccToken = "IUGU-DUMMY-TOKEN-"+_generateDummyToken(32);	
		
		//if (response.errors) {
			/*props.setDialogTitle("Ops! algo deu errado!");	
			props.setDialogMessage("Ocorreu um problema ao tentar gerar o token de seu cartão de crédito junto a IUGU. Tente novamente. Caso o problema persista, entra em contato com o suporte.");
			props.setAlert(true);	
			setSavingCard(false);
			return false;*/
		//} else {
						
			// Set Iugu Token
			//setCreditcardToken(response.id);
			setCreditcardToken(ccToken);

				// Prepare credditcard dara to send to API
				var masked = "";
				for (var i = 0; i < creditcardNumber.length; i++) {
					masked += (i > 3 && i < creditcardNumber.length - 4) ? 'X' : creditcardNumber[i];
					if ((i + 1) % 4 == 0)
						masked += " ";
				}	
				var creditcardNumberMasked = masked;

				// Set Card Obj
				var cardObj = {
					creditcardHolder:creditcardHolder,
					creditcardNumber:creditcardNumberMasked,
					creditCardFlag:creditcardFlag,
					expirationDate:creditcardDate,
					creditcardToken:ccToken
					//creditcardToken:response.id
				}	
				var propsParam = {
					setCards:props.setCards,
					cards:props.cards,
					setSavingCard:setSavingCard,
					setComplementNewCard:setComplementNewCard,
					setAlert:props.setAlert,
					setDialogMessage:props.setDialogMessage,
					setDialogTitle:props.setDialogTitle,
					setDialog:setDialog
				}	
				saveCard(cardObj,propsParam);		
						
		//}  

	//});	

	
};	

const handleKeypress = e => {
	
	 //it triggers by pressing the enter key  
	if (e.key === "Enter") {
	  saveCardAction(props);
	}

};


	return (
	<div>
		  <Dialog fullScreen open={dialog.target == 'payment' && dialog.status} onClose={()=>setDialog('payment',false)} TransitionComponent={TransitionSlideUp}>
			<AppBar  sx={{
				position: 'fixed'
			}}>
			  <Toolbar>
				<IconButton edge="start" color="inherit" onClick={() => setDialog('payment',false)} aria-label="close">
				  <CloseIcon />
				</IconButton>
				<Typography variant="h6" style={{
					marginLeft: "10px",
					flex: 1
				}}>
				  Forma de Pagamento
				</Typography>
				</Toolbar>
			</AppBar>

			<Box style={{...screenStyleDialog}} m={0}>
				
				<Box mt={7} ml={0} mr={0} style={{height:"77vh",overflow:"scroll"}}>
					
						
						<Box style={{marginBottom:"0px",alignItems:"center",justifyContent:"center",display:"flex",flexDirection:"column",paddingLeft:"50px",paddingRight:"50px",textAlign:"center"}} >
						
									<Box style={{marginTop:"30px",marginBottom:"20px"}}>						
										<div style={{fontFamily:"Roboto",fontWeight:"100",fontSize:"42px",color:"#003CFA",lineHeight:"38px"}}>
											  ADICIONAR FORMA DE PAGAMENTO
										</div>								 	
									</Box>	
																			
						</Box>
							
						<Box style={{paddingLeft:"50px",paddingRight:"50px"}} mb={5} mt={1}>
									
							<TextField
							  label="Nome do titular do cartão *"
							  placeholder="Digite o nome como no cartão"
							  style={{ margin: 10,marginLeft:0,marginRight:0,backgroundColor:"#fff",width:"100%"}}
							  variant="outlined"
							  type="text"
							  autoComplete='off'									 
							  onKeyPress={handleKeypress}
							  value={creditcardHolder}
							  onChange={(event) => {
								  setCreditcardHolder(event.target.value)
							  }}							  
							  /*InputProps={{
								startAdornment: <InputAdornment position="start"><PersonIcon style={{fontSize:"1.2rem",flex:1,color:"#DDD"}} /></InputAdornment>,
							  }}*/							  
							/>

						 <Number
							masked
							 onChange={({value}) => setCreditcardNumber(value) }
							cardTypes={restrictAmex ? ['americanExpress'] : []}
						  onKeyPress={handleKeypress}
							render={({ value, valid, type, getInputProps }) => {
							
								if (!type) { var typeCard = ""; }
								if (type) { var typeCard = genericFlag; }
								if (type == "Visa") { var typeCard = visa; setCreditcardFlag("visa"); }
								if (type == "Mastercard") { var typeCard = mastercard; setCreditcardFlag("mastercard"); }
								if (type == "American Express") { var typeCard = amex; setCreditcardFlag("amex");  }
								if (type == "Diners Club") { var typeCard = diners; setCreditcardFlag("diners");  }
								if (type == "Elo") { var typeCard = elo; setCreditcardFlag("elo");  }
															
								return (
								  <div>
									<TextField
									  {...getInputProps() }
									  label="Número do cartão *"
									  placeholder="Digite o número do cartão"
									  style={{ margin: 10,marginLeft:0,marginRight:0,backgroundColor:"#fff",width:"100%"}}
									  autoComplete='off'									 
									  variant="outlined"
									  type="text"
									  InputProps={{
										/*startAdornment: <InputAdornment position="start"><CreditCardIcon style={{fontSize:"1.2rem",flex:1,color:"#DDD"}} /></InputAdornment>,*/
										endAdornment: <InputAdornment position="end"><img src={typeCard} style={{height:"30px",width:"auto"}} /></InputAdornment>										
									  }}										  
									/>
								  </div>
								)
							}
							
							} />							
									
							<div style={{display:"flex",flexDirection:"row",alignItems:"stretch",justifyContent:"center"}} >
							
							 <Expiration
							  onChange={({value, valid, error, month, year}) => setCreditcardDate(month+"/"+year)}
							  render={({ getInputProps, value, valid, error, month, year }) => (
								<TextField
								  {...getInputProps() } 
								  label="Vencimento *"
								  placeholder="MM/AA"
								  style={{flex:1, margin: 10,marginLeft:0,marginRight:10,backgroundColor:"#fff",width:"100%"}}
								  variant="outlined"
								  type="text"
								  /*InputProps={{
									startAdornment: <InputAdornment position="start"><DateRangeOutlinedIcon style={{fontSize:"1.2rem",flex:1,color:"#DDD"}} /></InputAdornment>,
								  }}*/									  
								/>
								)} />								
								
								<Cvc
								onChange={({value}) => setCreditcardCvv(value) }
								cardType={restrictAmex ? 'americanExpress' : undefined}
								render={({ getInputProps, value, valid }) => (								
								<TextField
								  {...getInputProps() }
								  label="Cód. Seg. *"
								  placeholder="3 ou 4 dígitos"
								  style={{flex:1, margin: 10,marginLeft:0,marginRight:0,backgroundColor:"#fff",width:"100%"}}
								  variant="outlined"
								  type="text"
								  inputProps={{
									maxLength:4  
								  }}
						  
								/>
								)} />
							
							</div>

							<div style={{marginTop:"20px",flex:1,alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>    

									<div> 
										<div style={{fontSize:"12px",fontFamily:"Roboto",color:"#636466",textAlign:"center"}}>Você está em um ambiente seguro, levamos segurança muito a sério. Todos os dados de cartão de crédito são enviados de forma segura para a IUGU (Gateway de pagamento), que gera uma chave única para essa transação ou armazenamento para uso futuro. <span style={{color:"#003CFA",display:"contents",marginLeft:"10px"}} onClick={()=> alert('modal sobre segurança') }>Saiba mais sobre segurança e IUGU</span></div>
									</div>
							</div>							
						
						</Box>


					</Box>
			
					<Divider style={{marginBottom:"5px"}} />
						
					<Box style={{display:"flex",flexDirection:"row",justifyContent:"center"}} ml={1} mr={1} mb={5} mt={2}>	
						
						
						<Button color="secondary" variant="outlined" style={{flex:1,height:"53px",borderRadius:"10px",marginRight:"10px"}} onClick={()=>setDialog('payment',false)}>
						 Fechar
						</Button>

						<Button disabled={savingCard} color="primary" variant="contained" style={{flex:2,height:"53px",borderRadius:"10px",color:"#FFF"}}  onClick={()=>saveCardAction(props)}>
							{ !savingCard &&
							   <div>CONFIRMAR</div>
							}
							
							{ savingCard &&
							<>
							   <CircularProgress size={30} color="inherit" />
							   <span style={{marginLeft:"10px"}}>Processando...</span>
							</>
							}	
						</Button>
							
					</Box>
				
			</Box>

		  </Dialog>
		</div>
		
	);
	
});
  


export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(CreditCards);

