import './MainLogoff.css';
import React,{useState,useEffect,Fragment} from 'react';
import {Link,useNavigate} from 'react-router-dom';

import PropTypes from 'prop-types';
import { CSSTransition } from "react-transition-group";

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

// Material UI Import
import { Box,CircularProgress,Typography,Button,IconButton,TextField,FormControl  } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';

import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from '@mui/icons-material/Warning';


import {useComponentWillMount} from '../../Utils/customFunctions';
import Header from '../../Components/Layout/Header';
import If from '../../Components/Common/If';

import checked from '../../Assets/Images/checked.svg'; 
import backgroundHexagon from '../../Assets/Images/backgroundHexagon.png';
import checkIconBlue from '../../Assets/Images/checkIcon-blue.svg';


// Load Actions
import * as AppActions from '../../Store/Actions/App'
import * as LoginActions from '../../Store/Actions/Login'

const Logoff = props => {


const screenStyle = {
  alignItems: "stretch",
  justifyContent: "top",
  width:"100vw",
  minHeight:"100vh",
  display:"flex",
  flexDirection:"column",
  backgroundImage: "url(" + backgroundHexagon + ")",
  backgroundSize:"cover",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"fixed"
}; 

const screenStyleLoading = {
  alignItems: "middle",
  justifyContent: "center",
  width:"100vw",
  height:"100vh",
  display: "flex",
  flexDirection: "column"
}; 

const styles = {
  indicator: {
    backgroundColor: '#A255A0',
  },
};

// Set functions
const handleChange = (event, newValue) => {
setValue(newValue);
};


// set classes var
// const classes = useStyles()();


// set vars from props
// Remember to add those who come from Redux
const { 
	innerLoading,
	setInnerLoading,
	processLogOff	
} = props;

// set Use of history for PUSH 
let navigate = useNavigate();

// Set internal state
const [contentVisible,setContentState] = useState(false);
const [loadingMessage,setLoadingMessage] = useState("Carregando...");
const [value, setValue] = useState(0); // state for tab

const [snack,setSnack] = useState(false); // state for tab
const [snackMessage, setSnackMessage] = useState(""); // state for tab

  
// Run after mount component
useEffect(() => {
  
 // Avoid user to go back on browser or hardware android butto
 /* window.navigateState(null, document.title, window.location.href);
  window.addEventListener('popstate', function(event) {
    window.navigateState(null, document.title, window.location.href);
	
});  */
  
	//[ DISPATCH ACTION ] checkEmail(NEXT STEP, DATA ,navigation OBJ);
	var props = {
		navigate:navigate,
		setSnack:setSnack,
		setSnackMessage:setSnackMessage		
	}			
	// Execute LOGOFF
	processLogOff(props);

 // Set Content Loading ON
 setContentState(true);

  // set scroll to top
  window.scrollTo(0, 1);
    
  // Runs when UNMOUNT component
  return () => {
    console.log("Desmontou o componente MainHome...");
  };
  
}, []); // end useEffect


  useEffect(() => {
    const blockNavigation = (event) => {
      // Bloqueia a navegação para trás
      event.preventDefault();
      // Navega programaticamente para a mesma rota (ou você pode escolher outra rota conforme necessário)
      navigate(window.location.pathname, { replace: true });
    };

    // Adiciona o listener ao evento 'popstate'
    window.addEventListener('popstate', blockNavigation);

    // Limpa o listener quando o componente é desmontado
    return () => {
      window.removeEventListener('popstate', blockNavigation);
    };
  }, [navigate]); // Dependências do useEffect


// Will Mount Component HOOK
useComponentWillMount(() => {
	
    // Set session of app
  var sessionCheck = sessionStorage.getItem('@motuum/appLaunched');
  
  // if session is not created, redirect to bootstrap. (avoid direct access using url)
  if (!sessionCheck) {
	  navigate("/");
  } // end if	
      
}, []); // end useComponentWillMount

const handleClose = () => {
	setInnerLoading(false);
};

	return (
	<Fragment>
			
			<Header title="Saindo do aplicativo..." buttons={{support:false,menu:false,close:false,back:false,rightFake:true,leftFake:true}} /> 
			
			<CSSTransition in={contentVisible} timeout={1000} classNames="fade"	unmountOnExit>	
			
				<Box className="mainProfile" style={{...screenStyle}} m={0}>

						<Box style={{marginBottom:"20px",backgroundColor:"transparent",alignItems:"center",display:"flex",flexDirection:"column"}} pl={5} pr={5} mt={15}>

								<div>
									<img src={checkIconBlue} alt="Ajuda" style={{width:"100px",height:"100px"}} />
								</div>

								<Box style={{marginTop:"30px",marginBottom:"30px"}}>						
									<div style={{fontFamily:"Roboto",fontWeight:"100",fontSize:"42px",color:"#003CFA",lineHeight:"38px"}}>	
										  VOCÊ SAIU DO APLICATIVO
									</div>								 	
								</Box> 	
								
								<div style={{fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px",color:"#636466"}}>Você deslogou o seu usuário nesse device. Caso queira autenticar novamente, faça o login. </div>								
								
						</Box>
											
						
						<Box style={{flexBasis:"20vh",backgroundColor:"transparent",display:"flex",alignItems:"center"}}>							
								
							<div style={{flex:1}}>						
									
									<Button variant="contained" color="primary"  style={{fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px",borderRadius:"10px",width:"80%",height:"53px"}} component={Link} to="/login">
									  Ir para o login
									</Button>	
									
							</div>	
								
						</Box>
														
				</Box>
			
			</CSSTransition> 
			
			<SnackNotification 
				snack={snack}
				setSnack={setSnack}
				snackMessage={snackMessage}
			/>			
				
	</Fragment>
)};


const SnackNotification = (props) => {
	
	//props.snackMessage = "Código enviado com sucesso! Aguarde alguns segundos para receber...";

	return (
		<Snackbar
			anchorOrigin={{
			  vertical: 'bottom',
			  horizontal: 'left',
			}}
			open={props.snack}
			autoHideDuration={4000}
			onClose={() => props.setSnack(false)}
		  >
		    <div style={{borderRadius:10,borderWidth:"1px",borderColor:"#222",padding:10,display:"flex",flexDirection:"row",alignItems:"center",flex:1,backgroundColor:"#666"}}>
				
				<div style={{flex:6,fontSize:"14px",textAlign:"left",color:"#fff"}}>{props.snackMessage}</div>
				
				<IconButton style={{flex:1,color:"#fff"}} size="small" aria-label="close" color="inherit" onClick={() => props.setSnack(false)}>
				  <CloseIcon fontSize="small" />
				</IconButton>
			  			
			</div>
		  </Snackbar>
	  )	
	
} // end AlertDialog

 

const mapStateToProps = store => ({
  innerLoading:store.appReducer.innerLoading
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AppActions,...LoginActions }, dispatch);

export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(Logoff);
 