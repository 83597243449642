// APP REDUCER 
export const INNER_LOADING = "app/inner/loading";
export const TOGGLE_DRAWER = "app/set/drawer";
export const SET_DIALOG = "app/set/dialog";
export const SET_APPLOADING = "app/bootstrap/loading";
export const SET_WAITINGAPPROVAL = "app/set/waitingapproval";
export const SET_APPSTART = "app/set/start";
export const SET_NEXTROUTE = "app/set/nextroute";
export const SET_USERAPPROVEDDATA = "app/set/userapproved/data";
export const SET_USERDATA = "app/set/user/data";
export const SET_ONLINESTATUS = "app/set/onlinestatus";
export const SET_TERMSCONTENT = "app/set/content/terms";
export const SET_CUSTOMER_CANCELTAX = "app/set/customer/canceltax";
export const SET_CUSTOMER_ALTERTAX = "app/set/customer/altertax";


// REGISTER REDUCER
export const BUTTON_LOADING = "app/button/loading";
export const SET_REGISTER_STEP0 = "register/step0/set";
export const SET_REGISTER_STEP1 = "register/step1/set";
export const SET_REGISTER_STEP2 = "register/step2/set";
export const SET_REGISTER_STEP3 = "register/step3/set";

// LOGIN REDUCER
export const SET_LOGIN_STEP0 = "login/step0/set";
export const SET_LOGIN_STEP1 = "login/step1/set";
export const SET_LOGIN_STEP2 = "login/step2/set";
export const SET_LOGIN = "login/login/set";
export const SET_LOGOFF = "login/logoff/set";

// SERVICE SEARCH
export const SET_PARENT_CATEGORIES = "service/search/set/parentcategories";
export const SET_CHILD_CATEGORIES = "service/search/set/childcategories";
export const SET_CATEGORIES = "service/search/set/categories";
export const SET_CATEGORY_BREADCRUMB = "service/search/set/category/breadcrumb";
export const SET_COUNTERTIMER = "service/search/set/timer";
export const SET_SEARCH_RESULT = "service/search/set/result";

// SERVICE HIRE REDUCER
export const RESET_SERVICE_HIRE = "service/hire/reset";
export const SET_SERVICE = "service/hire/set/service";
export const SET_SCHEDULE = "service/hire/set/schedule";
export const SET_ADDRESS = "service/hire/set/address";
export const SET_PAYMENT = "service/hire/set/payment";
export const SET_INVOICE = "service/hire/set/invoice";
export const SET_EXTRAINFO = "service/hire/set/extrainfo";
export const SET_ADDRESSES_CARDS = "service/hire/set/addressescards";
export const SET_CREDIT_CARDS = "service/hire/set/creditcards";
export const SET_INVOICES = "service/hire/set/invoices"; 

// SERVICE ORDERS REDUCER
export const SET_SCHEDULEDSERVICES = "service/order/scheduled/set";
export const SET_SCHEDULEDSERVICES_CANCELLED = "service/order/scheduled/cancelled/set";
export const SET_EXECUTEDSERVICES = "service/order/executed/set";
export const SET_ONGOINGSERVICES = "service/order/ongoing/set";
export const SET_CANCELLEDSERVICES = "service/order/cancelled/set";
export const SET_SELECTED_SCHEDULEDSERVICE = "service/order/selected/scheduled/set";
export const SET_SELECTED_EXECUTEDSERVICE = "service/order/selected/executed/set";
export const SET_SELECTED_ONGOINGSERVICE = "service/order/selected/ongoing/set";
export const SET_SELECTED_CANCELLEDSERVICE = "service/order/selected/cancelled/set";

// Chat Data
export const SET_CHAT_DATA = "service/order/chat/set";

// Chat 
export const SET_CHAT_CHANNEL_ID = "app/set/chat/channel/id";
export const SET_CHAT_CHANNEL = "app/set/chat/channel";
export const SET_CHAT_CUSTOMER = "app/set/chat/customer";
export const SET_CHAT_PROVIDER = "app/set/chat/provider";




