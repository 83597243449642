import './MainOnBoarding.css';
import React,{useState,useEffect,Fragment, useRef} from 'react';
import {Link,useNavigate} from 'react-router-dom';
import { CSSTransition } from "react-transition-group";

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

// import SwipeableViews from 'react-swipeable-views';
import { useSpring, animated } from 'react-spring'

import { Box,CircularProgress,Button } from '@mui/material';

import Zoom from '@mui/material/Zoom';

import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import PageviewOutlinedIcon from '@mui/icons-material/PageviewOutlined';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';

import {useComponentWillMount} from '../../Utils/customFunctions';

import logo from '../../Assets/Images/logo_motuum-white.svg';
import backgroundHexagon from '../../Assets/Images/backgroundHexagon.png'; 
import backgroundHeaderImage from '../../Assets/Images/backgroundHeader.png'; 
import helpIcon from '../../Assets/Images/help-icon-white.svg'; 
import paginationDotWhite from '../../Assets/Images/paginationDot-white.svg'; 
import fowardIconWhite from '../../Assets/Images/fowardIcon-white.svg';
import personIconWhite from '../../Assets/Images/personIcon-white.svg'; 
import searchIconWhite from '../../Assets/Images/searchIcon-white.svg';
import userIconWhite from '../../Assets/Images/userIcon-white.svg';
import calmIconWhite from '../../Assets/Images/calmIcon-white.svg';

// Load Actions
import * as AppActions from '../../Store/Actions/App'

import { register } from 'swiper/element/bundle';
register();

const OnBoarding = props => {
	

const screenStyle = {
  alignItems: "stretch",
  justifyContent: "flex-start",
  width:"100vw",
  height:"100vh",
  display:"flex",
  flexDirection:"column",
  backgroundColor:"#003CFA"
}; 

// set vars from props
// Remember to add those who come from Redux
const { 
	innerLoading,
	setInnerLoading
} = props;

// set Use of history for PUSH 
let navigate = useNavigate();

const swiperElRef = useRef(null);


// Set internal state
const [contentVisible,setContentState] = useState(false);
const [loadingMessage,setLoadingMessage] = useState("Carregando...");
const [toggle,setToggle] = useState(true);
const [currentSlide,setCurrentSlide] = useState(0);

const [styleAnim1, api] = useSpring(() => ({ opacity: 1 }));

//api.start({ opacity: toggle ? 1 : 0 });
//api.stop()
  
// Run after mount component
useEffect(() => {
	
  console.log("Montou o componente MainHome....");
  
  // Set Onbosrding screen as VIEWED
  localStorage.setItem("viewedOnboarding",true);
   
  // Show content after load for animation
 // setContentState(true);
 
 // Set Content Loading ON
 setInnerLoading(true);
 
 // Set Content Ready to show
  setContentState(true);
 
 // Simulate Delay
 setTimeout(function() {
	 
	  // Set Content Loading OFF
	 // setInnerLoading(false);
	  
	  // Set Content Ready to show
	 // setContentState(true);
	 
	
 },3000);

  // set scroll to top
  window.scrollTo(0, 1);
    
  // Runs when UNMOUNT component
  return () => {
    console.log("Desmontou o componente MainHome...");
  };
  
}, []); // end useEffect


// Will Mount Component HOOK
useComponentWillMount(() => {
	
  // Set session of app
  var sessionCheck = sessionStorage.getItem('@motuum/appLaunched');
  
  // if session is not created, redirect to bootstrap. (avoid direct access using url)
  if (!sessionCheck) {
	  navigate("/");
  } // end if
  
      
}, []); // end useComponentWillMount

const handleClose = () => {
	setInnerLoading(false);
};

const styles = {
  slide: {
    padding: 0,
    minHeight: "300px",
    color: '#fff',
  },
  slide1: {
    background: 'transparent',
  },
  slide2: {
    background: 'transparent',
  },
  slide3: {
    background: 'transparent',
  },
};

// Check which Slide
const checkSlide = (index) => {
	
	setCurrentSlide(index);
	
}

  useEffect(() => {
    // listen for Swiper events using addEventListener
    swiperElRef.current.addEventListener('swiperprogress', (e) => {
      const [swiper, progress] = e.detail;
      console.log(progress);
    });

    swiperElRef.current.addEventListener('swiperslidechange', (e) => {
      console.log('slide changed');
    });
  }, []);


/* Spring Animation*/
const propsAnim = useSpring({ config:{duration: 2000 }, to: { opacity: 1 }, from: { opacity: 0 }, delay: 1000, })

	return (
	<Fragment>
				
			<animated.div className="mainOnboarding" style={{...screenStyle,...propsAnim}} m={0}>
			
				<Box style={{marginTop:"15%",marginBottom:"7vh",alignItems:"flex-end",display:"flex"}}>	
				
					<Box style={{flex:1,alignItems:"center",display:"flex"}}>						
							<img src={logo} alt="Logomarca Motuum" style={{marginLeft:"47px",marginRight:"auto",width:"53%",height:"auto"}} />
					</Box>	
					
				</Box>		

				<swiper-container
				  ref={swiperElRef}
				  slides-per-view="1"
				  navigation="true"
				  pagination="true"
				  style={{width:"100%",height:"50%"}}
				>
				  <swiper-slide>
				  
						<Box style={{marginTop:"30px",backgroundColor:"transparent",alignItems:"center",display:"flex",flexDirection:"row",paddingLeft:"50px"}} mt={props.marginTop} pr={5}>

								<Box style={{marginTop:"0px",width:"239px"}}>						
									<div style={{fontFamily:"Roboto",fontWeight:"normal",fontSize:"20px",color:"#FFF",lineHeight:"26px",textAlign:"left"}}>	
										 Nós <span style={{fontWeight:"bold"}}>conectamos pessoas</span> a <span style={{fontWeight:"bold"}}>profissionais de TI</span> e <span style={{fontWeight:"bold"}}>Telecomunicação</span> para que seu <span style={{fontWeight:"bold"}}>problema</span> seja <span style={{fontWeight:"bold"}}>rapidamente resolvido</span>.
									</div>								 	
								</Box> 	
								
						</Box>					  
				  
				  </swiper-slide>
				  <swiper-slide>
				  
						<Box style={{marginTop:"30px",position:"relative",height:"300px",overflow:"hidden",marginLeft:"53px"}} mt={props.marginTop} >

								<Box style={{position:"absolute",left:0,top:"10px",width:"180px"}}>						
									<div style={{fontFamily:"Roboto",fontWeight:"normal",fontSize:"20px",color:"#FFF",lineHeight:"26px",textAlign:"left"}}>	
										 <span style={{fontWeight:"bold"}}>Crie seu perfil</span><br /> e conecte-se
									</div>								 	
								</Box> 	
								
								<div style={{position:"absolute",right:"-65px",bottom:"18px",...styleAnim1}}>
									<img src={personIconWhite} alt="User" style={{width:"290px",height:"auto"}} />
								</div>								
								
						</Box>					  
				  
				  </swiper-slide>
				  <swiper-slide>
				  
						<Box style={{marginTop:"30px",position:"relative",height:"300px",overflow:"hidden",marginLeft:"53px"}} mt={props.marginTop} >

								<Box style={{position:"absolute",left:0,top:"10px",width:"180px"}}>						
									<div style={{fontFamily:"Roboto",fontWeight:"normal",fontSize:"20px",color:"#FFF",lineHeight:"26px",textAlign:"left"}}>	
										 <span style={{fontWeight:"bold"}}>Encontre o<br /> serviço</span> desejado
									</div>								 	
								</Box> 	
								
								<div style={{position:"absolute",right:"-65px",bottom:"18px",...styleAnim1}}>
									<img src={searchIconWhite} alt="User" style={{width:"290px",height:"auto"}} />
								</div>								
								
						</Box>				  
				  
				  </swiper-slide>
				  
				  <swiper-slide>
				  
						<Box style={{marginTop:"30px",position:"relative",height:"300px",overflow:"hidden",marginLeft:"53px"}} mt={props.marginTop}>

								<Box style={{position:"absolute",left:0,top:"10px",width:"180px"}}>						
									<div style={{fontFamily:"Roboto",fontWeight:"normal",fontSize:"20px",color:"#FFF",lineHeight:"26px",textAlign:"left"}}>	
										 Solicite um<br /> <span style={{fontWeight:"bold"}}>profissional</span>
									</div>								 	
								</Box> 	
								
								<div style={{position:"absolute",right:"-65px",bottom:"18px",...styleAnim1}}>
									<img src={userIconWhite} alt="User" style={{width:"290px",height:"auto"}} />
								</div>								
								
						</Box>				  
				  
				  </swiper-slide>	
				  
				  <swiper-slide>
				  
						<Box style={{marginTop:"30px",position:"relative",height:"300px",overflow:"hidden",marginLeft:"53px"}} mt={props.marginTop} >

								<Box style={{position:"absolute",left:0,top:"10px",width:"180px"}}>						
									<div style={{fontFamily:"Roboto",fontWeight:"normal",fontSize:"20px",color:"#FFF",lineHeight:"26px",textAlign:"left"}}>	
										 <span style={{fontWeight:"bold"}}>Pronto!</span> Agora<br /> É só relaxar
									</div>								 	
								</Box> 	
								
								<div style={{position:"absolute",right:"-65px",bottom:"18px",...styleAnim1}}>
									<img src={calmIconWhite} alt="User" style={{width:"290px",height:"auto"}} />
								</div>								
								
						</Box>				  
				  
				  </swiper-slide>				  

				</swiper-container>				
			
			{/*<SwipeableViews resistance index={currentSlide}  onChangeIndex={(index, indexLatest, meta)=> { console.log("INDEX:"+index+" and INDEX LATEST:"+indexLatest+" and META:"+meta+"");checkSlide(index) }} >
			  
				<div style={Object.assign({}, styles.slide, styles.slide1)} style={{backgroundColor:"transparent"}}>
				  
						<Box style={{marginTop:"30px",backgroundColor:"transparent",alignItems:"center",display:"flex",flexDirection:"row",paddingLeft:"50px"}} mt={props.marginTop} pr={5}>

								<Box style={{marginTop:"0px",width:"239px"}}>						
									<div style={{fontFamily:"Roboto",fontWeight:"normal",fontSize:"20px",color:"#FFF",lineHeight:"26px",textAlign:"left"}}>	
										 N?s <span style={{fontWeight:"bold"}}>conectamos pessoas</span> a <span style={{fontWeight:"bold"}}>profissionais de TI</span> e <span style={{fontWeight:"bold"}}>Telecomunica??o</span> para que seu <span style={{fontWeight:"bold"}}>problema</span> seja <span style={{fontWeight:"bold"}}>rapidamente resolvido</span>.
									</div>								 	
								</Box> 	
								
						</Box>		  
				  
				</div>
				<div style={Object.assign({}, styles.slide, styles.slide2)}>
					

						<Box style={{marginTop:"30px",position:"relative",height:"300px",overflow:"hidden",marginLeft:"53px"}} mt={props.marginTop} >

								<Box style={{position:"absolute",left:0,top:"10px",width:"180px"}}>						
									<div style={{fontFamily:"Roboto",fontWeight:"normal",fontSize:"20px",color:"#FFF",lineHeight:"26px",textAlign:"left"}}>	
										 <span style={{fontWeight:"bold"}}>Crie seu perfil</span><br /> e conecte-se
									</div>								 	
								</Box> 	
								
								<div style={{position:"absolute",right:"-65px",bottom:"18px",...styleAnim1}}>
									<img src={personIconWhite} alt="User" style={{width:"290px",height:"auto"}} />
								</div>								
								
						</Box>	


				</div>
				
				<div style={Object.assign({}, styles.slide, styles.slide3)}>

						<Box style={{marginTop:"30px",position:"relative",height:"300px",overflow:"hidden",marginLeft:"53px"}} mt={props.marginTop} >

								<Box style={{position:"absolute",left:0,top:"10px",width:"180px"}}>						
									<div style={{fontFamily:"Roboto",fontWeight:"normal",fontSize:"20px",color:"#FFF",lineHeight:"26px",textAlign:"left"}}>	
										 <span style={{fontWeight:"bold"}}>Encontre o<br /> serviço</span> desejado
									</div>								 	
								</Box> 	
								
								<div style={{position:"absolute",right:"-65px",bottom:"18px",...styleAnim1}}>
									<img src={searchIconWhite} alt="User" style={{width:"290px",height:"auto"}} />
								</div>								
								
						</Box>	

				</div>
				

				<div style={Object.assign({}, styles.slide, styles.slide3)}>

						<Box style={{marginTop:"30px",position:"relative",height:"300px",overflow:"hidden",marginLeft:"53px"}} mt={props.marginTop}>

								<Box style={{position:"absolute",left:0,top:"10px",width:"180px"}}>						
									<div style={{fontFamily:"Roboto",fontWeight:"normal",fontSize:"20px",color:"#FFF",lineHeight:"26px",textAlign:"left"}}>	
										 Solicite um<br /> <span style={{fontWeight:"bold"}}>profissional</span>
									</div>								 	
								</Box> 	
								
								<div style={{position:"absolute",right:"-65px",bottom:"18px",...styleAnim1}}>
									<img src={userIconWhite} alt="User" style={{width:"290px",height:"auto"}} />
								</div>								
								
						</Box>	

				</div>


				<div style={Object.assign({}, styles.slide, styles.slide3)}>

						<Box style={{marginTop:"30px",position:"relative",height:"300px",overflow:"hidden",marginLeft:"53px"}} mt={props.marginTop} >

								<Box style={{position:"absolute",left:0,top:"10px",width:"180px"}}>						
									<div style={{fontFamily:"Roboto",fontWeight:"normal",fontSize:"20px",color:"#FFF",lineHeight:"26px",textAlign:"left"}}>	
										 <span style={{fontWeight:"bold"}}>Pronto!</span> Agora<br /> ? s? relaxar
									</div>								 	
								</Box> 	
								
								<div style={{position:"absolute",right:"-65px",bottom:"18px",...styleAnim1}}>
									<img src={calmIconWhite} alt="User" style={{width:"290px",height:"auto"}} />
								</div>								
								
						</Box>		

				</div>				
				
				
				</SwipeableViews>*/}
			  
				<Box style={{marginTop:"4vh",backgroundColor:"transparent",alignItems:"center",display:"flex",flexDirection:"column"}}>	
				
					<Box style={{flex:1,alignItems:"center",display:"flex",flexDirection:"row",justifyContent:"center"}}>

						<div style={{marginRight:"10px",opacity:currentSlide == 0 ? "1" : "0.3"}} onClick={()=>setCurrentSlide(0)}>
							<img src={paginationDotWhite} alt="Step1" style={{width:"50px",height:"6px"}} />
						</div>
						
						<div style={{marginRight:"10px",opacity:currentSlide == 1 ? "1" : "0.3"}} onClick={()=>setCurrentSlide(1)}>
							<img src={paginationDotWhite} alt="Step2" style={{width:"50px",height:"6px"}} />
						</div>

						<div style={{marginRight:"10px",opacity:currentSlide == 2 ? "1" : "0.3"}} onClick={()=>setCurrentSlide(2)}>
							<img src={paginationDotWhite} alt="Step3" style={{width:"50px",height:"6px"}} />
						</div>

						<div style={{marginRight:"10px",opacity:currentSlide == 3 ? "1" : "0.3"}} onClick={()=>setCurrentSlide(3)}>
							<img src={paginationDotWhite} alt="Step4" style={{width:"50px",height:"6px"}} />
						</div>

						<div style={{opacity:currentSlide == 4 ? "1" : "0.3"}} onClick={()=>setCurrentSlide(4)}>
							<img src={paginationDotWhite} alt="Step5" style={{width:"50px",height:"6px"}} />
						</div>						
					
					</Box>	
					
					<Box style={{paddingRight:"13vw",marginTop:"30px",flex:1,alignItems:"center",display:"flex",alignSelf:"flex-end"}}>						
							
							<div style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"flex-end"}} onClick={()=> navigate("/register")} >
									
								<div style={{fontFamily:"Roboto",fontSize:"11px",color:"#FFFFFF",height:"20px"}}>PULAR APRESENTAÇÃO</div>	
								<div><img src={fowardIconWhite} alt="Jump" style={{width:"18px",height:"18px"}} /></div>	

							</div>	

					</Box>					
					
				</Box>				  
			
			</animated.div>
				
	</Fragment>
)};

const mapStateToProps = store => ({
  innerLoading:store.appReducer.innerLoading
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AppActions }, dispatch);

export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(OnBoarding);
 
