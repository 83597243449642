import './Header.css'
import React,{useState,useEffect} from 'react'
import { useNavigate,useLocation } from "react-router-dom"

import { CSSTransition } from "react-transition-group"

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// // import LiveChat from 'react-livechat'

// Load Actions
import * as AppActions from '../../Store/Actions/App'

import If from '../../Components/Common/If'
import {SupportComponent} from '../../Screens/Support/SupportComponentHeader'

import MenuIcon from '@mui/icons-material/Menu'
import HelpIcon from '@mui/icons-material/Help'
import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import Slide from '@mui/material/Slide'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

import UserDrawer from './UserDrawer'

import backgroundHeaderImage from '../../Assets/Images/backgroundHeader.png' // Tell Webpack this JS file uses this image
import logo from '../../Assets/Images/logo_motuum-white.svg' // Tell Webpack this JS file uses this image
import backIcon from '../../Assets/Images/backIcon.svg' // Tell Webpack this JS file uses this image

import {
	AppBar,
	Drawer,
	Toolbar,
	Typography,
	IconButton,
    Box,
	Divider,
	Button,
	TextField,
	InputAdornment
} from '@mui/material'


const Bar = props => {
	
let navigate =  useNavigate();

// Set var from props
const {
	leftDrawer,
	rightDrawer,
	title,
	toggleDrawer,
	setDialog,
	userData,
	dialog
} = props

// Set Component State
const [boxVisible,setBoxState] = useState(false)

const location = useLocation()	

// After mount component
useEffect(() => {
	
	// If UserData (coming from REDUX) is empty, go to Splashscreen to load data 
	// Select WHICH screen to redirect...
	if (!userData.email && (location.pathname == '/hireService' || (location.pathname == '/home' || location.pathname == '/home/activate/step1' || location.pathname == '/home/activate/step2' ) || location.pathname == '/manageProfile' || location.pathname == '/searchService' ) ) {
		navigate("/")
		return
	}

// you can still use new StreamChat("api_key")? 	

	// Reload App 
	/*if (!userData.email) {	  
		navigate("/")  
		return
	} // end if*/		
	
  setBoxState(true)
  
}, [])


const backgroundHeaderModal = {
  backgroundImage: "url(" + backgroundHeaderImage + ")",
  backgroundSize:"cover",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"center",
  display:"flex",
  alignItems:"center",
  justifyContent:"center",
  height:props.buttons.home ? '150px': props.buttons.service ? "185px" : props.buttons.serviceHire ? "150px" : "80px"
} 	


return (
    <header style={{flexGrow: 0}}> 

	<Slide direction="down" in={boxVisible} timeout={1000} mountOnEnter unmountOnExit>				
      <AppBar position="fixed" style={{...backgroundHeaderModal}}>
        <Toolbar style={{width:"100%",height:props.buttons.home ? "86px" : "56px"}}>
		
		<If test={props.buttons.menu}>
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => toggleDrawer(true)}>
            <MenuIcon style={{width:"30px",height:"auto"}} />
          </IconButton>
		</If>
		
		<If test={props.buttons.back}>
			{props.buttons.backUrl &&
			  <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => navigate(props.buttons.backUrl)}>
				<img src={backIcon} alt="Voltar" style={{width:"30px",height:"auto"}} />
			  </IconButton>						
			}
			
			{!props.buttons.backUrl &&
			  <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => navigate(-1)}>
				<img src={backIcon} alt="Voltar" style={{width:"30px",height:"auto"}} />
			  </IconButton>			
			}
		</If>
		
		<If test={props.buttons.leftFake}>
          <IconButton disabled edge="start"  style={{minWidth:"54px"}} color="inherit" />            
		</If>
		
          <div style={{
				flexGrow: 1,
				display:"flex",
				alignItems:"center",
				justifyContent:"center"
		  }}>
			  <img src={logo} alt="Motuum" style={{marginLeft:"auto",marginRight:"auto",width:"50%",height:"auto"}} />
          </div>
          
		 <If test={props.buttons.support}>
		  <IconButton edge="end" color="inherit" aria-label="support" onClick={()=>setDialog('helpComplete',true)} >
            <HelpIcon />
          </IconButton>
		 </If>
		 
		 {/*onClick={() => toggleDrawer('right',true)}*/}
		 
		<If test={props.buttons.rightFake}>
          <IconButton disabled edge="end" color="inherit" style={{minWidth:"54px"}} />
		</If>		 
		 
		 <If test={props.buttons.close}>
		  <IconButton edge="end" color="inherit" aria-label="close" >
            <CloseIcon />
          </IconButton>
		 </If>		 
		  
        </Toolbar>
		
		{props.buttons.home &&
		<>
			<div style={{marginTop: "7px",width:"100%",height:"100px",display:"flex",justifyContent:"center",alignItems:"flex-start"}}>
				<TextField
				  style={{ margin: 0,marginLeft:0,marginRight:0,height:"54px",backgroundColor:"#fff",width:"90%",fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px"}}
				  placeholder="Busque por um serviço"
				  variant="outlined"
				  type="text"
				  InputProps={{
						endAdornment:<InputAdornment position="end"><IconButton aria-label="search"><SearchIcon style={{width:"30px",height:"auto"}} /></IconButton></InputAdornment>
					}}	
				/>
			</div>
		</>
		}	

		{props.buttons.service &&
		<>
			<div style={{marginTop: "0px",width:"100%",height:"185px",position:"relative",overflow:"hidden"}}>
				
				<img src={props.buttons.serviceIcon} style={{width:"100%",height:"auto",left:"0px",position:"absolute",bottom:"0px"}} />
				
			</div>
		</>
		}	

		{props.buttons.serviceHire &&
		<>
			<div style={{marginTop: "0px",width:"100%",height:"160px",position:"relative",overflow:"hidden"}}>
				
				<div style={{marginTop:"25px"}}>
					<div style={{color:"#FFFFFF",fontFamily:"Roboto",fontWeight:"590",fontSize:"22px"}}>{props.buttons.serviceName}</div>
					<div style={{color:"#FFFFFF",marginTop:"3px",fontFamily:"Roboto",fontWeight:"normal",fontSize:"12px"}}>{props.buttons.serviceCategory}</div>
				</div>
				
			</div>
		</>
		}			
		
      </AppBar>  
	  
	  </Slide>	

	{/*  User Drawer from Left */}
	<Drawer anchor='left' open={leftDrawer} onClose={() => toggleDrawer(!leftDrawer)}>
			<UserDrawer direction="left" />
	 </Drawer>	
	
	{/* LIVE Chat component */}	
	{/*<LiveChat license={14088816} />*/}	
	
	{/* Help Dialog */}
	<HelpDialog screen={props.screen} />
	  
    </header>	
)

}

// Bind States to Props
const mapStateToProps = store => ({
  leftDrawer:store.appReducer.leftDrawer,
  dialog:store.appReducer.dialog,
  userData:store.appReducer.userData,
  chatData:store.appReducer.chatData
})

// Bind Actions to Propos
const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AppActions }, dispatch)
  

// Transition Slide for Dialog SLIDE UP	
const TransitionSlideUp = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />  
})	


/* Extra  Dialog Component START */
export const HelpDialog = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {
		
const backgroundHeaderModal = {
  backgroundImage: "url(" + backgroundHeaderImage + ")",
  backgroundSize:"cover",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"center",
  display:"flex",
  alignItems:"center",
  justifyContent:"center"
} 			

const screenStyleDialog = {
  alignItems: "center",
  justifyContent: "top",
  width:"100vw",
  display:"flex",
  flexDirection:"column",
  backgroundColor:"#F0F0F0",
  flex:1
} 

const { 
	innerLoading,
	setInnerLoading,
	setDialog,
	dialog
} = props

	return (
	<div>
		  <Dialog fullScreen open={dialog.target == 'helpComplete' && dialog.status} onClose={()=>setDialog('helpComplete',false)} TransitionComponent={TransitionSlideUp}>
		  
			<AppBar sx={{
				position: 'fixed',
				height:'80px',
				...backgroundHeaderModal	
			}}>
			  <Toolbar style={{width:"100%"}}>
				  <div style={{
						marginLeft: 10,
						flex: 1,
						textAlign:"center",
						display:"flex",
						justifyContent:"center",
						alignItems:"center"	
				  }}>
				    <img src={logo} alt="Motuum" style={{marginLeft:"auto",marginRight:"auto",width:"40%",height:"auto"}} />
				  </div>
				</Toolbar>
			</AppBar>

			<Box style={{...screenStyleDialog,backgroundColor:"#FFF",paddingLeft:"20px",paddingRight:"20px"}} m={0}>
			
				<Box mt={11} ml={0} mr={0}>
					
					<Box pt={2}>

						<SupportComponent screen={props.screen} setDialog={setDialog} dialog={dialog} />
							
						<Box style={{backgroundColor:"transparent",display:"flex",flexDirection:"row",justifyContent:"center"}} ml={0} mr={0} mb={5} mt={0} pl={5} pr={5}>						
							
							<Button color="primary" variant="outlined" style={{width:"100%",height:"54px",borderRadius:"10px",fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px",}} onClick={()=>setDialog('helpComplete',false)}>
							 Fechar
							</Button>	
								
						</Box>							

					</Box>
			
				</Box>				
			
			</Box>

		  </Dialog>
		</div>
		
	)
	
})

  

export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(Bar)


