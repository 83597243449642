import './MainExecutedServices.css';
import React,{useState,useEffect,Fragment} from 'react';
import {Link,useNavigate,useParams} from 'react-router-dom';

import PropTypes from 'prop-types';
import { CSSTransition } from "react-transition-group";

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

import moment from 'moment';
import 'moment/locale/pt-br';

import { Box,Backdrop,CircularProgress,Typography,Button,InputAdornment,IconButton,TextField,Divider } from '@mui/material';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import WarningIcon from '@mui/icons-material/Warning';
import LockIcon from '@mui/icons-material/Lock';
import Slide from '@mui/material/Slide';
import Fade from '@mui/material/Fade';
import Zoom from '@mui/material/Zoom';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Danger from '@mui/icons-material/Error';

import helpIcon from '../../Assets/Images/help-icon-blue.svg'; 
import deviceIcon from '../../Assets/Images/deviceIcon.svg'; 
import chatComplain from '../../Assets/Images/iconComplain-blue.svg'; 
import chatIcon from '../../Assets/Images/iconChat-blue.svg';
import chatSupportIcon from '../../Assets/Images/chatIcon.svg'; 
import profileIcon from '../../Assets/Images/profileIcon.svg';
import userProfileIcon from '../../Assets/Images/userProfileIcon.svg';
import passwordProfileIcon from '../../Assets/Images/passwordProfileIcon.svg';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

import InputLabel from '@mui/material/InputLabel';
import ButtonGroup from '@mui/material/ButtonGroup';

import InputMask from "react-input-mask";

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import NiceInputPassword from 'react-nice-input-password';

import {useComponentWillMount} from '../../Utils/customFunctions';
import Header from '../../Components/Layout/Header';
import If from '../../Components/Common/If';

import logo from '../../Assets/Images/logo_motuum-white.svg';
import backgroundHexagon from '../../Assets/Images/backgroundHexagon.png'; 
import backgroundHeaderImage from '../../Assets/Images/backgroundHeader.png'; 
import backIcon from '../../Assets/Images/backIcon.svg';
import infoIcon from '../../Assets/Images/infoIcon-red.svg'; 



// Load Actions
import * as AppActions from '../../Store/Actions/App'
import * as ServiceActions from '../../Store/Actions/Service'

const ProfileManage = props => {

const screenStyle = {
  alignItems: "stretch",
  justifyContent: "top",
  width:"100vw",
  minHeight:"100vh",
  display:"flex",
  flexDirection:"column",
  backgroundImage: "url(" + backgroundHexagon + ")",
  backgroundSize:"cover",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"center"
}; 

const screenStyleLoading = {
  alignItems: "middle",
  justifyContent: "center",
  width:"100vw",
  height:"100vh",
  display: "flex",
  flexDirection: "column"
}; 

// set classes var
// const classes = useStyles()();

// Set TABPANEL
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
	  hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


// set vars from props
// Remember to add those who come from Redux
const { 
	innerLoading,
	setInnerLoading,
	setDialog,
	dialog,
	UserData,
	retrieveComplaintCategories,
	retrieveComplaintSubCategories,
	retrieveServiceComplaint,
	createServiceComplaintReply,
	updateServiceComplaint
} = props;

let { id } = useParams();
const [serviceComplaintId,setServiceComplaintId] = useState(id);
const [serviceOrderId,setServiceOrderId] = useState(null);
const [serviceComplaintCommentId,setServiceComplaintCommentId] = useState(null);

// set Use of history for PUSH 
let navigate = useNavigate();

// Set internal state
const [contentVisible,setContentState] = useState(false);
const [loadingMessage,setLoadingMessage] = useState("Carregando...");
const [loading, setLoading] = useState(true); 
const [saving, setSaving] = useState(false); 
const [updating, setUpdating] = useState(false); 

const [dialogAction, setDialogAction] = useState(false); 
const [dialogAlert, setAlertDialog] = useState(false); 
const [dialogMessage, setDialogMessage] = useState("");
const [dialogTitle, setDialogTitle] = useState("Verifique as informações");
const [alert, setAlert] = useState(false); 
const [confirm, setConfirm] = useState(false); 
const [confirmActionValue, setConfirmActionValue] = useState(null); 

const [reportProblem,setReportProblem] = React.useState({});
const [newReport,setNewReport] = React.useState(true);

const [problemType, setProblemType] = React.useState(1); // 1 - problem  2 - claim  3 - complaint
const [selectProblem, setSelectProblem] = React.useState(""); 
const [selectSubProblem, setSelectSubProblem] = React.useState(""); 
const [title, setTitle] = useState("");
const [comment, setComment] = useState("");

const [problemCategories, setProblemCategories] = useState([]); 
const [problemSubCategories, setProblemSubCategories] = useState([]); 
const [actionToTake, setActionToTake] = useState(null); 

const confirmAction = (action) => {
		
	if (action == 'comment') { 	
		
		// Check if terms was accepted
		if (!comment) {		
			setDialogTitle("Verifique as informações");
			setDialogMessage("Você deve preencher todos os campos marcados com * para prosseguir");
			setAlert(true);	
			return false;		
		} // end if	
		
		// set title
		setDialogTitle("Tem certeza que quer prosseguir?");
		setDialogMessage("Ao prosseguir o seu comentário ou resposta será enviado.");

	
	} // end if
	else {
		
		// set title
		setDialogTitle("Tem certeza que quer prosseguir?");
		setDialogMessage("Ao prosseguir você estará encerrando essa solicitação como concluída.");
			
	} // end else

	// Open confirmation alert
	setConfirmActionValue(action);	
	setConfirm(true);	
	
}


const saveAction = (props) => {
	
	setSaving(true);
		
	// Retrieve Scheduled Services
	var data = {
		serviceOrderId:serviceOrderId,
		serviceComplaintId:serviceComplaintId,
		serviceComplaintCommentId:serviceComplaintCommentId,
		comment:comment		
	}
	var props = {
		setAlert:setAlert,
		setDialogMessage:setDialogMessage,
		setDialogTitle:setDialogTitle,
		setLoading:setSaving,
		setDialogAction:setDialogAction,
		loadData:loadData,
		setComment:setComment
	}	
	createServiceComplaintReply(data,props);	

}

const updateAction = (props) => {
	
	setUpdating(true);
		
	// Retrieve Scheduled Services
	var data = {
		serviceOrderId:serviceOrderId,
		serviceComplaintId:serviceComplaintId		
	}
	var props = {
		setAlert:setAlert,
		setDialogMessage:setDialogMessage,
		setDialogTitle:setDialogTitle,
		setLoading:setUpdating,
		setDialogAction:setDialogAction,
		loadData:loadData
	}	
	updateServiceComplaint(data,props);	
	
}

const retrieveSubProblem = (value) => {
	
	//setSubProblemLoading(true);
		
	// Retrieve Scheduled Services
	var data = {
		categoryId:value
	}
	var props = {
		setAlert:setAlert,
		setDialogMessage:setDialogMessage,
		setDialogTitle:setDialogTitle,
		setLoading:setLoading,
		setDialogAction:setDialogAction,
		setProblemSubCategories:setProblemSubCategories
	}	
	retrieveComplaintSubCategories(data,props);	
	
	setActionToTake(null);

}

// Load Boostrap Data
const loadData = () => {
	
	// Retrieve Complaint Categories
	var data = {
		categoryId:0
	}
	var propsParams = {
		setAlert:setAlert,
		setDialogMessage:setDialogMessage,
		setDialogTitle:setDialogTitle,
		setDialogAction:setDialogAction,
		setProblemCategories:setProblemCategories
	}	
	retrieveComplaintCategories(data,propsParams);	
	
	// Retrieve Service Complaints list
	var data = {
		serviceComplaintId:serviceComplaintId
	}
	var propsParams = {
		setLoading:setLoading,
		setReportProblem:setReportProblem,
		setNewReport:setNewReport,
		setLoading:setLoading
	}		
	retrieveServiceComplaint(data,propsParams);

}


// Run after mount component
useEffect(() => {
	
	// Retrieve User Data Obj. 
	var result = localStorage.getItem("@motuum/userData", undefined,true);
	var userData = JSON.parse(result);  
  
	  // If token dont exist, redirect to onboarding or register
	  if (!userData) {	  
		navigate("/");  
	  } // end if
	  // If token exist, redirect to HOME
	  else {
		  if (!userData.userToken) {
			navigate("/");
		  } // end if
	  } // end else    
	
	// Load data
	loadData();	
 
 // Set Content Loading ON
 //setInnerLoading(true);
 setContentState(true);

  // set scroll to top
  window.scrollTo(0, 1);
    
  // Runs when UNMOUNT component
  return () => {
   
  };
  
}, []); // end useEffect


// Run after retrieve Complaint Object
useEffect(() => {
	
	if (reportProblem.categoryId) {
		retrieveSubProblem(reportProblem.categoryId);
	} // end if

	if (reportProblem.id) {
		setServiceOrderId(reportProblem.ServiceOrderId);		
		setServiceComplaintCommentId(reportProblem.ServiceOrderComplaintComments.length > 0 ? reportProblem.ServiceOrderComplaintComments[0].id : null)
	} // end if
  
}, [reportProblem]); // end useEffect


// Will Mount Component HOOK
useComponentWillMount(() => {
	
    // Set session of app
  var sessionCheck = sessionStorage.getItem('@motuum/appLaunched');
  
  // if session is not created, redirect to bootstrap. (avoid direct access using url)
  if (!sessionCheck) {
	  navigate("/");
  } // end if	
  
      
}, []); // end useComponentWillMount


const handleKeypress = e => {	
	 //it triggers by pressing the enter key  
	if (e.key === "Enter") {
	  saveAction(props);
	}
};	


	return (
	<Fragment>
			
			<Header title="Reportar Problema" buttons={{support:false,rightFake:true,menu:false,back:true,close:false}} screen="ProfileManage" />
				
			{/* Inner Loading */}
			{loading &&
					<Box className="loadingContent" style={{...screenStyleLoading}}>
							<Box mt={0} mx={0}>								
								<CircularProgress /> 
								<div> Carregando...Aguarde!</div>								
							</Box>	
					</Box>
			}	

			<Fade in={!loading} timeout={1000}  mountOnEnter unmountOnExit>			
			
				<Box className="mainHome" style={{...screenStyle}} mt={10}>
											
					<Box style={{marginTop:"20px",backgroundColor:"transparent"}} mt={props.marginTop} pl={5} pr={5} pb={5}>
														
							{reportProblem.id &&
							<>											
										
									<div style={{width:"100%",marginTop:"10px",marginBottom:"0px",textAlign:"left",color:"#003CFA",fontFamily:"Roboto",fontWeight:"regular",fontSize:"16px",fontWeight:"bold"}}> Tipo da solicitação </div>
									<div style={{width:"100%",marginTop:"5px",marginBottom:"10px",textAlign:"left",color:"#636466",fontFamily:"Roboto",fontWeight:"regular",fontSize:"16px"}}>

										{reportProblem.type == 1 &&
											<span>Problema</span>
										}
										
										{reportProblem.type == 2 &&
											<span>Reclamação</span>
										}

										{reportProblem.type == 3 &&
											<span>Denúncia</span>
										}
									
									</div>	
									
									 <Divider style={{marginTop:"15px",marginBottom:"15px"}} />		

									<div style={{width:"100%",marginTop:"10px",marginBottom:"0px",textAlign:"left",color:"#003CFA",fontFamily:"Roboto",fontWeight:"regular",fontSize:"16px",fontWeight:"bold"}}> Status </div>
									<div style={{width:"100%",marginTop:"5px",marginBottom:"10px",textAlign:"left",color:"#636466",fontFamily:"Roboto",fontWeight:"regular",fontSize:"16px"}}>

										{reportProblem.status == 0 &&
											<span style={{color:"blue"}}>Enviada</span>
										}
										
										{reportProblem.status == 1 &&
											<span style={{color:"orange"}}>Em análise</span>
										}
										
										{reportProblem.status == 3 &&
											<span style={{color:"green"}}>Finalizado por você</span>
										}
										
										{reportProblem.status == 2 &&
											<span style={{color:"green"}}>Finalizado pela plataforma</span>
										}
									
									</div>		

									<Divider style={{marginTop:"15px",marginBottom:"15px"}} />
									
										
								{reportProblem.type == 1 &&
								<>
								
									<div style={{width:"100%",marginTop:"10px",marginBottom:"0px",textAlign:"left",color:"#003CFA",fontFamily:"Roboto",fontWeight:"regular",fontSize:"16px",fontWeight:"bold"}}> Tipo do Problema </div>
									
									<div style={{width:"100%",marginTop:"5px",marginBottom:"10px",textAlign:"left",color:"#636466",fontFamily:"Roboto",fontWeight:"regular",fontSize:"16px"}}> 
										{problemCategories.map((item, index) => {
											
											if (item.id == reportProblem.categoryId) {
												return (<span>{item.label}</span>) 
											}
											
										}		
										)}
									</div>
									
									<Divider style={{marginTop:"15px",marginBottom:"15px"}} />	

									{reportProblem.type == 1 && reportProblem.categoryId &&
									<>
										
											<div style={{width:"100%",marginTop:"10px",marginBottom:"0px",textAlign:"left",color:"#003CFA",fontFamily:"Roboto",fontWeight:"regular",fontSize:"16px",fontWeight:"bold"}}> Subtipo do Problema </div>
											<div style={{width:"100%",marginTop:"5px",marginBottom:"10px",textAlign:"left",color:"#636466",fontFamily:"Roboto",fontWeight:"regular",fontSize:"16px"}}>

												{problemSubCategories.map((item, index) => {
													
													if (item.id == reportProblem.subCategoryId) {
														return (<span>{item.label}</span>) 
													}
													
												}		
												)}
											
											</div>
											
											<Divider style={{marginTop:"15px",marginBottom:"15px"}} />	
								
									</>
									}								
								
								</>
								}
							
							
								  <div style={{width:"100%",marginTop:"10px",marginBottom:"0px",textAlign:"left",color:"#003CFA",fontFamily:"Roboto",fontWeight:"regular",fontSize:"16px",fontWeight:"bold"}}> Título </div>
								 <div style={{width:"100%",marginTop:"10px",marginBottom:"0px",textAlign:"left",color:"#636466",fontFamily:"Roboto",fontWeight:"regular",fontSize:"16px"}}> {reportProblem.title} </div>
								 
								 <Divider style={{marginTop:"15px",marginBottom:"15px"}} />		
						
								<div style={{width:"100%",marginTop:"10px",marginBottom:"0px",textAlign:"left",color:"#003CFA",fontFamily:"Roboto",fontWeight:"regular",fontSize:"16px",fontWeight:"bold"}}> Descrição do Problema </div>
								<div style={{width:"100%",marginTop:"10px",marginBottom:"0px",textAlign:"left",color:"#636466",fontFamily:"Roboto",fontWeight:"regular",fontSize:"16px"}}> {reportProblem.details} </div>
								
								{reportProblem.ServiceOrderComplaintComments.length > 0 &&
								<>
								<Divider style={{marginTop:"15px",marginBottom:"15px"}} />	
								
								<div style={{width:"100%",marginTop:"10px",marginBottom:"0px",textAlign:"left",color:"#003CFA",fontFamily:"Roboto",fontWeight:"regular",fontSize:"16px",fontWeight:"bold"}}> Interações </div>
								<div style={{width:"100%",marginTop:"10px",marginBottom:"0px",textAlign:"left",color:"#636466",fontFamily:"Roboto",fontWeight:"regular",fontSize:"16px"}}> 

										{reportProblem.ServiceOrderComplaintComments.map((item, index) => {
													
											var createdAt = moment(item.createdAt).format("DD/MM/Y HH:mm");
											var replies = item.ServiceOrderComplaintCommentReplies;
																			
											return (
										<div key={item.id} style={{width:"90%",marginTop:"10px",marginBottom:"0px",textAlign:"left",color:"#636466",fontFamily:"Roboto",fontWeight:"regular",fontSize:"12px",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}> 

												<div style={{width:"100%",marginTop:"5px",marginBottom:"5px",textAlign:"left",color:"#636466",fontFamily:"Roboto",fontWeight:"regular",fontSize:"14px"}}>{item.comment} </div>
												<div style={{width:"100%",marginTop:"0px",marginBottom:"5px",textAlign:"left",color:"#333",fontFamily:"Roboto",fontWeight:"regular",fontSize:"10px",fontDecoration:"italic"}}>Por {item.ManagerUserName} em <strong>{createdAt}</strong> </div>
												
												{replies.length > 0 &&	
												<>												
												
													{replies.map((item2,index2) => {
														
														var createdAt = moment(item2.createdAt).format("DD/MM/Y HH:mm");
														var author = item2.origin == 'manager' ? "Pela Motuum" : "Por você";
															
													
														return (
														<div key={item2.id} style={{width:"100%",marginTop:"10px",marginBottom:"0px",textAlign:"left",color:"#636466",fontFamily:"Roboto",fontWeight:"regular",fontSize:"12px",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",backgroundColor:"#FFF",padding:"10px",borderRadius:"10px"}}> 

															<div style={{width:"100%",marginTop:"5px",marginBottom:"5px",textAlign:"left",color:"#636466",fontFamily:"Roboto",fontWeight:"regular",fontSize:"12px"}}> {item2.reply} </div>
															<div style={{width:"100%",marginTop:"0px",marginBottom:"5px",textAlign:"left",color:"#333",fontFamily:"Roboto",fontWeight:"regular",fontSize:"10px",fontDecoration:"italic"}}>{author} em <strong>{createdAt}</strong> </div>

														</div>
														)
											
														}		
													)}																

													
												</>
												}

											</div>
											)
											
										}		
										)}										

								</div>
								</>								
								}
																
								{reportProblem.status != 2 && reportProblem.status != 3 &&
								<>

								{reportProblem.ServiceOrderComplaintComments.length > 0 &&
								<>
								<Divider style={{marginTop:"15px",marginBottom:"15px"}} />	
								
								  <TextField
									  id="outlined-multiline-static"
									  label="Comentário ou resposta *"
									  style={{textAlign:"left",backgroundColor:"#fff",width:"100%"}}
									  multiline
									  rows={3}
										value={comment}
										onChange={(event) => {
										setComment(event.target.value)
										}}									  
									  placeholder="Adicione um comentário ou resposta"
									  variant="outlined"
									/>									
								
									<div style={{textAlign:"right"}}>
										<Button disabled={updating} variant="contained" color="primary" size="small" style={{marginTop:"10px",borderRadius:"10px"}} onClick={!saving ? ()=>confirmAction("comment") : null}>
											{ !saving &&
											   <div>Adicionar</div>
											}
											
											{ saving &&
											<>
											   <CircularProgress size={30} color="inherit" />
											   <span style={{marginLeft:"10px"}}>Gravando...</span>
											</>
											}
										</Button>
									</div>
								</>
								}
								<Divider style={{marginTop:"15px",marginBottom:"15px"}} />	
								
								<Button disabled={saving} variant="outlined" color="primary" size="large" style={{width:"100%",height:"53px",marginTop:"20px",borderRadius:"10px"}} onClick={()=>confirmAction("close")}>
									{ !updating &&
									   <div>Encerrar</div>
									}
									
									{ updating &&
									<>
									   <CircularProgress size={30} color="inherit" />
									   <span style={{marginLeft:"10px"}}>Atualizando...</span>
									</>
									}
								</Button> 
								</>
								}									
							
							</>
							}
		
							
					</Box>						
						
						
				</Box>			
			
			</Fade>  
			
			<AlertDialog 
				alert={alert}
				setAlert={setAlert}
				dialogMessage={dialogMessage}
				dialogTitle={dialogTitle}
				dialogAction={dialogAction}
				history={navigate}
			/>	
			
			<ConfirmDialog 
				saveAction={saveAction}
				updateAction={updateAction}
				dialog={dialog}
				confirm={confirm}
				setConfirm={setConfirm}
				setDialog={setDialog}
				dialogTitle={dialogTitle}
				dialogMessage={dialogMessage}
				setDialogTitle={setDialogTitle}
				setDialogMessage={setDialogMessage}
				confirmActionValue={confirmActionValue}
			/>				
			
	</Fragment>
)}; 

const mapStateToProps = store => ({
  innerLoading:store.appReducer.innerLoading,
  UserData:store.appReducer.userData,
  dialog:store.appReducer.dialog
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AppActions,...ServiceActions }, dispatch);  

// Transition Slide for Dialog SLIDE UP	
const TransitionSlideUp = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;  
});	

const AlertDialogBackup = (props) => {
	
	return (
		 <Dialog
			open={props.alert} 
			onClose={() => { props.setAlert(false)} }
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		  >
		  <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
			<DialogContent>
			  <DialogContentText id="alert-dialog-description">
			  {props.dialogMessage}
			  </DialogContentText>
			</DialogContent>
			<DialogActions>
			  <Button onClick={() => { props.setAlert(false) }} color="primary" autoFocus>
				OK
			  </Button>
			</DialogActions>			
		  </Dialog>
	  )
	
} // end AlertDialog

const AlertDialog = (props) => {
	
	return (
		 <Dialog
			open={props.alert}
			onClose={!props.dialogAction ? () => { props.setAlert(false) } : () => { props.setAlert(false);props.navigate("/executedServices") }  }
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		  >
		  <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
			<DialogContent>
			  <DialogContentText id="alert-dialog-description">
			  {props.dialogMessage}
			  </DialogContentText>
			</DialogContent>
			<DialogActions>
			
			{props.dialogAction &&
			  <Button onClick={() => {props.setAlert(false);props.navigate("/executedServices")} } color="primary" autoFocus>
				OK
			  </Button>
			}

			{!props.dialogAction &&
			  <Button onClick={() => props.setAlert(false) } color="primary" autoFocus>
				OK
			  </Button>
			}			  
			  
			</DialogActions>			
		  </Dialog>
	  )
	
	
} // end AlertDialog

const ConfirmDialog = (props) => {
	
	return (
		 <Dialog
			open={props.confirm}
			onClose={ ()=>props.setConfirm(false) } 
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		  >
		  <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
			<DialogContent>
			  <DialogContentText id="alert-dialog-description">
			  {props.dialogMessage}
			  </DialogContentText>
			</DialogContent>
			<DialogActions>

			  <Button onClick={ ()=>props.setConfirm(false) } color="primary" autoFocus>
				ABORTAR
			  </Button>			

			  <Button onClick={props.confirmActionValue == 'comment' ? ()=> {props.setConfirm(false);props.saveAction(props); } : ()=> {props.setConfirm(false);props.updateAction(props); } } color="primary" autoFocus>
				CONFIRMAR 
			  </Button>
				
			</DialogActions>			
		  </Dialog>
	  )
		
} // end AlertDialog

export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(ProfileManage);

