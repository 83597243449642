// import './MainHome.css';
import React,{useState,useEffect,Fragment} from 'react';
import {Link,useNavigate} from 'react-router-dom';
import { CSSTransition } from "react-transition-group";

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

import { Box,Backdrop,CircularProgress,Button,Divider,IconButton } from '@mui/material';

import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from '@mui/icons-material/Warning';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import NotInterestedIcon from '@mui/icons-material/NotInterested';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Fade from '@mui/material/Fade';
import Slide from '@mui/material/Slide';
import AnimateHeight from 'react-animate-height';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

import {useComponentWillMount} from '../../Utils/customFunctions';
import Header from '../../Components/Layout/Header';
import If from '../../Components/Common/If';
import NavigationBottom from '../../Components/Layout/NavigationBottom';
import ZoomIcon from '@mui/icons-material/ZoomIn';

import backgroundHexagon from '../../Assets/Images/backgroundHexagon.png'; 
import backgroundHeaderImage from '../../Assets/Images/backgroundHeader.png'; 
import helpIcon from '../../Assets/Images/help-icon-blue.svg';
import logo from '../../Assets/Images/logo_motuum-white.svg'; 
import desktopIcon from '../../Assets/Images/desktopIcon.svg'; 
import iconOngoingBlue from '../../Assets/Images/iconOngoing-blue.svg'; 
import backIconBlue from '../../Assets/Images/backIconBlue.svg'; 

// Load Actions
import * as AppActions from '../../Store/Actions/App'
import * as ServiceActions from '../../Store/Actions/Service'

		
const SearchComponent = props => {		
	

const screenStyle = {
  alignItems: "stretch",
  justifyContent: "top",
  width:"100vw",
  minHeight:"100vh",
  display:"flex",
  flexDirection:"column",
  backgroundImage: "url(" + backgroundHexagon + ")",
  backgroundSize:"cover",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"center"
}; 

const screenStyleLoading = {
  alignItems: "middle",
  justifyContent: "center",
  width:"100vw",
  height:"100vh",
  display: "flex",
  flexDirection: "column"
}; 

// set classes var
// const classes = useStyles()();

// set vars from props
// Remember to add those who come from Redux
const { 
	innerLoading,
	setInnerLoading,
	UserData,
	UserDataObj,
	categories,
	retrieveCategories,
	retrieveHighlightService,
	setService
} = props;

// set Use of history for PUSH 
let navigate = useNavigate();

// Set internal state
const [contentVisible,setContentState] = useState(false);
const [firstName, setFirstName] = React.useState(UserData.firstName ? UserData.firstName:"");
const [validated,setValidated] = React.useState(UserData.validated ? UserData.validated: 0);

const [Level,setLevel] = useState(0);
const [SubLevel,setSubLevel] = useState(0);
const [ServicesResult,setServicesResult] = useState(false);
const [ServicesResultSearch,setServicesResultSearch] = useState(false);
const [searchKeyword,setSearchKeyword] = useState("");

const [heightPanel,setHeightPanel] = useState(0);
const [heightPanelServices,setHeightPanelServices] = useState(0);

const [selectedLevel,setSelectedLevel] = useState({});
const [selectedLevelChilds,setSelectedLevelChilds] = useState([]);
const [selectedCategory,setSelectedCategory] = useState({});
const [cards,setCards] = useState([]);

// Service counter
const [highlightCard,setHighlightCard] = useState(null);

const [dialogAlert, setAlertDialog] = useState(false); // state for tab
const [dialogMessage, setDialogMessage] = useState(""); // state for tab
const [dialogTitle, setDialogTitle] = useState("Verifique as informações"); // state for tab

  
// Run after mount component
useEffect(() => {
	
	// Retrieve User Data Obj. 
	var result = localStorage.getItem("@motuum/userData", undefined,true);
	var userData = JSON.parse(result);  
	
	// Rediredct if there is no userData
	if (!userData) {
	navigate("/");  
	return;
	} // end if	
	
	// Comunicate with Webview
	try {
		window.ReactNativeWebView.postMessage(JSON.stringify(userData));
	}
	catch(e) {
		console.log("CATCH"+e.message);
	}

	// Propagate name and activaiton status
	setFirstName(userData.firstName ? userData.firstName:"");
	setValidated(UserData.activationDate ? 1:0);
	 
 // Set Content Loading ON
 //setInnerLoading(true);
 setContentState(true);
 
 	// Data
	var data = {
		parentId:0  
	}  
	var props = {
		navigate:navigate,
		setAlert:setAlertDialog,
		setDialogMessage:setDialogMessage,
		setDialogTitle:setDialogTitle,
		setContentState:setContentState
	}	
	// Load Categories Firts Level...
	retrieveCategories(data,props);
	
	
	// Retrieve Highlight Service - ASYNC
	var props = {
		setHighlightCard:setHighlightCard
	}	
	// Load Categories Firts Level...
	retrieveHighlightService(props);		
	

  // set scroll to top
  window.scrollTo(0, 1);
    
  // Runs when UNMOUNT component
  return () => {
    
	
	
  };
  
}, []); // end useEffect

// Generic Select Icon for multiple selects
const selectLevel = (number) => {
	
	// Collapse panel animating if category is selected
	if (selectedCategory.id) {
		setHeightPanel(0);	
		setHeightPanelServices(0);
	} // end if
	
	if (number == 0) {

		setServicesResult(false);				
		setSubLevel(0)					
		setLevel(0);
		
	} else if (number > 0) {
		
		setServicesResult(false);
		
		setTimeout(function() {
			
			// Set selected level 
			var array = [...categories]; // clone
			var index = array.filter(item => item.id == number);
			var reducedArray = index[0];
			setSelectedCategory(reducedArray);
			setSelectedLevel(reducedArray);		
			
			// Set childs of selectedLevel
			var arrayChilds = [...reducedArray.childs]; // clone
			setSelectedLevelChilds(arrayChilds);		
				
			setLevel(number);		
			setSubLevel(1);

			// Open panel animating with delay 
			
			setHeightPanel('auto');
			
		},selectedCategory.id ? 400 : 0);		
		
	}

};

// Generic Select Icon for multiple selects
const searchServices = (category) => {	

	var array = [...selectedLevelChilds]; // clone
	var index = array.filter(item => item.id == category);
	var reducedArray = index[0];
	
	// Select only Active Services
	var arrayServices = [...reducedArray.Services]; // clone
	var index = arrayServices.filter(item => item.status == 1);	
	var reducedArrayServices = index;

	setSelectedCategory(reducedArray);
	setCards(reducedArrayServices);	
	
	setServicesResult(true);
	setHeightPanelServices('auto');

};

// Generic Select Icon for multiple selects
const selectService = (serviceId) => {	

	var array = [...selectedCategory.Services]; // clone
	var index = array.filter(item => item.id == serviceId);
	var reducedArray = index[0];
	setService({
		...reducedArray,
		breadcrumb:selectedCategory.breadcrumb,
		categoryId:selectedCategory.id
	});
	
	// go to...
	goTo("/searchService/view/"+serviceId+"");	

};


// Set highlight service
const selectHighlightService = (serviceId) => {	

	setService({
		...highlightCard,
		breadcrumb:highlightCard.ServiceCategories[0].breadcrumb ? highlightCard.ServiceCategories[0].breadcrumb : "Serviço em destaque",
		categoryId:highlightCard.ServiceCategories[0].id
	});

	// go to...
	goTo("/searchService/view/"+serviceId+"");	

};



// Go to Target when click on card
const goTo = (url) => {	
	navigate(url);
}; 


const needActivation = () => {
	
	setAlertDialog(true);
	setDialogMessage("Para solicitar um serviço é necessário que ative seu perfil através da confirmação de seu email ou telefone celular.");
	setDialogTitle("Ative seu perfil");	
	
}

	return (
	<>
	<div style={{background:"linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(0,0,0,0.1) 100%)",paddingBottom:"20px"}}>

									<div style={{marginBottom:"10px",paddingTop:"20px",color:"#636466",fontFamily:"Roboto",fontSize:"18px",fontWeight:"bold",textAlign:"left",marginLeft:"30px"}}>Serviços</div>

									<div className="scrolling-wrapper-flexbox" style={{paddingLeft:"20px",paddingRight:"20px",paddingTop:"10px"}}>

										{categories.map((item, index) => {

											return (
											<div className="card" key={item.id} style={{display:"flex",flexDirection:"column",justifyContent:"flex-start",alignItems:"center"}}  onClick={() => selectLevel(item.id)}>
											
												<div className={(!ServicesResult ? item.id == selectedCategory.id : item.id == selectedCategory.parent) ? "active" : ""} style={{backgroundColor:"#FFFFFF",width:"100px",height:"100px",minHeight:"100px",boxShadow:"0px 10px 20px #B9B9B9",borderRadius:"10px",marginBottom:"5px",display:"flex",alignItems:"center",justifyContent:"center"}}>
													<img src={item.icon} alt="{item.name}" style={{marginLeft:"10px",marginRight:"10px",width:"50px",height:"50px"}} />											
												</div>
												
												<div style={{color:"#636466",fontFamily:"Roboto",fontSize:"12px"}}>
													{item.name}
												</div>									
												
											</div>
											)

											}) 
										
										}
				
									  </div>

									<AnimateHeight
									  duration={ 300 }
									  height={ heightPanel }
									>									  
									{(SubLevel > 0 && !ServicesResult) &&
									<div className="serviceSubCategories" style={{marginTop:"20px",paddingLeft:"30px",paddingRight:"30px"}}>

										<Divider style={{backgroundColor:"#707070",opacity:"0.5"}} />
										<div style={{marginTop:"9px",marginBottom:"9px",color:"#003CFA",fontFamily:"Roboto",fontSize:"16px",fontWeight:"bold",textAlign:"left",display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center"}}>
												<div>{selectedCategory.name}</div>
										</div>
										<Divider style={{backgroundColor:"#707070",opacity:"0.2"}} />
										
										<div style={{marginTop:"10px"}}>
										
										{selectedLevelChilds.map((item, index) => {
										
											return (
												<div key={item.id} style={{backgroundColor:"#FFFFFF",width:"100%",height:"50px",boxShadow:"0px 10px 20px #D6D6D6",borderRadius:"10px",marginBottom:"10px",display:"flex",alignItems:"center",justifyContent:"flex-start"}} onClick={() => searchServices(item.id)}>
												
													<img src={item.icon} style={{marginLeft:"39px",marginRight:"17px",width:"23px",height:"auto"}} />
													<div style={{marginTop:"5px",marginBottom:"5px",color:"#636466",fontFamily:"Roboto",fontSize:"16px",fontWeight:"regular",textAlign:"left"}}> {item.name} </div>
													
												</div>	
											)
											})
										}	
										
										</div>

									</div>
									}
									</AnimateHeight>									

									<AnimateHeight
									  duration={ 500 }
									  height={ heightPanelServices }
									>
									{ServicesResult &&
									<div className="serviceSubCategories" style={{marginTop:"20px",paddingLeft:"30px",paddingRight:"30px"}}>

										<Divider style={{backgroundColor:"#707070",opacity:"0.5"}} />
										<div style={{marginTop:"5px",marginBottom:"5px",color:"#003CFA",fontFamily:"Roboto",fontSize:"16px",fontWeight:"bold",textAlign:"left",display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center"}}>
												<IconButton color="primary" onClick={() => selectLevel(selectedCategory.parent)} style={{padding:"0px",marginRight:"5px"}}>
													<img src={backIconBlue} style={{marginLeft:"0px",marginRight:"0px",width:"30px",height:"auto"}} />
												</IconButton>
												<div>{selectedCategory.name}</div>
										</div>
										<Divider style={{backgroundColor:"#707070",opacity:"0.2"}} />
										
										<div style={{marginTop:"10px"}}>
										
											{cards.map((item, index) => {
															
											
											if (item.status == 1) {												
												return (
													<Card  key={index} onClick={()=> selectService(item.id)} style={{backgroundColor:"#FFFFFF",width:"354px",height:"164px",boxShadow:"0px 10px 20px #B9B9B9",borderRadius:"10px",marginBottom:"25px",position:"relative"}}>
													  <CardContent style={{padding:"5px",paddingBottom:"10px"}}>
														
														<Box style={{display:"flex",flexdirection:"row",alignItems:"flex-start",justifyContent:"center"}}>
																	
															<Box style={{display:"flex",justifyContent:"center",alignItems:"center",margin:"20px"}}>
																<img src={item.icon} style={{flex:1,width:"50px",height:"50px"}} />
															</Box>		
															
															<Box p={0}>		
																	<div style={{marginLeft:"10px",marginRight:"20px",marginTop:"5px",marginBottom:"5px",textAlign:"left",color:"#003CFA",fontFamily:"Roboto",fontSize:"16px",fontWeight:"bold"}}>{item.name}</div>
																	<div style={{overflow: "scroll", textAlign:"left",marginLeft:"10px",marginRight:"20px",marginTop:"0px",marginBottom:"0px",color:"#636466",fontFamily:"Roboto",fontSize:"12px",fontWeight:"regular",minHeight:"71px",maxHeight:"71px"}}>{item.shortDescription ? item.shortDescription : item.description}</div>
															</Box>	
														
														</Box>
														
													  </CardContent>
													  
														<CardActions style={{display:"flex",flexDirection:"row",backgroundColor:"#003CFA07",position:"absolute",bottom:"0",width:"100%"}}>
																  
														  <div style={{flex:1,textAlign:"center"}}>
																<Button style={{color:"#003CFA",fontFamily:"Roboto",fontWeight:"normal",fontSize:"12px"}} size="small">										
																	<ZoomIcon style={{fontSize:"20px",color: '#003CFA',marginRight:"5px"}} />
																	<div>Selecionar serviço</div>										
																</Button>
															</div>
															
														  </CardActions>	
														  
													</Card>	
												)											
											} // end if
											
											}
											
											)}	
											
											{cards.length == 0 &&
													<div style={{height:"27vh",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",marginTop:"0px"}}>
													
														<div style={{flex:1.5,display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"flex-end",marginBottom:10}}>
														  <NotInterestedIcon style={{fontSize:"3rem",color: "#DDD"}} />
														</div>
														
														<div style={{flex:1.5,display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"flex-start"}}>
															<div style={{fontSize:20,color:"silver",textAlign:"center"}}>Nenhum serviço encontrado</div>
														</div>													
													
													</div>									
											}										
										
										</div>

									</div>
									}	
									</AnimateHeight>									
									  
							</div>
							
							<div style={{marginTop:"0px",paddingBottom:"200px"}}>

									<div style={{marginBottom:"20px",paddingTop:"20px",color:"#636466",fontFamily:"Roboto",fontSize:"18px",fontWeight:"bold",textAlign:"left",marginLeft:"30px"}}>Serviço em destaque</div>

									<div className="serviceCard" style={{paddingLeft:"30px",paddingRight:"30px"}}>


											{!highlightCard &&
													<div style={{height:"24vh",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",marginTop:"40px"}}>
													
														<Box className="loadingContent">
																<Box mt={0} mx={0}>								
																	<CircularProgress /> 
																	<div> Carregando serviço...</div>								
																</Box>	
														</Box>												
													
													</div>									
											}	

											{highlightCard &&
												<Card  onClick={()=> console.log("Pressed...")} style={{backgroundColor:"#FFFFFF",width:"354px",height:"200px",boxShadow:"0px 10px 20px #B9B9B9",borderRadius:"10px",position:"relative"}} onClick={()=> selectHighlightService(highlightCard.id)} >
												  <CardContent style={{padding:"5px"}}>
													
													<Box style={{display:"flex",flexdirection:"row",alignItems:"flex-start",justifyContent:"center"}}>
																
														<Box p={0}>		
																<div style={{marginLeft:"20px",marginRight:"20px",marginTop:"20px",marginBottom:"10px",textAlign:"left",color:"#003CFA",fontFamily:"Roboto",fontSize:"16px",fontWeight:"bold"}}>{highlightCard.name}</div>
																<div style={{textAlign:"left",marginLeft:"20px",marginRight:"20px",marginTop:"0px",marginBottom:"20px",color:"#636466",fontFamily:"Roboto",fontSize:"12px",fontWeight:"regular"}}>
																	{highlightCard.shortDescription}
																</div>
														</Box>	

														<Box style={{display:"flex",justifyContent:"center",alignItems:"center",margin:"20px"}}>
															<img src={highlightCard.icon} style={{flex:1,width:"110px",height:"110px"}} />
														</Box>														
													
													</Box>
													
												  </CardContent>
											
													<CardActions style={{display:"flex",flexDirection:"row",backgroundColor:"#003CFA07",position:"absolute",bottom:"0",width:"100%"}}>
															  
													  <div style={{flex:1,textAlign:"center"}}>
															<Button style={{color:"#003CFA",fontFamily:"Roboto",fontWeight:"normal",fontSize:"12px"}} size="small">										
																<ZoomIcon style={{fontSize:"20px",color: '#003CFA',marginRight:"5px"}} />
																<div>Selecionar serviço</div>										
															</Button>
														</div>
														
													  </CardActions>				
												</Card>	
												}
				
									  </div>
									  
							</div>	
	</>
	);
	
};

const mapStateToProps = store => ({
  innerLoading:store.appReducer.innerLoading,
  UserData:store.registerReducer.UserData,
  UserDataObj:store.appReducer.userData,
  categories:store.serviceReducer.categories,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AppActions, ...ServiceActions }, dispatch);


export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(SearchComponent);
 


