import './MainSplashScreen.css';
import React,{useState,useEffect,Fragment} from 'react';

import { useNavigate} from 'react-router-dom';
import { CSSTransition } from "react-transition-group";

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

import { Box,CircularProgress } from '@mui/material';

import {useComponentWillMount} from '../../Utils/customFunctions';

// import logo from '../../Assets/Images/logoTypeWOslogan.svg'; // Tell Webpack this JS file uses this image
import logo from '../../Assets/Images/logo_motuum-white.svg'; // Tell Webpack this JS file uses this image
import backgroundHexagon from '../../Assets/Images/backgroundHexagon.png'; // Tell Webpack this JS file uses this image
import backgroundHeaderImage from '../../Assets/Images/backgroundHeader.png'; // Tell Webpack this JS file uses this image

// Load Actions
import * as AppActions from '../../Store/Actions/App'

const SplashScreen = props => {

const screenStyle = {
  alignItems: "stretch",
  justifyContent: "center",
  width:"100vw",
  height:"100vh",
  display:"flex",
  flexDirection:"column",
  backgroundImage: "url(" + backgroundHeaderImage + ")",
  backgroundSize:"cover",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"center"
}; 

// set vars from props
// Remember to add those who come from Redux
const { 
	innerLoading,
	setInnerLoading,
	retrieveUserData,
	userData
} = props;

// set Use of history for PUSH 
let navigate = useNavigate();


// Set internal state
const [contentVisible,setContentState] = useState(false);
  
// Run after mount component
useEffect(() => {
	
	
  // After mount, execute action to check USER state
  // ...
    
  // Set Content Ready to show
  setContentState(true);
  
	 // Simulate Delay
  /*setTimeout(function() {
		  
		  navigate("/onboarding");
		
   },4000); */ 

  // set scroll to top
  window.scrollTo(0, 1);
    
  // Runs when UNMOUNT component
  return () => {
    
	// unmount...
	
  };
  
}, []); // end useEffect


// Will Mount Component HOOK
useComponentWillMount(() => {
	
  // Execute background tasks when enter on APP....
  // ...
  
  // test purpose. 
  //sessionStorage.removeItem('@motuum/appLaunched');
  
  // Set session of app with TIMEOUT (TEST PURPOSE) - Need to activate after BOOTSTRAP...
  var timeout = setTimeout(function() {
	  var sessionId = Math.floor(Math.random() * 99123); 
	  sessionStorage.setItem('@motuum/appLaunched', true);
  },1000);
      
	// Retrieve User Data Obj. 
	var result = localStorage.getItem("@motuum/userData", undefined,true);
	var userData = JSON.parse(result);

    // check if user viewed onboarding screen
	var onboardingViewed = localStorage.getItem("viewedOnboarding", undefined,true);
	var loggedOff = localStorage.getItem("loggedOff", undefined,true);

    // Wait for Session creation (all OK)
	var timer = setInterval(function() {
		
		// retrive session
		var sessionCheck = sessionStorage.getItem('@motuum/appLaunched');
		
		if (sessionCheck) {	
				
			  // Clear interval
			  clearInterval(timer);
		
			  // If token dont exist, redirect to onboarding, login or register
			  if (!userData) {	  
				  if (onboardingViewed) {
					
					if (loggedOff == 1) {	
						navigate("/login");
						return;
					}			
					
					navigate("/register");					
				  } // end if
				  else {	  
					navigate("/onboarding");
				  } // end else	  
			  } // end if
			  // If token exist, redirect to HOME
			  else {
				  
				    // Retrieve latest user data
					retrieveUserData();				  
					navigate("/home");
			  } // end else  
		  
		} // end if
		  
	 },3000);
	    
        
}, []); // end useComponentWillMount

	return (
	<Fragment>
						
				<Box className="mainSplash" style={{...screenStyle}} m={0}>
							
						<Box style={{flex:3,alignItems:"center",display:"flex"}}>							
										
							<Box style={{flex:1,alignItems:"center",display:"flex",flexDirection:"column"}}>						
									<img src={logo} atl="Logomarca Motuum" style={{marginLeft:"auto",marginRight:"auto",width:"60vw"}} />	
							</Box>	
							
						</Box>
						
						<Box style={{flex:1.5,alignItems:"top",display:"flex"}}>							
										
							<Box style={{flex:1,alignItems:"center",display:"flex"}}>						
									
								<div style={{flex:1}}>						
									  <CircularProgress style={{color:"#FFF",width:"25px",height:"25px"}} /> 
									  <h5 style={{color:"#FFF",marginTop:"5px"}}>Configurando o app</h5>
									  
								</div>									
									
							</Box>	
							
						</Box>						
								
				</Box>			
			
				
	</Fragment>
)};

const mapStateToProps = store => ({
  innerLoading:store.appReducer.innerLoading,
  userData:store.appReducer.userData
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AppActions }, dispatch);

export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(SplashScreen);
 
