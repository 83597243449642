import './Assets/Styles/index.css';
import 'fontsource-roboto';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux'

import CssBaseline from '@mui/material/CssBaseline'
import App from './App'; 
import store from './Store/store'

import reportWebVitals from './reportWebVitals';

import {
  isBrowser
} from "react-device-detect";

// Obtenha o elemento root
const rootElement = document.getElementById('root');

// Aplique o background azul se estiver em um navegador
if (isBrowser && rootElement) {
  rootElement.style.backgroundColor = 'blue';
}

const root = ReactDOM.createRoot(rootElement);
root.render(
  <React.StrictMode>
	<CssBaseline />
    <Provider store={store}>
		<App />
	</Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
