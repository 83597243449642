import React,{useState,useEffect,Fragment} from 'react';

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

import { Box,Divider,Typography,Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';

import Info from '@mui/icons-material/ErrorOutline';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AccountTreeIcon from '@mui/icons-material/AccountTree'; 
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ScheduleIcon from '@mui/icons-material/Schedule';
import WarningIcon from '@mui/icons-material/Warning';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// Load Actions
import * as AppActions from '../../Store/Actions/App'

const mapStateToProps = store => ({
  innerLoading:store.appReducer.innerLoading,
  dialog:store.appReducer.dialog
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AppActions }, dispatch);
    
// Transition Slide for Dialog SLIDE UP	
const TransitionSlideUp = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;  
});	
	

/* Extra  Dialog Component START */
export const ServiceDetailDialog = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {


const screenStyleDialog = {
  alignItems: "stretch",
  justifyContent: "top",
  width:"100vw",
  minHeight:"100vh",
  display:"flex",
  flexDirection:"column",
  backgroundColor:"#FFF"
}; 
	
// set classes var
// const classes = useStyles()();	

const { 
	innerLoading,
	setInnerLoading,
	setDialog,
	SelectedOngoingServiceOrder,
	dialog
} = props;	

const [categoryBreadCrumb,setCategoryBreadCrumb] = useState(SelectedOngoingServiceOrder.ServiceOrderService ? SelectedOngoingServiceOrder.ServiceOrderService.Service.ServiceCategories[0].breadcrumb :"carregando...");

var formatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

	return (
	<div>
		  <Dialog fullScreen open={dialog.target == 'serviceDetail' && dialog.status} onClose={()=>setDialog('serviceDetail',false)} TransitionComponent={TransitionSlideUp}>
			<AppBar style={{position: 'fixed'}}>
			  <Toolbar>
				<IconButton edge="start" color="inherit" onClick={() => setDialog('serviceDetail',false)} aria-label="close">
				  <CloseIcon />
				</IconButton>
				<Typography variant="h6" style={{marginLeft: "10px",flex: 1}}>
				  Detalhes do serviço
				</Typography>
				</Toolbar>
			</AppBar>

			<Box style={{...screenStyleDialog}} m={0}>
			
				<Box mt={8} ml={0} mr={0}>

							<Box ml={2} mr={2} mb={1} mt={0} style={{alignItems:"stretch",justifyContent:"flex-start",display:"flex",flexDirection:"column"}}>
							
								<h3 style={{marginTop:"5px",marginBottom:"10px"}}> {SelectedOngoingServiceOrder.ServiceOrderService.name} </h3>
								<div style={{textAlign:"left"}}>
									{SelectedOngoingServiceOrder.ServiceOrderService.description}
								</div>
							
							</Box>

							<Box ml={2} mr={2} mb={1} mt={0} style={{alignItems:"stretch",justifyContent:"flex-start",display:"flex",flexDirection:"column"}}>
							
								<h3 style={{marginTop:"5px",marginBottom:"10px"}}> Garantia do serviço </h3>
								<div style={{textAlign:"left"}}>
									{SelectedOngoingServiceOrder.ServiceOrderService.warrantyTerm}
								</div>
							
							</Box>							
								
							<Divider light />
							

							<Box mt={2} mb={2} style={{textAlign:"center",alignItems:"stretch",justifyContent:"center",display:"flex",flexDirection:"column"}}>
									
									{/* Time Box */}
									<Box ml={1} mr={1} mb={2} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>
									
										<Box style={{flex:2}}>
										
											<Box style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>    
												<div style={{marginRight:"5px"}}> 
													<ScheduleIcon style={{fontSize:"1.7rem",color:"#555"}} />
												</div>
												
												<div> 
													<div style={{fontSize:"0.9rem",color:"#555555"}}>Tempo médio do serviço</div>
												</div>
											</Box>									
										
										</Box>
										
										<Box style={{flex:1}}>								
												<Box style={{fontSize:"0.9rem",border:"1px solid gray",backgroundColor:"#FFF",padding:"2px",borderRadius:"5px"}}>
													{SelectedOngoingServiceOrder.ServiceOrderService.effort} {SelectedOngoingServiceOrder.ServiceOrderService.effortUnity}
												</Box>								
										</Box>								
									
									</Box>
									
									{/* Price Box */}	
									<Box ml={1} mr={1} mb={2} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>
									
										<Box style={{flex:2}}>
										
											<Box style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>    
												<div style={{marginRight:"5px"}}> 
													<AttachMoneyIcon style={{fontSize:"1.7rem",color:"green"}} />
												</div>
												
												<div> 
													<div style={{fontSize:"0.9rem",color:"#555555"}}>Valor do serviço</div>
												</div>
											</Box>									
										
										</Box>
										
										<Box style={{flex:1}}>								
												<Box style={{fontSize:"0.9rem",border:"1px solid gray",backgroundColor:"#FFF",padding:"2px",borderRadius:"5px"}}>
													{SelectedOngoingServiceOrder.ServiceBasisPrice ? formatter.format(SelectedOngoingServiceOrder.ServiceBasisPrice) : "carregando..."}
												</Box>								
										</Box>								
									
									</Box>	

									{SelectedOngoingServiceOrder.type == 3 &&	
									<>
									<Box ml={1} mr={1} mb={2} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>
									
										<Box style={{flex:2}}>
										
											<Box style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>    
												<div style={{marginRight:"5px"}}> 
													<AttachMoneyIcon style={{fontSize:"1.7rem",color:"green"}} />
												</div>
												
												<div> 
													<div style={{fontSize:"0.9rem",color:"#555555"}}>Taxa de urgência</div>
												</div>
											</Box>									
										
										</Box>
										
										<Box style={{flex:1}}>								
												<Box style={{fontSize:"0.9rem",border:"1px solid gray",backgroundColor:"#FFF",padding:"2px",borderRadius:"5px"}}>
													{SelectedOngoingServiceOrder.ServiceUrgentFee ? formatter.format(SelectedOngoingServiceOrder.ServiceUrgentFee) : "carregando..."}
												</Box>								
										</Box>								
									
									</Box>
									<Box ml={1} mr={1} mb={2} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>
									
										<Box style={{flex:2}}>
										
											<Box style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>    
												<div style={{marginRight:"5px"}}> 
													<AttachMoneyIcon style={{fontSize:"1.7rem",color:"green"}} />
												</div>
												
												<div> 
													<div style={{fontSize:"0.9rem",color:"#555555"}}>Valor Total</div>
												</div>
											</Box>									
										
										</Box>
										
										<Box style={{flex:1}}>								
												<Box style={{fontSize:"0.9rem",border:"1px solid gray",backgroundColor:"#FFF",padding:"2px",borderRadius:"5px"}}>
													{SelectedOngoingServiceOrder.ServiceTotalPrice ? formatter.format(SelectedOngoingServiceOrder.ServiceTotalPrice) : "carregando..."}
												</Box>								
										</Box>								
									
									</Box>	
									</>									
									}									
								
									{/* Category Box */}	
									<Box ml={1} mr={1} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>
									
										<Box style={{flex:2}}>
										
											<Box style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>    
												<div style={{marginRight:"5px"}}> 
													<AccountTreeIcon style={{fontSize:"1.7rem",color:"#555"}} />
												</div>
												
												<div> 
													<div style={{fontSize:"0.9rem",color:"#555555"}}>Categoria</div>
												</div>
											</Box>									
										
										</Box>
										
										<Box style={{flex:2}}>								
												<Box style={{fontSize:"0.9rem",border:"1px solid gray",backgroundColor:"#FFF",padding:"2px",borderRadius:"5px"}}>
													{categoryBreadCrumb}
												</Box>								
										</Box>								
									
									</Box>					
														


					</Box>
			
					<Divider style={{marginBottom:"5px"}} />
						
					<Box style={{display:"flex",flexDirection:"row",justifyContent:"center"}} ml={1} mr={1} mb={5} mt={2}>						
						
						<Button color="secondary" variant="outlined" style={{flex:1,height:"48px",marginRight:"10px"}} onClick={()=>setDialog('serviceDetail',false)}>
						 Fechar
						</Button>		
													
					</Box>			
			
				</Box>				
			
			</Box>

		  </Dialog>
		</div>
		
	);
	
});



