import React,{useState,useEffect,Fragment} from 'react';

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

import { Box,Divider,Typography,Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';

import Info from '@mui/icons-material/ErrorOutline';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AccountTreeIcon from '@mui/icons-material/AccountTree'; 
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ScheduleIcon from '@mui/icons-material/Schedule';
import WarningIcon from '@mui/icons-material/Warning';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import visa from '../../Assets/Images/visa.svg'; // Tell Webpack this JS file uses this image
import mastercard from '../../Assets/Images/mastercard.svg'; // Tell Webpack this JS file uses this image
import amex from '../../Assets/Images/amex.svg'; // Tell Webpack this JS file uses this image
import diners from '../../Assets/Images/diners.svg'; // Tell Webpack this JS file uses this image
import elo from '../../Assets/Images/elo.svg'; // Tell Webpack this JS file uses this image
import genericFlag from '../../Assets/Images/genericFlag.svg'; // Tell Webpack this JS file uses this image

// Load Actions
import * as AppActions from '../../Store/Actions/App'

const mapStateToProps = store => ({
  innerLoading:store.appReducer.innerLoading,
  dialog:store.appReducer.dialog
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AppActions }, dispatch);
    
// Transition Slide for Dialog SLIDE UP	
const TransitionSlideUp = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;  
});	
	

/* Extra  Dialog Component START */
export const PaymentDetailDialog = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {
		

const screenStyleDialog = {
  alignItems: "stretch",
  justifyContent: "top",
  width:"100vw",
  minHeight:"100vh",
  display:"flex",
  flexDirection:"column",
  backgroundColor:"#FFF"
}; 
	
// set classes var
// const classes = useStyles()();	

const { 
	innerLoading,
	setInnerLoading,
	setDialog,
	SelectedOngoingServiceOrder,
	dialog
} = props;	

const [cardFlag, setCardFlag] = useState(""); // state for tab

var formatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});



// Run after mount component
useEffect(() => {
		
	if (SelectedOngoingServiceOrder.ServiceOrderCreditCard.cardFlag == "visa") {
		setCardFlag(visa);
	} // end if
	else if  (SelectedOngoingServiceOrder.ServiceOrderCreditCard.cardFlag == "mastercard") {
		setCardFlag(mastercard);
	} // end else if
	else if  (SelectedOngoingServiceOrder.ServiceOrderCreditCard.cardFlag == "amex") {
		setCardFlag(amex);
	} // end else if	
	else if  (SelectedOngoingServiceOrder.ServiceOrderCreditCard.cardFlag == "diners") {
		setCardFlag(diners);
	} // end else if	
	else if  (SelectedOngoingServiceOrder.ServiceOrderCreditCard.cardFlag == "elo") {
		setCardFlag(elo);
	} // end else if	
	else if  (SelectedOngoingServiceOrder.ServiceOrderCreditCard.cardFlag == "generic") {
		setCardFlag(genericFlag);
	} // end else if	
	
}, []); // end useEffect



	return (
	<div>
		  <Dialog fullScreen open={dialog.target == 'paymentDetail' && dialog.status} onClose={()=>{setDialog('paymentDetail',false);setDialog('serviceInfo',true)}} TransitionComponent={TransitionSlideUp}>
			<AppBar style={{position: 'fixed'}}>
			  <Toolbar>
				<IconButton edge="start" color="inherit" onClick={() => {setDialog('paymentDetail',false);setDialog('serviceInfo',true)}} aria-label="close">
				  <CloseIcon />
				</IconButton>
				<Typography variant="h6" style={{marginLeft: "10px",flex: 1}}>
				  Detalhes do cartão de crédito
				</Typography>
				</Toolbar>
			</AppBar>

			<Box style={{...screenStyleDialog}} m={0}>
			
				<Box mt={8} ml={0} mr={0}>

							<Box mt={2} mb={2} style={{textAlign:"center",alignItems:"stretch",justifyContent:"center",display:"flex",flexDirection:"column"}}>
									
									{/* Holder credit card Box */}
									<Box ml={1} mr={1} mb={2} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>
									
										<Box style={{flex:2}}>
										
											<Box style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>
												<div> 
													<div style={{fontSize:"0.9rem",color:"#555555"}}>Titular do cartão</div>
												</div>
											</Box>									
										
										</Box>
										
										<Box style={{flex:2}}>								
												<Box style={{fontSize:"0.9rem",border:"1px solid gray",backgroundColor:"#FFF",padding:"2px",borderRadius:"5px"}}>
													{SelectedOngoingServiceOrder.ServiceOrderCreditCard.holderName}
												</Box>								
										</Box>								
									
									</Box>
									
									{/* credit card referencie Box */}
									<Box ml={1} mr={1} mb={2} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>
									
										<Box style={{flex:2}}>
										
											<Box style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>
												<div> 
													<div style={{fontSize:"0.9rem",color:"#555555"}}>Número Cartão</div>
												</div>
											</Box>									
										
										</Box>
										
										<Box style={{flex:2}}>								
												<Box style={{fontSize:"0.9rem",border:"1px solid gray",backgroundColor:"#FFF",padding:"2px",borderRadius:"5px"}}>
													{SelectedOngoingServiceOrder.ServiceOrderCreditCard.referenceNumber}
												</Box>								
										</Box>								
									
									</Box>	

									{/* credit card referencie Box */}
									<Box ml={1} mr={1} mb={2} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>
									
										<Box style={{flex:2}}>
										
											<Box style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>
												<div> 
													<div style={{fontSize:"0.9rem",color:"#555555"}}>Bandeira Cartão</div>
												</div>
											</Box>									
										
										</Box>
										
										<Box style={{flex:2}}>								
												<Box style={{fontSize:"0.9rem",border:"1px solid gray",backgroundColor:"#FFF",padding:"2px",borderRadius:"5px"}}>
													<img src={cardFlag} style={{marginLeft:"10px",marginRight:"10px",width:"30px"}} />	
												</Box>								
										</Box>								
									
									</Box>										
									
									{/* credit card referencie Box */}
									<Box ml={1} mr={1} mb={2} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>
									
										<Box style={{flex:2}}>
										
											<Box style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>
												<div> 
													<div style={{fontSize:"0.9rem",color:"#555555"}}>Data Expiração Cartão</div>
												</div>
											</Box>									
										
										</Box>
										
										<Box style={{flex:2}}>								
												<Box style={{fontSize:"0.9rem",border:"1px solid gray",backgroundColor:"#FFF",padding:"2px",borderRadius:"5px"}}>
													{SelectedOngoingServiceOrder.ServiceOrderCreditCard.expirationDate}
												</Box>								
										</Box>								
									
									</Box>									
																		
									

					</Box>
			
					<Divider style={{marginBottom:"5px"}} />
						
					<Box style={{display:"flex",flexDirection:"row",justifyContent:"center"}} ml={1} mr={1} mb={5} mt={2}>						
						
						<Button color="secondary" variant="outlined" style={{flex:1,height:"48px",marginRight:"10px"}} onClick={()=>{setDialog('paymentDetail',false);setDialog('serviceInfo',true)}}>
						 Fechar
						</Button>		
													
					</Box>			
			
				</Box>				
			
			</Box>

		  </Dialog>
		</div>
		
	);
	
});



