import './MainProfileManage.css';
import React,{useState,useEffect,Fragment} from 'react';
import {Link,useNavigate} from 'react-router-dom';

import PropTypes from 'prop-types';
import { CSSTransition } from "react-transition-group";

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

import { Box,Backdrop,CircularProgress,Typography,Button,InputAdornment,IconButton,TextField,Divider } from '@mui/material';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import WarningIcon from '@mui/icons-material/Warning';
import LockIcon from '@mui/icons-material/Lock';
import Slide from '@mui/material/Slide';
import Fade from '@mui/material/Fade';
import Zoom from '@mui/material/Zoom';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Danger from '@mui/icons-material/Error';

import helpIcon from '../../Assets/Images/help-icon-blue.svg'; 
import deviceIcon from '../../Assets/Images/deviceIcon.svg'; 
import chatComplain from '../../Assets/Images/iconComplain-blue.svg'; 
import chatIcon from '../../Assets/Images/iconChat-blue.svg';
import chatSupportIcon from '../../Assets/Images/chatIcon.svg'; 
import profileIcon from '../../Assets/Images/profileIcon.svg';
import userProfileIcon from '../../Assets/Images/userProfileIcon.svg';
import passwordProfileIcon from '../../Assets/Images/passwordProfileIcon.svg';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


import InputMask from "react-input-mask";

import NiceInputPassword from 'react-nice-input-password';

import {useComponentWillMount} from '../../Utils/customFunctions';
import Header from '../../Components/Layout/Header';
import If from '../../Components/Common/If';

import logo from '../../Assets/Images/logo_motuum-white.svg';
import backgroundHexagon from '../../Assets/Images/backgroundHexagon.png'; 
import backgroundHeaderImage from '../../Assets/Images/backgroundHeader.png'; 
import backIcon from '../../Assets/Images/backIcon.svg';
import infoIcon from '../../Assets/Images/infoIcon-red.svg'; 

// Loading  Tab Content components
import {UserDataForm} from './MainProfileData';
import {PasswordChangeForm} from './MainProfilePassword';

// Load Actions
import * as AppActions from '../../Store/Actions/App'

const ProfileManage = props => {


const screenStyle = {
  alignItems: "stretch",
  justifyContent: "top",
  width:"100vw",
  height:"100vh",
  display:"flex",
  flexDirection:"column",
  backgroundImage: "url(" + backgroundHexagon + ")",
  backgroundSize:"cover",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"center"
}; 

const screenStyleLoading = {
  alignItems: "middle",
  justifyContent: "center",
  width:"100vw",
  height:"100vh",
  display: "flex",
  flexDirection: "column"
}; 

// set classes var
// const classes = useStyles()();

// Set functions
const handleChange = (event, newValue) => {
setValue(newValue);
};

// Set TABPANEL
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
	  hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


// set vars from props
// Remember to add those who come from Redux
const { 
	innerLoading,
	setInnerLoading,
	setDialog,
	dialog
} = props;

// set Use of history for PUSH 
let navigate = useNavigate();

// Set internal state
const [contentVisible,setContentState] = useState(false);
const [loadingMessage,setLoadingMessage] = useState("Carregando...");
const [value, setValue] = useState(0); // state for tab

const [dialogAlert, setAlertDialog] = useState(false); // state for tab
const [dialogMessage, setDialogMessage] = useState(""); // state for tab
const [dialogTitle, setDialogTitle] = useState("Verifique as informações"); // state for tab
const [alert, setAlert] = useState(false); // state for tab

// Run after mount component
useEffect(() => {
	
	// Retrieve User Data Obj. 
	var result = localStorage.getItem("@motuum/userData", undefined,true);
	var userData = JSON.parse(result);  
  
	  // If token dont exist, redirect to onboarding or register
	  if (!userData) {	  
		navigate("/");  
	  } // end if
	  // If token exist, redirect to HOME
	  else {
		  if (!userData.userToken) {
			navigate("/");
		  } // end if
	  } // end else    
	
  console.log("Montou o componente MainHome....");
 
 // Set Content Loading ON
 //setInnerLoading(true);
 setContentState(true);

  // set scroll to top
  window.scrollTo(0, 1);
    
  // Runs when UNMOUNT component
  return () => {
   
  };
  
}, [props.open]); // end useEffect


// Will Mount Component HOOK
useComponentWillMount(() => {
	
    // Set session of app
  var sessionCheck = sessionStorage.getItem('@motuum/appLaunched');
  
  // if session is not created, redirect to bootstrap. (avoid direct access using url)
  if (!sessionCheck) {
	  navigate("/");
  } // end if	
  
      
}, []); // end useComponentWillMount


	return (
	<Fragment>
			
			<Header title="Gerenciar Perfil" buttons={{support:false,fakeRight:true,menu:false,back:true,close:false}} screen="ProfileManage" />
				
			{/* Inner Loading */}
			<If test={!contentVisible}>
					<Box className="loadingContent" style={{...screenStyleLoading}}>
							<Box mt={0} mx={0}>								
								<CircularProgress /> 
								<div> Carregando...Aguarde!</div>								
							</Box>	
					</Box>
			</If>	

			<FinishDialog
				setAlertDialog={setAlertDialog}
				setAlert={setAlert}
				setDialogMessage={setDialogMessage}
				setDialogTitle={setDialogTitle}
			/>			

			<Fade in={contentVisible} timeout={1000}  mountOnEnter unmountOnExit>			
			
				<Box className="mainHome" style={{...screenStyle}} mt={10}>
				
				{/*<Box style={{marginBottom:"0px",backgroundColor:"transparent",alignItems:"center",display:"flex",flexDirection:"column"}} pl={5} pr={5}>

								<Box style={{marginTop:"40px",marginBottom:"30px"}}>						
									<div style={{fontFamily:"Roboto",fontWeight:"100",fontSize:"42px",color:"#003CFA",lineHeight:"38px"}}>	
										  COMO PODEMOS AJUDAR?
									</div>								 	
								</Box> 	
								
				</Box>*/}
						
						<Box style={{marginTop:"30px",backgroundColor:"transparent",alignItems:"center",display:"flex",flexDirection:"column"}} mt={props.marginTop} pl={5} pr={5}>

								<div>
									<img src={helpIcon} alt="Ajuda" style={{width:"100px",height:"100px"}} />
								</div>

								<Box style={{marginTop:"20px",marginBottom:"50px"}}>						
									<div style={{fontFamily:"Roboto",fontWeight:"100",fontSize:"42px",color:"#636466",lineHeight:"38px"}}>	
										 EDITAR MEU PERFIL
									</div>								 	
								</Box> 	
								
						</Box>						
						
						<Box style={{backgroundColor:"transparent",alignItems:"center",justifyContent:"center",display:"flex",flexDirection:"row",flexWrap:"wrap"}} pl={5} pr={5}>			
								
							<div style={{width:"140px",height:"140px",backgroundColor:"#F7F7F7",margin:"10px",borderRadius:"10px",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}} onClick={() =>  navigate("/manageProfile/update") }>
								
									<div> 
										<img src={userProfileIcon} alt="Device" style={{width:"48px",height:"48px"}} />
									</div>
									
									<div style={{color:"#636466",fontFamily:"Roboto",fontWeight:"normal",fontSize:"14px",}}> 
										Alterar meus<br/> dados
									</div>									
									
							</div>

							<div style={{width:"140px",height:"140px",backgroundColor:"#F7F7F7",margin:"10px",borderRadius:"10px",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}} onClick={() => navigate("/manageProfile/updatePassword") }>
								
									<div> 
										<img src={passwordProfileIcon} alt="Edit" style={{width:"32px",height:"42px"}} />
									</div>
									
									<div style={{color:"#636466",fontFamily:"Roboto",fontWeight:"normal",fontSize:"14px"}}> 
										Alterar minha<br /> senha
									</div>									
									
							</div>
												
								
						</Box>	


						<Box pl={5} pr={5} style={{marginTop:"50px",borderRadius:"10px",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
								
								<div style={{color:"#636466",fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px",textAlign:"center"}} onClick={() => setDialog('profileFinish',true)}> 
									ENCERRAR PERFIL NA PLATAFORMA
								</div>									
								
						</Box>							
						
					</Box>			
			
			</Fade>  
			
			<AlertDialog 
				alert={alert}
				setAlert={setAlert}
				dialogMessage={dialogMessage}
				dialogTitle={dialogTitle}
			/>	
			
	</Fragment>
)}; 

const mapStateToProps = store => ({
  innerLoading:store.appReducer.innerLoading,
  UserData:store.appReducer.userData,
  dialog:store.appReducer.dialog
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AppActions }, dispatch);  

// Transition Slide for Dialog SLIDE UP	
const TransitionSlideUp = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;  
});	

/* Finish Dialog Component START */
export const FinishDialog = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {

const backgroundHeaderModal = {
  backgroundImage: "url(" + backgroundHeaderImage + ")",
  backgroundSize:"cover",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"center",
  display:"flex",
  alignItems:"center",
  justifyContent:"center"
}; 

const screenStyleDialog = {
  alignItems: "stretch",
  justifyContent: "top",
  width:"100vw",
  minHeight:"100vh",
  display:"flex",
  flexDirection:"column",
  backgroundImage: "url(" + backgroundHexagon + ")",
  backgroundSize:"cover",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"center"
}; 
	
// set classes var
// const classes = useStyles()();	

// set vars from props
// Remember to add those who come from Redux
const { 
	innerLoading,
	setInnerLoading,
	setDialog,
	dialog,
	terminateUserProfile,
	setAlertDialog,
	setDialogMessage,
	setDialogTitle,
	setAlert
} = props;	
/* Dialog END*/	

const [acceptTerms, setAcceptTerms] = useState(false); // state for tab
const [loading, setLoading] = useState(false); // state for tab

// Set functions
const handleAcceptTerms = (event) => {

	const newValue = event.target.checked
	setAcceptTerms(newValue);
	
};	

const terminateProfile = () => {
	
	
	if (acceptTerms !== true) {
		
		props.setDialogTitle("Verifique as informações.");
		props.setDialogMessage("Você precisa aceitar que está de acordo com o encerramento de seu perfil para proesseguir.");
		props.setAlert(true);
		return;		
		
	} // end if
	
	setLoading(true);
	
	var propsParam = {
		setLoading:setLoading,
		setDialog:setDialog,
		navigate:navigate,
		setAlertDialog:setAlertDialog,
		setDialogMessage:setDialogMessage,
		setDialogTitle:setDialogTitle,
		setAlert:setAlert
	}	
	terminateUserProfile(propsParam);
		
	setTimeout(function() {
		
		setLoading(false);
		
	},3000);
	
	
} // end terminate


// set Use of history for PUSH 
var navigate = useNavigate();
						
	return (
	<div>
		  <Dialog fullScreen open={dialog.target == 'profileFinish' && dialog.status} onClose={!loading ? ()=>setDialog('profileFinish',false) : null} TransitionComponent={TransitionSlideUp}>
			<AppBar style={{position: 'fixed',height:'80px',...backgroundHeaderModal}}>
			  <Toolbar style={{width:"100%"}}>
			  
				  <IconButton edge="start" color="inherit" aria-label="menu" onClick={!loading ? () => setDialog('profileFinish',false) : null}>
					<img src={backIcon} alt="Voltar" style={{width:"30px",height:"auto"}} />
				  </IconButton>				  
			  
				  <div style={{marginLeft: "10px",flex: 1,textAlign:"center",display:"flex",justifyContent:"center",alignItems:"center"}}>
					  <img src={logo} alt="Motuum" style={{marginLeft:"auto",marginRight:"auto",width:"40%",height:"auto"}} />
				  </div>
				  
				  <IconButton edge="start" color="inherit" aria-label="menu" style={{width:"30px",height:"auto"}}>
					
				  </IconButton>						  
				  
				</Toolbar>
			</AppBar>

			<Box style={{...screenStyleDialog,backgroundColor:"#FFF",paddingLeft:"20px",paddingRight:"20px"}} m={0}>
			
				<Box mt={10} ml={0} mr={0}>
			
					<Box style={{marginTop:"30px",backgroundColor:"transparent",alignItems:"center",justifyContent:"center",display:"flex",flexDirection:"column"}} mt={props.marginTop} pl={5} pr={5}>

							<div>
								<img src={infoIcon} alt="Info" style={{width:"100px",height:"100px"}} />
							</div>

							<Box style={{marginTop:"20px",marginBottom:"20px"}}>						
								<div style={{fontFamily:"Roboto",fontWeight:"100",fontSize:"42px",color:"#E30000",lineHeight:"38px",textAlign:"center"}}>	
									 ENCERRAMENTO DE PERFIL
								</div>								 	
							</Box> 	
							
					</Box>						
						
					<Box style={{marginTop:"10px",backgroundColor:"transparent",alignItems:"center",justifyContent:"center",display:"flex",flexDirection:"row",flexWrap:"wrap"}} pl={5} pr={5}>				

						<div style={{color:"#636466",fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px",textAlign:"center"}}>Atenção, o encerramento de seu perfil é um processo irreversível. Os seus dados serão definitivamente excluídos.</div>
	
					</Box>

					<Box style={{marginTop:"40px",marginBottom:"20px",textAlign:"center"}} pl={1} pr={1}>
					
						  <Checkbox												
							checked={acceptTerms}
							onChange={handleAcceptTerms}
							inputProps={{ 'aria-label': 'primary checkbox' }}
						  />
						  
						  <span style={{marginBottom:"10px"}}>
							Estou de acordo com o encerramento de meu perfil
						  </span>	
						  
					</Box>
									  
					<Box style={{marginTop:"10px",marginBottom:"10px"}} pl={4} pr={4}>
							
							<Button disabled={loading} className="confirmButton" variant="contained" color="primary" style={{fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px",width:"100%",height:"53px",marginTop:"10px",backgroundColor:"#E30000 !important"}} onClick={()=>terminateProfile()}>
								{ !loading &&
								   <div>ENCERRAR</div>
								}
								
								{ loading &&
								<>
								   <CircularProgress size={30} color="inherit" />
								   <span style={{marginLeft:"10px"}}>ENCERRANDO...</span>
								</>
								}
							</Button>								
							
							<Button disabled={loading}  className="cancelButton" variant="contained" color="secondary" style={{fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px",height:"53px",width:"100%",marginTop:"20px"}} onClick={() => setDialog('profileFinish',false)}>
							 CANCELAR
							</Button>						
					
					</Box>						
			
			
				</Box>				
			
			</Box>

		  </Dialog>
	</div>	
	);
	
});

const AlertDialog = (props) => {
	
	return (
		 <Dialog
			open={props.alert} 
			onClose={() => { props.setAlert(false)} }
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		  >
		  <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
			<DialogContent>
			  <DialogContentText id="alert-dialog-description">
			  {props.dialogMessage}
			  </DialogContentText>
			</DialogContent>
			<DialogActions>
			  <Button onClick={() => { props.setAlert(false) }} color="primary" autoFocus>
				OK
			  </Button>
			</DialogActions>			
		  </Dialog>
	  )
	
} // end AlertDialog


export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(ProfileManage);

