import axios from 'axios';
import * as types from '../actionTypes';
import {jwtDecode} from "jwt-decode";
import * as config from '../../Config/api.js';


export const toggleDrawer = (drawerStatus) => { 
	return {
		type: types.TOGGLE_DRAWER,
		payload: drawerStatus
	}			
};
	
export const setInnerLoading  = (loadingStatus) => { 
	return { 
		type: types.INNER_LOADING,
		payload: loadingStatus 
	}
};

export const setDialog  = (target,status) => { 
		
	/* Logica */
		// ...
	
	return { 
		type: types.SET_DIALOG,
		payload: {target:target,status:status} 
	}
};

export const setScheduled  = (value) => { 
		
	/* Logica */
		// ...
	
	return { 
		type: types.SET_SCHEDULE,
		payload: value
	}
};

export const setUserData  = (value) => { 

	return { 
		type: types.SET_USERDATA,
		payload: value 
	}
};

export const setChatData  = (value) => { 

	return { 
		type: types.SET_CHAT_DATA,
		payload: value 
	}
};

export const setChatChannel  = (value) => { 

	return { 
		type: types.SET_CHAT_CHANNEL,
		payload: value 
	}
};

export const setChatChannelId  = (value) => { 

	return { 
		type: types.SET_CHAT_CHANNEL_ID,
		payload: value 
	}
};

export const setChatCustomer  = (value) => { 

	return { 
		type: types.SET_CHAT_CUSTOMER,
		payload: value 
	}
};

export const setChatProvider  = (value) => { 

	return { 
		type: types.SET_CHAT_PROVIDER,
		payload: value 
	}
};

export const setTermsContent  = (value) => { 	
	return { 
		type: types.SET_TERMSCONTENT,
		payload: value
	}
};

	
export const setCustomerAlterTax  = (value) => { 
	return { 
		type: types.SET_CUSTOMER_ALTERTAX,
		payload: value 
	}
};

export const setCustomerCancelTax  = (value) => { 
	return { 
		type: types.SET_CUSTOMER_CANCELTAX,
		payload: value 
	}
};

export const retrieveTermsContent  = (data,props) => { 
		
		return dispatch => {
					
					// prepare data to send
					var sendData = {
						contentId:"fc49a5f7-f86e-44fd-918d-a139a11e43de"
					} // end if
	
					// Connect to retrieve content endpoint
					axios({
						   url    : config.API_URL+'/app/retrieveContent',
						   method : 'POST',
						   data: sendData,
						   headers: {
							Accept: 'application/json'
						}
					})				
					
					.then((response) => {
													
							// If an error occured. Check error is blocking or non-blocking.
							if (response.data.error) {					
								props.setDialogMessage('Ocorreu um erro ao buscar os termos de uso. Tente novamente. [1]');		
								props.setAlert(true);							
								return;						
							} // end if	*/
														
							if (response.data.success) {
											
								// if Status = 0 
								if (response.data.content) {
		
									// Harded CODE for TERMS...
									dispatch(setTermsContent(response.data.content));
									return;
									
								} // end if										
																
							} // end if				
					
					}, (error) => {
						var alertMessage = (error.code == "ECONNABORTED") ? 
						"Ocorreu um erro ao se comunicar com a API. Verifique se está com acesso a internet.":
						"Ocorreu um erro ao se comunicar com a API. Verifique o email e tente novamente.";					
						props.setDialogMessage(alertMessage);		
						props.setAlert(true);	
						return;											
					})
					.catch(error=> {
						props.setDialogMessage("Ocorreu um erro geral ao se comunicar com a API. Verifique sua conexão e tente novamente.");
						props.setAlert(true);	
						return;
					});
					
	} // end dispatch

};

export const retrieveContent  = (data,props) => { 
		
		return dispatch => {
					
					// prepare data to send
					var sendData = {
						contentId:data.contentId
					} // end if
	
					// Connect to retrieve content endpoint
					axios({
						   url    : config.API_URL+'/app/retrieveContent',
						   method : 'POST',
						   data: sendData,
						   headers: {
							Accept: 'application/json'
						}
					})				
					
					.then((response) => {
													
							// If an error occured. Check error is blocking or non-blocking.
							if (response.data.error) {					
								props.setDialogMessage('Ocorreu um erro ao buscar o conteúdo selecionado. Tente novamente. [1]');		
								props.setAlert(true);							
								return;						
							} // end if	*/
														
							if (response.data.success) {
											
								// if Status = 0 
								if (response.data.content) {
									
									// PROVISORY...
										// Add dinamic UUID STATE update...store based on UUID.

									// Harded CODE for TERMS...
									dispatch(setTermsContent(response.data.content));
									return;
									
								} // end if										
																
							} // end if				
					
					}, (error) => {
						var alertMessage = (error.code == "ECONNABORTED") ? 
						"Ocorreu um erro ao se comunicar com a API. Verifique se está com acesso a internet.":
						"Ocorreu um erro ao se comunicar com a API. Verifique o email e tente novamente.";					
						props.setDialogMessage(alertMessage);		
						props.setAlert(true);	
						return;											
					})
					.catch(error=> {
						props.setDialogMessage("Ocorreu um erro geral ao se comunicar com a API. Verifique sua conexão e tente novamente.");
						props.setAlert(true);	
						return;
					});
					
	} // end dispatch

};

export const retrieveUserData  = () => { 
		
		return dispatch => {

					// Check if there is a local USER OBEJECt with a TOKEN
					var result = localStorage.getItem("@motuum/userData", undefined,true);
					var userObj = JSON.parse(result);  
					
					if (!result) {
						return;
					} // end if
					
					// decode JWT 
					var decoded = jwtDecode(userObj.userToken);
					
					// Set CustomerUserId
					const CustomerUserId = decoded.CustomerUserId;		

					// Connect to retrieve content endpoint
					axios({
						   url    : config.API_URL+'/api/v1/user/retrieveData',
						   method : 'GET',
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})								
					.then((response) => {
													
							// If an error occured. Check error is blocking or non-blocking.
							if (response.data.error) {					

								console.log('Ocorreu um erro ao buscar os dados do usuário. Tente novamente. [1]');	
								
								// Set logoff flag
								localStorage.setItem("loggedOff",1);
								
								// Remove userData 
								localStorage.removeItem("@motuum/userData");	
								localStorage.removeItem("viewedHireWizard");
								localStorage.removeItem("viewedOnboarding");
								
								// Remove Session ALSO
								sessionStorage.removeItem("@motuum/searchingProvider");	
								sessionStorage.removeItem("@motuum/serviceOfferId");	
								sessionStorage.removeItem("@motuum/quotationId");	
								sessionStorage.removeItem("@motuum/sessionId");
								sessionStorage.removeItem("@motuum/selectedProvider");

								return;						
							} // end if	*/
														
							if (response.data.success) {
								
								// If user was not found, delete local info and go to login
								if (response.data.invalidToken ) {
						
										alert("A chave de seu usuário está diferente. Isso pode deixar seu acesso inseguro. Faça o login novamente.");
	
										// Remove userData 
										localStorage.removeItem("@motuum/userData");	
										localStorage.removeItem("viewedHireWizard");
										localStorage.removeItem("viewedOnboarding");
										sessionStorage.removeItem("@motuum/sessionId");
														
									return;
								} // end if												
								
				
								// Set Customer Taxes
								dispatch(setCustomerCancelTax(response.data.CustomerCancelTax));
								dispatch(setCustomerAlterTax(response.data.CustomerAlterTax));
								

								// set user data
								dispatch(setUserData(response.data.user));
								return;
																
							} // end if				
					
					}, (error) => {						
						var alertMessage = (error.code == "ECONNABORTED") ? 
						"Ocorreu um erro ao se comunicar com a API. Verifique se está com acesso a internet.":
						"Ocorreu um erro ao se comunicar com a API. Verifique o email e tente novamente.";					
						console.log(alertMessage);		
						return;											
					})
					.catch(error=> {
						console.log("Ocorreu um erro geral ao se comunicar com a API. Verifique sua conexão e tente novamente.");
						return;
					});
					
	} // end dispatch

};

export const terminateUserProfile  = (props) => { 
		
		return dispatch => {


					// Check if there is a local USER OBEJECt with a TOKEN
					var result = localStorage.getItem("@motuum/userData", undefined,true);
					var userObj = JSON.parse(result);  
					
					if (!result) {						
						return;
					} // end if
					
					// decode JWT 
					var decoded = jwtDecode(userObj.userToken);
					
					// Set CustomerUserId
					const CustomerUserId = decoded.CustomerUserId;
					
					// Connect to retrieve content endpoint
					axios({
						   url    : config.API_URL+'/api/v1/user/terminateProfile',
						   method : 'DELETE',
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})								
					.then((response) => {
													
							// If an error occured. Check error is blocking or non-blocking.
							if (response.data.error) {					

								props.setDialogMessage("Ocorreu um erro ao encerrar o seu perfil. Tente novamente.");
								props.setDialogTitle("Ops! Algo deu errado! :-(");
								props.setAlert(true);
								return;
				
							} // end if	*/
														
							if (response.data.success) {
								
								// Set logoff flag
								localStorage.setItem("loggedOff",1);
								
								// Remove userData 
								localStorage.removeItem("@motuum/userData");	
								localStorage.removeItem("viewedHireWizard");
								localStorage.removeItem("viewedOnboarding");
								
								props.navigate("/login");
								
								return;
																
							} // end if				
					
					}, (error) => {						
						var alertMessage = (error.code == "ECONNABORTED") ? 
						"Ocorreu um erro ao se comunicar com a API. Verifique se está com acesso a internet.":
						"Ocorreu um erro ao se comunicar com a API. Verifique o email e tente novamente.";					
						console.log(alertMessage);		
						return;											
					})
					.catch(error=> {
						console.log("Ocorreu um erro geral ao se comunicar com a API. Verifique sua conexão e tente novamente.");
						return;
					});
					
	} // end dispatch

};

export const retrieveFaq  = (data,props) => { 
		
		return dispatch => {
					
					// prepare data to send
					var sendData = {
						context:"customer"
					} // end if
	
					// Connect to retrieve content endpoint
					axios({
						   url    : config.API_URL+'/app/content/faq/list',
						   method : 'POST',
						   data: sendData,
						   headers: {
							Accept: 'application/json'
						}
					})				
					
					.then((response) => {

													
						// If an error occured. Check error is blocking or non-blocking.
						if (response.data.error) {	
							props.setLoading(false);					
							props.setFaq([]);						
							return;					
						} // end if	*/
				
						if (response.data.success) {
																
							props.setFaq(response.data.faq);
							
							// set loading off
							props.setLoading(false);						
								
						} // end if
						
						// If any other error, what do do???
						// ...
							
						}, (error) => {
							props.setLoading(false);						
							props.setFaq([]);						
							return;				
					})
					.catch(error=> {
						props.setLoading(false);						
						props.setFaq([]);						
						return;	
					});
					
	} // end dispatch
	
};


export const retrieveFaqByScreen  = (data,props) => { 
		
		return dispatch => {
					
					// prepare data to send
					var sendData = {
						context:"customer",
						screenReference:data.screenReference
					} // end if
	
					// Connect to retrieve content endpoint
					axios({
						   url    : config.API_URL+'/app/content/faq/listByScreen',
						   method : 'POST',
						   data: sendData,
						   headers: {
							Accept: 'application/json'
						}
					})				
					
					.then((response) => {
													
						// If an error occured. Check error is blocking or non-blocking.
						if (response.data.error) {	
							props.setLoading(false);					
							props.setFaq([]);						
							return;					
						} // end if	*/
				
						if (response.data.success) {
																
							props.setFaq(response.data.faq);
							
							// set loading off
							props.setLoading(false);						
								
						} // end if
						
						// If any other error, what do do???
						// ...
							
						}, (error) => {
							props.setLoading(false);						
							props.setFaq([]);						
							return;				
					})
					.catch(error=> {
						props.setLoading(false);						
						props.setFaq([]);						
						return;	
					});
					
	} // end dispatch
	
};



