import './MainOngoingServices.css';
import React,{useState,useEffect,useRef,Fragment} from 'react';
import {Link,useNavigate} from 'react-router-dom';
import { CSSTransition } from "react-transition-group";
import * as config from '../../Config/api.js';

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

import moment from 'moment';
import 'moment/locale/pt-br';

import { Box,Backdrop,CircularProgress,Button,Divider,Avatar } from '@mui/material';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Badge from '@mui/material/Badge';

import Fade from '@mui/material/Fade';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ChatIcon from '@mui/icons-material/Chat';
import NotInterestedIcon from '@mui/icons-material/NotInterested';

import {useComponentWillMount} from '../../Utils/customFunctions';
import Header from '../../Components/Layout/Header';
import If from '../../Components/Common/If';
import NavigationBottom from '../../Components/Layout/NavigationBottom';

import logo from '../../Assets/Images/logoTypeWOslogan.svg'; // Tell Webpack this JS file uses this image
import backgroundHexagon from '../../Assets/Images/backgroundHexagon.png'; // Tell Webpack this JS file uses this image
import desktop from '../../Assets/Images/desktop.svg'; // Tell Webpack this JS file uses this image
import router from '../../Assets/Images/router.svg'; // Tell Webpack this JS file uses this image
import zoomIconBlue from '../../Assets/Images/zoomIconBlue.svg'; 


// Load Actions
import * as AppActions from '../../Store/Actions/App'
import * as ServiceActions from '../../Store/Actions/Service'

const OngoingServices = props => {

const screenStyle = {
  alignItems: "stretch",
  justifyContent: "top",
  width:"100vw",
  minHeight:"100vh",
  display:"flex",
  flexDirection:"column",
  backgroundImage: "url(" + backgroundHexagon + ")",
  backgroundSize:"cover",
  backgroundRepeat:"repeat",
  backgroundPosition:"center"
}; 


const screenStyleLoading = {
  alignItems: "middle",
  justifyContent: "center",
  width:"100vw",
  height:"100vh",
  display: "flex",
  flexDirection: "column"
};

// set classes var
// const classes = useStyles()();

// set vars from props
// Remember to add those who come from Redux
const { 
	buttonLoading,
	setButtonLoading,
	OngoingServiceOrders,
	retrieveServiceOrders
} = props;

// set Use of history for PUSH 
let navigate = useNavigate();

// Set internal state
const [contentVisible,setContentState] = useState(false);
const [loadingMessage,setLoadingMessage] = useState("Carregando...");

const [dialogAlert, setAlertDialog] = useState(false); // state for tab
const [dialogMessage, setDialogMessage] = useState(""); // state for tab
const [dialogTitle, setDialogTitle] = useState("Verifique as informações"); // state for tab

const periodArray = [];
periodArray[1] = "09:00 ?s 13:00 (manhã)";
periodArray[2] = "13:00 ?s 18:00 (tarde)";
periodArray[3] = "18:00 ?s 21:00 (noite)";
 

// Go to Target when click on card
const goTo = (url) => {	
	navigate(url);
};
  
// Run after mount component
useEffect(() => {

	// Set Backdrop Loading over interface
	setButtonLoading(true); 

	// Retrieve Scheduled Services
	var data = {
		status:[1,2,3],
		page:1,
		limit:10
	}
	var props = {
		navigate:navigate,
		setAlert:setAlertDialog,
		setDialogMessage:setDialogMessage,
		setDialogTitle:setDialogTitle,
		setContentState:setContentState
	}	
	retrieveServiceOrders(data,props);
	
	// set scroll to top
	window.scrollTo(0, 1);

	// Runs when UNMOUNT component
	return () => {
	
		// unmount component
	
	};
  
}, []); // end useEffect

// useEffect only for history changes
useEffect(() => {	

	// Set Backdrop Loading over interface
	setButtonLoading(true); 

	// Retrieve Scheduled Services
	var data = {
		status:[1,2,3],
		page:1,
		limit:10
	}
	var props = {
		navigate:navigate,
		setAlert:setAlertDialog,
		setDialogMessage:setDialogMessage,
		setDialogTitle:setDialogTitle,
		setContentState:setContentState
	}	
	retrieveServiceOrders(data,props);

}, [history]); // end useEffect only for dialgo changes


// Will Mount Component HOOK
useComponentWillMount(() => {
	
    // Set session of app
  var sessionCheck = sessionStorage.getItem('@motuum/appLaunched');
  
  // if session is not created, redirect to bootstrap. (avoid direct access using url)
  if (!sessionCheck) {
	  navigate("/");
  } // end if	
  
	// Retrieve User Data Obj. 
	var result = localStorage.getItem("@motuum/userData", undefined,true);
	var userData = JSON.parse(result);  
  
	  // If token dont exist, redirect to onboarding or register
	  if (!userData) {	  
		navigate("/");  
	  } // end if
	  // If token exist, redirect to HOME
	  else {
		  if (!userData.userToken) {
			navigate("/");
		  } // end if
	  } // end else    
      
}, []); // end useComponentWillMount

	return (
	<Fragment>
			
			<Header title="Serviços em Andamento" buttons={{back:true,support:false,rightFake:true,menu:false,close:false,backUrl:"/home"}} />

			{/* Loading */}
			{ buttonLoading &&
					<Box className="loadingContent" style={{...screenStyleLoading}}>
							<Box mt={0} mx={0}>								
								<CircularProgress /> 
								<div> Carregando...Aguarde!</div>								
							</Box>	
					</Box>
			}

			<Fade in={contentVisible} timeout={{enter:1000,exit:300}}  mountOnEnter unmountOnExit>	
			
				<Box className="mainHome" style={{...screenStyle}} mt={0}>
					

							<Box pl={2} pr={2} mt={10} style={{alignItems:"stretch",display:"flex",flexDirection:"column",justifyContent:"center",paddingBottom:"120px",paddingLeft:"30px",paddingRight:"30px"}}>	
						
							<Box style={{marginTop:"30px",marginBottom:"30px"}}>						
								<div style={{fontFamily:"Roboto",fontWeight:"100",fontSize:"42px",color:"#003CFA",lineHeight:"38px"}}>
									  SERVIÇOS EM ANDAMENTO
								</div>								 	
							</Box>							
						

							{OngoingServiceOrders.map((item, index) => {
			
									if (item.type == 1) {
										var periodLabel = periodArray[item.scheduledPeriod];	
									} // end if
									else if (item.type == 2) {
										var periodLabel = "HOJE das "+periodArray[item.scheduledPeriod];	
									} // end if
									else {
										var periodLabel = "URGENTE - em até 2 horas";	
									} // end if
																	
									var formattedDate = moment(item.scheduledDate).locale('pt-br').format("DD/MM/YYYY (dddd)");
									var formattedDateNoWeek = moment(item.scheduledDate).locale('pt-br').format("DD/MM/YYYY");			
			
									// Update later for Google Directions API result (minutes to arrive).
									if (item.status == 1 || item.status == 2) {
										var arrivePrediction =  moment(item.willArriveAt).locale('pt-br').format("HH:mm");
										var arrivePredictionRaw =  moment(item.willArriveAt);
										var finisPredictionAfterArrive = moment(arrivePredictionRaw).add(item.ServiceOrderService.Service.effort,"minutes").locale('pt-br').format("HH:mm");
									}
							 
									// Is status is 3, use updatedDate to get started time. 
									var beganDatetime = moment(item.startedAt).locale('pt-br').format("HH:mm");
									var finishPrediction = moment(item.startedAt).add(item.ServiceOrderService.Service.effort,"minutes").locale('pt-br').format("HH:mm");
																	
									// set avatar URL
									var ProviderUserId = item.ProviderUser.id ? item.ProviderUser.id : null;
									var avatar = item.ProviderUser.ProviderProfile.avatar ? item.ProviderUser.ProviderProfile.avatar:null;
									var avatarImageUrl = config.API_URL+"/provider/avatar/retrieve/"+ProviderUserId+"/"+avatar+"";
									
									// set Provider employee and companyName
									var employeeName = item.ProviderUser.ProviderProfile.ProviderProfilePerson.completeName ? item.ProviderUser.ProviderProfile.ProviderProfilePerson.completeName: "Não definido";
									var companyName = item.ProviderUser.ProviderProfile.ProviderProfileCompany.realName ? item.ProviderUser.ProviderProfile.ProviderProfileCompany.realName: "Não definido";
															
									var cardColor = item.ServiceOrderService.Service.ServiceCategories[0].color ?item.ServiceOrderService.Service.ServiceCategories[0].color: "#CCC";
									
									var cardIcon = item.ServiceOrderService.Service.ServiceCategories[0].icon ?item.ServiceOrderService.Service.ServiceCategories[0].icon: desktop;
									
									var breadcrummb = item.ServiceOrderService.Service.ServiceCategories[0].breadcrumb ?item.ServiceOrderService.Service.ServiceCategories[0].breadcrumb: "Icone";
															
									return (<><Card key={item.id}  style={{minHeight:"194px",width:"100%",marginBottom:"20px",backgroundColor:"#ffffff",boxShadow: "0px 10px 20px 0px #0000002A",borderRadius:"10px",position:"relative"}} onClick={(target)=>goTo("/ongoingServices/view/"+item.id+"")}>
									
											<CardContent style={{padding:"10px",paddingBottom:"0px"}}>
															
												<Box style={{display:"flex",flexdirection:"row",alignItems:"flex-start",justifyContent:"space-between",marginTop:"10px",marginLeft:"15px",marginRight:"15px"}}>
													
													<Box style={{float:"left",textAlign:"left"}}>	

													  {item.status == 3 &&
														<h3 style={{color:"#1EC300",fontFamily:"Roboto",fontWeight:"bold",fontSize:"16px",marginTop:"0px",marginBottom:"2px",lineHeight:"18px"}}>Em execução</h3>
													  }	

													  {item.status != 3 &&
														<h3 style={{color:"#FFBA00",fontFamily:"Roboto",fontWeight:"bold",fontSize:"16px",marginTop:"0px",marginBottom:"2px",lineHeight:"18px"}}>Em trânsito</h3>
													  }	
														
														<div style={{color:"#636466",fontFamily:"Roboto",fontWeight:"bold",fontSize:"12px",lineHeight:"18px"}}>
														{item.ServiceOrderService.name}
														</div>	
													</Box>
													
													<div style={{textAlign:"right",float:"right",maxWidth:"50%",maxHeight:"55px",overflow:"hidden"}}>
														<h3 style={{color:"#636466",fontFamily:"Roboto",fontWeight:"bold",fontSize:"12px",marginTop:"0px",marginBottom:"2px",lineHeight:"18px"}}>{formattedDateNoWeek}</h3>
														<div style={{color:"#636466",fontFamily:"Roboto",fontWeight:"normal",fontSize:"12px",lineHeight:"18px"}}>
															
																{item.status == 3 &&
																	<div>
																	INICIADO {beganDatetime}<br />
																	PREVISÃO TÉRMINO {finishPrediction}
																	</div>														
																}
																
																{item.status != 3 &&
																	<div>
																	CHEGADA {arrivePrediction}<br />
																	PREVISÃO TÉRMINO {finisPredictionAfterArrive}
																	</div>	
																}
																
														</div>
													</div>														
												
												</Box>
												
												<Divider style={{marginBottom:"15px",marginTop:"15px",marginLeft:"15px",marginRight:"15px"}} />
												
												<Box style={{display:"flex",flexdirection:"row",alignItems:"center",justifyContent:"space-between",marginTop:"10px",marginLeft:"15px",marginRight:"15px"}}>
													
													<Box style={{float:"left",textAlign:"left"}}>										
														<h3 style={{color:"#636466",fontFamily:"Roboto",fontWeight:"bold",fontSize:"16px",marginTop:"0px",marginBottom:"2px",lineHeight:"18px"}}>{employeeName}</h3>
														<div style={{color:"#636466",fontFamily:"Roboto",fontWeight:"bold",fontSize:"12px",lineHeight:"18px"}}>
														{companyName}
														</div>	
													</Box>
													
													<div style={{textAlign:"right",float:"right"}}>
															<Avatar src={avatarImageUrl} variant="square" style={{width:"40px",height:"40px",borderRadius:"50px"}} />	
													</div>														
												
												</Box>									
												
											  </CardContent>
										
										<CardActions style={{backgroundColor:"#003CFA10",padding:"2px", position:"absolute",bottom:"0",width:"100%"}}>
												  
										  <div style={{flex:1,textAlign:"center"}}>
												<Button style={{color:"#003CFA",fontFamily:"Roboto",fontWeight:"normal",fontSize:"12px"}} size="small" onClick={()=>console.log("pressed...") }>										
													<img src={zoomIconBlue} style={{width:"24px",height:"24px",marginRight:"5px"}} />
													<div>Detalhes do serviço</div>										
												</Button>
											</div>
											
										 </CardActions>										

									</Card></>) // end return
											
									} 
											
								)}	

								{/* If there is no result */}
								{OngoingServiceOrders.length == 0 &&
									<div style={{height:"50vh",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center"}}>
									
										<div style={{flex:1.5,display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"flex-end",marginBottom:10}}>
										  <NotInterestedIcon style={{fontSize:"7rem",color: "#DDD"}} />
										</div>
										
										<div style={{flex:1.5,display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"flex-start"}}>
											<div style={{fontSize:20,color:"silver",textAlign:"center"}}>Nenhum serviço em andamento</div>
										</div>													
									
									</div>				
								}									
																
						</Box>	
						
						{/* Bottom Navigation Bar */}
						<NavigationBottom page="ongoing" />						

						
				</Box>
			
			</Fade> 

			<AlertDialog 
				dialogAlert={dialogAlert}
				setAlertDialog={setAlertDialog}
				dialogTitle={dialogTitle}
				dialogMessage={dialogMessage}
			/>		
				
	</Fragment>
)};



const AlertDialog = (props) => {
	
	return (
		 <Dialog
			open={props.dialogAlert}
			onClose={() => props.setAlertDialog(false)} 
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		  >
		  <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
			<DialogContent>
			  <DialogContentText id="alert-dialog-description">
			  {props.dialogMessage}
			  </DialogContentText>
			</DialogContent>
			<DialogActions>
			  <Button onClick={() => props.setAlertDialog(false)} color="primary" autoFocus>
				OK
			  </Button>
			</DialogActions>			
		  </Dialog>
	  )
	
	
} // end AlertDialog

const mapStateToProps = store => ({
  buttonLoading:store.serviceReducer.buttonLoading,  
  OngoingServiceOrders:store.serviceReducer.OngoingServiceOrders
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AppActions, ...ServiceActions }, dispatch);

export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(OngoingServices);
	
