import './MainOngoingServices.css';
import React,{useState,useEffect,Fragment} from 'react';
import {Link,useNavigate,useParams} from 'react-router-dom';
import { CSSTransition } from "react-transition-group";
import * as config from '../../Config/api.js';

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

import moment from 'moment';
import 'moment/locale/pt-br';
import add from 'date-fns/add'
import format from 'date-fns/format'
import { DatePicker } from '@mui/x-date-pickers'

import AnimateHeight from 'react-animate-height';

import { Box,Backdrop,CircularProgress,Paper,Divider,Typography,Button,Avatar } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Fade from '@mui/material/Fade';
import Slide from '@mui/material/Slide';
import Badge from '@mui/material/Badge';

import Select from '@mui/material/Select';
import NativeSelect from '@mui/material/NativeSelect';
import MenuItem from '@mui/material/MenuItem';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import brLocale from "date-fns/locale/pt-BR";
import DateFnsUtils from '@date-io/date-fns'; // choose your lib

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import CloseIcon from '@mui/icons-material/Close';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Info from '@mui/icons-material/ErrorOutline';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AccountTreeIcon from '@mui/icons-material/AccountTree'; 
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ScheduleIcon from '@mui/icons-material/Schedule';
import WarningIcon from '@mui/icons-material/Warning';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import EventIcon from '@mui/icons-material/Event';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ChatIcon from '@mui/icons-material/Chat';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import HelpIcon from '@mui/icons-material/Help';

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';

import {useComponentWillMount} from '../../Utils/customFunctions';
import Header from '../../Components/Layout/Header';
import If from '../../Components/Common/If';
import {ServiceDetailDialog} from './DialogServiceDetail';
import {ServiceInfoDialog} from './DialogServiceInfo';
import {PaymentDetailDialog} from './DialogPaymentDetail';
import {ExtraDetailDialog} from './DialogExtraDetail';
import {AddressDetailDialog} from './DialogAddressDetail';
import {ImageDetailDialog} from './DialogImageDetail';
import {ProviderLocationDialog} from './DialogProviderLocationDetail';

import backgroundHexagon from '../../Assets/Images/backgroundHexagon.png'; // Tell Webpack this JS file uses this image
import backgroundHexagonLight from '../../Assets/Images/backgroundHexagonLight.png'; // Tell Webpack this JS file uses this image
import headerCategory1 from '../../Assets/Images/headerSimpleCategory1.png'; // Tell Webpack this JS file uses this image
import priceIconBlue from '../../Assets/Images/priceIconBlue.svg'; 
import scheduleIconBlue from '../../Assets/Images/scheduleIconBlue.svg'; 
import plusIconBlue from '../../Assets/Images/plusIconBlue.svg'; 
import lessIconBlue from '../../Assets/Images/lessIconBlue.svg'; 
import zoomIconBlue from '../../Assets/Images/zoomIconBlue.svg'; 
import chatIconGreen from '../../Assets/Images/iconChatGreen.svg'; 
import calendarIconBlue from '../../Assets/Images/calendarIconBlue.svg'; 
import placeIconBlue from '../../Assets/Images/placeIconBlue.svg'; 
import cardIconBlue from '../../Assets/Images/cardIconBlue.svg'; 
import personIconBlue from '../../Assets/Images/personIconBlue.svg'; 
import chatIconBlue from '../../Assets/Images/chatIconBlue.svg'; 

import visa from '../../Assets/Images/visa.svg'; // Tell Webpack this JS file uses this image
import mastercard from '../../Assets/Images/mastercard.svg'; // Tell Webpack this JS file uses this image
import amex from '../../Assets/Images/amex.svg'; // Tell Webpack this JS file uses this image
import diners from '../../Assets/Images/diners.svg'; // Tell Webpack this JS file uses this image
import elo from '../../Assets/Images/elo.svg'; // Tell Webpack this JS file uses this image
import genericFlag from '../../Assets/Images/genericFlag.svg'; // Tell Webpack this JS file uses this image

// Load Actions
import * as AppActions from '../../Store/Actions/App'
import * as ServiceActions from '../../Store/Actions/Service'

const OngoingServicesView = props => {


const screenStyle = {
  alignItems: "stretch",
  justifyContent: "top",
  width:"100vw",
  minHeight:"100vh",
  display:"flex",
  flexDirection:"column",
  backgroundImage: "url(" + backgroundHexagon + ")",
  backgroundSize:"cover",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"center"
};

const backgroundLight = {
  backgroundImage: "url(" + backgroundHexagonLight + ")",
  backgroundSize:"cover",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"center"
};

const backgroundStyle = {
  backgroundImage: "url(" + backgroundHexagon + ")",
  backgroundSize:"cover",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"center"
}; 

const screenStyleLoading = {
  alignItems: "middle",
  justifyContent: "center",
  width:"100vw",
  height:"100vh",
  display: "flex",
  flexDirection: "column"
}; 


const screenStyleBackgroundImage = {
  backgroundSize:"auto",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"center",
  minHeight:"59px",
  width:"100%"
};

// set classes var
// const classes = useStyles()();

// set vars from props
const { 
	buttonLoading,
	setButtonLoading,
	SelectedOngoingServiceOrder,
	retrieveServiceOrder,
	retrieveProviderLocation,
	setDialog,
	dialog
} = props;

let { id } = useParams();
const [ServiceOrderId,setServiceOrderId] = useState(id);

// Set internal state
const [contentVisible,setContentState] = useState(false);
const [contentNotFoundVisible,setContentNotFoundVisible] = useState(false);
const [dialogAlert, setAlertDialog] = useState(false); // state for tab
const [dialogMessage, setDialogMessage] = useState(""); // state for tab
const [dialogTitle, setDialogTitle] = useState("Verifique as informações"); // state for tab
const [dialogAction, setDialogAction] = useState(false); // state for tab
const [dialogActionUrl, setDialogActionUrl] = useState("ongoingServices"); // state for tab

const [image,setImage] = useState("");

//Set content constants
const [serviceId,setServiceId] = useState(null);
const [serviceName,setServiceName] = useState("carregando...");
const [serviceDescription,setServiceDescription] = useState("carregando...");
const [serviceScheduledDate,setServiceScheduledDate] = useState("carregando...");
const [breadcrumb,setBreadcrumb] = useState("carregando...");
const [serviceWarranty,setServiceWarranty] = useState("carregando...");
const [servicePaymentReferenceNumber,setServicePaymentReference] = useState("carregando...");

const [categoryHeader,setCategoryHeader] = useState("carregando...");

const [serviceScheduledPeriod,setServiceScheduledPeriod] = useState("carregando...");
const [servicePaymentMethod,setServicePaymentMethod] = useState("carregando...");
const [serviceExecutionTime,setServiceExecutionTime] = useState("carregando...");
const [servicePrice,setServicePrice] = useState("carregando...");
const [serviceAddress,setServiceAddress] = useState("carregando...");
const [serviceBillingInfo,setServiceBillingInfo] = useState("carregando...");
const [serviceEmployeeName,setServiceEmployeeName] = useState("carregando...");
const [serviceEmployeeDocument,setServiceEmployeeDocument] = useState("carregando...");
const [serviceEmployeeDocumentAlt,setServiceEmployeeDocumentAlt] = useState("carregando...");
const [serviceEmployeeDocumentAltFlag,setServiceEmployeeDocumentAltFlag] = useState(false);
const [serviceCompanyName,setServiceCompanyName] = useState("carregando...");

const [metaInfo,setMetaInfo] = useState([]);

const [billingName,setServiceBillingName] = useState("carregando...");
const [billingDocument,setServiceBillingDocument] = useState("carregando...");

const [serviceScheduledDateNoWeek,setServiceScheduledDateNoWeek] = useState("carregando...");
const [serviceScheduledDateWeek,setServiceScheduledDateWeek] = useState("carregando...");

const [serviceStartedAt,setServiceStartedAt] = useState("carregando...");
const [minutesFromStart,setMinutesFromStart] = useState("carregando...");
const [servicePredictedFinish,setServicePredictedFinish] = useState("carregando...");
const [serviceArrivePrediction,setServiceArrivePrediction] = useState("carregando...");

const [currentLocation,setCurrentLocation] = useState(null);
const [arriveEstimation,setArriveEstimation] = useState(null);

const [extraInfoflag,setExtraInfoFlag] = useState(false);
const [serviceStatus, setServiceStatus] = useState(3);
const [cancelDate, setCancelDate] = useState("");

const [heightPanel,setHeightPanel] = useState(0);
const [cardFlag, setCardFlag] = useState(""); // state for tab

var avatarImageUrl = config.API_URL+"/provider/avatar/retrieve/null/null";
const [serviceCompanyAvatar,setServiceCompanyAvatar] = useState(avatarImageUrl);

const [headerTitle, setHeaderTitle] = useState("Serviço em andamento"); // state for tab

const periodArray = [];
periodArray[1] = "09:00 ?s 13:00";
periodArray[2] = "13:00 ?s 18:00";
periodArray[3] = "18:00 ?s 21:00";

let navigate = useNavigate();

// Run after mount component
useEffect(() => {
		
	// Set Backdrop Loading over interface
	setButtonLoading(true); 

	// Retrieve Scheduled Services
	var data = {
		serviceOrderId:ServiceOrderId,
		status:[1,2,3]
	}	
	var props = {
		navigate:navigate,
		setAlert:setAlertDialog,
		setDialogMessage:setDialogMessage,
		setDialogTitle:setDialogTitle,
		setContentState:setContentState,
		setContentNotFoundVisible:setContentNotFoundVisible
	}	
	retrieveServiceOrder(data,props);
	
  // set scroll to top
  window.scrollTo(0, 1);
    
  // Runs when UNMOUNT component
  return () => {
    console.log("Desmontou o componente MainHome...");
  };
  
}, []); // end useEffect


const zoomImage = (image) => {
	
	props.setImage(image);
	setDialog('imageDetail',true);
	
}

// Run if object [SelectedOngoingServiceOrder] change
useEffect(() => {

try {

// Update later for Google Directions API result (minutes to arrive).
if (SelectedOngoingServiceOrder.status == 1 || SelectedOngoingServiceOrder.status == 2) {
	var arrivePrediction =  moment(SelectedOngoingServiceOrder.willArriveAt).locale('pt-br').format("HH:mm");
	var arrivePredictionRaw =  moment(SelectedOngoingServiceOrder.willArriveAt);
	var finisPredictionAfterArrive = moment(arrivePredictionRaw).add(SelectedOngoingServiceOrder.ServiceOrderService.Service.effort,"minutes").locale('pt-br').format("HH:mm");
	setServiceArrivePrediction(arrivePrediction);
	setHeaderTitle("Profissional em trânsito");
}
else {
	setHeaderTitle("Serviço em andamento");
}

// Is status is 3, use updatedDate to get started time. 
var beganDatetime = moment(SelectedOngoingServiceOrder.startedAt).locale('pt-br').format("HH:mm");
setServiceStartedAt(beganDatetime);

var beganDatetimeRaw = moment(SelectedOngoingServiceOrder.startedAt);
var endTime = moment();

var duration = moment.duration(endTime.diff(beganDatetimeRaw));
var minutes = Math.floor(duration.asMinutes());
setMinutesFromStart(minutes);

var finishPrediction = moment(SelectedOngoingServiceOrder.startedAt).add(SelectedOngoingServiceOrder.ServiceOrderService.Service.effort,"minutes").locale('pt-br').format("HH:mm");	
setServicePredictedFinish(finishPrediction);
	
// Set service details
setServiceId(SelectedOngoingServiceOrder.ServiceOrderService.ServiceId);
setServiceName(SelectedOngoingServiceOrder.ServiceOrderService.name);
setServiceDescription(SelectedOngoingServiceOrder.ServiceOrderService.description);
setServiceWarranty(SelectedOngoingServiceOrder.ServiceOrderService.warrantyTerm);

setBreadcrumb(SelectedOngoingServiceOrder.ServiceOrderService.Service.ServiceCategories[0].breadcrumb);
setCategoryHeader(SelectedOngoingServiceOrder.ServiceOrderService.Service.ServiceCategories[0].headerImageSize3);


// set payment reference number
setServicePaymentReference(SelectedOngoingServiceOrder.ServiceOrderCreditCard.referenceNumber);

// Set Meta Info
setMetaInfo(SelectedOngoingServiceOrder.ServiceOrderMetaInformations);

if (SelectedOngoingServiceOrder.type == 1) {
	var periodLabel = periodArray[SelectedOngoingServiceOrder.scheduledPeriod];	
} // end if
else if (SelectedOngoingServiceOrder.type == 2) {
	var periodLabel = "HOJE das "+periodArray[SelectedOngoingServiceOrder.scheduledPeriod];	
} // end if
else {
	var periodLabel = "URGENTE - em até 2 horas";	
} // end if
setServiceScheduledPeriod(periodLabel);

var formattedDate = moment(SelectedOngoingServiceOrder.scheduledDate).locale('pt-br').format("DD/MM/YYYY (dddd)");
setServiceScheduledDate(formattedDate);

var serviceScheduledDateNoWeekTmp = moment(SelectedOngoingServiceOrder.scheduledDate).locale('pt-br').format("DD/MM/YYYY");
setServiceScheduledDateNoWeek(serviceScheduledDateNoWeekTmp);

var  serviceScheduledDateWeekTmp = moment(SelectedOngoingServiceOrder.scheduledDate).locale('pt-br').format("(dddd)");
setServiceScheduledDateWeek(serviceScheduledDateWeekTmp);


var paymentMethodLabel = SelectedOngoingServiceOrder.paymentMethod == 1 ? "Cartão de Crédito": "Transferência via PIX";
setServicePaymentMethod(paymentMethodLabel);

var timeLabel = SelectedOngoingServiceOrder.ServiceOrderService.effort +" "+SelectedOngoingServiceOrder.ServiceOrderService.effortUnity;
setServiceExecutionTime(timeLabel);

var priceLabel = "R$ "+(Math.round(SelectedOngoingServiceOrder.ServiceTotalPrice * 100) / 100).toFixed(2);
setServicePrice(priceLabel);

// set status
setServiceStatus(SelectedOngoingServiceOrder.status);
setCancelDate(moment(SelectedOngoingServiceOrder.cancelledAt).format("DD/MM/YYYY HH:mm"));

// Set address and billing info
setServiceAddress(SelectedOngoingServiceOrder.ServiceOrderAddress.fullAddress);
var BillinInfoType = SelectedOngoingServiceOrder.ServiceOrderBilling.type == 1 ? "<strong>Pessoa Física</strong>": "<strong>Pessoa Jurídica</strong>";


if (SelectedOngoingServiceOrder.ServiceOrderMetaInformations.length > 0) {
	setExtraInfoFlag(true);
} // end if


var BillingInfoLabel = BillinInfoType+" <br /> "+SelectedOngoingServiceOrder.ServiceOrderBilling.billingName +" <br /> "+SelectedOngoingServiceOrder.ServiceOrderBilling.fullAddress;
setServiceBillingInfo(BillingInfoLabel);

var billingNameTmp = SelectedOngoingServiceOrder.ServiceOrderBilling.billingName;
setServiceBillingName(billingNameTmp);

var billingDocumentTmp = SelectedOngoingServiceOrder.ServiceOrderBilling.billingDocument;
setServiceBillingDocument(billingDocumentTmp);

// Set Provider Data
setServiceEmployeeName(SelectedOngoingServiceOrder.ProviderUser.ProviderProfile.ProviderProfilePerson.ProviderProfileCompanyEmployee.completeName);
setServiceEmployeeDocument(SelectedOngoingServiceOrder.ProviderUser.ProviderProfile.ProviderProfilePerson.ProviderProfileCompanyEmployee.document);

setServiceEmployeeDocumentAlt(SelectedOngoingServiceOrder.ProviderUser.ProviderProfile.ProviderProfilePerson.ProviderProfileCompanyEmployee.documentAlt+" ("+SelectedOngoingServiceOrder.ProviderUser.ProviderProfile.ProviderProfilePerson.ProviderProfileCompanyEmployee.documentAltInfo+")");
setServiceEmployeeDocumentAltFlag(SelectedOngoingServiceOrder.ProviderUser.ProviderProfile.ProviderProfilePerson.ProviderProfileCompanyEmployee.documentAlt);

setServiceCompanyName(SelectedOngoingServiceOrder.ProviderUser.ProviderProfile.ProviderProfileCompany.realName);
var avatarImageUrl = config.API_URL+"/provider/avatar/retrieve/"+SelectedOngoingServiceOrder.ProviderUser.id+"/"+SelectedOngoingServiceOrder.ProviderUser.ProviderProfile.avatar+"";
setServiceCompanyAvatar(avatarImageUrl);

// PAYMENT Info	
	if (SelectedOngoingServiceOrder.ServiceOrderCreditCard.cardFlag == "visa") {
		setCardFlag(visa);
	} // end if
	else if  (SelectedOngoingServiceOrder.ServiceOrderCreditCard.cardFlag == "mastercard") {
		setCardFlag(mastercard);
	} // end else if
	else if  (SelectedOngoingServiceOrder.ServiceOrderCreditCard.cardFlag == "amex") {
		setCardFlag(amex);
	} // end else if	
	else if  (SelectedOngoingServiceOrder.ServiceOrderCreditCard.cardFlag == "diners") {
		setCardFlag(diners);
	} // end else if	
	else if  (SelectedOngoingServiceOrder.ServiceOrderCreditCard.cardFlag == "elo") {
		setCardFlag(elo);
	} // end else if	
	else if  (SelectedOngoingServiceOrder.ServiceOrderCreditCard.cardFlag == "generic") {
		setCardFlag(genericFlag);
	} // end else if	

} catch(e) {}

  
}, [SelectedOngoingServiceOrder]); // end useEffect

// useEffect only for history changes
useEffect(() => {	
	setExtraInfoFlag(false);
}, [history]); // end useEffect only for dialgo changes


// Will Mount Component HOOK
useComponentWillMount(() => {
	
    // Set session of app
  var sessionCheck = sessionStorage.getItem('@motuum/appLaunched');
  
  // if session is not created, redirect to bootstrap. (avoid direct access using url)
  if (!sessionCheck) {
	  navigate("/");
  } // end if	
  
	// Retrieve User Data Obj. 
	var result = localStorage.getItem("@motuum/userData", undefined,true);
	var userData = JSON.parse(result);  
  
	  // If token dont exist, redirect to onboarding or register
	  if (!userData) {	  
		navigate("/");  
	  } // end if
	  // If token exist, redirect to HOME
	  else {
		  if (!userData.userToken) {
			navigate("/");
		  } // end if
	  } // end else    
      
}, []); // end useComponentWillMount



// useEffect only for history changes
useEffect(() => {	

try {
	// Runs when opens
	  var data = {
		  ServiceOrderId:SelectedOngoingServiceOrder.id,
		  ProviderUserId:SelectedOngoingServiceOrder.ProviderUser.id		  
	  }
	  var propsParam = {
		  setCurrentLocation:setCurrentLocation,
		  setArriveEstimation:setArriveEstimation			  
	  }
	  retrieveProviderLocation(data,propsParam);

	// Replace with Location.startLocationUpdatesAsync(taskName, options)
	var locationTimer = setInterval(function() {

		  var data = {
			  ServiceOrderId:SelectedOngoingServiceOrder.id,
			  ProviderUserId:SelectedOngoingServiceOrder.ProviderUser.id		  
		  }
		  var propsParam = {
			  setCurrentLocation:setCurrentLocation,
			  setArriveEstimation:setArriveEstimation			  
		  }
		  retrieveProviderLocation(data,propsParam);	  
			
	},180000); // Run every 3 minutes	

	// Unmount component...
	return () => {
      clearInterval(locationTimer);
    };
	
} catch(e) {
	console.log(e.message);
}	
	
}, [SelectedOngoingServiceOrder]); // end useEffect only for dialgo changes

const retrieveCurrentLocation = () => {
	
	  var data = {
		  ServiceOrderId:SelectedOngoingServiceOrder.id,
		  ProviderUserId:SelectedOngoingServiceOrder.ProviderUser.id		  
	  }
	  var propsParam = {
		  setCurrentLocation:setCurrentLocation,
		  setArriveEstimation:setArriveEstimation			  
	  }
	  retrieveProviderLocation(data,propsParam);	
	
}


	return (
	<Fragment>
			
			<Header title={headerTitle} buttons={{support:false,menu:false,close:false,back:true,rightFake:true}}  />
			
			{/* Loading */}
			{ buttonLoading &&
					<Box className="loadingContent" style={{...screenStyleLoading}}>
							<Box mt={0} mx={0}>								
								<CircularProgress /> 
								<div> Carregando...Aguarde!</div>								
							</Box>	
					</Box>
			}			
			
			<Fade in={contentVisible} timeout={1000} mountOnEnter unmountOnExit>			
			<div>


				<Box className="mainSearchServicesView" style={{...screenStyle}} mt={0}>
							
					{/* Category Block */}
					<Box mt={10} mb={0} style={{paddingLeft:"10px",paddingRight:"10px"}} >
					
						<div style={{color:"#636466",fontFamily:"Roboto",fontSize:"16px",fontWeight:"normal",margin:"20px",textAlign:"left"}} onClick={()=>{ navigate("/searchService") } }> 
							{breadcrumb} 
						</div>
						
					</Box>	

					<Box style={{paddingLeft:"30px",paddingRight:"30px",marginBottom:"0px"}}> 
						
						<Card style={{minWidth: "90%",marginBottom:"20px",minHeight:serviceStatus != 3 ? "220px":"252px",width:"100%",marginBottom:"10px",backgroundColor:"#ffffff",boxShadow: "0px 10px 20px 0px #0000002A",borderRadius:"10px",position:"relative"}}>
									
							<CardContent style={{padding:"10px",paddingBottom:"0px"}}>
							
								<Box style={{display:"flex",flexdirection:"row",alignItems:"flex-start",justifyContent:"space-between",marginTop:"10px",marginLeft:"15px",marginRight:"15px"}}>
									
									<Box style={{float:"left",textAlign:"left"}}>	

									  {serviceStatus == 3 &&
										<h3 style={{color:"#1EC300",fontFamily:"Roboto",fontWeight:"bold",fontSize:"16px",marginTop:"0px",marginBottom:"2px",lineHeight:"18px"}}>Em execução</h3>
									  }	

									  {serviceStatus != 3 &&
										<h3 style={{color:"#FFBA00",fontFamily:"Roboto",fontWeight:"bold",fontSize:"16px",marginTop:"0px",marginBottom:"2px",lineHeight:"18px"}}>Em trânsito</h3>
									  }	
										
										<div style={{color:"#636466",fontFamily:"Roboto",fontWeight:"bold",fontSize:"12px",lineHeight:"18px"}}>
										{serviceName}
										</div>	
									</Box>
									
									<div style={{textAlign:"right",float:"right",maxWidth:"50%",maxHeight:serviceStatus != 3 ? "55px":"72px",overflow:"hidden"}}>
										<h3 style={{color:"#636466",fontFamily:"Roboto",fontWeight:"bold",fontSize:"12px",marginTop:"0px",marginBottom:"2px",lineHeight:"18px"}}>{serviceScheduledDateNoWeek}</h3>
										<div style={{color:"#636466",fontFamily:"Roboto",fontWeight:"normal",fontSize:"12px",lineHeight:"18px"}}>
											
												{serviceStatus == 3 &&
													<div>
													INICIADO ÀS {serviceStartedAt}<br />
													PREVISÃO TÉRMINO {servicePredictedFinish}<br />
													<div style={{color:"#003CFA"}}>TEMPO DECOR. {minutesFromStart} min</div>
													</div>														
												}
												
												{serviceStatus != 3 &&
													<div>
													CHEGADA PREVISTA {arriveEstimation ? arriveEstimation : serviceArrivePrediction}<br />
													</div>	
												}
												
										</div>
									</div>														
								
								</Box>
								
								<Divider style={{marginBottom:"15px",marginTop:"15px",marginLeft:"15px",marginRight:"15px"}} />							
								
								<Box style={{display:"flex",flexdirection:"row",alignItems:"center",justifyContent:"space-between",marginTop:"10px",marginLeft:"15px",marginRight:"15px"}}>
									
									<Box style={{float:"left",textAlign:"left"}}>										
										<h3 style={{color:"#003CFA",fontFamily:"Roboto",fontWeight:"bold",fontSize:"16px",marginTop:"0px",marginBottom:"2px",lineHeight:"18px"}}>{serviceEmployeeName}</h3>
										<div style={{color:"#636466",fontFamily:"Roboto",fontWeight:"bold",fontSize:"12px",lineHeight:"18px"}}>
										{serviceCompanyName}
										</div>	
									</Box>
									
									<div style={{textAlign:"right",float:"right"}}>
											<Avatar src={avatarImageUrl} variant="square"  style={{width:"50px",height:"50px",borderRadius:"50px"}} />	
									</div>														
								
								</Box>	
							
								<Box style={{textAlign:"left",padding:"0px",marginTop:"0px",color:"#636466",fontFamily:"Roboto",fontWeight:"regular",fontSize:"12px",marginLeft:"15px",marginRight:"15px"}}>
																						
										{serviceEmployeeDocumentAltFlag &&
										<>
										RG: {serviceEmployeeDocumentAlt} <br />
										</>
										}
										CPF: {serviceEmployeeDocument}
											
								</Box>									
								
							  </CardContent>
						
						{serviceStatus == 3 &&
							<CardActions style={{backgroundColor:"#003CFA10",padding:"2px", position:"absolute",bottom:"0",width:"100%"}}>
									  
							  <div style={{flex:1,textAlign:"center"}}>
									<Button style={{color:"#003CFA",fontFamily:"Roboto",fontWeight:"normal",fontSize:"12px"}} size="small" onClick={()=>console.log("pressed...") }>										
										<img src={chatIconBlue} style={{width:"20px",height:"20px",marginRight:"5px"}} />
										<div>ABRIR CHAT COM SUPORTE</div>										
									</Button>
								</div>
								
							 </CardActions>														
						}
						
						{serviceStatus != 3 &&
							<CardActions style={{backgroundColor:"#1EC30010",padding:"2px", position:"absolute",bottom:"0",width:"100%"}}>
									  
							  <div style={{flex:1,textAlign:"center"}}>
									<Button style={{color:"#1EC300",fontFamily:"Roboto",fontWeight:"normal",fontSize:"12px"}} size="small" onClick={()=>console.log("pressed...") }>										
										<img src={chatIconGreen} style={{width:"20px",height:"20px",marginRight:"5px"}} />
										<div>ABRIR CHAT COM PROFISSIONAL</div>										
									</Button>
								</div>
								
							 </CardActions>	
						}						
						
								

					</Card>

					</Box>	
					
					<Box style={{paddingLeft:"30px",paddingRight:"30px",marginBottom:"10px"}}> 

						<Card style={{minWidth: "90%",marginBottom:"20px",minHeight:"154px",width:"100%",marginBottom:"10px",backgroundColor:"#ffffff",boxShadow: "0px 10px 20px 0px #0000002A",borderRadius:"10px"}}>
						
						  <CardContent style={{padding:"25px",paddingBottom:"0px"}}>
							
							<Box >
								
								<Box style={{padding:"0px"}}>
									
										<div style={{textAlign:"left"}}>
											<h3 style={{color:"#003CFA",fontFamily:"Roboto",fontWeight:"bold",fontSize:"16px",marginTop:"0px",marginBottom:"5px",lineHeight:"18px"}}>{serviceName}</h3>
											<div style={{minHeight:"54px",color:"#636466",fontFamily:"Roboto",fontWeight:"normal",fontSize:"12px",lineHeight:"18px"}}>
												{serviceDescription}
											</div>
										</div>											
									
								</Box>	
										
								<AnimateHeight
								  duration={ 500 }
								  height={ heightPanel }
								>								
									<Box style={{padding:"0px",marginTop:"15px"}}>
										
											<div style={{textAlign:"left"}}>
												<h3 style={{color:"#003CFA",fontFamily:"Roboto",fontWeight:"bold",fontSize:"16px",marginTop:"0px",marginBottom:"5px",lineHeight:"18px"}}>Garantia do Serviço</h3>
												<div style={{color:"#636466",fontFamily:"Roboto",fontWeight:"normal",fontSize:"12px",lineHeight:"18px"}}>
													{serviceWarranty}
												</div>
											</div>											
										
									</Box>									
								</AnimateHeight>
								
							
							</Box>
							
						  </CardContent>
							
							<CardActions style={{display:"flex",flexDirection:"row",backgroundColor:"#003CFA10",padding:"2px",marginTop:"19px"}}>
									  
							  <div style={{flex:1,textAlign:"center"}}>
									
									{heightPanel == 0 &&
									<Button style={{color:"#003CFA",fontFamily:"Roboto",fontWeight:"normal",fontSize:"12px"}} size="small" onClick={()=>setHeightPanel(100)}>										
										<img src={plusIconBlue} style={{width:"18px",height:"18px",marginRight:"5px"}} />
										<div>Ver mais</div>										
									</Button>	
									}									
									
									{heightPanel > 0 &&
									<Button style={{color:"#003CFA",fontFamily:"Roboto",fontWeight:"normal",fontSize:"12px"}} size="small" onClick={()=>setHeightPanel(0)}>										
										<img src={lessIconBlue} style={{width:"18px",height:"18px",marginRight:"5px"}} />
										<div>Ver menos</div>										
									</Button>
									}
									
								</div>
								
							  </CardActions>										

						</Card>	
						

					</Box>	
	
					<Divider light style={{marginLeft:"30px",marginRight:"30px"}} />

					<Box pt={0} pb={2} mt={0} mb={0} style={{...backgroundLight,alignItems:"stretch",justifyContent:"center",display:"flex",flexDirection:"column",paddingLeft:"30px",paddingRight:"30px"}}>
							
							
							{/* Price Box */}	
							<Box mt={1} ml={1} mr={1} mb={1} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>
							
								<Box style={{flex:2}}>
								
									<Box style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>    
										<div style={{marginRight:"5px"}}> 
											<img src={priceIconBlue} style={{width:"30px",height:"30px"}} />
										</div>
										
										<div> 
											<div style={{fontFamily:"Roboto",fontWeight:"regular",fontSize:"12px",color:"#636466",textTransform:"uppercase"}}>Valor do serviço</div>
										</div>
									</Box>									
								
								</Box>
								
								<Box style={{flex:1}}>								
										<Box style={{fontFamily:"Roboto",fontWeight:"bold",fontSize:"16px",padding:"2px",color:"#003CFA",textAlign:"right"}}>
											{servicePrice}
										</Box>								
								</Box>								
							
							</Box>							
						
							<Divider light />

							{/* Time Box */}
							<Box mt={1} ml={1} mr={1} mb={1} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>
							
								<Box style={{flex:1}}>
								
									<Box style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>    
										<div style={{marginRight:"5px"}}> 
											<img src={calendarIconBlue} style={{width:"30px",height:"30px"}} />
										</div>
										
										<div> 
											<div style={{fontFamily:"Roboto",fontWeight:"regular",fontSize:"12px",color:"#636466",textTransform:"uppercase"}}>DIA E HORÁRIO</div>
										</div>
									</Box>									
								
								</Box>
								
								<Box style={{flex:1.5}}>								
										<Box style={{fontFamily:"Roboto",fontWeight:"bold",fontSize:"16px",padding:"2px",color:"#003CFA",textAlign:"right"}}>
											{serviceScheduledDateNoWeek} <small>{serviceScheduledDateWeek}</small> <br />
											<span style={{fontWeight:"normal"}}>{serviceScheduledPeriod}</span>
										</Box>								
								</Box>								
							
							</Box>
							
							<Divider light />

							{/* Address Box */}
							<Box mt={1} ml={1} mr={1} mb={1} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>
							
								<Box style={{flex:1}}>
								
									<Box style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>    
										<div style={{marginRight:"5px"}}> 
											<img src={placeIconBlue} style={{width:"30px",height:"30px"}} />
										</div>
										
										<div> 
											<div style={{fontFamily:"Roboto",fontWeight:"regular",fontSize:"12px",color:"#636466",textTransform:"uppercase"}}>ENDEREÇO</div>
										</div>
									</Box>									
								
								</Box>
								
								<Box style={{flex:1.5}}>								
										<Box style={{fontFamily:"Roboto",fontWeight:"bold",fontSize:"16px",padding:"2px",color:"#003CFA",textAlign:"right"}}>
											{serviceAddress}
										</Box>								
								</Box>								
							
							</Box>
							
							<Divider light />
							

							{/* Payment Box */}
							<Box mt={1} ml={1} mr={1} mb={1} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>
							
								<Box style={{flex:1}}>
								
									<Box style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>    
										<div style={{marginRight:"5px"}}> 
											<img src={cardIconBlue} style={{width:"30px",height:"30px"}} />
										</div>
										
										<div> 
											<div style={{fontFamily:"Roboto",fontWeight:"regular",fontSize:"12px",color:"#636466",textTransform:"uppercase"}}>PAGAMENTO</div>
										</div>
									</Box>									
								
								</Box>
								
								<Box style={{flex:1.5}}>								
										<Box style={{fontFamily:"Roboto",fontWeight:"bold",fontSize:"16px",padding:"2px",color:"#003CFA",textAlign:"right",display:"flex",alignItems:"flex-end",flexDirection:"column",justifyContent:"center"}}>
											{servicePaymentMethod}<br />										
											{servicePaymentReferenceNumber}
											<img src={cardFlag} style={{marginLeft:"10px",marginRight:"0px",width:"30px"}} />
										</Box>								
								</Box>								
							
							</Box>
							
							<Divider light />	

							{/* Invoice Box */}
							<Box mt={1} ml={1} mr={1} mb={1} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>
							
								<Box style={{flex:1}}>
								
									<Box style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>    
										<div style={{marginRight:"5px"}}> 
											<img src={personIconBlue} style={{width:"30px",height:"30px"}} />
										</div>
										
										<div> 
											<div style={{fontFamily:"Roboto",fontWeight:"regular",fontSize:"12px",color:"#636466",textTransform:"uppercase"}}>FATURAMENTO</div>
										</div>
									</Box>									
								
								</Box>
								
								<Box style={{flex:1.5}}>								
										<Box style={{fontFamily:"Roboto",fontWeight:"bold",fontSize:"16px",padding:"2px",color:"#003CFA",textAlign:"right",display:"flex",alignItems:"flex-end",flexDirection:"column",justifyContent:"center"}}>
											{billingName}<br />
											<span style={{fontWeight:"normal"}}>{billingDocument}</span>
																						
										</Box>								
								</Box>								
							
							</Box>
							
							<Divider light />							
									
							{/* Category Block */}
							<Box mt={1} mb={0} style={{paddingLeft:"0px",paddingRight:"0px"}} >
							
							{metaInfo.length > 0 &&
								<div style={{color:"#003CFA",fontFamily:"Roboto",fontSize:"18px",fontWeight:"bold",margin:"10px",textAlign:"left"}}> 
									Informações Extras
								</div>
							}
								
									{metaInfo.map( (item) => {
									
									if (item.fieldId == 2) {
										var itemValue = JSON.parse(item.fieldValue); 
									} // end if
									else {
										var itemValue = item.fieldValue;
									} // end else
									
									return (
										<Box ml={2} mr={2} mb={2} style={{alignItems:"stretch",justifyContent:"flex-start",display:"flex",flexDirection:"column"}} key={item.id}>
											
											<Box style={{flex:1}}>								
													<Box style={{fontSize:"0.9rem",border:"1px solid gray",backgroundColor:"#FFF",padding:"10px",borderRadius:"5px",textAlign:"left"}}>
														
														{Array.isArray(itemValue) &&
														<Box style={{marginTop:"0px", display: 'flex',flexWrap: 'wrap', justifyContent: 'flex-start',overflow: 'hidden',}}>													
														<ImageList style={{flex:1,display:"flex",padding:"0",flexWrap:"nowrap",listStyle:"none",overflowY:"auto"}} cols={2.5}>									
															{itemValue.map((item, index) => {	
														
																var extraImage = config.API_URL+"/service/extrainfoimage/"+SelectedOngoingServiceOrder.CustomerUserId+"/"+item.filename+"";
																
																return (
																	<ImageListItem key={item.id} style={{padding:"15px"}}>
																	<img src={extraImage} onClick={()=> zoomImage(extraImage) } />
																	<ImageListItemBar
																	  style={{background:"transparent",marginLeft: "10px",flex: 1}}
																	  actionIcon={
																		<IconButton>
																		  <ZoomInIcon style={{color:"#FFF",marginLeft: "10px",flex: 1}}  />
																		</IconButton>
																	  }
																	/>
																  </ImageListItem>																
																)
																
																} 
																		
															)}	
														 </ImageList>														
														</Box>	
														}
														
														{!Array.isArray(itemValue) &&
															<div style={{marginBottom:"0px",textAlign:"left",color:"#636466",fontFamily:"Roboto",fontWeight:"regular",fontSize:"16px"}}> {itemValue} </div>
														}														
														
													</Box>								
											</Box>								
										
										</Box>
									)
									
									})
									
									
									}								
								
							</Box>								
							
							<Box mt={1} mb={21}>	
											
								{(serviceStatus == 1 || serviceStatus == 2) &&
								<>
								<Box style={{...backgroundStyle,width:"100%",position:"relative",bottom:"0px"}} p={1}>								
									
									<Button color="primary" variant="outlined" style={{width:"100%",height:"53px",borderRadius:"10px",marginBottom:"20px"}} onClick={()=>setDialog('cancelservice',true)}>
									 CANCELAR SERVIÇO
									</Button>		
										
								</Box>
								</>
								}
								
								{serviceStatus == 3 &&
								<>
								<Box style={{...backgroundStyle,width:"100%",position:"relative",bottom:"0px"}} p={1}>		
									<Button color="primary" variant="outlined" style={{width:"100%",height:"53px",borderRadius:"10px",marginBottom:"20px"}}>
									 PROBLEMAS COM O SERVIÇO?
									</Button>		
										
								</Box>
								</>
								}
								
							</Box>								
											
					</Box>
					
					<Box style={{backgroundColor:"transparent",display:"flex",width:"100%",alignItems:"center",position:"fixed",bottom:0,height:"80px",backgroundColor:"#ffffff",boxShadow: "0px -10px 15px 0px #0000001A"}}>		
							
						<Box style={{flex:1,paddingRight:"50px",paddingLeft:"50px"}}>	
						
						
							{(serviceStatus == 1 || serviceStatus == 2) &&
							<>							
								<Button color="primary" variant="contained" style={{width:"100%",height:"53px"}} onClick={(target,status) => setDialog('providerLocationDetail',true)}> LOCALIZAR PROFISSIONAL </Button>	
								</>
							}
							
							{(serviceStatus == 3) &&
							<>							
								<Button color="primary" variant="contained" style={{width:"100%",height:"53px"}} onClick={()=>navigate("/ongoingServices")}> OK </Button>		
								</>
							}							
							
				
						</Box>	

								
					</Box>						
								
				</Box>
				
				
					<AlertDialog 
						dialogAlert={dialogAlert}
						setAlertDialog={setAlertDialog}
						dialogTitle={dialogTitle}
						dialogMessage={dialogMessage}
						dialogAction={dialogAction}
						dialogActionUrl={dialogActionUrl}
						history={navigate}
					/>		

					<CancelDialog 
						dialogAlert={dialogAlert}
						setAlertDialog={setAlertDialog}
						dialogTitle={dialogTitle}
						dialogMessage={dialogMessage}
						setDialogTitle={setDialogTitle}
						setDialogMessage={setDialogMessage}
						setDialogAction={setDialogAction}
						setDialogActionUrl={setDialogActionUrl}
						ServiceOrderId={ServiceOrderId}
						history={navigate}
					/>
					
					<FinishDialog 
						dialogAlert={dialogAlert}
						setAlertDialog={setAlertDialog}
						dialogTitle={dialogTitle}
						dialogMessage={dialogMessage}
						setDialogTitle={setDialogTitle}
						setDialogMessage={setDialogMessage}
						setDialogAction={setDialogAction}
						setDialogActionUrl={setDialogActionUrl}
						ServiceOrderId={ServiceOrderId}
						history={navigate}
					/>

					<ConfirmDialog 
						dialog={dialog}
						setDialog={setDialog}
						dialogTitle={dialogTitle}
						dialogMessage={dialogMessage}
						setDialogTitle={setDialogTitle}
						setDialogMessage={setDialogMessage}
					/>

					<ServiceDetailDialog 
						SelectedOngoingServiceOrder={SelectedOngoingServiceOrder}
					/>
					
					<ServiceInfoDialog
						SelectedOngoingServiceOrder={SelectedOngoingServiceOrder}
					/>					
					
					<PaymentDetailDialog 
						SelectedOngoingServiceOrder={SelectedOngoingServiceOrder}
					/>			
					
					<ExtraDetailDialog 
						SelectedOngoingServiceOrder={SelectedOngoingServiceOrder}
						setImage={setImage}
					/>		
					
					<AddressDetailDialog 
						SelectedOngoingServiceOrder={SelectedOngoingServiceOrder}
					/>		
					
					<ProviderLocationDialog 
						SelectedOngoingServiceOrder={SelectedOngoingServiceOrder}
						arriveEstimation={arriveEstimation}
						currentLocation={currentLocation}
						retrieveCurrentLocation={retrieveCurrentLocation}
					/>		

					<ImageDetailDialog image={image} />				
				</div>
			</Fade>    
			
				
	</Fragment>
)};

const mapStateToProps = store => ({
  dialog:store.appReducer.dialog,
  CustomerCancelTax:store.appReducer.CustomerCancelTax,
  buttonLoading:store.serviceReducer.buttonLoading,  
  SelectedOngoingServiceOrder:store.serviceReducer.SelectedOngoingServiceOrder
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AppActions, ...ServiceActions }, dispatch);

// Transition Slide for Dialog SLIDE UP	
const TransitionSlideUp = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;  
});	

/* Cancel Dialog Component START */
export const CancelDialog = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {
	


const screenStyleDialog = {
  alignItems: "stretch",
  justifyContent: "top",
  width:"100vw",
  minHeight:"100vh",
  display:"flex",
  flexDirection:"column",
  backgroundColor:"#FFF"
}; 
	
// set classes var
// const classes = useStyles()();	

// set vars from props
// Remember to add those who come from Redux
const { 
	innerLoading,
	setInnerLoading,
	setDialog,
	dialog,
	cancelOngoingService,
	SelectedOngoingServiceOrder,
	ServiceOrderId,
	CustomerCancelTax
} = props;	


/* Dialog END*/	

const [acceptTerms, setAcceptTerms] = useState(false); // state for tab
const [acceptCharge, handleAcceptCharge] = useState(false); // state for tab
const [cancelReason, setCancelReason] = useState("-");
const [cancelTax, setCancelTax] = useState(false);

const [loadingCancel, setLoadingCancel] = useState(false);

// Set functions
const handleAcceptTerms = (event) => {
	const newValue = event.target.checked
	setAcceptTerms(newValue);	
};	

const confirmCancel = (props) => {

	// check of terms of urgency was accepted
	if (!cancelReason || cancelReason == '-') {			
		props.setDialogMessage("Você precisa escolher o motivo do cancelamento.");
		props.setAlertDialog(true);
		return;			
	} // end if	
	
	// check of terms of urgency was accepted
	if (!acceptCharge) {			
		props.setDialogMessage("Você precisa estar de acordo com o cancelamento para prosseguir.");
		props.setAlertDialog(true);
		return;			
	} // end if		

	// Set Loading
	setLoadingCancel(true);

	// Set Service Schedule
	let data = {
		ServiceOrderId:props.ServiceOrderId,
		cancelReason:cancelReason,
		cancelTax:cancelTax
	}
	var propsParam = {
		history:props.history,
		setAlert:props.setAlertDialog,
		setDialogMessage:props.setDialogMessage,
		setDialogTitle:props.setDialogTitle,
		setLoadingCancel:setLoadingCancel,
		setDialogAction:props.setDialogAction,
		setDialog:setDialog
	}	
	// Cancel method
	cancelOngoingService(data,propsParam);

};	

var formatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});


// useEffect only for dialgo changes
useEffect(() => {
		
	// execute only if this modal
	if (dialog.target == 'cancelservice' && dialog.status) {
		
		var today = moment().format("YYYY-MM-DD HH:mm:ss");
		var morningDate = moment(SelectedOngoingServiceOrder.scheduledDate).format("YYYY-MM-DD 09:00:00");
		var eveningDate = moment(SelectedOngoingServiceOrder.scheduledDate).format("YYYY-MM-DD 13:00:00");
		var nightDate = moment(SelectedOngoingServiceOrder.scheduledDate).format("YYYY-MM-DD 18:00:00");
		
		var startDate = moment(today);
		var endDate = moment(morningDate);
		
		if (SelectedOngoingServiceOrder.scheduledPeriod == 1 && SelectedOngoingServiceOrder.type != 3) {
			var endDate = moment(morningDate);	
		} // end if
		else if (SelectedOngoingServiceOrder.scheduledPeriod == 2 && SelectedOngoingServiceOrder.type != 3) {
			var endDate = moment(eveningDate);	
		} // end if
		else if (SelectedOngoingServiceOrder.scheduledPeriod == 3 && SelectedOngoingServiceOrder.type != 3) {
			var endDate = moment(nightDate);
		} // end if
		
		var duration = moment.duration(endDate.diff(startDate));
		var minutes = parseInt(duration.asMinutes());
		
		// Set cancel Tax on
		if (minutes <= 90) {
			setCancelTax(true);
		} // end if
		
	
	}  // end if

}, [dialog]); // end useEffect only for dialgo changes

	return (
	<div>
		<LocalizationProvider utils={DateFnsUtils} locale={brLocale}>
		  <Dialog fullScreen open={dialog.target == 'cancelservice' && dialog.status} onClose={()=>setDialog('cancelservice',false)} TransitionComponent={TransitionSlideUp}>
			<AppBar style={{position: 'fixed'}}>
			  <Toolbar>
				<IconButton edge="start" color="inherit" onClick={() => setDialog('cancelservice',false)} aria-label="close">
				  <CloseIcon />
				</IconButton>
				<Typography variant="h6" style={{marginLeft: "10px",flex: 1}}>
				  Cancelando Serviço agendado
				</Typography>
			 </Toolbar>
			</AppBar>

			<Box style={{...screenStyleDialog}} m={0}>
			
				<Box mt={7} ml={0} mr={0}>
					
					<Box style={{marginBottom:"5px",alignItems:"stretch",justifyContent:"flex-start",display:"flex",flexDirection:"column"}} p={2}>

								<h5 style={{fontSize:"1rem",color:"#A255A0",marginTop:"10px",textAlign:"center"}}>Cancelamento de serviço agendado</h5>
								
								<Box mt={0}  mb={1} ml={2} mr={2} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>  
										<div style={{marginRight:"10px"}}> 
											<WarningIcon style={{fontSize:"3rem",color:"red"}} />
										</div>
										
										<div> 
											<div style={{marginBottom:"10px",textAlign:"left"}}> Atenção, o cancelamento do serviço agendado é um processo irreversível. Você precisa mesmo cancelar?  </div>
										</div>
								</Box>									
							
					</Box>
					
					<Divider style={{marginBottom:"5px"}} />
					
					{/* URGENT Date */}						
					<Fade in={true} timeout={{ enter: 1000, exit: 0 }} mountOnEnter unmountOnExit>			
							
						<div>

							<Box mt={2}  mb={0} ml={2} mr={2} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>  
									<div> 
										<div style={{marginBottom:"10px",textAlign:"center",fontWeight:"bold"}}> Selecione o motivo do cancelamento </div>
									</div>
							</Box>	
							
							<Box mt={0}  mb={2} ml={2} mr={2} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>  
									<div style={{marginRight:"10px",width:"90%"}}> 

											<Select
											  label="Motivo do cancelamento"
											  placeholder="Motivo do cancelamento"
											  style={{ width:"100%",paddingTop:0,paddingBottom:0,margin:5,marginLeft:10,marginRight:0,backgroundColor:"#fff",width:"100%"}}
											  size="small"
											  variant="outlined"
											  value={cancelReason}
											  onChange={(event) => {
												  setCancelReason(event.target.value)
											  }}	
											 >
												<MenuItem value="-">- Selecione um motivo -</MenuItem>
												<MenuItem value="Tive um imprevisto">Tive um imprevisto</MenuItem>
												<MenuItem value="Profissional não chegou no horário">Profissional não chegou no horário</MenuItem>
												<MenuItem value="Não gostei do profissional">Não gostei do profissional</MenuItem>
												<MenuItem value="Profissional não interagiu. Sumiu.">Profissional não interagiu. Sumiu.</MenuItem>
												<MenuItem value="Outro motivo não listado">Outro motivo não listado</MenuItem>
											</Select>
											
									</div>
	
							</Box>								
							
							{cancelTax &&
							<>
							<Divider style={{marginBottom:"5px"}} />							

							<Box mt={2} mb={2} ml={2} mr={2} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>  
									<div style={{marginRight:"10px"}}> 
										<AttachMoneyIcon style={{fontSize:"3rem",color:"green"}} />
									</div>
									
									<div> 
										<div style={{fontSize:"0.85rem",color:"#555555",textAlign:"left"}}>
										<strong> Taxa de cancelamento </strong><br />
										Será cobrada uma taxa de {formatter.format(CustomerCancelTax)} referente a taxa de cancelamento após tempo limite (1h e 30min antes do serviço). 										
										</div>
									</div>
							</Box>
							</>
							}
							
							<Divider style={{marginBottom:"5px"}} />							
							
							<Box mt={2} mb={2} ml={1} mr={2} style={{alignItems:"stretch",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>  

								<div style={{alignItems:"center",justifyContent:"center",display:"flex",flexDirection:"row"}}>
								
									  <div>
										  <Checkbox												
											checked={acceptCharge}
											onChange={(status)=>handleAcceptCharge(!acceptCharge)}
											inputProps={{ 'aria-label': 'primary checkbox' }}
										  />
									  </div>
									  
									   <span>
										Estou de acordo com o cancelamento do serviço
									  </span>
								
								</div>	 
								
							</Box>							
							

						</div>
						
					</Fade>					
			
					<Divider style={{marginBottom:"5px"}} />
						
					<Box style={{display:"flex",flexDirection:"row",justifyContent:"center"}} ml={1} mr={1} mb={5} mt={2}>						
						
						<Button disabled={loadingCancel} color="secondary" variant="outlined" style={{flex:1,height:"48px",marginRight:"10px"}} onClick={()=>setDialog('cancelservice',false)}>
						 Fechar
						</Button>		
						
						<Button disabled={loadingCancel} color="primary" variant="contained" style={{flex:2,height:"48px",color:"#FFF"}} onClick={()=>confirmCancel(props)}>
							{ !loadingCancel &&
							   <div>Confirmar cancelamento</div>
							}
							
							{ loadingCancel &&
							<>
							   <CircularProgress size={30} color="inherit" />
							   <span style={{marginLeft:"10px"}}>Cancelando...</span>
							</>
							}
						</Button>
							
					</Box>			
			
				</Box>				
			
			</Box>

		  </Dialog>
		 </LocalizationProvider>
	 
		 
		</div>		
	);
	
});


/* Cancel Dialog Component START */
export const FinishDialog = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {
	


const screenStyleDialog = {
  alignItems: "stretch",
  justifyContent: "top",
  width:"100vw",
  minHeight:"100vh",
  display:"flex",
  flexDirection:"column",
  backgroundColor:"#FFF"
}; 
	
// set classes var
// const classes = useStyles()();	

// set vars from props
// Remember to add those who come from Redux
const { 
	innerLoading,
	setInnerLoading,
	setDialog,
	dialog,
	finishOngoingService,
	SelectedOngoingServiceOrder,
	ServiceOrderId
} = props;	


/* Dialog END*/	

const [acceptTerms, setAcceptTerms] = useState(false); // state for tab
const [acceptCharge, handleAcceptCharge] = useState(false); // state for tab
const [acceptProblem, handleAcceptProblem] = useState(false); // state for tab
const [finishReason, setFinishReason] = useState("-");

const [loadingFinish, setLoadingFinish] = useState(false);

const confirmCancel = (props) => {

	// check of terms of urgency was accepted
	if (!finishReason || finishReason == '-') {			
		props.setDialogMessage("Você precisa escolher o motivo do encerramento do serviço.");
		props.setAlertDialog(true);
		return;			
	} // end if	
	
	// check of terms of urgency was accepted
	if (!acceptCharge) {			
		props.setDialogMessage("Você precisa estar de acordo com o encerramento para prosseguir.");
		props.setAlertDialog(true);
		return;			
	} // end if		

	// Set Loading
	setLoadingFinish(true);

	// Set Service Schedule
	let data = {
		ServiceOrderId:props.ServiceOrderId,
		finishReason:finishReason,
		acceptProblem:acceptProblem
	}
	var propsParam = {
		history:props.history,
		setAlert:props.setAlertDialog,
		setDialogMessage:props.setDialogMessage,
		setDialogTitle:props.setDialogTitle,
		setLoadingFinish:setLoadingFinish,
		setDialogAction:props.setDialogAction,
		setDialog:setDialog
	}	
	// Cancel method
	finishOngoingService(data,propsParam);

};	

var formatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});


// useEffect only for dialgo changes
useEffect(() => {
		
	// execute only if this modal
	if (dialog.target == 'finishservice' && dialog.status) {

	
	}  // end if

}, [dialog]); // end useEffect only for dialgo changes

	return (
	<div>
		<LocalizationProvider utils={DateFnsUtils} locale={brLocale}>
		  <Dialog fullScreen open={dialog.target == 'finishservice' && dialog.status} onClose={()=>setDialog('finishservice',false)} TransitionComponent={TransitionSlideUp}>
			<AppBar style={{position: 'fixed'}}>
			  <Toolbar>
				<IconButton edge="start" color="inherit" onClick={() => setDialog('finishservice',false)} aria-label="close">
				  <CloseIcon />
				</IconButton>
				<Typography variant="h6" style={{marginLeft: "10px",flex: 1}}>
				  Encerrando Serviço
				</Typography>
			 </Toolbar>
			</AppBar>

			<Box style={{...screenStyleDialog}} m={0}>
			
				<Box mt={7} ml={0} mr={0}>
					
					<Box style={{marginBottom:"5px",alignItems:"stretch",justifyContent:"flex-start",display:"flex",flexDirection:"column"}} p={2}>

								<h5 style={{fontSize:"1rem",color:"#A255A0",marginTop:"10px",textAlign:"center"}}>Encerramento de serviço em andamento</h5>
								
								<Box mt={0}  mb={1} ml={2} mr={2} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>  
										<div style={{marginRight:"10px"}}> 
											<WarningIcon style={{fontSize:"3rem",color:"red"}} />
										</div>
										
										<div> 
											<div style={{marginBottom:"10px",textAlign:"left"}}> <strong>Atenção! o encerramento do serviço deve ser feito pelo prestador.</strong> Se tudo correu bem, peça que ele encerre. O encerramento por parte do cliente é possível em alguns casos. Você precisa mesmo encerrará O processo é irreversível. </div>
										</div>
								</Box>									
							
					</Box>
					
					<Divider style={{marginBottom:"5px"}} />
								
					<Fade in={true} timeout={{ enter: 1000, exit: 0 }} mountOnEnter unmountOnExit>			
							
						<div>

							<Box mt={2}  mb={0} ml={2} mr={2} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>  
									<div> 
										<div style={{marginBottom:"10px",textAlign:"center",fontWeight:"bold"}}> Selecione o motivo do encerramento </div>
									</div>
							</Box>	
							
							<Box mt={0}  mb={2} ml={2} mr={2} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>  
									<div style={{marginRight:"10px",width:"90%"}}> 

											<Select
											  label="Motivo do encerramento"
											  placeholder="Motivo do encerramento"
											  style={{ width:"100%",paddingTop:0,paddingBottom:0,margin:5,marginLeft:10,marginRight:0,backgroundColor:"#fff",width:"100%"}}
											  size="small"
											  variant="outlined"
											  value={finishReason}
											  onChange={(event) => {
												  setFinishReason(event.target.value)
											  }}	
											 >
												<MenuItem value="-">- Selecione um motivo -</MenuItem>
												<MenuItem value="Profissional não conseguiu encerrar.">Profissional não conseguiu encerrar.</MenuItem>
												<MenuItem value="Profissional iniciou o serviço sem estar no local.">Profissional iniciou o serviço sem estar no local.</MenuItem>
												<MenuItem value="Tive problemas no serviço.">Tive problemas no serviço.</MenuItem>
												<MenuItem value="Outro motivo não listado">Outro motivo não listado</MenuItem>
											</Select>
											
									</div>
	
							</Box>								
								
							<Divider style={{marginBottom:"5px"}} />							
							
							<Box mt={2} mb={2} ml={1} mr={2} style={{alignItems:"stretch",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>  

								<div style={{alignItems:"center",justifyContent:"center",display:"flex",flexDirection:"row"}}>
								
									  <div>
										  <Checkbox												
											checked={acceptProblem}
											onChange={(status)=>handleAcceptProblem(!acceptProblem)}
											inputProps={{ 'aria-label': 'primary checkbox' }}
										  />
									  </div>
									  
									   <span>
										<strong>Marque se teve problemas no serviço</strong> e quer abrir uma disputa. O pagamento do serviço será retido até análise. 
									  </span>
								
								</div>	 
								
							</Box>	

							<Divider style={{marginBottom:"5px"}} />							
							
							<Box mt={2} mb={2} ml={1} mr={2} style={{alignItems:"stretch",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>  

								<div style={{alignItems:"center",justifyContent:"center",display:"flex",flexDirection:"row"}}>
								
									  <div>
										  <Checkbox												
											checked={acceptCharge}
											onChange={(status)=>handleAcceptCharge(!acceptCharge)}
											inputProps={{ 'aria-label': 'primary checkbox' }}
										  />
									  </div>
									  
									   <span>
										Estou de acordo com o encerramento do serviço
									  </span>
								
								</div>	 
								
							</Box>								
							

						</div>
						
					</Fade>					
			
					<Divider style={{marginBottom:"5px"}} />
						
					<Box style={{display:"flex",flexDirection:"row",justifyContent:"center"}} ml={1} mr={1} mb={5} mt={2}>						
						
						<Button disabled={loadingFinish} color="secondary" variant="outlined" style={{flex:1,height:"48px",marginRight:"10px"}} onClick={()=>setDialog('finishservice',false)}>
						 Fechar
						</Button>		
						
						<Button disabled={loadingFinish} color="primary" variant="contained" style={{flex:3,height:"48px",color:"#FFF"}} onClick={()=>confirmCancel(props)}>
							{ !loadingFinish &&
							   <div>Confirmar encerramento</div>
							}
							
							{ loadingFinish &&
							<>
							   <CircularProgress size={30} color="inherit" />
							   <span style={{marginLeft:"10px"}}>Encerrando...</span>
							</>
							}
						</Button>
							
					</Box>			
			
				</Box>				
			
			</Box>

		  </Dialog>
		 </LocalizationProvider>
	 
		 
		</div>		
	);
	
});



const ConfirmDialog = (props) => {
	
	return (
		 <Dialog
			open={props.dialog.target == 'updateServicePrompt' && props.dialog.status}
			onClose={ ()=>props.setDialog('updateServicePrompt',false) } 
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		  >
		  <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
			<DialogContent>
			  <DialogContentText id="alert-dialog-description">
			  {props.dialogMessage}
			  </DialogContentText>
			</DialogContent>
			<DialogActions>

			  <Button onClick={ ()=>props.setDialog('updateServicePrompt',false) } color="primary" autoFocus>
				ALTERAR DATA
			  </Button>			

			  <Button onClick={ ()=> {props.setDialog('updateServicePrompt',false);props.setDialog('cancelservice',true); } } color="primary" autoFocus>
				CANCELAR SERVIÇO
			  </Button>
				
			</DialogActions>			
		  </Dialog>
	  )
		
} // end AlertDialog


const AlertDialog = (props) => {
	
	return (
		 <Dialog
			open={props.dialogAlert}
			onClose={!props.dialogAction ? () => { props.setAlertDialog(false) } : () => { props.setAlertDialog(false);props.navigate(props.dialogActionUrl) }  }
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		  >
		  <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
			<DialogContent>
			  <DialogContentText id="alert-dialog-description">
			  {props.dialogMessage}
			  </DialogContentText>
			</DialogContent>
			<DialogActions>
			
			{props.dialogAction &&
			  <Button onClick={() => {props.setAlertDialog(false);props.navigate(props.dialogActionUrl)} } color="primary" autoFocus>
				OK
			  </Button>
			}

			{!props.dialogAction &&
			  <Button onClick={() => props.setAlertDialog(false) } color="primary" autoFocus>
				OK
			  </Button>
			}			  
			  
			</DialogActions>			
		  </Dialog>
	  )
	
	
} // end AlertDialog


export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(OngoingServicesView); 

