import './MainExecutedServices.css';
import React,{useState,useEffect,Fragment} from 'react';
import {Link,useNavigate,useParams} from 'react-router-dom';
import { CSSTransition } from "react-transition-group";
import * as config from '../../Config/api.js';

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

import moment from 'moment';
import 'moment/locale/pt-br';
import add from 'date-fns/add'
import format from 'date-fns/format'
import { DatePicker } from '@mui/x-date-pickers'

import ReactStars from 'react-stars'
import AnimateHeight from 'react-animate-height';

import { Box,Backdrop,CircularProgress,Paper,Divider,Typography,Button,Avatar } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Fade from '@mui/material/Fade';
import Slide from '@mui/material/Slide';
import Badge from '@mui/material/Badge';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';


import Select from '@mui/material/Select';
import NativeSelect from '@mui/material/NativeSelect';
import MenuItem from '@mui/material/MenuItem';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import brLocale from "date-fns/locale/pt-BR";
import DateFnsUtils from '@date-io/date-fns'; // choose your lib

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import CloseIcon from '@mui/icons-material/Close';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Info from '@mui/icons-material/ErrorOutline';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AccountTreeIcon from '@mui/icons-material/AccountTree'; 
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ScheduleIcon from '@mui/icons-material/Schedule';
import WarningIcon from '@mui/icons-material/Warning';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import EventIcon from '@mui/icons-material/Event';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ChatIcon from '@mui/icons-material/Chat';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import HelpIcon from '@mui/icons-material/Help';


import {useComponentWillMount} from '../../Utils/customFunctions';
import Header from '../../Components/Layout/Header';
import If from '../../Components/Common/If';
import {ServiceDetailDialog} from './DialogServiceDetail';
import {ServiceInfoDialog} from './DialogServiceInfo';
import {PaymentDetailDialog} from './DialogPaymentDetail';
import {ExtraDetailDialog} from './DialogExtraDetail';
import {AddressDetailDialog} from './DialogAddressDetail';
import {ImageDetailDialog} from './DialogImageDetail';

// import logo from '../../Assets/Images/logoTypeWOslogan.svg'; // Tell Webpack this JS file uses this image
import logo from '../../Assets/Images/logo_motuum-white.svg'; // Tell Webpack this JS file uses this image
import helpIcon from '../../Assets/Images/help-icon-blue.svg'; // Tell Webpack this JS file uses this image
import backgroundHexagon from '../../Assets/Images/backgroundHexagon.png'; // Tell Webpack this JS file uses this image
import backgroundHexagonLight from '../../Assets/Images/backgroundHexagonLight.png'; // Tell Webpack this JS file uses this image
import backgroundHeaderImage from '../../Assets/Images/backgroundHeader.png'; // Tell Webpack this JS file uses this image
import headerCategory1 from '../../Assets/Images/headerSimpleCategory1.png'; // Tell Webpack this JS file uses this image
import priceIconBlue from '../../Assets/Images/priceIconBlue.svg'; 
import scheduleIconBlue from '../../Assets/Images/scheduleIconBlue.svg'; 
import plusIconBlue from '../../Assets/Images/plusIconBlue.svg'; 
import lessIconBlue from '../../Assets/Images/lessIconBlue.svg'; 
import zoomIconBlue from '../../Assets/Images/zoomIconBlue.svg'; 
import chatIconGreen from '../../Assets/Images/iconChatGreen.svg'; 
import calendarIconBlue from '../../Assets/Images/calendarIconBlue.svg'; 
import placeIconBlue from '../../Assets/Images/placeIconBlue.svg'; 
import cardIconBlue from '../../Assets/Images/cardIconBlue.svg'; 
import personIconBlue from '../../Assets/Images/personIconBlue.svg';
import checkIconGreen from '../../Assets/Images/checkIcon-green.svg';
import infoIconOrange from '../../Assets/Images/infoIcon-orange.svg';

import visa from '../../Assets/Images/visa.svg'; // Tell Webpack this JS file uses this image
import mastercard from '../../Assets/Images/mastercard.svg'; // Tell Webpack this JS file uses this image
import amex from '../../Assets/Images/amex.svg'; // Tell Webpack this JS file uses this image
import diners from '../../Assets/Images/diners.svg'; // Tell Webpack this JS file uses this image
import elo from '../../Assets/Images/elo.svg'; // Tell Webpack this JS file uses this image
import genericFlag from '../../Assets/Images/genericFlag.svg'; // Tell Webpack this JS file uses this image

// Load Actions
import * as AppActions from '../../Store/Actions/App'
import * as ServiceActions from '../../Store/Actions/Service'

const ExecutedServicesView = props => {


const screenStyle = {
  alignItems: "stretch",
  justifyContent: "top",
  width:"100vw",
  minHeight:"100vh",
  display:"flex",
  flexDirection:"column",
  backgroundImage: "url(" + backgroundHexagon + ")",
  backgroundSize:"cover",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"center"
};

const backgroundLight = {
  backgroundImage: "url(" + backgroundHexagonLight + ")",
  backgroundSize:"cover",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"center"
};

const backgroundStyle = {
  backgroundImage: "url(" + backgroundHexagon + ")",
  backgroundSize:"cover",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"center"
}; 

const screenStyleLoading = {
  alignItems: "middle",
  justifyContent: "center",
  width:"100vw",
  height:"100vh",
  display: "flex",
  flexDirection: "column"
}; 


const screenStyleBackgroundImage = {
  backgroundSize:"auto",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"center",
  minHeight:"59px",
  width:"100%"
};

// set classes var
// const classes = useStyles()();

// set vars from props
// set vars from props
const { 
	buttonLoading,
	setButtonLoading,
	SelectedExecutedServiceOrder,
	retrieveServiceOrder,
	setDialog,
	dialog
} = props;

let { id } = useParams();
const [ServiceOrderId,setServiceOrderId] = useState(id);

// Set internal state
const [contentVisible,setContentState] = useState(false);
const [contentNotFoundVisible,setContentNotFoundVisible] = useState(false);
const [dialogAlert, setAlertDialog] = useState(false); // state for tab
const [dialogMessage, setDialogMessage] = useState(""); // state for tab
const [dialogTitle, setDialogTitle] = useState("Verifique as informações"); // state for tab
const [dialogAction, setDialogAction] = useState(false); // state for tab

const [image,setImage] = useState("");

//Set content constants
const [serviceId,setServiceId] = useState(null);
const [serviceName,setServiceName] = useState("carregando...");
const [serviceDescription,setServiceDescription] = useState("carregando...");
const [serviceScheduledDate,setServiceScheduledDate] = useState("carregando...");
const [breadcrumb,setBreadcrumb] = useState("carregando...");

const [categoryHeader,setCategoryHeader] = useState("carregando...");

const [serviceScheduledPeriod,setServiceScheduledPeriod] = useState("carregando...");
const [servicePaymentMethod,setServicePaymentMethod] = useState("carregando...");
const [serviceExecutionTime,setServiceExecutionTime] = useState("carregando...");
const [servicePrice,setServicePrice] = useState("carregando...");
const [serviceAddress,setServiceAddress] = useState("carregando...");
const [serviceBillingInfo,setServiceBillingInfo] = useState("carregando...");
const [serviceEmployeeName,setServiceEmployeeName] = useState("carregando...");
const [serviceEmployeeDocument,setServiceEmployeeDocument] = useState("carregando...");
const [serviceEmployeeDocumentAlt,setServiceEmployeeDocumentAlt] = useState("carregando...");
const [serviceEmployeeDocumentAltFlag,setServiceEmployeeDocumentAltFlag] = useState(false);
const [serviceCompanyName,setServiceCompanyName] = useState("carregando...");

const [serviceWarranty,setServiceWarranty] = useState("carregando...");
const [servicePaymentReferenceNumber,setServicePaymentReference] = useState("carregando...");

const [metaInfo,setMetaInfo] = useState([]);

const [billingName,setServiceBillingName] = useState("carregando...");
const [billingDocument,setServiceBillingDocument] = useState("carregando...");

const [serviceScheduledDateNoWeek,setServiceScheduledDateNoWeek] = useState("carregando...");
const [serviceScheduledDateWeek,setServiceScheduledDateWeek] = useState("carregando...");

const [paymentStatus,setPaymentStatus] = useState("carregando...");

const [serviceStartedAt,setServiceStartedAt] = useState("carregando...");
const [serviceFinishedAt,setServiceFinishedAt] = useState("carregando...");

const [serviceStartedAtDate,setServiceStartedAtDate] = useState("carregando...");
const [serviceStartedAtHour,setServiceStartedAtHour] = useState("carregando...");
const [serviceFinishedAtHour,setServiceFinishedAtHour] = useState("carregando...");

const [minutesFromStart,setMinutesFromStart] = useState("carregando...");
const [servicePredictedFinish,setServicePredictedFinish] = useState("carregando...");
const [serviceArrivePrediction,setServiceArrivePrediction] = useState("carregando...");

const [extraInfoflag,setExtraInfoFlag] = useState(false);
const [serviceStatus, setServiceStatus] = useState(null);
const [cancelDate, setCancelDate] = useState("");
const [ratingEnabled, setRatingEnabled] = useState(false);
const [ratingEnabledUpdate, setRatingEnabledUpdate] = useState(false);
const [generalRating, setGeneralRating] = useState(0);

const [heightPanel,setHeightPanel] = useState(0);
const [cardFlag, setCardFlag] = useState(""); // state for tab

var avatarImageUrl = config.API_URL+"/provider/avatar/retrieve/null/null";
const [serviceCompanyAvatar,setServiceCompanyAvatar] = useState(avatarImageUrl);

const [headerTitle, setHeaderTitle] = useState("Serviço em andamento"); // state for tab

const periodArray = [];
periodArray[1] = "09:00 às 13:00";
periodArray[2] = "13:00 às 18:00";
periodArray[3] = "18:00 às 21:00";

let navigate = useNavigate();


// Run after mount component
useEffect(() => {
		
	// Set Backdrop Loading over interface
	setButtonLoading(true); 

	// Retrieve Scheduled Services
	var data = {
		serviceOrderId:ServiceOrderId,
		status:[4,5,6]
	}	
	var props = {
		navigate:navigate,
		setAlert:setAlertDialog,
		setDialogMessage:setDialogMessage,
		setDialogTitle:setDialogTitle,
		setContentState:setContentState,
		setContentNotFoundVisible:setContentNotFoundVisible
	}	
	retrieveServiceOrder(data,props);
	
  // set scroll to top
  window.scrollTo(0, 1);
    
  // Runs when UNMOUNT component
  return () => {
    console.log("Desmontou o componente MainHome...");
  };
  
}, []); // end useEffect


const zoomImage = (image) => {
	
	setImage(image);
	setDialog('imageDetail',true);
	
}


// Run if object [SelectedScheduledServiceOrder] change
useEffect(() => {

try {

// Update later for Google Directions API result (minutes to arrive).
if (SelectedExecutedServiceOrder.status == 1 || SelectedExecutedServiceOrder.status == 2) {
	var arrivePrediction =  moment(SelectedExecutedServiceOrder.leavedAt).add(60,"minutes").locale('pt-br').format("HH:mm");
	var arrivePredictionRaw =  moment(SelectedExecutedServiceOrder.leavedAt).add(60,"minutes");
	var finisPredictionAfterArrive = moment(arrivePredictionRaw).add(SelectedExecutedServiceOrder.ServiceOrderService.Service.effort,"minutes").locale('pt-br').format("HH:mm");
	setServiceArrivePrediction(finisPredictionAfterArrive);
	setHeaderTitle("Profissional em trânsito");
}
else {
	setHeaderTitle("Serviço em andamento");
}

// Is status is 3, use updatedDate to get started time. 
var beganDatetime = moment(SelectedExecutedServiceOrder.startedAt).locale('pt-br').format("DD/MM/YYYY HH:mm");
setServiceStartedAt(beganDatetime);

var beganDatetimeDate = moment(SelectedExecutedServiceOrder.startedAt).locale('pt-br').format("DD/MM/YYYY");
setServiceStartedAtDate(beganDatetimeDate);

var beganDatetimeHour = moment(SelectedExecutedServiceOrder.startedAt).locale('pt-br').format("HH:mm");
setServiceStartedAtHour(beganDatetimeHour);

var finishDatetime = moment(SelectedExecutedServiceOrder.finishedAt).locale('pt-br').format("DD/MM/YYYY HH:mm");
setServiceFinishedAt(finishDatetime);

var finishDatetimeHour = moment(SelectedExecutedServiceOrder.finishedAt).locale('pt-br').format("HH:mm");
setServiceFinishedAtHour(finishDatetimeHour);

var beganDatetimeRaw = moment(SelectedExecutedServiceOrder.startedAt);
//var endTime = moment();
var endTime = moment(SelectedExecutedServiceOrder.finishedAt);

var duration = moment.duration(endTime.diff(beganDatetimeRaw));
var minutes = Math.floor(duration.asMinutes());
//setMinutesFromStart(minutes);

// Format with Hour format (hh:mm)
var minutesFormatted = moment.utc(moment.duration(minutes, "minutes").asMilliseconds()).format("HH:mm")
setMinutesFromStart(minutesFormatted);

var finishPrediction = moment(SelectedExecutedServiceOrder.startedAt).add(SelectedExecutedServiceOrder.ServiceOrderService.Service.effort,"minutes").locale('pt-br').format("HH:mm");	
setServicePredictedFinish(finishPrediction);
	
// Set service details
setServiceId(SelectedExecutedServiceOrder.ServiceOrderService.ServiceId);
setServiceName(SelectedExecutedServiceOrder.ServiceOrderService.name);
setServiceDescription(SelectedExecutedServiceOrder.ServiceOrderService.description);
setServiceWarranty(SelectedExecutedServiceOrder.ServiceOrderService.warrantyTerm);

// set payment status
setPaymentStatus(SelectedExecutedServiceOrder.ServiceOrderPayment ? SelectedExecutedServiceOrder.ServiceOrderPayment.status:1);

setBreadcrumb(SelectedExecutedServiceOrder.ServiceOrderService.Service.ServiceCategories[0].breadcrumb);
setCategoryHeader(SelectedExecutedServiceOrder.ServiceOrderService.Service.ServiceCategories[0].headerImageSize3);

// set payment reference number
setServicePaymentReference(SelectedExecutedServiceOrder.ServiceOrderCreditCard.referenceNumber);

// Set Meta Info
setMetaInfo(SelectedExecutedServiceOrder.ServiceOrderMetaInformations);

if (SelectedExecutedServiceOrder.type == 1) {
	var periodLabel = periodArray[SelectedExecutedServiceOrder.scheduledPeriod];	
} // end if
else if (SelectedExecutedServiceOrder.type == 2) {
	var periodLabel = "HOJE das "+periodArray[SelectedExecutedServiceOrder.scheduledPeriod];	
} // end if
else {
	var periodLabel = "URGENTE - em até 2 horas";	
} // end if
setServiceScheduledPeriod(periodLabel);

var formattedDate = moment(SelectedExecutedServiceOrder.scheduledDate).locale('pt-br').format("DD/MM/YYYY (dddd)");
setServiceScheduledDate(formattedDate);

var serviceScheduledDateNoWeekTmp = moment(SelectedExecutedServiceOrder.scheduledDate).locale('pt-br').format("DD/MM/YYYY");
setServiceScheduledDateNoWeek(serviceScheduledDateNoWeekTmp);

var  serviceScheduledDateWeekTmp = moment(SelectedExecutedServiceOrder.scheduledDate).locale('pt-br').format("(dddd)");
setServiceScheduledDateWeek(serviceScheduledDateWeekTmp);

var paymentMethodLabel = SelectedExecutedServiceOrder.paymentMethod == 1 ? "Cartão de Crédito": "Transferência via PIX";
setServicePaymentMethod(paymentMethodLabel);

var timeLabel = SelectedExecutedServiceOrder.ServiceOrderService.effort +" "+SelectedExecutedServiceOrder.ServiceOrderService.effortUnity;
setServiceExecutionTime(timeLabel);

var priceLabel = "R$ "+(Math.round(SelectedExecutedServiceOrder.ServiceTotalPrice * 100) / 100).toFixed(2);
setServicePrice(priceLabel);

// set status
setServiceStatus(SelectedExecutedServiceOrder.status);
setCancelDate(moment(SelectedExecutedServiceOrder.cancelledAt).format("DD/MM/YYYY HH:mm"));

// Set address and billing info
setServiceAddress(SelectedExecutedServiceOrder.ServiceOrderAddress.fullAddress);
var BillinInfoType = SelectedExecutedServiceOrder.ServiceOrderBilling.type == 1 ? "<strong>Pessoa Física</strong>": "<strong>Pessoa Jurídica</strong>";


if (SelectedExecutedServiceOrder.ServiceOrderMetaInformations.length > 0) {
	setExtraInfoFlag(true);
} // end if


var BillingInfoLabel = BillinInfoType+" <br /> "+SelectedExecutedServiceOrder.ServiceOrderBilling.billingName +" <br /> "+SelectedExecutedServiceOrder.ServiceOrderBilling.fullAddress;
setServiceBillingInfo(BillingInfoLabel);

var billingNameTmp = SelectedExecutedServiceOrder.ServiceOrderBilling.billingName;
setServiceBillingName(billingNameTmp);

var billingDocumentTmp = SelectedExecutedServiceOrder.ServiceOrderBilling.billingDocument;
setServiceBillingDocument(billingDocumentTmp);

// Set Provider Data
setServiceEmployeeName(SelectedExecutedServiceOrder.ProviderUser.ProviderProfile.ProviderProfilePerson.ProviderProfileCompanyEmployee.completeName);
setServiceEmployeeDocument(SelectedExecutedServiceOrder.ProviderUser.ProviderProfile.ProviderProfilePerson.ProviderProfileCompanyEmployee.document);

setServiceEmployeeDocumentAlt(SelectedExecutedServiceOrder.ProviderUser.ProviderProfile.ProviderProfilePerson.ProviderProfileCompanyEmployee.documentAlt+" ("+SelectedExecutedServiceOrder.ProviderUser.ProviderProfile.ProviderProfilePerson.ProviderProfileCompanyEmployee.documentAltInfo+")");
setServiceEmployeeDocumentAltFlag(SelectedExecutedServiceOrder.ProviderUser.ProviderProfile.ProviderProfilePerson.ProviderProfileCompanyEmployee.documentAlt);

setServiceCompanyName(SelectedExecutedServiceOrder.ProviderUser.ProviderProfile.ProviderProfileCompany.realName);
var avatarImageUrl = config.API_URL+"/provider/avatar/retrieve/"+SelectedExecutedServiceOrder.ProviderUser.id+"/"+SelectedExecutedServiceOrder.ProviderUser.ProviderProfile.avatar+"";

setServiceCompanyAvatar(avatarImageUrl);

// Set rating mode
if (SelectedExecutedServiceOrder.ServiceOrderProviderRating) {
	setRatingEnabled(true);
	setGeneralRating(SelectedExecutedServiceOrder.ServiceOrderProviderRating.generalRating);
} else {
	setRatingEnabled(false);		
}

// Set if can update rating
var today = moment().format("YYYY-MM-DD");
var finishDate = moment(SelectedExecutedServiceOrder.ServiceOrderProviderRating.createdAt).format("YYYY-MM-DD");
if (finishDate == today) {
	setRatingEnabledUpdate(true);
} else {
	setRatingEnabledUpdate(false);		
} // end else


// PAYMENT Info	
	if (SelectedExecutedServiceOrder.ServiceOrderCreditCard.cardFlag == "visa") {
		setCardFlag(visa);
	} // end if
	else if  (SelectedExecutedServiceOrder.ServiceOrderCreditCard.cardFlag == "mastercard") {
		setCardFlag(mastercard);
	} // end else if
	else if  (SelectedExecutedServiceOrder.ServiceOrderCreditCard.cardFlag == "amex") {
		setCardFlag(amex);
	} // end else if	
	else if  (SelectedExecutedServiceOrder.ServiceOrderCreditCard.cardFlag == "diners") {
		setCardFlag(diners);
	} // end else if	
	else if  (SelectedExecutedServiceOrder.ServiceOrderCreditCard.cardFlag == "elo") {
		setCardFlag(elo);
	} // end else if	
	else if  (SelectedExecutedServiceOrder.ServiceOrderCreditCard.cardFlag == "generic") {
		setCardFlag(genericFlag);
	} // end else if	

} catch(e) {
	
	
	console.log("CATCH ERROR: "+JSON.stringify(e.message,null,2));
	
	
}



	  
}, [SelectedExecutedServiceOrder]); // end useEffect

// useEffect only for history changes
useEffect(() => {	
	setExtraInfoFlag(false);
}, [history]); // end useEffect only for dialgo changes

// Will Mount Component HOOK
useComponentWillMount(() => {
	
    // Set session of app
  var sessionCheck = sessionStorage.getItem('@motuum/appLaunched');
  
  // if session is not created, redirect to bootstrap. (avoid direct access using url)
  if (!sessionCheck) {
	  navigate("/");
  } // end if	
  
	// Retrieve User Data Obj. 
	var result = localStorage.getItem("@motuum/userData", undefined,true);
	var userData = JSON.parse(result);  
  
	  // If token dont exist, redirect to onboarding or register
	  if (!userData) {	  
		navigate("/");  
	  } // end if
	  // If token exist, redirect to HOME
	  else {
		  if (!userData.userToken) {
			navigate("/");
		  } // end if
	  } // end else    
      
}, []); // end useComponentWillMount


	return (
	<Fragment>
			
			<Header title="Serviço realizado" buttons={{support:false,menu:false,close:false,back:true,rightFake:true}}  />
			
			{/* Loading */}
			{ buttonLoading &&
					<Box className="loadingContent" style={{...screenStyleLoading}}>
							<Box mt={0} mx={0}>								
								<CircularProgress /> 
								<div> Carregando...Aguarde!</div>								
							</Box>	
					</Box>
			}			
			
			<Fade in={contentVisible} timeout={1000}  mountOnEnter unmountOnExit>			
			<div>

				<Box className="mainSearchServicesView" style={{...screenStyle}} mt={0}>
							
					{/* Category Block */}
					<Box mt={10} mb={0} style={{paddingLeft:"10px",paddingRight:"10px"}} >
					
						<div style={{color:"#636466",fontFamily:"Roboto",fontSize:"16px",fontWeight:"normal",margin:"20px",textAlign:"left"}} onClick={()=>{ navigate("/searchService") } }> 
							{breadcrumb} 
						</div>
						
					</Box>	

					<Box style={{paddingLeft:"30px",paddingRight:"30px",marginBottom:"0px"}}> 
					
						<Card style={{minWidth: "90%",marginBottom:"20px",height:"60px",width:"100%",marginBottom:"10px",backgroundColor:"#ffffff",boxShadow: "0px 10px 20px 0px #0000002A",borderRadius:"10px",position:"relative"}}>
									
							<CardContent>
							
								<Box style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"flex-start"}}>
								
								{paymentStatus == 1 &&
								<>
									<div style={{marginRight:"5px"}}> 
										<img src={checkIconGreen} style={{width:"30px",height:"30px"}} />
									</div>									
									
									<div style={{color:"#1EC300",fontFamily:"Roboto",fontWeight:"bold",fontSize:"16px",marginTop:"0px",marginBottom:"5px",lineHeight:"22px"}}>Pagamento Realizado</div>
								</>
								}
								
								{paymentStatus == 2 &&
								<>
									<div style={{marginRight:"5px"}}> 
										<img src={infoIconOrange} style={{width:"30px",height:"30px"}} />
									</div>									
									
									<div style={{color:"orange",fontFamily:"Roboto",fontWeight:"bold",fontSize:"16px",marginTop:"0px",marginBottom:"5px",lineHeight:"22px"}}>Pagamento Pendente</div>
								</>
								}								
									
								</Box>									
								
							 </CardContent>

						</Card>								  
							
						<Card  style={{minWidth: "90%",marginBottom:"20px",minHeight:"250px",width:"100%",marginBottom:"10px",backgroundColor:"#ffffff",boxShadow: "0px 10px 20px 0px #0000002A",borderRadius:"10px",position:"relative"}}>
									
							<CardContent style={{padding:"10px",paddingBottom:"0px"}}>
							
								<Box style={{display:"flex",flexdirection:"row",alignItems:"flex-start",justifyContent:"space-between",marginTop:"10px",marginLeft:"15px",marginRight:"15px"}}>
									
									<Box style={{float:"left",textAlign:"left"}}>	

										<h3 style={{color:"#003CFA",fontFamily:"Roboto",fontWeight:"bold",fontSize:"16px",marginTop:"0px",marginBottom:"2px",lineHeight:"18px"}}>
										
											{serviceStatus == 4 &&
												<span style={{color:"#003CFA"}}>Finalizado</span>
											}
											
											{serviceStatus == 5 &&
												<span style={{color:"orange"}}>Em análise</span>
											}

											{serviceStatus == 6 &&
												<span style={{color:"red"}}>Em disputa</span>
											}										
										
										</h3>
										
										<div style={{color:"#636466",fontFamily:"Roboto",fontWeight:"bold",fontSize:"12px",lineHeight:"18px"}}>
										{serviceName}
										</div>	
									</Box>
									
									<div style={{textAlign:"right",float:"right",maxWidth:"50%",maxHeight:"72px",overflow:"hidden"}}>
										<h3 style={{color:"#636466",fontFamily:"Roboto",fontWeight:"bold",fontSize:"12px",marginTop:"0px",marginBottom:"2px",lineHeight:"18px"}}>{serviceStartedAtDate}</h3>
										<div style={{color:"#636466",fontFamily:"Roboto",fontWeight:"normal",fontSize:"12px",lineHeight:"18px"}}>
										
											<div>
											INICIADO <strong>{serviceStartedAtHour}</strong><br />
											FINALIZADO <strong>{serviceFinishedAtHour}</strong><br />
											<div style={{color:"#003CFA"}}>TEMPO SERVIÇO <strong>{minutesFromStart}</strong></div>
											</div>														
												
										</div>
									</div>														
								
								</Box>
								
								<Divider style={{marginBottom:"15px",marginTop:"15px",marginLeft:"15px",marginRight:"15px"}} />							
								
								<Box style={{display:"flex",flexdirection:"row",alignItems:"center",justifyContent:"space-between",marginTop:"10px",marginLeft:"15px",marginRight:"15px"}}>
									
									<Box style={{float:"left",textAlign:"left"}}>										
										<h3 style={{color:"#003CFA",fontFamily:"Roboto",fontWeight:"bold",fontSize:"16px",marginTop:"0px",marginBottom:"2px",lineHeight:"18px"}}>{serviceEmployeeName}</h3>
										<div style={{color:"#636466",fontFamily:"Roboto",fontWeight:"bold",fontSize:"12px",lineHeight:"18px"}}>
										{serviceCompanyName}
										</div>	
									</Box>
									
									<div style={{textAlign:"right",float:"right"}}>
											<Avatar src={serviceCompanyAvatar} variant="square" style={{width:"50px",height:"50px",borderRadius:"50px"}} />	
									</div>														
								
								</Box>	
							
								<Box style={{textAlign:"left",padding:"0px",marginTop:"0px",color:"#636466",fontFamily:"Roboto",fontWeight:"regular",fontSize:"12px",marginLeft:"15px",marginRight:"15px"}}>
																						
										{serviceEmployeeDocumentAltFlag &&
										<>
										RG: {serviceEmployeeDocumentAlt} <br />
										</>
										}
										CPF: {serviceEmployeeDocument}
											
								</Box>		

								<Box style={{display:"flex",flexdirection:"row",alignItems:"center",justifyContent:"space-between",marginTop:"10px",marginLeft:"15px",marginRight:"15px"}}>
									
									<Box style={{float:"left",textAlign:"left"}}>	

										{ratingEnabled &&
											<>
											<Box style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center"}}>				
																								
													<div style={{marginTop:"-5px"}}>
														<ReactStars
														  count={5}
														  edit={false}
														  value={4}
														  half={true}
														  size={22}
														  color2={'#FFBA00'} />	
													</div>
												
											</Box>
											</>
											}	
											
											{!ratingEnabled &&
											<>
											<Box style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center"}}>				
																								
													<div style={{marginTop:"-5px"}}>
														<ReactStars
														  count={5}
														  edit={false}
														  value={5}
														  half={true}
														  readonly
														  size={22}
														  color2={'#D6D6D6'} />
													</div>
																									
											</Box>
											</>
											}													
										
									</Box>
									
									<Box style={{float:"right",textAlign:"right"}}>	
									
										   {ratingEnabled &&
											<>
											
												{ratingEnabledUpdate &&
												<>
												<div style={{textAlign:"right"}}>
													<div style={{color:"#003CFA",fontFamily:"Roboto",fontWeight:"regular",fontSize:"12px"}} onClick={() => setDialog('updateRatingProvider',true)}>
														ALTERAR QUALIFICAÇÃO
													</div>	
												</div>
												</>
												}

												{!ratingEnabledUpdate &&
												<>
												<div style={{textAlign:"right"}}>
													<div style={{color:"#707070",fontFamily:"Roboto",fontWeight:"regular",fontSize:"12px"}}>
														PRESTADOR QUALIFICADO
													</div>	
												</div>
												</>
												}											
																											
											</>
											}	
											
											{!ratingEnabled &&
											<>
												<div style={{textAlign:"right",float:"right",fontFamily:"Roboto",fontWeight:"regular",fontSize:"12px",color:"#E30000"}}>
													QUALIFICAÇÃO PENDENTE	
												</div>	
											</>
											}	

										</Box>											
								
								</Box>									
								
							  </CardContent>

					</Card>

					</Box>	
					
					<Box style={{paddingLeft:"30px",paddingRight:"30px",marginBottom:"10px"}}> 

						<Card style={{minWidth: "90%",marginBottom:"20px",minHeight:"154px",width:"100%",marginBottom:"10px",backgroundColor:"#ffffff",boxShadow: "0px 10px 20px 0px #0000002A",borderRadius:"10px"}}>
						
						  <CardContent style={{padding:"25px",paddingBottom:"0px"}}>
							
							<Box >
								
								<Box style={{padding:"0px"}}>
									
										<div style={{textAlign:"left"}}>
											<h3 style={{color:"#003CFA",fontFamily:"Roboto",fontWeight:"bold",fontSize:"16px",marginTop:"0px",marginBottom:"5px",lineHeight:"18px"}}>{serviceName}</h3>
											<div style={{minHeight:"54px",color:"#636466",fontFamily:"Roboto",fontWeight:"normal",fontSize:"12px",lineHeight:"18px"}}>
												{serviceDescription}
											</div>
										</div>											
									
								</Box>	
										
								<AnimateHeight
								  duration={ 500 }
								  height={ heightPanel }
								>								
									<Box style={{padding:"0px",marginTop:"15px"}}>
										
											<div style={{textAlign:"left"}}>
												<h3 style={{color:"#003CFA",fontFamily:"Roboto",fontWeight:"bold",fontSize:"16px",marginTop:"0px",marginBottom:"5px",lineHeight:"18px"}}>Garantia do Serviço</h3>
												<div style={{color:"#636466",fontFamily:"Roboto",fontWeight:"normal",fontSize:"12px",lineHeight:"18px"}}>
													{serviceWarranty}
												</div>
											</div>											
										
									</Box>									
								</AnimateHeight>
								
							
							</Box>
							
						  </CardContent>
							
							<CardActions style={{display:"flex",flexDirection:"row",backgroundColor:"#003CFA10",padding:"2px",marginTop:"19px"}}>
									  
							  <div style={{flex:1,textAlign:"center"}}>
									
									{heightPanel == 0 &&
									<Button style={{color:"#003CFA",fontFamily:"Roboto",fontWeight:"normal",fontSize:"12px"}} size="small" onClick={()=>setHeightPanel(100)}>										
										<img src={plusIconBlue} style={{width:"18px",height:"18px",marginRight:"5px"}} />
										<div>Ver mais</div>										
									</Button>	
									}									
									
									{heightPanel > 0 &&
									<Button style={{color:"#003CFA",fontFamily:"Roboto",fontWeight:"normal",fontSize:"12px"}} size="small" onClick={()=>setHeightPanel(0)}>										
										<img src={lessIconBlue} style={{width:"18px",height:"18px",marginRight:"5px"}} />
										<div>Ver menos</div>										
									</Button>
									}
									
								</div>
								
							  </CardActions>										

						</Card>	
						

					</Box>	
	
					<Divider light style={{marginLeft:"30px",marginRight:"30px"}} />

					<Box pt={0} pb={2} mt={0} mb={0} style={{...backgroundLight,alignItems:"stretch",justifyContent:"center",display:"flex",flexDirection:"column",paddingLeft:"30px",paddingRight:"30px"}}>
							
							
							{/* Price Box */}	
							<Box mt={1} ml={1} mr={1} mb={1} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>
							
								<Box style={{flex:2}}>
								
									<Box style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>    
										<div style={{marginRight:"5px"}}> 
											<img src={priceIconBlue} style={{width:"30px",height:"30px"}} />
										</div>
										
										<div> 
											<div style={{fontFamily:"Roboto",fontWeight:"regular",fontSize:"12px",color:"#636466",textTransform:"uppercase"}}>Valor do serviço</div>
										</div>
									</Box>									
								
								</Box>
								
								<Box style={{flex:1}}>								
										<Box style={{fontFamily:"Roboto",fontWeight:"bold",fontSize:"16px",padding:"2px",color:"#003CFA",textAlign:"right"}}>
											{servicePrice}
										</Box>								
								</Box>								
							
							</Box>							
						
							<Divider light />

							{/* Time Box */}
							<Box mt={1} ml={1} mr={1} mb={1} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>
							
								<Box style={{flex:1}}>
								
									<Box style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>    
										<div style={{marginRight:"5px"}}> 
											<img src={calendarIconBlue} style={{width:"30px",height:"30px"}} />
										</div>
										
										<div> 
											<div style={{fontFamily:"Roboto",fontWeight:"regular",fontSize:"12px",color:"#636466",textTransform:"uppercase"}}>DIA E HORÁRIO</div>
										</div>
									</Box>									
								
								</Box>
								
								<Box style={{flex:1.5}}>								
										<Box style={{fontFamily:"Roboto",fontWeight:"bold",fontSize:"16px",padding:"2px",color:"#003CFA",textAlign:"right"}}>
											{serviceScheduledDateNoWeek} <small>{serviceScheduledDateWeek}</small> <br />
											<span style={{fontWeight:"normal"}}>{serviceScheduledPeriod}</span>
										</Box>								
								</Box>								
							
							</Box>
							
							<Divider light />

							{/* Address Box */}
							<Box mt={1} ml={1} mr={1} mb={1} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>
							
								<Box style={{flex:1}}>
								
									<Box style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>    
										<div style={{marginRight:"5px"}}> 
											<img src={placeIconBlue} style={{width:"30px",height:"30px"}} />
										</div>
										
										<div> 
											<div style={{fontFamily:"Roboto",fontWeight:"regular",fontSize:"12px",color:"#636466",textTransform:"uppercase"}}>ENDEREÇO</div>
										</div>
									</Box>									
								
								</Box>
								
								<Box style={{flex:1.5}}>								
										<Box style={{fontFamily:"Roboto",fontWeight:"bold",fontSize:"16px",padding:"2px",color:"#003CFA",textAlign:"right"}}>
											{serviceAddress}
										</Box>								
								</Box>								
							
							</Box>
							
							<Divider light />
							

							{/* Payment Box */}
							<Box mt={1} ml={1} mr={1} mb={1} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>
							
								<Box style={{flex:1}}>
								
									<Box style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>    
										<div style={{marginRight:"5px"}}> 
											<img src={cardIconBlue} style={{width:"30px",height:"30px"}} />
										</div>
										
										<div> 
											<div style={{fontFamily:"Roboto",fontWeight:"regular",fontSize:"12px",color:"#636466",textTransform:"uppercase"}}>PAGAMENTO</div>
										</div>
									</Box>									
								
								</Box>
								
								<Box style={{flex:1.5}}>								
										<Box style={{fontFamily:"Roboto",fontWeight:"bold",fontSize:"16px",padding:"2px",color:"#003CFA",textAlign:"right",display:"flex",alignItems:"flex-end",flexDirection:"column",justifyContent:"center"}}>
											{servicePaymentMethod}<br />										
											{servicePaymentReferenceNumber}
											<img src={cardFlag} style={{marginLeft:"10px",marginRight:"0px",width:"30px"}} />
										</Box>								
								</Box>								
							
							</Box>
							
							<Divider light />	

							{/* Invoice Box */}
							<Box mt={1} ml={1} mr={1} mb={1} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>
							
								<Box style={{flex:1}}>
								
									<Box style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>    
										<div style={{marginRight:"5px"}}> 
											<img src={personIconBlue} style={{width:"30px",height:"30px"}} />
										</div>
										
										<div> 
											<div style={{fontFamily:"Roboto",fontWeight:"regular",fontSize:"12px",color:"#636466",textTransform:"uppercase"}}>FATURAMENTO</div>
										</div>
									</Box>									
								
								</Box>
								
								<Box style={{flex:1.5}}>								
										<Box style={{fontFamily:"Roboto",fontWeight:"bold",fontSize:"16px",padding:"2px",color:"#003CFA",textAlign:"right",display:"flex",alignItems:"flex-end",flexDirection:"column",justifyContent:"center"}}>
											{billingName}<br />
											<span style={{fontWeight:"normal"}}>{billingDocument}</span>
																						
										</Box>								
								</Box>								
							
							</Box>
							
							<Divider light />							
									
							{/* Category Block */}
							<Box mt={1} mb={0} style={{paddingLeft:"0px",paddingRight:"0px"}} >
							
							{metaInfo.length > 0 &&
								<div style={{color:"#003CFA",fontFamily:"Roboto",fontSize:"18px",fontWeight:"bold",margin:"10px",textAlign:"left"}}> 
									Informações Extras
								</div>
							}
								
									{metaInfo.map( (item) => {
									
									if (item.fieldId == 2) {
										var itemValue = JSON.parse(item.fieldValue); 
									} // end if
									else {
										var itemValue = item.fieldValue;
									} // end else
									
									return (
										<Box ml={2} mr={2} mb={2} style={{alignItems:"stretch",justifyContent:"flex-start",display:"flex",flexDirection:"column"}} key={item.id}>
											
											<Box style={{flex:1}}>								
													<Box style={{fontSize:"0.9rem",border:"0px solid gray",backgroundColor:"transparent",padding:"10px",borderRadius:"5px",textAlign:"left"}}>
														
														{Array.isArray(itemValue) &&
														<Box style={{marginTop:"0px", display: 'flex',flexWrap: 'wrap', justifyContent: 'flex-start',overflow: 'hidden',}}>													
														<ImageList style={{flex:1,display:"flex",padding:"0",flexWrap:"nowrap",listStyle:"none",overflowY:"auto"}} cols={2.5}>									
															{itemValue.map((item, index) => {	
														
																var extraImage = config.API_URL+"/service/extrainfoimage/"+SelectedExecutedServiceOrder.CustomerUserId+"/"+item.filename+"";
																
																return (
																	<ImageListItem key={item.id} style={{padding:"15px"}}>
																	<img src={extraImage} onClick={()=> zoomImage(extraImage) } />
																	<ImageListItemBar
																	  style={{
																		marginLeft: "10px",
																		flex: 1,
																		textAlign:"center",
																		display:"flex",
																		justifyContent:"center",
																		alignItems:"center",
																		background:"transparent"
																	  }}															
																	  actionIcon={
																		<IconButton>
																		  <ZoomInIcon style={{
																			marginLeft: "10px",
																			flex: 1,
																			textAlign:"center",
																			display:"flex",
																			justifyContent:"center",
																			alignItems:"center",
																			color:"#FFF"
																		  }} />
																		</IconButton>
																	  }
																	/>
																  </ImageListItem>																
																)
																
																} 
																		
															)}	
														 </ImageList>														
														</Box>	
														}
														
														{!Array.isArray(itemValue) &&
															<div style={{marginBottom:"0px",textAlign:"left",color:"#636466",fontFamily:"Roboto",fontWeight:"regular",fontSize:"16px"}}> {itemValue} </div>
														}														
														
													</Box>								
											</Box>								
										
										</Box>
									)
									
									})
									
									
									}								
								
							</Box>								
							
							<Box mt={1} mb={21}>	


								<Box style={{...backgroundStyle,width:"100%",position:"relative",bottom:"0px"}} p={1}>		
									<Button color="primary" variant="outlined" style={{width:"100%",height:"53px",borderRadius:"10px",marginBottom:"20px"}} onClick={() => navigate("/executedServices/complaint/"+ServiceOrderId)} >
									 PROBLEMAS COM O SERVIÇO?
									</Button>		
										
								</Box>
								
							</Box>								
											
					</Box>
					
					<Box style={{backgroundColor:"transparent",display:"flex",width:"100%",alignItems:"center",position:"fixed",bottom:0,height:"80px",backgroundColor:"#ffffff",boxShadow: "0px -10px 15px 0px #0000001A"}}>		
							
						<Box style={{flex:1,paddingRight:"50px",paddingLeft:"50px"}}>	
						
						
							{!ratingEnabled &&
							<>							
								<Button color="primary" variant="contained" style={{width:"100%",height:"53px"}} onClick={() => setDialog('addRatingProvider',true)}> QUALIFICAR PRESTADOR </Button>	
								</>
							}
							
							{ratingEnabled &&
							<>							
								<Button color="primary" variant="contained" style={{width:"100%",height:"53px"}} onClick={()=>navigate("/finishedServices")}> OK </Button>		
								</>
							}							
							
				
						</Box>	

								
					</Box>						
								
				</Box>				
				
					<AlertDialog 
						dialogAlert={dialogAlert}
						setAlertDialog={setAlertDialog}
						dialogTitle={dialogTitle}
						dialogMessage={dialogMessage}
						dialogAction={dialogAction}
						history={navigate}
					/>		

					<CancelDialog 
						dialogAlert={dialogAlert}
						setAlertDialog={setAlertDialog}
						dialogTitle={dialogTitle}
						dialogMessage={dialogMessage}
						setDialogTitle={setDialogTitle}
						setDialogMessage={setDialogMessage}
						setDialogAction={setDialogAction}
						ServiceOrderId={ServiceOrderId}
						history={navigate}
					/>
					
					<AddRatingDialog 
						dialogAlert={dialogAlert}
						setAlertDialog={setAlertDialog}
						dialogTitle={dialogTitle}
						dialogMessage={dialogMessage}
						setDialogTitle={setDialogTitle}
						setDialogMessage={setDialogMessage}
						setDialogAction={setDialogAction}
						ServiceOrderId={ServiceOrderId}
						history={navigate}
						serviceEmployeeName={serviceEmployeeName}
						serviceCompanyName={serviceCompanyName}
						avatarImageUrl={avatarImageUrl}
						setRatingEnabled={setRatingEnabled}
					/>
					
					<UpdateRatingDialog 
						dialogAlert={dialogAlert}
						setAlertDialog={setAlertDialog}
						dialogTitle={dialogTitle}
						dialogMessage={dialogMessage}
						setDialogTitle={setDialogTitle}
						setDialogMessage={setDialogMessage}
						setDialogAction={setDialogAction}
						ServiceOrderId={ServiceOrderId}
						history={navigate}
						serviceEmployeeName={serviceEmployeeName}
						serviceCompanyName={serviceCompanyName}
						avatarImageUrl={avatarImageUrl}						
					/>

					<ConfirmDialog 
						dialog={dialog}
						setDialog={setDialog}
						dialogTitle={dialogTitle}
						dialogMessage={dialogMessage}
						setDialogTitle={setDialogTitle}
						setDialogMessage={setDialogMessage}
					/>

					<ServiceDetailDialog 
						SelectedExecutedServiceOrder={SelectedExecutedServiceOrder}
					/>
					
					<ServiceInfoDialog 
						SelectedExecutedServiceOrder={SelectedExecutedServiceOrder}
					/>					
					
					<PaymentDetailDialog 
						SelectedExecutedServiceOrder={SelectedExecutedServiceOrder}
					/>			
					
					<ExtraDetailDialog 
						SelectedExecutedServiceOrder={SelectedExecutedServiceOrder}
						setImage={setImage}
					/>		
					
					<AddressDetailDialog 
						SelectedExecutedServiceOrder={SelectedExecutedServiceOrder}
					/>		

					<ImageDetailDialog image={image} />				
				</div>
			</Fade>    
			
				
	</Fragment>
)};


const mapStateToProps = store => ({
  dialog:store.appReducer.dialog,
  buttonLoading:store.serviceReducer.buttonLoading,  
  SelectedExecutedServiceOrder:store.serviceReducer.SelectedExecutedServiceOrder
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AppActions, ...ServiceActions }, dispatch);

// Transition Slide for Dialog SLIDE UP	
const TransitionSlideUp = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;  
});	

/* Cancel Dialog Component START */
export const CancelDialog = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {
	

const screenStyleDialog = {
  alignItems: "stretch",
  justifyContent: "top",
  width:"100vw",
  minHeight:"100vh",
  display:"flex",
  flexDirection:"column",
  backgroundColor:"#FFF"
}; 
	
// set classes var
// const classes = useStyles()();	

// set vars from props
// Remember to add those who come from Redux
const { 
	innerLoading,
	setInnerLoading,
	setDialog,
	dialog,
	cancelScheduledService,
	SelectedExecutedServiceOrder,
	ServiceOrderId
} = props;	


/* Dialog END*/	

const [acceptTerms, setAcceptTerms] = useState(false); // state for tab
const [acceptCharge, handleAcceptCharge] = useState(false); // state for tab
const [cancelReason, setCancelReason] = useState("-");
const [cancelTax, setCancelTax] = useState(false);

const [loadingCancel, setLoadingCancel] = useState(false);

// Set functions
const handleAcceptTerms = (event) => {
	const newValue = event.target.checked
	setAcceptTerms(newValue);	
};	

const confirmCancel = (props) => {

	// check of terms of urgency was accepted
	if (!cancelReason || cancelReason == '-') {			
		props.setDialogMessage("Você precisa escolher o motivo do cancelamento.");
		props.setAlertDialog(true);
		return;			
	} // end if	
	
	// check of terms of urgency was accepted
	if (!acceptCharge) {			
		props.setDialogMessage("Você precisa estar de acordo com o cancelamento para prosseguir.");
		props.setAlertDialog(true);
		return;			
	} // end if		

	// Set Loading
	setLoadingCancel(true);

	// Set Service Schedule
	let data = {
		ServiceOrderId:props.ServiceOrderId,
		cancelReason:cancelReason,
		cancelTax:cancelTax
	}
	var propsParam = {
		history:props.history,
		setAlert:props.setAlertDialog,
		setDialogMessage:props.setDialogMessage,
		setDialogTitle:props.setDialogTitle,
		setLoadingCancel:setLoadingCancel,
		setDialogAction:props.setDialogAction,
		setDialog:setDialog
	}	
	// Cancel method
	cancelScheduledService(data,propsParam);

};	

var formatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});


// useEffect only for dialgo changes
useEffect(() => {
		
	// execute only if this modal
	if (dialog.target == 'cancelservice' && dialog.status) {
		
		var today = moment().format("YYYY-MM-DD HH:mm:ss");
		var morningDate = moment(SelectedExecutedServiceOrder.scheduledDate).format("YYYY-MM-DD 09:00:00");
		var eveningDate = moment(SelectedExecutedServiceOrder.scheduledDate).format("YYYY-MM-DD 13:00:00");
		var nightDate = moment(SelectedExecutedServiceOrder.scheduledDate).format("YYYY-MM-DD 18:00:00");
		
		var startDate = moment(today);
		var endDate = moment(morningDate);
		
		if (SelectedExecutedServiceOrder.scheduledPeriod == 1 && SelectedExecutedServiceOrder.type != 3) {
			var endDate = moment(morningDate);	
		} // end if
		else if (SelectedExecutedServiceOrder.scheduledPeriod == 2 && SelectedExecutedServiceOrder.type != 3) {
			var endDate = moment(eveningDate);	
		} // end if
		else if (SelectedExecutedServiceOrder.scheduledPeriod == 3 && SelectedExecutedServiceOrder.type != 3) {
			var endDate = moment(nightDate);
		} // end if
		
		var duration = moment.duration(endDate.diff(startDate));
		var minutes = parseInt(duration.asMinutes());
		
		// Set cancel Tax on
		if (minutes <= 90) {
			setCancelTax(true);
		} // end if
		
	
	}  // end if

}, [dialog]); // end useEffect only for dialgo changes

	return (
	<div>
		<LocalizationProvider utils={DateFnsUtils} locale={brLocale}>
		  <Dialog fullScreen open={dialog.target == 'cancelservice' && dialog.status} onClose={()=>setDialog('cancelservice',false)} TransitionComponent={TransitionSlideUp}>
			<AppBar style={{position: 'fixed'}}>
			  <Toolbar>
				<IconButton edge="start" color="inherit" onClick={() => setDialog('cancelservice',false)} aria-label="close">
				  <CloseIcon />
				</IconButton>
				<Typography variant="h6" style={{marginLeft: "10px",flex: 1}}>
				  Cancelando Serviço agendado
				</Typography>
			 </Toolbar>
			</AppBar>

			<Box style={{...screenStyleDialog}} m={0}>
			
				<Box mt={7} ml={0} mr={0}>
					
					<Box style={{marginBottom:"5px",alignItems:"stretch",justifyContent:"flex-start",display:"flex",flexDirection:"column"}} p={2}>

								<h5 style={{fontSize:"1rem",color:"#A255A0",marginTop:"10px",textAlign:"center"}}>Cancelamento de serviço agendado</h5>
								
								<Box mt={0}  mb={1} ml={2} mr={2} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>  
										<div style={{marginRight:"10px"}}> 
											<WarningIcon style={{fontSize:"3rem",color:"red"}} />
										</div>
										
										<div> 
											<div style={{marginBottom:"10px",textAlign:"left"}}> Aten??o, o cancelamento do serviço agendado ? um processo irreversível. Você precisa mesmo cancelar?  </div>
										</div>
								</Box>									
							
					</Box>
					
					<Divider style={{marginBottom:"5px"}} />
					
					{/* URGENT Date */}						
					<Fade in={true} timeout={{ enter: 1000, exit: 0 }} mountOnEnter unmountOnExit>			
							
						<div>

							<Box mt={2}  mb={0} ml={2} mr={2} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>  
									<div> 
										<div style={{marginBottom:"10px",textAlign:"center",fontWeight:"bold"}}> Selecione o motivo do cancelamento </div>
									</div>
							</Box>	
							
							<Box mt={0}  mb={2} ml={2} mr={2} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>  
									<div style={{marginRight:"10px",width:"90%"}}> 

											<Select
											  label="Motivo do cancelamento"
											  placeholder="Motivo do cancelamento"
											  style={{ width:"100%",paddingTop:0,paddingBottom:0,margin:5,marginLeft:10,marginRight:0,backgroundColor:"#fff",width:"100%"}}
											  size="small"
											  variant="outlined"
											  value={cancelReason}
											  onChange={(event) => {
												  setCancelReason(event.target.value)
											  }}	
											 >
												<MenuItem value="-">- Selecione um motivo -</MenuItem>
												<MenuItem value="Cometi um erro nos dados">Cometi um erro nos dados</MenuItem>
												<MenuItem value="Mudei de ideia">Mudei de ideia</MenuItem>
												<MenuItem value="Agendei sem querer">Agendei sem querer</MenuItem>
												<MenuItem value="Fiz apenas um teste">Fiz apenas um teste</MenuItem>
												<MenuItem value="Não gostei do profissional">Não gostei do profissional</MenuItem>
												<MenuItem value="Tive um imprevisto">Tive um imprevisto</MenuItem>
												<MenuItem value="Profissional não tinha disponibilidade">Profissional não tinha disponibilidade</MenuItem>
												<MenuItem value="Outro motivo não listado">Outro motivo não listado</MenuItem>
											</Select>
											
									</div>
	
							</Box>								
							
							{cancelTax &&
							<>
							<Divider style={{marginBottom:"5px"}} />							

							<Box mt={2} mb={2} ml={2} mr={2} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>  
									<div style={{marginRight:"10px"}}> 
										<AttachMoneyIcon style={{fontSize:"3rem",color:"green"}} />
									</div>
									
									<div> 
										<div style={{fontSize:"0.85rem",color:"#555555",textAlign:"left"}}>
										<strong> Taxa de cancelamento </strong><br />
										Ser? cobrada uma taxa de {formatter.format(40)} referente a taxa de cancelamento ap?s tempo limite (1h e 30min antes do serviço). 									
										</div>
									</div>
							</Box>
							</>
							}
							
							<Divider style={{marginBottom:"5px"}} />							
							
							<Box mt={2} mb={2} ml={1} mr={2} style={{alignItems:"stretch",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>  

								<div style={{alignItems:"center",justifyContent:"center",display:"flex",flexDirection:"row"}}>
								
									  <div>
										  <Checkbox												
											checked={acceptCharge}
											onChange={(status)=>handleAcceptCharge(!acceptCharge)}
											inputProps={{ 'aria-label': 'primary checkbox' }}
										  />
									  </div>
									  
									   <span>
										Estou de acordo com o cancelamento do serviço
									  </span>
								
								</div>	 
								
							</Box>							
							

						</div>
						
					</Fade>					
			
					<Divider style={{marginBottom:"5px"}} />
						
					<Box style={{display:"flex",flexDirection:"row",justifyContent:"center"}} ml={1} mr={1} mb={5} mt={2}>						
						
						<Button disabled={loadingCancel} color="secondary" variant="outlined" style={{flex:1,height:"48px",marginRight:"10px"}} onClick={()=>setDialog('cancelservice',false)}>
						 Fechar
						</Button>		
						
						<Button disabled={loadingCancel} color="primary" variant="contained" style={{flex:2,height:"48px",color:"#FFF"}} onClick={()=>confirmCancel(props)}>
							{ !loadingCancel &&
							   <div>Confirmar cancelamento</div>
							}
							
							{ loadingCancel &&
							<>
							   <CircularProgress size={30} color="inherit" />
							   <span style={{marginLeft:"10px"}}>Cancelando...</span>
							</>
							}
						</Button>
							
					</Box>			
			
				</Box>				
			
			</Box>

		  </Dialog>
		 </LocalizationProvider>
	 
		 
		</div>		
	);
	
});


/* Add Rating Dialog Component START */
export const AddRatingDialog = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {
	
const backgroundHeaderModal = {
  backgroundImage: "url(" + backgroundHeaderImage + ")",
  backgroundSize:"cover",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"center",
  display:"flex",
  alignItems:"center",
  justifyContent:"center"
}; 		


const screenStyleDialog = {
  alignItems: "stretch",
  justifyContent: "top",
  width:"100vw",
  minHeight:"100vh",
  display:"flex",
  flexDirection:"column",
  backgroundImage: "url(" + backgroundHexagon + ")",
  backgroundSize:"cover",
  backgroundRepeat:"repeat",
  backgroundPosition:"center"
}; 
	
// set classes var
// const classes = useStyles()();	

// set vars from props
// Remember to add those who come from Redux
const { 
	setDialog,
	dialog,
	setButtonLoading,
	buttonLoading,
	addCustomerRatingServiceOrder,
	SelectedExecutedServiceOrder,
	refreshServiceOrder,
	ServiceOrderId
} = props;	 


/* Dialog END*/	

const [loadingRating, setLoadingRating] = useState(false);
const [rating1, setRating1] = React.useState(null); // separated rating
const [rating2, setRating2] = React.useState(null); // separated rating
const [rating3, setRating3] = React.useState(null); // separated rating
const [rating4, setRating4] = React.useState(null); // separated rating
const [rating5, setRating5] = React.useState(null); // separated rating
const [generalRating, setGeneralRating] = React.useState(0); // general rating

const saveCustomerRating = (props) => {
	
	// check of terms of urgency was accepted
	if (!rating1 && !rating2 && !rating3 && !rating4) {			
		props.setDialogMessage("Você precisa qualificar o profissional em ao menos um critério para prosseguir.");
		props.setAlertDialog(true);		
		return;			
	} // end if		
	
	// Calculate General rating
	var totalRating = 0;
	var totalRatingValue = 0;
	if (rating1) { totalRating++; totalRatingValue = totalRatingValue+rating1; }
	if (rating2) { totalRating++; totalRatingValue = totalRatingValue+rating2; }
	if (rating3) { totalRating++; totalRatingValue = totalRatingValue+rating3; }
	if (rating4) { totalRating++; totalRatingValue = totalRatingValue+rating4; }
	if (rating5) { totalRating++; totalRatingValue = totalRatingValue+rating5; }
	
	// Set specific rating
	var specificRatingObj = {
		rating1: rating1,
		rating2: rating2, 
		rating3: rating3,
		rating4: rating4,
		rating5: rating5			
	}
	
	// Set general rating based on specific rating
	var generalRatingValue = Math.round((totalRatingValue / totalRating)*100)/100;
	setGeneralRating(generalRatingValue);	

	// Set Loading
	setLoadingRating(true);

	// Set Obj
	let data = {
		ServiceOrderId:props.ServiceOrderId,
		generalRating: generalRatingValue ? generalRatingValue:null,
		specificRating: generalRatingValue ? specificRatingObj:null
	}
	var propsParam = {
		history:props.history,
		setAlert:props.setAlertDialog,
		setDialogMessage:props.setDialogMessage,
		setDialogTitle:props.setDialogTitle,
		setLoadingRating:setLoadingRating,
		setDialogAction:props.setDialogAction,
		setDialog:setDialog,
		refreshServiceOrder:refreshServiceOrder,
		setRatingEnabled:props.setRatingEnabled		
	}
	// Add rating method
	addCustomerRatingServiceOrder(data,propsParam);

};	


var formatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

const ratingChanged = (newRating) => {
  console.log(newRating)
}

// useEffect only for dialgo changes
useEffect(() => {
		
	// execute only if this modal
	if (dialog.target == 'addRatingProvider' && dialog.status) {
				
	
	}  // end if

}, [dialog]); // end useEffect only for dialgo changes

	return (
	<div>
		<LocalizationProvider utils={DateFnsUtils} locale={brLocale}>
		  <Dialog fullScreen open={dialog.target == 'addRatingProvider' && dialog.status} onClose={()=>setDialog('addRatingProvider',false)} TransitionComponent={TransitionSlideUp}>
		  

			<AppBar style={{position: 'fixed',height:'80px',...backgroundHeaderModal}}>
			  <Toolbar style={{width:"100%"}}>
				<IconButton edge="start" color="inherit" onClick={() => setDialog('addRatingProvider',false)} aria-label="close">
				  <CloseIcon />
				</IconButton>
				  <div style={{
					marginLeft: "10px",
					flex: 1,
					textAlign:"center",
					display:"flex",
					justifyContent:"center",
					alignItems:"center"
				  }}>
					  <img src={logo} alt="Motuum" style={{marginLeft:"auto",marginRight:"auto",width:"50%",height:"auto"}} />
				  </div>
				  <IconButton disabled edge="end" color="inherit" style={{minWidth:"54px"}} />
				</Toolbar>
			</AppBar>

			<Box style={{...screenStyleDialog}} m={0}>
			
				<Box mt={10} ml={0} mr={0}>
				
					<Box style={{marginBottom:"20px",marginTop:"30px",backgroundColor:"transparent",textAlign:"center"}} pl={3} pr={3}>

							<Box style={{marginTop:"20px",marginBottom:"0px"}}>						
								<div style={{fontFamily:"Roboto",fontWeight:"100",fontSize:"42px",color:"#003CFA",lineHeight:"38px"}}>	
									 QUALIFICAR PROFISSIONAL
								</div>								 	
							</Box> 	
							
					</Box>				
					
					<Box style={{marginBottom:"0px",alignItems:"stretch",justifyContent:"flex-start",display:"flex",flexDirection:"column"}} p={2}>

							<Card style={{minWidth: "90%",marginBottom:"20px",minHeight:"80px",width:"100%",marginBottom:"10px",backgroundColor:"#ffffff",boxShadow: "0px 10px 20px 0px #0000002A",borderRadius:"10px",position:"relative"}}>
										
								<CardContent style={{padding:"10px",paddingBottom:"0px"}}>
									
									<Box style={{display:"flex",flexdirection:"row",alignItems:"center",justifyContent:"space-between",marginTop:"6px",marginLeft:"15px",marginRight:"15px"}}>
										
										<Box style={{float:"left",textAlign:"left"}}>										
											<h3 style={{color:"#003CFA",fontFamily:"Roboto",fontWeight:"bold",fontSize:"16px",marginTop:"0px",marginBottom:"2px",lineHeight:"18px"}}>{props.serviceEmployeeName}</h3>
											<div style={{color:"#636466",fontFamily:"Roboto",fontWeight:"bold",fontSize:"12px",lineHeight:"18px"}}>
											{props.serviceCompanyName}
											</div>	
										</Box>
										
										<div style={{textAlign:"right",float:"right"}}>
												<Avatar src={props.avatarImageUrl} variant="square" style={{width:"50px",height:"50px",borderRadius:"50px"}} />	
										</div>														
									
									</Box>	
									
								  </CardContent>

						</Card>								
							
					</Box>
										
					{/* URGENT Date */}						
					<Fade in={true} timeout={{ enter: 1000, exit: 0 }} mountOnEnter unmountOnExit>			
							
						<div>

							<Box mt={0} mb={15} ml={2} mr={2} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}> 
							
									<div style={{marginRight:"0px",width:"100%"}}> 

										<Divider style={{marginBottom:"5px",marginTop:"5px"}} />

										<div style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"flex-start"}}>
											<div style={{marginRight:"15px",flex:1}}>Pontualidade</div>
											<div style={{flex:1,justifyContent:"end",display:"flex"}}>
												<ReactStars
												  count={5}
												  onChange={setRating1}
												  size={36}
												  half={false}
												  value={rating1}
												  color2={'#ffd700'} />	
											</div>
										</div>
										
										<Divider style={{marginBottom:"5px",marginTop:"5px"}} />									
										
										<div style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"flex-start"}}>
											<div style={{marginRight:"15px",flex:1}}>Qualidade</div>
											<div style={{flex:1,justifyContent:"end",display:"flex"}}>
												<ReactStars
												  count={5}
												  onChange={setRating2}
												  size={36}
												  half={false}
												  value={rating2}
												  color2={'#ffd700'} />	
											</div>
										</div>
										
										<Divider style={{marginBottom:"5px",marginTop:"5px"}} />									

										<div style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"flex-start"}}>
											<div style={{marginRight:"15px",flex:1}}>Apresentação</div>
											<div style={{flex:1,justifyContent:"end",display:"flex"}}>
												<ReactStars
												  count={5}
												  onChange={setRating3}
												  size={36}
												  half={false}
												  value={rating3}
												  color2={'#ffd700'} />	
											</div>
										</div>
										
										<Divider style={{marginBottom:"5px",marginTop:"5px"}} />									

										<div style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"flex-start"}}>
											<div style={{marginRight:"15px",flex:1}}>Cordialidade</div>
											<div style={{flex:1,justifyContent:"end",display:"flex"}}>
												<ReactStars
												  count={5}
												  onChange={setRating4}
												  size={36}
												  half={false}
												  value={rating4}
												  color2={'#ffd700'} />	
											</div>
										</div>
										
										<Divider style={{marginBottom:"5px",marginTop:"5px"}} />									

										<div style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"flex-start"}}>
											<div style={{marginRight:"15px",flex:1}}>Comunicação</div>
											<div style={{flex:1,justifyContent:"end",display:"flex"}}>
												<ReactStars
												  count={5}
												  onChange={setRating5}
												  size={36}
												  half={false}
												  value={rating5}
												  color2={'#ffd700'} />	
											</div>
										</div>										
											
									</div>
	
							</Box>								
							
						</div>
						
					</Fade>					
				
				</Box>

				<Box style={{backgroundColor:"transparent",display:"flex",width:"100%",alignItems:"center",position:"fixed",bottom:0,height:"80px",backgroundColor:"#ffffff",boxShadow: "0px -10px 15px 0px #0000001A"}}>		
						
					<Box style={{flex:1,paddingRight:"50px",paddingLeft:"50px"}}>	
				
						<Button color="primary" variant="contained" style={{width:"100%",height:"53px"}} onClick={()=>saveCustomerRating(props)}> 
							{ !loadingRating &&
							   <div>CONFIRMAR</div>
							}
							
							{ loadingRating &&
							<>
							   <CircularProgress size={30} color="inherit" />
							   <span style={{marginLeft:"10px"}}>ENVIANDO...</span>
							</>
							}
						</Button>
			
					</Box>	

							
				</Box>					
			
			</Box>

		  </Dialog>
		 </LocalizationProvider>
	 
		 
		</div>		
	);
	
});


/* Update Rating Dialog Component START */
export const UpdateRatingDialog = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {
	
const backgroundHeaderModal = {
  backgroundImage: "url(" + backgroundHeaderImage + ")",
  backgroundSize:"cover",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"center",
  display:"flex",
  alignItems:"center",
  justifyContent:"center"
}; 		


const screenStyleDialog = {
  alignItems: "stretch",
  justifyContent: "top",
  width:"100vw",
  minHeight:"100vh",
  display:"flex",
  flexDirection:"column",
  backgroundImage: "url(" + backgroundHexagon + ")",
  backgroundSize:"cover",
  backgroundRepeat:"repeat",
  backgroundPosition:"center"
}; 
	
// set classes var
// const classes = useStyles()();	

// set vars from props
// Remember to add those who come from Redux
const { 
	setDialog,
	dialog,
	setButtonLoading,
	buttonLoading,
	updateCustomerRatingServiceOrder,
	SelectedExecutedServiceOrder,
	refreshServiceOrder,
	ServiceOrderId
} = props;	 


/* Dialog END*/	

const [loadingRating, setLoadingRating] = useState(false);
const [rating1, setRating1] = React.useState(null); // separated rating
const [rating2, setRating2] = React.useState(null); // separated rating
const [rating3, setRating3] = React.useState(null); // separated rating
const [rating4, setRating4] = React.useState(null); // separated rating
const [rating5, setRating5] = React.useState(null); // separated rating
const [generalRating, setGeneralRating] = React.useState(0); // general rating

const saveCustomerRating = (props) => {
	
	// check of terms of urgency was accepted
	if (!rating1 && !rating2 && !rating3 && !rating4) {			
		props.setDialogMessage("Você precisa qualificar o profissional em ao menos um critério para prosseguir.");
		props.setAlertDialog(true);		
		return;			
	} // end if		
	
	// Calculate General rating
	var totalRating = 0;
	var totalRatingValue = 0;
	if (rating1) { totalRating++; totalRatingValue = totalRatingValue+rating1; }
	if (rating2) { totalRating++; totalRatingValue = totalRatingValue+rating2; }
	if (rating3) { totalRating++; totalRatingValue = totalRatingValue+rating3; }
	if (rating4) { totalRating++; totalRatingValue = totalRatingValue+rating4; }
	if (rating5) { totalRating++; totalRatingValue = totalRatingValue+rating5; }
	
	// Set specific rating
	var specificRatingObj = {
		rating1: rating1,
		rating2: rating2, 
		rating3: rating3,
		rating4: rating4,
		rating5: rating5			
	}
	
	// Set general rating based on specific rating
	var generalRatingValue = Math.round((totalRatingValue / totalRating)*100)/100;
	setGeneralRating(generalRatingValue);	

	// Set Loading
	setLoadingRating(true);

	// Set Obj
	let data = {
		ServiceOrderId:props.ServiceOrderId,
		generalRating: generalRatingValue ? generalRatingValue:null,
		specificRating: generalRatingValue ? specificRatingObj:null
	}
	var propsParam = {
		history:props.history,
		setAlert:props.setAlertDialog,
		setDialogMessage:props.setDialogMessage,
		setDialogTitle:props.setDialogTitle,
		setLoadingRating:setLoadingRating,
		setDialogAction:props.setDialogAction,
		setDialog:setDialog,
		refreshServiceOrder:refreshServiceOrder,
	}
	// Add rating method
	updateCustomerRatingServiceOrder(data,propsParam);

};	


var formatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

const ratingChanged = (newRating) => {
  console.log(newRating)
}

// useEffect only for dialgo changes
useEffect(() => {
		
	// execute only if this modal
	if (dialog.target == 'updateRatingProvider' && dialog.status) {
				
			var CustomerRatingObj = JSON.parse(SelectedExecutedServiceOrder.ServiceOrderProviderRating.specificRating);

			// Set extra info
			var rating1Value = CustomerRatingObj.rating1 ? setRating1(CustomerRatingObj.rating1) : null;
			var rating2Value = CustomerRatingObj.rating2 ? setRating2(CustomerRatingObj.rating2) : null;
			var rating3Value = CustomerRatingObj.rating3 ? setRating3(CustomerRatingObj.rating3) : null;
			var rating4Value = CustomerRatingObj.rating4 ? setRating4(CustomerRatingObj.rating4) : null;
			var rating5Value = CustomerRatingObj.rating5 ? setRating5(CustomerRatingObj.rating5) : null;
	
	}  // end if

}, [dialog]); // end useEffect only for dialgo changes

	return (
	<div>
		<LocalizationProvider utils={DateFnsUtils} locale={brLocale}>
		  <Dialog fullScreen open={dialog.target == 'updateRatingProvider' && dialog.status} onClose={()=>setDialog('updateRatingProvider',false)} TransitionComponent={TransitionSlideUp}>

			<AppBar style={{position: 'fixed'}}>
			  <Toolbar style={{width:"100%"}}>
				<IconButton edge="start" color="inherit" onClick={() => setDialog('updateRatingProvider',false)} aria-label="close">
				  <CloseIcon />
				</IconButton>
				  <div style={{
					marginLeft: "10px",
					flex: 1,
					textAlign:"center",
					display:"flex",
					justifyContent:"center",
					alignItems:"center"
				  }}>
					  <img src={logo} alt="Motuum" style={{marginLeft:"auto",marginRight:"auto",width:"50%",height:"auto"}} />
				  </div>
				  <IconButton disabled edge="end" color="inherit" style={{minWidth:"54px"}} />
				</Toolbar>
			</AppBar>

			<Box style={{...screenStyleDialog}} m={0}>
			
				<Box mt={10} ml={0} mr={0}>
				
					<Box style={{marginBottom:"20px",marginTop:"30px",backgroundColor:"transparent",textAlign:"center"}} pl={3} pr={3}>

							<Box style={{marginTop:"20px",marginBottom:"0px"}}>						
								<div style={{fontFamily:"Roboto",fontWeight:"100",fontSize:"42px",color:"#003CFA",lineHeight:"38px"}}>	
									 QUALIFICAR PROFISSIONAL
								</div>								 	
							</Box> 	
							
					</Box>				
					
					<Box style={{marginBottom:"0px",alignItems:"stretch",justifyContent:"flex-start",display:"flex",flexDirection:"column"}} p={2}>

							<Card style={{minWidth: "90%",marginBottom:"20px",minHeight:"80px",width:"100%",marginBottom:"10px",backgroundColor:"#ffffff",boxShadow: "0px 10px 20px 0px #0000002A",borderRadius:"10px",position:"relative"}}>
										
								<CardContent style={{padding:"10px",paddingBottom:"0px"}}>
									
									<Box style={{display:"flex",flexdirection:"row",alignItems:"center",justifyContent:"space-between",marginTop:"6px",marginLeft:"15px",marginRight:"15px"}}>
										
										<Box style={{float:"left",textAlign:"left"}}>										
											<h3 style={{color:"#003CFA",fontFamily:"Roboto",fontWeight:"bold",fontSize:"16px",marginTop:"0px",marginBottom:"2px",lineHeight:"18px"}}>{props.serviceEmployeeName}</h3>
											<div style={{color:"#636466",fontFamily:"Roboto",fontWeight:"bold",fontSize:"12px",lineHeight:"18px"}}>
											{props.serviceCompanyName}
											</div>	
										</Box>
										
										<div style={{textAlign:"right",float:"right"}}>
												<Avatar src={props.avatarImageUrl} variant="square"  style={{width:"50px",height:"50px",borderRadius:"50px"}} />	
										</div>														
									
									</Box>	
									
								  </CardContent>

						</Card>								
							
					</Box>
					
					{/* URGENT Date */}						
					<Fade in={true} timeout={{ enter: 1000, exit: 0 }} mountOnEnter unmountOnExit>			
							
						<div>

							<Box mt={0} mb={15} ml={2} mr={2} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}> 
							
									<div style={{marginRight:"0px",width:"100%"}}> 
									
										<Divider style={{marginBottom:"5px",marginTop:"5px"}} />	

										<div style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"flex-start"}}>
											<div style={{marginRight:"15px",flex:1}}>Pontualidade</div>
											<div style={{flex:1,justifyContent:"end",display:"flex"}}>
												<ReactStars
												  count={5}
												  onChange={setRating1}
												  size={36}
												  half={false}
												  value={rating1}
												  color2={'#ffd700'} />	
											</div>
										</div>
									
										<Divider style={{marginBottom:"5px",marginTop:"5px"}} />	
										
										<div style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"flex-start"}}>
											<div style={{marginRight:"15px",flex:1}}>Qualidade</div>
											<div style={{flex:1,justifyContent:"end",display:"flex"}}>
												<ReactStars
												  count={5}
												  onChange={setRating2}
												  size={36}
												  half={false}
												  value={rating2}
												  color2={'#ffd700'} />	
											</div>
										</div>
										
										<Divider style={{marginBottom:"5px",marginTop:"5px"}} />	

										<div style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"flex-start"}}>
											<div style={{marginRight:"15px",flex:1}}>Apresentação</div>
											<div style={{flex:1,justifyContent:"end",display:"flex"}}>
												<ReactStars
												  count={5}
												  onChange={setRating3}
												  size={36}
												  half={false}
												  value={rating3}
												  color2={'#ffd700'} />	
											</div>
										</div>
										
										<Divider style={{marginBottom:"5px",marginTop:"5px"}} />	

										<div style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"flex-start"}}>
											<div style={{marginRight:"15px",flex:1}}>Cordialidade</div>
											<div style={{flex:1,justifyContent:"end",display:"flex"}}>
												<ReactStars
												  count={5}
												  onChange={setRating4}
												  size={36}
												  half={false}
												  value={rating4}
												  color2={'#ffd700'} />	
											</div>
										</div>
										
										<Divider style={{marginBottom:"5px",marginTop:"5px"}} />	

										<div style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"flex-start"}}>
											<div style={{marginRight:"15px",flex:1}}>Comunicação</div>
											<div style={{flex:1,justifyContent:"end",display:"flex"}}>
												<ReactStars
												  count={5}
												  onChange={setRating5}
												  size={36}
												  half={false}
												  value={rating5}
												  color2={'#ffd700'} />	
											</div>
										</div>										
											
									</div>
	
							</Box>								
							
							<Divider style={{marginBottom:"5px"}} />							
							
				
						</div>
						
					</Fade>					
			
				</Box>	

				<Box style={{backgroundColor:"transparent",display:"flex",width:"100%",alignItems:"center",position:"fixed",bottom:0,height:"80px",backgroundColor:"#ffffff",boxShadow: "0px -10px 15px 0px #0000001A"}}>		
						
					<Box style={{flex:1,paddingRight:"50px",paddingLeft:"50px"}}>	
				
						<Button disabled={loadingRating} color="primary" variant="contained" style={{width:"100%",height:"53px"}} onClick={()=>saveCustomerRating(props)}>
							{ !loadingRating &&
							   <div>CONFIRMAR</div>
							}
							
							{ loadingRating &&
							<>
							   <CircularProgress size={30} color="inherit" />
							   <span style={{marginLeft:"10px"}}>ALTERANDO...</span>
							</>
							}
						</Button>
			
					</Box>	

							
				</Box>					
			
			</Box>

		  </Dialog>
		 </LocalizationProvider>
	 
		 
		</div>		
	);
	
});


const ConfirmDialog = (props) => {
	
	return (
		 <Dialog
			open={props.dialog.target == 'updateServicePrompt' && props.dialog.status}
			onClose={ ()=>props.setDialog('updateServicePrompt',false) } 
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		  >
		  <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
			<DialogContent>
			  <DialogContentText id="alert-dialog-description">
			  {props.dialogMessage}
			  </DialogContentText>
			</DialogContent>
			<DialogActions>

			  <Button onClick={ ()=>props.setDialog('updateServicePrompt',false) } color="primary" autoFocus>
				ALTERAR DATA
			  </Button>			

			  <Button onClick={ ()=> {props.setDialog('updateServicePrompt',false);props.setDialog('cancelservice',true); } } color="primary" autoFocus>
				CANCELAR SERVIÇO
			  </Button>
				
			</DialogActions>			
		  </Dialog>
	  )
		
} // end AlertDialog




const AlertDialog = (props) => {
	
	return (
		 <Dialog
			open={props.dialogAlert}
			onClose={!props.dialogAction ? () => { props.setAlertDialog(false) } : () => { props.setAlertDialog(false);props.navigate("/scheduledServices") }  }
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		  >
		  <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
			<DialogContent>
			  <DialogContentText id="alert-dialog-description">
			  {props.dialogMessage}
			  </DialogContentText>
			</DialogContent>
			<DialogActions>
			
			{props.dialogAction &&
			  <Button onClick={() => {props.setAlertDialog(false);props.navigate("/scheduledServices")} } color="primary" autoFocus>
				OK
			  </Button>
			}

			{!props.dialogAction &&
			  <Button onClick={() => props.setAlertDialog(false) } color="primary" autoFocus>
				OK
			  </Button>
			}			  
			  
			</DialogActions>			
		  </Dialog>
	  )
	
	
} // end AlertDialog


export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(ExecutedServicesView); 

