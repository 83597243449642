import './MainSearchService.css';
import React,{useState,useEffect,Fragment} from 'react';
import {Link,useNavigate} from 'react-router-dom';
import { CSSTransition } from "react-transition-group";

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

import { Box,Backdrop,CircularProgress,Paper,Divider,Typography,Button } from '@mui/material';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

import Grid from '@mui/material/Grid';

import Fade from '@mui/material/Fade';
import Slide from '@mui/material/Slide';
import Info from '@mui/icons-material/ErrorOutline';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import MemoryIcon from '@mui/icons-material/Memory';
import AppsIcon from '@mui/icons-material/Apps';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloseIcon from '@mui/icons-material/Close';
import NotInterestedIcon from '@mui/icons-material/NotInterested';


import {useComponentWillMount} from '../../Utils/customFunctions';
import Header from '../../Components/Layout/Header';
import If from '../../Components/Common/If';

import backgroundHexagon from '../../Assets/Images/backgroundHexagon.png'; // Tell Webpack this JS file uses this image
import desktop from '../../Assets/Images/desktop.svg'; // Tell Webpack this JS file uses this image

// Load Actions
import * as AppActions from '../../Store/Actions/App'
import * as ServiceActions from '../../Store/Actions/Service'
import NavigationBottom from '../../Components/Layout/NavigationBottom';
import SearchComponent from '../SearchService/SearchComponent';

const SearchService = props => {

const screenStyle = {
  alignItems: "stretch",
  justifyContent: "top",
  width:"100vw",
  minHeight:"100vh",
  display:"flex",
  flexDirection:"column",
  backgroundImage: "url(" + backgroundHexagon + ")",
  backgroundSize:"cover",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"center"
}; 

const screenStyleLoading = {
  alignItems: "middle",
  justifyContent: "center",
  width:"100vw",
  height:"100vh",
  display: "flex",
  flexDirection: "column"
}; 

const screenStyleLoadingSearch = {
  alignItems: "center",
  justifyContent: "center",
  height:"54vh",
  display: "flex",
  flexDirection: "column"
}; 


// set classes var
// const classes = useStyles()();

// set vars from props
// Remember to add those who come from Redux
const { 
	buttonLoading,
	setButtonLoading,
	hireServiceService,
    categories,
	retrieveCategories,
	setService,
	searchResultItens,
	searchServicesByKeyword
} = props;

// Set internal state
const [contentVisible,setContentState] = useState(false);
const [searchVisible,setSearchState] = useState(false);
const [loadingMessage,setLoadingMessage] = useState("Carregando...");

const [Level,setLevel] = useState(0);
const [SubLevel,setSubLevel] = useState(0);
const [ServicesResult,setServicesResult] = useState(false);
const [ServicesResultSearch,setServicesResultSearch] = useState(false);
const [searchKeyword,setSearchKeyword] = useState("");

const [dialogAlert, setAlertDialog] = useState(false); // state for tab
const [dialogMessage, setDialogMessage] = useState(""); // state for tab
const [dialogTitle, setDialogTitle] = useState("Verifique as informações"); // state for tab

const [selectedLevel,setSelectedLevel] = useState({});
const [selectedLevelChilds,setSelectedLevelChilds] = useState([]);
const [selectedCategory,setSelectedCategory] = useState({});
const [cards,setCards] = useState([]);
const [searchResult,setSearchResult] = useState(searchResultItens.length > 0 ? searchResultItens:[]);

// set Use of history for PUSH 
let navigate = useNavigate();

// Generic Select Icon for multiple selects
const selectLevel = (number) => {
	
	if (number == 0) {

		setServicesResult(false);				
		setTimeout(function(){			
				
			setSubLevel(0)					
			setTimeout(function(){			
				setLevel(0);
			},300);				
			
		},300);	
		
	} else if (number > 0) {
		
		// Set selected level 
		var array = [...categories]; // clone
		var index = array.filter(item => item.id == number);
		var reducedArray = index[0];
		setSelectedCategory(reducedArray);
		setSelectedLevel(reducedArray);		
		
		// Set childs of selectedLevel
		var arrayChilds = [...reducedArray.childs]; // clone
		setSelectedLevelChilds(arrayChilds);		
			
	    setLevel(number);		
		setTimeout(function(){			
			setSubLevel(1)			
		},300);
		
	}

};

// Generic Select Icon for multiple selects
const searchServices = (category) => {	

	var array = [...selectedLevelChilds]; // clone
	var index = array.filter(item => item.id == category);
	var reducedArray = index[0];

	setSelectedCategory(reducedArray);
	setCards(reducedArray.Services);	
	
	setServicesResult(true);

};

// Generic Select Icon for multiple selects
const selectService = (serviceId) => {	

	var array = [...selectedCategory.Services]; // clone
	var index = array.filter(item => item.id == serviceId);
	var reducedArray = index[0];
	setService({
		...reducedArray,
		breadcrumb:selectedCategory.breadcrumb,
		categoryId:selectedCategory.id
	});
	
	// go to...
	goTo("/searchService/view/"+serviceId+"");	

};

// Generic Select Icon for multiple selects
const selectServiceSearch = (serviceId) => {	

	var array = [...searchResult]; // clone
	var index = array.filter(item => item.id == serviceId);
	var reducedArray = index[0];
	setService({
		...reducedArray,
		breadcrumb:reducedArray.ServiceCategories[0].breadcrumb,
		categoryId:reducedArray.ServiceCategories[0].id
	});
	
	// go to...
	goTo("/searchService/view/"+serviceId+"");	

};

// Generic Select Icon for multiple selects
const searchByTermServices = (status) => {	


	if (status === true) {
		
		setServicesResult(false);
			
		setTimeout(function(){	
			setSubLevel(0)					
			setLevel(1)					
			setTimeout(function(){
					setServicesResultSearch(true);
			},500);					
				
		},500);	
		
	} else {
		
		setServicesResultSearch(false);				
		setTimeout(function(){
			setSubLevel(0)					
			setLevel(0)			
		},500);	
		
	} // end else

};

// Go to Target when click on card
const goTo = (url) => {	
	navigate(url);
}; 
  
// Run after mount component
useEffect(() => {
	
    // Set session of app
  var sessionCheck = sessionStorage.getItem('@motuum/appLaunched');
  
  // if session is not created, redirect to bootstrap. (avoid direct access using url)
  if (!sessionCheck) {
	  navigate("/");
  } // end if	
  
	// Retrieve User Data Obj. 
	var result = localStorage.getItem("@motuum/userData", undefined,true);
	var userData = JSON.parse(result);  
  
	  // If token dont exist, redirect to onboarding or register
	  if (!userData) {	  
		navigate("/");  
	  } // end if
	  // If token exist, redirect to HOME
	  else {
		  if (!userData.userToken) {
			navigate("/");
		  } // end if
	  } // end else     
  
	// Data
	var data = {
		parentId:0  
	}  
	var props = {
		navigate:navigate,
		setAlert:setAlertDialog,
		setDialogMessage:setDialogMessage,
		setDialogTitle:setDialogTitle,
		setContentState:setContentState
	}	
	// Load Categories Firts Level...
	retrieveCategories(data,props);

  // set scroll to top
  window.scrollTo(0, 1);
    
  // Runs when UNMOUNT component
  return () => {
    
	
  };
  
}, []); // end useEffect

// Will Mount Component HOOK
useComponentWillMount(() => {
	
}, []); // end useComponentWillMount

const handleKeypress = e => {
		
	 //it triggers by pressing the enter key  
	if (e.key === "Enter") {
	  e.preventDefault();
	  searchAction();
	}

};

const resetSearch = () => {	
	setSearchResult([]);	
}

const searchAction = () => {
	
	// Chekc if keyword was provided
	if (searchKeyword.trim() == "" || !searchKeyword) {
		setDialogMessage('Digite ao menos uma palavra-chave para fazer a busca.');		
		setAlertDialog(true);			
		return;
	}
	
	// Chekc if keyword was provided
	if (searchKeyword.length < 2) {
		setDialogMessage('A palava que digitou é muito pequena. Seja mais específico.');		
		setAlertDialog(true);			
		return;
	}	
	
	// turn search panel on
	searchByTermServices(true);
	
	// Data
	var data = {
		keywords:searchKeyword
	}  
	var props = {
		navigate:navigate,
		setAlert:setAlertDialog,
		setDialogMessage:setDialogMessage,
		setDialogTitle:setDialogTitle,
		setSearchState:setSearchState,
		setSearchResult:setSearchResult
	}	
	// Load Categories Firts Level...
	searchServicesByKeyword(data,props);	
		
}

	return (
	<Fragment>
			
			<Header title="Solicitar Serviço" buttons={{support:false,menu:false,close:false,rightFake:true,home:true,back:true,backUrl:"/home"}} />
			
			{/* Inner Loading */}
			{ !contentVisible &&
					<Box className="loadingContent" style={{...screenStyleLoading}}>
							<Box mt={0} mx={0}>								
								<CircularProgress /> 
								<div> Carregando Categorias...Aguarde!</div>								
							</Box>	
					</Box>
			}			
			
			<Fade in={contentVisible} timeout={{ enter: 1000, exit: 300 }}  mountOnEnter unmountOnExit>			
			
				<Box className="mainSearchServices" style={{...screenStyle}} mt={0}>

					<Box style={{flex:1,alignItems:"center",overflow:"scroll"}} mt={19} ml={0} mr={0}>

						{/* Search Service Component */}
						<SearchComponent />
						
					</Box>
					
					{/* Bottom Navigation Bar */}
					<NavigationBottom page="home" />
					
				</Box>
			
			</Fade>    
			

			<AlertDialog 
				dialogAlert={dialogAlert}
				setAlertDialog={setAlertDialog}
				dialogTitle={dialogTitle}
				dialogMessage={dialogMessage}
			/>					
				
	</Fragment>
)};

const AlertDialog = (props) => {
	
	return (
		 <Dialog
			open={props.dialogAlert}
			onClose={() => props.setAlertDialog(false)} 
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		  >
		  <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
			<DialogContent>
			  <DialogContentText id="alert-dialog-description">
			  {props.dialogMessage}
			  </DialogContentText>
			</DialogContent>
			<DialogActions>
			  <Button onClick={() => props.setAlertDialog(false)} color="primary" autoFocus>
				OK
			  </Button>
			</DialogActions>			
		  </Dialog>
	  )
	
	
} // end AlertDialog

const mapStateToProps = store => ({
  buttonLoading:store.appReducer.buttonLoading,
  hireServiceService:store.serviceReducer.hireServiceService,
  categories:store.serviceReducer.categories,
  parentCategories:store.serviceReducer.parentCategories,
  childCategories:store.serviceReducer.childCategories,
  searchResultItens:store.serviceReducer.searchResultItens  
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AppActions, ...ServiceActions }, dispatch);

export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(SearchService);
 
