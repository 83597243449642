import './MainProfile.css';
import React,{useState,useEffect,Fragment} from 'react';
import {Link,useNavigate} from 'react-router-dom';

import PropTypes from 'prop-types';
import { CSSTransition } from "react-transition-group";

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

import InputMask from "react-input-mask";

// import LiveChat from 'react-livechat'

// Material UI Import
import { Box,CircularProgress,Typography,Button,InputAdornment,IconButton,TextField,AppBar,Toolbar } from '@mui/material';

import Slide from '@mui/material/Slide';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import {useComponentWillMount} from '../../Utils/customFunctions';
import {SupportComponent} from '../Support/SupportComponentHeader';

import logo from '../../Assets/Images/logo_motuum-white.svg'; // Tell Webpack this JS file uses this image
import helpIcon from '../../Assets/Images/help-icon-blue.svg'; // Tell Webpack this JS file uses this image
import backgroundHexagon from '../../Assets/Images/backgroundHexagon.png'; // Tell Webpack this JS file uses this image
import backgroundHeaderImage from '../../Assets/Images/backgroundHeader.png'; // Tell Webpack this JS file uses this image

// Load Actions
import * as AppActions from '../../Store/Actions/App'
import * as RegisterActions from '../../Store/Actions/Register'

const Profile = props => {

const screenStyle = {
  alignItems: "stretch",
  justifyContent: "top",
  width:"100vw",
  height:"100vh",
  display:"flex",
  flexDirection:"column",
  backgroundImage: "url(" + backgroundHexagon + ")",
  backgroundSize:"cover",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"center"
}; 

const backgroundHeader = {
  backgroundImage: "url(" + backgroundHeaderImage + ")",
  backgroundSize:"cover",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"center"
}; 

const screenStyleLoading = {
  alignItems: "middle",
  justifyContent: "center",
  width:"100vw",
  height:"100vh",
  display: "flex",
  flexDirection: "column"
}; 

const styles = {
  indicator: {
    backgroundColor: '#A255A0',
  },
};

// set classes var
// const classes = useStyles()();

// Set functions
const handleChange = (event, newValue) => {
setValue(newValue);
};

// Set TABPANEL
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
	  hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

// set vars from props
// Remember to add those who come from Redux
const { 
	registerDataStep0,
	registerDataStep1,
	registerDataStep2,
	registerDataStep3,
	innerLoading,
	setInnerLoading,
	buttonLoading,
	setButtonLoading,
	setRegister,
	processStep
} = props;

// set Use of history for PUSH 
let navigate = useNavigate();

// Set internal state
const [contentVisible,setContentState] = useState(false);
const [loadingMessage,setLoadingMessage] = useState("Carregando...");
const [value, setValue] = useState(0); // state for tab
const [dialog, setDialog] = useState(false); // state for tab
const [dialogMessage, setDialogMessage] = useState(""); // state for tab

const [dialogHelp, setDialogHelp] = useState(false); // state for tab

const [email, setEmail] = useState(registerDataStep0.email ? registerDataStep0.email : ""); // state for input fields
const [mobilePhone, setMobilePhone] = useState(registerDataStep0.mobilePhone ? registerDataStep0.mobilePhone : ""); // state for input fields
    
const nextStep = (nextStep,param) => {
	
	setButtonLoading(true);
	
	if (param.type == "email") {
		
		setEmail(param.email);
		
		// Check if Email is filled
		if (!param.email) {		
			setDialogMessage("Digite o email para prosseguir.");
			setDialog(true);
			setButtonLoading(false);		
			return false;		
		} // end if
		
		// Check if Email is valid
		let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
		if (reg.test(param.email) === false) {
			setDialogMessage("Digite um email válido para prosseguir.");
			setDialog(true);
			setButtonLoading(false);				
			return false;
		} // end if
				
	
	} // end if
	else if (param.type == "mobilePhone") {
		
		const mobileNumberClean = param.mobilePhone.replace(/\D/g,"");
		
		setMobilePhone(param.mobilePhone);
		
		// Check if Mobile Phone is filled
		if (!param.mobilePhone) {		
			setDialogMessage("Digite o telefone para prosseguir.");
			setDialog(true);
			setButtonLoading(false);		
			return false;		
		} // end if
		
		// Check if Phone Number is valid is valid
		
			// Min 10 numbers 
			if (mobileNumberClean.length < 10) {
				setDialogMessage("Digite um Telefone celular válido para prosseguir.");
				setDialog(true);
				setButtonLoading(false);				
				return false;
			} // end if	
		
			// Check if DDD is valid
			const dddArray = [11,12,13,14,15,16,17,18,19,21,22,24,27,28,31,32,33,34,35,37,38,41,42,43,44,45,46,47,48,49,51,53,54,55,61,62,63,64,65,66,67,68,69,71,73,74,75,77,79,81,82,83,84,85,86,87,88,89,91,92,93,94,95,96,97,98,99];
			const dddPart = parseInt(mobileNumberClean.substr(0,2));	

			if (!dddArray.includes(dddPart)) {
				setDialogMessage("Digite um DDD válido para prosseguir.");
				setDialog(true);
				setButtonLoading(false);				
				return false;
			} // end if		

			// If number has 11 digits, Check if number star with 9. If not, fail.
			const firstElevenDigitPart = parseInt(mobileNumberClean.substr(2,1));
			if (firstElevenDigitPart != 9 && mobileNumberClean.length == 11) {
				setDialogMessage("Digite um Número de celular válido para prosseguir.");
				setDialog(true);
				setButtonLoading(false);				
				return false;
			} // end if		
			
			// If number has 10 digits, check if number start with 6, 7, 8 or 9
			const tenDigitsValid = [6,7,8,9];
			const firstDigitPart = parseInt(mobileNumberClean.substr(2,1));	
			if (!tenDigitsValid.includes(firstDigitPart)) {
				setDialogMessage("Digite um Número de celular válido para prosseguir.");
				setDialog(true);
				setButtonLoading(false);				
				return false;
			} // end if					
			

	} // end else if

	// Save email on store
	setRegister({...param},0);

	// set data
	var data = {...param};
	
	//[ DISPATCH ACTION ] checkEmail(NEXT STEP, DATA ,navigation OBJ);
	var props = {
		navigate:navigate,
		setDialogMessage:setDialogMessage,
		setDialog:setDialog	
	}
	processStep(nextStep,data,props);
	
} // end const
  
  
// Run after mount component
useEffect(() => {
	
	// Retrieve User Data Obj. 
	var result = localStorage.getItem("@motuum/userData", undefined,true);
	var userData = JSON.parse(result);  

	  // If token dont exist, redirect to onboarding or register
	  if (userData) {
		  navigate("/home");
		  return;
	  } // end if  	
	
 // Set Content Loading ON
 //setInnerLoading(true);
 setContentState(true);
 
  // set scroll to top
  window.scrollTo(0, 1);
    
  // Runs when UNMOUNT component
  return () => {
    console.log("Desmontou o componente MainHome...");
  };
  
}, []); // end useEffect


// Will Mount Component HOOK
useComponentWillMount(() => {
	
    // Set session of app
  var sessionCheck = sessionStorage.getItem('@motuum/appLaunched');
  
  // if session is not created, redirect to bootstrap. (avoid direct access using url)
  if (!sessionCheck) {
	  navigate("/");
  } // end if	
      

}, []); // end useComponentWillMount


	return (
	<Fragment>
			
			<CSSTransition in={contentVisible} timeout={1000} classNames="fade"	unmountOnExit>	
			
				<Box className="mainProfile" style={{...screenStyle}} m={0}>
							
						<Box style={{height:"210px",alignItems:"center",display:"flex",backgroundColor:"#003CFA",...backgroundHeader}}>	
						
							<Box style={{flex:1,alignItems:"center",display:"flex",marginTop:"9%"}}>						
									<img src={logo} alt="Logomarca Motuum" style={{marginLeft:"auto",marginRight:"auto",width:"76%",height:"auto"}} />
							</Box>	
							
						</Box>
						
						<Box style={{backgroundColor:"transparent",display:"flex",alignItems:"top",paddingLeft:"35px",paddingRight:"35px",marginBottom:"120px"}}>								
								
							<Box style={{flexGrow: 1,backgroundColor: "transparent",flex:1 }} ml={2} mr={2} >
								
							
								<Box style={{marginTop:"30px",marginBottom:"20px"}}>						
									<div style={{fontFamily:"Roboto",fontWeight:"100",fontSize:"42px",color:"#003CFA"}}>
										  CADASTRO
									</div>								 	
								</Box>									
							
							
								<FormEmail
									buttonLoading={buttonLoading}
									nextStep={nextStep}	
									email={email}
								/>
								
								<Box style={{backgroundColor:"transparent",justifyContent:"center",alignItems:"flex-start",display:"flex",flexDirection:"row",marginTop:"10px"}}>	
								
										<Button  style={{flex:1.5,color:"#636466",fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px",textTransform:"none"}} component={Link} to="/onboarding">Rever apresentação</Button>	
										
										<Button  style={{flex:1,color:"#003CFA",fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px",textTransform:"none"}} component={Link} to="/login">Login</Button>	
										
								</Box>									
							
							</Box>
								
						</Box>
						

						<Box style={{backgroundColor:"transparent",display:"flex",width:"100%",alignItems:"center",position:"fixed",bottom:0,height:"80px",backgroundColor:"#ffffff",boxShadow: "0px -10px 15px 0px #0000001A"}} onClick={()=>setDialogHelp(true)}>		

							<Box style={{flex:1,display:"flex",flexDirection:"row",justifyContent:"space-around"}}>
									
								<Box style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>						
										
											<div style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
												<img src={helpIcon} alt="Ajuda" style={{width:"25px",height:"auto"}} />
											</div>
										  
											<div style={{marginLeft:"5px",paddingTop:"3px",fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px",color:"#003cfa",}}>
											  Ajuda
											</div>	
											
						
								</Box>	

							</Box>								
								
						</Box>								
								
				</Box>
			
			</CSSTransition> 
			
			{/*<LiveChat license={13444314} />*/}
			
			<HelpDialog
				dialogHelp={dialogHelp}
				setDialogHelp={setDialogHelp}
			/>
			
			<AlertDialog 
				dialog={dialog}
				setDialog={setDialog}
				dialogMessage={dialogMessage}
			/>
			
				
	</Fragment>
)};

const FormEmail = (props) => {
	
const [email, setEmail] = useState(props.email); // state for input fields
	
const handleKeypress = e => {	
	 //it triggers by pressing the enter key  
	if (e.key === "Enter") {
	  props.nextStep(1,{email:email,type:'email'})
	}
};	
	
	return (	
		<form id="formLoginEmail"  noValidate autoComplete="off">

			<TextField
			  id="loginEmail"
			  label="Email"
			  style={{ margin: 10,marginLeft:0,marginRight:0,backgroundColor:"#fff",width:"100%"}}
			  placeholder="Informe o email para cadastro"
			  required
			  variant="outlined"
			  type="email"
			  onKeyPress={handleKeypress}
			  value={email}
			  onChange={(event) => {
				  setEmail(event.target.value)
			  }}									  
			/>

			<Button disabled={props.buttonLoading} variant="contained" color="primary" size="large" style={{width:"100%",height:"54px",marginTop:"10px",backgroundColor:props.buttonLoading ? "#AAA":"#555555",fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px"}} onClick={()=> props.nextStep(1,{email:email,type:'email'}) }>	
			
			{ !props.buttonLoading &&
			   <div>Próximo</div>
			}
			
			{ props.buttonLoading &&
			<>
			   <CircularProgress size={30} color="inherit" />
			   <span style={{marginLeft:"10px"}}> Verificando </span>
			  </>
			}	
			
			</Button>
		  
		</form>	
	);
	
	
} // end export const

const FormMobilePhone = (props) => {
	
	const [mobilePhone, setMobilePhone] = useState(props.mobilePhone); // state for input fields
	
const handleKeypress = e => {	
	 //it triggers by pressing the enter key  
	if (e.key === "Enter") {
	  props.nextStep(1,{mobilePhone:mobilePhone,type:'mobilePhone'})
	}
};	
	
	return (	
		<form id="formLoginPhone" noValidate autoComplete="off">

			<InputMask
				mask="(99) 99999-9999"
				onKeyPress={handleKeypress}
				value={mobilePhone}
				  onChange={(event) => {
					  setMobilePhone(event.target.value)
				  }}
			  >
			  {(inputProps) => (
				<TextField
				  label="Telefone Celular"
				  style={{ margin: 10,marginLeft:0,marginRight:0,backgroundColor:"#fff",width:"94%"}}
				  placeholder="Telefone no formato (XX) 9XXXX-XXXX"
				  required
				  size="small"
				  variant="outlined"
				 />
			  )}
			</InputMask>
										
			<Button disabled={props.buttonLoading} variant="contained" color="primary" size="large" style={{width:"100%",height:"48px",marginTop:"10px",backgroundColor:props.buttonLoading ? "#AAA":"#555555"}} onClick={()=> props.nextStep(1,{mobilePhone:mobilePhone,type:'mobilePhone'}) }>	
			
			{ !props.buttonLoading &&
			   <div>Iniciar cadastro de perfil</div>
			}
			
			{ props.buttonLoading &&
			   <CircularProgress size={30} color="inherit" />
			}	
			
			</Button>
		  
		</form>	
	);
	
} // end export const


const AlertDialog = (props) => {
	
	return (
		 <Dialog
			open={props.dialog}
			onClose={() => props.setDialog(false)} 
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		  >
		  <DialogTitle id="alert-dialog-title">Verifique as informações</DialogTitle>
			<DialogContent>
			  <DialogContentText id="alert-dialog-description">
			  {props.dialogMessage}
			  </DialogContentText>
			</DialogContent>
			<DialogActions>
			  <Button onClick={() => props.setDialog(false)} color="primary" autoFocus>
				OK
			  </Button>
			</DialogActions>			
		  </Dialog>
	  )
	
	
} // end AlertDialog
  
  
const mapStateToProps = store => ({
  innerLoading:store.appReducer.innerLoading,
  buttonLoading:store.registerReducer.buttonLoading,
  registerDataStep0:store.registerReducer.registerDataStep0,
  registerDataStep1:store.registerReducer.registerDataStep1,
  registerDataStep2:store.registerReducer.registerDataStep2,
  registerDataStep3:store.registerReducer.registerDataStep3
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AppActions, ...RegisterActions }, dispatch);


// Transition Slide for Dialog SLIDE UP	
const TransitionSlideUp = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;  
});	



/* Extra  Dialog Component START */
export const HelpDialog = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {
		

const backgroundHeaderModal = {
  backgroundImage: "url(" + backgroundHeaderImage + ")",
  backgroundSize:"cover",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"center",
  display:"flex",
  alignItems:"center",
  justifyContent:"center"
}; 		


const screenStyleDialog = {
  alignItems: "center",
  justifyContent: "top",
  width:"100vw",
  minHeight:"100vh",
  display:"flex",
  flexDirection:"column",
  backgroundColor:"#F0F0F0"
}; 
	
// set classes var
// const classes = useStyles()();	

const { 
	innerLoading,
	setInnerLoading,
	setDialogHelp,
	dialogHelp
} = props;	

	return (
	<div>
		  <Dialog fullScreen open={dialogHelp} onClose={()=>setDialogHelp(false)} TransitionComponent={TransitionSlideUp}>
		  
			<AppBar style={{position: 'fixed',height:'80px',...backgroundHeaderModal}}>
			  <Toolbar style={{width:"100%"}}>
				  <div style={{marginLeft: "10px",flex: 1,textAlign:"center",display:"flex",justifyContent:"center",alignItems:"center"}}>
					  <img src={logo} alt="Motuum" style={{marginLeft:"auto",marginRight:"auto",width:"40%",height:"auto"}} />
				  </div>
				</Toolbar>
			</AppBar>

			<Box style={{...screenStyleDialog,backgroundColor:"#FFF",paddingLeft:"20px",paddingRight:"20px"}} m={0}>
			
				<Box mt={10} ml={0} mr={0}>
					
					<Box pt={2}>

						<SupportComponent simple="true" screen="register" setDialog={setDialogHelp}  dialog={dialogHelp} />
						
						<Box style={{backgroundColor:"transparent",display:"flex",flexDirection:"row",justifyContent:"center"}} ml={0} mr={0} mb={5} mt={0} pl={7} pr={7}>						
							
							<Button color="primary" variant="outlined" style={{width:"100%",height:"54px",borderRadius:"10px",fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px",}} onClick={()=>setDialogHelp(false)}>
							 Fechar
							</Button>		
								
						</Box>							

					</Box>
			
				</Box>				
			
			</Box>

		  </Dialog>
		</div>
		
	);
	
});


export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(Profile);
 
