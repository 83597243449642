import './MainLoginForgotPassword.css';
import React,{useState,useEffect,Fragment} from 'react';
import {Link,useNavigate} from 'react-router-dom';

import PropTypes from 'prop-types';
import { CSSTransition } from "react-transition-group";

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

import InputMask from "react-input-mask";
// // import LiveChat from 'react-livechat'

// Material UI Import
import { Box,CircularProgress,Typography,Button,IconButton,TextField,FormControl,Divider,InputAdornment,AppBar,Toolbar  } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';

import Slide from '@mui/material/Slide';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LockIcon from '@mui/icons-material/Lock';

import NiceInputPassword from 'react-nice-input-password';

import {useComponentWillMount} from '../../Utils/customFunctions';
import Header from '../../Components/Layout/Header';
import If from '../../Components/Common/If';
import {SupportComponent} from '../Support/SupportComponentHeader';

// import logo from '../../Assets/Images/logoTypeWOslogan.svg'; // Tell Webpack this JS file uses this image
import logo from '../../Assets/Images/logo_motuum-white.svg'; // Tell Webpack this JS file uses this image
import helpIcon from '../../Assets/Images/help-icon-blue.svg'; // Tell Webpack this JS file uses this image
import backgroundHexagon from '../../Assets/Images/backgroundHexagon.png'; // Tell Webpack this JS file uses this image
import backgroundHeaderImage from '../../Assets/Images/backgroundHeader.png'; // Tell Webpack this JS file uses this image


// Load Actions
import * as AppActions from '../../Store/Actions/App'
import * as LoginActions from '../../Store/Actions/Login'

const RecoverPasswordStep2 = props => {



const screenStyle = {
  alignItems: "stretch",
  justifyContent: "top",
  width:"100vw",
  height:"100vh",
  display:"flex",
  flexDirection:"column",
  backgroundImage: "url(" + backgroundHexagon + ")",
  backgroundSize:"cover",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"center"
}; 

const backgroundHeader = {
  backgroundImage: "url(" + backgroundHeaderImage + ")",
  backgroundSize:"cover",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"center"
}; 


const screenStyleLoading = {
  alignItems: "middle",
  justifyContent: "center",
  width:"100vw",
  height:"100vh",
  display: "flex",
  flexDirection: "column"
}; 

const styles = {
  indicator: {
    backgroundColor: '#A255A0',
  },
};

// set classes var
// const classes = useStyles()();

// set vars from props
// Remember to add those who come from Redux
const { 
	buttonLoading,
	setButtonLoading,
	recoverPasswordUpdate,
	userData
} = props;

// set Use of history for PUSH 
let navigate = useNavigate();

// Set internal state
const [contentVisible,setContentState] = useState(false);
const [value, setValue] = useState(0); // state for tab

const [dialog, setDialog] = useState(false); // state for tab
const [dialogMessage, setDialogMessage] = useState(""); // state for tab
const [snack,setSnack] = useState(false); // state for tab
const [snackMessage, setSnackMessage] = useState(""); // state for tab

const [password, setPassword] = useState(""); // state for input fields
const [passwordConfirm, setPasswordConfirm] = useState(""); // state for input fields

// password view control
const [showPassword, setShowPassword] = useState(false);
const handleClickShowPassword = () => setShowPassword(!showPassword);
const handleMouseDownPassword = () => setShowPassword(!showPassword);

const [dialogHelp, setDialogHelp] = useState(false); // state for tab

const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
const handleClickShowPasswordConfirm = () => setShowPasswordConfirm(!showPasswordConfirm);
const handleMouseDownPasswordConfirm = () => setShowPasswordConfirm(!showPasswordConfirm);

const updatePasswordAction = () => {
	
	setButtonLoading(true);

	// Check if Email is filled
	if (!password || !passwordConfirm) {		
		setDialogMessage("Preencha todos os campos para prosseguir.");
		setDialog(true);
		setButtonLoading(false);		
		return false;		
	} // end if


	// Check if Email is filled
	if (password != passwordConfirm) {		
		setDialogMessage("Verifique as senhas digitadas. Elas devem ser iguais.");
		setDialog(true);
		setButtonLoading(false);		
		return false;		
	} // end if
	
	// set data
	var data = { 
		password:password
	};

	var props = {
		navigate:navigate,
		setDialogMessage:setDialogMessage,
		setDialog:setDialog	,
		setSnack:setSnack,
		setSnackMessage:setSnackMessage
	}		
	// Activate Profile Generate
	recoverPasswordUpdate(data,props);
	
} // end updatePasswordAction


/* Passsword Strenght bar START*/
const levelBarCss = (level) => ({
  height: "8px",
  width: level > 0 ? `${(100 / 4) * level}%` : "100%",
  marginBottom: 10,
  transition: "width 0.5s ease",
  backgroundColor: ["#EFEFEF", "red", "orange", "yellow", "green"][level],
  borderRadius: 0
});

const CustomLevelBar = (levels) => <div style={levelBarCss(levels)} />;

 const securityLevels = [
      {
        descriptionLabel: <Typography>1 number</Typography>,
        validator: /.*[0-9].*/
      },
      {
        descriptionLabel: <Typography>1 lowercase letter</Typography>,
        validator: /.*[a-z].*/
      },
      {
        descriptionLabel: <Typography>1 uppercase letter</Typography>,
        validator: /.*[A-Z].*/
      },
      {
        descriptionLabel: <Typography>6 of length</Typography>,
        validator: /^.{6,}$/
      }
    ];
/* Passsword Strenght bar END*/

const handleKeypress = e => {
	
	 //it triggers by pressing the enter key  
	if (e.key === "Enter") {
	  updatePasswordAction();
	}

};


// Set functions
const handleChange = (event) => {
	
//const newValue = "A";
const newValue = event.value;
setPassword(newValue);
};

// Run after mount component
useEffect(() => {
 
 // Avoid user to go back on browser or hardware android butto
 /* window.navigateState(null, document.title, window.location.href);
  window.addEventListener('popstate', function(event) {
    window.navigateState(null, document.title, window.location.href);
  });*/

 // set content on
 setContentState(true);

  // set scroll to top
  window.scrollTo(0, 1);
    
  // Runs when UNMOUNT component
  return () => {
    // unmounted  component
  };
  
}, []); // end useEffect


// Will Mount Component HOOK
useComponentWillMount(() => {
	
    // Set session of app
  var sessionCheck = sessionStorage.getItem('@motuum/appLaunched');
  
  // if session is not created, redirect to bootstrap. (avoid direct access using url)
  if (!sessionCheck) {
	  navigate("/");
  } // end if	
  
    // Set session of app
  var sessionRecover = sessionStorage.getItem('@motuum/recoverToken');  
  // Need to exist recover token to stay on this screen
  if (!sessionRecover) {
	  navigate("/forgotpassword");
  } // end if    
      
}, []); // end useComponentWillMount

	return (
	<Fragment>
						
			<CSSTransition in={contentVisible} timeout={1000} classNames="fade"	unmountOnExit>	
			
				<Box className="mainProfile" style={{...screenStyle}} m={0}>
							
						<Box style={{height:"210px",alignItems:"center",display:"flex",backgroundColor:"#003CFA",...backgroundHeader}}>	
						
							<Box style={{flex:1,alignItems:"center",display:"flex",marginTop:"9%"}}>						
									<img src={logo} alt="Logomarca Motuum" style={{marginLeft:"auto",marginRight:"auto",width:"76%",height:"auto"}} />
							</Box>	
							
						</Box>							
						
						<Box style={{flex:1,backgroundColor:"transparent",display:"flex",flexDirection:"column",alignItems:"top",marginBottom:"120px",paddingLeft:"55px",paddingRight:"55px"}}>	
							
							<Box style={{marginTop:"40px",marginBottom:"20px",lineHeight:"38px",}}>					
								<div style={{fontFamily:"Roboto",fontWeight:"100",fontSize:"42px",color:"#003CFA"}}>
									  NOVA SENHA
								</div>		
								
							</Box>	
								
							<Box style={{flexGrow: 1,backgroundColor: "transparent" }} ml={0} mr={0} style={{flex:1}}>
																 
										<NiceInputPassword
												  placeholder="Nova senha"
												  InputComponent={TextField}
												  InputComponentProps={{
													onKeyPress:handleKeypress,  
													type:showPassword ? "text":"password", 
													style:{ margin: 0,marginTop:"10px",marginLeft:0,marginRight:0,backgroundColor:"#fff",width:"100%" },
													variant: "outlined",
													InputProps: {
													  startAdornment: <LockIcon style={{marginRight:"10px",color:"#ccc"}} />,
													  endAdornment: <IconButton style={{marginRight:"-10px"}} aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} >
													  {showPassword ? <Visibility /> : <VisibilityOff />}
													  </IconButton>
													}
												  }}
												  showSecurityLevelBar
												  renderLevelBarComponent={CustomLevelBar}
												  onChange={handleChange}
												  value={password}
												  securityLevels={securityLevels}
												/>		
										 
										<TextField
										  style={{ margin: 10,marginLeft:0,marginRight:0,backgroundColor:"#fff",width:"100%"}}
										  placeholder="Confirmar senha"
										  variant="outlined"
										  type={showPasswordConfirm ? "text":"password"}
										  onKeyPress={handleKeypress}
										  value={passwordConfirm}
										  onChange={(event) => {
											  setPasswordConfirm(event.target.value)
										  }}										  
										  InputProps={{
											  startAdornment: (
												<InputAdornment position="start">
												  <LockIcon style={{color:"#ccc"}} />
												</InputAdornment>
											  ),
											   endAdornment: (<IconButton style={{marginRight:"-10px"}} aria-label="toggle password visibility" onClick={handleClickShowPasswordConfirm} onMouseDown={handleMouseDownPasswordConfirm} >
											  {showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
											  </IconButton>)
											}}	
										 />
									  
										<Button disabled={buttonLoading} variant="contained" color="primary" size="large" style={{width:"100%",borderRadius:"10px",height:"53px",marginTop:"10px",backgroundColor:buttonLoading ? "#AAA":"#555555"}} onClick={()=> updatePasswordAction() }>	
										
										{ !buttonLoading &&
										   <div>SALVAR</div>
										}
										
										{ buttonLoading &&
										   <CircularProgress size={30} color="inherit" />
										}	
										
										</Button>						
						
						
									<div style={{marginTop:"20px",display:"flex",flexDirection:"column"}}>	
									
										 <Button color="secondary" style={{marginTop:"10px",marginBottom:"0px"}} onClick={() => navigate("/login") }>Cancelar recuperação de senha</Button>
																			
									</div>						
						
							  
							</Box>
								
						</Box>
						

						<Box style={{backgroundColor:"transparent",display:"flex",width:"100%",alignItems:"center",position:"fixed",bottom:0,height:"80px",backgroundColor:"#ffffff",boxShadow: "0px -10px 15px 0px #0000001A"}} onClick={()=>setDialogHelp(true)}>		

							<Box style={{flex:1,display:"flex",flexDirection:"row",justifyContent:"space-around"}}>
									
								<Box style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>						
										
											<div style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
												<img src={helpIcon} alt="Ajuda" style={{width:"25px",height:"auto"}} />
											</div>
										  
											<div style={{marginLeft:"5px",paddingTop:"3px",fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px",color:"#003cfa",}}>
											  Ajuda
											</div>	
											
						
								</Box>	

							</Box>								
								
						</Box>							
						
								
				</Box>
			
			</CSSTransition> 
			

			{/*<LiveChat license={13444314} />*/}					
			
			<HelpDialog
				dialogHelp={dialogHelp}
				setDialogHelp={setDialogHelp}
			/>			
						
			
			<AlertDialog 
				dialog={dialog}
				setDialog={setDialog}
				dialogMessage={dialogMessage}
			/>

			<SnackNotification 
				snack={snack}
				setSnack={setSnack}
				snackMessage={snackMessage}
			/>
			
				
	</Fragment>
)};

const FormEmail = (props) => {
	
const [email, setEmail] = useState(props.email); // state for input fields
	
	return (	
		<form id="formLoginEmail"  noValidate autoComplete="off">

			<TextField
			  id="loginEmail"
			  label="Email"
			  style={{ margin: 10,marginLeft:0,marginRight:0,backgroundColor:"#fff",width:"94%"}}
			  placeholder="Informe o email para cadastro"
			  required
			  size="small"
			  variant="outlined"
			  type="email"
			  value={email}
			  onChange={(event) => {
				  setEmail(event.target.value)
			  }}									  
			/>
			
			<div style={{display:"flex",flexDiredction:"row"}}>
			
				<Button color="secondary" style={{width:"45%",height:"48px",marginTop:"10px"}} component={Link} to="/login">Recuperar depois</Button>

				<Button variant="contained"  color="primary" size="large" style={{width:"55%",height:"48px",marginTop:"10px",backgroundColor:"#555555",color:"#FFF"}} onClick={() => props.sendCodeCheck("email",{email:email}) }>
					{ !props.sendingCode &&
					   <div>Enviar código</div>
					}
					
					{ props.sendingCode &&
					   <CircularProgress size={30} color="inherit" />
					}	
				</Button>

			</div>
		  
		</form>	
	);
	
	
} // end export const

const FormMobilePhone = (props) => {
	
	const [mobilePhone, setMobilePhone] = useState(props.mobilePhone); // state for input fields
	
	return (	
		<form id="formLoginPhone" noValidate autoComplete="off">

			<InputMask
            mask="(99) 99999-9999"
            value={mobilePhone}
			  onChange={(event) => {
				  setMobilePhone(event.target.value)
			  }}
          >
		  {(inputProps) => (
			<TextField
			  label="Telefone Celular"
			  style={{ margin: 10,marginLeft:0,marginRight:0,backgroundColor:"#fff",width:"94%"}}
			  placeholder="Telefone no formato (XX) 9XXXX-XXXX"
			  required
			  size="small"
			  variant="outlined"
			 />
		  )}
        </InputMask>
										
			<div style={{display:"flex",flexDiredction:"row"}}>
			
				<Button color="secondary" style={{width:"45%",height:"48px",marginTop:"10px"}} component={Link} to="/login">Recuperar depois</Button>

				<Button variant="contained"  color="primary" size="large" style={{width:"55%",height:"48px",marginTop:"10px",backgroundColor:"#555555",color:"#FFF"}} onClick={() => props.sendCodeCheck("mobilePhone",{mobilePhone:mobilePhone})}>
					{ !props.sendingCode &&
					   <div>Enviar código</div>
					}
					
					{ props.sendingCode &&
					   <CircularProgress size={30} color="inherit" />
					}	
				</Button>

			</div>
			
		  
		</form>	
	);
	
} // end export const

const AlertDialog = (props) => {
	
	return (
		 <Dialog
			open={props.dialog}
			onClose={() => props.setDialog(false)} 
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		  >
		  <DialogTitle id="alert-dialog-title">Verifique as informações</DialogTitle>
			<DialogContent>
			  <DialogContentText id="alert-dialog-description">
			  {props.dialogMessage}
			  </DialogContentText>
			</DialogContent>
			<DialogActions>
			  <Button onClick={() => props.setDialog(false)} color="primary" autoFocus>
				OK
			  </Button>
			</DialogActions>			
		  </Dialog>
	  )
	
	
} // end AlertDialog

const SnackNotification = (props) => {
	
	//props.snackMessage = "Código enviado com sucesso! Aguarde alguns segundos para receber...";

	return (
		<Snackbar
			anchorOrigin={{
			  vertical: 'bottom',
			  horizontal: 'left',
			}}
			open={props.snack}
			autoHideDuration={4000}
			onClose={() => props.setSnack(false)}
		  >
		    <div style={{borderRadius:10,borderWidth:"1px",borderColor:"#222",padding:10,display:"flex",flexDirection:"row",alignItems:"center",flex:1,backgroundColor:"#666"}}>
				
				<div style={{flex:6,fontSize:"14px",textAlign:"left",color:"#fff"}}>{props.snackMessage}</div>
				
				<IconButton style={{flex:1,color:"#fff"}} size="small" aria-label="close" color="inherit" onClick={() => props.setSnack(false)}>
				  <CloseIcon fontSize="small" />
				</IconButton>
			  			
			</div>
		  </Snackbar>
	  )	
	
} // end AlertDialog

 
 
const mapStateToProps = store => ({
  buttonLoading:store.registerReducer.buttonLoading,
  userData:store.appReducer.userData
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AppActions, ...LoginActions }, dispatch);


// Transition Slide for Dialog SLIDE UP	
const TransitionSlideUp = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;  
});	


/* Extra  Dialog Component START */
export const HelpDialog = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {
		
const backgroundHeaderModal = {
  backgroundImage: "url(" + backgroundHeaderImage + ")",
  backgroundSize:"cover",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"center",
  display:"flex",
  alignItems:"center",
  justifyContent:"center"
}; 		


const screenStyleDialog = {
  alignItems: "center",
  justifyContent: "top",
  width:"100vw",
  minHeight:"100vh",
  display:"flex",
  flexDirection:"column",
  backgroundColor:"#F0F0F0"
}; 
	
// set classes var
// const classes = useStyles()();	

const { 
	innerLoading,
	setInnerLoading,
	setDialogHelp,
	dialogHelp
} = props;	

	return (
	<div>
		  <Dialog fullScreen open={dialogHelp} onClose={()=>setDialogHelp(false)} TransitionComponent={TransitionSlideUp}>
		  
			<AppBar style={{position: 'fixed',height:'80px',...backgroundHeaderModal}}>
			  <Toolbar style={{width:"100%"}}>
					<div style={{marginLeft: "10px",flex: 1,textAlign:"center",display:"flex",justifyContent:"center",alignItems:"center"}}>
					  <img src={logo} alt="Motuum" style={{marginLeft:"auto",marginRight:"auto",width:"40%",height:"auto"}} />
				  </div>
				</Toolbar>
			</AppBar>

			<Box style={{...screenStyleDialog,backgroundColor:"#FFF",paddingLeft:"20px",paddingRight:"20px"}} m={0}>
			
				<Box mt={10} ml={0} mr={0}>
					
					<Box pt={2}>

						<SupportComponent simple="true" screen="login" setDialog={setDialogHelp}  dialog={dialogHelp} />
						
						<Box style={{backgroundColor:"transparent",display:"flex",flexDirection:"row",justifyContent:"center"}} ml={0} mr={0} mb={5} mt={0} pl={7} pr={7}>						
							
							<Button color="primary" variant="outlined" style={{width:"100%",height:"54px",borderRadius:"10px",fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px",}} onClick={()=>setDialogHelp(false)}>
							 Fechar
							</Button>		
								
						</Box>							

					</Box>
			
				</Box>				
			
			</Box>

		  </Dialog>
		</div>
		
	);
	
});

export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(RecoverPasswordStep2);
 