import React,{useState,useEffect,Fragment} from 'react';

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

import { Box,Divider,Typography,Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';

import Info from '@mui/icons-material/ErrorOutline';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AccountTreeIcon from '@mui/icons-material/AccountTree'; 
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ScheduleIcon from '@mui/icons-material/Schedule';
import WarningIcon from '@mui/icons-material/Warning';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// Load Actions
import * as AppActions from '../../Store/Actions/App'

const mapStateToProps = store => ({
  innerLoading:store.appReducer.innerLoading,
  dialog:store.appReducer.dialog
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AppActions }, dispatch);
    
// Transition Slide for Dialog SLIDE UP	
const TransitionSlideUp = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;  
});	
	

/* Extra  Dialog Component START */
export const ImageDetailDialog = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {
		

const screenStyleDialog = {
  justifyContent: "center",
  width:"100vw",
  backgroundColor:"#FFF"
}; 
	
// set classes var
// const classes = useStyles()();	

const { 
	innerLoading,
	setInnerLoading,
	setDialog,
	SelectedOngoingServiceOrder,
	dialog
} = props;	


var formatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

	return (
	<div>
		  <Dialog fullScreen open={dialog.target == 'imageDetail' && dialog.status} onClose={()=>{setDialog('imageDetail',false);setDialog('extraDetail',true)}} TransitionComponent={TransitionSlideUp}>
			<AppBar style={{position: 'fixed'}}>
			  <Toolbar>
				<IconButton edge="start" color="inherit" onClick={() => {setDialog('imageDetail',false);setDialog('extraDetail',true)}} aria-label="close">
				  <CloseIcon />
				</IconButton>
				<Typography variant="h6" style={{marginLeft: "10px",flex: 1}}>
				  Visualizando imagem
				</Typography>
				</Toolbar>
			</AppBar>

			<Box style={{...screenStyleDialog}} m={0}>

					<Box ml={0} mr={0} mb={1} mt={0} pt={8} style={{flex:1,alignItems:"stretch",justifyContent:"center",display:"flex",flexDirection:"column",backgroundColor:"#000"}}>
							<img src={props.image} style={{width:"100%",height:"auto"}} />							
					</Box>
	
					<Divider style={{marginBottom:"5px"}} />
						
					<Box style={{display:"flex",flexDirection:"row",justifyContent:"center"}} ml={1} mr={1} mb={5} mt={2}>						
						
						<Button color="secondary" variant="outlined" style={{flex:1,height:"48px",marginRight:"10px"}} onClick={()=>{setDialog('imageDetail',false);setDialog('extraDetail',true)} }>
						 Fechar
						</Button>		
													
					</Box>			
			
				
			</Box>
			
			
		  </Dialog>
		</div>
	);
	
});



