import './MainProfileManage.css';
import React,{useState,useEffect,Fragment} from 'react';
import {Link,useNavigate} from 'react-router-dom';

import PropTypes from 'prop-types';
import { CSSTransition } from "react-transition-group";

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

// // import LiveChat from 'react-livechat'

import { Box,Backdrop,CircularProgress,Typography,Button,InputAdornment,IconButton,TextField,Divider } from '@mui/material';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import WarningIcon from '@mui/icons-material/Warning';
import LockIcon from '@mui/icons-material/Lock';
import Slide from '@mui/material/Slide';
import Fade from '@mui/material/Fade';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Danger from '@mui/icons-material/Error';

import helpIcon from '../../Assets/Images/help-icon-blue.svg'; 
import deviceIcon from '../../Assets/Images/deviceIcon.svg'; 
import chatComplain from '../../Assets/Images/iconComplain-blue.svg'; 
import chatIcon from '../../Assets/Images/iconChat-blue.svg';
import chatSupportIcon from '../../Assets/Images/chatIcon.svg'; 
import profileIcon from '../../Assets/Images/profileIcon.svg';
import userProfileIcon from '../../Assets/Images/userProfileIcon.svg';
import passwordProfileIcon from '../../Assets/Images/passwordProfileIcon.svg';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import InputMask from "react-input-mask";

import NiceInputPassword from 'react-nice-input-password';

import {useComponentWillMount} from '../../Utils/customFunctions';
import Header from '../../Components/Layout/Header';
import If from '../../Components/Common/If';
import {SupportComponent} from '../Support/SupportComponentHeader';

import logo from '../../Assets/Images/logo_motuum-white.svg';
import backgroundHexagon from '../../Assets/Images/backgroundHexagon.png'; 
import backgroundHeaderImage from '../../Assets/Images/backgroundHeader.png'; 

// Loading  Tab Content components
import {PasswordChangeForm} from './MainProfilePassword';

// Load Actions
import * as AppActions from '../../Store/Actions/App'

const ProfileManage = props => {



const screenStyle = {
  alignItems: "stretch",
  justifyContent: "top",
  width:"100vw",
  height:"100vh",
  display:"flex",
  flexDirection:"column",
  backgroundImage: "url(" + backgroundHexagon + ")",
  backgroundSize:"cover",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"center"
}; 

const screenStyleLoading = {
  alignItems: "middle",
  justifyContent: "center",
  width:"100vw",
  height:"100vh",
  display: "flex",
  flexDirection: "column"
}; 

// set classes var
// const classes = useStyles()();

// Set functions
const handleChange = (event, newValue) => {
setValue(newValue);
};

// Set TABPANEL
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
	  hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


// set vars from props
// Remember to add those who come from Redux
const { 
	innerLoading,
	setInnerLoading,
	setDialog,
	dialog
} = props;

// set Use of history for PUSH 
let navigate = useNavigate();

// Set internal state
const [contentVisible,setContentState] = useState(false);
const [loadingMessage,setLoadingMessage] = useState("Carregando...");
const [value, setValue] = useState(0); // state for tab

const [dialogAlert, setAlertDialog] = useState(false); // state for tab
const [dialogMessage, setDialogMessage] = useState(""); // state for tab
const [dialogTitle, setDialogTitle] = useState("Verifique as informações"); // state for tab
const [alert, setAlert] = useState(false); // state for tab

const [dialogHelp, setDialogHelp] = useState(false); // state for tab

// Run after mount component
useEffect(() => {
	
	// Retrieve User Data Obj. 
	var result = localStorage.getItem("@motuum/userData", undefined,true);
	var userData = JSON.parse(result);  
  
	  // If token dont exist, redirect to onboarding or register
	  if (!userData) {	  
		navigate("/");  
	  } // end if
	  // If token exist, redirect to HOME
	  else {
		  if (!userData.userToken) {
			navigate("/");
		  } // end if
	  } // end else    
	
  console.log("Montou o componente MainHome....");
 
 // Set Content Loading ON
 //setInnerLoading(true);
 setContentState(true);

  // set scroll to top
  window.scrollTo(0, 1);
    
  // Runs when UNMOUNT component
  return () => {
   
  };
  
}, [props.open]); // end useEffect


// Will Mount Component HOOK
useComponentWillMount(() => {
	
    // Set session of app
  var sessionCheck = sessionStorage.getItem('@motuum/appLaunched');
  
  // if session is not created, redirect to bootstrap. (avoid direct access using url)
  if (!sessionCheck) {
	  navigate("/");
  } // end if	
  
      
}, []); // end useComponentWillMount


	return (
	<Fragment>
			
			<Header title="Gerenciar Perfil" buttons={{support:false,menu:false,back:true,close:false,fakeRight:true}} screen="ProfileManage" />
				
			{/* Inner Loading */}
			<If test={!contentVisible}>
					<Box className="loadingContent" style={{...screenStyleLoading}}>
							<Box mt={0} mx={0}>								
								<CircularProgress /> 
								<div> Carregando...Aguarde!</div>								
							</Box>	
					</Box>
			</If>	
		

			<Fade in={contentVisible} timeout={1000}  mountOnEnter unmountOnExit>			
			
				<Box className="mainHome" style={{...screenStyle}} mt={9}>
				
						<Box style={{marginTop:"30px",backgroundColor:"transparent",alignItems:"center",display:"flex",flexDirection:"column"}} mt={props.marginTop} pl={5} pr={5}>

								<Box style={{marginTop:"20px",marginBottom:"20px"}}>						
									<div style={{fontFamily:"Roboto",fontWeight:"100",fontSize:"42px",color:"#003CFA",lineHeight:"38px"}}>	
										MINHA SENHA
									</div>								 	
								</Box> 	
								
						</Box>						
						
						<Box style={{backgroundColor:"transparent",alignItems:"center",justifyContent:"center",display:"flex",flexDirection:"column"}} pl={5} pr={5}>			
								
							<PasswordChangeForm />	
								
						</Box>	
						

						<Box style={{backgroundColor:"transparent",display:"flex",width:"100%",alignItems:"center",position:"fixed",bottom:0,height:"80px",backgroundColor:"#ffffff",boxShadow: "0px -10px 15px 0px #0000001A"}} onClick={()=>setDialogHelp(true)}>		

							<Box style={{flex:1,display:"flex",flexDirection:"row",justifyContent:"space-around"}}>
									
								<Box style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>						
										
											<div style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
												<img src={helpIcon} alt="Ajuda" style={{width:"25px",height:"auto"}} />
											</div>
										  
											<div style={{marginLeft:"5px",paddingTop:"3px",fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px",color:"#003cfa",}}>
											  Ajuda
											</div>	
											
						
								</Box>	

							</Box>								
								
						</Box>										
						
						{/*<LiveChat license={13444314} />*/}					
						
						<HelpDialog 				
							dialogHelp={dialogHelp}
							setDialogHelp={setDialogHelp}
						/>							
						
				</Box>			
			
			
			</Fade>  
			
	</Fragment>
)}; 

const mapStateToProps = store => ({
  innerLoading:store.appReducer.innerLoading,
  UserData:store.appReducer.userData,
  dialog:store.appReducer.dialog
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AppActions }, dispatch);  

// Transition Slide for Dialog SLIDE UP	
const TransitionSlideUp = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;  
});	



/* Extra  Dialog Component START */
export const HelpDialog = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {
		
		
const backgroundHeaderModal = {
  backgroundImage: "url(" + backgroundHeaderImage + ")",
  backgroundSize:"cover",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"center",
  display:"flex",
  alignItems:"center",
  justifyContent:"center"
}; 		

const screenStyleDialog = {
  alignItems: "center",
  justifyContent: "top",
  width:"100vw",
  minHeight:"100vh",
  display:"flex",
  flexDirection:"column",
  backgroundColor:"#F0F0F0"
}; 
	
// set classes var
// const classes = useStyles()();	

const { 
	innerLoading,
	setInnerLoading,
	setDialogHelp,
	dialogHelp
} = props;	

	return (
	<div>
		  <Dialog fullScreen open={dialogHelp} onClose={()=>setDialogHelp(true)} TransitionComponent={TransitionSlideUp}>
		  
			<AppBar style={{position: 'fixed',height:'80px',...backgroundHeaderModal}}>
			  <Toolbar style={{width:"100%"}}>
				  <div style={{marginLeft: "10px",flex: 1,textAlign:"center",display:"flex",justifyContent:"center",alignItems:"center"}}>
					  <img src={logo} alt="Motuum" style={{marginLeft:"auto",marginRight:"auto",width:"40%",height:"auto"}} />
				  </div>
				</Toolbar>
			</AppBar>

			<Box style={{...screenStyleDialog,backgroundColor:"#FFF",paddingLeft:"20px",paddingRight:"20px"}} m={0}>
			
				<Box mt={10} ml={0} mr={0}>
					
					<Box pt={2}>

						<SupportComponent simple="true" screen="register" setDialog={setDialogHelp}  dialog={dialogHelp} />
						
						<Box style={{backgroundColor:"transparent",display:"flex",flexDirection:"row",justifyContent:"center"}} ml={0} mr={0} mb={5} mt={0} pl={7} pr={7}>						
							
							<Button color="primary" variant="outlined" style={{width:"100%",height:"54px",borderRadius:"10px",fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px",}} onClick={()=>setDialogHelp(false)}>
							 Fechar
							</Button>		
								
						</Box>							

					</Box>
			
				</Box>				
			
			</Box>

		  </Dialog>
		</div>
		
	);
	
});




export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(ProfileManage);

