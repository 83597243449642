import './MainProfile.css';
import React,{useState,useEffect,Fragment} from 'react';
import {Link,useNavigate} from 'react-router-dom';

import PropTypes from 'prop-types';
import { CSSTransition } from "react-transition-group";

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

// import LiveChat from 'react-livechat'

// Material UI Import
import { Box,CircularProgress,Typography,Button,IconButton,TextField,FormControl  } from '@mui/material';

import Slide from '@mui/material/Slide';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from '@mui/icons-material/Warning';


import {useComponentWillMount} from '../../Utils/customFunctions';
import Header from '../../Components/Layout/Header';
import If from '../../Components/Common/If';
import {SupportComponent} from '../Support/SupportComponentHeader';

import checked from '../../Assets/Images/checked.svg'; // Tell Webpack this JS file uses this image
import checkGreen from '../../Assets/Images/check_green.svg'; // Tell Webpack this JS file uses this image
import backgroundHexagon from '../../Assets/Images/backgroundHexagon.png'; // Tell Webpack this JS file uses this image
import backgroundHeaderImage from '../../Assets/Images/backgroundHeader.png'; // Tell Webpack this JS file uses this image
import helpIcon from '../../Assets/Images/help-icon-blue.svg'; // Tell Webpack this JS file uses this image
import logo from '../../Assets/Images/logo_motuum-white.svg'; // Tell Webpack this JS file uses this image

// Load Actions
import * as AppActions from '../../Store/Actions/App'
import * as RegisterActions from '../../Store/Actions/Register'

const ProfileStep4 = props => {


const screenStyle = {
  alignItems: "stretch",
  justifyContent: "top",
  width:"100vw",
  minHeight:"100vh",
  display:"flex",
  flexDirection:"column",
  backgroundImage: "url(" + backgroundHexagon + ")",
  backgroundSize:"cover",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"fixed"
}; 

const screenStyleLoading = {
  alignItems: "middle",
  justifyContent: "center",
  width:"100vw",
  height:"100vh",
  display: "flex",
  flexDirection: "column"
}; 

const styles = {
  indicator: {
    backgroundColor: '#A255A0',
  },
};

// Set functions
const handleChange = (event, newValue) => {
setValue(newValue);
};


// set classes var
// const classes = useStyles()();


// set vars from props
// Remember to add those who come from Redux
const { 
	registerDataStep0,
	registerDataStep1,
	registerDataStep2,
	registerDataStep3,
	innerLoading,
	setInnerLoading,
	buttonLoading,
	setButtonLoading,
	setRegister,
	processStep
} = props;

// set Use of history for PUSH 
let navigate = useNavigate();


// Set internal state
const [contentVisible,setContentState] = useState(false);
const [loadingMessage,setLoadingMessage] = useState("Carregando...");
const [value, setValue] = useState(0); // state for tab

const [validated,setValidated] = React.useState(registerDataStep3.validated ? true: false);

const [dialogHelp, setDialogHelp] = useState(false); // state for tab
  
// Run after mount component
useEffect(() => {
	
 // Avoid user to go back on browser or hardware android butto
 /* window.navigateState(null, document.title, window.location.href);
  window.addEventListener('popstate', function(event) {
    window.navigateState(null, document.title, window.location.href);
  });*/

 // Set Content Loading ON
 //setInnerLoading(true);
 setContentState(true);
 
 // Simulate Delay
 setTimeout(function() {
	 
	  // Set Content Loading OFF
	  //setInnerLoading(false);
	  
	  // Set Content Ready to show
	  //setContentState(true);
	
 },3000);

  // set scroll to top
  window.scrollTo(0, 1);
    
  // Runs when UNMOUNT component
  return () => {
    console.log("Desmontou o componente MainHome...");
  };
  
}, []); // end useEffect


// Will Mount Component HOOK
useComponentWillMount(() => {
	
    // Set session of app
  var sessionCheck = sessionStorage.getItem('@motuum/appLaunched');
  
  // if session is not created, redirect to bootstrap. (avoid direct access using url)
  if (!sessionCheck) {
	  navigate("/");
  } // end if	
      
}, []); // end useComponentWillMount

const handleClose = () => {
	setInnerLoading(false);
};

	return (
	<Fragment>
			
			<Header title="Perfil criado" buttons={{support:false,menu:false,close:false,back:false,leftFake:true}} /> 
			
			<CSSTransition in={contentVisible} timeout={1000} classNames="fade"	unmountOnExit>	
			
				<Box className="mainProfile" style={{...screenStyle}} m={0} pb={15}>
							
						<Box style={{marginBottom:"20px",backgroundColor:"transparent",alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"column"}} mt={14} pl={7} pr={7} >

								<div>
									<img src={checkGreen} atl="Sucesso" style={{marginLeft:"auto",marginRight:"auto",width:"25vw"}} />	
								</div>

								<Box mt={2} mb={1}>						
									<div style={{fontFamily:"Roboto",fontWeight:"100",fontSize:"42px",lineHeight:"38px",color:"#003CFA"}}>
										  PERFIL CRIADO COM SUCESSO!
									</div>								 	
								</Box>	
								
								<div style={{fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px",color:"#636466",marginTop:"20px"}}>Agora você está conectado a Motuum e terá acesso a milhares de profissionais de TI e telecomunicação qualificados.</div>
														
									
								{ validated && 
									<div style={{fontFamily:"Roboto",fontWeight:"bold",fontSize:"16px",color:"#003CFA",marginTop:"20px"}}>Você está habilitado para solicitar profissionais.</div>	
								}

								{ !validated && 
									<div style={{fontFamily:"Roboto",fontWeight:"bold",fontSize:"16px",color:"red",marginTop:"20px"}}>Você não está habilitado para solicitar profissionais. Valide seu perfil.</div>	
								}								
								
						</Box>
				
						
						<Box pl={7} pr={7} style={{backgroundColor:"transparent",display:"flex",alignItems:"center"}}>							
								
							<div style={{flex:1}}>						
									
									<Button  variant="contained" color="primary" size="large" style={{width:"100%",height:"53px",borderRadius:"10px",backgroundColor:"#555555"}} component={Link} to="/home">
									  Entrar
									</Button>	
									
							</div>	
								
						</Box>
						

						<Box style={{backgroundColor:"transparent",display:"flex",width:"100%",alignItems:"center",position:"fixed",bottom:0,height:"80px",backgroundColor:"#ffffff",boxShadow: "0px -10px 15px 0px #0000001A"}} onClick={()=>setDialogHelp(true)}>		

							<Box style={{flex:1,display:"flex",flexDirection:"row",justifyContent:"space-around"}}>
									
								<Box style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>						
										
											<div style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
												<img src={helpIcon} alt="Ajuda" style={{width:"25px",height:"auto"}} />
											</div>
										  
											<div style={{marginLeft:"5px",paddingTop:"3px",fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px",color:"#003cfa",}}>
											  Ajuda
											</div>	
											
						
								</Box>	

							</Box>								
								
						</Box>						
														
				</Box>
			
			</CSSTransition> 
			
	
	{/*<LiveChat license={13444314} />*/}		
			
			<HelpDialog
				dialogHelp={dialogHelp}
				setDialogHelp={setDialogHelp}
			/>
				
	</Fragment>
)};


const mapStateToProps = store => ({
  innerLoading:store.appReducer.innerLoading,
  buttonLoading:store.registerReducer.buttonLoading,
  registerDataStep0:store.registerReducer.registerDataStep0,
  registerDataStep1:store.registerReducer.registerDataStep1,
  registerDataStep2:store.registerReducer.registerDataStep2,
  registerDataStep3:store.registerReducer.registerDataStep3,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AppActions, ...RegisterActions }, dispatch);


// Transition Slide for Dialog SLIDE UP	
const TransitionSlideUp = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;  
});	


/* Extra  Dialog Component START */
export const HelpDialog = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {
		

const backgroundHeaderModal = {
  backgroundImage: "url(" + backgroundHeaderImage + ")",
  backgroundSize:"cover",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"center",
  display:"flex",
  alignItems:"center",
  justifyContent:"center"
}; 		
	


const screenStyleDialog = {
  alignItems: "center",
  justifyContent: "top",
  width:"100vw",
  minHeight:"100vh",
  display:"flex",
  flexDirection:"column",
  backgroundColor:"#F0F0F0"
}; 
	
// set classes var
// const classes = useStyles()();	

const { 
	innerLoading,
	setInnerLoading,
	setDialogHelp,
	dialogHelp
} = props;	

	return (
	<div>
		  <Dialog fullScreen open={dialogHelp} onClose={()=>setDialogHelp(false)} TransitionComponent={TransitionSlideUp}>
		  
			<AppBar style={{position: 'fixed',height:'80px',...backgroundHeaderModal}}>
			  <Toolbar style={{width:"100%"}}>
				  <div style={{marginLeft: "10px",flex: 1,textAlign:"center",display:"flex",justifyContent:"center",alignItems:"center"}}>
					  <img src={logo} alt="Motuum" style={{marginLeft:"auto",marginRight:"auto",width:"40%",height:"auto"}} />
				  </div>
				</Toolbar>
			</AppBar>

			<Box style={{...screenStyleDialog,backgroundColor:"#FFF",paddingLeft:"20px",paddingRight:"20px"}} m={0}>
			
				<Box mt={10} ml={0} mr={0}>
					
					<Box pt={2}>

						<SupportComponent simple="true" screen="register" setDialog={setDialogHelp}  dialog={dialogHelp} />
						
						<Box style={{backgroundColor:"transparent",display:"flex",flexDirection:"row",justifyContent:"center"}} ml={0} mr={0} mb={5} mt={0} pl={7} pr={7}>						
							
							<Button color="primary" variant="outlined" style={{width:"100%",height:"54px",borderRadius:"10px",fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px",}} onClick={()=>setDialogHelp(false)}>
							 Fechar
							</Button>		
								
						</Box>							

					</Box>
			
				</Box>				
			
			</Box>

		  </Dialog>
		</div>
		
	);
	
});

export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(ProfileStep4);
 