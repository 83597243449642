import './MainProfile.css';
import React,{useState,useEffect,Fragment} from 'react';
import {Routes,Route,Link,useNavigate} from 'react-router-dom';

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

import {useComponentWillMount} from '../../Utils/customFunctions';

// Load Views
import ProfileRegisterStep0 from './MainProfileRegisterStep0';
import ProfileRegisterStep1 from './MainProfileRegisterStep1';
import ProfileRegisterStep2 from './MainProfileRegisterStep2';
import ProfileRegisterStep3a from './MainProfileRegisterStep3-activate-Step1';
import ProfileRegisterStep3b from './MainProfileRegisterStep3-activate-Step2';
import ProfileRegisterStep4 from './MainProfileRegisterStep4';

// Load Actions
import * as AppActions from '../../Store/Actions/App'

const Profile = props => {


// set vars from props
// Remember to add those who come from Redux
const { 
	innerLoading,
	setInnerLoading
} = props;

// set Use of history for PUSH 
let navigate = useNavigate();
  
// Run after mount component
useEffect(() => {
	
  console.log("Montou o componente MainHome....");
  
  // set scroll to top
  window.scrollTo(0, 1);
    
  // Runs when UNMOUNT component
  return () => {
    console.log("Desmontou o componente MainHome...");
  };
  
}, []); // end useEffect


// Will Mount Component HOOK
useComponentWillMount(() => {
	
    // Set session of app
  var sessionCheck = sessionStorage.getItem('@motuum/appLaunched');
  
  // if session is not created, redirect to bootstrap. (avoid direct access using url)
  if (!sessionCheck) {
	  navigate("/");
  } // end if	
  
      
}, []); // end useComponentWillMount


	return (
	<Fragment>
			
			{/*<Routes>
			  <Route path="/register/" element={<ProfileRegisterStep0 />} />
			  <Route path="/register/step1" element={<ProfileRegisterStep1 />} />
			  <Route path="/register/step2" element={<ProfileRegisterStep2 />} />
			  <Route path="/register/step3/activate/step1" element={<ProfileRegisterStep3a />} />
			  <Route path="/register/step3/activate/step2" element={<ProfileRegisterStep3b />} />
			  <Route path="/register/step4" element={<ProfileRegisterStep4 />} />
			</Routes>*/}			
				
	</Fragment>
)};

const mapStateToProps = store => ({
  innerLoading:store.appReducer.innerLoading
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AppActions }, dispatch);

export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(Profile);
 
