import './App.css';
import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
// import { createTheme  } from '@mui/material/styles';
import { ThemeProvider, createTheme } from "@mui/material/styles";
// import { ThemeProvider } from '@material-ui/styles';

import {
  osVersion,
  osName,
  fullBrowserVersion,
  mobileVendor,
  mobileModel,
  deviceType,
  isBrowser,
  isMobile
} from "react-device-detect";

import withSplashScreen from './Components/Layout/WithSplash';
import Launch from './Screens/Launch';

// Show only open open on a desktop
import backgroundHexagon from './Assets/Images/backgroundHexagon.png'; // Tell Webpack this JS file uses this image
import logo from './Assets/Images/logoTypeWOslogan.svg'; // Tell Webpack this JS file uses this image
const screenStyle = {
  justifyContent: "top",
  backgroundColor:"blue", 
  backgroundSize:"cover",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"center"
}; 

// Set Globa theme
const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#003CFA',
	  //main: '#A255A0',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#999',
      main: '#555',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#FFF',
    },
	
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
});

const App = ( props => {
	
// Put app inside a MOBILE FRAME (if opened on a browser)
if (isBrowser) {	
	return (	
		<ThemeProvider theme={theme}>
		   <div className="App" style={{...screenStyle,height:"100vh",width:"100vw",display:"flex",alignItems:"center",justifyContent:"center"}}>
				<div style={{flex:1,color:"#FFF"}}>
					
					<img src="https://backoffice.motuum.com.br/static/media/logoTypeWOsloganNew.f333c896.svg" alt="Logomarca Motuum" style={{filter:"grayscale(1) brightness(5)",marginLeft:"auto",marginRight:"auto",width:"400px"}} />
					<br /><br />
					<h2> Abra o App Motuum em seu celular. </h2>
					<div style={{marginBottom:"20px"}}> Aponte a câmera de seu celular para o QRCODE abaixo e abra o link </div>
					<img src="http://api.qrserver.com/v1/create-qr-code/?color=000000&amp;bgcolor=FFFFFF&amp;data=https%3A%2F%2Fapp.motuum.com.br&amp;qzone=1&amp;margin=0&amp;size=300x300&amp;ecc=L" alt="qr code" />
					
					<div style={{marginTop:"30px"}}> OU SE PREFERIR, acesse no navegador de seu celular <strong>https://app.motuum.com.br</strong> </div>
					
				</div>
		   </div>
		</ThemeProvider>
	)
} // end if
else {	
	return (
		<ThemeProvider theme={theme}>
		   <div className="App">
				<Router>
					<Launch />
				</Router>
		   </div>
		</ThemeProvider>   
	)
}}

);

export default App;

