import React,{useState,useEffect,Fragment} from 'react';

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

import { Box,Divider,Typography,Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';

// import {Map, Marker, GoogleApiWrapper} from 'google-maps-react';
// import GoogleMapReact from 'google-map-react';

import {APIProvider, Map,Marker} from '@vis.gl/react-google-maps';

import Info from '@mui/icons-material/ErrorOutline';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AccountTreeIcon from '@mui/icons-material/AccountTree'; 
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ScheduleIcon from '@mui/icons-material/Schedule';
import WarningIcon from '@mui/icons-material/Warning';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';
import PlaceIcon from '@mui/icons-material/Place';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import logo from '../../Assets/Images/logo_motuum-white.svg'; // Tell Webpack this JS file uses this image
import backgroundHexagon from '../../Assets/Images/backgroundHexagon.png'; // Tell Webpack this JS file uses this image
import backgroundHeaderImage from '../../Assets/Images/backgroundHeader.png'; // Tell Webpack this JS file uses this image
import backIcon from '../../Assets/Images/backIcon.svg'; // Tell Webpack this JS file uses this image

// Load Actions
import * as AppActions from '../../Store/Actions/App'

const mapStateToProps = store => ({
  innerLoading:store.appReducer.innerLoading,
  dialog:store.appReducer.dialog
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AppActions }, dispatch);
    
// Transition Slide for Dialog SLIDE UP	
const TransitionSlideUp = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;  
});	
	

/* Extra  Dialog Component START */
export const ProviderLocationDialog = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {
		

const backgroundHeaderModal = {
  backgroundImage: "url(" + backgroundHeaderImage + ")",
  backgroundSize:"cover",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"center",
  display:"flex",
  alignItems:"center",
  justifyContent:"center"
}; 	


const backgroundStyle = {
  backgroundImage: "url(" + backgroundHexagon + ")",
  backgroundSize:"cover",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"center"
}; 

const screenStyleDialog = {
  alignItems: "stretch",
  justifyContent: "top",
  width:"100vw",
  minHeight:"100vh",
  display:"flex",
  flexDirection:"column",
  ...backgroundStyle
}; 
	
// set classes var
// const classes = useStyles()();	

const { 
	innerLoading,
	setInnerLoading,
	setDialog,
	SelectedOngoingServiceOrder,
	dialog
} = props;	

const [address,setAddress] = useState("");
const [addressText,setAddressText] = useState("");
const [addressTitle,setAddressTitle] = useState("");
const [coordinates,setCoordinates] = useState(null);

var formatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

// Run after mount component
useEffect(() => {
	
// Set address for STATIC MAP dinamically
let addressText = SelectedOngoingServiceOrder.ServiceOrderAddress.fullAddress;
let addressCoordinate = coordinates ? coordinates.lat+","+coordinates.lng : null;

let finalAddress = "https://maps.googleapis.com/maps/api/staticmap?center="+(!coordinates ? addressText : addressCoordinate)+"&zoom=14&size=303x250&maptype=roadmap&markers=color:blue%7C"+(!coordinates ? addressText : addressCoordinate)+"&key=AIzaSyBdE3uu0p9cJ_961a03aXMo0kGSzuUfTVE";

setAddress(finalAddress);

// Set address Text and Title
setAddressText(addressText);
let addressLabel = SelectedOngoingServiceOrder.ServiceOrderAddress.label;
setAddressTitle(addressLabel);

	
}, []); // end useEffect

// useEffect only for dialgo changes
useEffect(() => {
		
	// execute only if this modal
	if (dialog.target == 'providerLocationDetail' && dialog.status) {
		
		// Set Coordinates
		if (props.currentLocation) {
			var tmp = props.currentLocation.split(",");
			var currentCoordinates = {
				lat: tmp[0],
				lng: tmp[1]
			}
			setCoordinates(currentCoordinates);
		} else {
				
			var currentCoordinates = {
				lat: 0,
				lng: 0
			}
			setCoordinates(currentCoordinates);

		} // end else
	
	}  // end if

}, [dialog]); // end useEffect only for dialgo changes

// useEffect only for dialgo changes
useEffect(() => {
	
		// Set Coordinates
		if (props.currentLocation) {
			var tmp = props.currentLocation.split(",");
			var currentCoordinates = {
				lat: tmp[0],
				lng: tmp[1]
			}
			setCoordinates(currentCoordinates);
		} else {
				
			var currentCoordinates = {
				lat: 0,
				lng: 0
			}
			setCoordinates(currentCoordinates);
		} // end else

}, [props.currentLocation]); 
 

	return (
	<div>
		  <Dialog fullScreen open={dialog.target == 'providerLocationDetail' && dialog.status} onClose={()=>setDialog('providerLocationDetail',false)} TransitionComponent={TransitionSlideUp}>
			
			<AppBar style={{position: 'fixed',height:'80px',...backgroundHeaderModal}}>
			  <Toolbar style={{width:"100%"}}>
				<IconButton edge="start" color="inherit" onClick={() => setDialog('providerLocationDetail',false)} aria-label="close">
				  <img src={backIcon} alt="Voltar" style={{width:"30px",height:"auto"}} />
				</IconButton>			  
				  <div style={{marginLeft: "10px",flex: 1,textAlign:"center",display:"flex",justifyContent:"center",alignItems:"center"}}>
					  <img src={logo} alt="Motuum" style={{marginLeft:"auto",marginRight:"auto",width:"50%",height:"auto"}} />
				  </div>
				<IconButton disabled edge="end" color="inherit" style={{minWidth:"54px"}} />				  
				</Toolbar>
			</AppBar>			

			<Box style={{...screenStyleDialog}} m={0}>
			
				<Box mt={11} ml={0} mr={0}>

						<Card  style={{minHeight:"450px",width:"86%",marginBottom:"10px",backgroundColor:"#ffffff",boxShadow: "0px 10px 20px 0px #0000002A",borderRadius:"10px",position:"relative",marginRight:"auto",marginLeft:"auto",marginTop:"20px",marginBottom:"20px"}}>
									
							<CardContent style={{padding:"10px",paddingBottom:"0px"}}>
							
								<Box style={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center",marginTop:"10px",marginLeft:"15px",marginRight:"15px"}}>
									
									<Box style={{flex:1,width:"100%",height:"100%"}}>	
										{coordinates &&
										<>
											<div style={{ height: '40vh', width: '100%' }}>
											<APIProvider apiKey="AIzaSyBdE3uu0p9cJ_961a03aXMo0kGSzuUfTVE">
											<Map
											zoom={14}
											center={{lat: parseFloat(coordinates.lat), lng:parseFloat(coordinates.lng)}}
											gestureHandling={'greedy'}
											disableDefaultUI={false}
											fullscreenControlOptions={false}
											mapTypeControl={false}
											streetViewControl={false}
											fullscreenControl={false}
											> 
											<Marker position={{lat: parseFloat(coordinates.lat), lng:parseFloat(coordinates.lng)}} />
											</Map>
											</APIProvider>
											</div>			
										</>				
										}

										{!coordinates &&
										<>
										<Box ml={2} mr={2} style={{display:"flex",alignItems:"stretch",justifyContent:"center"}}><div style={{flex:1,marginTop:"50px"}}>O profissional <strong>não está compartilhando a localização em tempo real</strong>. A data estimada de chegada é baseada no horário que ele saiu de casa e se estiver vindo de carro para o local. </div></Box>
										</>
										}
									</Box>
									
									<Box style={{display:"flex",flexdirection:"row",alignItems:"flex-start",justifyContent:"space-between",marginTop:"16px",width:"100%"}}>
										
										<Box style={{float:"left",textAlign:"left"}}>										
											<h3 style={{color:"#003CFA",fontFamily:"Roboto",fontWeight:"bold",fontSize:"16px",marginTop:"0px",marginBottom:"2px",lineHeight:"18px"}}>{addressTitle}</h3>
											
											<div style={{marginTop:"10px",color:"#636466",fontFamily:"Roboto",fontWeight:"bold",fontSize:"12px",lineHeight:"18px"}}>											
											Estimativa de chegada<br />
											<div style={{color:"#003CFA"}}>{props.arriveEstimation}</div>
											</div>	
										</Box>
										
										<div style={{width:"50%",textAlign:"right",float:"right",color:"#636466",fontFamily:"Roboto",fontWeight:"regular",fontSize:"12px",lineHeight:"18px"}}>
											{addressText}
										</div>														
									
									</Box>										
								
								</Box>
								
							  </CardContent>

					</Card>				
			
					<div style={{color:"#636466",textAlign:"center",fontFamily:"Roboto",fontWeight:"regular",fontSize:"12px"}}><i>Atualizado de 5 em 5 minutos</i></div>
			
				</Box>	

				<Box style={{backgroundColor:"transparent",display:"flex",width:"100%",alignItems:"center",position:"fixed",bottom:0,height:"80px",backgroundColor:"#ffffff",boxShadow: "0px -10px 15px 0px #0000001A"}}>		
						
					<Box style={{flex:1,paddingRight:"50px",paddingLeft:"50px"}}>	
					
						<Button color="primary" variant="contained" style={{width:"100%",height:"53px"}} onClick={()=>props.retrieveCurrentLocation()}> ATUALIZAR </Button>	
			
					</Box>	

							
				</Box>					
			
			</Box>

		  </Dialog>
		</div>
		
	);
	
});



