import './MainLoginForgotPassword.css';
import React,{useState,useEffect,Fragment} from 'react';
import {Link,useNavigate} from 'react-router-dom';

import PropTypes from 'prop-types';
import { CSSTransition } from "react-transition-group";

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

// Material UI Import
import { Box,CircularProgress,Typography,Button,IconButton,TextField,FormControl  } from '@mui/material';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from '@mui/icons-material/Warning';


import {useComponentWillMount} from '../../Utils/customFunctions';
import Header from '../../Components/Layout/Header';
import If from '../../Components/Common/If';

import checked from '../../Assets/Images/checked.svg'; // Tell Webpack this JS file uses this image
import backgroundHexagon from '../../Assets/Images/backgroundHexagon.png'; // Tell Webpack this JS file uses this image
import checkIconBlue from '../../Assets/Images/checkIcon-blue.svg';


// Load Actions
import * as AppActions from '../../Store/Actions/App'
import * as RegisterActions from '../../Store/Actions/Register'

const RecoverPasswordSuccess = props => {


const screenStyle = {
  alignItems: "stretch",
  justifyContent: "top",
  width:"100vw",
  minHeight:"100vh",
  display:"flex",
  flexDirection:"column",
  backgroundImage: "url(" + backgroundHexagon + ")",
  backgroundSize:"cover",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"fixed"
}; 

const screenStyleLoading = {
  alignItems: "middle",
  justifyContent: "center",
  width:"100vw",
  height:"100vh",
  display: "flex",
  flexDirection: "column"
}; 

const styles = {
  indicator: {
    backgroundColor: '#A255A0',
  },
};

// Set functions
const handleChange = (event, newValue) => {
setValue(newValue);
};


// set classes var
// const classes = useStyles()();


// set vars from props
// Remember to add those who come from Redux
const { 
	registerDataStep0,
	registerDataStep1,
	registerDataStep2,
	registerDataStep3,
	innerLoading,
	setInnerLoading,
	buttonLoading,
	setButtonLoading,
	setRegister,
	processStep
} = props;

// set Use of history for PUSH 
let navigate = useNavigate();


// Set internal state
const [contentVisible,setContentState] = useState(false);
const [loadingMessage,setLoadingMessage] = useState("Carregando...");
const [value, setValue] = useState(0); // state for tab
  
// Run after mount component
useEffect(() => {
	
 // Avoid user to go back on browser or hardware android butto
 /* window.navigateState(null, document.title, window.location.href);
  window.addEventListener('popstate', function(event) {
    window.navigateState(null, document.title, window.location.href);
  });*/

 // Set Content Loading ON
 //setInnerLoading(true);
 setContentState(true);
 
 // Simulate Delay
 setTimeout(function() {
	 
	  // Set Content Loading OFF
	  //setInnerLoading(false);
	  
	  // Set Content Ready to show
	  //setContentState(true);
	
 },3000);

  // set scroll to top
  window.scrollTo(0, 1);
    
  // Runs when UNMOUNT component
  return () => {
    console.log("Desmontou o componente MainHome...");
  };
  
}, []); // end useEffect


// Will Mount Component HOOK
useComponentWillMount(() => {
	
    // Set session of app
  var sessionCheck = sessionStorage.getItem('@motuum/appLaunched');
  
  // if session is not created, redirect to bootstrap. (avoid direct access using url)
  if (!sessionCheck) {
	  navigate("/");
  } // end if	
    
    // Set session of app
  var sessionRecover = sessionStorage.getItem('recoverPasswordStatus');
  
  // Is status not 2, redirect to logjn
  if (sessionRecover < 2) {
	  navigate("/login");
  } // end if	    
      
}, []); // end useComponentWillMount

	return (
	<Fragment>
			
			<Header title="Senha atualizada!" buttons={{support:false,menu:false,close:false,back:false,leftFake:true}} /> 
			
			<CSSTransition in={contentVisible} timeout={1000} classNames="fade"	unmountOnExit>	
			
				<Box className="mainProfile" style={{...screenStyle}} m={0}>
				
					   <Box style={{marginBottom:"20px",backgroundColor:"transparent",alignItems:"center",display:"flex",flexDirection:"column"}} pl={5} pr={5} mt={15}>

								<div>
									<img src={checkIconBlue} alt="Ajuda" style={{width:"100px",height:"100px"}} />
								</div>

								<Box style={{marginTop:"30px",marginBottom:"30px"}}>						
									<div style={{fontFamily:"Roboto",fontWeight:"100",fontSize:"42px",color:"#003CFA",lineHeight:"38px"}}>	
										  SENHA ALTERADA COM SUCESSO
									</div>								 	
								</Box> 	
								
								<div style={{fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px",color:"#636466"}}>Agora você poderá fazer o login com seu usuário. Vá para o login para prosseguir.</div>								
								
						</Box>
											
						
						<Box style={{flexBasis:"20vh",backgroundColor:"transparent",display:"flex",alignItems:"center"}}>							
								
							<div style={{flex:1}}>						
									
									<Button variant="contained" color="primary"  style={{fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px",borderRadius:"10px",width:"80%",height:"53px"}} component={Link} to="/login">
									  Ir para o login
									</Button>	
									
							</div>	
								
						</Box>				
														
				</Box>
			
			</CSSTransition> 
			
				
	</Fragment>
)};


const mapStateToProps = store => ({
  buttonLoading:store.registerReducer.buttonLoading
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AppActions, ...RegisterActions }, dispatch);

export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(RecoverPasswordSuccess);
 