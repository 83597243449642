import React,{useState,useEffect,Fragment} from 'react';
import {Link,useNavigate} from 'react-router-dom';

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

import { Box,Backdrop,CircularProgress,Button,IconButton,Divider,Fade } from '@mui/material';

import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from '@mui/icons-material/Warning';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Badge from '@mui/material/Badge';
import ChatIcon from '@mui/icons-material/Chat';

import If from '../../Components/Common/If';

import JivoChat from '../../Components/Jivochat'; // Ajuste o caminho conforme necessário

// import backgroundHexagon from '../../Assets/Images/backgroundHexagon.png'; // Tell Webpack this JS file uses this image
import helpIcon from '../../Assets/Images/help-icon-blue.svg'; // Tell Webpack this JS file uses this image
import deviceIcon from '../../Assets/Images/deviceIcon.svg'; // Tell Webpack this JS file uses this image
import chatIcon from '../../Assets/Images/chatIcon.svg'; // Tell Webpack this JS file uses this image

const screenStyle = {
  alignItems: "stretch",
  justifyContent: "top",
  width:"100vw",
  display:"flex",
  flexDirection:"column",
  backgroundSize:"contain",
  backgroundRepeat:"repeat",
  backgroundPosition:"center",
  textAlign:"center"
}; 
				
export const SupportComponent = (props) => {
	
		const { 
			setDialog,
			dialog,
			screen
		} = props;
		
// set Use of history for PUSH 
let navigate = useNavigate();		

// Função para abrir o JivoChat
const openJivoChat = () => {
    if (window.jivo_api) {
      window.jivo_api.open();
    }
  };

    // Run after mount component
useEffect(() => {
  

	// Seleciona todos os elementos que têm uma classe que começa com 'button_'
	const jivoButtons = document.querySelectorAll("[class^='button_']");
	
	// Aplica 'display: none' a cada elemento encontrado
	jivoButtons.forEach(button => {
	  button.style.display = 'none';
	});  
	
  // Runs when UNMOUNT component
  return () => {

	console.log("Desmontou o componente Suporte...");

	// Limpando o script do JivoChat quando o componente for desmontado
	const jivoScript = document.querySelector('script[src="//code.jivosite.com/widget/XtvJfKExuq"]');
	if (jivoScript) {
	  document.body.removeChild(jivoScript);
	}

	// Seleciona todos os elementos que têm uma classe que começa com 'button_'
	const jivoButtons = document.querySelectorAll("[class^='button_']");
	
	// Aplica 'display: none' a cada elemento encontrado
	jivoButtons.forEach(button => {
	  button.style.display = 'none';
	});  


  };


}, []); // end useEffect

  				
			return (
			<>
				<Box className="mainHome" style={{...screenStyle}} m={0}>

						<Box style={{marginBottom:"10px",backgroundColor:"transparent",alignItems:"center",display:"flex",flexDirection:"column"}} pl={5} pr={5}>

								<div>
									<img src={helpIcon} alt="Ajuda" style={{width:"100px",height:"100px"}} />
								</div>

								<Box style={{marginTop:"20px",marginBottom:"50px"}}>						
									<div style={{fontFamily:"Roboto",fontWeight:"100",fontSize:"42px",color:"#636466",lineHeight:"38px"}}>	
										  COMO PODEMOS AJUDAR?
									</div>								 	
								</Box> 	
								
						</Box>
						
						<Box style={{backgroundColor:"transparent",alignItems:"stretch",display:"flex",flexDirection:"row"}} pl={5} pr={5} mb={7}>			
								
							<div style={{flex:1,width:"130px",height:"130px",backgroundColor:"#F7F7F7",margin:"10px",borderRadius:"10px",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}} onClick={() => {setDialog('helpComplete',false);navigate("/support/faqByScreen/"+screen)}}>
								
									<div> 
										<img src={deviceIcon} alt="Device" style={{width:"28px",height:"44px"}} />
									</div>
									
									<div style={{color:"#636466",fontFamily:"Roboto",fontWeight:"normal",fontSize:"14px",}}> 
										Dúvidas sobre<br/> essa tela
									</div>									
									
							</div>

							<div style={{flex:1,width:"130px",height:"130px",backgroundColor:"#F7F7F7",margin:"10px",borderRadius:"10px",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}} onClick={() => {openJivoChat();} }>
							
									<div> 
										<img src={chatIcon} alt="Device" style={{width:"48px",height:"48px"}} />
									</div>
									
									<div style={{color:"#636466",fontFamily:"Roboto",fontWeight:"normal",fontSize:"14px",}}> 
										Abrir Chat com<br /> o suporte
									</div>									
									
							</div>
								
						</Box>						
						{(dialog.target == 'helpComplete' && dialog.status) &&
							<JivoChat />
						}													
				</Box>
				</>
			)
				
}
