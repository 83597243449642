import './MainSupportChat.css';
import React,{useState,useEffect,Fragment} from 'react';
import { CSSTransition } from "react-transition-group";
import {Link,useNavigate,useParams} from 'react-router-dom';

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { Box,Backdrop,CircularProgress } from '@mui/material';

import {useComponentWillMount} from '../../Utils/customFunctions';
import Header from '../../Components/Layout/Header';
import If from '../../Components/Common/If';

import backgroundHexagon from '../../Assets/Images/backgroundHexagon.png'; // Tell Webpack this JS file uses this image

// import LiveChat from 'react-livechat'

// Load Actions
import * as AppActions from '../../Store/Actions/App'

const SupportChat = props => {


const screenStyle = {
  alignItems: "center",
  justifyContent: "start",
  width:"100vw",
  minHeight:"100vh",
  display:"flex",
  flexDirection:"column",
  backgroundImage: "url(" + backgroundHexagon + ")",
  backgroundSize:"cover",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"center"
}; 

const screenStyleLoading = {
  alignItems: "middle",
  justifyContent: "center",
  width:"100vw",
  height:"100vh",
  display: "flex",
  flexDirection: "column"
}; 

// set classes var
// const classes = useStyles()();

// set vars from props
// Remember to add those who come from Redux
const { 
	innerLoading,
	setInnerLoading,
	retrieveFaqByScreen
} = props;

let { id } = useParams();
const [screenReference,setScreenReference] = useState(id);

// set Use of history for PUSH 
let navigate = useNavigate();

// Set internal state
const [contentVisible,setContentState] = useState(false);
const [loadingMessage,setLoadingMessage] = useState("Carregando...");

const [loading,setLoading] = useState(true);
const [faq,setFaq] = useState([]);
  
// Run after mount component
useEffect(() => {
 
 // Set Content Loading ON
 setInnerLoading(false);
 setContentState(true);

  // set scroll to top
  window.scrollTo(0, 1);
  
	// Retrieve Service Complaints list
	var data = {
		context:"customer",
		screenReference:screenReference
	}
	var propsParams = {
		setLoading:setLoading,
		setFaq:setFaq		
	}		
	retrieveFaqByScreen(data,propsParams);		
    
  // Runs when UNMOUNT component
  return () => {
  
  };
  
}, []); // end useEffect


// Will Mount Component HOOK
useComponentWillMount(() => {
	
      
}, []); // end useComponentWillMount

const handleClose = () => {
	setInnerLoading(false);
};

	return (
	<Fragment>
			
			<Header title="Ajuda" buttons={{support:false,rightFake:true,menu:false,back:true,close:false}} />
			
			{/* Inner Loading */}
				{loading &&
					<Box className="loadingContent" style={{...screenStyleLoading}}>
							<Box mt={0} mx={0}>								
								<CircularProgress /> 
								<div> Carregando...Aguarde!</div>								
							</Box>	
					</Box>
				}			
			
			<CSSTransition in={!loading} timeout={1000} classNames="fade"	unmountOnExit>	
			
				<Box className="mainHome" style={{...screenStyle}} mt={10}>
							
						<Box style={{marginBottom:"0px",backgroundColor:"transparent",alignItems:"center",display:"flex",flexDirection:"column"}} pl={5} pr={5}>

								<Box style={{marginTop:"40px",marginBottom:"10px"}}>						
									<div style={{fontFamily:"Roboto",fontWeight:"100",fontSize:"42px",color:"#003CFA",lineHeight:"38px"}}>	
										  DÚVIDAS SOBRE O APP
									</div>								 	
								</Box> 	
								
						</Box>							
							
						<Box mt={1} mx={1} pr={2} pl={2}>	
						
							<div style={{width: '94%'}}>
								
								{faq.map((item, index) => {	
								
									var faqItems = item.AppFaqItems;						
								
									return(<><div style={{fontFamily:"Roboto",fontWeight:"bold",fontSize:"14px",color:"#003CFA",lineHeight:"38px",marginTop:"15px"}}>{item.screenName}</div>
											{faqItems.map((item2, index2) => {	
								  
											return(<Accordion key={item2.id}>
												<AccordionSummary
												  style={{textAlign:"left"}}
												  expandIcon={<ExpandMoreIcon />}
												  aria-controls="panel1a-content"
												  id="panel1a-header"
												>
												  <Typography style={{fontSize: "1rem",fontWeight:"regular"}}>{item2.question}</Typography>
												</AccordionSummary>
												<AccordionDetails  style={{textAlign:"left"}}>
												  <Typography>
													{item2.answer}
												  </Typography>
												</AccordionDetails>
											  </Accordion>)
													}		
											 )}</>)

									}		
								)}	
								  
							</div>
							
						</Box>
								
				</Box>
			
			</CSSTransition>    
			
				
	</Fragment>
)};

const mapStateToProps = store => ({
  innerLoading:store.appReducer.innerLoading
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AppActions }, dispatch);

export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(SupportChat);
 
