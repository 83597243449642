import React from 'react';
import axios from 'axios';
import moment from 'moment';
import {jwtDecode} from "jwt-decode";
import * as types from '../actionTypes';
import * as config from '../../Config/api.js';

export const setButtonLoading  = (status) => { 
	return { 
		type: types.BUTTON_LOADING,
		payload: status 
	}
};

export const setAddressesCards = (value) => {
	return {
		type: types.SET_ADDRESSES_CARDS,
		payload: value
	}	
};

export const saveAddress = (data,props)  => {
	
		return dispatch => {
		
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = localStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
				// decode JWT 
				var decoded = jwtDecode(userObj.userToken);
				
				// Set CustomerUserId
				const CustomerUserId = decoded.CustomerUserId;
	
					// Prepare for user creation
					const preparedData = {};	
					preparedData.label = data.label;
					preparedData.streetType = data.streetType;
					preparedData.streetName = data.streetName;
					preparedData.streetNumber = data.streetNumber;
					preparedData.streetComplement = data.streetComplement;
					preparedData.postalCode = data.postalCode;
					preparedData.suburb = data.suburb;
					preparedData.city = data.city;
					preparedData.state = data.state;
					preparedData.country = data.country;
					preparedData.fullAddress = data.fullAddress;
					preparedData.googleCoordinates = data.googleCoordinates;
					preparedData.googlePlaceId = data.googlePlaceId;						
					preparedData.mainAddress = data.mainAddress;
					
					
					// Connect to CREATE QUOTATION Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/user/address/create',
						   method : 'POST',
						   data: preparedData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})	
					.then((response) => {
							
					// If an error occured. Check error is blocking or non-blocking.
					if (response.data.error) {						

						// Alert error creating address
						props.setDialogMessage("Ocorreu um erro ao gravar o endereço. Tente novamente.[1]");
						props.setAlert(true);
						props.setSavingAddress(false);
						return;
		
					} // end if	*/
			
					if (response.data.success) {
							
							// Add card to current cards aray
							var finalCards = [...props.cards,response.data.address];
							props.setCards(finalCards);
							
							// send to redux
							dispatch(setAddressesCards(finalCards));
							
							// turn off loading button
							props.setSavingAddress(false);
							
							// Clean Screen to show new address
							props.setComplementNewAddress(false);
							props.cleanAddress();
							
							props.setDialogTitle("Cadastro finalizado!");
							props.setDialogMessage("Endereço cadastrado com sucesso.");
							props.setAlert(true);
							props.setDialog('address',false);
							return;
							
					} // end if

						
					}, (error) => {											
						
						// Alert error creating address
						props.setDialogMessage("Ocorreu um erro ao gravar o endereço. Tente novamente. [2]");
						props.setAlert(true);
						props.setSavingAddress(false);
						return;
											
					})
					.catch(error=> {							
						// Alert error creating address
						props.setDialogMessage("Ocorreu um erro ao gravar o endereço. Tente novamente. [3]");
						props.setAlert(true);
						props.setSavingAddress(false);
						return;
					});

		}
	
}

export const deleteAddress = (data,props)  => {
	
		return dispatch => {
		
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = localStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
				// decode JWT 
				var decoded = jwtDecode(userObj.userToken);
				
				// Set CustomerUserId
				const CustomerUserId = decoded.CustomerUserId;
	
					// Prepare for user creation
					const preparedData = {};	
					preparedData.addressId = data.addressId;
					
					
					// Connect to CREATE QUOTATION Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/user/address/delete',
						   method : 'PUT',
						   data: preparedData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})	
					.then((response) => {
							
					// If an error occured. Check error is blocking or non-blocking.
					if (response.data.error) {						

						// Alert error creating address
						props.setDialogMessage("Ocorreu um erro ao excluir o endereço. Tente novamente.[1]");
						props.setAlert(true);
						props.setLoadingButton(false);
						props.setDialog('deleteAddressPrompt',false);
						return;
		
					} // end if	*/
			
					if (response.data.success) {
														
							var array = [...props.cards]; // clone
							var index = array.filter(item => item.id != data.addressId);	
							var finalCards = [...index];	

							props.setCards(finalCards);							
							
							// send to redux
							dispatch(setAddressesCards(finalCards));
							
							props.setDialogTitle("Endereço excluído com sucesso!");
							props.setDialogMessage("O endereço selecionado foi excluído com sucesso.");
							props.setAlert(true);
							
							// turn off loading button
							props.setLoadingButton(false);
							props.setDialog('deleteAddressPrompt',false);							
							
							return;
						
					} // end if

						
					}, (error) => {											
						
						// Alert error creating address
						props.setDialogMessage("Ocorreu um erro ao excluir o endereço. Tente novamente. [2]");
						props.setAlert(true);
						props.setLoadingButton(false);
						props.setDialog('deleteAddressPrompt',false);
						return;
											
					})
					.catch(error=> {	
					
						// Alert error creating address
						props.setDialogMessage("Ocorreu um erro ao excluir o endereço. Tente novamente. [3]");
						props.setAlert(true);
						props.setLoadingButton(false);
						props.setDialog('deleteAddressPrompt',false);
						return;
					});

		}
	
}
	
export const retrieveAddresses = (props)  => {
	
		return dispatch => {
		
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = localStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
				// decode JWT 
				var decoded = jwtDecode(userObj.userToken);
				
				// Set CustomerUserId
				const CustomerUserId = decoded.CustomerUserId;
	
					
					// Connect to CREATE QUOTATION Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/user/address/list',
						   method : 'GET',
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})	
					.then((response) => {
							
					// If an error occured. Check error is blocking or non-blocking.
					if (response.data.error) {						

						// Alert error creating address
						props.setDialogMessage("Ocorreu um erro ao buscar os endereços. Tente novamente.[1]");
						props.setAlert(true);
						props.setLoadingAddress(false);
						return;
		
					} // end if	*/
			
					if (response.data.success) {
							
							// Add card to current cards aray
							var finalCards = response.data.addresses;
							
							// send to redux
							dispatch(setAddressesCards(finalCards));

							if (finalCards.length > 0) {
								props.setCards(finalCards);
							} // end ids
							
							// turn off loading button
							props.setLoadingAddress(false);
							props.setContentState(true);

							return;
							
					} // end if

						
					}, (error) => {											
						
						// Alert error creating address
						props.setDialogMessage("Ocorreu um erro ao buscar os endereços. Tente novamente. [2]");
						props.setAlert(true);
						props.setLoadingAddress(false);
						return;
											
					})
					.catch(error=> {							
						// Alert error creating address
						props.setDialogMessage("Ocorreu um erro ao buscar os endereços. Tente novamente. [3]");
						props.setAlert(true);
						props.setLoadingAddress(false);
						return;
					});

		}
	
}
	
	
	

	
	
	