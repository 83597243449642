import * as types from '../actionTypes';

const initialState = { 
	buttonLoading: true,
	creditCards:[]
}
 
export const creditcardReducer = (state = initialState, action) => {
		
    switch (action.type) {
		case types.SET_CREDIT_CARDS:
            return { ...state, creditCards: action.payload }			
        default:
            return state
    }
}