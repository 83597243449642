import './MainSupport.css';
import React,{useState,useEffect,Fragment} from 'react';
import {Link,useNavigate,useParams} from 'react-router-dom';

import PropTypes from 'prop-types';
import { CSSTransition } from "react-transition-group";

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

import moment from 'moment';
import 'moment/locale/pt-br';

import { Box,Backdrop,CircularProgress,Typography,Button,InputAdornment,IconButton,TextField,Divider } from '@mui/material';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import WarningIcon from '@mui/icons-material/Warning';
import LockIcon from '@mui/icons-material/Lock';
import Slide from '@mui/material/Slide';
import Fade from '@mui/material/Fade';
import Zoom from '@mui/material/Zoom';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Danger from '@mui/icons-material/Error';

import helpIcon from '../../Assets/Images/help-icon-blue.svg'; 
import deviceIcon from '../../Assets/Images/deviceIcon.svg'; 
import chatComplain from '../../Assets/Images/iconComplain-blue.svg'; 
import chatIcon from '../../Assets/Images/iconChat-blue.svg';
import chatSupportIcon from '../../Assets/Images/chatIcon.svg'; 
import profileIcon from '../../Assets/Images/profileIcon.svg';
import userProfileIcon from '../../Assets/Images/userProfileIcon.svg';
import passwordProfileIcon from '../../Assets/Images/passwordProfileIcon.svg';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

import InputLabel from '@mui/material/InputLabel';
import ButtonGroup from '@mui/material/ButtonGroup';

import InputMask from "react-input-mask";

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import NiceInputPassword from 'react-nice-input-password';

import {useComponentWillMount} from '../../Utils/customFunctions';
import Header from '../../Components/Layout/Header';
import If from '../../Components/Common/If';

import logo from '../../Assets/Images/logo_motuum-white.svg';
import backgroundHexagon from '../../Assets/Images/backgroundHexagon.png'; 
import backgroundHeaderImage from '../../Assets/Images/backgroundHeader.png'; 
import backIcon from '../../Assets/Images/backIcon.svg';
import infoIcon from '../../Assets/Images/infoIcon-red.svg'; 



// Load Actions
import * as AppActions from '../../Store/Actions/App'
import * as ServiceActions from '../../Store/Actions/Service'

const ProfileManage = props => {

const screenStyle = {
  alignItems: "stretch",
  justifyContent: "top",
  width:"100vw",
  minHeight:"100vh",
  display:"flex",
  flexDirection:"column",
  backgroundImage: "url(" + backgroundHexagon + ")",
  backgroundSize:"cover",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"center"
}; 

const screenStyleLoading = {
  alignItems: "middle",
  justifyContent: "center",
  width:"100vw",
  height:"100vh",
  display: "flex",
  flexDirection: "column"
}; 

// set classes var
// const classes = useStyles()();

// Set TABPANEL
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
	  hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


// set vars from props
// Remember to add those who come from Redux
const { 
	innerLoading,
	setInnerLoading,
	setDialog,
	dialog,
	UserData,
	createTicket,
	retrieveTickets
} = props;

const [serviceOrderId,setServiceOrderId] = useState(null);


// set Use of history for PUSH 
let navigate = useNavigate();

// Set internal state
const [contentVisible,setContentState] = useState(false);
const [loadingMessage,setLoadingMessage] = useState("Carregando...");
const [loading, setLoading] = useState(true); 
const [saving, setSaving] = useState(false); 

const [dialogAction, setDialogAction] = useState(false); // state for tab
const [dialogAlert, setAlertDialog] = useState(false); // state for tab
const [dialogMessage, setDialogMessage] = useState(""); // state for tab
const [dialogTitle, setDialogTitle] = useState("Verifique as informações"); // state for tab
const [alert, setAlert] = useState(false); // state for tab
const [confirm, setConfirm] = useState(false); // state for tab

const [tickets,setTickets] = React.useState([]); 
const [newTicket,setNewTicket] = React.useState(true);

const [email, setName] = useState(UserData.email);
const [name, setEmail] = useState(UserData.CustomerProfile.completeName);
const [subject, setSubject] = useState("");
const [description, setDescription] = useState("");
const [priority, setPriority] = useState(1);
const [acceptTerms, setAcceptTerms] = useState(false); // state for tab


const prioritiesSelect = [
	{id:1,label:"Normal (recomendado)"},
	{id:2,label:"Alta"},
	{id:3,label:"Urgente"}
]

//console.log("[actionToTake]--->"+JSON.stringify(actionToTake,null,2));

// console.log("[problemCategories]--->"+JSON.stringify(problemCategories,null,2));
// console.log("[problemSubCategories]--->"+JSON.stringify(problemSubCategories,null,2));


const confirmAction = () => {
		
	// Check if terms was accepted
	if (!subject || !description ) {		
		setDialogTitle("Verifique as informações");
		setDialogMessage("Você deve preencher todos os campos marcados com * para prosseguir");
		setAlert(true);	
		return false;		
	} // end if	

	// Check if terms was accepted
	if (!acceptTerms) {		
		setDialogTitle("Verifique as informações");
		setDialogMessage("Você precisa confirmar a veracidade das informações para prosseguir");
		setAlert(true);	
		return false;		
	} // end if		
	
	// set title
	setDialogTitle("Tem certeza que quer prosseguir?");
	setDialogMessage("Ao prosseguir sua solicitação será criada e será analisada.");

	// Open confirmation alert
	setConfirm(true);	
	
}

const saveAction = (props) => {
	
	setSaving(true);
		
	// Retrieve Scheduled Services
	var data = {
		name:name,
		email:email,
		subject:subject,
		description:description,
		priority:priority
	}
	var props = {
		setAlert:setAlert,
		setDialogMessage:setDialogMessage,
		setDialogTitle:setDialogTitle,
		setLoading:setSaving,
		setDialogAction:setDialogAction,
		loadData:loadData
	}	
	createTicket(data,props);	

}

const loadData = () => {
	
	// Retrieve Service Complaints list
	var data = {
		FreshDeskRequesterId:0 // need to get from tickets ServiceOrderCustomerSupportTicket
	}
	var propsParams = {
		setLoading:setLoading,
		setTickets:setTickets,
		setNewTicket:setNewTicket,
		setLoading:setLoading		
	}		
	retrieveTickets(data,propsParams);	
	
}

// Run after mount component
useEffect(() => {
	
	// Retrieve User Data Obj. 
	var result = localStorage.getItem("@motuum/userData", undefined,true);
	var userData = JSON.parse(result);  
  
	  // If token dont exist, redirect to onboarding or register
	  if (!userData) {	  
		navigate("/");  
	  } // end if
	  // If token exist, redirect to HOME
	  else {
		  if (!userData.userToken) {
			navigate("/");
		  } // end if
	  } // end else    

	// Load start data
	loadData();
	
 
 // Set Content Loading ON
 //setInnerLoading(true);
 setContentState(true);

  // set scroll to top
  window.scrollTo(0, 1);
    
  // Runs when UNMOUNT component
  return () => {
   
  };
  
}, [props.open]); // end useEffect


// Will Mount Component HOOK
useComponentWillMount(() => {
	
    // Set session of app
  var sessionCheck = sessionStorage.getItem('@motuum/appLaunched');
  
  // if session is not created, redirect to bootstrap. (avoid direct access using url)
  if (!sessionCheck) {
	  navigate("/");
  } // end if	
  
      
}, []); // end useComponentWillMount


const handleKeypress = e => {	
	 //it triggers by pressing the enter key  
	if (e.key === "Enter") {
	  saveAction(props);
	}
};	



	return (
	<Fragment>
			
			<Header title="Reportar Problema" buttons={{support:false,rightFake:true,menu:false,back:true,close:false}} screen="ProfileManage" />
				
			{/* Inner Loading */}
			{loading &&
					<Box className="loadingContent" style={{...screenStyleLoading}}>
							<Box mt={0} mx={0}>								
								<CircularProgress /> 
								<div> Carregando...Aguarde!</div>								
							</Box>	
					</Box>
			}	

			<Fade in={!loading} timeout={1000}  mountOnEnter unmountOnExit>			
			
				<Box className="mainHome" style={{...screenStyle}} mt={10}>
				
					<Box style={{marginBottom:"0px",backgroundColor:"transparent",alignItems:"center",display:"flex",flexDirection:"column"}} pl={5} pr={5}>

									<Box style={{marginTop:"40px",marginBottom:"20px"}}>						
										<div style={{fontFamily:"Roboto",fontWeight:"100",fontSize:"42px",color:"#003CFA",lineHeight:"38px"}}>	
											 CHAMADOS DE SUPORTE
										</div>								 	
									</Box> 	
									
					</Box>
							
					<Box style={{marginTop:"10px",backgroundColor:"transparent",alignItems:"center",display:"flex",flexDirection:"column"}} mt={props.marginTop} pl={5} pr={5}>
							
							{tickets.length > 0 && !newTicket  &&
							<>
								{tickets.map((item, index) => {	
								
									var formattedCreation = moment(item.created_at).format("DD/MM/Y");
										 
									if (item.custom_fields.cf_origin == "customer") {

									return (<Card key={item.id}  style={{minHeight:"110px",width:"100%",marginBottom:"10px",backgroundColor:"#ffffff",boxShadow: "0px 10px 20px 0px #0000002A",borderRadius:"10px",position:"relative"}} onClick={(target)=>navigate("/support/ticketview/"+item.id+"")}>
									
											<CardContent style={{padding:"10px",paddingBottom:"0px"}}>
															
												<Box style={{display:"flex",flexdirection:"row",alignItems:"center",justifyContent:"space-between",marginTop:"10px",marginLeft:"15px",marginRight:"15px"}}>
													
													<Box style={{float:"left",textAlign:"left"}}>										
														<h3 style={{color:"#636466",fontFamily:"Roboto",fontWeight:"bold",fontSize:"14px",marginTop:"0px",marginBottom:"2px",lineHeight:"18px"}}>{item.subject}</h3>
														<div style={{color:"#636466",fontFamily:"Roboto",fontWeight:"regular",fontSize:"12px",lineHeight:"18px"}}>
														{formattedCreation}
														</div>	
													</Box>
													
													<div style={{textAlign:"right",float:"right",width:"40%",overflow:"hidden"}}>
														<h3 style={{color:"#636466",fontFamily:"Roboto",fontWeight:"bold",fontSize:"12px",marginTop:"0px",marginBottom:"2px",lineHeight:"18px"}}>
														
														{item.status == 2 &&
															<span style={{color:"blue"}}>Aberto</span>
														}
														
														{item.status == 3 &&
															<span style={{color:"orange"}}>Em análise</span>
														}

														{item.status == 4 &&
															<span style={{color:"green"}}>Resolvido</span>
														}
														
														{item.status == 5 &&
															<span style={{color:"gray"}}>Fechado</span>
														}														
														
														</h3>
														<div style={{color:"#636466",fontFamily:"Roboto",fontWeight:"bold",fontSize:"10px",lineHeight:"18px"}}>

															{item.priority == 1 &&
																<span>Prioridade Padrão</span>
															}
															
															{item.priority == 2 &&
																<span>Alta prioridade</span>
															}
															
															{item.priority == 3 &&
																<span style={{color:"red"}}>Urgente</span>
															}															

														</div>														
													</div>														
												
												</Box>
												
											  </CardContent>
									</Card>)
									} // end if
										}		
									)}										
									
									
								<Box style={{width:"100%",position:"relative",marginTop:"20px"}} >		
									<Button color="primary" variant="outlined" style={{width:"100%",height:"53px",borderRadius:"10px",marginBottom:"0px"}} onClick={() => setNewTicket(true) } >
									 NOVO CHAMADO DE SUPORTE
									</Button>		
										
								</Box>			
			
							</>
							}
							
							{newTicket &&
							<>
															
								<TextField
									id="title"
									label="Assunto *"
									style={{ margin: 10,marginLeft:0,marginRight:0,backgroundColor:"#fff",width:"100%"}}
									placeholder="Informe o assunto de seu chamado"
									variant="outlined"
									type="text"
									onKeyPress={handleKeypress}
									value={subject}
									onChange={(event) => {
									setSubject(event.target.value)
									}}
								 />
								 
								<FormControl variant="outlined" style={{margin: "5px",width: '100%'}}>
										
										<InputLabel>Prioridade do chamado</InputLabel>
										
										<Select
										  style={{textAlign:"left",backgroundColor:"#fff",width:"100%"}}
										  value={priority}
										  onChange={(event) => {
											  setPriority(event.target.value);
										  }}										  
										  label="Prioridade do chamado"
										>

										 {prioritiesSelect.map((item, index) => {	
											return (<MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>) 
											}		
										)}	
										</Select>
								</FormControl>										 
																
								  <TextField
									  id="outlined-multiline-static"
									  label="Descrição *"
									  style={{textAlign:"left",backgroundColor:"#fff",width:"100%"}}
									  multiline
									  rows={4}
										value={description}
										onChange={(event) => {
										setDescription(event.target.value)
										}}									  
									  placeholder="Descreva o seu chamado com o maior número de detalhes."
									  variant="outlined"
									/>	

								<Box style={{marginTop:"20px",marginBottom:"20px",textAlign:"center"}} pl={1} pr={1}>
								
									  <Checkbox												
										checked={acceptTerms}
										  onChange={(event) => {
											  setAcceptTerms(event.target.checked)
										  }}											
										inputProps={{ 'aria-label': 'primary checkbox' }}
									  />
									  
									  <span style={{marginBottom:"10px"}}>
										Confirmo que as informações acima são verdadeiras
									  </span>	
									  
								</Box>
								
								<Button readOnly={saving} variant="contained" color="primary" size="large" style={{width:"100%",height:"53px",marginTop:"10px",borderRadius:"10px"}} onClick={()=>confirmAction()}>
									{ !saving &&
									   <div>Criar chamado</div>
									}
									
									{ saving &&
									<>
									   <CircularProgress size={30} color="inherit" />
									   <span style={{marginLeft:"10px"}}>Criando...</span>
									</>
									}
								</Button>			
								
							  
							
								{tickets.length > 0 &&
								<>		
									<Box style={{width:"100%",position:"relative",marginTop:"10px"}} >		
										<Button color="primary" variant="outlined" style={{width:"100%",height:"53px",borderRadius:"10px",marginBottom:"10px"}} onClick={() => setNewTicket(false) } >
										 LISTA DE CHAMADOS
										</Button>		
											
									</Box>
								</>
								}							
							
							</>
							}
		
							
					</Box>						
						
						
				</Box>			
			
			</Fade>  
			
			<AlertDialog 
				alert={alert}
				setAlert={setAlert}
				dialogMessage={dialogMessage}
				dialogTitle={dialogTitle}
				dialogAction={dialogAction}
				history={navigate}
			/>	
			
			<ConfirmDialog 
				saveAction={saveAction}
				dialog={dialog}
				confirm={confirm}
				setConfirm={setConfirm}
				setDialog={setDialog}
				dialogTitle={dialogTitle}
				dialogMessage={dialogMessage}
				setDialogTitle={setDialogTitle}
				setDialogMessage={setDialogMessage}
			/>				
			
	</Fragment>
)}; 

const mapStateToProps = store => ({
  innerLoading:store.appReducer.innerLoading,
  UserData:store.appReducer.userData,
  dialog:store.appReducer.dialog
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AppActions,...ServiceActions }, dispatch);  

// Transition Slide for Dialog SLIDE UP	
const TransitionSlideUp = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;  
});	

const AlertDialogBackup = (props) => {
	
	return (
		 <Dialog
			open={props.alert} 
			onClose={() => { props.setAlert(false)} }
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		  >
		  <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
			<DialogContent>
			  <DialogContentText id="alert-dialog-description">
			  {props.dialogMessage}
			  </DialogContentText>
			</DialogContent>
			<DialogActions>
			  <Button onClick={() => { props.setAlert(false) }} color="primary" autoFocus>
				OK
			  </Button>
			</DialogActions>			
		  </Dialog>
	  )
	
} // end AlertDialog

const AlertDialog = (props) => {
	
	return (
		 <Dialog
			open={props.alert}
			onClose={!props.dialogAction ? () => { props.setAlert(false) } : () => { props.setAlert(false);props.navigate("/support") }  }
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		  >
		  <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
			<DialogContent>
			  <DialogContentText id="alert-dialog-description">
			  {props.dialogMessage}
			  </DialogContentText>
			</DialogContent>
			<DialogActions>
			
			{props.dialogAction &&
			  <Button onClick={() => {props.setAlert(false);props.navigate("/support")} } color="primary" autoFocus>
				OK
			  </Button>
			}

			{!props.dialogAction &&
			  <Button onClick={() => props.setAlert(false) } color="primary" autoFocus>
				OK
			  </Button>
			}			  
			  
			</DialogActions>			
		  </Dialog>
	  )
	
	
} // end AlertDialog

const ConfirmDialog = (props) => {
	
	return (
		 <Dialog
			open={props.confirm}
			onClose={ ()=>props.setConfirm(false) } 
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		  >
		  <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
			<DialogContent>
			  <DialogContentText id="alert-dialog-description">
			  {props.dialogMessage}
			  </DialogContentText>
			</DialogContent>
			<DialogActions>

			  <Button onClick={ ()=>props.setConfirm(false) } color="primary" autoFocus>
				ABORTAR
			  </Button>			

			  <Button onClick={ ()=> {props.setConfirm(false);props.saveAction(props); } } color="primary" autoFocus>
				CONFIRMAR 
			  </Button>
				
			</DialogActions>			
		  </Dialog>
	  )
		
} // end AlertDialog

export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(ProfileManage);

