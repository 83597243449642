import { appReducer } from './appReducer';
import { registerReducer } from './registerReducer';
import { loginReducer } from './loginReducer';
import { profileReducer } from './profileReducer';
import { serviceReducer } from './serviceReducer';
import { addressReducer } from './addressReducer';
import { creditcardReducer } from './creditcardReducer';

import { combineReducers } from 'redux';

export const Reducers = combineReducers({
  appReducer: appReducer,
  registerReducer:registerReducer,
  loginReducer:loginReducer,
  profileReducer:profileReducer,
  serviceReducer:serviceReducer,
  addressReducer:addressReducer,
  creditcardReducer:creditcardReducer
});