import { useEffect } from 'react';

const JivoChat = () => {
  useEffect(() => {

      // Configuração para prevenir a inicialização automática
      window.jivo_config = {
        startHidden: true, // Esta linha impede que o JivoChat abra automaticamente
      };

    // Função para adicionar o script do JivoChat
    const addJivoScript = () => {
      const script = document.createElement('script');
      script.src = '//code.jivosite.com/widget/XtvJfKExuq';
      script.async = true;
      document.body.appendChild(script);
    };

    addJivoScript();

  }, []);

  return null;
};

export default JivoChat;

