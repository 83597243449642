import './MainHireService.css';
import React,{useState,useEffect,useRef,Fragment} from 'react';
import {Link,useNavigate} from 'react-router-dom';
import { CSSTransition } from "react-transition-group";

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import moment from 'moment';

import { styled } from '@mui/system';

import InputMask from "react-input-mask";
import { Number, Cvc, Expiration } from "react-credit-card-primitives";

import PlacesAutocomplete, { 
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

import { Box,Backdrop,CircularProgress,Paper,Divider,Typography,Button,ButtonGroup,IconButton,TextField,InputAdornment } from '@mui/material';

import LinearProgress from '@mui/material/LinearProgress';
import AnimateHeight from 'react-animate-height';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import Slide from '@mui/material/Slide';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';

import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';
import LockIcon from '@mui/icons-material/Lock';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import HttpsIcon from '@mui/icons-material/Https';
import PersonIcon from '@mui/icons-material/Person';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import Camera,{ FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

import Danger from '@mui/icons-material/Error';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import ZoomInIcon from '@mui/icons-material/ZoomIn';
import HelpIcon from '@mui/icons-material/Help';
import Info from '@mui/icons-material/ErrorOutline';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DeleteIcon from '@mui/icons-material/Delete';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';

import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import QueueOutlinedIcon from '@mui/icons-material/QueueOutlined';

import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import add from 'date-fns/add'
import format from 'date-fns/format'


import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import brLocale from "date-fns/locale/pt-BR";
import { ptBR } from '@mui/x-date-pickers/locales';
import { DatePicker } from '@mui/x-date-pickers'

import {useComponentWillMount} from '../../Utils/customFunctions';
import Header from '../../Components/Layout/Header';
import If from '../../Components/Common/If';
import CircularProgressWithLabel from '../../Components/Common/CircularProgressWithLabel';
import {ServiceDetailDialog} from '../SearchService/DialogServiceDetail';

import backgroundHexagon from '../../Assets/Images/backgroundHexagon.png'; // Tell Webpack this JS file uses this image
import headerCategory1 from '../../Assets/Images/headerSimpleCategory1.png'; // Tell Webpack this JS file uses this image
import logo from '../../Assets/Images/logoTypeWOslogan.svg'; // Tell Webpack this JS file uses this image
import Approval from '../../Assets/Images/approval.svg';
import visa from '../../Assets/Images/visa.svg'; // Tell Webpack this JS file uses this image
import mastercard from '../../Assets/Images/mastercard.svg'; // Tell Webpack this JS file uses this image
import amex from '../../Assets/Images/amex.svg'; // Tell Webpack this JS file uses this image
import diners from '../../Assets/Images/diners.svg'; // Tell Webpack this JS file uses this image
import elo from '../../Assets/Images/elo.svg'; // Tell Webpack this JS file uses this image
import genericFlag from '../../Assets/Images/genericFlag.svg'; // Tell Webpack this JS file uses this image

import plusIconBlue from '../../Assets/Images/plusIconBlue.svg'; 
import lessIconBlue from '../../Assets/Images/lessIconBlue.svg'; 
import priceIconBlue from '../../Assets/Images/priceIconBlue.svg'; 
import priceIconGreen from '../../Assets/Images/priceIconGreen.svg'; 
import scheduleIconBlue from '../../Assets/Images/scheduleIconBlue.svg'; 
import zoomIconBlue from '../../Assets/Images/zoomIconBlue.svg'; 
import chatIconGreen from '../../Assets/Images/iconChatGreen.svg'; 
import calendarIconBlue from '../../Assets/Images/calendarIconBlue.svg'; 
import placeIconBlue from '../../Assets/Images/placeIconBlue.svg'; 
import cardIconBlue from '../../Assets/Images/cardIconBlue.svg'; 
import personIconBlue from '../../Assets/Images/personIconBlue.svg';

// Load Actions
import * as AppActions from '../../Store/Actions/App'
import * as ServiceActions from '../../Store/Actions/Service'

const HireService = props => {


const screenStyle = {
  alignItems: "stretch",
  justifyContent: "top",
  width:"100vw",
  height:"fit-content",
  minHeight:"100vh",
  paddingBottom:"250px",
  display:"flex",
  flexDirection:"column",
  backgroundImage: "url(" + backgroundHexagon + ")",
  backgroundSize:"cover",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"center"
}; 

const screenStyleBackgroundImage = {
  backgroundImage: "url(" + headerCategory1 + ")",
  backgroundSize:"auto",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"center",
  minHeight:"59px",
  width:"100%"
};

const screenStyleLoading = {
  alignItems: "middle",
  justifyContent: "center",
  width:"100vw",
  height:"fit-content",
  minHeight:"100vh",
  display: "flex",
  flexDirection: "column"
}; 

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 10,
  '& .MuiLinearProgress-colorPrimary': {
    backgroundColor: "#FFFFFF",
  },
  '& .MuiLinearProgress-barColorPrimary': {
    borderRadius: 10,
    backgroundColor: '#1EC300',
  },
}));

// set classes var
// const classes = useStyles()();

// set vars from props
// Remember to add those who come from Redux
const { 
	innerLoading,
	setInnerLoading,
	setDialog,
	dialog,
	setService,
	scheduledInfo,
	resetHireService,
	hireServiceService,
	hireServiceSchedule,
	hireServiceAddress,
	hireServicePayment,
	hireServiceInvoice,
	hireServiceExtraInfo,
	createServiceQuotation,
	buttonLoading,
	setButtonLoading,
	termsContent,
	retrieveTermsContent,
	userData,
	setSchedule,
	setAddress,
	setPayment,
	setInvoice,
	setExtraInfo
} = props;

// Set internal state
const [contentVisible,setContentState] = useState(false);
const [loadingMessage,setLoadingMessage] = useState("Carregando...");

const [selectedDate,setDate] = useState('');

const [cards,setCards] = useState([]);
const [acceptTerms, setAcceptTerms] = useState(false); // state for tab
const [servicePrice, setServicePrice] = useState(hireServiceService.Price ? hireServiceService.Price[0].price:0); // state for tab
const [serviceUrgentTax, setServiceUrgentTax] = useState(hireServiceService.Price ? hireServiceService.Price[0].urgentTax:0); // state for tab
const [serviceTotalPrice,setServiceTotalPrice] = useState(hireServiceService.Price ? hireServiceService.Price[0].price : 0);

const [heightPanel,setHeightPanel] = useState(0);

// Initital state
const maxSteps = 5;
const initialPercent = 100 / 5;
const addressInfo = "";
const paymentInfo = "";
const invoiceInfo = "";
const extraInfo = "";


/* Set Steps*/
const [currentStep,setCurrentStep] = useState(1);
const [step1,setStep1] = useState(true);
const [step2,setStep2] = useState(false);
const [step3,setStep3] = useState(false);
const [step4,setStep4] = useState(false);
const [step5,setStep5] = useState(false);
const [allSet,setStepAll] = useState(false);
const [progress, setProgress] = useState(initialPercent);
const [categoryBreadCrumb,setCategoryBreadCrumb] = useState("carregando...");

const [modal, setModal] = useState(false); // state for tab

const [currentStepLabel, setCurrentStepLabel] = useState("Dia e Horário do serviço");
const [nextStepLabel, setNextStepLabel] = useState("Endereço do local");

const [dialogTitle, setDialogTitle] = useState("Verifique as informações"); // state for tab
const [dialogMessage, setDialogMessage] = useState(""); // state for tab
const [alert, setAlert] = useState(false); // state for tab

// added here for Scope and view mode
const [extraInfoDescription, setExtraInfoDescription] = useState("");
const [cameraImages, setCameraImages] = useState([]);


console.log("userData: "+JSON.stringify(userData,null,2));
console.log("hireServiceAddress: "+JSON.stringify(hireServiceAddress,null,2));
console.log("hireServicePayment: "+JSON.stringify(hireServicePayment,null,2));
console.log("hireServiceSchedule: "+JSON.stringify(hireServiceSchedule,null,2));
console.log("hireServiceService: "+JSON.stringify(hireServiceService,null,2));
console.log("hireServiceSchedule: "+JSON.stringify(hireServiceSchedule,null,2));
console.log("hireServiceAddress: "+JSON.stringify(hireServiceAddress,null,2));
console.log("hireServicePayment: "+JSON.stringify(hireServicePayment,null,2));
console.log("hireServiceInvoice: "+JSON.stringify(hireServiceInvoice,null,2));
console.log("hireServiceExtraInfo: "+JSON.stringify(hireServiceExtraInfo,null,2));


let navigate = useNavigate();

const bottomRef = useRef();
const scrollToBottom = () => {
	
	bottomRef.current.scrollIntoView({
	behavior: "smooth",
	block: "start",
	});
	
};

// Run after mount component
useEffect(() => {
	 
	// Set breadcrumb
	setCategoryBreadCrumb(hireServiceService.breadcrumb ? hireServiceService.breadcrumb : "carregando...");
	
	// if session is not created, redirect to bootstrap. (avoid direct access using url)
	if (!hireServiceService.id) {
	  history.push("/searchService");
	} // end if			
	
	// Retrieve User Data Obj. 
	var result = localStorage.getItem("@motuum/userData", undefined,true);
	var UserData = JSON.parse(result);  
	
	// Rediredct if there is no userData
	if (!UserData) {	  
	history.push("/");  
	return;
	} // end if	
	

	// Reset service data when mount component. 
	resetHireService();

	// Reset SESSION related to Hire Service
	sessionStorage.removeItem("@motuum/searchingProvider");	
	sessionStorage.removeItem("@motuum/serviceOfferId");	
	sessionStorage.removeItem("@motuum/quotationId");	
	sessionStorage.removeItem("@motuum/selectedProvider");	
    
	// Render hireServiceService 
	setService(hireServiceService);
  
  // Show Wizard Dialog FIRST TIME ONLY
  /*var viewedHireWizard = localStorage.getItem("viewedHireWizard", undefined,true);
  if (!viewedHireWizard) {
	  
	 // Disable Hint
	//toggleDialog('wizard',true);
	
	// Mark Wizard as viewed (localstorage)
	localStorage.setItem("viewedHireWizard",true);
	
  } // end if*/
 
 // Set Content Loading ON
 setContentState(true);
 //setInnerLoading(true);

  // set scroll to top
  window.scrollTo(0, 1);
    
  // Runs when UNMOUNT component
  return () => {
   // console.log("Desmontou o componente MainHome...");
  };
  
}, []); // end useEffect

// Set functions
const handleAcceptTerms = (event) => {

const newValue = event.target.checked
setAcceptTerms(newValue);

};

// Change Step Function Next and Previous
const changeStep = (direction,step) => {
	
if (direction == 'next') {
	
	// set next step
	const nextStepNumber = step + 1;

	// Dinamically proccess function
	if (currentStep == maxSteps) {
		setCurrentStep(nextStepNumber);	
		setStepAll(true);
	} else {
		eval('setStep'+nextStepNumber+'(true)');
		setProgress(progress+initialPercent);
		setCurrentStep(nextStepNumber);	
			
		// Set Label on Step by Step	
		if (nextStepNumber == 1) {
			setCurrentStepLabel("Dia e Horário do serviço");
			setNextStepLabel("Endereço do Local");			
		} // end if	
		else if (nextStepNumber == 2) {
			setCurrentStepLabel("Endereço do Local");
			setNextStepLabel("Forma de pagamento");			
		} // end if	
		else if (nextStepNumber == 3) {
			setCurrentStepLabel("Forma de Pagamento");
			setNextStepLabel("Dados de Faturamento");			
		} // end if	
		else if (nextStepNumber == 4) {
			setCurrentStepLabel("Dados de Faturamento");
			setNextStepLabel("Informações Extras");			
		} // end if		
		else if (nextStepNumber == 5) {
			setCurrentStepLabel("Informações Extras");
			setNextStepLabel("Confirmar solicitação");			
		} // end if				
		
	} // end else
	
} // end if
else if (direction == 'previous') {
	
	// set next step
	const nextStepNumber = step - 1;

	// Dinamically proccess function
	eval('setStep'+currentStep+'(false)');
	setProgress(progress-initialPercent);
	setCurrentStep(nextStepNumber);	
	
	// Set Label on Step by Step	
	if (nextStepNumber == 1) {
		setCurrentStepLabel("Dia e Horário do serviço");
		setNextStepLabel("Endereço do Local");			
	} // end if	
	else if (nextStepNumber == 2) {
		setCurrentStepLabel("Endereço do Local");
		setNextStepLabel("Forma de pagamento");			
	} // end if	
	else if (nextStepNumber == 3) {
		setCurrentStepLabel("Forma de Pagamento");
		setNextStepLabel("Dados de Faturamento");			
	} // end if	
	else if (nextStepNumber == 4) {
		setCurrentStepLabel("Dados de Faturamento");
		setNextStepLabel("Informações Extras");			
	} // end if		
	else if (nextStepNumber == 5) {
		setCurrentStepLabel("Informações Extras");
		setNextStepLabel("Confirmar solicitação");			
	} // end if		
	
} // end else

// Scroll to Bottm after pressed button
/*setTimeout(function(){
	scrollToBottom();
},500);*/

};

// Change Step Function Next and Previous
const toggleDialog = (target,status) => {

	// set which dialog to open or close
	setDialog(target,status);

};

// Will Mount Component HOOK
useComponentWillMount(() => {
	
    // Set session of app
  var sessionCheck = sessionStorage.getItem('@motuum/appLaunched');
  
  // if session is not created, redirect to bootstrap. (avoid direct access using url)
  if (!sessionCheck) {
	  history.push("/");
  } // end if	
      
}, []); // end useComponentWillMount


// Validate if its ready to process service
const validateService = () => {
		
	// Check if terms was accepted
	if (!acceptTerms) {		
		setDialogMessage("Você deve aceitar os termos de uso para prosseguir.");
		setAlert(true);
		setButtonLoading(false);		
		return false;		
	} // end if		
	
	// Open confirmation alert
	toggleDialog('confirm',true);	
	
} // end const


// Process abd Create Quotation, BEFORE send to next Page to SEARCH for Provider...
const processService = () => {
	
	setButtonLoading(true); 
	
	// Check if terms was accepted
	if (!acceptTerms) {		
		setDialogMessage("Você deve aceitar os termos de uso para prosseguir.");
		setAlert(true);
		setButtonLoading(false);		
		return false;		
	} // end if		
	

	// set data
	var data = {
		hireServiceService:{
			...hireServiceService,
			serviceTotalPrice:serviceTotalPrice ? serviceTotalPrice : hireServiceService.Price.price,
			serviceDiscount:0
		},
		hireServiceSchedule:hireServiceSchedule,
		hireServiceAddress:hireServiceAddress,
		hireServicePayment:hireServicePayment,
		hireServiceInvoice:hireServiceInvoice, 
		hireServiceExtraInfo:hireServiceExtraInfo,
		userEmail: userData.email,
		userMobilePhone: userData.mobilePhone,
		PaymentGatewayCustomerId:userData.CustomerProfile.PaymentGatewayCustomerId
	};	

	//[ DISPATCH ACTION ] checkEmail(NEXT STEP, DATA ,navigation OBJ);
	//When create Service Quotation might approved payment on IUGU and also create billing
	// If Payment Fail, might alert user to try another credit card...
	var props = {
		navigate:navigate,
		setDialogTitle:setDialogTitle,
		setDialogMessage:setDialogMessage,
		setAlert:setAlert,
	}		
	createServiceQuotation(data,props);
	
} // end const


// Open terms content
const openTerms = () => {
 
	setModal(true);

	// Retrieve content from API
	var props = {
		setDialogMessage:setDialogMessage,
		setAlert:setDialog
	}	
	var data = {}
	if (!termsContent) {
		retrieveTermsContent(data,props);
	} // end if

};


var formatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

// Return MAIN content
return (
	<Fragment>
						
			<Header title={hireServiceService.name} buttons={{support:false,serviceHire:true,serviceName:hireServiceService.name,serviceCategory:hireServiceService.breadcrumb,rightFake:true,menu:false,close:false,back:true}}  />
			
			{/* Inner Loading */}
			{!contentVisible &&
					<Box className="loadingContent" style={{...screenStyleLoading}}>
							<Box mt={0} mx={0}>								
								<CircularProgress /> 
								<div> Carregando...Aguarde!</div>								
							</Box>	
					</Box>
			}						
			
			<Fade in={contentVisible} timeout={1000}  mountOnEnter unmountOnExit>			
			
				<Box className="mainSearchServicesView" style={{...screenStyle}} mt={0}>
										
					{/* Step Control Block */}
					<Box style={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center",paddingLeft:"30px",paddingRight:"30px",marginTop:"170px"}}  mb={2} >
					
						<Box>							
							<BorderLinearProgress variant="determinate" value={progress} style={{width:"88vw",boxShadow: "0px -10px 20px 0px #D6D6D6"}} />							
						</Box>
						
						<Box style={{marginTop:"10px"}} >							
							<div style={{textAlign:"center",color:"#636466",fontFamily:"Roboto",fontSize:"12px",fontWeight:"normal"}}>
								<strong>{currentStep > maxSteps ? maxSteps : currentStep}</strong>/{maxSteps}
							</div>					
						</Box>
					
					</Box>
					
					<Box style={{marginBottom:"10px",paddingLeft:"30px",paddingRight:"30px"}}> 

						<Card style={{minWidth: "90%",marginBottom:"20px",width:"100%",marginBottom:"20px",backgroundColor:"#ffffff",boxShadow: "0px 10px 20px 0px #0000002A",borderRadius:"10px"}}>
						
						<AnimateHeight
						  duration={ 500 }
						  height={ heightPanel }
						>	
						  <CardContent style={{padding:"25px",paddingBottom:"0px"}}>
							
							<Box >
								
								<Box style={{padding:"0px"}}>
									
										<div style={{textAlign:"left"}}>
											<h3 style={{color:"#003CFA",fontFamily:"Roboto",fontWeight:"bold",fontSize:"16px",marginTop:"0px",marginBottom:"5px",lineHeight:"18px"}}>{hireServiceService.name}</h3>
											<div style={{minHeight:"54px",color:"#636466",fontFamily:"Roboto",fontWeight:"normal",fontSize:"12px",lineHeight:"18px"}}>
												{hireServiceService.description}
											</div>
										</div>											
									
								</Box>	
							
									<Box style={{padding:"0px",marginTop:"15px"}}>
										
											<div style={{textAlign:"left"}}>
												<h3 style={{color:"#003CFA",fontFamily:"Roboto",fontWeight:"bold",fontSize:"16px",marginTop:"0px",marginBottom:"5px",lineHeight:"18px"}}>Garantia do Serviço</h3>
												<div style={{color:"#636466",fontFamily:"Roboto",fontWeight:"normal",fontSize:"12px",lineHeight:"18px"}}>
													{hireServiceService.warrantyTerm}
												</div>
											</div>											
										
									</Box>									
								
							
							</Box>
							
						  </CardContent>
						  </AnimateHeight>
							
							<CardActions style={{display:"flex",flexDirection:"row",backgroundColor:"#003CFA10",padding:"2px"}}>
									  
							  <div style={{flex:1,textAlign:"center"}}>
									
									{heightPanel == 0 &&
									<Button style={{color:"#003CFA",fontFamily:"Roboto",fontWeight:"normal",fontSize:"12px"}} size="small" onClick={()=>setHeightPanel(220)}>										
										<img src={plusIconBlue} style={{width:"18px",height:"18px",marginRight:"5px"}} />
										<div>Ver mais</div>										
									</Button>	
									}									
									
									{heightPanel > 0 &&
									<Button style={{color:"#003CFA",fontFamily:"Roboto",fontWeight:"normal",fontSize:"12px"}} size="small" onClick={()=>setHeightPanel(0)}>										
										<img src={lessIconBlue} style={{width:"18px",height:"18px",marginRight:"5px"}} />
										<div>Ver menos</div>										
									</Button>
									}
									
								</div>
								
							  </CardActions>										

						</Card>	
						

					</Box>		
					
					<Divider light style={{marginLeft:"30px",marginRight:"30px"}} />

					{/* Price Box */}	
					<Box mt={1} ml={1} mr={1} mb={1} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row",paddingLeft:"30px",paddingRight:"30px"}}>
					
						<Box style={{flex:2}}>
						
							<Box style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>    
								<div style={{marginRight:"5px"}}> 
									<img src={priceIconBlue} style={{width:"30px",height:"30px"}} />
								</div>
								
								<div> 
									<div style={{fontFamily:"Roboto",fontWeight:"regular",fontSize:"12px",color:"#636466",textTransform:"uppercase"}}>Valor do serviço</div>
								</div>
							</Box>									
						
						</Box>
						
						<Box style={{flex:1}}>								
								<Box style={{fontFamily:"Roboto",fontWeight:"bold",fontSize:"16px",padding:"2px",color:"#003CFA",textAlign:"right"}}>
									{hireServiceService.Price ? formatter.format(hireServiceService.Price[0].price) : "carregando..."}
								</Box>								
						</Box>								
					
					</Box>							
				
					<Divider light style={{marginLeft:"30px",marginRight:"30px"}} />	
					
					{/* Urgent Price Box */}	
					{hireServiceSchedule.scheduledType == 3 &&
					<>
						<Box mt={1} ml={1} mr={1} mb={1} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row",paddingLeft:"30px",paddingRight:"30px"}}>
						
							<Box style={{flex:2}}>
							
								<Box style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>    
									<div style={{marginRight:"5px"}}> 
										<img src={priceIconGreen} style={{width:"30px",height:"30px"}} />
									</div>
									
									<div> 
										<div style={{fontFamily:"Roboto",fontWeight:"bold",fontSize:"12px",color:"#1EC300",textTransform:"uppercase"}}>Taxa Extra (Urgência)</div>
									</div>
								</Box>									
							
							</Box>
							
							<Box style={{flex:1}}>								
									<Box style={{fontFamily:"Roboto",fontWeight:"bold",fontSize:"16px",padding:"2px",color:"#1EC300",textAlign:"right"}}>
										{hireServiceService.Price ? formatter.format(hireServiceService.Price[0].urgentTax) : "carregando..."}
									</Box>								
							</Box>								
						
						</Box>							
					
						<Divider light style={{marginLeft:"30px",marginRight:"30px"}} />
					</>
					}

					{/* Date and Time SELECTED Box */}
					<Slide direction="left" in={hireServiceSchedule.scheduledType && currentStep != 1} timeout={{ enter: 1000, exit: 300 }}   mountOnEnter unmountOnExit>
						<div>
							 <Box mt={1} ml={1} mr={1} mb={1} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row",paddingLeft:"30px",paddingRight:"30px"}} onClick={()=> {setCurrentStep(1);setProgress(20)} }>
							
								<Box style={{flex:1}}>
								
									<Box style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>    
										<div style={{marginRight:"5px"}}> 
											<img src={calendarIconBlue} style={{width:"30px",height:"30px"}} />
										</div>
										
										<div> 
											<div style={{fontFamily:"Roboto",fontWeight:"regular",fontSize:"12px",color:"#636466",textTransform:"uppercase"}}>DIA E HORÁRIO</div>
										</div>
									</Box>									
								
								</Box>
								
								<Box style={{flex:1.5}}>								
										<Box style={{fontFamily:"Roboto",fontWeight:"bold",fontSize:"16px",padding:"2px",color:"#003CFA",textAlign:"right"}}>
											<div dangerouslySetInnerHTML={{__html: 	hireServiceSchedule.selectedDateText}} style={{fontSize:"14px"}}></div>											
										</Box>								
								</Box>								
							
							</Box>
								
							<Divider light style={{marginLeft:"30px",marginRight:"30px"}} />	
						</div>
					</Slide>
					
										
					{/* Date and time Box STEP 1  */}
					<Slide direction="left" in={currentStep == 1} timeout={{ enter: 1000, exit: 300 }}   mountOnEnter unmountOnExit>
						<div>
							<Box mt={0} mb={0} style={{alignItems:"stretch",justifyContent:"center",display:"flex",flexDirection:"column",paddingLeft:"30px",paddingRight:"30px"}}>
										
									<div style={{color:"#003CFA",fontFamily:"Roboto",fontWeight:"bold",fontSize:"18px",marginTop:"20px",marginBottom:"20px",textAlign:"left"}}> Dia e horário </div>
										
									<DateDialog
										servicePrice={servicePrice}
										serviceUrgentTax={serviceUrgentTax}
										setServiceTotalPrice={setServiceTotalPrice}
										changeStep={changeStep}
										currentStep={currentStep}
									/>
									
							</Box>	
						</div>
					</Slide>


					{/* Address SELECTED Box */}
					<Slide direction="left" in={hireServiceAddress.streetName && currentStep != 2} timeout={{ enter: 1000, exit: 300 }}   mountOnEnter unmountOnExit>
						<div>
							<Box mt={1} ml={1} mr={1} mb={1} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row",paddingLeft:"30px",paddingRight:"30px"}} onClick={()=>{setCurrentStep(2);setProgress(40)}}>
							
								<Box style={{flex:1}}>
								
									<Box style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>    
										<div style={{marginRight:"5px"}}> 
											<img src={placeIconBlue} style={{width:"30px",height:"30px"}} />
										</div>
										
										<div> 
											<div style={{fontFamily:"Roboto",fontWeight:"regular",fontSize:"12px",color:"#636466",textTransform:"uppercase"}}>ENDEREÇO</div>
										</div>
									</Box>									
								
								</Box>
								
								<Box style={{flex:1.5}}>								
										<Box style={{fontFamily:"Roboto",fontWeight:"bold",fontSize:"16px",padding:"2px",color:"#003CFA",textAlign:"right"}}>
											{hireServiceAddress.resumedAddress}
										</Box>								
								</Box>								
							
							</Box>							 
								
							<Divider light style={{marginLeft:"30px",marginRight:"30px"}} />	
						</div>
					</Slide>
										
					{/* Address Box STEP 2  */}
					<Slide direction="left" in={currentStep == 2} timeout={{ enter: 1000, exit: 300 }}   mountOnEnter unmountOnExit>
						<div>
							<Box mt={0} mb={0} style={{alignItems:"stretch",justifyContent:"center",display:"flex",flexDirection:"column",paddingLeft:"30px",paddingRight:"30px"}}>
										
									<div style={{color:"#003CFA",fontFamily:"Roboto",fontWeight:"bold",fontSize:"18px",marginTop:"20px",marginBottom:"20px",textAlign:"left"}}> Endereço </div>
																
									<AddressDialog 
										setAlert={setAlert}
										setDialogMessage={setDialogMessage}
										setDialogTitle={setDialogTitle}
										changeStep={changeStep}
										currentStep={currentStep}
									/>
									
							</Box>	
						</div>
					</Slide>


					{/* Payment SELECTED Box */}
					<Slide direction="left" in={hireServicePayment.id && currentStep != 3} timeout={{ enter: 1000, exit: 300 }}   mountOnEnter unmountOnExit>
						<div>
							<Box mt={1} ml={1} mr={1} mb={1} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row",paddingLeft:"30px",paddingRight:"30px"}}  onClick={()=>{setCurrentStep(3);setProgress(60)}}>
							
								<Box style={{flex:1}}>
								
									<Box style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>    
										<div style={{marginRight:"5px"}}> 
											<img src={cardIconBlue} style={{width:"30px",height:"30px"}} />
										</div>
										
										<div> 
											<div style={{fontFamily:"Roboto",fontWeight:"regular",fontSize:"12px",color:"#636466",textTransform:"uppercase"}}>PAGAMENTO</div>
										</div>
									</Box>									
								
								</Box>
								
								<Box style={{flex:1.5}}>								
										<Box style={{fontFamily:"Roboto",fontWeight:"bold",fontSize:"16px",padding:"2px",color:"#003CFA",textAlign:"right",display:"flex",alignItems:"flex-end",flexDirection:"column",justifyContent:"center"}}>
											<div style={{alignItems:"right",justifyContent:"flex-start",flexDirection:"column",display:"flex"}}>
												<span>Cartão de crédito</span> 
												<span>{hireServicePayment.referenceNumber}</span> 
												<span><img src={hireServicePayment.cardFlag == "visa" ? visa : hireServicePayment.cardFlag == "mastercard" ? mastercard : hireServicePayment.cardFlag == "amex" ? amex : hireServicePayment.cardFlag == "diners" ? diners : hireServicePayment.cardFlag == "elo" ? elo : genericFlag} alt="{hireServicePayment.cardFlag}" style={{width:"30px"}} /></span>											
											</div>										
										</Box>								
								</Box>								
							
							</Box>						 
								
							<Divider light style={{marginLeft:"30px",marginRight:"30px"}} />	
						</div>
					</Slide>
										
					{/* Payment Box STEP 3  */}
					<Slide direction="left" in={currentStep == 3} timeout={{ enter: 1000, exit: 300 }}   mountOnEnter unmountOnExit>
						<div>
							<Box mt={0} mb={0} style={{alignItems:"stretch",justifyContent:"center",display:"flex",flexDirection:"column",paddingLeft:"30px",paddingRight:"30px"}}>
										
									<div style={{color:"#003CFA",fontFamily:"Roboto",fontWeight:"bold",fontSize:"18px",marginTop:"20px",marginBottom:"20px",textAlign:"left"}}> Forma de Pagamento </div>
																
									<PaymentDialog
										setAlert={setAlert}
										setDialogMessage={setDialogMessage}
										setDialogTitle={setDialogTitle}
										changeStep={changeStep}
										currentStep={currentStep}
									/>
									
							</Box>	
						</div>
					</Slide>					
					

					{/* Invoice SELECTED Box */}
					<Slide direction="left" in={hireServiceInvoice.billingName && currentStep != 4} timeout={{ enter: 1000, exit: 300 }}   mountOnEnter unmountOnExit>
						<div>
							<Box mt={1} ml={1} mr={1} mb={1} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row",paddingLeft:"30px",paddingRight:"30px"}}  onClick={()=>{setCurrentStep(4);setProgress(80)}}>
							
								<Box style={{flex:1}}>
								
									<Box style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>    
										<div style={{marginRight:"5px"}}> 
											<img src={personIconBlue} style={{width:"30px",height:"30px"}} />
										</div>
										
										<div> 
											<div style={{fontFamily:"Roboto",fontWeight:"regular",fontSize:"12px",color:"#636466",textTransform:"uppercase"}}>FATURAMENTO</div>
										</div>
									</Box>									
								
								</Box>
								
								<Box style={{flex:1.5}}>								
										<Box style={{fontFamily:"Roboto",fontWeight:"bold",fontSize:"16px",padding:"2px",color:"#003CFA",textAlign:"right",display:"flex",alignItems:"flex-end",flexDirection:"column",justifyContent:"center"}}>
											{hireServiceInvoice.billingName}<br />
											<span style={{fontWeight:"normal"}}>{hireServiceInvoice.billingDocument}</span>
										</Box>								
								</Box>								
							
							</Box>							
								
							<Divider light style={{marginLeft:"30px",marginRight:"30px"}} />	
						</div>
					</Slide>
										
					{/* Invoice Box STEP 4 */}
					<Slide direction="left" in={currentStep == 4} timeout={{ enter: 1000, exit: 300 }}   mountOnEnter unmountOnExit>
						<div>
							<Box mt={0} mb={0} style={{alignItems:"stretch",justifyContent:"center",display:"flex",flexDirection:"column",paddingLeft:"30px",paddingRight:"30px"}}>
										
									<div style={{color:"#003CFA",fontFamily:"Roboto",fontWeight:"bold",fontSize:"18px",marginTop:"20px",marginBottom:"20px",textAlign:"left"}}> Faturamento </div>
																
									<InvoiceDialog
										setAlert={setAlert}
										setDialogMessage={setDialogMessage}
										setDialogTitle={setDialogTitle}
										changeStep={changeStep}	
										setStepAll={setStepAll}	
										currentStep={currentStep}
									/>
									
							</Box>	
						</div>
					</Slide>					


					{/* Extra Info SELECTED Box */}
					<Slide direction="left" in={currentStep != 5 && hireServiceExtraInfo.length > 0} timeout={{ enter: 1000, exit: 300 }}   mountOnEnter unmountOnExit>
						<div>
							<Box mt={0} mb={0} style={{alignItems:"stretch",justifyContent:"center",display:"flex",flexDirection:"column",paddingLeft:"30px",paddingRight:"30px"}} onClick={()=>{setCurrentStep(5);setStepAll(false);setProgress(100)}}>
										
									<div style={{color:"#003CFA",fontFamily:"Roboto",fontWeight:"bold",fontSize:"18px",marginTop:"20px",marginBottom:"20px",textAlign:"left"}}> Informações Extras </div>
																
									<ExtraDialog
										toggleDialog={toggleDialog}
										setAlert={setAlert}
										setDialogMessage={setDialogMessage}
										setDialogTitle={setDialogTitle}
										currentStep={currentStep}
										allSet={allSet}
										setStepAll={setStepAll}
										view={true}
										extraInfoDescription={extraInfoDescription}
										cameraImages={cameraImages}
									/>
									
							</Box> 
						</div>
					</Slide>
			
										
					{/* Extra Info Box STEP 4 */}
					<Slide direction="left" in={currentStep == 5} timeout={{ enter: 1000, exit: 300 }}   mountOnEnter unmountOnExit>
						<div>
							<Box mt={0} mb={0} style={{alignItems:"stretch",justifyContent:"center",display:"flex",flexDirection:"column",paddingLeft:"30px",paddingRight:"30px"}}>
										
									<div style={{color:"#003CFA",fontFamily:"Roboto",fontWeight:"bold",fontSize:"18px",marginTop:"20px",marginBottom:"20px",textAlign:"left"}}> Informações Extras </div>
																
									<ExtraDialog 
										toggleDialog={toggleDialog}
										setAlert={setAlert}
										setDialogMessage={setDialogMessage}
										setDialogTitle={setDialogTitle}
										currentStep={currentStep}
										allSet={allSet}
										setStepAll={setStepAll}
										changeStep={changeStep}
										view={false}
										extraInfoDescription={extraInfoDescription}
										cameraImages={cameraImages}		
										setExtraInfoDescription={setExtraInfoDescription}
										setCameraImages={setCameraImages}												
									/>
									
							</Box> 
						</div>
					</Slide>
					
					
					{/* Terms and Buttons Block */}	
					<Slide direction="left" in={allSet} timeout={{ enter: 1000, exit: 300 }} mountOnEnter unmountOnExit>
					
						<Box style={{backgroundColor:"transparent",display:"flex",width:"100%",alignItems:"center",position:"fixed",bottom:0,backgroundColor:"#ffffff",boxShadow: "0px -10px 15px 0px #0000001A",zIndex:"9"}}>		

								<Box style={{width:"100%"}}>
										
									<Box style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>	

										<Box style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>  

											<div style={{marginTop:"0px",marginBottom:"0px"}}>
											
											{ !buttonLoading &&
											<>
												  <Checkbox												
													checked={acceptTerms}
													onChange={handleAcceptTerms}
													inputProps={{ 'aria-label': 'primary checkbox' }}
												  />
												  
												  <span>
													Estou de acordo com os <span style={{color:"blue"}} onClick={()=> openTerms() }>Termos de uso</span>
												  </span>
											</>
											}									  
											
											</div>	 
											
										</Box>
										
										<Box style={{display:"flex",flexDirection:"row",justifyContent:"center"}} ml={0} mr={0} mb={2} mt={1}>			

											<Button variant="contained" style={{width:"100%",height:"53px",fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px",color:"#FFF",borderRadius:"10px",paddingLeft:"30px",paddingRight:"30px"}}  disabled={buttonLoading} onClick={(target,status) => validateService() }>
												{ !buttonLoading &&
												   <div>Buscar Profissional</div>
												}									
												{ buttonLoading &&
													<>
													   <CircularProgress size={30} color="inherit" />
													   <span style={{marginLeft:"10px"}}> Processando...</span>
												   </>
												}	
											</Button>								
												
										</Box>	

									</Box>	

								</Box>								
									
						</Box>						
					
					</Slide>					
								
				</Box>
				
				
			</Fade>    
			
			<ConfirmDialog processService ={processService} />
			<ConfirmServiceChangeDialog />
			<ServiceDetailDialog serviceId={hireServiceService.serviceId} />
	
			<AlertDialog 
				alert={alert}
				setAlert={setAlert}
				dialogMessage={dialogMessage}
				dialogTitle={dialogTitle}
			/>

			<TermsDialog 
				modal={modal}
				setModal={setModal}
			/>				
												
	</Fragment>
)};

const mapStateToProps = store => ({
	innerLoading:store.appReducer.innerLoading,
	termsContent:store.appReducer.termsContent,
	dialog:store.appReducer.dialog,
	userData:store.appReducer.userData,
	scheduledInfo:store.appReducer.scheduledInfo,
	hireServiceService:store.serviceReducer.hireServiceService,
	hireServiceSchedule:store.serviceReducer.hireServiceSchedule,
	hireServiceAddress:store.serviceReducer.hireServiceAddress,
	hireServicePayment:store.serviceReducer.hireServicePayment,
	hireServiceInvoice:store.serviceReducer.hireServiceInvoice,
	hireServiceExtraInfo:store.serviceReducer.hireServiceExtraInfo,
	addressesCardsItens:store.serviceReducer.addressesCards,
	creditCardsItens:store.serviceReducer.creditCards,
	invoiceItens:store.serviceReducer.invoices,
	buttonLoading:store.serviceReducer.buttonLoading
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AppActions, ...ServiceActions }, dispatch);
    
// Transition Slide for Dialog SLIDE UP	
const TransitionSlideUp = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;  
});	

/* Date Dialog Component START */
export const DateDialog = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {
	

const screenStyleDialog = {
  alignItems: "stretch",
  justifyContent: "top",
  width:"100%",
  minHeight:"50vh",
  display:"flex",
  flexDirection:"column",
  backgroundColor:"transparent"
}; 
	
// set classes var
// const classes = useStyles()();	

// set vars from props
// Remember to add those who come from Redux
const { 
	innerLoading,
	setInnerLoading,
	setDialog,
	dialog,
	setScheduled,
	setSchedule,
	currentStep
} = props;	

/* Dialog END*/	

// add one day to today
const todayPlusTomorrow = add(new Date(), {
  days: 1
});
const tomorrow = format(todayPlusTomorrow, 'dd/MM/yyyy') // formatted
const todayPlusDays = add(new Date(), {
  days: 7
});
const maxdate = format(todayPlusDays, 'dd/MM/yyyy') // formatted

 const [maxDate, setMaxDate] = useState(todayPlusDays);
 const [minDate, setMinDate] = useState(todayPlusTomorrow);

const [acceptTerms, setAcceptTerms] = useState(false); // state for tab
const [selectedDate, handleDateChange] = useState(todayPlusTomorrow);
const [selectedPeriod, handlePeriodChange] = useState(1);
const [selectedPeriodToday, handlePeriodChangeToday] = useState(1);
const [scheduleType, handleScheduleChange] = useState(1);
const [acceptCharge, handleAcceptCharge] = useState(false); // state for tab

const [morningPeriod, setMorningPeriod] = useState(false); // state for tab
const [noonPeriod, setNoonPeriod] = useState(false); // state for tab
const [nightPeriod, setNightPeriod] = useState(false); // state for tab
const [urgentType, setUrgentType] = useState(false); // state for tab

const [dialogMessage, setDialogMessage] = useState(""); // state for tab
const [alert, setAlert] = useState(false); // state for tab

// To simulate behavior
const scheduled = true;
const scheduledTime = 1; // 1 manha, 2 tarde, 3 night
const today = false;
const urgent = false; 
let hourText = "";
let dateText = "";

const periodArray = new Array();
periodArray[1] = "09:00 ?s 13:00 (manhã)";
periodArray[2] = "13:00 ?s 18:00 (tarde)";
periodArray[3] = "18:00 ?s 21:00 (noite)";

// Set functions
const handleAcceptTerms = (event) => {
	const newValue = event.target.checked
	setAcceptTerms(newValue);	
};	

const selectServiceDate = (value) => {

	// Update selected date
	handleDateChange(value);	
	
};	

const confirmSchedule = (value) => {

	if (value == 1) {
		hourText = periodArray[selectedPeriod];
		const formattedDate = format(selectedDate, 'dd/MM/yyyy (iiii)',{locale: brLocale}) // formatted
		dateText = ""+formattedDate+" de "+hourText;
		
		// If normal, set total as price
		var totalPrice = parseInt(props.servicePrice);
		props.setServiceTotalPrice(totalPrice);
		
		var today = null;
		
	} // end if
	else if (value == 2) {
		hourText = periodArray[selectedPeriodToday];
		dateText = "<strong>Hoje</strong> de "+hourText;
		
		// If normal, set total as price
		var totalPrice = parseInt(props.servicePrice);
		props.setServiceTotalPrice(totalPrice);
		
		var today = moment().format("YYYY-MM-DD");
		
	} // end if
	else if (value == 3) {
		dateText = "<strong>Urgente</strong> em até 2h assim que finalizado o pedido";
		
		// check of terms of urgency was accepted
		if (!acceptCharge) {			
			setDialogMessage("Para prosseguir, é necessário que aceite os termos da taxa extra para serviços urgentes.");
			setAlert(true);
			return;			
		} // end if
		
		// Check if today is after  16:00. Dont allow urgnet service
		let currentTime = moment().format("HH:mm:ss");
		let format = "HH:mm:ss";
		let limitTime = moment('16:00:00', format).utcOffset('-0300');		
		let checkTime = moment().isAfter(limitTime, 'hour');
		
		// check of terms of urgency was accepted
		/*if (checkTime) {			
			setDialogMessage("O horário para agendamentos urgentes ? até as 16:00. Tente outra op??o.");
			setAlert(true);
			return;			
		} // end if */	

		// If URGENT, add Urgent Tax to TOTAL amount	
		var totalPrice = parseInt(props.servicePrice) + parseInt(props.serviceUrgentTax);
		props.setServiceTotalPrice(totalPrice);
		
		var today = moment().format("YYYY-MM-DD");
		
	} // end if	

	// Set Service Schedule
	let data = {
		scheduledType:scheduleType,
		scheduledPeriod:selectedPeriod,
		selectedDateText:dateText,
		selectedDate:today ? today : selectedDate
	}
	setSchedule(data);

	// Close Dialog
	setDialog('schedule',false);
	props.changeStep('next',1);
	
};	

var formatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

const showHourRestriction = (type) => {

	if (type == 'today') {
		var message = "Para solicitar um serviço para o mesmo dia é necessário solicitar com 4 horas de anteced?ncia ao período desejado. Tente novamente em outro horário.";	
	} // end if
	else if (type == 'urgent') {
		var message = "Serviços com prazo urgente são agendados para atendimento em até duas horas. Você pode solicitar serviços urgentes de 08:00 até 16:00.";		
	} // end else if	
	setDialogMessage(message);
	setAlert(true);
	
}

// Run after mount component
useEffect(() => {
	
	var currentHour = moment().format("HH");
		
	var morningFlag = false;
	var noonFlag = false;
	var nightFlag = false;
	
	if (currentHour <= "05") {
		setMorningPeriod(true);
		morningFlag = true;
	} // end if
	
	if (currentHour <= "09") {
		setNoonPeriod(true);
		noonFlag = true;
	} // end if	
	
	if (currentHour <= "14") {
		setNightPeriod(true);
		nightFlag = true;
	} // end if	
	
	// FOR TESTING...	
		setMorningPeriod(true);
		morningFlag = true;

		setNoonPeriod(true);
		noonFlag = true;

		setNightPeriod(true);
		nightFlag = true;
	
 
	// Set today schedule period
	if (morningFlag) { handlePeriodChange(1) }
	else if (!morningFlag && noonFlag) { handlePeriodChange(2) }
	else if (!morningFlag && !noonFlag) { handlePeriodChange(3) }
	
	if (currentHour >= "08" && currentHour < "16") {
		setUrgentType(true);
	}
	
	// FOR TESTING...
	setUrgentType(true);
	
	if (morningFlag) {
		handlePeriodChangeToday(1);
	} // end if
	else {
		if (noonFlag) {
				handlePeriodChangeToday(2);
		} // end if
		else {
			handlePeriodChangeToday(3);
		} // end else 	
	} // end else
	
}, []); // end useEffect



	return (
	<div>
	{/*<LocalizationProvider dateAdapter={DateAdapter} utils={DateFnsUtils} locale={brLocale}>*/}
		<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={brLocale} localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}>
			<Box style={{...screenStyleDialog}} m={0}>
			
				<Box mt={0} ml={0} mr={0}>
					
					<Box style={{marginBottom:"5px",alignItems:"stretch",justifyContent:"flex-start",display:"flex",flexDirection:"column"}} p={0}>

								<div style={{flex:1,alignItems:"stretch",justifyContent:"flex-start",display:"flex",flexDirection:"column"}}>    
									
										<div style={{marginBottom:"10px",textAlign:"left",color:"#636466",fontFamily:"Roboto",fontWeight:"bold",fontSize:"12px"}}> Defina pra quando quer o serviço </div>
										
										<ButtonGroup color="secondary" aria-label="outlined primary button group" style={{display:"flex",flexDirection:"row",backgroundColor:"#FFFFFF"}}>
										
										  <Button variant={ scheduleType == 1 ? "contained" : ""} style={{flex:1,fontFamily:"Roboto",fontWeight:"regular",fontSize:"12px"}} onClick={(type)=>handleScheduleChange(1)}>Agendado</Button>
										
										{(morningPeriod || noonPeriod || nightPeriod) &&
											<Button variant={ scheduleType == 2 ? "contained" : ""} style={{flex:1,fontFamily:"Roboto",fontWeight:"regular",fontSize:"12px"}} onClick={(type)=>handleScheduleChange(2)}>Para Hoje</Button>
										}
										
										{(!morningPeriod && !noonPeriod && !nightPeriod) &&
											<Button variant="" style={{flex:1,fontFamily:"Roboto",fontWeight:"regular",fontSize:"12px"}} onClick={()=>showHourRestriction('today')}>Para Hoje</Button>
										}										
										  
										{urgentType &&  
										  <Button variant={ scheduleType == 3 ? "contained" : ""} style={{flex:1,fontFamily:"Roboto",fontWeight:"regular",fontSize:"12px"}} onClick={(type)=>handleScheduleChange(3)}>Urgente</Button>
										}
										
										{!urgentType &&
											<Button variant="" style={{flex:1,fontFamily:"Roboto",fontWeight:"regular",fontSize:"12px"}} onClick={()=>showHourRestriction('urgent')}>URGENTE</Button>
										}											
										  
										</ButtonGroup>
										
									
								</div>								
					</Box>
					
						
					{/* Scheduled Date */}						
					<Fade in={scheduleType == 1} timeout={{ enter: 1000, exit: 0 }} mountOnEnter unmountOnExit>			
							
						<div>
								
							{/* Scheduled - Day Selection */}
							<Box style={{marginTop:"26px",marginBottom:"5px",alignItems:"stretch",justifyContent:"flex-start",display:"flex",flexDirection:"column"}} p={0}>	
							
										<div style={{flex:1,alignItems:"stretch",justifyContent:"flex-start",display:"flex",flexDirection:"column"}}>    

											{/*<DatePicker
													disablePast
													disableToolbar
													autoOk
													format="dd/MM/yyyy (iiii)"
													minDate={minDate} // min date is tomorrow
													maxDate={maxDate} // calculate today + 10 days
													inputVariant="outlined"
													cancelLabel="Cancelar"
													label="Dia do serviço"
													value={selectedDate}
													onChange={selectServiceDate}
												/>*/}

												 <DatePicker
														disablePast
														openTo="day"
														views={['year', 'month', 'day']}
														label="Dia do serviço"
														value={selectedDate}
														onChange={selectServiceDate}
														minDate={minDate}
														maxDate={maxDate}
														renderInput={(params) => <TextField {...params} helperText={null} />}
													  />
												  
										</div>
										
							</Box>	
														
							{/* Scheduled - Period Selection */}
							<Box style={{marginTop:"21px",marginBottom:"5px",alignItems:"stretch",justifyContent:"flex-start",display:"flex",flexDirection:"column"}} p={0}>	

										<div style={{flex:1,alignItems:"stretch",justifyContent:"flex-start",display:"flex",flexDirection:"column"}}>    
											
												<div style={{marginBottom:"10px",textAlign:"left",color:"#636466",fontFamily:"Roboto",fontWeight:"bold",fontSize:"12px"}}>  Defina o período do dia </div>
										
												<ButtonGroup color="secondary" aria-label="outlined primary button group" style={{display:"flex",flexDirection:"row",alignItems:"stretch",backgroundColor:"#FFFFFF"}}>
													  <Button variant={ selectedPeriod == 1 ? "contained" : ""} style={{flex:1,fontFamily:"Roboto",fontWeight:"regular",fontSize:"12px"}} onClick={(period)=>handlePeriodChange(1)}>Manhã</Button>
													  <Button variant={ selectedPeriod == 2 ? "contained" : ""} style={{flex:1,fontFamily:"Roboto",fontWeight:"regular",fontSize:"12px"}} onClick={(period)=>handlePeriodChange(2)}>Tarde</Button>
													  <Button variant={ selectedPeriod == 3 ? "contained" : ""} style={{flex:1,fontFamily:"Roboto",fontWeight:"regular",fontSize:"12px"}} onClick={(period)=>handlePeriodChange(3)}>Noite</Button>
												</ButtonGroup>
											
										</div>	


										<Box mt={2} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>  
												<div style={{marginRight:"10px"}}> 
													<Info style={{fontSize:"1.5rem",color:"#555"}} />
												</div>
												
												<div> 
												
												<Fade in={selectedPeriod == 1} timeout={{ enter: 1000, exit: 0 }}  mountOnEnter unmountOnExit>			
													<div style={{fontFamily:"Roboto",fontWeight:"regular",fontSize:"12px",textAlign:"left"}}>Entre 09:00 e 13:00</div>
												</Fade>
												
												<Fade in={selectedPeriod == 2} timeout={{ enter: 1000, exit: 0 }} mountOnEnter unmountOnExit>			
													<div style={{fontFamily:"Roboto",fontWeight:"regular",fontSize:"12px",textAlign:"left"}}>Entre 13:00 e 18:00</div>
												</Fade>

												<Fade in={selectedPeriod == 3} timeout={{ enter: 1000, exit: 0 }} mountOnEnter unmountOnExit>			
													<div style={{fontFamily:"Roboto",fontWeight:"regular",fontSize:"12px",textAlign:"left"}}>Entre 18:00 e 21:00</div>
												</Fade>												
													
													
												</div>
										</Box>	

							</Box>	
							
						
						</div>
						
					</Fade>
					
					{/* For Today Date */}						
					<Fade in={scheduleType == 2} timeout={{ enter: 1000, exit: 0 }} mountOnEnter unmountOnExit>			
							
						<div>
							
							{/* Scheduled - Period Selection */}
							<Box style={{marginTop:"26px",marginBottom:"5px",alignItems:"stretch",justifyContent:"flex-start",display:"flex",flexDirection:"column"}} p={0}>	

										<div style={{flex:1,alignItems:"stretch",justifyContent:"flex-start",display:"flex",flexDirection:"column"}}>    
																							
												<div style={{marginBottom:"10px",textAlign:"left",color:"#636466",fontFamily:"Roboto",fontWeight:"bold",fontSize:"12px"}}>  Defina o período do dia </div>
												
												<ButtonGroup color="secondary" aria-label="outlined primary button group" style={{display:"flex",flexDirection:"row",alignItems:"stretch",backgroundColor:"#FFFFFF"}}>
												
												{morningPeriod &&
													  <Button variant={ selectedPeriodToday == 1 ? "contained" : ""} style={{flex:1,fontFamily:"Roboto",fontWeight:"regular",fontSize:"12px"}} onClick={(period)=>handlePeriodChangeToday(1)}>Manhã</Button>
												}
													  
													  
												{noonPeriod &&
													  <Button variant={ selectedPeriodToday == 2 ? "contained" : ""} style={{flex:1,fontFamily:"Roboto",fontWeight:"regular",fontSize:"12px"}} onClick={(period)=>handlePeriodChangeToday(2)}>Tarde</Button>
												}
													  
												{nightPeriod &&
													  <Button variant={ selectedPeriodToday == 3 ? "contained" : ""} style={{flex:1,fontFamily:"Roboto",fontWeight:"regular",fontSize:"12px"}} onClick={(period)=>handlePeriodChangeToday(3)}>Noite</Button>
												}
													  
												</ButtonGroup>
											
										</div>	

										<Box mt={2} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>  
												<div style={{marginRight:"10px"}}> 
													<Info style={{fontSize:"1.5rem",color:"#555"}} />
												</div>
												
												<div> 
												
												<Fade in={selectedPeriodToday == 1} timeout={{ enter: 1000, exit: 0 }}  mountOnEnter unmountOnExit>			
													<div style={{fontFamily:"Roboto",fontWeight:"regular",fontSize:"12px",textAlign:"left"}}>Entre 09:00 e 13:00</div>
												</Fade>
												
												<Fade in={selectedPeriodToday == 2} timeout={{ enter: 1000, exit: 0 }} mountOnEnter unmountOnExit>			
													<div style={{fontFamily:"Roboto",fontWeight:"regular",fontSize:"12px",textAlign:"left"}}>Entre 13:00 e 18:00</div>
												</Fade>

												<Fade in={selectedPeriodToday == 3} timeout={{ enter: 1000, exit: 0 }} mountOnEnter unmountOnExit>			
													<div style={{fontFamily:"Roboto",fontWeight:"regular",fontSize:"12px",textAlign:"left"}}>Entre 18:00 e 21:00</div>
												</Fade>												
													
													
												</div>
										</Box>	

							</Box>	

						</div>
						
					</Fade>
					
					{/* URGENT Date */}						
					<Fade in={scheduleType == 3} timeout={{ enter: 1000, exit: 0 }} mountOnEnter unmountOnExit>			
							
						<div>

							<Box mt={2}  mb={2} ml={2} mr={2} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>  									
									<div> 
										<div style={{fontFamily:"Roboto",fontWeight:"regular",fontSize:"12px",color:"#555555",textAlign:"left"}}>
										Serviços com prazo urgente são agendados para atendimento em até duas horas.
										</div>
									</div>
							</Box>	
					

							<Box mt={2} mb={2} ml={2} mr={2} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>  
									<div style={{marginRight:"10px"}}> 
										<img src={priceIconGreen} style={{width:"30px",height:"30px"}} />
									</div>
									
									<div> 
										<div style={{fontFamily:"Roboto",fontWeight:"regular",fontSize:"12px",color:"#555555",textAlign:"left"}}>TAXA EXTRA (URGÊNCIA): <strong>{formatter.format(props.serviceUrgentTax)}</strong></div>
									</div>
							</Box>	
							
							<Box mt={2} mb={2} ml={1} mr={2} style={{alignItems:"stretch",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>  

								<div style={{alignItems:"center",justifyContent:"center",display:"flex",flexDirection:"row"}}>
								
									  <div>
										  <Checkbox												
											checked={acceptCharge}
											onChange={(status)=>handleAcceptCharge(!acceptCharge)}
											inputProps={{ 'aria-label': 'primary checkbox' }}
										  />
									  </div>
									  
									  <div>
										Estou de acordo com a <strong>Taxa extra cobrada</strong>
									  </div>										
								
								</div>	 
								
							</Box>							
							

						</div>
						
					</Fade>	

					<Fade in={currentStep == 1} timeout={{ enter: 3000, exit: 0 }} mountOnEnter unmountOnExit>			
					<Box style={{backgroundColor:"transparent",display:"flex",width:"100%",alignItems:"center",position:"fixed",bottom:0,height:"100px",backgroundColor:"#ffffff",boxShadow: "0px -10px 15px 0px #0000001A",marginLeft:"-30px",zIndex:"9"}}>		

							<Box style={{width:"100%"}}>
									
								<Box style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>	

										<Button color="secondary" variant="contained" style={{width:"76%",height:"53px",fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px",color:"#FFF",borderRadius:"10px",paddingLeft:"30px",paddingRight:"30px"}} onClick={()=>confirmSchedule(scheduleType)}>
										  Confirmar
										</Button>	

								</Box>	

							</Box>								
								
					</Box>
					</Fade>
			
				</Box>				
			
			</Box>
			
			<AlertDialog 
				alert={alert}
				setAlert={setAlert}
				dialogMessage={dialogMessage}
			/>				

		 </LocalizationProvider>
	 
		 
		</div>		
	);
	
});
  
/* Address Dialog Component START */
export const AddressDialog = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {
	

const screenStyleLoading = {
  alignItems: "stretch",
  justifyContent: "top",
  width:"100%",
  minHeight:"50vh",
  display: "flex",
  flexDirection: "column"
};

const screenStyleDialog = {
  alignItems: "stretch",
  justifyContent: "top",
  width:"100%",
  display:"flex",
  flexDirection:"column",
  backgroundColor:"transparent"
}; 
	
// set classes var
// const classes = useStyles()();	

// set vars from props
// Remember to add those who come from Redux
const { 
	innerLoading,
	setInnerLoading,
	setDialog,
	dialog,
	setAddress,
	hireServiceAddress,
	saveAddress,
	retrieveAddresses,
	addressesCardsItens,
	currentStep
} = props;	

/* Dialog END*/	

const [selectedAddress, setSelectedAddress] = useState(false);
const [savingAddress, setSavingAddress] = useState(false);
const [loadingAddress, setLoadingAddress] = useState(true);

// Set existing addresses 
const [addressesCards,setAddressesCard] = useState(addressesCardsItens ? addressesCardsItens:[]);
const [addressesCatalog,setAddressesCatalog] = useState(false);
const [selectedAddressId, setSelectedAddressId] = useState("");
const [selectedAddressLabel, setSelectedAddressLabel] = useState("");
const [selectedStreet, setSelectedStreet] = useState("");
const [selectedStreetNumber, setSelectedStreetNumber] = useState("");
const [selectedStreetComplement, setSelectedStreetComplement] = useState("");
const [selectedPostalCode, setSelectedPostalCode] = useState("");
const [selectedStreetReference, setSelectedStreetReference] = useState("");
const [selectedSuburb, setSelectedSuburb] = useState("");
const [selectedCity, setSelectedCity] = useState("");
const [selectedUF, setSelectedUF] = useState("");
const [selectedUfCode, setSelectedUfCode] = useState("");
const [selectedCountry, setSelectedCountry] = useState("");
const [selectedCoordinates, setSelectedCoordinates] = useState("");
const [selectedPlaceId, setSelectedPlaceId] = useState("");
const [selectedMainAddress, setSelectedMainAddress] = useState(false);
const [addressURL, setAddressURL] = useState("");
const [complementNewAddress, setComplementNewAddress] = useState(false);
const [address, setPlaceAddress] = useState("");

const handleChange = address => {
	setPlaceAddress( address );
};
 
const selectAddress = (addressId) => {
	
	var array = [...addressesCards]; // clone
	var index = array.filter(item => item.id == addressId);
	var filteredCard = index[0];
	
	// Propagate to interface
	var resumedAddress = filteredCard.streetType+" "+
						 filteredCard.streetName+", "+
						 filteredCard.streetNumber+", "+
						 filteredCard.streetComplement+", "+
						 filteredCard.city+", "+
						 filteredCard.state;
				
	setSelectedAddressId(filteredCard.addressId); // set selected id
	setSelectedStreet(resumedAddress); // set street
	setSelectedSuburb(filteredCard.suburb); // set suburb
	setSelectedCity(filteredCard.city); // set city
	setSelectedUF(filteredCard.state); // set UF
	setSelectedCountry(filteredCard.country); // set UF
	setSelectedPlaceId(filteredCard.placeId); // set placeId

	// Set that address was selected
	setSelectedAddress(true);	
	
	// Set Redux Address
	setAddress({...filteredCard,resumedAddress:resumedAddress});		

	// Close Dialog
	setDialog('address',false);
	props.changeStep('next',2);
	
} // end const
 
// Selection using Googgle Places Query
const handleSelect = (address,placeId,suggestion) => {

// Try first
try {

// Separate street from rest
setSelectedStreet(suggestion.terms[0].value); // set street
setSelectedSuburb(suggestion.terms[1].value); // set suburb
setSelectedCity(suggestion.terms[2].value); // set city
setSelectedUF(suggestion.terms[3].value); // set UF
setSelectedUfCode(suggestion.terms[3].value);	
setSelectedCountry(suggestion.terms[4].value); // set UF
setSelectedPlaceId(placeId); // set placeId

// Set selected address to textFiel
setPlaceAddress( address );

// Set that address was selected
setSelectedAddress(true);
 
// Retrieve GeoCode by Address 
geocodeByAddress(address)
  .then(results => getLatLng(results[0]))
  .then(latLng => { 				
		setSelectedCoordinates(JSON.stringify(latLng));
		
		// Set google maps static URL
		let addressText = latLng.lat+","+latLng.lng;
		let finalAddress = "https://maps.googleapis.com/maps/api/staticmap?center="+addressText+"&zoom=11&size=328x100&maptype=roadmap&markers=color:blue%7Clabel:S%7C40.702147,-74.015794&markers=color:green%7Clabel:G%7C40.711614,-74.012318&markers=color:red%7Clabel:C%7C40.718217,-73.998284&key=AIzaSyBdE3uu0p9cJ_961a03aXMo0kGSzuUfTVE";		
		setAddressURL(finalAddress);

	})
  .catch(error => console.error('Error', error));
  
} catch (e) {
	
		props.setDialogMessage("Ocorreu um problema com o endereço selecionado. Adicione mais informações para que apareça a rua, cidade, bairro e estado. Endereços parciais não são aceitos.");
		props.setAlert(true);
	
} // end catch
    
};

const saveAddressAction = (props) => {

	// Check if required fields are OK
	if (!selectedStreetNumber || !selectedAddressLabel) {		
		props.setDialogMessage("Preencha todos os campos marcados com * para prosseguir.");
		props.setAlert(true);	
		return false;		
	} // end if	
	
	var postalCodeCheck = selectedPostalCode.replace(/\D/g,"");
	if (postalCodeCheck.length < 8 && postalCodeCheck) {		
		props.setDialogMessage("Preencha o CEP corretamente.");
		props.setAlert(true);	
		return false;		
	} // end if	
	
	// Set loading on...
	setSavingAddress(true);
		
	// Break Street Address into streetType and streetName
	var tmp = selectedStreet.split(" ");
	var streetType = tmp[0].replace(".","");
	var streetName = selectedStreet.substr(tmp[0].length + 1,(selectedStreet.length - tmp[0].length) ); 
		
	// Set Full Adress
	var fullAddress = selectedStreet+", "+selectedStreetNumber+", "+selectedStreetComplement+", "+selectedCity+", "+selectedUfCode+", "+selectedSuburb+", "+selectedCountry;
	
	// Set Redux Address
	var addressObj = {
		label:selectedAddressLabel,
		streetType:streetType,
		streetName:streetName,
		streetNumber:selectedStreetNumber,
		streetComplement:selectedStreetComplement,
		postalCode:postalCodeCheck,
		suburb:selectedSuburb,
		city:selectedCity,
		state:selectedUfCode,
		country:selectedCountry,
		fullAddress:fullAddress,
		googleCoordinates:selectedCoordinates,
		googlePlaceId:selectedPlaceId,
		mainAddress:selectedMainAddress 		
	}	
	var props = {
		setSelectedAddressId:setSelectedAddressId,
		setAddressesCard:setAddressesCard,
		addressesCards:addressesCards,
		setSavingAddress:setSavingAddress,
		selectAddress:selectAddress,
		setAlert:props.setAlert,
		setDialogMessage:props.setDialogMessage,
		setDialogTitle:props.setDialogTitle,
		setDialog:setDialog,		
		setComplementNewAddress:setComplementNewAddress,
		setAddressesCatalog:setAddressesCatalog,
		cleanAddress:cleanAddress		
	}		
	saveAddress(addressObj,props);
};	

const cleanAddress = () => {
	
	setPlaceAddress("");
	setSelectedAddress(false);	
		
	setSelectedStreet(""); 
	setSelectedSuburb("");
	setSelectedCity(""); 
	setSelectedUF("");
	setSelectedCountry("");
	setSelectedPlaceId(""); 

}

const handleAcceptTerms = (event) => {

const newValue = event.target.checked
setSelectedMainAddress(newValue);

}; 

// Run after mount component
useEffect(() => {


}, []); // end useEffect

// useEffect only for dialgo changes
useEffect(() => {
		
		// Retrieve Addresses from API
		var propsParam = {
			setAddressesCard:setAddressesCard,
			addressesCards:addressesCards,
			setLoadingAddress:setLoadingAddress,
			setAlert:props.setAlert,
			setDialogMessage:props.setDialogMessage,
			setDialogTitle:props.setDialogTitle,
			setDialog:props.setDialog,
			setAddressesCatalog:setAddressesCatalog
		}		
		retrieveAddresses(propsParam);			
		
		// Set Address Catalog ON if there is any address
		if (addressesCards.length > 0) {
			setAddressesCatalog(true);
		} // end if


}, [dialog]); // end useEffect only for dialgo changes

const handleKeypress = e => {
	
	 //it triggers by pressing the enter key  
	if (e.key === "Enter") {
	  saveAddressAction(props);
	}

};

return (
	<div>		
			<Box style={{...screenStyleDialog}} m={0}>
			
				{loadingAddress && 
					<Box style={{...screenStyleLoading}}>
							<Box mt={0} mx={0} style={{textAlign:"center"}}>								
								<CircularProgress /> 
								<div> Carregando endereços...Aguarde!</div>								
							</Box>	
					</Box>
				}
			
				{!loadingAddress &&
				<>				
				<Box mt={0} ml={0} mr={0} style={{height:"79vh"}}>

					{/* Show button for catalog */}	
					{/* ( (addressesCards.length > 0 && !addressesCatalog) ) &&					
					<Box p={0} style={{display:"flex",flexDirection:"row",alignItems:"stretch",justifyContent:"center",marginBottom:"15px"}}>
								
							<Button size="small" onClick={()=> setAddressesCatalog(true) }>										
								<LibraryBooksIcon style={{fontSize:"1.5rem",color: '#BBB',marginRight:"10px"}} />
								<div>Selecionar endereço do catélogo</div>										
							</Button>	
					
					</Box>
					*/}

					{/* Show existing address for selection */}	
					{ (addressesCards.length > 0 && addressesCatalog) &&					
					<>
					{/*<Box p={0} style={{display:"flex",flexDirection:"row",alignItems:"stretch",justifyContent:"center",marginBottom:"15px"}}>
								
							<Button size="small" onClick={()=> {setAddressesCatalog(false);cleanAddress()  } }>										
								<AddCircleIcon style={{fontSize:"1.5rem",color: '#BBB',marginRight:"10px"}} />
								<div>Adicionar novo endereço </div>										
							</Button>	
					
					</Box>*/}
					
					<Box p={0} style={{flex:1}}>
					
					<div style={{marginBottom:"10px",textAlign:"left",color:"#636466",fontFamily:"Roboto",fontWeight:"bold",fontSize:"12px"}}>  Selecione um endereço cadastrado </div>
					
							{addressesCards.map((item, index) => {

								return	(								
									<Card key={index} style={{minWidth: "90%",marginBottom:"20px",display:"flex",flexDirection:"row",width:"100%",marginBottom:"20px",backgroundColor:"#ffffff",boxShadow: "0px 10px 10px 0px #0000002A",borderRadius:"10px"}}>
									
									  <CardContent style={{padding:"5px"}}>
										
										<Box style={{display:"flex",flexdirection:"row",alignItems:"center",justifyContent:"start"}}>
											<Box m={1}>	
													<div style={{textAlign:"left",fontSize:"0.75rem"}}>
														<h3 style={{marginTop:"0px",marginBottom:"0px"}}>{item.label}</h3>
														<div>
															{item.fullAddress}
														</div>
													</div>
											</Box>	
										</Box>
									  </CardContent>
									  
										<CardActions style={{display:"flex",flexDirection:"row"}}>
											<div style={{flex:1,textAlign:"right"}}>
									
													<Button color="secondary" variant="contained" size="small" onClick={()=> selectAddress(item.id)}>			
														<div>Selecionar</div>										
													</Button>												
												
											</div>
										</CardActions>

									</Card>						
								)
							
							}
							
							)}					
					
					</Box>
					</>
					}
					
					{/* If there is no addresses open new address direct */}
					{ ( (addressesCards.length == 0 || !addressesCatalog) && !complementNewAddress) &&
					<Box p={0} style={{height:"60vh",marginTop:"5px"}}>

						<PlacesAutocomplete
							value={address}
							onChange={handleChange}
							onSelect={handleSelect}
							
						  >
							{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {	
							
								return (
							  <div>

							  <TextField 
							  id="outlined-basic"
							  label="Endereço do Local"
							  fullWidth
							  onClick={()=>cleanAddress()}
							  variant="outlined"
								{...getInputProps({
									placeholder: 'Digite o endereço ...'
								  })}
							  />
								<div style={{maxHeight:"260px",overflow:"auto",backgroundColor:"whitesmoke",padding:"5px",borderWidth:"1px",borderRadius:"10px",borderColor:"red"}}>
								  {loading && <div>Carregando...</div>}
								  {suggestions.map(suggestion => {
									const className = suggestion.active
									  ? 'suggestion-item--active'
									  : 'suggestion-item';
									// inline style for demonstration purpose
									const style = suggestion.active
									  ? { backgroundColor: '#fff', cursor: 'pointer',fontSize:"14px",marginBottom:"5px",borderRadius:"2px" }
									  : { backgroundColor: '#fff', cursor: 'pointer',fontSize:"14px",marginBottom:"5px",borderRadius:"2px" };
									return (
									  <div key={suggestion.index}
										{...getSuggestionItemProps(suggestion, {
										  className,
										  style,
										})}
									  >
										<span>{suggestion.description}</span>
									  </div>
									);
								  })}
								</div>
							  </div>
							)}
							
							}
						  </PlacesAutocomplete>
						  
						  { selectedAddress &&
						  <>
						  <div style={{margin:"0px",padding:"10px",borderWidth:"1px",borderColor:"gray",borderRadius:"10px",backgroundColor:"whitesmoke",textAlign:"left"}}>
							<div><strong>Rua:</strong> {selectedStreet} </div>
							<div><strong>Bairro:</strong> {selectedSuburb} </div>
							<div><strong>Cidade:</strong> {selectedCity} </div>
							<div><strong>UF:</strong> {selectedUfCode}</div>						  
							<div><strong>País:</strong> {selectedCountry}</div>							  
						  </div>
						  
							<Box mb={1} mt={1} style={{textAlign:"center"}}> 
								<img src={addressURL} style={{width:"100%",height:"100px",backgroundColor:"whitesmoke"}} />
							</Box>
							</>
						  }

					</Box>
					}
					
					{ (selectedAddress && complementNewAddress) &&
						<Box ml={0} mr={0} mb={5} mt={2} style={{height:"70vh"}}>
						
							<h3 style={{textAlign:"left",marginBottom:"5px"}}>Endereço selecionado</h3>
							<Divider variant="middle" style={{marginBottom:"10px"}} />
						
							<Box mb={1} mt={1} style={{textAlign:"left"}}> 
								<div> {selectedStreet}, {selectedSuburb}, {selectedCity} - {selectedUfCode} </div>							
							</Box>
							
							<Divider variant="middle" style={{marginBottom:"10px"}} />

							<TextField
							  label="Título do endereço *"
							  placeholder="Ex.: Minha casa, Casa dos meus pais, Trabalho..."
							  style={{ margin: 10,marginLeft:0,marginRight:0,backgroundColor:"#fff",width:"100%"}}
							  size="small"
							  variant="outlined"
							  type="text"
							  onKeyPress={handleKeypress}
							  value={selectedAddressLabel}
							  onChange={(event) => {
								  setSelectedAddressLabel(event.target.value)
							  }}							  
							/>	
									
							<div style={{display:"flex",flexDirection:"row",alignItems:"stretch",justifyContent:"center"}} >
							
								<TextField
								  label="Número *"
								  style={{flex:1, margin: 10,marginLeft:0,marginRight:10,backgroundColor:"#fff",width:"100%"}}
								  size="small"
								  variant="outlined"
								  type="number"
								  onKeyPress={handleKeypress}
								  value={selectedStreetNumber}
								  onChange={(event) => {
									  setSelectedStreetNumber(event.target.value)
								  }}									  
								/>
								
								<TextField
								  label="Complemento "
								  style={{flex:2, margin: 10,marginLeft:0,marginRight:0,backgroundColor:"#fff",width:"100%"}}
								  size="small"
								  variant="outlined"
								  type="text"
								  onKeyPress={handleKeypress}
								  value={selectedStreetComplement}
								  onChange={(event) => {
									  setSelectedStreetComplement(event.target.value)
								  }}									  
								/>
							
							</div>
							
							<div style={{display:"flex",flexDirection:"row",alignItems:"stretch",justifyContent:"center"}} >
															
								<InputMask
									mask="99999-999"
									onKeyPress={handleKeypress}
									value={selectedPostalCode}
									  onChange={(event) => {
										  setSelectedPostalCode(event.target.value)
									  }}
								  >
									<TextField
									  label="CEP "
									  style={{flex:1, margin: 10,marginLeft:0,marginRight:10,backgroundColor:"#fff",width:"100%"}}
									  size="small"
									  variant="outlined"
									 />
								</InputMask>									
								
								<TextField
								  label="Ponto de referência"
								  style={{ flex:2, margin: 10,marginLeft:0,marginRight:0,backgroundColor:"#fff",width:"100%"}}
								  size="small"
								  variant="outlined"
								  type="text"
								  onKeyPress={handleKeypress}
								  value={selectedStreetReference}
								  onChange={(event) => {
									  setSelectedStreetReference(event.target.value)
								  }}							  
								/>	
							
							</div>
						
									  
							{/*<div style={{marginTop:"10px",marginBottom:"10px"}}>
							
								  <Checkbox												
									checked={selectedMainAddress}
									onChange={handleAcceptTerms}
									inputProps={{ 'aria-label': 'primary checkbox' }}
								  />
								  
								  <span>
									Tornar esse endereço como principal
								  </span>
								  
							</div>*/}						
						
						</Box>
					}					
			
					
				</Box>					
					</>
					}	

			</Box>

			<Fade in={currentStep == 2} timeout={{ enter: 3000, exit: 0 }} mountOnEnter unmountOnExit>	
			<Box style={{backgroundColor:"transparent",display:"flex",width:"100%",alignItems:"center",position:"fixed",bottom:0,height:"100px",backgroundColor:"#ffffff",boxShadow: "0px -10px 15px 0px #0000001A",marginLeft:"-30px",zIndex:"9"}}>		

					<Box style={{width:"100%"}}>
							
						<Box style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>	

							{ (!complementNewAddress && addressesCatalog) &&
							<Button color="secondary" variant="contained" style={{width:"76%",height:"53px",fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px",color:"#FFF",borderRadius:"10px",paddingLeft:"30px",paddingRight:"30px"}} onClick={()=> {setAddressesCatalog(false);cleanAddress()  } }>
							 Novo endereço
							</Button>
							}	

							{ ( ((addressesCards.length > 0 && !addressesCatalog) && !complementNewAddress) || (selectedAddress && complementNewAddress) ) &&
							<Button color="secondary" disabled={savingAddress} variant="outlined" style={{width:"26%",height:"53px",fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px",color:"#003CFA",borderRadius:"10px",marginRight:"10px"}} onClick={()=>{ setComplementNewAddress(false);setAddressesCatalog(true);cleanAddress() } }>
							 Voltar
							</Button>
							}							

							{ ( (addressesCards.length == 0 || !addressesCatalog) && !complementNewAddress) &&
							<Button disabled={!selectedAddress} color="primary" variant="contained" style={{width:"64%",height:"53px",fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px",color:"#FFF",borderRadius:"10px",paddingLeft:"30px",paddingRight:"30px",opacity:!selectedAddress ? "0.5":"1"}} onClick={()=>setComplementNewAddress(true)}>
							  Pr?ximo
							</Button>
							}
							
							{ (selectedAddress && complementNewAddress) &&
							<Button disabled={!selectedAddress} color="primary" variant="contained" style={{width:"64%",height:"53px",fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px",color:"#FFF",borderRadius:"10px",paddingLeft:"30px",paddingRight:"30px",opacity:!selectedAddress ? "0.5":"1"}} onClick={()=>saveAddressAction(props)}>
								{ !savingAddress &&
								   <div>Confirmar</div>
								}
								
								{ savingAddress &&
								<>
								   <CircularProgress size={30} color="inherit" />
								   <span style={{marginLeft:"10px"}}>Gravando...</span>
								</>
								}	
							</Button>
							}	

						</Box>	

					</Box>								
						
			</Box>	
			</Fade>
			
		</div>
		
	);
	
	
});
  
/* Payment Dialog Component START */
export const PaymentDialog = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {
	

const screenStyleDialog = {
  alignItems: "stretch",
  justifyContent: "top",
  width:"100%",
  minHeight:"50vh",
  display:"flex",
  flexDirection:"column",
  backgroundColor:"transparent"
}; 


const screenStyleLoading = {
  alignItems: "middle",
  justifyContent: "center",
  width:"100%",
  display: "flex",
  flexDirection: "column"
};
	
// set classes var
// const classes = useStyles()();	

const { 
	innerLoading,
	setInnerLoading,
	setDialog,
	dialog,
	setPayment,
	creditCardsItens,
	setCreditCards,
	saveCard,
	retrieveCards,
	hireServicePayment,
	currentStep
} = props;	

const [selectedPayment, setSelectedCreditCard] = useState(false);
const [savingCard, setSavingCard] = useState(false);
const [loadingCards, setLoadingCards] = useState(true);

const [restrictAmex, setRestrictAmex] = useState(false);
const [maskedCvc, setMaskedCvc] = useState(false);

const [creditcardDate, setCreditcardDate] = useState("");
const [creditcardHolder, setCreditcardHolder] = useState("");
const [creditcardCvv, setCreditcardCvv] = useState("");
const [creditcardNumber, setCreditcardNumber] = useState("");
const [creditcardFlag, setCreditcardFlag] = useState("");
const [creditcardToken, setCreditcardToken] = useState("");

// Set existing addresses 
const [creditCards,setCreditCard] = useState(creditCardsItens ? creditCardsItens:[]);

const [creditCardCatalog,setCreditCardCatalog] = useState(false);
const [complementNewCard, setComplementNewCard] = useState(false);

const selectCreditCard = (creditCardId) => {
	
	var array = [...creditCards]; // clone
	var index = array.filter(item => item.id == creditCardId);
	var filteredCard = index[0];

	// Set that address was selected
	setSelectedCreditCard(true);
	
	// Set Redux Address
	setPayment({...filteredCard});		

	// Close Dialog
	setDialog('payment',false);
	props.changeStep('next',3);
	
} // end const
 
const handleAcceptTerms = (event) => {

const newValue = event.target.checked
setSelectedCreditCard(newValue);

}; 


// useEffect only for dialgo changes
useEffect(() => {
		
		// Setup IUGU
		window.Iugu.setAccountID("54EB3E591DFC322DF6B8D3FBBDAC7E0E");
		window.Iugu.setTestMode(true);		
		
		// Retrieve Addresses from API
		var propsParam = {
			setCreditCardCatalog:setCreditCardCatalog,
			setCreditCard:setCreditCard,
			creditCards:creditCards,
			setSavingCard:setSavingCard,
			setComplementNewCard:setComplementNewCard,
			setAlert:props.setAlert,
			setDialogMessage:props.setDialogMessage,
			setDialogTitle:props.setDialogTitle,
			setDialog:setDialog,
			setLoadingCards:setLoadingCards
		}		
		retrieveCards(propsParam);			
		
		// Set Address Catalog ON if there is any address
		if (creditCards.length > 0) {
			setCreditCardCatalog(true);
		} // end if		


}, [dialog]); // end useEffect only for dialgo changes


const saveCardAction = (props) => {

	// Check if required fields are OK
	if (!creditcardNumber || !creditcardDate || !creditcardHolder || !creditcardCvv) {
		props.setDialogTitle("Verifique as informações");		
		props.setDialogMessage("Preencha todos os campos marcados com * para prosseguir.");
		props.setAlert(true);	
		return false;		
	} // end if	
	
	// Validate creditcard number
	var creditCardNumberCheck = window.Iugu.utils.validateCreditCardNumber(creditcardNumber);
	if (!creditCardNumberCheck) {
		props.setDialogTitle("Verifique as informações");		
		props.setDialogMessage("Verifique o número do cartão de crédito informado.");
		props.setAlert(true);	
		return false;		
	} // end if		
	
	// Validate expiraiton date
	var tmp = creditcardDate.split("/");
	var expirationMonth = tmp[0];
	var expirationYear = tmp[1];
	var creditCardExpirationCheck = window.Iugu.utils.validateExpiration(expirationMonth,expirationYear);
	if (!creditCardExpirationCheck) {	
		props.setDialogTitle("Verifique as informações");
		props.setDialogMessage("Verifique a data de vencimento do cartão de crédito informado.");
		props.setAlert(true);	
		return false;		
	} // end if	

	// Validate expiraiton date
	var creditCardCVVCheck = window.Iugu.utils.validateCVV(creditcardCvv,creditcardFlag);
	if (!creditCardCVVCheck) {	
		props.setDialogTitle("Verifique as informações");	
		props.setDialogMessage("Verifique código de segurança do cartão de crédito informado.");
		props.setAlert(true);	
		return false;		
	} // end if			
	
	// Split first name from lastname
	var tmp = creditcardHolder.trim();
	var tmp2 = tmp.split(" ");
	var firstName = tmp2[0].replace(/[|&;$#%@"<>()+,]/g, "");; // set first name
	var lastName = tmp.substr(tmp2[0].length + 1,(tmp.length - tmp2[0].length) ).replace(/[|&;$%@"<>()+,]/g, ""); 
	
	// If user only inputed one name wuthout lastname or surname
	if (lastName.trim() == "" || lastName.trim().length < 2) {	
		props.setDialogTitle("Verifique as informações");
		props.setDialogMessage("Você precisa digitar o seu sobrenome ou um sobrenome válido.");
		props.setAlert(true);	
		return false;		
	} // end if			
	
	// Try to create IUGU token
	var cc = window.Iugu.CreditCard(creditcardNumber,expirationMonth, expirationYear,firstName,lastName,creditcardCvv);

	// Set loading on...
	setSavingCard(true);
	
	window.Iugu.createPaymentToken(cc, function(response) {
		
		if (response.errors) {
			props.setDialogMessage("Ocorreu um problema ao tentar gerar o token de seu cartão de crédito junto a IUGU. Tente novamente. Caso o problema persista, entra em contato com o suporte.");
			props.setAlert(true);	
			return false;
		} else {
						
			// Set Iugu Token
			setCreditcardToken(response.id);

				// Prepare credditcard dara to send to API
				var masked = "";
				for (var i = 0; i < creditcardNumber.length; i++) {
					masked += (i > 3 && i < creditcardNumber.length - 4) ? 'X' : creditcardNumber[i];
					if ((i + 1) % 4 == 0)
						masked += " ";
				}	
				var creditcardNumberMasked = masked;

				// Set Card Obj
				var cardObj = {
					creditcardHolder:creditcardHolder,
					creditcardNumber:creditcardNumberMasked,
					creditCardFlag:creditcardFlag,
					expirationDate:creditcardDate,
					creditcardToken:response.id,
					storeCreditcard:selectedPayment
				}	
				var propsParam = {
					setCreditCardCatalog:setCreditCardCatalog,
					setCreditCard:setCreditCard,
					creditCards:creditCards,
					setSavingCard:setSavingCard,
					setComplementNewCard:setComplementNewCard,
					setAlert:props.setAlert,
					setDialogMessage:props.setDialogMessage,
					setDialogTitle:props.setDialogTitle,
					setDialog:setDialog
				}	
				saveCard(cardObj,propsParam);		
						
		}   
	});	

	
};	

const handleKeypress = e => {
	
	 //it triggers by pressing the enter key  
	if (e.key === "Enter") {
	  saveCardAction(props);
	}

};


	return (
	<div>
			<Box style={{...screenStyleDialog}} m={0}>
			
				{loadingCards && 
					<Box style={{...screenStyleLoading}}>
							<Box mt={0} mx={0} style={{textAlign:"center"}}>								
								<CircularProgress /> 
								<div> Carregando cartões...Aguarde!</div>								
							</Box>	
					</Box>
				}
			
				{!loadingCards &&
				<>		
				<Box mt={0} ml={0} mr={0}>

							{/* Show existing address for selection */}	
							{ (creditCards.length > 0 && creditCardCatalog) &&					
							<>
							{/*<Box p={2} style={{display:"flex",flexDirection:"row",alignItems:"stretch",justifyContent:"center"}}>
										
									<Button size="small" onClick={()=>setCreditCardCatalog(false)}>										
										<AddCircleIcon style={{fontSize:"1.5rem",color: '#BBB',marginRight:"10px"}} />
										<div>Adicionar novo cartão de crédito </div>										
									</Button>	
							
							</Box>*/}
							
							<Box p={0}>
										
									<div style={{marginBottom:"10px",textAlign:"left",color:"#636466",fontFamily:"Roboto",fontWeight:"bold",fontSize:"12px"}}>  Selecione um cartão de crédito cadastrado </div>
							
									{creditCards.map((item, index) => { 
									
											if (item.cardFlag == "visa") {
												var flag = visa;
											} // end if
											else if  (item.cardFlag == "mastercard") {
												var flag = mastercard;
											} // end else if
											else if  (item.cardFlag == "amex") {
												var flag = amex;
											} // end else if	
											else if  (item.cardFlag == "diners") {
												var flag = diners;
											} // end else if	
											else if  (item.cardFlag == "elo") {
												var flag = elo;
											} // end else if	
											else if  (item.cardFlag == "generic") {
												var flag = genericFlag;
											} // end else if										
									
										return (
											<Card key={index} style={{minWidth: "90%",marginBottom:"20px",display:"flex",flexDirection:"column",width:"100%",marginBottom:"20px",backgroundColor:"#ffffff",boxShadow: "0px 10px 20px 0px #0000002A",borderRadius:"10px"}}>
									
											  <CardContent style={{padding:"5px"}}>
												
												<Box style={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"start"}}>
													
													<Box style={{textAlign:"right",width:"100%",paddingTop:"12px",paddingBottom:"12px"}} pl={2} pr={2}>										
														<img src={flag} alt="{item.cardFlag}" style={{width:"auto",height:"35px"}} />
													</Box>
													
													<Box m={0} pl={2} pr={2} style={{width:"100%"}}>										
														
															<div style={{textAlign:"left",color:"#636466"}}>
																													
																<div style={{fontFamily:"Roboto",fontWeight:"bold",fontSize:"16px",letterSpacing:"5px",marginBottom:"5px"}}>{item.referenceNumber}</div>
																
																<div style={{fontFamily:"Roboto",fontWeight:"bold",fontSize:"12px",textTransform: "uppercase"}}>{item.holderName}</div>
																
																<div style={{fontFamily:"Roboto",fontWeight:"normal",fontSize:"12px"}}>{item.expirationDate}</div>
															
															</div>											
														
													</Box>	
												
												</Box>
												
											  </CardContent>											
											  
												<CardActions style={{display:"flex",flexDirection:"row"}}>
													<div style={{flex:1,textAlign:"right"}}>
									
														<Button color="secondary" variant="contained" size="small" onClick={()=> selectCreditCard(item.id)}>
															<div>Selecionar</div>										
														</Button>												
													
													</div>
												</CardActions>

											</Card>								
																			
										)}
									
									)}					
							
							</Box>
							</>
							}
							
							
					{ (!creditCardCatalog) &&
						<Box ml={2} mr={2} mb={5} mt={2}>
							
							<div style={{marginBottom:"10px",textAlign:"left",color:"#636466",fontFamily:"Roboto",fontWeight:"bold",fontSize:"14px"}}>  Dados do seu cartão de crédito </div>
								
							<form id="formPayment"  noValidate autoComplete="off">
								
							<TextField
							  label="Nome do titular do cartão *"
							  placeholder="Digite o nome como no cartão"
							  style={{ margin: 10,marginLeft:0,marginRight:0,backgroundColor:"#fff",width:"100%",color:"#003CFA"}}
							  variant="outlined"
							  type="text"
							  autoComplete='off'									 
							  onKeyPress={handleKeypress}
							  value={creditcardHolder}
							  onChange={(event) => {
								  setCreditcardHolder(event.target.value)
							  }}							  
							  /*InputProps={{
								startAdornment: <InputAdornment position="start"><PersonIcon style={{fontSize:"1.2rem",flex:1,color:"#DDD"}} /></InputAdornment>,
							  }}*/							  
							/>

						 <Number
							masked
							 onChange={({value}) => setCreditcardNumber(value) }
							cardTypes={restrictAmex ? ['americanExpress'] : []}
						  onKeyPress={handleKeypress}
							render={({ value, valid, type, getInputProps }) => {
							
								if (!type) { var typeCard = ""; }
								if (type) { var typeCard = genericFlag; }
								if (type == "Visa") { var typeCard = visa; setCreditcardFlag("visa"); }
								if (type == "Mastercard") { var typeCard = mastercard; setCreditcardFlag("mastercard"); }
								if (type == "American Express") { var typeCard = amex; setCreditcardFlag("amex");  }
								if (type == "Diners Club") { var typeCard = diners; setCreditcardFlag("diners");  }
								if (type == "Elo") { var typeCard = elo; setCreditcardFlag("elo");  }
															
								return (
								  <div>
									<TextField
									  {...getInputProps() }
									  label="Número do cartão *"
									  placeholder="Digite o número do cartão"
									  style={{ margin: 10,marginLeft:0,marginRight:0,backgroundColor:"#fff",width:"100%"}}
									  autoComplete='off'									 
									  variant="outlined"
									  type="text"
									  InputProps={{
										/*startAdornment: <InputAdornment position="start"><CreditCardIcon style={{fontSize:"1.2rem",flex:1,color:"#DDD"}} /></InputAdornment>,*/
										endAdornment: <InputAdornment position="end"><img src={typeCard} style={{height:"30px",width:"auto"}} /></InputAdornment>										
									  }}										  
									/>
								  </div>
								)
							}
							
							} />							
									
							<div style={{display:"flex",flexDirection:"row",alignItems:"stretch",justifyContent:"center"}} >
							
							 <Expiration
							  onChange={({value, valid, error, month, year}) => setCreditcardDate(month+"/"+year)}
							  render={({ getInputProps, value, valid, error, month, year }) => (
								<TextField
								  {...getInputProps() } 
								  label="Vencimento *"
								  placeholder="MM/AA"
								  style={{flex:1, margin: 10,marginLeft:0,marginRight:10,backgroundColor:"#fff",width:"100%"}}
								  variant="outlined"
								  type="text"
								  /*InputProps={{
									startAdornment: <InputAdornment position="start"><DateRangeOutlinedIcon style={{fontSize:"1.2rem",flex:1,color:"#DDD"}} /></InputAdornment>,
								  }}*/									  
								/>
								)} />								
								
								<Cvc
								onChange={({value}) => setCreditcardCvv(value) }
								cardType={restrictAmex ? 'americanExpress' : undefined}
								render={({ getInputProps, value, valid }) => (								
								<TextField
								  {...getInputProps() }
								  label="Cód Segurança *"
								  placeholder="3 ou 4 dígitos"
								  style={{flex:1, margin: 10,marginLeft:0,marginRight:0,backgroundColor:"#fff",width:"100%"}}
								  variant="outlined"
								  type="text"
								  inputProps={{
									maxLength:4  
								  }}
								  /*InputProps={{
									startAdornment: <InputAdornment position="start"><LockIcon style={{fontSize:"1.2rem",flex:1,color:"#DDD"}} /></InputAdornment>,
								  }}*/								  
								/>
								)} />
								
								
							</div>
							
							</form>
									  
							<div style={{marginTop:"10px",marginBottom:"10px"}}>
							
								  <Checkbox												
									checked={selectedPayment}
									onChange={handleAcceptTerms}
									inputProps={{ 'aria-label': 'primary checkbox' }}
								  />
								  
								  <span>
									Armazenar cartão para usar novamente
								  </span>										
							
							</div>

							<div style={{flex:1,alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>    
									<div> 
										<HttpsIcon style={{fontSize:"2.5rem",color:"blue"}} />
									</div>
									
									<div> 
										<div style={{textAlign:"left",fontSize:"0.8rem",color:"#555555",marginLeft:"10px"}}>Levamos segurança muito a sério. Todos os dados de cartão de crédito são enviados de forma segura pra a IUGU (Gateway de pagamento), que gera uma chave única para essa transação ou armazena para uso futuro.</div>
									</div>
							</div>							
						
						</Box>
					}								
							
							


					</Box>
					</>
				   }					
		
			</Box>
			
			<Fade in={currentStep == 3} timeout={{ enter: 3000, exit: 0 }} mountOnEnter unmountOnExit>	
			<Box style={{backgroundColor:"transparent",display:"flex",width:"100%",alignItems:"center",position:"fixed",bottom:0,height:"100px",backgroundColor:"#ffffff",boxShadow: "0px -10px 15px 0px #0000001A",marginLeft:"-30px",zIndex:"9"}}>		

					<Box style={{width:"100%"}}>
							
						<Box style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>	

							{ (!complementNewCard && creditCardCatalog) &&
							<Button color="secondary" variant="contained" style={{width:"76%",height:"53px",fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px",color:"#FFF",borderRadius:"10px",paddingLeft:"30px",paddingRight:"30px"}} onClick={()=>setCreditCardCatalog(false)}>
							 Novo cartão de crédito
							</Button>
							}	

							{ (!creditCardCatalog && creditCards.length > 0) &&
							<Button color="secondary" disabled={savingCard} variant="outlined" style={{width:"26%",height:"53px",fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px",color:"#003CFA",borderRadius:"10px",marginRight:"10px"}} onClick={()=>{ setComplementNewCard(false);setCreditCardCatalog(true) } }>
							 Voltar
							</Button>
							}
							
							{/*{ (creditCards.length == 0 && !creditCardCatalog) &&
							<Button disabled={savingCard} color="primary" variant="contained" style={{width:"64%",height:"53px",fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px",color:"#FFF",borderRadius:"10px"}}  onClick={()=>saveCardAction(props)}>
								{ !savingCard &&
								   <div>Confirmar</div>
								}
								
								{ savingCard &&
								<>
								   <CircularProgress size={30} color="inherit" />
								   <span style={{marginLeft:"10px"}}>Processando...</span>
								</>
								}	
							</Button>
							}*/}
							
							{ (!creditCardCatalog) &&
							<Button disabled={savingCard} color="primary" variant="contained" style={{width:"64%",height:"53px",fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px",color:"#FFF",borderRadius:"10px"}} onClick={()=>saveCardAction(props)}>
								{ !savingCard &&
								   <div>Confirmar</div>
								}
								
								{ savingCard &&
								<>
								   <CircularProgress size={30} color="inherit" />
								   <span style={{marginLeft:"10px"}}>Processando...</span>
								</>
								}	
							</Button>
							}	
							

						</Box>	
 
					</Box>								
						
			</Box>
			</Fade>
			
		</div>
	);
	
});
  
/* Invoice Dialog Component START */
export const InvoiceDialog = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {


const screenStyleDialog = {
  alignItems: "stretch",
  justifyContent: "top",
  width:"100%",
  minHeight:"50vh",
  display:"flex",
  flexDirection:"column",
  backgroundColor:"transparent"
}; 
	
const screenStyleLoading = {
  alignItems: "middle",
  justifyContent: "center",
  width:"100%",
  display: "flex",
  flexDirection: "column"
};	
	
// set classes var
// const classes = useStyles()();	

const { 
	innerLoading,
	setInnerLoading,
	setDialog,
	dialog,
	setInvoice,
	invoiceItens,
	saveInvoice,
	retrieveInvoices,
	searchAddressByCep,
	hireServiceAddress,
	hireServiceInvoice,
	currentStep
} = props;	

const [value, setValue] = useState(0); // state for tab

// Billing document and type
const [invoiceId, setInvoiceId] = useState("");
const [invoiceTypeLabel, setInvoiceTypeLabel] = useState("Pessoa Física");
const [invoiceType, setInvoiceType] = useState("PF");
const [billingName, setBillingName] = useState("");
const [billingDocument, setBillingDocument] = useState("");

// Billing Address
const [addressId, setAddressId] = useState("");
const [streetType, setStreetType] = useState("");
const [street, setStreet] = useState("");
const [streetNumber, setStreetNumber] = useState("");
const [streetComplement, setStreetComplement] = useState("");
const [suburb, setSuburb] = useState("");
const [postalCode, setPostalCode] = useState("");
const [city, setCity] = useState("");
const [state, setState] = useState("");
const [country, setCountry] = useState("");

const [selectedInvoice, setSelectedInvoice] = useState(true);

const [savingInvoice, setSavingInvoice] = useState(false);
const [loadingInvoices, setLoadingInvoices] = useState(true);
const [loadingAddress, setLoadingAddress] = useState(false);

const [invoices,setInvoices] = useState(invoiceItens ? invoiceItens:[]);
const [invoiceCatalog,setInvoiceCatalog] = useState(false);
const [complementNewInvoice, setComplementNewInvoice] = useState(false);

const selectInvoice = (invoiceId) => {
	
	var array = [...invoices]; // clone
	var index = array.filter(item => item.id == invoiceId);
	var filteredCard = index[0];

	// Set that address was selected
	setSelectedInvoice(true);	
	
	// Set Redux Address
	setInvoice({...filteredCard});		

	// Close Dialog
	setDialog('invoice',false);
	props.changeStep('next',4);

	/*setTimeout(function() {		
		props.setStepAll(true);
	},1000);*/ 
	
} // end const


// useEffect only for dialgo changes
useEffect(() => {
				
		// Retrieve Addresses from API
		var propsParam = {
			setInvoiceCatalog:setInvoiceCatalog,
			setInvoices:setInvoices,
			invoices:invoices,
			setSavingInvoice:setSavingInvoice,
			setLoadingInvoices:setLoadingInvoices,
			setComplementNewInvoice:setComplementNewInvoice,
			setAlert:props.setAlert,
			setDialogMessage:props.setDialogMessage,
			setDialogTitle:props.setDialogTitle,
			setDialog:setDialog
		}		
		retrieveInvoices(propsParam);			
		
		// Set Address Catalog ON if there is any address
		if (invoices.length > 0) {
			setInvoiceCatalog(true);
		} // end if	

}, [dialog]); // end useEffect only for dialgo changes

const saveInvoiceAction = (props) => {
	
	// Check if required fields are OK
	if (!billingName || !billingDocument || ( (!street || !streetNumber || !suburb || !city || !state || !postalCode) && !selectedInvoice ) ) {		
		props.setDialogMessage("Preencha todos os campos marcados com * para prosseguir.");
		props.setAlert(true);	
		return false;		
	} // end if	
		
	// Check if CPF is valid
	var cpfCheck = billingDocument.replace(/\D/g,"");
	if ( !validaCPF(cpfCheck) && invoiceType == "PF") {
		props.setDialogMessage("Informe um CPF válido para prosseguir.");
		props.setAlert(true);	
		return;
	} // end if
	
	// Check if CNPJ is valid
	var cnpjCheck = billingDocument.replace(/\D/g,"");
	if ( !validaCNPJ(cnpjCheck) && invoiceType == "PJ" ) {
		props.setDialogMessage("Informe um CNPJ válido para prosseguir..");
		props.setAlert(true);	
		return;
	} // end if	
		
	// Set loading on...
	setSavingInvoice(true);

	// Set Invoice Obj
	var invoiceObj = {
		invoiceId:invoiceId,
		invoiceTypeLabel:invoiceTypeLabel,
		invoiceType:invoiceType,
		billingName:billingName,
		billingDocument:billingDocument,
		selectedInvoice:selectedInvoice,
		addressId:addressId,
		streetType:streetType,
		postalCode:postalCode,
		street:street,
		streetNumber:streetNumber,
		streetComplement:streetComplement,
		suburb:suburb,
		city:city,
		state:state,
		country:country,
		hireServiceAddress:hireServiceAddress
	}	
	var propsParam = {
		setInvoiceCatalog:setInvoiceCatalog,
		setInvoices:setInvoices,
		invoices:invoices,
		setSavingInvoice:setSavingInvoice,
		setComplementNewInvoice:setComplementNewInvoice,
		setAlert:props.setAlert,
		setDialogMessage:props.setDialogMessage,
		setDialogTitle:props.setDialogTitle,
		setAlert:props.setAlert,
		setDialog:props.setDialog
	}	
	saveInvoice(invoiceObj,propsParam);		
	
};	


const handleAcceptTerms = (event) => {

const newValue = event.target.checked
setSelectedInvoice(newValue);

}; 

const handleKeypress = e => {
	
	 //it triggers by pressing the enter key  
	if (e.key === "Enter") {
	  saveInvoiceAction(props);
	}

};

// Set functions
const handleChange = (event, newValue) => {

	setValue(newValue);

	setBillingName("");
	setBillingDocument("");

	if (newValue == 0) {
		setInvoiceTypeLabel("Pessoa Física");
		setInvoiceType("PF");
	} // end if
	else {
		setInvoiceTypeLabel("Pessoa Jurídica");
		setInvoiceType("PJ");	
	} // end else

};

const searchAddressByCepAction = (props) => {
	
	// Check is postal code is OK
	var postalCodeCheck = postalCode.replace(/\D/g,"");
	if (postalCodeCheck.length < 8) {		
		props.setDialogMessage("Preencha o CEP corretamente.");
		props.setAlert(true);	
		return false;		
	} // end if	
	
	// loading gauge
	setLoadingAddress(true);
	
	// Set Card Obj
	var addressObj = {
		postalCode:postalCode
	}	
	var propsParam = {
		setStreet:setStreet,
		setSuburb:setSuburb,
		setCity:setCity,
		setState:setState,
		setLoadingAddress:setLoadingAddress,
		setAlert:props.setAlert,
		setDialogMessage:props.setDialogMessage,
		setDialogTitle:props.setDialogTitle,
		setDialog:props.setDialog
	}	
	searchAddressByCep(addressObj,propsParam);		
	
} 
	
/*
* @description Validation of CPF
* method: _validaCPF
*/	
const validaCPF = (value) => {

	var soma = 0;
	var resto;
	var inputCPF = value;

	if(inputCPF == '00000000000' || inputCPF == '11111111111' || inputCPF == '22222222222' || inputCPF == '33333333333' || inputCPF == '44444444444' || inputCPF == '55555555555' || inputCPF == '66666666666' || inputCPF == '77777777777' || inputCPF == '88888888888' || inputCPF == '99999999999') return false;
	for(var i=1; i<=9; i++) soma = soma + parseInt(inputCPF.substring(i-1, i)) * (11 - i);
	resto = (soma * 10) % 11;

	if((resto == 10) || (resto == 11)) resto = 0;
	if(resto != parseInt(inputCPF.substring(9, 10))) return false;

	soma = 0;
	for(var i = 1; i <= 10; i++) soma = soma + parseInt(inputCPF.substring(i-1, i))*(12-i);
	resto = (soma * 10) % 11;

	if((resto == 10) || (resto == 11)) resto = 0;
	if(resto != parseInt(inputCPF.substring(10, 11))) return false;
	return true;
			
} // end const
	
/*
* @description Validaton of CNPJ
* method: _validaCNPJ
* 
*/	
const validaCNPJ = (value) => {

	var cnpjTemp = value.replace(/[^\d]+/g,'');
 
	if(cnpjTemp == '') return false;
	 
	if (cnpjTemp.length != 14)
		return false;
 
	// Elimina CNPJs invalidos conhecidos
	if (cnpjTemp == "00000000000000" || 
		cnpjTemp == "11111111111111" || 
		cnpjTemp == "22222222222222" || 
		cnpjTemp == "33333333333333" || 
		cnpjTemp == "44444444444444" || 
		cnpjTemp == "55555555555555" || 
		cnpjTemp == "66666666666666" || 
		cnpjTemp == "77777777777777" || 
		cnpjTemp == "88888888888888" || 
		cnpjTemp == "99999999999999")
		return false;
		 
	// Valida DVs
	var tamanho = cnpjTemp.length - 2
	var numeros = cnpjTemp.substring(0,tamanho);
	var digitos = cnpjTemp.substring(tamanho);
	var soma = 0;
	var pos = tamanho - 7;
	for (var i = tamanho; i >= 1; i--) {
	  soma += numeros.charAt(tamanho - i) * pos--;
	  if (pos < 2)
			pos = 9;
	}
	var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
	if (resultado != digitos.charAt(0))
		return false;
		 
	tamanho = tamanho + 1;
	numeros = cnpjTemp.substring(0,tamanho);
	soma = 0;
	pos = tamanho - 7;
	for (var i = tamanho; i >= 1; i--) {
	  soma += numeros.charAt(tamanho - i) * pos--;
	  if (pos < 2)
			pos = 9;
	}
	resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
	if (resultado != digitos.charAt(1))
		  return false;
		   
	return true;

} // end const

	return (
	<div>			
			<Box style={{...screenStyleDialog}} m={0}>
			
				{loadingInvoices && 
					<Box style={{...screenStyleLoading}}>
							<Box mt={0} mx={0} style={{textAlign:"center"}}>								
								<CircularProgress /> 
								<div> Carregando dados...Aguarde!</div>								
							</Box>	
					</Box>
				}
			
				{!loadingInvoices &&
				<>		
				<Box mt={0} ml={0} mr={0}>
							
							{/* Show existing address for selection */}	
							{ (invoices.length > 0 && invoiceCatalog) &&					
							<>
							{/*<Box p={2} style={{display:"flex",flexDirection:"row",alignItems:"stretch",justifyContent:"center"}}>
										
									<Button size="small" onClick={()=>setInvoiceCatalog(false)}>										
										<AddCircleIcon style={{fontSize:"1.5rem",color: '#BBB',marginRight:"10px"}} />
										<div>Adicionar novos dados de faturamento</div>									
									</Button>	
							
							</Box>*/}
							
							<Box p={0}>
							
									<div style={{marginBottom:"10px",textAlign:"left",color:"#636466",fontFamily:"Roboto",fontWeight:"bold",fontSize:"12px"}}>  Escolha em nome de quem a fatura será emitida </div>
								
									{invoices.map((item, index) => (
										
										<Card key={item.id} style={{minWidth: "90%",marginBottom:"20px",display:"flex",flexDirection:"row",width:"100%",marginBottom:"20px",backgroundColor:"#ffffff",boxShadow: "0px 10px 20px 0px #0000002A",borderRadius:"10px"}}>
										
										  <CardContent style={{padding:"0px",flex:4}}>
											
											<Box style={{display:"flex",flexdirection:"row",alignItems:"center",justifyContent:"start"}}>
											
													<Box m={1} style={{flex:5}}>													
															<h4 style={{textAlign:"left",marginTop:"0px",marginBottom:"0px"}}>{item.type == "PF" ? "Pessoa Física":"Pessoa Jud?rica"}</h4>
															<div style={{textAlign:"left",fontSize:"0.8rem"}}>
																{item.billingName}<br />
															Documento: {item.billingDocument}
															</div>	
													</Box>
												
													<CardActions style={{display:"flex",flexDirection:"row"}}>
														<div style={{flex:1,textAlign:"right"}}>

																<Button color="secondary" variant="contained" size="small" onClick={()=> selectInvoice(item.id)}>
																	<div>Selecionar</div>										
																</Button>											
																		
														</div>
													</CardActions>													

												</Box>
											
											<Box style={{display:"flex",flexdirection:"row",alignItems:"center",justifyContent:"start"}}>
												<Box m={0} style={{padding:"10px"}}>													
														<div style={{textAlign:"left",fontSize:"0.7rem",lineHeight:"25px",backgroundColor:"whitesmoke",borderRadius:"5px"}}>
															{item.CustomerAddress.fullAddress}
														</div>	
												</Box>
										    </Box>											
											
										  </CardContent>
										  

										</Card>								
																		
									))}						
							
							</Box>
							</>
							}
							
							
					{ (!invoiceCatalog) &&
						<Box ml={0} mr={0} mb={5} mt={2}>
												
							<div style={{marginBottom:"10px",textAlign:"left",color:"#636466",fontFamily:"Roboto",fontWeight:"bold",fontSize:"12px"}}>  Escolha em nome de quem a fatura será emitida </div>
												
							  <Tabs
								style={{marginBottom:"10px",backgroundColor:"#FFFFFF",boxShadow: "0px 10px 20px 0px #D6D6D6",borderRadius:"10px",height:"36px"}} 
								value={value}
								indicatorColor="transparent"
								textColor="primary"
								onChange={handleChange}
								aria-label="Seleção de tipo de faturamento"
							  >
								<Tab label="Pessoa Física" style={{height:"36px",borderRadius:"10px",textTransform:"uppercase",flexBasis:"50%",fontFamily:"Roboto",fontWeight:"normal",fontSize:"12px"}} />
								<Tab label="Pessoa Jurídica" style={{height:"36px",borderRadius:"10px",textTransform:"uppercase",flexBasis:"50%",fontFamily:"Roboto",fontWeight:"normal",fontSize:"12px"}} />
							  </Tabs>
							  
							  {value == 0 &&
							  <Box ml={0} mr={0} mb={2} mt={2}>	
													
									<TextField
									  label="Nome completo *"
									  placeholder="Digite o nome para faturamento"
									  style={{ margin: 10,marginLeft:0,marginRight:0,backgroundColor:"#fff",width:"100%"}}
									  variant="outlined"
									  type="text"							 
									  onKeyPress={handleKeypress}
									  value={billingName}
									  onChange={(event) => {
										  setBillingName(event.target.value)
									  }}						  					  
									/>
									
									<InputMask
										mask="999.999.999-99"
										  onKeyPress={handleKeypress}
										  value={billingDocument}
										  onChange={(event) => {
											  setBillingDocument(event.target.value)
										  }}	
									  >									
										<TextField
										  label="CPF *"
										  placeholder="Digite o CPF para faturamento"
										  style={{ margin: 10,marginLeft:0,marginRight:0,backgroundColor:"#fff",width:"100%"}}
										  variant="outlined"
										  type="text"							 						  					  
										/>	
									</InputMask>
									
								</Box>
							  }
								
							  {value == 1 &&
							  <Box ml={0} mr={0} mb={2} mt={2}>	
							  			
									<TextField
									  label="Razão Social *"
									  placeholder="Digite a razão social da empresa"
									  style={{ margin: 10,marginLeft:0,marginRight:0,backgroundColor:"#fff",width:"100%"}}
									  variant="outlined"
									  type="text"							 
									  onKeyPress={handleKeypress}
									  value={billingName}
									  onChange={(event) => {
										  setBillingName(event.target.value)
									  }}						  						  
									/>
									
									<InputMask
										mask="99.999.999/9999-99"
										  onKeyPress={handleKeypress}
										  value={billingDocument}
										  onChange={(event) => {
											  setBillingDocument(event.target.value)
										  }}
									  >										
										<TextField
										  label="CNPJ *"
										  placeholder="Digite o CNPJ para faturamento"
										  style={{ margin: 10,marginLeft:0,marginRight:0,backgroundColor:"#fff",width:"100%"}}
										  variant="outlined"
										  type="text"							 							  						  
										/>
									</InputMask>										
								</Box>
							  }											
									 
							<div style={{marginTop:"10px",marginBottom:"10px"}}>
							
								  <Checkbox												
									checked={selectedInvoice}
									onChange={handleAcceptTerms}
									inputProps={{ 'aria-label': 'primary checkbox' }}
								  />
								  
								  <span>
									Utilizar endereço do local do serviço
								  </span>										
							
							</div>
							
							{!selectedInvoice &&
							<div style={{marginTop:"10px",marginBottom:"10px"}}>
							
								<h3 style={{textAlign:"center",marginBottom:"5px"}}>Preencha o endereço para faturamento</h3>
								<Divider variant="middle" style={{marginBottom:"10px"}} />						

										<div style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center"}} >
																		
											<InputMask
												mask="99999-999"
												onKeyPress={handleKeypress}
												value={postalCode}
												  onChange={(event) => {
													  setPostalCode(event.target.value)
												  }}
											  >
												<TextField
												  label="Digite o CEP *"
												  style={{flex:2, margin: 10,marginLeft:0,marginRight:10,backgroundColor:"#fff",width:"100%"}}
												  variant="outlined"
												 />
											</InputMask>		

																				
											<Button color="secondary" disabled={loadingAddress} variant="contained" style={{flex:2,height:"40px",color:"#FFF"}}  onClick={()=>searchAddressByCepAction(props)}>
													{ !loadingAddress &&
													   <div>Buscar endereço</div>
													}
													
													{ loadingAddress &&
													<>
													   <CircularProgress size={30} color="inherit" />
													   <span style={{marginLeft:"10px"}}>Buscando...</span>
													</>
													}
											</Button>
										
										
										</div>

										<TextField
										  label="Rua / Logradouro *"
										  placeholder="Exemplo: Av. das américas"
										  style={{ margin: 10,marginLeft:0,marginRight:0,backgroundColor:"#fff",width:"100%"}}
										  variant="outlined"
										  type="text"
										  onKeyPress={handleKeypress}
										  value={street}
										  onChange={(event) => {
											  setStreet(event.target.value)
										  }}							  
										/>	
												
										<div style={{display:"flex",flexDirection:"row",alignItems:"stretch",justifyContent:"center"}} >
										
											<TextField
											  label="Número *"
											  style={{flex:1, margin: 10,marginLeft:0,marginRight:10,backgroundColor:"#fff",width:"100%"}}
											  variant="outlined"
											  type="number"
											  onKeyPress={handleKeypress}
											  value={streetNumber}
											  onChange={(event) => {
												  setStreetNumber(event.target.value)
											  }}									  
											/>
											
											<TextField
											  label="Complemento"
											  style={{flex:2, margin: 10,marginLeft:0,marginRight:0,backgroundColor:"#fff",width:"100%"}}
											  variant="outlined"
											  type="text"
											  onKeyPress={handleKeypress}
											  value={streetComplement}
											  onChange={(event) => {
												  setStreetComplement(event.target.value)
											  }}									  
											/>
										
										</div>
										
										<div style={{display:"flex",flexDirection:"row",alignItems:"stretch",justifyContent:"center"}} >
											
											<TextField
											  label="Bairro *"
											  style={{flex:2, margin: 10,marginLeft:0,marginRight:0,backgroundColor:"#fff",width:"100%"}}
											  variant="outlined"
											  type="text"
											  onKeyPress={handleKeypress}
											  value={suburb}
											  onChange={(event) => {
												  setCity(event.target.value)
											  }}									  
											/>	
										
										</div>										
										
										<div style={{display:"flex",flexDirection:"row",alignItems:"stretch",justifyContent:"center"}} >
											
											<TextField
											  label="Cidade *"
											  style={{flex:2, margin: 10,marginLeft:0,marginRight:0,backgroundColor:"#fff",width:"100%"}}
											  variant="outlined"
											  type="text"
											  onKeyPress={handleKeypress}
											  value={city}
											  onChange={(event) => {
												  setCity(event.target.value)
											  }}									  
											/>											
											
											<Select
											  label="UF"
											  style={{ flex:1.5, paddingTop:0,paddingBottom:0,margin: 10,marginLeft:10,marginRight:0,backgroundColor:"#fff",width:"100%"}}
											  variant="outlined"
											  value={state}
											  onChange={(event) => {
												  setState(event.target.value)
											  }}	
											 >
												<MenuItem value="">- Selecione -</MenuItem>
												<MenuItem value="AC">Acre</MenuItem>
												<MenuItem value="AL">Alagoas</MenuItem>
												<MenuItem value="AP">Amapá</MenuItem>
												<MenuItem value="AM">Amazonas</MenuItem>
												<MenuItem value="BA">Bahia</MenuItem>
												<MenuItem value="CE">Ceará</MenuItem>
												<MenuItem value="DF">Distrito Federal</MenuItem>
												<MenuItem value="ES">Espírito Santo</MenuItem>
												<MenuItem value="GO">Goiás</MenuItem>
												<MenuItem value="MA">Maranhão</MenuItem>
												<MenuItem value="MT">Mato Grosso</MenuItem>
												<MenuItem value="MS">Mato Grosso do Sul</MenuItem>
												<MenuItem value="MG">Minas Gerais</MenuItem>
												<MenuItem value="PA">Pará</MenuItem>
												<MenuItem value="PB">Paraíba</MenuItem>
												<MenuItem value="PR">Paraná</MenuItem>
												<MenuItem value="PE">Pernambuco</MenuItem>
												<MenuItem value="PI">Piauí</MenuItem>
												<MenuItem value="RJ">Rio de Janeiro</MenuItem>
												<MenuItem value="RN">Rio Grande do Norte</MenuItem>
												<MenuItem value="RS">Rio Grande do Sul</MenuItem>
												<MenuItem value="RO">Rondônia</MenuItem>
												<MenuItem value="RR">Roraima</MenuItem>
												<MenuItem value="SC">Santa Catarina</MenuItem>
												<MenuItem value="SP">São Paulo</MenuItem>
												<MenuItem value="SE">Sergipe</MenuItem>
												<MenuItem value="TO">Tocantins</MenuItem>
											</Select>
													
										</div>
										
							
							</div>
							}							
						
						</Box>
					}								
							

					</Box>
					</>
				   }					
		
			</Box>		

			<Fade in={currentStep == 4} timeout={{ enter: 3000, exit: 0 }} mountOnEnter unmountOnExit>	
			<Box style={{backgroundColor:"transparent",display:"flex",width:"100%",alignItems:"center",position:"fixed",bottom:0,height:"100px",backgroundColor:"#ffffff",boxShadow: "0px -10px 15px 0px #0000001A",marginLeft:"-30px",zIndex:"9"}}>		

					<Box style={{width:"100%"}}>
							
						<Box style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>	
	
							{ (!complementNewInvoice && invoiceCatalog) &&
							<Button color="secondary" variant="contained" style={{width:"76%",height:"53px",fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px",color:"#FFF",borderRadius:"10px",paddingLeft:"30px",paddingRight:"30px"}} onClick={()=>setInvoiceCatalog(false)}>
							 Inserir novos dados
							</Button>
							}	

							{ (!invoiceCatalog && invoices.length > 0) &&
							<Button color="secondary" disabled={savingInvoice} variant="outlined" style={{width:"26%",height:"53px",fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px",color:"#003CFA",borderRadius:"10px",marginRight:"10px"}} onClick={()=>{ setComplementNewInvoice(false);setInvoiceCatalog(true) } }>
							 Voltar
							</Button>
							}
							
							{ (!invoiceCatalog) &&
							<Button disabled={savingInvoice} color="primary" variant="contained" style={{width:(!invoiceCatalog && invoices.length > 0) ? "64%" : "76%",height:"53px",fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px",color:"#FFF",borderRadius:"10px"}} onClick={()=>saveInvoiceAction(props)}>
								{ !savingInvoice &&
								   <div>Confirmar</div>
								}
								
								{ savingInvoice &&
								<>
								   <CircularProgress size={30} color="inherit" />
								   <span style={{marginLeft:"10px"}}>Processando...</span>
								</>
								}	
							</Button>
							}								


						</Box>	

					</Box>								
						
			</Box>
			</Fade>
			
		</div>
		
	);
	
});
  
/* Extra  Dialog Component START */
export const ExtraDialog = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {
	

const screenStyleDialog = {
  alignItems: "stretch",
  justifyContent: "top",
  width:"100%",
  minHeight:"50vh",
  display:"flex",
  flexDirection:"column",
  backgroundColor:"transparent"
}; 
	
// set classes var
// const classes = useStyles()();	

const { 
	innerLoading,
	setInnerLoading,
	setDialog,
	dialog,
	setExtraInfo,
	hireServiceExtraInfo,
	currentStep,
	view,
	extraInfoDescription,
	cameraImages,
	setExtraInfoDescription,
	setCameraImages
} = props;	

const [selectedExtra, handleExtraChange] = useState("");
const [dataUri, setDataUri] = useState('');

const saveImage = (dataUri) => {
	
	// Check if terms was accepted
	if (cameraImages.length == 5) {		
		props.setDialogMessage("Você só pode adicionar até 5 imagens. Exclua uma das imagens para adicionar uma nova imagem.");
		props.setAlert(true);	
		return false;		
	} // end if			
	
	var index = cameraImages.length + 1;
	var obj = {
		src:dataUri,
		id:index
	}
	
	var array = [...cameraImages,obj];	
	setCameraImages(array);
	
}

const deleteImage = (id) => {
	
	var array = [...cameraImages]; // clone
	var index = array.filter(item => item.id != id);
	setCameraImages(index);
	
}

const confirmExtra = () => {
	
		// Check if required fields are OK
	if (!extraInfoDescription) {		
		props.setDialogMessage("Preencha todos os campos marcados com * para prosseguir.");
		props.setAlert(true);	
		return false;		
	} // end if	
	
	var descriptionObj = {
		templateId:"1",
		fieldId:"1",
		fieldType:"textarea",
		fieldLabel:"Descreva mais sobre sua solicitação",
		fieldValue:extraInfoDescription,
		fieldOrder:"0"		
	}
	
	if (cameraImages.length > 0) {
		var imagesObj = {
			templateId:"1",
			fieldId:"2",
			fieldType:"images",
			fieldLabel:"Adicione até 5 imagens",
			fieldValue:[...cameraImages],
			fieldOrder:"1"		
		}		
	}
	
	// if images was sent
	if (imagesObj) {
		setExtraInfo([descriptionObj,imagesObj]);
	} // end if
	else {
		setExtraInfo([descriptionObj]);
	} // end else
	 
	// Close Dialog
	setDialog('extra',false);
	
	// Go to next step
	props.changeStep('next',5);	
	
	// Set ALL SET to finish order
	/*setTimeout(function() {		
		props.setStepAll(true);
	},1000);	*/
	
};	

	return (
	<div>
			<Box style={{...screenStyleDialog}} m={0}>
			
					<Box mt={0} ml={0} mr={0}>
								
						<Box ml={0} mr={0} mb={5} mt={!view ? 2 : 0}>
						
								{!view &&
									<div style={{marginBottom:"10px",textAlign:"left",color:"#636466",fontFamily:"Roboto",fontWeight:"bold",fontSize:"12px"}}>  Descreva o serviço e se necessário, adicione até 5 fotos </div>
								}
								
								{!view &&
									<TextField
									  label="Mensagem *"
									  placeholder="Adicione mais detalhes sobre sua solicitação para ajudar o profissional."
									  style={{ margin: 10,marginLeft:0,marginRight:0,backgroundColor:"#fff",width:"100%"}}
									  size="small"
									  multiline
									  rows={5}
									  variant="outlined"
									  type="text"
									  readOnly={currentStep != 5}
									  value={extraInfoDescription}
									  onChange={(event) => {
											  setExtraInfoDescription(event.target.value)
									  }}	
									/>	
								}
								
								{view &&
									<div style={{marginBottom:"10px",textAlign:"left",color:"#636466",fontFamily:"Roboto",fontWeight:"regular",fontSize:"16px"}}> {extraInfoDescription} </div>
								}
										  
								<div style={{marginTop:"10px",marginBottom:"10px"}}>

								{!view &&
									<div size="large" style={{selfAlign:"center",backgroundColor:"#003CFA",borderRadius:"50px",width:"54px",height:"54px",marginRight:"auto",marginLeft:"auto"}} onClick={currentStep == 5 ? (target,status) => props.toggleDialog('camera',true) : console.log("nothing...")}>
										<IconButton>
										  <AddAPhotoIcon style={{color:"#FFF"}} />
										</IconButton>
									</div>
								}
											
								<Box style={{marginTop:"10px", display: 'flex',flexWrap: 'wrap', justifyContent: 'flex-start'}}>

									<ImageList style={{flex:1,display:"flex",padding:"0",flexWrap:"nowrap",listStyle:"none",overflowY:"auto"}} cols={2.5}>									
										{cameraImages.map((item) => (
										  <ImageListItem key={item.id} style={{padding:"15px"}}>
											<img src={item.src} />
											<ImageListItemBar
											  style={{marginLeft: "10px",flex: 1,background:"transparent",}}
											  actionIcon={
												<IconButton onClick={!view ? (target,status) => ()=> deleteImage(item.id) : console.log("nothing...")}>
												  <DeleteIcon style={{marginLeft: "10px",flex: 1,color:"#FFF"}} />
												</IconButton>
											  }
											/>
										  </ImageListItem>
										))}
										
									 </ImageList>

								</Box>
								
								</div>

						</Box>
										

					</Box>
			
			
			</Box>
			
			<Fade in={currentStep == 5} timeout={{ enter: 3000, exit: 0 }} mountOnEnter unmountOnExit>	
			<Box style={{backgroundColor:"transparent",display:"flex",width:"100%",alignItems:"center",position:"fixed",bottom:0,height:"100px",backgroundColor:"#ffffff",boxShadow: "0px -10px 15px 0px #0000001A",marginLeft:"-30px",zIndex:"9"}}>		

					<Box style={{width:"100%"}}>
							
						<Box style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>	
	
							<Button color="primary" variant="contained" style={{width:"76%",height:"53px",fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px",color:"#FFF",borderRadius:"10px"}} onClick={()=>confirmExtra()}>
								   <div>Confirmar</div>
							</Button>

						</Box>	

					</Box>								
						
			</Box>
			</Fade>			

		  <CameraDialog saveImage={saveImage} />
		  
		</div>
		
	);
	
});


/* Camera Dialog Component START */
export const CameraDialog = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {

const screenStyleDialog = {
  alignItems: "stretch",
  justifyContent: "top",
  width:"100vw",
  minHeight:"100vh",
  display:"flex",
  flexDirection:"column",
  backgroundColor:"#FFF"
}; 
	
// set classes var
// const classes = useStyles()();	

const { 
	innerLoading,
	setInnerLoading,
	setDialog,
	dialog,
	setExtraInfo
} = props;	

const [selectedExtra, handleExtraChange] = useState("");

 function handleTakePhoto (dataUri) {
	props.saveImage(dataUri);
	setDialog('camera',false);
	setDialog('extra',true);
  }
 
  function handleTakePhotoAnimationDone (dataUri) {

  }
 
  function handleCameraError (error) {
    
  }
 
  function handleCameraStart (stream) {
    
  }
 
  function handleCameraStop () {
    
  }

	return (
	<div>
		  <Dialog fullScreen open={dialog.target == 'camera' && dialog.status} onClose={()=>setDialog('camera',false)} TransitionComponent={TransitionSlideUp}>
			<AppBar style={{position: 'fixed'}}>
			  <Toolbar>
				<IconButton edge="start" color="inherit" onClick={() => { setDialog('camera',false); setDialog('extra',true) } } aria-label="close">
				  <CloseIcon />
				</IconButton>
				<Typography variant="h6" style={{marginLeft: "10px",flex: 1}}>
				  Tire uma foto
				</Typography>
				</Toolbar>
			</AppBar>

			<Box style={{...screenStyleDialog}} m={0}>
			
					<Box mt={7} ml={0} mr={0} style={{height:"77vh",overflow:"scroll",backgroundColor:"#000"}}>
								
						<Box ml={2} mr={2} mb={5} mt={2}>
						
						 <Camera
							onTakePhoto = { (dataUri) => { handleTakePhoto(dataUri); } }
							onTakePhotoAnimationDone = { (dataUri) => { handleTakePhotoAnimationDone(dataUri); } }
							onCameraError = { (error) => { handleCameraError(error); } }						 
							idealFacingMode = {FACING_MODES.ENVIRONMENT}
							imageType = {IMAGE_TYPES.JPG}
							isImageMirror = {false}	
							sizeFactor = {1}
						/>	

						</Box>
										

					</Box>
			
					<Divider style={{marginBottom:"5px"}} />
						
					<Box style={{display:"flex",flexDirection:"row",justifyContent:"center"}} ml={1} mr={1} mb={5} mt={2}>	

						<Button color="secondary" variant="outlined" style={{flex:1,height:"48px",marginRight:"10px"}} onClick={()=>{ setDialog('camera',false); setDialog('extra',true) } }>
						 Fechar
						</Button>		
							
					</Box>			
			
			</Box>

		  </Dialog>
		</div>
		
	);
	
});


/* Confirm Alert Dialog Component START */
export const ConfirmDialog = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {
	

const screenStyleDialog = {
  alignItems: "stretch",
  justifyContent: "top",
  width:"100vw",
  minHeight:"100vh",
  display:"flex",
  flexDirection:"column",
  backgroundColor:"#FFF"
}; 
	
// set classes var
// const classes = useStyles()();	

const { 
	innerLoading,
	setInnerLoading,
	setDialog,
	dialog,
	setConfirm
} = props;	


const [selectedExtra, handleExtraChange] = useState("");

const confirmService = (value) => {

	// Close Dialog
	setDialog('confirm',false);
	
	// execute service processing...
	props.processService();
	
};	

	return (
		<div>
		  <Dialog
			open={dialog.target == 'confirm' && dialog.status} onClose={()=>setDialog('confirm',false)}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		  >
			<DialogTitle id="alert-dialog-title">{"Confirmação de Solicitação"}</DialogTitle>
			<DialogContent>
			  <DialogContentText id="alert-dialog-description">
				Ao prosseguir, estaremos fazendo uma pré-autorização em seu cartão de crédito e a cobrança efetiva só será realizada quando o serviço for finalizado, no local. 
			  </DialogContentText>
			  
			  <DialogContentText id="alert-dialog-description">
				 Ao confirmar estaremos buscando em até 5 minutos ou antes um profissional para lhe atender. 
			  </DialogContentText>			  
			  
			</DialogContent>
			<DialogActions>
			  <Button onClick={()=>setDialog('confirm',false)} color="primary">
				Cancelar
			  </Button>
			  <Button color="primary" onClick={()=>confirmService()} autoFocus>
				Confirmar
			  </Button>
			</DialogActions>
		  </Dialog>
		</div>
	);
	
});

/* Confirm Alert Dialog Component START */
export const ConfirmServiceChangeDialog = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {
	

const screenStyleDialog = {
  alignItems: "stretch",
  justifyContent: "top",
  width:"100vw",
  minHeight:"100vh",
  display:"flex",
  flexDirection:"column",
  backgroundColor:"#FFF"
}; 
	
// set classes var
// const classes = useStyles()();	

const { 
	innerLoading,
	setInnerLoading,
	setDialog,
	dialog,
} = props;	


const [selectedExtra, handleExtraChange] = useState("");

const confirmService = (value) => {

	// Close Dialog
	setDialog('confirm',false);
	
};	

	return (
		<div>
		  <Dialog
			open={dialog.target == 'confirmServiceChange' && dialog.status} onClose={()=>setDialog('confirmServiceChange',false)}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		  >
			<DialogTitle id="alert-dialog-title">{"Quer mesmo alterar o serviço?"}</DialogTitle>
			<DialogContent>
			  <DialogContentText id="alert-dialog-description">
				Caso prossiga com a alteração você será redirecionado para a tela de seleção de serviço e deverá fazer o processo de seleção de serviço novamente. 
			  </DialogContentText>
			</DialogContent>
			<DialogActions>
			  <Button onClick={()=>setDialog('confirmServiceChange',false)} color="primary">
				Cancelar
			  </Button>
			  <Button onClick={()=>setDialog('confirmServiceChange',false)} component={Link} to="/searchService" color="primary" autoFocus>
				Alterar
			  </Button>
			</DialogActions>
		  </Dialog>
		</div>
	);
	
});

export const AlertDialog = (props) => {
	
	return (
		 <Dialog
			open={props.alert} 
			onClose={()=>props.setAlert(false)}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		  >
		  <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
			<DialogContent>
			  <DialogContentText id="alert-dialog-description">
			  {props.dialogMessage}
			  </DialogContentText>
			</DialogContent>
			<DialogActions>
			  <Button onClick={() => props.setAlert(false)} color="primary" autoFocus>
				OK
			  </Button>
			</DialogActions>			
		  </Dialog>
	  )
	
} // end AlertDialog

/* Wizard Dialog Component START */
export const WizardDialog = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {
	

const screenStyleDialog = {
  alignItems: "stretch",
  justifyContent: "top",
  width:"100vw",
  minHeight:"100vh",
  display:"flex",
  flexDirection:"column",
  backgroundImage: "url(" + backgroundHexagon + ")",
  backgroundSize:"cover",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"center"
}; 
	
// set classes var
// const classes = useStyles()();	

const { 
	innerLoading,
	setInnerLoading,
	setDialog,
	dialog,
} = props;	

let navigate = useNavigate();

const [selectedExtra, handleExtraChange] = useState("");

const confirmExtra = (value) => {

	// Close Dialog
	setDialog('wizard',false);
	
};	

	return (
	<div>
		  <Dialog fullScreen open={dialog.target == 'wizard' && dialog.status} onClose={()=>setDialog('wizard',false)} TransitionComponent={TransitionSlideUp}>
			<Box style={{...screenStyleDialog}} m={0}>
			
				<Box mt={3} ml={0} mr={0}>
					
					<Box p={0} pb={10}>

						<Box style={{alignItems:"center",display:"flex"}}>												
										
							<Box style={{flex:1,alignItems:"center",display:"flex",flexDirection:"column"}}>						
									<div style={{display:"flex",alignItems:"center"}}>
												<img src={Approval} alt="Solicitando um Profissional" style={{marginLeft:"auto",marginRight:"auto",width:"20vw"}} />			
									</div>
									<h2 style={{color:"#555",marginBottom:"10px",marginTop:"10px"}}>Solicitando um Profissional</h2>
							</Box>	
							
						</Box>

						<Box style={{alignItems:"stretch",justifyContent:"center",display:"flex",flexDirection:"column"}} mt={0} mb={3} ml={3} mr={3}>
							
							<h3 style={{color:"#A255A0",marginBottom:"10px",textAlign:"center"}}>Sua primeira vez aqui!</h3>
								
								<div style={{fontSize:"1rem",color:"#555555",textAlign:"center"}}>Na tela a seguir, você deverá definir cada item abaixo para finalizar a sua solicitação. </div>							
							
						</Box>
											
									
						<Box style={{flex:1,alignItems:"strecht",display:"flex",flexDirection:"column"}} mt={4}>						
						
							<div style={{flex:1}}>	
							
								<div style={{alignItems:"flex-start",justifyContent:"center",display:"flex",flexDirection:"column",marginLeft:"35px"}}>
								
										<div style={{flex:1,alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row",marginBottom:"30px",zIndex:"9999"}}>    
											<div style={{display:"flex",alignItems:"center"}}>
												<DateRangeOutlinedIcon style={{fontSize:"1.5rem",flex:1}} />
											</div>
											<div> 
												<span style={{fontSize:"1rem",marginLeft:"10px"}}>Data e Hora (período) do serviço</span>
											</div>
										</div>
										
										<div style={{flex:1,alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row",marginBottom:"30px",zIndex:"9999"}}>    
											<div style={{display:"flex",alignItems:"center"}}>
												<HomeOutlinedIcon style={{fontSize:"1.5rem",flex:1}} />
											</div>
											<div> 
												<span style={{fontSize:"1rem",marginLeft:"10px"}}>Endereço onde o serviço será prestado</span>
											</div>
										</div>										
										
										<div style={{flex:1,alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row",marginBottom:"30px",zIndex:"9999"}}>    
											<div style={{display:"flex",alignItems:"center"}}>
												<CreditCardOutlinedIcon style={{fontSize:"1.5rem",flex:1}} />
											</div>
											<div> 
												<span style={{fontSize:"1rem",marginLeft:"10px"}}>Forma de pagamento pelo serviço</span>
											</div>
										</div>	

										<div style={{flex:1,alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row",marginBottom:"30px",zIndex:"9999"}}>    
											<div style={{display:"flex",alignItems:"center"}}>
												<ReceiptOutlinedIcon style={{fontSize:"1.5rem",flex:1}} />
											</div>
											<div> 
												<span style={{fontSize:"1rem",marginLeft:"10px"}}>Dados de faturamento do serviço</span>
											</div>
										</div>	

										<div style={{flex:1,alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row",marginBottom:"10px",zIndex:"9999"}}>    
											<div style={{display:"flex",alignItems:"center"}}>
												<QueueOutlinedIcon style={{fontSize:"1.5rem",flex:1}} />
											</div>
											<div> 
												<span style={{fontSize:"1rem",marginLeft:"10px"}}>Informações extras sobre o serviço</span>
											</div>
										</div>											
											
										  
								</div>
								
							</div>
						
						</Box>
						

					</Box>
						
					
					<Box style={{width:"100%",position:"fixed",bottom:"20px",display:"flex",flexDirection:"row",justifyContent:"center",zIndex:"9999"}} pl={2} pr={2}>					
						
						<Button color="secondary" variant="outlined" style={{flex:1,height:"48px",marginRight:"10px"}} onClick={() => history.goBack()}>
						 Voltar
						</Button>		
						
						<Button color="primary" variant="contained" style={{flex:2,height:"48px",color:"#FFF"}} onClick={()=>setDialog('wizard',false)}>
						  Proseguir
						</Button>
							
					</Box>			
			
				</Box>				
			
			</Box>

		  </Dialog>
		</div>
		
	);
	
});


/* Extra  Dialog Component START */
const TermsDialog = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {

const screenStyleDialog = {
  alignItems: "stretch",
  justifyContent: "top",
  width:"100vw",
  minHeight:"100vh",
  display:"flex",
  flexDirection:"column",
  backgroundColor:"#FFF"
}; 
	
// set classes var
// const classes = useStyles()();	

const { 
	setModal,
	modal,
	termsContent
} = props;	

	return (
	<div>
		  <Dialog fullScreen open={modal} onClose={()=>setModal(false)} TransitionComponent={TransitionSlideUp}>
			<AppBar style={{position: 'fixed'}}>
			  <Toolbar>
				<IconButton edge="start" color="inherit" onClick={() => setModal(false)} aria-label="close">
				  <CloseIcon />
				</IconButton>
				<Typography variant="h6" style={{marginLeft: "10px",flex: 1}}>
				  Termos de Uso
				</Typography>
				</Toolbar>
			</AppBar>

			<Box m={0} style={{overflow:"hidden"}}>
			
				<Box mt={7} ml={0} mr={0} style={{height:"100%"}}>
					
					<Box p={5} style={{maxHeight:"80%",overflow:"scroll"}}>

						<div style={{flex:1,display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>	
						
							{!termsContent &&
							<>
							  <CircularProgress style={{color:"#333",width:"25px",height:"25px",marginBottom:"10px"}} /> 
							  <h5 style={{color:"#333",marginTop:"5px"}}>Carregando Termos de uso</h5>
							</>
							}
							
							{termsContent &&
							 <div dangerouslySetInnerHTML={{__html: termsContent}} />
							}
							  
						</div>

					</Box>
						
				<Divider style={{marginBottom:"5px"}} />
					
				<Box style={{display:"flex",flexDirection:"row",justifyContent:"center"}} ml={1} mr={1} mb={1} mt={2}>						
					<Button color="secondary" variant="outlined" style={{flex:1,height:"48px"}} onClick={()=>setModal(false)}>
					 Fechar
					</Button>		
						
				</Box>	

					
			
				</Box>				
			
			</Box>

		  </Dialog>
		</div>
		
	);
	
});


export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(HireService);
 
