import './MainAddresses.css';
import React,{useState,useEffect,useRef,Fragment} from 'react';
import {Link,useNavigate} from 'react-router-dom';
import { CSSTransition } from "react-transition-group";

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

import { Box,Backdrop,CircularProgress,Button,Divider,Avatar,FormControlLabel,IconButton,TextField,Typography } from '@mui/material';

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

import Slide from '@mui/material/Slide';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';


import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Checkbox from '@mui/material/Checkbox';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


import Fade from '@mui/material/Fade';
import DeleteIcon from '@mui/icons-material/Delete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import CloseIcon from '@mui/icons-material/Close';

import InputMask from "react-input-mask";

import {useComponentWillMount} from '../../Utils/customFunctions';
import Header from '../../Components/Layout/Header';
import If from '../../Components/Common/If';
// import {AddressDetailDialog} from './DialogAddressDetail';

import Countdown from "react-countdown";

import logo from '../../Assets/Images/logoTypeWOslogan.svg'; // Tell Webpack this JS file uses this image
import backgroundHexagon from '../../Assets/Images/backgroundHexagon.png'; // Tell Webpack this JS file uses this image
import desktop from '../../Assets/Images/desktop.svg'; // Tell Webpack this JS file uses this image
import router from '../../Assets/Images/router.svg'; // Tell Webpack this JS file uses this image
import visa from '../../Assets/Images/visa.svg'; // Tell Webpack this JS file uses this image
import mastercard from '../../Assets/Images/mastercard.svg'; // Tell Webpack this JS file uses this image


// Load Actions
import * as AppActions from '../../Store/Actions/App'
import * as AddressActions from '../../Store/Actions/Address'

const Addresses = props => {


const screenStyle = {
  alignItems: "stretch",
  justifyContent: "top",
  width:"100vw",
  minHeight:"100vh",
  display:"flex",
  flexDirection:"column",
  backgroundImage: "url(" + backgroundHexagon + ")",
  backgroundSize:"cover",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"center"
}; 

const backgroundStyle = {
  backgroundImage: "url(" + backgroundHexagon + ")",
  backgroundSize:"cover",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"center"
}; 


const screenStyleLoading = {
  alignItems: "middle",
  justifyContent: "center",
  width:"100vw",
  height:"100vh",
  display: "flex",
  flexDirection: "column"
}; 


// set vars from props
// Remember to add those who come from Redux
const { 
	innerLoading,
	setInnerLoading,
	saveAddress,
	retrieveAddresses,
	deleteAddress,
	addressesCardsItens,
	dialog,
	setDialog
} = props;

// set Use of history for PUSH 
let navigate = useNavigate();

// Set internal state
const [contentVisible,setContentState] = useState(false);
const [loadingMessage,setLoadingMessage] = useState("Carregando...");

const [addressId, setAddressId] = useState(null); 

const [dialogTitle, setDialogTitle] = useState("Tem certeza que quer excluir?"); // state for tab
const [dialogMessage, setDialogMessage] = useState("Tem certeza que quer excluir o endereço selecionado?"); // state for tab
const [alert, setAlert] = useState(false); // state for tab
const [dialogAlert, setAlertDialog] = useState(false); // state for tab

const [openAddress,setOpenAddress] = useState({});
const [selectedAddress, setSelectedAddress] = useState(false);
const [savingAddress, setSavingAddress] = useState(false);
const [loadingAddress, setLoadingAddress] = useState(true);
const [loadingButton, setLoadingButton] = useState(false);

const [cards,setCards] = useState(addressesCardsItens ? addressesCardsItens:[]);

// Run after mount component
useEffect(() => {
  
	// Retrieve Addresses from API
	var propsParam = {
		setContentState:setContentState,
		setCards:setCards,
		setAlert:setAlert,
		setDialogMessage:setDialogMessage,
		setDialogTitle:setDialogTitle,
		setLoadingAddress:setLoadingAddress
	}		
	retrieveAddresses(propsParam);  

  // set scroll to top
  window.scrollTo(0, 1);
    
  // Runs when UNMOUNT component
  return () => {
    
	// unmounted....
	
  };
  
}, []); // end useEffect


// Will Mount Component HOOK
useComponentWillMount(() => {
	
    // Set session of app
  var sessionCheck = sessionStorage.getItem('@motuum/appLaunched');
  
  // if session is not created, redirect to bootstrap. (avoid direct access using url)
  if (!sessionCheck) {
	  navigate("/");
  } // end if	
      
}, []); // end useComponentWillMount


const deleteAddressPrompt = (addressId) => {
	
	setAddressId(addressId);		
	setDialogTitle("Tem certeza que quer prosseguir?");
	setDialogMessage("Tem certeza que quer excluir o endereço selecionado? Essa ação é irreversível.");	
	setDialog('deleteAddressPrompt',true);	
	
}

const deleteAddressAction = () => {
	
	setLoadingButton(true);

	// Retrieve Addresses from API
	var propsParam = {
		setDialog:setDialog,
		setAlert:setAlert,
		setDialogMessage:setDialogMessage,
		setDialogTitle:setDialogTitle,
		setLoadingButton:setLoadingButton,
		cards:cards,
		setCards:setCards
	}
	var data = {
		addressId:addressId
	}	
	deleteAddress(data,propsParam);  
	
} 

const viewAddressAction = (addressId) => {
	
		var array = [...cards]; // clone
		var index = array.filter(item => item.id == addressId);	
		var finalCard = index[0];

		setOpenAddress(finalCard);
		setDialog('viewaddress',true);
	
}

	return (
	<Fragment>
			
			<Header title="Catálogo de Endereços" buttons={{back:true,support:false,menu:false,close:false,fakeRight:true}} />
			
			{/* Inner Loading */}
			{loadingAddress &&
				<Box className="loadingContent" style={{...screenStyleLoading}}>
						<Box mt={0} mx={0}>								
							<CircularProgress /> 
							<div> Carregando...Aguarde!</div>								
						</Box>	
				</Box>
			}		

			<Fade in={contentVisible} timeout={{enter:1000,exit:300}}  mountOnEnter unmountOnExit>	
			
				<Box className="mainHome" style={{...screenStyle}} mt={0}>						

						<Box pl={2} pr={2} mt={11} style={{alignItems:"stretch",display:"flex",flexDirection:"column",justifyContent:"center",paddingBottom:"120px",paddingLeft:"30px",paddingRight:"30px"}}>	
						
							<Box style={{marginTop:"30px",marginBottom:"30px"}}>						
								<div style={{fontFamily:"Roboto",fontWeight:"100",fontSize:"42px",color:"#003CFA",lineHeight:"38px"}}>
									  ENDEREÇOS CADASTRADOS
								</div>								 	
							</Box>	
						
						
							{cards.map((item, index) => {
								
									// try to get coordinates
									let coordinates = JSON.parse(item.googleCoordinates);
									let addressText = item.fullAddress;
									let addressCoordinate = coordinates.lat+","+coordinates.lng;
											
									let finalAddress = "https://maps.googleapis.com/maps/api/staticmap?center="+addressCoordinate+"&zoom=11&size=140x116&maptype=roadmap&markers=color:blue%7C"+addressCoordinate+"&key=AIzaSyBdE3uu0p9cJ_961a03aXMo0kGSzuUfTVE";
															
									return (
									

									<Card key={index} style={{height:"180px",width:"100%",marginBottom:"20px",backgroundColor:"#ffffff",boxShadow: "0px 10px 20px 0px #0000002A",borderRadius:"10px"}}>
									
									  <CardContent style={{padding:"10px",paddingBottom:"0px"}}>
										
										<Box style={{display:"flex",flexdirection:"row",alignItems:"center",justifyContent:"space-between"}}>
											
											<Box style={{padding:"3px"}}>
												
												<Box>										
													<img src={finalAddress} style={{width:"140px",height:"auto",backgroundColor:"whitesmoke",borderRadius:"10px"}} />
												</Box>
												
											</Box>												
											
											<Box style={{padding:"3px", width:"126px",}}>
												
													<div style={{textAlign:"right",float:"right"}}>
														<h3 style={{color:"#003CFA",fontFamily:"Roboto",fontWeight:"bold",fontSize:"16px",marginTop:"0px",marginBottom:"5px",lineHeight:"18px"}}>{item.label}</h3>
														<div style={{color:"#636466",fontFamily:"Roboto",fontWeight:"normal",fontSize:"12px",lineHeight:"18px"}}>
															{item.fullAddress}
														</div>
													</div>											
												
											</Box>	
										
										</Box>
										
									  </CardContent>
										
										<CardActions style={{display:"flex",flexDirection:"row",backgroundColor:"#E3000010",padding:"2px",marginTop:"9px"}}>
												  
										  <div style={{flex:1,textAlign:"center"}}>
												<Button style={{color:"#E30000",fontFamily:"Roboto",fontWeight:"normal",fontSize:"12px"}} size="small" onClick={()=>deleteAddressPrompt(item.id) }>										
													<DeleteIcon style={{fontSize:"20px",color: '#E30000',marginRight:"5px"}} />
													<div>Excluir Endereço</div>										
												</Button>
											</div>
											
										  </CardActions>										

									</Card>
								
								)}
							
							)}		

							{/* If there is no result */}
							{cards.length == 0 &&
								<div style={{height:"70vh",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center"}}>
								
									<div style={{flex:1.5,display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"flex-end",marginBottom:10}}>
									  <NotInterestedIcon style={{fontSize:"7rem",color: "#DDD"}} />
									</div>
									
									<div style={{flex:1.5,display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"flex-start"}}>
										<div style={{fontSize:20,color:"silver",textAlign:"center"}}>Nenhum endereço cadastrado</div>
									</div>													
								
								</div>				
							}								

						</Box>	
						
				
				{/*<Box style={{...backgroundStyle,padding:"15px",width:"100%",position:"fixed",bottom:"0px",display:"flex",flexDirection:"row",justifyContent:"center"}}>	
						
								<Button color="secondary" variant="contained" style={{width:"80%",height:"48px"}} onClick={()=>setDialog('address',true)}>
								  Adicionar novo endereço
								</Button>
								
				</Box>	*/}	


						<Box style={{backgroundColor:"transparent",display:"flex",width:"100%",alignItems:"center",position:"fixed",bottom:0,height:"110px",backgroundColor:"#ffffff",boxShadow: "0px -10px 15px 0px #0000001A"}} onClick={()=>console.log(true)}>		

							<Box style={{flex:1,display:"flex",flexDirection:"row",justifyContent:"space-around"}}>
									
								<Box style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>	
									<Button color="secondary" variant="contained" style={{width:"100%",height:"54px",fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px"}} onClick={()=>setDialog('address',true)}>
									  Adicionar novo endereço
									</Button>
						
								</Box>	

							</Box>								
								
						</Box>	
						
						
				</Box>
			
			</Fade> 

			<AlertDialog 
				alert={alert}
				setAlert={setAlert}
				dialogTitle={dialogTitle}
				dialogMessage={dialogMessage}
			/>		

			<ConfirmDialog 
				dialog={dialog}
				setDialog={setDialog}
				dialogTitle={dialogTitle}
				dialogMessage={dialogMessage}
				loadingButton={loadingButton}
				setLoadingButton={setLoadingButton}
				deleteAddressAction={deleteAddressAction}
			/>			
			
			<AddressDialog 
				setAlert={setAlert}
				setDialogMessage={setDialogMessage}
				setDialogTitle={setDialogTitle}
				cards={cards}
				setCards={setCards}
			/>
			
			<ViewAddressDialog 
				alert={alert}
				setAlert={setAlert}
				openAddress={openAddress}
			/>			
			
	</Fragment>
)};


export const ConfirmDialog = (props) => {
	
	return (
		 <Dialog
			open={props.dialog.target == 'deleteAddressPrompt' && props.dialog.status}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		  >
		  <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
			<DialogContent>
			  <DialogContentText id="alert-dialog-description">
			  {props.dialogMessage}
			  </DialogContentText>
			</DialogContent>
			<DialogActions>

			  <Button disabled={props.loadingButton} onClick={ ()=>props.setDialog('deleteAddressPrompt',false) } color="primary" autoFocus>
				FECHAR
			  </Button>			

			  <Button disabled={props.loadingButton} onClick={ ()=> { props.deleteAddressAction(); } } color="primary" autoFocus>				
				{ !props.loadingButton &&
				   <div>CONFIRMAR</div>
				}
				
				{ props.loadingButton &&
				<>
				   <CircularProgress size={30} color="inherit" />
				   <span style={{marginLeft:"10px"}}>Aguarde...</span>
				</>
				}				
			  </Button>
				
			</DialogActions>			
		  </Dialog>
	  )
		
} // end AlertDialog

export const AlertDialog = (props) => {
	
	return (
		 <Dialog
			open={props.alert} 
			onClose={()=>props.setAlert(false)}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		  >
		  <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
			<DialogContent>
			  <DialogContentText id="alert-dialog-description">
			  {props.dialogMessage}
			  </DialogContentText>
			</DialogContent>
			<DialogActions>
			  <Button onClick={() => props.setAlert(false)} color="primary" autoFocus>
				OK
			  </Button>
			</DialogActions>			
		  </Dialog>
	  )
	
} // end AlertDialog

const mapStateToProps = store => ({
  innerLoading:store.appReducer.innerLoading,
  dialog:store.appReducer.dialog,
  addressesCardsItens:store.addressReducer.addressesCards,  
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AppActions, ...AddressActions }, dispatch);

// Transition Slide for Dialog SLIDE UP	
const TransitionSlideUp = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;  
});	

/* Address Dialog Component START */
export const AddressDialog = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {

const screenStyleLoading = {
  alignItems: "middle",
  justifyContent: "center",
  width:"100vw",
  height:"100vh",
  display: "flex",
  flexDirection: "column"
};

const screenStyleDialog = {
  alignItems: "stretch",
  justifyContent: "top",
  width:"100vw",
  display:"flex",
  flexDirection:"column",
  backgroundColor:"#FFF"
}; 


// set vars from props
// Remember to add those who come from Redux
const { 
	setDialog,
	dialog,
	saveAddress,
	addressesCardsItens
} = props;	

/* Dialog END*/	

const [selectedAddress, setSelectedAddress] = useState(false);
const [savingAddress, setSavingAddress] = useState(false);
const [loadingAddress, setLoadingAddress] = useState(true);

// Set existing addresses 
const [addressesCards,setAddressesCard] = useState(addressesCardsItens ? addressesCardsItens:[]);
const [selectedAddressId, setSelectedAddressId] = useState("");
const [selectedAddressLabel, setSelectedAddressLabel] = useState("");
const [selectedStreet, setSelectedStreet] = useState("");
const [selectedStreetNumber, setSelectedStreetNumber] = useState("");
const [selectedStreetComplement, setSelectedStreetComplement] = useState("");
const [selectedPostalCode, setSelectedPostalCode] = useState("");
const [selectedStreetReference, setSelectedStreetReference] = useState("");
const [selectedSuburb, setSelectedSuburb] = useState("");
const [selectedCity, setSelectedCity] = useState("");
const [selectedUF, setSelectedUF] = useState("");
const [selectedUfCode, setSelectedUfCode] = useState("");
const [selectedCountry, setSelectedCountry] = useState("");
const [selectedCoordinates, setSelectedCoordinates] = useState("");
const [selectedPlaceId, setSelectedPlaceId] = useState("");
const [selectedMainAddress, setSelectedMainAddress] = useState(false);
const [addressURL, setAddressURL] = useState("");
const [complementNewAddress, setComplementNewAddress] = useState(false);
const [address, setPlaceAddress] = useState("");

const handleChange = address => {
	setPlaceAddress( address );
};
 
// Selection using Googgle Places Query
const handleSelect = (address,placeId,suggestion) => {

// Try first
try {

// Separate street from rest
setSelectedStreet(suggestion.terms[0].value); // set street
setSelectedSuburb(suggestion.terms[1].value); // set suburb
setSelectedCity(suggestion.terms[2].value); // set city
setSelectedUF(suggestion.terms[3].value); // set UF
setSelectedUfCode(suggestion.terms[3].value);	
setSelectedCountry(suggestion.terms[4].value); // set UF
setSelectedPlaceId(placeId); // set placeId

// Set selected address to textFiel
setPlaceAddress( address );

// Set that address was selected
setSelectedAddress(true);
 
// Retrieve GeoCode by Address 
geocodeByAddress(address)
  .then(results => getLatLng(results[0]))
  .then(latLng => { 				
		setSelectedCoordinates(JSON.stringify(latLng));
		
		// Set google maps static URL
		let addressText = latLng.lat+","+latLng.lng;
		let finalAddress = "https://maps.googleapis.com/maps/api/staticmap?center="+addressText+"&zoom=11&size=328x100&maptype=roadmap&markers=color:blue%7Clabel:S%7C40.702147,-74.015794&markers=color:green%7Clabel:G%7C40.711614,-74.012318&markers=color:red%7Clabel:C%7C40.718217,-73.998284&key=AIzaSyBdE3uu0p9cJ_961a03aXMo0kGSzuUfTVE";		
		setAddressURL(finalAddress);

	})
  .catch(error => console.error('Error', error));
  
} catch (e) {
	
		props.setDialogMessage("Ocorreu um problema com o endereço selecionado. Adicione mais informações para que apareça a rua, cidade, bairro e estado. Endereços parciais não são aceitos.");
		props.setAlert(true);
	
} // end catch
    
};

const saveAddressAction = (props) => {

	// Check if required fields are OK
	if (!selectedStreetNumber || !selectedStreetComplement || !selectedAddressLabel || !selectedPostalCode) {		
		props.setDialogTitle("Verifique as informações");
		props.setDialogMessage("Preencha todos os campos marcados com * para prosseguir.");
		props.setAlert(true);	
		return false;		
	} // end if	
	
	var postalCodeCheck = selectedPostalCode.replace(/\D/g,"");
	if (postalCodeCheck.length < 8) {	
		props.setDialogTitle("Verifique o CEP");	
		props.setDialogMessage("Preencha o CEP corretamente.");
		props.setAlert(true);	
		return false;		
	} // end if	
	
	// Set loading on...
	setSavingAddress(true);
		
	// Break Street Address into streetType and streetName
	var tmp = selectedStreet.split(" ");
	var streetType = tmp[0].replace(".","");
	var streetName = selectedStreet.substr(tmp[0].length + 1,(selectedStreet.length - tmp[0].length) ); 
		
	// Set Full Adress
	var fullAddress = selectedStreet+", "+selectedStreetNumber+", "+selectedStreetComplement+", "+selectedCity+", "+selectedUfCode+", "+selectedSuburb+", "+selectedCountry;
	
	// Set Redux Address
	var addressObj = {
		label:selectedAddressLabel,
		streetType:streetType,
		streetName:streetName,
		streetNumber:selectedStreetNumber,
		streetComplement:selectedStreetComplement,
		postalCode:postalCodeCheck,
		suburb:selectedSuburb,
		city:selectedCity,
		state:selectedUfCode,
		country:selectedCountry,
		fullAddress:fullAddress,
		googleCoordinates:selectedCoordinates,
		googlePlaceId:selectedPlaceId,
		mainAddress:selectedMainAddress 		
	}	
	var props = {
		cards:props.cards,
		setCards:props.setCards,
		setSavingAddress:setSavingAddress,
		setAlert:props.setAlert,
		setDialogMessage:props.setDialogMessage,
		setDialogTitle:props.setDialogTitle,
		setDialog:setDialog,		
		setComplementNewAddress:setComplementNewAddress,
		cleanAddress:cleanAddress		
	}		
	saveAddress(addressObj,props);
};	

const cleanAddress = () => {
	
	setPlaceAddress("");
	setSelectedAddress(false);	
		
	setSelectedStreet(""); 
	setSelectedSuburb("");
	setSelectedCity(""); 
	setSelectedUF("");
	setSelectedCountry("");
	setSelectedPlaceId(""); 

}

const handleAcceptTerms = (event) => {

const newValue = event.target.checked
setSelectedMainAddress(newValue);
}; 

// Run after mount component
useEffect(() => {


}, []); // end useEffect

// useEffect only for dialgo changes
useEffect(() => {
	
	// execute only if this modal
	if (dialog.target == 'address' && dialog.status) {


		
	}  // end if

}, [dialog]); // end useEffect only for dialgo changes

const handleKeypress = e => {
	
	 //it triggers by pressing the enter key  
	if (e.key === "Enter") {
	  saveAddressAction(props);
	}

};

return (
	<div>		
		  <Dialog fullScreen open={dialog.target == 'address' && dialog.status} onClose={()=>setDialog('address',false)} TransitionComponent={TransitionSlideUp}>
			<AppBar  sx={{
				position: 'fixed'
			}}>
			  <Toolbar>
				<IconButton disabled={savingAddress} edge="start" color="inherit" onClick={() => setDialog('address',false)} aria-label="close">
				  <CloseIcon />
				</IconButton>
				<Typography variant="h6" style={{
					marginLeft: "10px",
					flex: 1,
				}}>
				  Criando um novo endereço
				</Typography>
				</Toolbar>
			</AppBar>

			<Box style={{...screenStyleDialog}} m={0}>
		
				<Box mt={7} ml={0} mr={0} style={{height:"79vh",overflow:"scroll"}}>
						
					{ !complementNewAddress &&
					<>
					<Box style={{marginBottom:"0px",alignItems:"center",justifyContent:"center",display:"flex",flexDirection:"column",paddingLeft:"30px",paddingRight:"30px",textAlign:"center"}} >
					
								<Box style={{marginTop:"30px",marginBottom:"20px"}}>						
									<div style={{fontFamily:"Roboto",fontWeight:"100",fontSize:"42px",color:"#003CFA",lineHeight:"38px"}}>
										  ADICIONAR ENDEREÇO
									</div>								 	
								</Box>	

								<div style={{flex:1,alignItems:"stretch",justifyContent:"flex-start",display:"flex",flexDirection:"column"}}>  Digite a rua do endereço e selecione o seu endereço na lista. Não é necessário colocar número da rua, pois você complementará o endereço em seguida.  									
								</div>									
																		
					</Box>					
					
					<Box p={2} style={{height:"60vh"}}>

						<PlacesAutocomplete
							value={address}
							onChange={handleChange}
							onSelect={handleSelect}
							
						  >
							{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {	
							
								return (
							  <div>

							  <TextField 
							  id="outlined-basic"
							  label="Endereço do Local"
							  fullWidth
							  onClick={()=>cleanAddress()}
							  variant="outlined"
								{...getInputProps({
									placeholder: 'Digite o endereço ...'
								  })}
							  />
								<div style={{maxHeight:"260px",overflow:"auto",backgroundColor:"whitesmoke",padding:"5px",borderWidth:"1px",borderRadius:"10px",borderColor:"red"}}>
								  {loading && <div>Carregando...</div>}
								  {suggestions.map(suggestion => {
									const className = suggestion.active
									  ? 'suggestion-item--active'
									  : 'suggestion-item';
									// inline style for demonstration purpose
									const style = suggestion.active
									  ? { backgroundColor: '#fff', cursor: 'pointer',fontSize:"14px",marginBottom:"5px",borderRadius:"2px" }
									  : { backgroundColor: '#fff', cursor: 'pointer',fontSize:"14px",marginBottom:"5px",borderRadius:"2px" };
									return (
									  <div key={suggestion.index}
										{...getSuggestionItemProps(suggestion, {
										  className,
										  style,
										})}
									  >
										<span>{suggestion.description}</span>
									  </div>
									);
								  })}
								</div>
							  </div>
							)}
							
							}
						  </PlacesAutocomplete>
						  
						  { selectedAddress &&
						  <>
						  <div style={{margin:"20px",padding:"10px",borderWidth:"1px",borderColor:"gray",borderRadius:"10px",backgroundColor:"whitesmoke"}}>
							<div><strong>Rua:</strong> {selectedStreet} </div>
							<div><strong>Bairro:</strong> {selectedSuburb} </div>
							<div><strong>Cidade:</strong> {selectedCity} </div>
							<div><strong>UF:</strong> {selectedUfCode}</div>						  
							<div><strong>País:</strong> {selectedCountry}</div>							  
						  </div>
						  
							<Box mb={1} mt={1} style={{textAlign:"center"}}> 
								<img src={addressURL} style={{width:"100%",height:"100px",backgroundColor:"whitesmoke"}} />
							</Box>
							</>
						  }

					</Box>
					</>
					}

					
					{ (selectedAddress && complementNewAddress) &&
						<Box ml={2} mr={2} mb={5} mt={2} style={{height:"70vh"}}>
						
							<h3 style={{textAlign:"center",marginBottom:"5px"}}>Endereço selecionado</h3>
							<Divider variant="middle" style={{marginBottom:"10px"}} />
						
							<Box mb={1} mt={1} style={{textAlign:"center"}}> 
								<div> {selectedStreet}, {selectedSuburb} </div>
								<div> {selectedCity} - {selectedUfCode} </div>							
							</Box>

							<Box mb={1} mt={1} style={{textAlign:"center"}}> 
								<img src={addressURL} style={{width:"100%",height:"100px",backgroundColor:"whitesmoke"}} />
							</Box>
												
							<h3 style={{textAlign:"center",marginBottom:"5px"}}>Informe o complemento de seu endereço</h3>
							<Divider variant="middle" style={{marginBottom:"10px"}} />

							<TextField
							  label="Título do endereço *"
							  placeholder="Ex.: Minha casa, Casa dos meus pais, Trabalho..."
							  style={{ margin: 10,marginLeft:0,marginRight:0,backgroundColor:"#fff",width:"100%"}}
							  size="small"
							  variant="outlined"
							  type="text"
							  onKeyPress={handleKeypress}
							  value={selectedAddressLabel}
							  onChange={(event) => {
								  setSelectedAddressLabel(event.target.value)
							  }}							  
							/>	
									
							<div style={{display:"flex",flexDirection:"row",alignItems:"stretch",justifyContent:"center"}} >
							
								<TextField
								  label="Número *"
								  style={{flex:1, margin: 10,marginLeft:0,marginRight:10,backgroundColor:"#fff",width:"100%"}}
								  size="small"
								  variant="outlined"
								  type="number"
								  onKeyPress={handleKeypress}
								  value={selectedStreetNumber}
								  onChange={(event) => {
									  setSelectedStreetNumber(event.target.value)
								  }}									  
								/>
								
								<TextField
								  label="Complemento *"
								  style={{flex:2, margin: 10,marginLeft:0,marginRight:0,backgroundColor:"#fff",width:"100%"}}
								  size="small"
								  variant="outlined"
								  type="text"
								  onKeyPress={handleKeypress}
								  value={selectedStreetComplement}
								  onChange={(event) => {
									  setSelectedStreetComplement(event.target.value)
								  }}									  
								/>
							
							</div>
							 
							<div style={{display:"flex",flexDirection:"row",alignItems:"stretch",justifyContent:"center"}} >
								
								<InputMask
								  mask="99999-999"
								  value={selectedPostalCode}
								  onChange={(event) => {
									setSelectedPostalCode(event.target.value);
								  }}
								  onKeyPress={handleKeypress}
								>
								  {(inputProps) => (
									<TextField
									  {...inputProps} // Espalha as props do input aqui
									  label="CEP *"
									  style={{ flex: 1, margin: 10, marginLeft: 0, marginRight: 10, backgroundColor: "#fff", width: "100%" }}
									  size="small"
									  variant="outlined"
									/>
								  )}
								</InputMask>								
								
								<TextField
								  label="Ponto de referência"
								  style={{ flex:2, margin: 10,marginLeft:0,marginRight:0,backgroundColor:"#fff",width:"100%"}}
								  size="small"
								  variant="outlined"
								  type="text"
								  onKeyPress={handleKeypress}
								  value={selectedStreetReference}
								  onChange={(event) => {
									  setSelectedStreetReference(event.target.value)
								  }}							  
								/>	
							
							</div>
						
									  
							<div style={{marginTop:"10px",marginBottom:"10px"}}>
							
								  <Checkbox												
									checked={selectedMainAddress}
									onChange={handleAcceptTerms}
									inputProps={{ 'aria-label': 'primary checkbox' }}
								  />
								  
								  <span>
									Tornar esse endereço como principal
								  </span>
								  
							</div>						
						
						</Box>
					}					
			
					
				</Box>				
				
					<Divider />
						
					<Box style={{display:"flex",flexDirection:"row",justifyContent:"center"}} ml={1} mr={1} mb={1} mt={1}>						
						{ (!complementNewAddress) &&
						<Button color="secondary" variant="outlined" style={{flex:1,height:"53px",marginRight:"10px",borderRadius:"10px"}} onClick={()=>setDialog('address',false)}>
						 Fechar
						</Button>
						}	

						{ ( (selectedAddress && complementNewAddress) ) &&
						<Button color="secondary" disabled={savingAddress} variant="outlined" style={{flex:1,height:"53px",marginRight:"10px",borderRadius:"10px"}} onClick={()=>{ setComplementNewAddress(false);cleanAddress() } }>
						 Cancelar
						</Button>
						}							

						{ ( !complementNewAddress) &&
						<Button disabled={!selectedAddress} color="primary" variant="contained" style={{flex:2,height:"53px",color:"#FFF"}}  onClick={()=>setComplementNewAddress(true)}>
						  Próximo
						</Button>
						}
						
						{ (selectedAddress && complementNewAddress) &&
						<Button disabled={!selectedAddress} color="primary" variant="contained" style={{flex:2,height:"53px",color:"#FFF"}}  onClick={()=>saveAddressAction(props)}>
							{ !savingAddress &&
							   <div>Confirmar Endereço</div>
							}
							
							{ savingAddress &&
							<>
							   <CircularProgress size={30} color="inherit" />
							   <span style={{marginLeft:"10px"}}>Gravando endereço...</span>
							</>
							}	
						</Button>
						}						
							
					</Box>	
		
			
			</Box>

		  </Dialog>
		 
		</div>
		
	);
	
});


/* Address Dialog Component START */
export const ViewAddressDialog = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {
	

const screenStyleLoading = {
  alignItems: "middle",
  justifyContent: "center",
  width:"100vw",
  height:"100vh",
  display: "flex",
  flexDirection: "column"
};

const screenStyleDialog = {
  alignItems: "stretch",
  justifyContent: "top",
  width:"100vw",
  display:"flex",
  flexDirection:"column",
  backgroundColor:"#FFF"
}; 
	


// set vars from props
// Remember to add those who come from Redux
const { 
	setDialog,
	dialog,
	openAddress
} = props;	

const [address,setAddress] = useState("");

// useEffect only for dialgo changes
useEffect(() => {
	
	// execute only if this modal
	if (dialog.target == 'viewaddress' && dialog.status) {

		try {
		// Set address for STATIC MAP dinamically
		/*let coordinates = JSON.parse(props.openAddress.googleCoordinates);
		let addressText = props.openAddress.fullAddress;
		let addressCoordinate = coordinates.lat+","+coordinates.lng;
				
		let finalAddress = "https://maps.googleapis.com/maps/api/staticmap?center="+addressCoordinate+"&zoom=17&size=600x600&maptype=roadmap&markers=color:blue%7C"+addressCoordinate+"&key=AIzaSyBdE3uu0p9cJ_961a03aXMo0kGSzuUfTVE";*/

		var addressText = props.openAddress.streetType+" "+props.openAddress.streetName+", "+props.openAddress.streetNumber+", "+props.openAddress.suburb+", "+props.openAddress.city+", "+props.openAddress.state+", Brazil";
		
		var finalAddress = "https://maps.googleapis.com/maps/api/staticmap?center="+addressText+"&zoom=16&size=600x600&maptype=roadmap&key=AIzaSyBdE3uu0p9cJ_961a03aXMo0kGSzuUfTVE";				
		 
		setAddress(finalAddress);
		} catch(e) {
			setDialog('viewaddress',false);			
		}
		
	}  // end if
 
}, [dialog]); // end useEffect only for dialog changes

return (
	<div>		
		  <Dialog fullScreen open={dialog.target == 'viewaddress' && dialog.status} onClose={()=>setDialog('viewaddress',false)} TransitionComponent={TransitionSlideUp}>
			<AppBar sx={{
				position: 'fixed'
			}}>
			  <Toolbar>
				<IconButton edge="start" color="inherit" onClick={() => setDialog('viewaddress',false)} aria-label="close">
				  <CloseIcon />
				</IconButton>
				<Typography variant="h6" style={{
					marginLeft: "10px",
					flex: 1,
				}}>
				  Visualizando endereço
				</Typography>
				</Toolbar>
			</AppBar>

			<Box style={{...screenStyleDialog}} m={0}>
			
					<Box mt={7} ml={2} mr={2} style={{height:"79vh",overflow:"scroll"}}>
							
							<Box mt={2}>										
								
									<div style={{textAlign:"left",fontSize:"0.85rem"}}>
										<h3 style={{marginTop:"0px",marginBottom:"0px"}}>{props.openAddress.label}</h3>
										<div>
											{props.openAddress.fullAddress}
										</div>
										<div style={{marginTop:"10px"}}>
											<strong>CEP:</strong> {props.openAddress.postalCode}
										</div>										
									</div>											
								
							</Box>							


							<Box mt={2} mb={2} style={{textAlign:"center",alignItems:"stretch",justifyContent:"center",display:"flex",flexDirection:"column"}}>
							
								<img src={address} style={{width:"100%",height:"auto",backgroundColor:"whitesmoke"}} />
								<i> A localização no Google Maps acima é aproximada. Pode existir discrepância com o endereço informado. </i>
							</Box>

						
					</Box>				
				
					<Divider />
						
					<Box style={{display:"flex",flexDirection:"row",justifyContent:"center"}} ml={1} mr={1} mb={1} mt={1}>						
						<Button color="secondary" variant="outlined" style={{flex:1,height:"48px",marginRight:"10px"}} onClick={()=>setDialog('viewaddress',false)}>
						 Fechar
						</Button>
							
					</Box>	
		
			
			</Box>

		  </Dialog>
		 
		</div>
		
	);
	
});


export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(Addresses);

