import './MainHome.css';
import React,{useState,useEffect,Fragment} from 'react';
import {Link,useNavigate} from 'react-router-dom';
import { CSSTransition } from "react-transition-group";

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

import { Box,Backdrop,CircularProgress,Button,Divider,IconButton } from '@mui/material';

import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from '@mui/icons-material/Warning';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import NotInterestedIcon from '@mui/icons-material/NotInterested';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Fade from '@mui/material/Fade';
import Slide from '@mui/material/Slide';
import AnimateHeight from 'react-animate-height';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

import {useComponentWillMount} from '../../Utils/customFunctions';
import Header from '../../Components/Layout/Header';
import If from '../../Components/Common/If';
import NavigationBottom from '../../Components/Layout/NavigationBottom';
import ZoomIcon from '@mui/icons-material/ZoomIn';

import backgroundHexagon from '../../Assets/Images/backgroundHexagon.png'; 
import backgroundHeaderImage from '../../Assets/Images/backgroundHeader.png'; 
import helpIcon from '../../Assets/Images/help-icon-blue.svg';
import logo from '../../Assets/Images/logo_motuum-white.svg'; 
import desktopIcon from '../../Assets/Images/desktopIcon.svg'; 
import iconOngoingBlue from '../../Assets/Images/iconOngoing-blue.svg'; 
import backIconBlue from '../../Assets/Images/backIconBlue.svg'; 

// Load Actions
import * as AppActions from '../../Store/Actions/App'
import * as ServiceActions from '../../Store/Actions/Service'
import SearchComponent from '../SearchService/SearchComponent';

const Home = props => {


const screenStyle = {
  alignItems: "stretch",
  justifyContent: "top",
  width:"100vw",
  minHeight:"100vh",
  display:"flex",
  flexDirection:"column",
  backgroundImage: "url(" + backgroundHexagon + ")",
  backgroundSize:"cover",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"center"
}; 

const screenStyleLoading = {
  alignItems: "middle",
  justifyContent: "center",
  width:"100vw",
  height:"100vh",
  display: "flex",
  flexDirection: "column"
}; 

// set classes var
// const classes = useStyles()();

// set vars from props
// Remember to add those who come from Redux
const { 
	innerLoading,
	setInnerLoading,
	UserData,
	UserDataObj,
	categories,
	retrieveCategories,
	setService
} = props;

// set Use of history for PUSH 
let navigate = useNavigate();

// Set internal state
const [contentVisible,setContentState] = useState(false);
const [firstName, setFirstName] = React.useState(UserData.firstName ? UserData.firstName:"");
const [validated,setValidated] = React.useState(UserData.validated ? UserData.validated: 0);

const [Level,setLevel] = useState(0);
const [SubLevel,setSubLevel] = useState(0);
const [ServicesResult,setServicesResult] = useState(false);
const [ServicesResultSearch,setServicesResultSearch] = useState(false);
const [searchKeyword,setSearchKeyword] = useState("");

const [heightPanel,setHeightPanel] = useState(0);
const [heightPanelServices,setHeightPanelServices] = useState(0);

const [selectedLevel,setSelectedLevel] = useState({});
const [selectedLevelChilds,setSelectedLevelChilds] = useState([]);
const [selectedCategory,setSelectedCategory] = useState({});
const [cards,setCards] = useState([]);


const [dialogAlert, setAlertDialog] = useState(false); // state for tab
const [dialogMessage, setDialogMessage] = useState(""); // state for tab
const [dialogTitle, setDialogTitle] = useState("Verifique as informações"); // state for tab

  
// Run after mount component
useEffect(() => {
	
	// Retrieve User Data Obj. 
	var result = localStorage.getItem("@motuum/userData", undefined,true);
	var userData = JSON.parse(result);  
	
	// Rediredct if there is no userData
	if (!userData) {
	navigate("/");  
	return;
	} // end if	
	
	// Comunicate with Webview
	try {
		window.ReactNativeWebView.postMessage(JSON.stringify(userData));
	}
	catch(e) {
		console.log("CATCH"+e.message);
	}

	// Propagate name and activaiton status
	setFirstName(userData.firstName ? userData.firstName:"");
	setValidated(UserData.activationDate ? 1:0);
	 
 // Set Content Loading ON
 //setInnerLoading(true);
 setContentState(true);
 
 	// Data
	var data = {
		parentId:0  
	}  
	var props = {
		navigate:navigate,
		setAlert:setAlertDialog,
		setDialogMessage:setDialogMessage,
		setDialogTitle:setDialogTitle,
		setContentState:setContentState
	}	
	// Load Categories Firts Level...
	retrieveCategories(data,props);

  // set scroll to top
  window.scrollTo(0, 1);
    
  // Runs when UNMOUNT component
  return () => {
    
	
	
  };
  
}, []); // end useEffect

// Generic Select Icon for multiple selects
const selectLevel = (number) => {
	
	// Collapse panel animating if category is selected
	if (selectedCategory.id) {
		setHeightPanel(0);	
		setHeightPanelServices(0);
	} // end if
	
	if (number == 0) {

		setServicesResult(false);				
		setSubLevel(0)					
		setLevel(0);
		
	} else if (number > 0) {
		
		setServicesResult(false);
		
		setTimeout(function() {
			
			// Set selected level 
			var array = [...categories]; // clone
			var index = array.filter(item => item.id == number);
			var reducedArray = index[0];
			setSelectedCategory(reducedArray);
			setSelectedLevel(reducedArray);		
			
			// Set childs of selectedLevel
			var arrayChilds = [...reducedArray.childs]; // clone
			setSelectedLevelChilds(arrayChilds);		
				
			setLevel(number);		
			setSubLevel(1);

			// Open panel animating with delay 
			
			setHeightPanel('auto');
			
		},selectedCategory.id ? 400 : 0);		
		
	}

};

// Generic Select Icon for multiple selects
const searchServices = (category) => {	

	var array = [...selectedLevelChilds]; // clone
	var index = array.filter(item => item.id == category);
	var reducedArray = index[0];

	setSelectedCategory(reducedArray);
	setCards(reducedArray.Services);	
	
	setServicesResult(true);
	setHeightPanelServices('auto');

};

// Generic Select Icon for multiple selects
const selectService = (serviceId) => {	

	var array = [...selectedCategory.Services]; // clone
	var index = array.filter(item => item.id == serviceId);
	var reducedArray = index[0];
	setService({
		...reducedArray,
		breadcrumb:selectedCategory.breadcrumb,
		categoryId:selectedCategory.id
	});
	
	// go to...
	goTo("/searchService/view/"+serviceId+"");	

};


// Go to Target when click on card
const goTo = (url) => {	
	navigate(url);
}; 


const needActivation = () => {
	
	setAlertDialog(true);
	setDialogMessage("Para solicitar um serviço é necessário que ative seu perfil através da confirmação de seu email ou telefone celular.");
	setDialogTitle("Ative seu perfil");	
	
}


// Will Mount Component HOOK
useComponentWillMount(() => {
	
    // Set session of app
  var sessionCheck = sessionStorage.getItem('@motuum/appLaunched');
  
  // if session is not created, redirect to bootstrap. (avoid direct access using url)
  if (!sessionCheck) {
	  navigate("/");
  } // end if	
  
	// Retrieve User Data Obj. 
	var result = localStorage.getItem("@motuum/userData", undefined,true);
	var userData = JSON.parse(result);  
	  
	  // If token dont exist, redirect to onboarding or register
	  if (!userData) {		  
		navigate("/");  
	  } // end if
	  // If token exist, redirect to HOME
	  else {
		  if (!userData.userToken) {
			navigate("/");
		  } // end if
	  } // end else    
        
}, []); // end useComponentWillMount

// filter:"grayscale(100%) contrast(160%)"

const handleClose = () => {
	setInnerLoading(false);
};

	return (
	<Fragment>
			
			<Header title="Motuum" buttons={{support:false,menu:true,close:false,rightFake:true,home:true}} />
			
			<CSSTransition in={contentVisible} timeout={1000} classNames="fade"	unmountOnExit>	
			
				<Box className="mainHome" style={{...screenStyle}} m={0}>

						<Box style={{flex:1,alignItems:"center",overflow:"scroll"}} mt={19} ml={0} mr={0}>

							{/* Search Service Component */}
							<SearchComponent />
							
						</Box>
						
						{/* Bottom Navigation Bar */}
						<NavigationBottom page="home" />
														
				</Box>
			
			</CSSTransition> 			
			
			<AlertDialog 
				dialogAlert={dialogAlert}
				setAlertDialog={setAlertDialog}
				dialogTitle={dialogTitle}
				dialogMessage={dialogMessage}
			/>					
			
				
	</Fragment>
)};

const AlertDialog = (props) => {
	
	return (
		 <Dialog
			open={props.dialogAlert}
			onClose={() => props.setAlertDialog(false)} 
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		  >
		  <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
			<DialogContent>
			  <DialogContentText id="alert-dialog-description">
			  {props.dialogMessage}
			  </DialogContentText>
			</DialogContent>
			<DialogActions>
			  <Button onClick={() => props.setAlertDialog(false)} color="primary" autoFocus>
				OK
			  </Button>
			</DialogActions>			
		  </Dialog>
	  )
	
	
} // end AlertDialog

const mapStateToProps = store => ({
  innerLoading:store.appReducer.innerLoading,
  UserData:store.registerReducer.UserData,
  UserDataObj:store.appReducer.userData,
  categories:store.serviceReducer.categories,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AppActions, ...ServiceActions }, dispatch);

export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(Home);
 
