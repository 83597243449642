import './MainScheduledServices.css';
import React,{useState,useEffect,Fragment} from 'react';
import {Link,useNavigate,useParams} from 'react-router-dom';
import { CSSTransition } from "react-transition-group";
import * as config from '../../Config/api.js';

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

import moment from 'moment';
import 'moment/locale/pt-br';
import add from 'date-fns/add'
import format from 'date-fns/format'


// import { DatePicker } from '@mui/x-date-pickers'

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { ptBR } from '@mui/x-date-pickers/locales';
import { DatePicker } from '@mui/x-date-pickers'

// import { StreamChat } from 'stream-chat';
// import { Chat, Channel, ChannelHeader, MessageInput, MessageList, Thread, Window } from 'stream-chat-react';
// import 'stream-chat-react/dist/css/index.css';

import AnimateHeight from 'react-animate-height';

import { Box,Backdrop,CircularProgress,Paper,Divider,Typography,Button,Avatar,ButtonGroup } from '@mui/material';

import Select from '@mui/material/Select';
import NativeSelect from '@mui/material/NativeSelect';
import MenuItem from '@mui/material/MenuItem';


import brLocale from "date-fns/locale/pt-BR";
import DateFnsUtils from '@date-io/date-fns'; // choose your lib

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import CloseIcon from '@mui/icons-material/Close';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import IconButton from '@mui/material/IconButton';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Fade from '@mui/material/Fade';
import Slide from '@mui/material/Slide';
import Badge from '@mui/material/Badge';


import Info from '@mui/icons-material/ErrorOutline';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AccountTreeIcon from '@mui/icons-material/AccountTree'; 
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ScheduleIcon from '@mui/icons-material/Schedule';
import WarningIcon from '@mui/icons-material/Warning';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import EventIcon from '@mui/icons-material/Event';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ChatIcon from '@mui/icons-material/Chat';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import NotInterestedIcon from '@mui/icons-material/NotInterested';

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';

import {useComponentWillMount} from '../../Utils/customFunctions';
import Header from '../../Components/Layout/Header';
import If from '../../Components/Common/If';
import {ServiceDetailDialog} from './DialogServiceDetail';
import {PaymentDetailDialog} from './DialogPaymentDetail';
import {ExtraDetailDialog} from './DialogExtraDetail';
import {AddressDetailDialog} from './DialogAddressDetail';
import {ImageDetailDialog} from './DialogImageDetail';

import backgroundHexagon from '../../Assets/Images/backgroundHexagon.png'; 
import backgroundHexagonLight from '../../Assets/Images/backgroundHexagonLight.png'; 
import headerCategory1 from '../../Assets/Images/headerSimpleCategory1.png'; 
import priceIconBlue from '../../Assets/Images/priceIconBlue.svg'; 
import scheduleIconBlue from '../../Assets/Images/scheduleIconBlue.svg'; 
import plusIconBlue from '../../Assets/Images/plusIconBlue.svg'; 
import lessIconBlue from '../../Assets/Images/lessIconBlue.svg'; 
import zoomIconBlue from '../../Assets/Images/zoomIconBlue.svg'; 
import chatIconGreen from '../../Assets/Images/iconChatGreen.svg'; 
import calendarIconBlue from '../../Assets/Images/calendarIconBlue.svg'; 
import placeIconBlue from '../../Assets/Images/placeIconBlue.svg'; 
import cardIconBlue from '../../Assets/Images/cardIconBlue.svg'; 
import personIconBlue from '../../Assets/Images/personIconBlue.svg'; 

import visa from '../../Assets/Images/visa.svg'; // Tell Webpack this JS file uses this image
import mastercard from '../../Assets/Images/mastercard.svg'; // Tell Webpack this JS file uses this image
import amex from '../../Assets/Images/amex.svg'; // Tell Webpack this JS file uses this image
import diners from '../../Assets/Images/diners.svg'; // Tell Webpack this JS file uses this image
import elo from '../../Assets/Images/elo.svg'; // Tell Webpack this JS file uses this image
import genericFlag from '../../Assets/Images/genericFlag.svg'; // Tell Webpack this JS file uses this image

import backgroundHeaderImage from '../../Assets/Images/backgroundHeader.png'; // Tell Webpack this JS file uses this image
import logo from '../../Assets/Images/logo_motuum-white.svg'; // Tell Webpack this JS file uses this image
import backIcon from '../../Assets/Images/backIcon.svg'; // Tell Webpack this JS file uses this image

// Load Actions
import * as AppActions from '../../Store/Actions/App'
import * as ServiceActions from '../../Store/Actions/Service'

const ScheduledServicesView = props => {


const screenStyle = {
  alignItems: "stretch",
  justifyContent: "top",
  width:"100vw",
  minHeight:"100vh",
  display:"flex",
  flexDirection:"column",
  backgroundImage: "url(" + backgroundHexagon + ")",
  backgroundSize:"cover",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"center"
};

const backgroundLight = {
  backgroundImage: "url(" + backgroundHexagonLight + ")",
  backgroundSize:"cover",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"center"
};

const backgroundStyle = {
  backgroundImage: "url(" + backgroundHexagon + ")",
  backgroundSize:"cover",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"center"
}; 

const screenStyleLoading = {
  alignItems: "middle",
  justifyContent: "center",
  width:"100vw",
  height:"100vh",
  display: "flex",
  flexDirection: "column"
}; 


const screenStyleBackgroundImage = {
  backgroundSize:"auto",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"center",
  minHeight:"59px",
  width:"100%"
};

// set classes var
// const classes = useStyles()();

// set vars from props
// Remember to add those who come from Redux
const { 
	buttonLoading,
	setButtonLoading,
	SelectedScheduledServiceOrder,
	retrieveServiceOrder,
	setDialog,
	dialog,
	UserData,
	setChatChannelId,
	setChatCustomer,
	setChatProvider		
} = props;

//console.log("[SelectedScheduledServiceOrder]: "+JSON.stringify(SelectedScheduledServiceOrder,null,2));

let { id } = useParams();
const [ServiceOrderId,setServiceOrderId] = useState(id);

// Set internal state
const [contentVisible,setContentState] = useState(false);
const [contentNotFoundVisible,setContentNotFoundVisible] = useState(false);
const [dialogAlert, setAlertDialog] = useState(false); // state for tab
const [dialogMessage, setDialogMessage] = useState(""); // state for tab
const [dialogTitle, setDialogTitle] = useState("Verifique as informações"); // state for tab
const [dialogAction, setDialogAction] = useState(false); // state for tab

const [image,setImage] = useState("");
const [headerTitle, setHeaderTitle] = useState("Serviço Agendado"); // state for tab

//Set content constants
const [serviceId,setServiceId] = useState(null);
const [serviceName,setServiceName] = useState("carregando...");
const [serviceWarranty,setServiceWarranty] = useState("carregando...");
const [serviceDescription,setServiceDescription] = useState("carregando...");

const [serviceScheduledDate,setServiceScheduledDate] = useState("carregando...");
const [serviceScheduledDateNoWeek,setServiceScheduledDateNoWeek] = useState("carregando...");
const [serviceScheduledDateWeek,setServiceScheduledDateWeek] = useState("carregando...");

const [breadcrumb,setBreadcrumb] = useState("carregando...");

const [categoryHeader,setCategoryHeader] = useState("carregando...");

const [serviceScheduledPeriod,setServiceScheduledPeriod] = useState("carregando...");
const [servicePaymentMethod,setServicePaymentMethod] = useState("carregando...");
const [servicePaymentReferenceNumber,setServicePaymentReference] = useState("carregando...");
const [serviceExecutionTime,setServiceExecutionTime] = useState("carregando...");
const [servicePrice,setServicePrice] = useState("carregando...");
const [serviceAddress,setServiceAddress] = useState("carregando...");

const [metaInfo,setMetaInfo] = useState([]);

const [serviceBillingInfo,setServiceBillingInfo] = useState("carregando...");
const [billingName,setServiceBillingName] = useState("carregando...");
const [billingDocument,setServiceBillingDocument] = useState("carregando...");


const [serviceEmployeeName,setServiceEmployeeName] = useState("carregando...");
const [serviceEmployeeDocument,setServiceEmployeeDocument] = useState("carregando...");
const [serviceEmployeeDocumentAlt,setServiceEmployeeDocumentAlt] = useState("carregando...");
const [serviceEmployeeDocumentAltFlag,setServiceEmployeeDocumentAltFlag] = useState(false);
const [serviceCompanyName,setServiceCompanyName] = useState("carregando...");

const [extraInfoflag,setExtraInfoFlag] = useState(false);
const [serviceStatus, setServiceStatus] = useState(0);
const [cancelDate, setCancelDate] = useState("");
const [cardFlag, setCardFlag] = useState(""); // state for tab

const [serviceCompanyAvatar,setServiceCompanyAvatar] = useState(config.API_URL+"/provider/avatar/retrieve/null/null");

const [heightPanel,setHeightPanel] = useState(0);

const periodArray = [];
periodArray[1] = "09:00 às 13:00";
periodArray[2] = "13:00 às 18:00";
periodArray[3] = "18:00 às 21:00";

let navigate = useNavigate();


// Run after mount component
useEffect(() => {
		
	// Set Backdrop Loading over interface
	setButtonLoading(true); 

	// Retrieve Scheduled Services
	var data = {
		serviceOrderId:ServiceOrderId,
		status:[0,99]
	}
	var props = {
		navigate:navigate,
		setAlert:setAlertDialog,
		setDialogMessage:setDialogMessage,
		setDialogTitle:setDialogTitle,
		setContentState:setContentState,
		setContentNotFoundVisible:setContentNotFoundVisible
	}	
	retrieveServiceOrder(data,props);
	
  // set scroll to top
  window.scrollTo(0, 1);
    
  // Runs when UNMOUNT component
  return () => {
    console.log("Desmontou o componente MainHome...");
  };
  
}, []); // end useEffect


// Run after mount component
useEffect(() => {
		
		// Retrieve User Data Obj. 
		var result = localStorage.getItem("@motuum/userData", undefined,true);
		var userData = JSON.parse(result);  

		// Set Stream chat client and connect user
		/*const clientValue = StreamChat.getInstance('8gw887km58c5')*/;
		const userToken = userData.userChatToken;
	
		/*const userToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoic2hpbnktc291bmQtNiJ9.734-Xyoqzd_r-KvVg0jEA8cvZ4uUOovjMxjIL9KNyiA";*/
		
		var user = { id: 'CustomerUserId'+UserData.id, name:UserData.CustomerProfile.completeName, image: 'https://getstream.io/random_png/?id=CustomerUserId'+UserData.CustomerUserId+'&name='+UserData.CustomerProfile.completeName+''  };

		/*clientValue.connectUser(
		 user,
		  userToken,
		);
		setClient(clientValue);*/
  
}, []); // end useEffect

const zoomImage = (image) => {
	
	setImage(image);
	setDialog('imageDetail',true);
	
}

// Run if object [SelectedScheduledServiceOrder] change
useEffect(() => {

try {
	
// Set service details
setServiceId(SelectedScheduledServiceOrder.ServiceOrderService.ServiceId);
setServiceName(SelectedScheduledServiceOrder.ServiceOrderService.name);
setServiceDescription(SelectedScheduledServiceOrder.ServiceOrderService.description);
setServiceWarranty(SelectedScheduledServiceOrder.ServiceOrderService.warrantyTerm);

setBreadcrumb(SelectedScheduledServiceOrder.ServiceOrderService.Service.ServiceCategories[0].breadcrumb);
setCategoryHeader(SelectedScheduledServiceOrder.ServiceOrderService.Service.ServiceCategories[0].headerImageSize3);

// set payment reference number
setServicePaymentReference(SelectedScheduledServiceOrder.ServiceOrderCreditCard.referenceNumber);

// Set Meta Info
setMetaInfo(SelectedScheduledServiceOrder.ServiceOrderMetaInformations);


if (SelectedScheduledServiceOrder.type == 1) {
	var periodLabel = periodArray[SelectedScheduledServiceOrder.scheduledPeriod];	
} // end if
else if (SelectedScheduledServiceOrder.type == 2) {
	var periodLabel = "HOJE das "+periodArray[SelectedScheduledServiceOrder.scheduledPeriod];	
} // end if
else {
	var periodLabel = "URGENTE - em até 2 horas";	
} // end if
setServiceScheduledPeriod(periodLabel);

// Set dates
var formattedDate = moment(SelectedScheduledServiceOrder.scheduledDate).locale('pt-br').format("DD/MM/YYYY (dddd)");
setServiceScheduledDate(formattedDate);

var serviceScheduledDateNoWeekTmp = moment(SelectedScheduledServiceOrder.scheduledDate).locale('pt-br').format("DD/MM/YYYY");
setServiceScheduledDateNoWeek(serviceScheduledDateNoWeekTmp);

var  serviceScheduledDateWeekTmp = moment(SelectedScheduledServiceOrder.scheduledDate).locale('pt-br').format("(dddd)");
setServiceScheduledDateWeek(serviceScheduledDateWeekTmp);


var paymentMethodLabel = SelectedScheduledServiceOrder.paymentMethod == 1 ? "Cartão de Crédito": "Transferência via PIX";
setServicePaymentMethod(paymentMethodLabel);

var timeLabel = SelectedScheduledServiceOrder.ServiceOrderService.effort +" "+SelectedScheduledServiceOrder.ServiceOrderService.effortUnity;
setServiceExecutionTime(timeLabel);

var priceLabel = "R$ "+(Math.round(SelectedScheduledServiceOrder.ServiceTotalPrice * 100) / 100).toFixed(2);
setServicePrice(priceLabel);

// set status
setServiceStatus(SelectedScheduledServiceOrder.status);
setCancelDate(moment(SelectedScheduledServiceOrder.cancelledAt).format("DD/MM/YYYY HH:mm"));

if (SelectedScheduledServiceOrder.status == 99) {
	setHeaderTitle("Serviço cancelado");
} // end if
else {
	setHeaderTitle("Serviço agendado");
}

// Set address and billing info
setServiceAddress(SelectedScheduledServiceOrder.ServiceOrderAddress.fullAddress);
var BillinInfoType = SelectedScheduledServiceOrder.ServiceOrderBilling.type == 1 ? "<strong>Pessoa Física</strong>": "<strong>Pessoa Jurídica</strong>";


if (SelectedScheduledServiceOrder.ServiceOrderMetaInformations.length > 0) {
	setExtraInfoFlag(true);
} // end if


// var BillingInfoLabel = BillinInfoType+" <br /> "+SelectedScheduledServiceOrder.ServiceOrderBilling.billingName +" <br /> "+SelectedScheduledServiceOrder.ServiceOrderBilling.fullAddress;
var BillingInfoLabel = SelectedScheduledServiceOrder.ServiceOrderBilling.billingName +" <br /> "+SelectedScheduledServiceOrder.ServiceOrderBilling.billingDocument;
setServiceBillingInfo(BillingInfoLabel);

var billingNameTmp = SelectedScheduledServiceOrder.ServiceOrderBilling.billingName;
setServiceBillingName(billingNameTmp);

var billingDocumentTmp = SelectedScheduledServiceOrder.ServiceOrderBilling.billingDocument;
setServiceBillingDocument(billingDocumentTmp);


// Set Provider Data
setServiceEmployeeName(SelectedScheduledServiceOrder.ProviderUser.ProviderProfile.ProviderProfilePerson.ProviderProfileCompanyEmployee.completeName);
setServiceEmployeeDocument(SelectedScheduledServiceOrder.ProviderUser.ProviderProfile.ProviderProfilePerson.ProviderProfileCompanyEmployee.document);  
setServiceEmployeeDocumentAlt(SelectedScheduledServiceOrder.ProviderUser.ProviderProfile.ProviderProfilePerson.ProviderProfileCompanyEmployee.documentAlt+" ("+SelectedScheduledServiceOrder.ProviderUser.ProviderProfile.ProviderProfilePerson.ProviderProfileCompanyEmployee.documentAltInfo+")");
setServiceEmployeeDocumentAltFlag(SelectedScheduledServiceOrder.ProviderUser.ProviderProfile.ProviderProfilePerson.ProviderProfileCompanyEmployee.documentAlt);
setServiceCompanyName(SelectedScheduledServiceOrder.ProviderUser.ProviderProfile.ProviderProfileCompany.realName);
var avatarImageUrl = config.API_URL+"/provider/avatar/retrieve/"+SelectedScheduledServiceOrder.ProviderUser.id+"/"+SelectedScheduledServiceOrder.ProviderUser.ProviderProfile.avatar+"";
setServiceCompanyAvatar(avatarImageUrl);

// PAYMENT Info	
	if (SelectedScheduledServiceOrder.ServiceOrderCreditCard.cardFlag == "visa") {
		setCardFlag(visa);
	} // end if
	else if  (SelectedScheduledServiceOrder.ServiceOrderCreditCard.cardFlag == "mastercard") {
		setCardFlag(mastercard);
	} // end else if
	else if  (SelectedScheduledServiceOrder.ServiceOrderCreditCard.cardFlag == "amex") {
		setCardFlag(amex);
	} // end else if	
	else if  (SelectedScheduledServiceOrder.ServiceOrderCreditCard.cardFlag == "diners") {
		setCardFlag(diners);
	} // end else if	
	else if  (SelectedScheduledServiceOrder.ServiceOrderCreditCard.cardFlag == "elo") {
		setCardFlag(elo);
	} // end else if	
	else if  (SelectedScheduledServiceOrder.ServiceOrderCreditCard.cardFlag == "generic") {
		setCardFlag(genericFlag);
	} // end else if	


} catch(e) {}

  
}, [SelectedScheduledServiceOrder]); // end useEffect

// useEffect only for history changes
useEffect(() => {	
	setExtraInfoFlag(false);
}, [history]); // end useEffect only for dialgo changes

// Will Mount Component HOOK
useComponentWillMount(() => {
	
    // Set session of app
  var sessionCheck = sessionStorage.getItem('@motuum/appLaunched');
  
  // if session is not created, redirect to bootstrap. (avoid direct access using url)
  if (!sessionCheck) {
	  navigate("/");
  } // end if	
  
	// Retrieve User Data Obj. 
	var result = localStorage.getItem("@motuum/userData", undefined,true);
	var userData = JSON.parse(result);  
  
	  // If token dont exist, redirect to onboarding or register
	  if (!userData) {	  
		navigate("/");  
	  } // end if
	  // If token exist, redirect to HOME
	  else {
		  if (!userData.userToken) {
			navigate("/");
		  } // end if
	  } // end else     
      
}, []); // end useComponentWillMount

const openChat = () => {

	// Set Chat itens
	var CustomerUserName = "CustomerUserId"+SelectedScheduledServiceOrder.CustomerUserId;
	var ProviderUserName = "ProviderUserId"+SelectedScheduledServiceOrder.ProviderUser.id;
	var ChannelId = "ServiceOrderId"+ServiceOrderId;

	console.log('<<<<<<<<<<<<<< ChannelId >>>>>>>>>>>>>>>'+JSON.stringify(ChannelId,null,2));
	
	setChatChannelId(ChannelId);
	setChatCustomer(CustomerUserName);
	setChatProvider(ProviderUserName);	
	 
	// Open chat
	setDialog('openChat',true)
	
} // end open chat

	return (
	<Fragment>
			
			<Header title={headerTitle} buttons={{support:false,menu:false,close:false,back:true,rightFake:true}}  />
			
			{/* Loading */}
			{ buttonLoading &&
					<Box className="loadingContent" style={{...screenStyleLoading}}>
							<Box mt={0} mx={0}>								
								<CircularProgress /> 
								<div> Carregando...Aguarde!</div>								
							</Box>	
					</Box>
			}			
			
			<Fade in={contentVisible} timeout={1000}  mountOnEnter unmountOnExit>			
			<div>
				<Box className="mainSearchServicesView" style={{...screenStyle}} mt={0}>
							
					{/* Category Block */}
					<Box mt={10} mb={0} style={{paddingLeft:"10px",paddingRight:"10px"}} >
					
						<div style={{color:"#636466",fontFamily:"Roboto",fontSize:"16px",fontWeight:"normal",margin:"20px",textAlign:"left"}} onClick={()=>{ navigate("/searchService") } }> 
							{breadcrumb} 
						</div>
						
					</Box>	
					
					<Box style={{paddingLeft:"30px",paddingRight:"30px",marginBottom:"0px"}}> 

						<Card style={{minWidth: "90%",marginBottom:"20px",minHeight:"154px",width:"100%",marginBottom:"10px",backgroundColor:"#ffffff",boxShadow: "0px 10px 20px 0px #0000002A",borderRadius:"10px"}}>
						
						  <CardContent style={{padding:"25px",paddingBottom:"0px"}}>
							
							<Box >
								
								<Box style={{padding:"0px"}}>
									
										<div style={{textAlign:"left"}}>
											<h3 style={{color:"#003CFA",fontFamily:"Roboto",fontWeight:"bold",fontSize:"16px",marginTop:"0px",marginBottom:"5px",lineHeight:"18px"}}>{serviceName}</h3>
											<div style={{minHeight:"54px",color:"#636466",fontFamily:"Roboto",fontWeight:"normal",fontSize:"12px",lineHeight:"18px"}}>
												{serviceDescription}
											</div>
										</div>											
									
								</Box>	
										
								<AnimateHeight
								  duration={ 500 }
								  height={ heightPanel }
								>								
									<Box style={{padding:"0px",marginTop:"15px"}}>
										
											<div style={{textAlign:"left"}}>
												<h3 style={{color:"#003CFA",fontFamily:"Roboto",fontWeight:"bold",fontSize:"16px",marginTop:"0px",marginBottom:"5px",lineHeight:"18px"}}>Garantia do Serviço</h3>
												<div style={{color:"#636466",fontFamily:"Roboto",fontWeight:"normal",fontSize:"12px",lineHeight:"18px"}}>
													{serviceWarranty}
												</div>
											</div>											
										
									</Box>									
								</AnimateHeight>
								
							
							</Box>
							
						  </CardContent>
							
							<CardActions style={{display:"flex",flexDirection:"row",backgroundColor:"#003CFA10",padding:"2px",marginTop:"19px"}}>
									  
							  <div style={{flex:1,textAlign:"center"}}>
									
									{heightPanel == 0 &&
									<Button style={{color:"#003CFA",fontFamily:"Roboto",fontWeight:"normal",fontSize:"12px"}} size="small" onClick={()=>setHeightPanel(100)}>										
										<img src={plusIconBlue} style={{width:"18px",height:"18px",marginRight:"5px"}} />
										<div>Ver mais</div>										
									</Button>	
									}									
									
									{heightPanel > 0 &&
									<Button style={{color:"#003CFA",fontFamily:"Roboto",fontWeight:"normal",fontSize:"12px"}} size="small" onClick={()=>setHeightPanel(0)}>										
										<img src={lessIconBlue} style={{width:"18px",height:"18px",marginRight:"5px"}} />
										<div>Ver menos</div>										
									</Button>
									}
									
								</div>
								
							  </CardActions>										

						</Card>	
						

					</Box>	

					<Box style={{paddingLeft:"30px",paddingRight:"30px",marginBottom:"10px"}}> 
									
						
						<Card style={{minHeight:"160px",minWidth: "90%",marginBottom:"20px",width:"100%",marginBottom:"20px",backgroundColor:"#ffffff",boxShadow: "0px 10px 20px 0px #0000002A",borderRadius:"10px",position:"relative"}}>
									
							<CardContent style={{padding:"10px",paddingBottom:"0px"}}>
								
								<Box style={{display:"flex",flexdirection:"row",alignItems:"center",justifyContent:"space-between",marginTop:"10px",marginLeft:"15px",marginRight:"15px"}}>
									
									<Box style={{float:"left",textAlign:"left"}}>										
										<h3 style={{color:"#003CFA",fontFamily:"Roboto",fontWeight:"bold",fontSize:"16px",marginTop:"0px",marginBottom:"2px",lineHeight:"18px"}}>{serviceEmployeeName}</h3>
										<div style={{color:"#636466",fontFamily:"Roboto",fontWeight:"bold",fontSize:"12px",lineHeight:"18px"}}>
										{serviceCompanyName}
										</div>	
									</Box>
									
									<div style={{textAlign:"right",float:"right"}}>
											<Avatar src={serviceCompanyAvatar} variant="square" style={{width:"50px",height:"50px",borderRadius:"50px"}} />
									</div>														
								</Box>	
							
								<Box style={{textAlign:"left",padding:"0px",marginTop:"0px",color:"#636466",fontFamily:"Roboto",fontWeight:"regular",fontSize:"12px",marginLeft:"15px",marginRight:"15px"}}>
																						
										{serviceEmployeeDocumentAltFlag &&
										<>
										RG: {serviceEmployeeDocumentAlt} <br />
										</>
										}
										CPF: {serviceEmployeeDocument}
											
								</Box>									
								
							  </CardContent>
						
						<CardActions style={{backgroundColor:"#1EC30010",padding:"2px", position:"absolute",bottom:"0",width:"100%"}}>
								  
						  <div style={{flex:1,textAlign:"center"}}>
								<Button style={{color:"#1EC300",fontFamily:"Roboto",fontWeight:"normal",fontSize:"12px"}} size="small" onClick={()=> openChat()}>										
									<img src={chatIconGreen} style={{width:"20px",height:"20px",marginRight:"5px"}} />
									<div>ABRIR CHAT COM PROFISSIONAL</div>										
								</Button>
							</div>
							
						 </CardActions>										

					</Card>

					</Box>	

	
					<Divider light style={{marginLeft:"30px",marginRight:"30px"}} />

					<Box pt={0} pb={2} mt={0} mb={0} style={{...backgroundLight,alignItems:"stretch",justifyContent:"center",display:"flex",flexDirection:"column",paddingLeft:"30px",paddingRight:"30px"}}>
							
							
							{/* Price Box */}	
							<Box mt={1} ml={1} mr={1} mb={1} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>
							
								<Box style={{flex:2}}>
								
									<Box style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>    
										<div style={{marginRight:"5px"}}> 
											<img src={priceIconBlue} style={{width:"30px",height:"30px"}} />
										</div>
										
										<div> 
											<div style={{fontFamily:"Roboto",fontWeight:"regular",fontSize:"12px",color:"#636466",textTransform:"uppercase"}}>Valor do serviço</div>
										</div>
									</Box>									
								
								</Box>
								
								<Box style={{flex:1}}>								
										<Box style={{fontFamily:"Roboto",fontWeight:"bold",fontSize:"16px",padding:"2px",color:"#003CFA",textAlign:"right"}}>
											{servicePrice}
										</Box>								
								</Box>								
							
							</Box>							
						
							<Divider light />

							{/* Time Box */}
							<Box mt={1} ml={1} mr={1} mb={1} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>
							
								<Box style={{flex:1}}>
								
									<Box style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>    
										<div style={{marginRight:"5px"}}> 
											<img src={calendarIconBlue} style={{width:"30px",height:"30px"}} />
										</div>
										
										<div> 
											<div style={{fontFamily:"Roboto",fontWeight:"regular",fontSize:"12px",color:"#636466",textTransform:"uppercase"}}>DIA E HORÁRIO</div>
										</div>
									</Box>									
								
								</Box>
								
								<Box style={{flex:1.5}}>								
										<Box style={{fontFamily:"Roboto",fontWeight:"bold",fontSize:"16px",padding:"2px",color:"#003CFA",textAlign:"right"}}>
											{serviceScheduledDateNoWeek} <small>{serviceScheduledDateWeek}</small> <br />
											<span style={{fontWeight:"normal"}}>{serviceScheduledPeriod}</span>
										</Box>								
								</Box>								
							
							</Box>
							
							<Divider light />

							{/* Address Box */}
							<Box mt={1} ml={1} mr={1} mb={1} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>
							
								<Box style={{flex:1}}>
								
									<Box style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>    
										<div style={{marginRight:"5px"}}> 
											<img src={placeIconBlue} style={{width:"30px",height:"30px"}} />
										</div>
										
										<div> 
											<div style={{fontFamily:"Roboto",fontWeight:"regular",fontSize:"12px",color:"#636466",textTransform:"uppercase"}}>ENDEREÇO</div>
										</div>
									</Box>									
								
								</Box>
								
								<Box style={{flex:1.5}}>								
										<Box style={{fontFamily:"Roboto",fontWeight:"bold",fontSize:"16px",padding:"2px",color:"#003CFA",textAlign:"right"}}>
											{serviceAddress}
										</Box>								
								</Box>								
							
							</Box>
							
							<Divider light />
							

							{/* Payment Box */}
							<Box mt={1} ml={1} mr={1} mb={1} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>
							
								<Box style={{flex:1}}>
								
									<Box style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>    
										<div style={{marginRight:"5px"}}> 
											<img src={cardIconBlue} style={{width:"30px",height:"30px"}} />
										</div>
										
										<div> 
											<div style={{fontFamily:"Roboto",fontWeight:"regular",fontSize:"12px",color:"#636466",textTransform:"uppercase"}}>PAGAMENTO</div>
										</div>
									</Box>									
								
								</Box>
								
								<Box style={{flex:1.5}}>								
										<Box style={{fontFamily:"Roboto",fontWeight:"bold",fontSize:"16px",padding:"2px",color:"#003CFA",textAlign:"right",display:"flex",alignItems:"flex-end",flexDirection:"column",justifyContent:"center"}}>
											{servicePaymentMethod}<br />										
											{servicePaymentReferenceNumber}
											<img src={cardFlag} style={{marginLeft:"10px",marginRight:"0px",width:"30px"}} />
										</Box>								
								</Box>								
							
							</Box>
							
							<Divider light />	

							{/* Invoice Box */}
							<Box mt={1} ml={1} mr={1} mb={1} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>
							
								<Box style={{flex:1}}>
								
									<Box style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>    
										<div style={{marginRight:"5px"}}> 
											<img src={personIconBlue} style={{width:"30px",height:"30px"}} />
										</div>
										
										<div> 
											<div style={{fontFamily:"Roboto",fontWeight:"regular",fontSize:"12px",color:"#636466",textTransform:"uppercase"}}>FATURAMENTO</div>
										</div>
									</Box>									
								
								</Box>
								
								<Box style={{flex:1.5}}>								
										<Box style={{fontFamily:"Roboto",fontWeight:"bold",fontSize:"16px",padding:"2px",color:"#003CFA",textAlign:"right",display:"flex",alignItems:"flex-end",flexDirection:"column",justifyContent:"center"}}>
											{billingName}<br />
											<span style={{fontWeight:"normal"}}>{billingDocument}</span>
																						
										</Box>								
								</Box>								
							
							</Box>
							
							<Divider light />							
									
							{/* Category Block */}
							<Box mt={1} mb={0} style={{paddingLeft:"0px",paddingRight:"0px"}} >
							
							{metaInfo.length > 0 &&
								<div style={{color:"#003CFA",fontFamily:"Roboto",fontSize:"18px",fontWeight:"bold",margin:"10px",textAlign:"left"}}> 
									Informações Extras
								</div>
							}
								
									{metaInfo.map( (item) => {
									
									if (item.fieldId == 2) {
										var itemValue = JSON.parse(item.fieldValue); 
									} // end if
									else {
										var itemValue = item.fieldValue;
									} // end else
									
									return (
										<Box ml={1} mr={1} mb={2} style={{alignItems:"stretch",justifyContent:"flex-start",display:"flex",flexDirection:"column"}} key={item.id}>
											
											<Box style={{flex:1}}>								
													<Box>
														
														{Array.isArray(itemValue) &&
														<Box style={{marginTop:"0px", display: 'flex',flexWrap: 'wrap', justifyContent: 'flex-start',overflow: 'hidden',}}>													
														<ImageList style={{flex:1,display:"flex",padding:"0",flexWrap:"nowrap",listStyle:"none",overflowY:"auto"}} cols={2.5}>									
															{itemValue.map((item, index) => {	
														
																var extraImage = config.API_URL+"/service/extrainfoimage/"+SelectedScheduledServiceOrder.CustomerUserId+"/"+item.filename+"";
																
																return (
																	<ImageListItem key={item.id} style={{padding:"15px"}}>
																	<img src={extraImage} onClick={()=> zoomImage(extraImage) } />
																	<ImageListItemBar
																	  style={{background:"transparent",marginLeft: "10px",flex: 1}}
																	  actionIcon={
																		<IconButton>
																		  <ZoomInIcon style={{fontSize:"25px",color:"#FFF",marginLeft: "10px",flex: 1}} />
																		</IconButton>
																	  }
																	/>
																  </ImageListItem>																
																)
																
																} 
																		
															)}	
														 </ImageList>														
														</Box>	
														}
														
														{!Array.isArray(itemValue) &&
															<div style={{marginBottom:"0px",textAlign:"left",color:"#636466",fontFamily:"Roboto",fontWeight:"regular",fontSize:"16px"}}> {itemValue} </div>
														}														
														
													</Box>								
											</Box>								
										
										</Box>
									)
									
									})
									
									
									}								
								
							</Box>								
							
							<Box mt={1} mb={21}>	
											
								{serviceStatus != 99 &&
								<>
								<Box style={{...backgroundStyle,width:"100%",position:"relative",bottom:"0px"}} p={1}>								
									
									<Button color="primary" variant="outlined" style={{width:"100%",height:"53px",borderRadius:"10px",marginBottom:"20px"}} onClick={()=>setDialog('cancelservice',true)}>
									 Cancelar serviço
									</Button>		
												
									<Button color="secondary" variant="outlined" style={{width:"100%",height:"53px",borderRadius:"10px"}} onClick={()=>setDialog('updateservice',true)}>
									  Alterar data
									</Button>	
										
								</Box>
								</>
								}

								{serviceStatus == 99 &&
								<>
								<Box style={{backgroundColor:"#AA0000",padding:"20px",color:"#fff",width:"100%",height:"81px",position:"fixed",left:"0px",bottom:"0px",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",zIndex:"99"}} p={1}>								

									<Button color="primary" variant="contained" style={{flex:1.5,marginRight:"10px",backgroundColor:"#555"}} onClick={()=>navigate("/scheduledServices")}>
									 Voltar
									</Button>									
									
									<div> Serviço cancelado em {cancelDate} </div>	
										
								</Box>
								</>
								}
								
							</Box>								
											
					</Box>
					
					<Box style={{backgroundColor:"transparent",display:"flex",width:"100%",alignItems:"center",position:"fixed",bottom:0,height:"80px",backgroundColor:"#ffffff",boxShadow: "0px -10px 15px 0px #0000001A"}}>		
							
						<Box style={{flex:1,paddingRight:"50px",paddingLeft:"50px"}}>	
						
							<Button color="primary" variant="contained" style={{width:"100%",height:"53px"}} onClick={()=>navigate("/scheduledServices")}> OK </Button>	
				
						</Box>	

								
					</Box>						
								
				</Box>
				
			<AlertDialog 
				dialogAlert={dialogAlert}
				setAlertDialog={setAlertDialog}
				dialogTitle={dialogTitle}
				dialogMessage={dialogMessage}
				dialogAction={dialogAction}
				history={navigate}
			/>		

			<CancelDialog 
				dialogAlert={dialogAlert}
				setAlertDialog={setAlertDialog}
				dialogTitle={dialogTitle}
				dialogMessage={dialogMessage}
				setDialogTitle={setDialogTitle}
				setDialogMessage={setDialogMessage}
				setDialogAction={setDialogAction}
				ServiceOrderId={ServiceOrderId}
				history={navigate}
			/>

			<ConfirmDialog 
				dialog={dialog}
				setDialog={setDialog}
				dialogTitle={dialogTitle}
				dialogMessage={dialogMessage}
				setDialogTitle={setDialogTitle}
				setDialogMessage={setDialogMessage}
			/>

			<UpdateDialog 
				dialogAlert={dialogAlert}
				setAlertDialog={setAlertDialog}
				dialogTitle={dialogTitle}
				dialogMessage={dialogMessage}
				setDialogTitle={setDialogTitle}
				setDialogMessage={setDialogMessage}
				setDialogAction={setDialogAction}
				ServiceOrderId={ServiceOrderId}
				history={navigate}
			/>			

			<ServiceDetailDialog 
				SelectedScheduledServiceOrder={SelectedScheduledServiceOrder}
			/>
			
			<PaymentDetailDialog 
				SelectedScheduledServiceOrder={SelectedScheduledServiceOrder}
			/>			
			
			<ExtraDetailDialog 
				SelectedScheduledServiceOrder={SelectedScheduledServiceOrder}
				setImage={setImage}
			/>		
			
			<AddressDetailDialog 
				SelectedScheduledServiceOrder={SelectedScheduledServiceOrder}
			/>		

			<ImageDetailDialog image={image} />
			
			</div>			
			</Fade>    
			
			
			{/* If there is no result */}
			{contentNotFoundVisible &&
				<div style={{height:"100vh",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center"}}>
				
					<div style={{flex:1.5,display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"flex-end",marginBottom:10}}>
					  <NotInterestedIcon style={{fontSize:"7rem",color: "#DDD"}} />
					</div>
					
					<div style={{flex:1.5,display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"flex-start"}}>
						<div style={{fontSize:20,color:"silver",textAlign:"center"}}>Nenhum serviço encontrado</div>
					</div>													
				
				</div>				
			}
	
				
	</Fragment>
)};

const mapStateToProps = store => ({
  dialog:store.appReducer.dialog,
  CustomerCancelTax:store.appReducer.CustomerCancelTax,
  CustomerAlterTax:store.appReducer.CustomerAlterTax,
  buttonLoading:store.serviceReducer.buttonLoading,  
  SelectedScheduledServiceOrder:store.serviceReducer.SelectedScheduledServiceOrder,
  UserData:store.appReducer.userData
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AppActions, ...ServiceActions }, dispatch);

// Transition Slide for Dialog SLIDE UP	
const TransitionSlideUp = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;  
});	
	

	
/* Cancel Dialog Component START */
export const CancelDialog = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {


const screenStyleDialog = {
  alignItems: "stretch",
  justifyContent: "top",
  width:"100vw",
  minHeight:"100vh",
  display:"flex",
  flexDirection:"column",
  backgroundColor:"#FFF"
}; 
	
// set classes var
// const classes = useStyles()();	

// set vars from props
// Remember to add those who come from Redux
const { 
	innerLoading,
	setInnerLoading,
	setDialog,
	dialog,
	cancelScheduledService,
	SelectedScheduledServiceOrder,
	ServiceOrderId,
	CustomerCancelTax
} = props;	


/* Dialog END*/	

const [acceptTerms, setAcceptTerms] = useState(false); // state for tab
const [acceptCharge, handleAcceptCharge] = useState(false); // state for tab
const [cancelReason, setCancelReason] = useState("-");
const [cancelTax, setCancelTax] = useState(false);

const [loadingCancel, setLoadingCancel] = useState(false);

// Set functions
const handleAcceptTerms = (event) => {
	const newValue = event.target.checked
	setAcceptTerms(newValue);	
};	

const confirmCancel = (props) => {

	// check of terms of urgency was accepted
	if (!cancelReason || cancelReason == '-') {			
		props.setDialogMessage("Você precisa escolher o motivo do cancelamento.");
		props.setAlertDialog(true);
		return;			
	} // end if	
	
	// check of terms of urgency was accepted
	if (!acceptCharge) {			
		props.setDialogMessage("Você precisa estar de acordo com o cancelamento para prosseguir.");
		props.setAlertDialog(true);
		return;			
	} // end if		

	// Set Loading
	setLoadingCancel(true);

	// Set Service Schedule
	let data = {
		ServiceOrderId:props.ServiceOrderId,
		cancelReason:cancelReason,
		cancelTax:cancelTax
	}
	var propsParam = {
		history:props.history,
		setAlert:props.setAlertDialog,
		setDialogMessage:props.setDialogMessage,
		setDialogTitle:props.setDialogTitle,
		setLoadingCancel:setLoadingCancel,
		setDialogAction:props.setDialogAction,
		setDialog:setDialog
	}	
	// Cancel method
	cancelScheduledService(data,propsParam);

};	

var formatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});


// useEffect only for dialgo changes
useEffect(() => {
		
	// execute only if this modal
	if (dialog.target == 'cancelservice' && dialog.status) {
		
		var today = moment().format("YYYY-MM-DD HH:mm:ss");
		var morningDate = moment(SelectedScheduledServiceOrder.scheduledDate).format("YYYY-MM-DD 09:00:00");
		var eveningDate = moment(SelectedScheduledServiceOrder.scheduledDate).format("YYYY-MM-DD 13:00:00");
		var nightDate = moment(SelectedScheduledServiceOrder.scheduledDate).format("YYYY-MM-DD 18:00:00");
		
		var startDate = moment(today);
		var endDate = moment(morningDate);
		
		if (SelectedScheduledServiceOrder.scheduledPeriod == 1 && SelectedScheduledServiceOrder.type != 3) {
			var endDate = moment(morningDate);	
		} // end if
		else if (SelectedScheduledServiceOrder.scheduledPeriod == 2 && SelectedScheduledServiceOrder.type != 3) {
			var endDate = moment(eveningDate);	
		} // end if
		else if (SelectedScheduledServiceOrder.scheduledPeriod == 3 && SelectedScheduledServiceOrder.type != 3) {
			var endDate = moment(nightDate);
		} // end if
		
		var duration = moment.duration(endDate.diff(startDate));
		var minutes = parseInt(duration.asMinutes());

		// Set cancel Tax on
		if (minutes <= 90) {
			setCancelTax(true);
		} // end if	
		
	}  // end if

}, [dialog]); // end useEffect only for dialgo changes

	return (
	<div>
		<LocalizationProvider utils={DateFnsUtils} locale={brLocale}>
		  <Dialog fullScreen open={dialog.target == 'cancelservice' && dialog.status} onClose={()=>setDialog('cancelservice',false)} TransitionComponent={TransitionSlideUp}>
			<AppBar style={{position: 'fixed'}}>
			  <Toolbar>
				<IconButton edge="start" color="inherit" onClick={() => setDialog('cancelservice',false)} aria-label="close">
				  <CloseIcon />
				</IconButton>
				<Typography variant="h6" style={{marginLeft: "10px",flex: 1}}>
				  Cancelando Serviço agendado
				</Typography>
			 </Toolbar>
			</AppBar>

			<Box style={{...screenStyleDialog}} m={0}>
			
				<Box mt={7} pl={3} pr={3}>
					
					<Box style={{marginBottom:"5px",alignItems:"stretch",justifyContent:"flex-start",display:"flex",flexDirection:"column"}} p={2}>

								<Box mt={1}  mb={1} ml={2} mr={2} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>  
										<div style={{marginRight:"10px"}}> 
											<WarningIcon style={{fontSize:"3rem",color:"red"}} />
										</div>
										
										<div> 
											<div style={{marginBottom:"10px",textAlign:"left"}}> Atenção, o cancelamento do serviço agendado é um processo irreversível. Você precisa mesmo cancelar?  </div>
										</div>
								</Box>									
							
					</Box>
					
					<Divider style={{marginBottom:"5px"}} />
					
					{/* URGENT Date */}						
					<Fade in={true} timeout={{ enter: 1000, exit: 0 }} mountOnEnter unmountOnExit>			
							
						<div>

							<Box mt={2}  mb={0} ml={2} mr={2} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>  
									<div> 
										<div style={{marginBottom:"10px",textAlign:"center",fontWeight:"bold"}}> Selecione o motivo do cancelamento </div>
									</div>
							</Box>	
							
							<Box mt={0}  mb={2} ml={2} mr={2} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>  
									<div style={{marginRight:"10px",width:"90%"}}> 

											<Select
											  label="Motivo do cancelamento"
											  placeholder="Motivo do cancelamento"
											  style={{ width:"100%",paddingTop:0,paddingBottom:0,margin:5,marginLeft:10,marginRight:0,backgroundColor:"#fff",width:"100%"}}
											  size="small"
											  variant="outlined"
											  value={cancelReason}
											  onChange={(event) => {
												  setCancelReason(event.target.value)
											  }}	
											 >
												<MenuItem value="-">- Selecione um motivo -</MenuItem>
												<MenuItem value="Cometi um erro nos dados">Cometi um erro nos dados</MenuItem>
												<MenuItem value="Mudei de ideia">Mudei de ideia</MenuItem>
												<MenuItem value="Agendei sem querer">Agendei sem querer</MenuItem>
												<MenuItem value="Fiz apenas um teste">Fiz apenas um teste</MenuItem>
												<MenuItem value="Não gostei do profissional">Não gostei do profissional</MenuItem>
												<MenuItem value="Tive um imprevisto">Tive um imprevisto</MenuItem>
												<MenuItem value="Profissional não tinha disponibilidade">Profissional não tinha disponibilidade</MenuItem>
												<MenuItem value="Outro motivo não listado">Outro motivo não listado</MenuItem>
											</Select>
											
									</div>
	
							</Box>								
							
							{cancelTax &&
							<>
							<Divider style={{marginBottom:"5px"}} />							

							<Box mt={2} mb={2} ml={3} mr={3} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>  
									<div style={{marginRight:"10px"}}> 
										<img src={priceIconBlue} style={{width:"50px",height:"50px"}} />
									</div>
									
									<div> 
										<div style={{fontSize:"0.85rem",color:"#555555",textAlign:"left"}}>
										<strong> Taxa de cancelamento </strong><br />
										Será cobrada uma taxa de {formatter.format(CustomerCancelTax)} referente a taxa de cancelamento após tempo limite (1h e 30min antes do serviço). 
										</div>
									</div>
							</Box>
							</>
							}
							
							<Divider style={{marginBottom:"5px"}} />							
							
							<Box mt={2} mb={2} ml={1} mr={2} style={{alignItems:"stretch",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>  

								<div style={{alignItems:"center",justifyContent:"center",display:"flex",flexDirection:"row"}}>
								
									  <div>
										  <Checkbox												
											checked={acceptCharge}
											onChange={(status)=>handleAcceptCharge(!acceptCharge)}
											inputProps={{ 'aria-label': 'primary checkbox' }}
										  />
									  </div>
									  
									   <span>
										Estou de acordo com o cancelamento do serviço
									  </span>
								
								</div>	 
								
							</Box>							
							

						</div>
						
					</Fade>					
			
					<Divider style={{marginBottom:"5px"}} />
						
					<Box style={{display:"flex",flexDirection:"row",justifyContent:"center"}} ml={1} mr={1} mb={5} mt={2}>						
						
						<Button disabled={loadingCancel} color="secondary" variant="outlined" style={{flex:1,height:"53px",marginRight:"10px",borderRadius:"10px"}} onClick={()=>setDialog('cancelservice',false)}>
						 Fechar
						</Button>		
						
						<Button disabled={loadingCancel} color="primary" variant="contained" style={{flex:2,height:"53px",color:"#FFF"}} onClick={()=>confirmCancel(props)}>
							{ !loadingCancel &&
							   <div>Confirmar</div>
							}
							
							{ loadingCancel &&
							<>
							   <CircularProgress size={30} color="inherit" />
							   <span style={{marginLeft:"10px"}}>Cancelando...</span>
							</>
							}
						</Button>
							
					</Box>			
			
				</Box>				
			
			</Box>

		  </Dialog>
		 </LocalizationProvider>
	 
		 
		</div>		
	);
	
});


/* Cancel Dialog Component START */
export const UpdateDialog = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {
	

const screenStyleDialog = {
  alignItems: "stretch",
  justifyContent: "top",
  width:"100vw",
  minHeight:"100vh",
  display:"flex",
  flexDirection:"column",
  backgroundColor:"#FFF"
}; 
	
// set classes var
// const classes = useStyles()();	

// set vars from props
// Remember to add those who come from Redux
const { 
	innerLoading,
	setInnerLoading,
	setDialog,
	dialog,
	updateScheduledService,
	SelectedScheduledServiceOrder,
	CustomerAlterTax
} = props;	

/* Dialog END*/	

const [acceptTerms, setAcceptTerms] = useState(false); // state for tab
const [acceptCharge, handleAcceptCharge] = useState(false); // state for tab
const [updateReason, setUpdateReason] = useState("-");
const [updateTax, setUpdateTax] = useState(false);

// add one day to today
const todayPlusTomorrow = add(new Date(), {
  days: 1
});
const tomorrow = format(todayPlusTomorrow, 'dd/MM/yyyy') // formatted

const todayPlusDays = add(new Date(), {
  days: 7
});
const maxdate = format(todayPlusDays, 'dd/MM/yyyy') // formatted

const [selectedDate, handleDateChange] = useState(todayPlusTomorrow);
const [maxDate, setMaxDate] = useState(todayPlusDays);
const [minDate, setMinDate] = useState(todayPlusTomorrow);
const [selectedPeriod, handlePeriodChange] = useState(1);
const [selectedPeriodToday, handlePeriodChangeToday] = useState(1);


const [selectDateScreen, setSelectDateScreen] = useState(false);

const [loadingUpdate, setLoadingUpdate] = useState(false);

// Set functions
const handleAcceptTerms = (event) => {
	const newValue = event.target.checked
	setAcceptTerms(newValue);	
};	

const selectServiceDate = (value) => {

	// Update selected date
	handleDateChange(value);	
	
};	



const confirmDateUpdate = (props) => {
	
	// check of terms of urgency was accepted
	if (!updateReason || updateReason == '-') {			
		props.setDialogMessage("Você precisa escolher o motivo da alteração do agendamento.");
		props.setAlertDialog(true);
		return;			
	} // end if	
	
	// check of terms of urgency was accepted
	if (!acceptCharge) {			
		props.setDialogMessage("Você precisa estar de acordo com a alteração de agendamento para prosseguir.");
		props.setAlertDialog(true);
		return;			
	} // end if		

	// Set Loading
	setSelectDateScreen(true);

};	


const confirmUpdate = (props) => {

	// check if date and period remains the same
	if ( (moment(selectedDate).format("YYYY-MM-DD") == SelectedScheduledServiceOrder.scheduledDate) && (selectedPeriod == SelectedScheduledServiceOrder.scheduledPeriod) ) {			
		props.setDialogMessage("A data e o período de seu agendamento não foram alterados. Selecione uma data e período diferente do atual agendamento.");
		props.setAlertDialog(true);
		return;			
	} // end if	

	
	// check of terms of urgency was accepted
	if (!updateReason || updateReason == '-') {			
		props.setDialogMessage("Você precisa escolher o motivo da alteração do agendamento.");
		props.setAlertDialog(true);
		return;			
	} // end if	
	
	// check of terms of urgency was accepted
	if (!acceptCharge) {			
		props.setDialogMessage("Você precisa estar de acordo com a alteração de agendamento para prosseguir.");
		props.setAlertDialog(true);
		return;			
	} // end if		

	// Set Loading
	setLoadingUpdate(true);

	// Set Service Schedule
	let data = {
		ServiceOrderId:props.ServiceOrderId,
		updateReason:updateReason,
		updateTax:updateTax,
		selectedDate:selectedDate,
		selectedPeriod:selectedPeriod		
	}
	var propsParam = {
		history:props.history,
		setAlert:props.setAlertDialog,
		setDialogMessage:props.setDialogMessage,
		setDialogTitle:props.setDialogTitle,
		setLoadingUpdate:setLoadingUpdate,
		setDialogAction:props.setDialogAction,
		setDialog:setDialog
	}	
	// Update method
	updateScheduledService(data,propsParam);

};	

var formatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});


// useEffect only for dialgo changes
useEffect(() => {
		
	// execute only if this modal
	if (dialog.target == 'updateservice' && dialog.status) {
		
		var today = moment().format("YYYY-MM-DD HH:mm:ss");
		var morningDate = moment(SelectedScheduledServiceOrder.scheduledDate).format("YYYY-MM-DD 09:00:00");
		var eveningDate = moment(SelectedScheduledServiceOrder.scheduledDate).format("YYYY-MM-DD 13:00:00");
		var nightDate = moment(SelectedScheduledServiceOrder.scheduledDate).format("YYYY-MM-DD 18:00:00");
		
		var startDate = moment(today);
		var endDate = moment(morningDate);
		
		if (SelectedScheduledServiceOrder.scheduledPeriod == 1 && SelectedScheduledServiceOrder.type != 3) {
			var endDate = moment(morningDate);	
		} // end if
		else if (SelectedScheduledServiceOrder.scheduledPeriod == 2 && SelectedScheduledServiceOrder.type != 3) {
			var endDate = moment(eveningDate);	
		} // end if
		else if (SelectedScheduledServiceOrder.scheduledPeriod == 3 && SelectedScheduledServiceOrder.type != 3) {
			var endDate = moment(nightDate);
		} // end if
		
		var duration = moment.duration(endDate.diff(startDate));
		var minutes = parseInt(duration.asMinutes());

		// Set cancel Tax on
		if (minutes <= 90) {
			setUpdateTax(true);
		} // end if
	
	}  // end if

}, [dialog]); // end useEffect only for dialgo changes

	return (
	<div>
		<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={brLocale} localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}>
		  <Dialog fullScreen open={dialog.target == 'updateservice' && dialog.status} onClose={()=>setDialog('updateservice',false)} TransitionComponent={TransitionSlideUp}>
			<AppBar style={{position: 'fixed'}}>
			  <Toolbar>
				<IconButton edge="start" color="inherit" onClick={() => setDialog('updateservice',false)} aria-label="close">
				  <CloseIcon />
				</IconButton>
				<Typography variant="h6" style={{marginLeft: "10px",flex: 1}}>
				  Alterando serviço agendado
				</Typography>
			 </Toolbar>
			</AppBar>

			<Box style={{...screenStyleDialog}} m={0}>
			
				<Box mt={7} pl={3} pr={3}>
					
					<Box style={{marginBottom:"5px",alignItems:"stretch",justifyContent:"flex-start",display:"flex",flexDirection:"column"}} p={2}>
								
								{selectDateScreen &&
								<Box mt={1} mb={1} ml={2} mr={2} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>  
										<div style={{marginRight:"10px"}}> 
											<WarningIcon style={{fontSize:"3rem",color:"red"}} />
										</div>
										
										<div> 
											<div style={{marginBottom:"10px",textAlign:"left"}}>Altere a data e horário abaixo. Caso o profissional não tenha disponilibidade, o serviço será cancelado.</div>
										</div>
								</Box>	
								}

								{!selectDateScreen &&
								<Box mt={1}  mb={1} ml={2} mr={2} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>  
										<div style={{marginRight:"10px"}}> 
											<WarningIcon style={{fontSize:"3rem",color:"red"}} />
										</div>
										
										<div> 
											<div style={{marginBottom:"10px",textAlign:"left"}}>Você pode alterar a data e horário do serviço, apenas. O processo é irreversível.  Caso o profissional não tenha disponilibidade, o serviço será cancelado.</div>
										</div>
								</Box>	
								}								
							
					</Box>
					
					<Divider style={{marginBottom:"5px"}} />
					
					{/* Warnign message */}						
					<Fade in={!selectDateScreen} timeout={{ enter: 1000, exit: 0 }} mountOnEnter unmountOnExit>			
							
						<div>

							<Box mt={2}  mb={0} ml={2} mr={2} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>  
									<div> 
										<div style={{marginBottom:"10px",textAlign:"center",fontWeight:"bold"}}> Selecione o motivo da alteração </div>
									</div>
							</Box>	
							
							<Box mt={0}  mb={2} ml={2} mr={2} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>  
									<div style={{marginRight:"10px",width:"90%"}}> 

											<Select
											  label="Motivo da alteração"
											  placeholder="Motivo da alteração"
											  style={{ width:"100%",paddingTop:0,paddingBottom:0,margin:5,marginLeft:10,marginRight:0,backgroundColor:"#fff",width:"100%"}}
											  size="small"
											  variant="outlined"
											  value={updateReason}
											  onChange={(event) => {
												  setUpdateReason(event.target.value)
											  }}	
											 >
												<MenuItem value="-">- Selecione um motivo -</MenuItem>
												<MenuItem value="Cometi um erro na data e horário">Cometi um erro na data e horário</MenuItem>
												<MenuItem value="Mudei de ideia">Mudei de ideia</MenuItem>
												<MenuItem value="Tive um imprevisto">Tive um imprevisto</MenuItem>
												<MenuItem value="Outro motivo não listado">Outro motivo não listado</MenuItem>
											</Select>
											
									</div>
	
							</Box>								
							
							{updateTax &&
							<>
							<Divider style={{marginBottom:"5px"}} />							

							<Box mt={2} mb={2} ml={3} mr={3} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>  
									<div style={{marginRight:"10px"}}> 
										<img src={priceIconBlue} style={{width:"50px",height:"50px"}} />
									</div>
									
									<div> 
										<div style={{fontSize:"0.85rem",color:"#555555",textAlign:"left"}}>
										<strong> Taxa de alteração </strong><br />
										Será cobrada uma taxa de {formatter.format(CustomerAlterTax)} referente a taxa de alteração após tempo limite (1h e 30min antes do serviço). 
										</div>
									</div>
							</Box>
							</>
							}
							
							<Divider style={{marginBottom:"5px"}} />							
							
							<Box mt={2} mb={2} ml={1} mr={2} style={{alignItems:"stretch",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>  

								<div style={{alignItems:"center",justifyContent:"center",display:"flex",flexDirection:"row"}}>
								
									  <div>
										  <Checkbox												
											checked={acceptCharge}
											onChange={(status)=>handleAcceptCharge(!acceptCharge)}
											inputProps={{ 'aria-label': 'primary checkbox' }}
										  />
									  </div>
									  
									   <span>
										Estou de acordo com a alteração do serviço
									  </span>
								
								</div>	 
								
							</Box>							
							

						</div>
						
					</Fade>

					{/* Select Date */}		
					<Fade in={selectDateScreen} timeout={{ enter: 1000, exit: 0 }} mountOnEnter unmountOnExit>			
							
						<div>

							<Box mt={2}  mb={0} ml={2} mr={2} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>  
									<div> 
										<div style={{marginBottom:"10px",textAlign:"center",fontWeight:"bold"}}> Selecione a nova data e período do serviço </div>
									</div>
							</Box>	
							
							<Box mt={0}  mb={2} ml={1} mr={1} style={{alignItems:"flex-start",justifyContent:"flex-start",display:"flex",flexDirection:"column"}}>  
		

									{/* Scheduled - Day Selection */}
									<Box style={{marginTop:"5px",marginBottom:"0px",alignItems:"stretch",justifyContent:"flex-start",display:"flex",flexDirection:"column"}} p={2}>															
												<div style={{flex:1,alignItems:"stretch",justifyContent:"flex-start",display:"flex",flexDirection:"column"}}>    
														<div style={{marginBottom:"10px",textAlign:"left"}}> Defina para que dia quer o serviço </div>	

														  <DatePicker
															disablePast
															disableToolbar
															autoOk
															format="dd/MM/yyyy (iiii)"
															minDate={minDate} // min date is tomorrow
															maxDate={maxDate} // calculate today + 10 days
															inputVariant="outlined"
															cancelLabel="Cancelar"
															size="small"
															helperText="Máximo de 7 dias à partir de hoje."
															value={selectedDate}
															onChange={selectServiceDate}
														  />

													  

												</div>
									</Box>	
									
									<Divider />
									
									{/* Scheduled - Period Selection */}
									<Box style={{marginTop:"5px",marginBottom:"5px",alignItems:"stretch",justifyContent:"flex-start",display:"flex",flexDirection:"column"}} p={2}>	

												<div style={{flex:1,alignItems:"stretch",justifyContent:"flex-start",display:"flex",flexDirection:"column"}}>    
													
														<div style={{marginBottom:"10px",textAlign:"left"}}> Defina o período para execução do serviço </div>
														<ButtonGroup color="secondary" aria-label="outlined primary button group" style={{display:"flex",flexDirection:"row",alignItems:"stretch"}}>
															  <Button variant={ selectedPeriod == 1 ? "contained" : ""} style={{flex:1}} onClick={(period)=>handlePeriodChange(1)}>Manhã</Button>
															  <Button variant={ selectedPeriod == 2 ? "contained" : ""} style={{flex:1}} onClick={(period)=>handlePeriodChange(2)}>Tarde</Button>
															  <Button variant={ selectedPeriod == 3 ? "contained" : ""} style={{flex:1}} onClick={(period)=>handlePeriodChange(3)}>Noite</Button>
														</ButtonGroup>
													
												</div>	


												<Box mt={2} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>  
														<div style={{marginRight:"10px"}}> 
															<Info style={{fontSize:"1.5rem",color:"#555"}} />
														</div>
														
														<div> 
														
														<Fade in={selectedPeriod == 1} timeout={{ enter: 1000, exit: 0 }}  mountOnEnter unmountOnExit>			
															<div style={{fontSize:"0.85rem",color:"#555555",textAlign:"left"}}>Período da Manhã entre 09:00 e 13:00</div>
														</Fade>
														
														<Fade in={selectedPeriod == 2} timeout={{ enter: 1000, exit: 0 }} mountOnEnter unmountOnExit>			
															<div style={{fontSize:"0.85rem",color:"#555555",textAlign:"left"}}>Período da Tarde entre 13:00 e 18:00</div>
														</Fade>

														<Fade in={selectedPeriod == 3} timeout={{ enter: 1000, exit: 0 }} mountOnEnter unmountOnExit>			
															<div style={{fontSize:"0.85rem",color:"#555555",textAlign:"left"}}>Período da Noite entre 18:00 e 21:00</div>
														</Fade>												
															
															
														</div>
												</Box>	

									</Box>

		
	
							</Box>								
					

						</div>
						
					</Fade>
					
			
					<Divider style={{marginBottom:"5px"}} />
						
					<Box style={{display:"flex",flexDirection:"row",justifyContent:"center"}} ml={1} mr={1} mb={5} mt={2}>

						{selectDateScreen &&
						<>
						<Button disabled={loadingUpdate} color="secondary" variant="outlined" style={{flex:1,height:"53px",marginRight:"10px",borderRadius:"10px"}} onClick={()=>setSelectDateScreen(false)}>
						 Cancelar
						</Button>		
						
						
						<Button disabled={loadingUpdate} color="primary" variant="contained" style={{flex:2,height:"53px",color:"#FFF"}} onClick={()=>confirmUpdate(props)}>
							{ !loadingUpdate &&
							   <div>Confirmar alteração</div>
							}
							
							{ loadingUpdate &&
							<>
							   <CircularProgress size={30} color="inherit" />
							   <span style={{marginLeft:"10px"}}>Alterando...</span>
							</>
							}
						</Button>
						</>
						}
					
						{!selectDateScreen &&
						<>
						<Button disabled={loadingUpdate} color="secondary" variant="outlined" style={{flex:1,height:"53px",marginRight:"10px",borderRadius:"10px"}} onClick={()=>setDialog('cancelservice',false)}>
						 Fechar
						</Button>		
						
						
						<Button disabled={loadingUpdate} color="primary" variant="contained" style={{flex:2,height:"53px",color:"#FFF"}} onClick={()=>confirmDateUpdate(props)}>
							{ !loadingUpdate &&
							   <div>Próximo</div>
							}
							
							{ loadingUpdate &&
							<>
							   <CircularProgress size={30} color="inherit" />
							   <span style={{marginLeft:"10px"}}>Alterando...</span>
							</>
							}
						</Button>
						</>
						}
							
					</Box>			
			
				</Box>				
			
			</Box>

		  </Dialog>
		 </LocalizationProvider>
	 	 
		</div>		
	);
	
});


const ConfirmDialog = (props) => {
	
	return (
		 <Dialog
			open={props.dialog.target == 'updateServicePrompt' && props.dialog.status}
			onClose={ ()=>props.setDialog('updateServicePrompt',false) } 
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		  >
		  <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
			<DialogContent>
			  <DialogContentText id="alert-dialog-description">
			  {props.dialogMessage}
			  </DialogContentText>
			</DialogContent>
			<DialogActions>

			  <Button onClick={ ()=>props.setDialog('updateServicePrompt',false) } color="primary" autoFocus>
				ALTERAR DATA
			  </Button>			

			  <Button onClick={ ()=> {props.setDialog('updateServicePrompt',false);props.setDialog('cancelservice',true); } } color="primary" autoFocus>
				CANCELAR SERVIÇO
			  </Button>
				
			</DialogActions>			
		  </Dialog>
	  )
		
} // end AlertDialog


const AlertDialog = (props) => {
	
	return (
		 <Dialog
			open={props.dialogAlert}
			onClose={!props.dialogAction ? () => { props.setAlertDialog(false) } : () => { props.setAlertDialog(false);props.navigate("/scheduledServices") }  }
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		  >
		  <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
			<DialogContent>
			  <DialogContentText id="alert-dialog-description">
			  {props.dialogMessage}
			  </DialogContentText>
			</DialogContent>
			<DialogActions>
			
			{props.dialogAction &&
			  <Button onClick={() => {props.setAlertDialog(false);props.navigate("/scheduledServices")} } color="primary" autoFocus>
				OK
			  </Button>
			}

			{!props.dialogAction &&
			  <Button onClick={() => props.setAlertDialog(false) } color="primary" autoFocus>
				OK
			  </Button>
			}			  
			  
			</DialogActions>			
		  </Dialog>
	  )
	
	
} // end AlertDialog

export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(ScheduledServicesView); 

