import React,{useState,useEffect,Fragment} from 'react';
import * as config from '../../Config/api.js';

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

import { Box,Divider,Typography,Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';

import Info from '@mui/icons-material/ErrorOutline';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AccountTreeIcon from '@mui/icons-material/AccountTree'; 
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ScheduleIcon from '@mui/icons-material/Schedule';
import WarningIcon from '@mui/icons-material/Warning';

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';
import ZoomInIcon from '@mui/icons-material/ZoomIn';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// Load Actions
import * as AppActions from '../../Store/Actions/App'

const mapStateToProps = store => ({
  innerLoading:store.appReducer.innerLoading,
  dialog:store.appReducer.dialog
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AppActions }, dispatch);
    
// Transition Slide for Dialog SLIDE UP	
const TransitionSlideUp = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;  
});	
	

/* Extra  Dialog Component START */
export const ExtraDetailDialog = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {
		

const screenStyleDialog = {
  alignItems: "stretch",
  justifyContent: "top",
  width:"100vw",
  minHeight:"100vh",
  display:"flex",
  flexDirection:"column",
  backgroundColor:"#FFF"
}; 
	
// set classes var
// const classes = useStyles()();	

const { 
	innerLoading,
	setInnerLoading,
	setDialog,
	SelectedOngoingServiceOrder,
	dialog
} = props;	

const [cardFlag, setCardFlag] = useState(""); // state for tab

var formatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});


// Run after mount component
useEffect(() => {

	
}, []); // end useEffect

const zoomImage = (image) => {
	
	props.setImage(image);
	setDialog('imageDetail',true);
	
}

	return (
	<div>
		  <Dialog fullScreen open={dialog.target == 'extraDetail' && dialog.status} onClose={()=>{setDialog('extraDetail',false);setDialog('serviceInfo',true)}} TransitionComponent={TransitionSlideUp}>
			<AppBar style={{position: 'fixed'}}>
			  <Toolbar>
				<IconButton edge="start" color="inherit" onClick={() => {setDialog('extraDetail',false);setDialog('serviceInfo',true)}} aria-label="close">
				  <CloseIcon />
				</IconButton>
				<Typography variant="h6" style={{marginLeft: "10px",flex: 1}}>
				  Informações adicionais
				</Typography>
				</Toolbar>
			</AppBar>

			<Box style={{...screenStyleDialog}} m={0}>
			
				<Box mt={8} ml={0} mr={0}>

							<Box mt={2} mb={2} style={{textAlign:"center",alignItems:"stretch",justifyContent:"center",display:"flex",flexDirection:"column"}}>
									
									{SelectedOngoingServiceOrder.ServiceOrderMetaInformations.map( (item) => {
									
									if (item.fieldId == 2) {
										var itemValue = JSON.parse(item.fieldValue); 
									} // end if
									else {
										var itemValue = item.fieldValue;
									} // end else
									
									return (
										<Box ml={2} mr={2} mb={2} style={{alignItems:"stretch",justifyContent:"flex-start",display:"flex",flexDirection:"column"}} key={item.id}>
										
											<Box style={{flex:1}}>
											
												<Box style={{marginBottom:"10px",alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>
													<div> 
														<div style={{fontSize:"0.9rem",color:"#555555"}}>{item.fieldLabel}</div>
													</div>
												</Box>									
											
											</Box>
											
											<Box style={{flex:1}}>								
													<Box style={{fontSize:"0.9rem",border:"1px solid gray",backgroundColor:"#FFF",padding:"10px",borderRadius:"5px",textAlign:"left"}}>
														
														{Array.isArray(itemValue) &&
														<Box style={{marginTop:"0px", display: 'flex',flexWrap: 'wrap', justifyContent: 'flex-start',overflow: 'hidden',}}>													
														<ImageList style={{flex:1,display:"flex",padding:"0",flexWrap:"nowrap",listStyle:"none",overflowY:"auto"}} cols={3}>									
															{itemValue.map((item, index) => {	
														
																var extraImage = config.API_URL+"/service/extrainfoimage/"+SelectedOngoingServiceOrder.CustomerUserId+"/"+item.filename+"";
																
																return (
																	<ImageListItem key={item.id}>
																	<img src={extraImage} onClick={()=> zoomImage(extraImage) } />
																	<ImageListItemBar
																	  style={{background:"transparent",marginLeft: "10px",flex: 1}}
																	  actionIcon={
																		<IconButton>
																		  <ZoomInIcon style={{color:"#FFF",marginLeft: "10px",flex: 1}} />
																		</IconButton>
																	  }
																	/>
																  </ImageListItem>																
																)
																
																} 
																		
															)}	
														 </ImageList>														
														</Box>	
														}
														
														{!Array.isArray(itemValue) &&
															<div>{itemValue}</div>
														}														
														
													</Box>								
											</Box>								
										
										</Box>
									)
									
									})
									
									}
									
					</Box>
			
					<Divider style={{marginBottom:"5px"}} />
						
					<Box style={{display:"flex",flexDirection:"row",justifyContent:"center"}} ml={1} mr={1} mb={5} mt={2}>						
						
						<Button color="secondary" variant="outlined" style={{flex:1,height:"48px",marginRight:"10px"}} onClick={()=>{setDialog('extraDetail',false);setDialog('serviceInfo',true)}}>
						 Fechar
						</Button>		
													
					</Box>			
			
				</Box>				
			
			</Box>
			

		  </Dialog>
		</div>
		
	);
	
});



