import './MainHome.css';
import React,{useState,useEffect,Fragment} from 'react';
import {Link,useNavigate} from 'react-router-dom';

import PropTypes from 'prop-types';
import { CSSTransition } from "react-transition-group";

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

import InputMask from "react-input-mask";

// Material UI Import
import { Box,CircularProgress,Typography,Button,IconButton,TextField,FormControl  } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';


import {useComponentWillMount} from '../../Utils/customFunctions';
import Header from '../../Components/Layout/Header';
import If from '../../Components/Common/If';

import logo from '../../Assets/Images/logoTypeWOslogan.svg'; // Tell Webpack this JS file uses this image
import backgroundHexagon from '../../Assets/Images/backgroundHexagon.png'; // Tell Webpack this JS file uses this image


// Load Actions
import * as AppActions from '../../Store/Actions/App'
import * as RegisterActions from '../../Store/Actions/Register'

const ProfileStep3 = props => {


const screenStyle = {
  alignItems: "stretch",
  justifyContent: "top",
  width:"100vw",
  height:"100vh",
  display:"flex",
  flexDirection:"column",
  backgroundImage: "url(" + backgroundHexagon + ")",
  backgroundSize:"cover",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"center"
}; 

const screenStyleLoading = {
  alignItems: "middle",
  justifyContent: "center",
  width:"100vw",
  height:"100vh",
  display: "flex",
  flexDirection: "column"
}; 

const styles = {
  indicator: {
    backgroundColor: '#A255A0',
  },
};

// Set functions
const handleChange = (event, newValue) => {
	
	setValue(newValue);

	if (newValue == 0) {
		setLoginType("email");
	} // end if
	else {
		setLoginType("mobilePhone");
	} // end else

};

// Set TABPANEL
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
	  hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

// set classes var
// const classes = useStyles()();


// set vars from props
// Remember to add those who come from Redux
const { 
	registerDataStep0,
	registerDataStep1,
	registerDataStep2,
	registerDataStep3,
	innerLoading,
	setInnerLoading,
	buttonLoading,
	setButtonLoading,
	setRegister,
	activateProfileGenerate,
	activateProfileConfirm,
	userData
} = props;

// set Use of history for PUSH 
let navigate = useNavigate();



console.log("userData: "+JSON.stringify(userData,null,2));


// Set internal state
const [contentVisible,setContentState] = useState(false);
const [loadingMessage,setLoadingMessage] = useState("Carregando...");
const [value, setValue] = useState(0); // state for tab

const [dialog, setDialog] = useState(false); // state for tab
const [dialogMessage, setDialogMessage] = useState(""); // state for tab
const [snack,setSnack] = useState(false); // state for tab
const [snackMessage, setSnackMessage] = useState(""); // state for tab

const [email, setEmail] = useState(registerDataStep1.email ? registerDataStep1.email : userData.email ? userData.email:""); // state for input fields
const [mobilePhone, setMobilePhone] = useState(registerDataStep1.mobilePhone ? registerDataStep1.mobilePhone : userData.mobilePhone ? userData.mobilePhone:""); // state for input fields
const [firstName, setFirstName] = React.useState(null);

const [codeView1, setCodeView1] = React.useState(true);
const [codeView2, setCodeView2] = React.useState(false);
const [code, setCode] = React.useState("");

const [sendingCode, setSendingCode] = React.useState(false);
const [dialogConfirm, setDialogConfirm] = useState(false); // state for tab
const [loginType, setLoginType] = useState("email"); // state for tab


// Confirm email change
const sendCodeCheck = (targetLoginType,value) => {
			
	if (targetLoginType == "email") {
		
		// set scope email
		setEmail(value.email);
	
		// Check if Email is filled
		if (!value.email) {		
			setDialogMessage("Digite o email para prosseguir.");
			setDialog(true);
			setButtonLoading(false);	
			setSendingCode(false);			
			return false;		
		} // end if

		// Check if Email is valid
		let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
		if (reg.test(value.email) === false) {
			setDialogMessage("Digite um email válido para prosseguir.");
			setDialog(true);
			setButtonLoading(false);	
			setSendingCode(false);				
			return false;
		} // end if	
		
	} // end if
	else {
		
		// set scope email
		setMobilePhone(value.mobilePhone);	

		// set only numbers for mobilePhone
		const mobileNumberClean = value.mobilePhone.replace(/\D/g,"");
		
		if (!value.mobilePhone) {		
			setDialogMessage("Digite o telefone para prosseguir.");
			setDialog(true);
			setButtonLoading(false);	
			setSendingCode(false);			
			return false;		
		} // end if		
		
		// Min 10 numbers 
		if (mobileNumberClean.length < 10) {
			setDialogMessage("Digite um Telefone celular válido para prosseguir.");
			setDialog(true);
			setButtonLoading(false);				
			return false;
		} // end if	

		// Check if DDD is valid
		const dddArray = [11,12,13,14,15,16,17,18,19,21,22,24,27,28,31,32,33,34,35,37,38,41,42,43,44,45,46,47,48,49,51,53,54,55,61,62,63,64,65,66,67,68,69,71,73,74,75,77,79,81,82,83,84,85,86,87,88,89,91,92,93,94,95,96,97,98,99];
		const dddPart = parseInt(mobileNumberClean.substr(0,2));	

		if (!dddArray.includes(dddPart)) {
			setDialogMessage("Digite um DDD válido para prosseguir.");
			setDialog(true);
			setButtonLoading(false);				
			return false;
		} // end if		

		// If number has 11 digits, Check if number star with 9. If not, fail.
		const firstElevenDigitPart = parseInt(mobileNumberClean.substr(2,1));
		if (firstElevenDigitPart != 9 && mobileNumberClean.length == 11) {
			setDialogMessage("Digite um Número de celular válido para prosseguir.");
			setDialog(true);
			setButtonLoading(false);				
			return false;
		} // end if		
		
		// If number has 10 digits, check if number start with 6, 7, 8 or 9
		const tenDigitsValid = [6,7,8,9];
		const firstDigitPart = parseInt(mobileNumberClean.substr(2,1));	
		if (!tenDigitsValid.includes(firstDigitPart)) {
			setDialogMessage("Digite um Número de celular válido para prosseguir.");
			setDialog(true);
			setButtonLoading(false);				
			return false;
		} // end if		
		
	}		

	// Check if email or mobilePhone changed...
	if ( (value.email != userData.email && targetLoginType == "email") || (value.mobilePhone != userData.mobilePhone && targetLoginType == "mobilePhone") ) {
		setDialogConfirm(true);	
	} // end if
	else {
		sendCode();
	} // end else
	
} // end const


const sendCode = () => {
	 	  
	setSendingCode(true);
	setCode("");

	// set data
	var data = {
		email:email,
		mobilePhone:mobilePhone,
		loginType:loginType
	};
	 
	// Save email on store
	setRegister(data,3);
	
	//[ DISPATCH ACTION ] checkEmail(NEXT STEP, DATA ,navigation OBJ);
	var props = {
		navigate:navigate,
		setDialogMessage:setDialogMessage,
		setDialog:setDialog	,
		setSnack:setSnack,
		setSnackMessage:setSnackMessage,
		setCodeView1:setCodeView1,
		setCodeView2:setCodeView2,
		setSendingCode:setSendingCode	
	}		
	
	// Activate Profile Generate
	activateProfileGenerate(data,props);
	
} // end


const activateProfile = () => {
	
	setButtonLoading(true);
	setSendingCode(false);
		
	// Check if Required fields are Filled
	if (!code) {	
		setButtonLoading(false);	
		setDialogMessage('Digite o código recebido por email ou celular para prosseguir.');	
		setDialog(true);		
		return;
	} // end if	

	if (code.length < 6) {	
		setButtonLoading(false);	
		setDialogMessage('O código recebido por email ou celular deve ter 6 dígitos.');
		setDialog(true);		
		return;
	} // end if		
	
	
	// set data
	var data = { 
		code:code,
		email:email,
		originalEmail:userData.email
	};
	
	var props = {
		navigate:navigate,
		setDialogMessage:setDialogMessage,
		setDialog:setDialog	,
		setSnack:setSnack,
		setSnackMessage:setSnackMessage,
		setCodeView1:setCodeView1,
		setCodeView2:setCodeView2,
		setSendingCode:setSendingCode,
		targetRedirect:"/home/activate/success"
	}		
	// Activate Profile Generate
	activateProfileConfirm(data,props);
	
} // end


const editEmail = () => {	
		setCodeView1(true);
		setCodeView2(false);		
} // end


// Run after mount component
useEffect(() => {

	// Retrieve User Data Obj. 
	var result = localStorage.getItem("@motuum/userData", undefined,true);
	var userData = JSON.parse(result);  
	
	// Rediredct if there is no userData
	if (!userData) {	  
	navigate("/");  
	return;
	} // end if		
	
	// Propagate firstName
	if (userData.CustomerProfile) {
		setFirstName(userData.CustomerProfile.firstName);
	} // end if

 // Set Content Loading ON
 //setInnerLoading(true);
 setContentState(true);
 
 // Simulate Delay
 setTimeout(function() {
	 
	  // Set Content Loading OFF
	  //setInnerLoading(false);
	  
	  // Set Content Ready to show
	  //setContentState(true);
	
 },3000);

  // set scroll to top
  window.scrollTo(0, 1);
    
  // Runs when UNMOUNT component
  return () => {
    // unmounted  component
  };
  
}, []); // end useEffect


// Will Mount Component HOOK
useComponentWillMount(() => {
	
    // Set session of app
  var sessionCheck = sessionStorage.getItem('@motuum/appLaunched');
  
  // if session is not created, redirect to bootstrap. (avoid direct access using url)
  if (!sessionCheck) {
	  navigate("/");
  } // end if	
      
}, []); // end useComponentWillMount

	return (
	<Fragment>
			
			<Header title="Ativando seu Perfil" buttons={{support:true,menu:false,close:false,back:true}} /> 
			
			<CSSTransition in={contentVisible} timeout={1000} classNames="fade"	unmountOnExit>	
			
				<Box className="mainProfile" style={{...screenStyle}} m={0}>
							
						<Box style={{marginBottom:"20px",backgroundColor:"transparent",alignItems:"center",display:"flex",flexDirection:"column"}} mt={7} pl={3} pr={3}>

								<h5 style={{fontSize:"1rem",color:"#A255A0"}}>{firstName ? firstName+", a":"A"}tive seu  na perfl na Motuum</h5>
								
								<div style={{fontSize:"0.9rem",color:"#555555"}}>Para solicitar profissionais, é necessário que ative o seu perfil através da confirmação do seu telefone ou email. </div>
								
						</Box>
						
						<Box style={{backgroundColor:"transparent",display:"flex",alignItems:"top"}}>							
								
							<Box ml={2} mr={2} style={{flex:1,flexGrow: 1,backgroundColor: "transparent",}}>
						
							  { codeView1 &&
							  <>
							  <Tabs
								style={{marginBottom:"10px"}} 
								value={value}
								indicatorColor="primary"
								textColor="primary"
								onChange={handleChange}
								aria-label="Seleção de forma de login"
							  >
								<Tab label="Validar Email" style={{textTransform:"none",flexBasis:"50%",fontSize:"0.9rem"}} />
								<Tab label="Validar Telefone" style={{textTransform:"none",flexBasis:"50%",fontSize:"0.9rem"}} />
							  </Tabs>
							  
							  
							  <TabPanel value={value} index={0}>
								
									<FormEmail
										sendingCode={sendingCode}
										email={email}
										sendCodeCheck={sendCodeCheck}
									/>
								
							  </TabPanel>
							  
							  <TabPanel value={value} index={1}>

									<FormMobilePhone
										sendingCode={sendingCode}
										mobilePhone={mobilePhone}
										sendCodeCheck={sendCodeCheck}
									/>

							  </TabPanel>
							  </>
							 }							  
							 
							 
							 { codeView2 &&
									<>
									<div>
										{ loginType == "mobilePhone" &&
											<TextField
											  label="Telefone Celular"
											  style={{ margin: 10,marginLeft:0,marginRight:0,backgroundColor:"#fff",width:"100%"}}
											  placeholder="Telefone no formato (XX) 9XXXX-XXXX"
											  required
											  size="small"
											  variant="outlined"
											  type="text"
											  type="phone"
											  disabled={true}
											  value={mobilePhone}
											  onChange={(event) => {
												  setMobilePhone(event.target.value)
											  }}	
											 />
										}

										{ loginType == "email" &&
											<TextField
											  label="Email"
											  style={{ margin: 10,marginLeft:0,marginRight:0,backgroundColor:"#fff",width:"100%"}}
											  placeholder="Informe o email cadastrado"
											  required
											  size="small"
											  disabled={true}
											  variant="outlined"
											  type="email"
											  value={email}
											  onChange={(event) => {
												  setEmail(event.target.value)
											  }}	
											/>
										}
												
								  </div>

									<div>							  
							  
									  { loginType == "mobilePhone" &&
										 <h4 style={{marginBottom:"5px"}}> Verifique seu telefone celular... </h4>
									  }

									  { loginType == "email" &&
										 <h4 style={{marginBottom:"5px"}}> Verifique sua caixa de email... </h4>
									  }
							  
										<TextField
										  id="verificationCode"
										  label="Digite os 6 números recebidos"
										  style={{ margin: 10,marginLeft:0,marginRight:0,backgroundColor:"#fff",width:"100%"}}
										  placeholder="Digite os 6 números recebidos"
										  required
										  variant="outlined"
										  inputProps={{ maxLength: 6 }}
											type="text"
											value={code}
											onChange={(event) => {
											  setCode(event.target.value)
											}}										  
										 />

									</div>
							  
									<div style={{display:"flex",flexDirection:"row"}}>
									
										<Button color="secondary" style={{width:"45%",height:"48px",marginTop:"10px"}} component={Link} to="/register/step4">Ativar depois</Button>

										<Button variant="contained" color="secondary" style={{width:"55%",height:"48px",marginTop:"10px"}} onClick={() => activateProfile() }>
											{ !buttonLoading &&
											   <div>Ativar Perfil</div>
											}
											
											{ buttonLoading &&
											   <CircularProgress size={30} color="inherit" />
											}	
										</Button>										
										
										
									</div>	

									<div style={{marginTop:"20px",display:"flex",flexDirection:"column"}}>	
									
									  { loginType == "mobilePhone" &&
										 <Button color="secondary" style={{marginTop:"10px",marginBottom:"0px"}} onClick={() => editEmail() }>Alterar Telefone</Button>
									  }

									  { loginType == "email" &&
										 <Button color="secondary" style={{marginTop:"10px",marginBottom:"0px"}} onClick={() => editEmail() }>Alterar Email</Button>
									  }
																			
									</div>

									<div style={{marginTop:"10px",display:"flex",flexDirection:"column"}}>
										
										<Button color="secondary" style={{height:"48px",marginTop:"10px",marginBottom:"0px"}} onClick={() => sendCode() }>
											{ !sendingCode &&
											   <div>Reenviar código</div>
											}
											
											{ sendingCode &&
											   <CircularProgress size={30} color="inherit" />
											}	
										</Button>										
										
										<small style={{color:"gray",marginTop:"0px"}}>Reenvie caso não tenha recebido em até 5 minutos</small>

									</div>
								</>
							 }
							 
							  
							</Box>
								
						</Box>
						
								
				</Box>
			
			</CSSTransition> 
			
			<AlertDialog 
				dialog={dialog}
				setDialog={setDialog}
				dialogMessage={dialogMessage}
			/>
			
			<ConfirmDialog 
				dialogConfirm={dialogConfirm}
				setDialogConfirm={setDialogConfirm}
				loginType={loginType}
				sendCode={sendCode}
			/>			

			<SnackNotification 
				snack={snack}
				setSnack={setSnack}
				snackMessage={snackMessage}
			/>
			
				
	</Fragment>
)};

const FormEmail = (props) => {
	
const [email, setEmail] = useState(props.email); // state for input fields
	
	return (	
		<form id="formLoginEmail"  noValidate autoComplete="off">

			<TextField
			  id="loginEmail"
			  label="Email"
			  style={{ margin: 10,marginLeft:0,marginRight:0,backgroundColor:"#fff",width:"94%"}}
			  placeholder="Informe o email para cadastro"
			  required
			  size="small"
			  variant="outlined"
			  type="email"
			  value={email}
			  onChange={(event) => {
				  setEmail(event.target.value)
			  }}									  
			/>
			
			<div style={{display:"flex",flexDiredction:"row"}}>
			
				<Button color="secondary" style={{width:"45%",height:"48px",marginTop:"10px"}} component={Link} to="/home">Ativar depois</Button>

				<Button variant="contained"  color="primary" size="large" style={{width:"55%",height:"48px",marginTop:"10px",backgroundColor:"#555555",color:"#FFF"}} onClick={() => props.sendCodeCheck("email",{email:email}) }>
					{ !props.sendingCode &&
					   <div>Enviar código</div>
					}
					
					{ props.sendingCode &&
					   <CircularProgress size={30} color="inherit" />
					}	
				</Button>

			</div>
		  
		</form>	
	);
	
	
} // end export const

const FormMobilePhone = (props) => {
	
	const [mobilePhone, setMobilePhone] = useState(props.mobilePhone); // state for input fields
	
	return (	
		<form id="formLoginPhone" noValidate autoComplete="off">

			<InputMask
            mask="(99) 99999-9999"
            value={mobilePhone}
			  onChange={(event) => {
				  setMobilePhone(event.target.value)
			  }}
          >
		  {(inputProps) => (
			<TextField
			  label="Telefone Celular"
			  style={{ margin: 10,marginLeft:0,marginRight:0,backgroundColor:"#fff",width:"94%"}}
			  placeholder="Telefone no formato (XX) 9XXXX-XXXX"
			  required
			  size="small"
			  variant="outlined"
			 />
		  )}
        </InputMask>
										
			<div style={{display:"flex",flexDiredction:"row"}}>
			
				<Button color="secondary" style={{width:"45%",height:"48px",marginTop:"10px"}} component={Link} to="/home">Ativar depois</Button>

				<Button variant="contained"  color="primary" size="large" style={{width:"55%",height:"48px",marginTop:"10px",backgroundColor:"#555555",color:"#FFF"}} onClick={() => props.sendCodeCheck("mobilePhone",{mobilePhone:mobilePhone})}>
					{ !props.sendingCode &&
					   <div>Enviar código</div>
					}
					
					{ props.sendingCode &&
					   <CircularProgress size={30} color="inherit" />
					}	
				</Button>

			</div>
			
		  
		</form>	
	);
	
} // end export const


const AlertDialog = (props) => {
	
	return (
		 <Dialog
			open={props.dialog}
			onClose={() => props.setDialog(false)} 
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		  >
		  <DialogTitle id="alert-dialog-title">Verifique as informações</DialogTitle>
			<DialogContent>
			  <DialogContentText id="alert-dialog-description">
			  {props.dialogMessage}
			  </DialogContentText>
			</DialogContent>
			<DialogActions>
			  <Button onClick={() => props.setDialog(false)} color="primary" autoFocus>
				OK
			  </Button>
			</DialogActions>			
		  </Dialog>
	  )
	
	
} // end AlertDialog

const ConfirmDialog = (props) => {
	
	return (
		 <Dialog
			open={props.dialogConfirm}
			onClose={() => {props.setDialogConfirm(false); } } 
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		  >
		  <DialogTitle id="alert-dialog-title">Confirme a a??o</DialogTitle>
			<DialogContent>
			  <DialogContentText id="alert-dialog-description">		
			  
			  { props.loginType == "email" &&
			   <span>Você alterou seu email do cadastro que foi realizado. Ao finalizar a ativação seu email do cadastro será atualizado. Confirma?</span>
			  }
			  
			  { props.loginType == "mobilePhone" &&
				<span>Você alterou seu telefone do cadastro que foi realizado. Ao finalizar a ativação seu email do cadastro será atualizado. Confirma?</span>
			  }			  
			  
			  </DialogContentText>
			</DialogContent>
			<DialogActions>
			  <Button onClick={() => { props.setDialogConfirm(false); } } color="secondary" autoFocus>
				CANCELAR
			  </Button>
			  <Button onClick={() => { props.sendCode();props.setDialogConfirm(false) } } color="primary" autoFocus>
				CONFIRMAR
			  </Button>			  
			</DialogActions>			
		  </Dialog>
	  )
	
} // end AlertDialog

const SnackNotification = (props) => {
	
	//props.snackMessage = "C?digo enviado com sucesso! Aguarde alguns segundos para receber...";

	return (
		<Snackbar
			anchorOrigin={{
			  vertical: 'bottom',
			  horizontal: 'left',
			}}
			open={props.snack}
			autoHideDuration={4000}
			onClose={() => props.setSnack(false)}
		  >
		    <div style={{borderRadius:10,borderWidth:"1px",borderColor:"#222",padding:10,display:"flex",flexDirection:"row",alignItems:"center",flex:1,backgroundColor:"#666"}}>
				
				<div style={{flex:6,fontSize:"14px",textAlign:"left",color:"#fff"}}>{props.snackMessage}</div>
				
				<IconButton style={{flex:1,color:"#fff"}} size="small" aria-label="close" color="inherit" onClick={() => props.setSnack(false)}>
				  <CloseIcon fontSize="small" />
				</IconButton>
			  			
			</div>
		  </Snackbar>
	  )	
	
} // end AlertDialog

 
 
const mapStateToProps = store => ({
  innerLoading:store.appReducer.innerLoading,
  buttonLoading:store.registerReducer.buttonLoading,
  registerDataStep0:store.registerReducer.registerDataStep0,
  registerDataStep1:store.registerReducer.registerDataStep1,
  registerDataStep2:store.registerReducer.registerDataStep2,
  registerDataStep3:store.registerReducer.registerDataStep3,
  userData:store.appReducer.userData
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AppActions, ...RegisterActions }, dispatch);

export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(ProfileStep3);
 