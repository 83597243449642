import React from 'react';
import axios from 'axios';
import moment from 'moment';
import * as types from '../actionTypes';
import * as config from '../../Config/api.js';

export const setButtonLoading  = (status) => { 

	return { 
		type: types.BUTTON_LOADING,
		payload: status 
	}
};

export const setLogin = (obj,step) => {

	return {
		type: types.SET_LOGIN,
		payload: obj
	}	

};

export const setLogoff = (obj,step) => {

	return {
		type: types.SET_LOGOFF,
		payload: obj
	}	

};

export const processLogOff = (props) => {
	
		return dispatch => {
			
			// Set logoff flag
			localStorage.setItem("loggedOff",1);
			
			// Remove userData 
			localStorage.removeItem("@motuum/userData");	
			
			// Remove Session ALSO
			sessionStorage.removeItem("@motuum/searchingProvider");	
			sessionStorage.removeItem("@motuum/serviceOfferId");	
			sessionStorage.removeItem("@motuum/quotationId");	
			sessionStorage.removeItem("@motuum/sessionId");
			sessionStorage.removeItem("@motuum/selectedProvider");

			dispatch(setLogin(false));
			dispatch(setLogoff(true));		
													
			return;			
			
		} // end dispatch
	
};

export const processLogin = (data,props) => {
	
		return dispatch => {

				// Prepare for login
				const preparedData = {};
				
				// Set general data
				preparedData.email = data.email ? data.email:"";
				preparedData.mobilePhone = data.mobilePhone ? data.mobilePhone: "";
				preparedData.password = data.password;
				preparedData.loginType = data.loginType;				
				preparedData.device = data.device;
				
				// Connect to REGISTER Endpoint
				axios({
					   url    : config.API_URL+'/user/login',
					   method : 'POST',
					   data   : preparedData,
					   headers: {
						Accept: 'application/json'
					}
				})				
				
				.then((response) => {
	
				// If an error occured
				if (response.data.error) {					
					dispatch(setButtonLoading(false));
					props.setDialogMessage('Ocorreu um erro ao se comunicar com o servidor. Tente novamente.');
					props.setAlertDialog(true);
					return;			
				} // end if	
	
  
				if (response.data.success) {
					
					dispatch(setButtonLoading(false));
					
					// If company already exist
					if (response.data.noResults) {
						props.setDialogMessage('Não foi possível autenticar o seu usuário. Verifique o email e a senha informados.');
						props.setAlertDialog(true);						
					} // end if
					
					
					// If company already exist
					if (response.data.token) {
						
						// Check if there is a local USER OBEJECt with a TOKEN
						var userObj = {
							userToken:response.data.token,
							userChatToken:response.data.chatToken,
							firstName:response.data.firstName,
							completeName:response.data.completeName,
							userStatus:response.data.status,
							validated: response.data.status
						}
						const saveObj = JSON.stringify(userObj);
						
						// save User Session Id on Session Storage
						sessionStorage.setItem("@motuum/sessionId",response.data.sessionId);						
						
						// save User Data on Local Storage
						localStorage.setItem("@motuum/userData",saveObj);
						
						// Check of user activated profile
							//  if not, redirect to activation...
						
						// Navigate to next step
						props.navigate("/");
						return;
																
					} // end if
					
					
				} // end if
					
				}, (error) => {
															
					dispatch(setButtonLoading(false));
					props.setDialogMessage('Ocorreu um erro ao se comunicar com o servidor. Tente novamente.');
					props.setAlertDialog(true);					
										
				})
				.catch(error=> {
						
					dispatch(setButtonLoading(false));
					props.setDialogMessage('Ocorreu um erro geral ao se comunicar com a API. Verifique sua conexão e tente novamente.');	
					props.setAlertDialog(true);					

				});

			
		} // end dispatch
	
};

export const recoverPasswordGenerate  = (data,props) => {

		return dispatch => {
					
					// prepare data to send
					var sendData = {
						email: data.email,
						mobilePhone:data.mobilePhone,
						loginType: data.loginType
					} // end if
	
					// Connect to REGISTER Endpoint
					axios({
						   url    : config.API_URL+'/user/recoverPasswordGenerate',
						   method : 'POST',
						   data: sendData,
						   headers: {
							Accept: 'application/json'
						}
					})				
					
					.then((response) => {
						
					// If an error occured. Check error is blocking or non-blocking.
					if (response.data.error) {
						
						props.setSendingCode(false);

						if (response.data.notificationSent === false) {							
							props.setDialogMessage('Ocorreu um erro ao enviar o email ou sms. Verifique os dados e tente novamente.');		
							props.setDialog(true);									
							return;
						} // end if

						props.setDialogMessage('Ocorreu um erro ao se comunicar com o servidor. Tente novamente. [1]');		
						props.setDialog(true);							
						return;					
					} // end if	*/
			
					if (response.data.success) {
						
						// If company already exist
						if (response.data.notfound) {
								props.setSendingCode(false);
								props.setDialogMessage('Não encontramos um usuário com as informações fornecidas. Verifique o email ou telefone informado.');
								props.setDialog(true);									
								return;
						} // end if
						
						// If company already exist
						if (response.data.notificationSent) {
											
							dispatch(setButtonLoading(false));
							
							// send success message to screen and toeaster
							props.setCodeView1(false);
							props.setCodeView2(true);	
							props.setSendingCode(false);
							
							// set Recover Token on sessionId
							sessionStorage.setItem("@motuum/recoverToken",response.data.recoverToken);
														
							props.setSnackMessage('Código enviado com sucesso! Aguarde alguns segundos para receber...');		
							props.setSnack(true);														
							return;											
							
						} // end if
						// Could not send notification. Tells to try again
						else {
								props.setSendingCode(false);
								props.setDialogMessage('O código não foi enviado para seu email ou celular devido a um problema. Tente reenviar o código. Se o problema persistir, verifique o email ou telefone.');
								props.setDialog(true);									
								return;
						} // end else
						
					} // end if
					
					// If any other error, what do do???
					// ...
						
					}, (error) => {
											
						props.setSendingCode(false);
						props.setDialogMessage('Ocorreu um erro ao se comunicar com o servidor. Tente novamente.');
						props.setDialog(true);							
						return;
											
					})
					.catch(error=> {
							
						props.setSendingCode(false);
						props.setDialogMessage('Ocorreu um erro geral ao se comunicar com a API. Verifique sua conexão e tente novamente.');
						props.setDialog(true);									
						return;

					});


		}		
};

export const recoverPasswordConfirm  = (data,props) => {

		return dispatch => {
		
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = sessionStorage.getItem("@motuum/recoverToken", undefined,true);
				var recoverToken = result;  
					
					// prepare data to send
					var sendData = {
						code: data.code,
						recoverToken:recoverToken
					} // end if
	
					// Connect to REGISTER Endpoint
					axios({
						   url    : config.API_URL+'/user/recoverPasswordConfirm',
						   method : 'POST',
						   data: sendData,
						   headers: {
							Accept: 'application/json'
						}
					})				
					
					.then((response) => {

					// If an error occured. Check error is blocking or non-blocking.
					if (response.data.error) {
							
						// Code expired...
						if (response.data.errorCode == 9992) {
							dispatch(setButtonLoading(false));
							props.setDialogMessage('O código informado está expirado. Reenvie um novo código e tente novamente. Lembre-se que você tem até 5 minutos para validar o código.');	
							props.setDialog(true);								
							return;								
						} // end if
						
						// Wrong code
						if (response.data.errorCode == 9991) {
							dispatch(setButtonLoading(false));
							props.setDialogMessage('O código informado não é igual ao enviado. Verifique o email ou mensagem recebida e tente novamente. Caso o erro persista, reenvie um novo código e tente novamente.');	
							props.setDialog(true);															
							return;			
						} // end if
					
					
						dispatch(setButtonLoading(false));
						props.setDialogMessage('Ocorreu um erro ao se comunicar com o servidor. Tente novamente. [1]');	
						props.setDialog(true);								

						return;					
					} // end if	*/
					
					if (response.data.success) {
						
						dispatch(setButtonLoading(false));
							
						// If company already exist
						if (response.data.validated) {
							
							// update / save User Data on Local Storage
							sessionStorage.setItem("recoverPasswordStatus","1");							
						
								// Turn off loading button and send Toast message
								dispatch(setButtonLoading(false));		
							  
								props.setSnackMessage('Código confirmado com sucesso! Altere a sua senha.');		
								props.setSnack(true);	
							
								props.navigate("/forgotpassword/updatePassword");
								return;
										
							// return to avoid 
							return;											
							
						} // end if
						
					} // end if
					
					// If any other error, 404 for recoverToken Expired???
					// ...
						
					}, (error) => {											
						dispatch(setButtonLoading(false));
						props.setDialogMessage('Ocorreu um erro ao se comunicar com o servidor. Tente novamente.');
						props.setDialog(true);							
						return;											
					})
					.catch(error=> {
						dispatch(setButtonLoading(false));
						props.setDialogMessage('Ocorreu um erro geral ao se comunicar com a API. Verifique sua conexão e tente novamente.');
						props.setDialog(true);								
						return;
					});

		}		
};

export const recoverPasswordUpdate  = (data,props) => {

		return dispatch => {
		
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = sessionStorage.getItem("@motuum/recoverToken", undefined,true);
				var recoverToken = result;  
					
					// prepare data to send
					var sendData = {
						password: data.password,
						recoverToken:recoverToken
					} // end if
	
					// Connect to REGISTER Endpoint
					axios({
						   url    : config.API_URL+'/user/recoverPasswordUpdate',
						   method : 'POST',
						   data: sendData,
						   headers: {
							Accept: 'application/json'
						}
					})				
					
					.then((response) => {

					// If an error occured. Check error is blocking or non-blocking.
					if (response.data.error) {
							
						// Code expired...
						if (response.data.errorCode == 8999) {
							dispatch(setButtonLoading(false));
							props.setDialogMessage('O tempo para alterar a sua senha expirou. Você tem até 10 minutos desde o momento e geração do código, confirmação e alteração da senha. Reinicie o processo.');	
							props.setDialog(true);								
							return;								
						} // end if
						
						// Wrong code
						if (response.data.errorCode == 9991) {
							dispatch(setButtonLoading(false));
							props.setDialogMessage('Não encontramos um usuário com as informações enviadas. Cancele essa tentativa e tente o processo novamente.');	
							props.setDialog(true);															
							return;			
						} // end if
					
						dispatch(setButtonLoading(false));
						props.setDialogMessage('Ocorreu um erro ao se comunicar com o servidor. Tente novamente. [1]');	
						props.setDialog(true);								

						return;					
					} // end if	*/
					
					if (response.data.success) {
						
						dispatch(setButtonLoading(false));
							
						// If company already exist
						if (response.data.updated) {
							
							// update recover status and remove recover token
							sessionStorage.setItem("recoverPasswordStatus","2");
							sessionStorage.removeItem("@motuum/recoverToken");							
						
								// Turn off loading button and send Toast message
								dispatch(setButtonLoading(false));		
							  
								props.setSnackMessage('Senha alterada com sucesso! Faça o login agora.');		
								props.setSnack(true);	
							
								props.navigate("/forgotpassword/success");
								return;
										
							// return to avoid 
							return;											
							
						} // end if
						
					} // end if
					
					// If any other error, 404 for recoverToken Expired???
					// ...
						
					}, (error) => {											
						dispatch(setButtonLoading(false));
						props.setDialogMessage('Ocorreu um erro ao se comunicar com o servidor. Tente novamente.');
						props.setDialog(true);							
						return;											
					})
					.catch(error=> {
						dispatch(setButtonLoading(false));
						props.setDialogMessage('Ocorreu um erro geral ao se comunicar com a API. Verifique sua conexão e tente novamente.');
						props.setDialog(true);								
						return;
					});

		}		
};





