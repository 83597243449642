import React,{useState,useEffect,Fragment} from 'react';

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import * as config from '../../Config/api.js';

import { Box,Divider,Typography,Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';

import moment from 'moment';
import 'moment/locale/pt-br';
import add from 'date-fns/add'
import format from 'date-fns/format'
import { DatePicker } from '@mui/x-date-pickers'

import Info from '@mui/icons-material/ErrorOutline';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AccountTreeIcon from '@mui/icons-material/AccountTree'; 
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ScheduleIcon from '@mui/icons-material/Schedule';
import WarningIcon from '@mui/icons-material/Warning';

import ZoomInIcon from '@mui/icons-material/ZoomIn';
import EventIcon from '@mui/icons-material/Event';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import backgroundHexagonLight from '../../Assets/Images/backgroundHexagonLight.png'; // Tell Webpack this JS file uses this image

// Load Actions
import * as AppActions from '../../Store/Actions/App'

const mapStateToProps = store => ({
  innerLoading:store.appReducer.innerLoading,
  dialog:store.appReducer.dialog
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AppActions }, dispatch);
    
// Transition Slide for Dialog SLIDE UP	
const TransitionSlideUp = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;  
});	
	

/* Extra  Dialog Component START */
export const ServiceInfoDialog = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {

const backgroundLight = {
  backgroundImage: "url(" + backgroundHexagonLight + ")",
  backgroundSize:"cover",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"center"
};

const screenStyleDialog = {
  alignItems: "stretch",
  justifyContent: "top",
  width:"100vw",
  minHeight:"100vh",
  display:"flex",
  flexDirection:"column",
  backgroundColor:"#FFF"
}; 
	
// set classes var
// const classes = useStyles()();	

const { 
	innerLoading,
	setInnerLoading,
	setDialog,
	SelectedExecutedServiceOrder,
	dialog
} = props;	

const [categoryBreadCrumb,setCategoryBreadCrumb] = useState(SelectedExecutedServiceOrder.ServiceOrderService ? SelectedExecutedServiceOrder.ServiceOrderService.Service.ServiceCategories[0].breadcrumb :"carregando...");

//Set content constants
const [serviceId,setServiceId] = useState(null);
const [serviceName,setServiceName] = useState("carregando...");
const [serviceDescription,setServiceDescription] = useState("carregando...");
const [serviceScheduledDate,setServiceScheduledDate] = useState("carregando...");
const [breadcrumb,setBreadcrumb] = useState("carregando...");
const [serviceScheduledPeriod,setServiceScheduledPeriod] = useState("carregando...");
const [servicePaymentMethod,setServicePaymentMethod] = useState("carregando...");
const [serviceExecutionTime,setServiceExecutionTime] = useState("carregando...");
const [servicePrice,setServicePrice] = useState("carregando...");
const [serviceAddress,setServiceAddress] = useState("carregando...");
const [serviceBillingInfo,setServiceBillingInfo] = useState("carregando...");
const [serviceEmployeeName,setServiceEmployeeName] = useState("carregando...");
const [serviceEmployeeDocument,setServiceEmployeeDocument] = useState("carregando...");
const [serviceCompanyName,setServiceCompanyName] = useState("carregando...");

const [serviceStartedAt,setServiceStartedAt] = useState("carregando...");
const [minutesFromStart,setMinutesFromStart] = useState("carregando...");
const [servicePredictedFinish,setServicePredictedFinish] = useState("carregando...");
const [serviceArrivePrediction,setServiceArrivePrediction] = useState("carregando...");

const [extraInfoflag,setExtraInfoFlag] = useState(false);
const [serviceStatus, setServiceStatus] = useState(3);

var formatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});


const periodArray = [];
periodArray[1] = "09:00 às 13:00";
periodArray[2] = "13:00 às 18:00";
periodArray[3] = "18:00 às 21:00";

// Run if object [SelectedScheduledServiceOrder] change
useEffect(() => {

try {
	
// Set service details
setServiceId(SelectedExecutedServiceOrder.ServiceOrderService.ServiceId);
setServiceName(SelectedExecutedServiceOrder.ServiceOrderService.name);
setServiceDescription(SelectedExecutedServiceOrder.ServiceOrderService.description);

setBreadcrumb(SelectedExecutedServiceOrder.ServiceOrderService.Service.ServiceCategories[0].breadcrumb);

if (SelectedExecutedServiceOrder.type == 1) {
	var periodLabel = periodArray[SelectedExecutedServiceOrder.scheduledPeriod];	
} // end if
else if (SelectedExecutedServiceOrder.type == 2) {
	var periodLabel = "HOJE das "+periodArray[SelectedExecutedServiceOrder.scheduledPeriod];	
} // end if
else {
	var periodLabel = "URGENTE - em at? 2 horas";	
} // end if
setServiceScheduledPeriod(periodLabel);

var formattedDate = moment(SelectedExecutedServiceOrder.scheduledDate).locale('pt-br').format("DD/MM/YYYY (dddd)");
setServiceScheduledDate(formattedDate);

var paymentMethodLabel = SelectedExecutedServiceOrder.paymentMethod == 1 ? "Cartão de Crédito": "Transferência via PIX";
setServicePaymentMethod(paymentMethodLabel);

var timeLabel = SelectedExecutedServiceOrder.ServiceOrderService.effort +" "+SelectedExecutedServiceOrder.ServiceOrderService.effortUnity;
setServiceExecutionTime(timeLabel);

var priceLabel = "R$ "+(Math.round(SelectedExecutedServiceOrder.ServiceTotalPrice * 100) / 100).toFixed(2);
setServicePrice(priceLabel);

// set status
setServiceStatus(SelectedExecutedServiceOrder.status);

// Set address and billing info
setServiceAddress(SelectedExecutedServiceOrder.ServiceOrderAddress.fullAddress);
var BillinInfoType = SelectedExecutedServiceOrder.ServiceOrderBilling.type == 1 ? "<strong>Pessoa Física</strong>": "<strong>Pessoa Jurídica</strong>";


if (SelectedExecutedServiceOrder.ServiceOrderMetaInformations.length > 0) {
	setExtraInfoFlag(true);
} // end if


var BillingInfoLabel = BillinInfoType+" <br /> "+SelectedExecutedServiceOrder.ServiceOrderBilling.billingName +" <br /> "+SelectedExecutedServiceOrder.ServiceOrderBilling.fullAddress;
setServiceBillingInfo(BillingInfoLabel);

// Set Provider Data
setServiceEmployeeName(SelectedExecutedServiceOrder.ProviderUser.ProviderProfile.ProviderProfilePerson.ProviderProfileCompanyEmployee.completeName);
setServiceEmployeeDocument(SelectedExecutedServiceOrder.ProviderUser.ProviderProfile.ProviderProfilePerson.ProviderProfileCompanyEmployee.document);
setServiceCompanyName(SelectedExecutedServiceOrder.ProviderUser.ProviderProfile.ProviderProfileCompany.realName);

} catch(e) {}

  
}, [SelectedExecutedServiceOrder]); // end useEffect


	return (
	<div>
		  <Dialog fullScreen open={dialog.target == 'serviceInfo' && dialog.status} onClose={()=>setDialog('serviceInfo',false)} TransitionComponent={TransitionSlideUp}>
			<AppBar style={{position: 'fixed'}}>
			  <Toolbar>
				<IconButton edge="start" color="inherit" onClick={() => setDialog('serviceInfo',false)} aria-label="close">
				  <CloseIcon />
				</IconButton>
				<Typography variant="h6" style={{marginLeft: "10px",flex: 1}}>
				  Detalhes do serviço
				</Typography>
				</Toolbar>
			</AppBar>

			<Box style={{...screenStyleDialog}} m={0}>
			
				<Box mt={8} ml={0} mr={0}>

					<Box pt={2} pb={2} mt={0} mb={0} style={{height:"70vh",...backgroundLight,alignItems:"stretch",justifyContent:"flex-start",display:"flex",flexDirection:"column"}}>
							
							{/* Date Box */}
							<Box ml={1} mr={1} mb={2} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>
							
								<Box style={{flex:2}}>
								
									<Box style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>    
										<div style={{marginRight:"5px"}}> 
											<EventIcon style={{fontSize:"1.5rem",color:"#999"}} />
										</div>
										
										<div> 
											<div style={{fontSize:"0.9rem",color:"#555555"}}>Data agendada</div>
										</div>
									</Box>									
								
								</Box>
								
								<Box style={{flex:2.5}}>								
										<Box style={{fontSize:"0.9rem",border:"1px solid gray",backgroundColor:"#FFF",padding:"2px",borderRadius:"5px"}}>
											{serviceScheduledDate}
										</Box>								
								</Box>								
							
							</Box>
							
							{/* Period Box */}	
							<Box ml={1} mr={1} mb={2} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>
							
								<Box style={{flex:2}}>
								
									<Box style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>    
										<div style={{marginRight:"5px"}}> 
											<TimelapseIcon style={{fontSize:"1.5rem",color:"#999"}} />
										</div>
										
										<div> 
											<div style={{fontSize:"0.9rem",color:"#555555"}}>Horário / Período</div>
										</div>
									</Box>									
								
								</Box>
								
								<Box style={{flex:2}}>								
										<Box style={{fontSize:"0.9rem",border:"1px solid gray",backgroundColor:"#FFF",padding:"2px",borderRadius:"5px"}}>
											{serviceScheduledPeriod}
										</Box>								
								</Box>								
							
							</Box>							
						
							{/* Payment Box */}	
							<Box ml={1} mr={1} mb={2} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>
							
								<Box style={{flex:2}}>
								
									<Box style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>    
										<div style={{marginRight:"5px"}}> 
											<AccountBalanceWalletIcon style={{fontSize:"1.5rem",color:"#999"}} />
										</div>
										
										<div> 
											<div style={{fontSize:"0.9rem",color:"#555555"}}>Pagamento</div>
										</div>
									</Box>									
								
								</Box>
								
								<Box style={{flex:2}}>								
										<Box style={{fontSize:"0.9rem",border:"1px solid gray",backgroundColor:"#FFF",padding:"2px",borderRadius:"5px"}}>
											{servicePaymentMethod}											
											<ZoomInIcon style={{fontSize:"1.4rem",color:"#999",float:"right"}} onClick={(target,status) => setDialog('paymentDetail',true)} />
											
										</Box>	
										
								</Box>								
							
							</Box>		


							{/* Extra info Box */}
							<Box ml={1} mr={1} mb={2} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>
							
								<Box style={{flex:2}}>
								
									<Box style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>    
										<div style={{marginRight:"5px"}}> 
											<AddCircleIcon style={{fontSize:"1.5rem",color:"#999"}} />
										</div>
										
										<div> 
											<div style={{fontSize:"0.9rem",color:"#555555"}}>Informações adicionais</div>
										</div>
									</Box>									
								
								</Box>
								
								<Box style={{flex:1}}>								
										<Box style={{fontSize:"0.9rem",backgroundColor:"transparent",padding:"2px",borderRadius:"5px",textAlign:"right"}}>
										
											{extraInfoflag &&
												<Button variant="outlined" size="small" color="secondary" onClick={(target,status) => setDialog('extraDetail',true)}>
												  Abrir
												</Button>											
											}

											{!extraInfoflag &&
												<Box style={{fontSize:"0.9rem",border:"1px solid gray",backgroundColor:"#FFF",padding:"2px",borderRadius:"5px"}}>
													Não informado										
												</Box>											
											}
											
										</Box>								
								</Box>								
							
							</Box>


							{/* Time Box */}
							<Box ml={1} mr={1} mb={2} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>
							
								<Box style={{flex:2}}>
								
									<Box style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>    
										<div style={{marginRight:"5px"}}> 
											<ScheduleIcon style={{fontSize:"1.5rem",color:"#999"}} />
										</div>
										
										<div> 
											<div style={{fontSize:"0.9rem",color:"#555555"}}>Tempo médio do serviço</div>
										</div>
									</Box>									
								
								</Box>
								
								<Box style={{flex:1}}>								
										<Box style={{fontSize:"0.9rem",border:"1px solid gray",backgroundColor:"#FFF",padding:"2px",borderRadius:"5px"}}>
											{serviceExecutionTime}
										</Box>								
								</Box>								
							
							</Box>


							{/* Price Box */}
							<Box ml={1} mr={1} mb={2} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>
							
								<Box style={{flex:2}}>
								
									<Box style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>    
										<div style={{marginRight:"5px"}}> 
											<AttachMoneyIcon style={{fontSize:"1.7rem",color:"green"}} />
										</div>
										
										<div> 
											<div style={{fontSize:"0.9rem",color:"#555555"}}>Valor do serviço</div>
										</div>
									</Box>									
								
								</Box>
								
								<Box style={{flex:1}}>								
										<Box style={{fontSize:"0.9rem",border:"1px solid gray",backgroundColor:"#FFF",padding:"2px",borderRadius:"5px"}}>
											{servicePrice}
										</Box>								
								</Box>								
							
							</Box>							
												
					
					</Box>

					<Divider style={{marginBottom:"5px"}} />
						
					<Box style={{display:"flex",flexDirection:"row",justifyContent:"center"}} ml={1} mr={1} mb={5} mt={2}>						
						
						<Button color="secondary" variant="outlined" style={{flex:1,height:"48px",marginRight:"10px"}} onClick={()=>setDialog('serviceInfo',false)}>
						 Fechar
						</Button>		
													
					</Box>			
			
				</Box>				
			
			</Box>

		  </Dialog>
		</div>
		
	);
	
});



