import './MainProfile.css';
import React,{useState,useEffect,Fragment} from 'react';
import {Link,useNavigate} from 'react-router-dom';

import {
  osVersion,
  osName,
  fullBrowserVersion,
  mobileVendor,
  mobileModel,
  deviceType,
  isBrowser,
  isMobile
} from "react-device-detect";

import PropTypes from 'prop-types';
import { CSSTransition } from "react-transition-group";

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
// import LiveChat from 'react-livechat'

import { Box,CircularProgress,Typography,Button,InputAdornment,IconButton,TextField,FormControl,Divider } from '@mui/material';

import Slide from '@mui/material/Slide';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LockIcon from '@mui/icons-material/Lock';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import NiceInputPassword from 'react-nice-input-password';

import {useComponentWillMount} from '../../Utils/customFunctions';
import Header from '../../Components/Layout/Header';
import If from '../../Components/Common/If';
import {SupportComponent} from '../Support/SupportComponentHeader';


import logo from '../../Assets/Images/logo_motuum-white.svg'; // Tell Webpack this JS file uses this image
import backgroundHexagon from '../../Assets/Images/backgroundHexagon.png'; // Tell Webpack this JS file uses this image
import backgroundHeaderImage from '../../Assets/Images/backgroundHeader.png'; // Tell Webpack this JS file uses this image
import helpIcon from '../../Assets/Images/help-icon-blue.svg'; // Tell Webpack this JS file uses this image


// Load Actions
import * as AppActions from '../../Store/Actions/App'
import * as RegisterActions from '../../Store/Actions/Register'

const ProfileStep2 = props => {


const screenStyle = {
  alignItems: "stretch",
  justifyContent: "top",
  width:"100vw",
  minHeight:"100vh",
  display:"flex",
  flexDirection:"column",
  backgroundImage: "url(" + backgroundHexagon + ")",
  backgroundSize:"cover",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"center"
}; 

const screenStyleLoading = {
  alignItems: "middle",
  justifyContent: "center",
  width:"100vw",
  height:"100vh",
  display: "flex",
  flexDirection: "column"
}; 

const styles = {
  indicator: {
    backgroundColor: '#A255A0',
  },
};

// set classes var
// const classes = useStyles()();

// Set functions
const handleChange = (event) => {
	
//const newValue = "A";
const newValue = event.value;
	
setValue(newValue);
setPassword(newValue);
};

// Set functions
const handleAcceptTerms = (event) => {

const newValue = event.target.checked
setAcceptTerms(newValue);

};

// set vars from props
// Remember to add those who come from Redux
const { 
	registerDataStep0,
	registerDataStep1,
	registerDataStep2,
	registerDataStep3,
	innerLoading,
	setInnerLoading,
	buttonLoading,
	setButtonLoading,
	setRegister,
	processStep,
	termsContent,
	retrieveTermsContent
} = props;

// set Use of history for PUSH 
let navigate = useNavigate();

// Set internal state
const [contentVisible,setContentState] = useState(false);
const [loadingMessage,setLoadingMessage] = useState("Carregando...");
const [value, setValue] = useState(''); 
const [acceptTerms, setAcceptTerms] = useState(false); // state for tab

const [dialog, setDialog] = useState(false); // state for tab
const [dialogMessage, setDialogMessage] = useState(""); // state for tab

const [dialogConfirm, setDialogConfirm] = useState(false); // state for tab

const [dialogHelp, setDialogHelp] = useState(false); // state for tab

const [modal, setModal] = useState(false); // state for tab

const [email, setEmail] = useState(registerDataStep1.email ? registerDataStep1.email : registerDataStep2.email ? registerDataStep2.email : ""); // state for input fields
const [mobilePhone, setMobilePhone] = useState(registerDataStep1.mobilePhone ? registerDataStep1.mobilePhone : registerDataStep2.mobilePhone ? registerDataStep2.mobilePhone : ""); // state for input fields

const [password, setPassword] = useState(""); // state for input fields
const [passwordConfirm, setPasswordConfirm] = useState(""); // state for input fields


// password view control
const [showPassword, setShowPassword] = useState(false);
const handleClickShowPassword = () => setShowPassword(!showPassword);
const handleMouseDownPassword = () => setShowPassword(!showPassword);

const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
const handleClickShowPasswordConfirm = () => setShowPasswordConfirm(!showPasswordConfirm);
const handleMouseDownPasswordConfirm = () => setShowPasswordConfirm(!showPasswordConfirm);


/* Passsword Strenght bar START*/
const levelBarCss = (level) => ({
  height: "8px",
  width: level > 0 ? `${(100 / 4) * level}%` : "100%",
  marginBottom: 10,
  transition: "width 0.5s ease",
  backgroundColor: ["#EFEFEF", "red", "orange", "yellow", "green"][level],
  borderRadius: 0
});

const CustomLevelBar = (levels) => <div style={levelBarCss(levels)} />;

 const securityLevels = [
      {
        descriptionLabel: <Typography>1 number</Typography>,
        validator: /.*[0-9].*/
      },
      {
        descriptionLabel: <Typography>1 lowercase letter</Typography>,
        validator: /.*[a-z].*/
      },
      {
        descriptionLabel: <Typography>1 uppercase letter</Typography>,
        validator: /.*[A-Z].*/
      },
      {
        descriptionLabel: <Typography>6 of length</Typography>,
        validator: /^.{6,}$/
      }
    ];
/* Passsword Strenght bar END*/
  
// Run after mount component
useEffect(() => {
	
  
	// Retrieve User Data Obj. 
	var result = localStorage.getItem("@motuum/userData", undefined,true);
	var userData = JSON.parse(result);  

	  // If token dont exist, redirect to onboarding or register
	  if (userData) {
		  navigate("/home");
		  return;
	  } // end if  	

	// if did not pass on step1, go back to.
	if (!registerDataStep1.email) {	  
	  navigate("/register/step1");
	  return;
	} // end if	 

 // Set Content Loading ON
 //setInnerLoading(true);
 setContentState(true);
 

  // set scroll to top
  window.scrollTo(0, 1);
    
  // Runs when UNMOUNT component
  return () => {
    // unmounted component
  };
  
}, []); // end useEffect


// Will Mount Component HOOK
useComponentWillMount(() => {
	
    // Set session of app
  var sessionCheck = sessionStorage.getItem('@motuum/appLaunched');
  
  // if session is not created, redirect to bootstrap. (avoid direct access using url)
  if (!sessionCheck) {
	  navigate("/");
  } // end if	
      
}, []); // end useComponentWillMount


const handleKeypress = e => {
	
	 //it triggers by pressing the enter key  
	if (e.key === "Enter") {
	  nextStepCheck();
	}

};


const nextStepCheck = () => {
		
	setButtonLoading(true);

	// Check if Email is filled
	if (!password || !passwordConfirm) {		
		setDialogMessage("Preencha todos os campos para prosseguir.");
		setDialog(true);
		setButtonLoading(false);		
		return false;		
	} // end if


	// Check if Email is filled
	if (password != passwordConfirm) {		
		setDialogMessage("Verifique as senhas digitadas. Elas devem ser iguais.");
		setDialog(true);
		setButtonLoading(false);		
		return false;		
	} // end if
	
	// Check if terms was accepted
	if (!acceptTerms) {		
		setDialogMessage("Você deve aceitar os termos de uso para prosseguir.");
		setDialog(true);
		setButtonLoading(false);		
		return false;		
	} // end if	
	
	// If all OK, open confirm BOX. 
	setDialogConfirm(true);	
	
} // end const

const nextStep = (nextStep) => {
		
	setButtonLoading(true);

	// Set device info
	var deviceObj = {
		deviceName: mobileVendor,
		deviceType: deviceType, 
		deviceOs: osName+" "+osVersion,
		deviceModel: mobileModel,
		deviceUserAgent: fullBrowserVersion,
		deviceScreenResolution:window.innerWidth+" x "+window.innerHeight
	}

	// set data
	var data = {
		...registerDataStep1,
		password:password,
		device: deviceObj
	};
		
	// Save email on store
	setRegister(data,2);
	
	//[ DISPATCH ACTION ] checkEmail(NEXT STEP, DATA ,navigation OBJ);
	var props = {
		navigate:navigate,
		setDialogMessage:setDialogMessage,
		setDialog:setDialog	
	}
	processStep(nextStep,data,props);
	
} // end const


// Open terms content
const openTerms = () => {
 
	setModal(true);

	// Retrieve content from API
	var props = {
		setDialogMessage:setDialogMessage,
		setAlert:setDialog
	}	
	var data = {}
	if (!termsContent) {
		retrieveTermsContent(data,props);
	} // end if

};


	return (
	<Fragment>
			
			<Header title="Cadastrar Perfil" buttons={{support:false,menu:false,close:false,back:true}} /> 
			
			<CSSTransition in={contentVisible} timeout={1000} classNames="fade"	unmountOnExit>	
			
				<Box className="mainProfile" style={{...screenStyle}} m={0} >
							
						<Box style={{marginBottom:"20px",backgroundColor:"transparent",paddingLeft:"55px",paddingRight:"55px"}} mt={10}>

								<Box style={{marginTop:"30px",marginBottom:"20px"}}>						
									<div style={{fontFamily:"Roboto",fontWeight:"100",fontSize:"42px",color:"#003CFA"}}>
										  CADASTRO
									</div>								 	
								</Box>	
								
								<div style={{fontFamily:"Roboto",fontSize:"16px",color:"#636466"}}>Preencha os dados abaixo para finalizar o seu cadastro.</div>
								
						</Box>
						
						<Box style={{backgroundColor:"transparent",display:"flex",alignItems:"top",paddingLeft:"30px",paddingRight:"30px"}} pb={15}>								
								
							<Box style={{flexGrow: 1,backgroundColor: "transparent" }} ml={2} mr={2} >

								<form id="formLoginEmail"  style={{flexGrow: 1,backgroundColor: "transparent" }} noValidate autoComplete="off">
									
									<FormControl style={{width:"94%"}}>

										<TextField
										  label="Email"
										  style={{ margin: 10,marginLeft:0,marginRight:0,backgroundColor:"#fff",width:"100%"}}
										  placeholder="Informe o email cadastrado"
										  required
										  disabled={true}
										  variant="outlined"
										  type="email"
										  value={email}
										  onChange={(event) => {
											  setEmail(event.target.value)
										  }}	
										/>
										 
										<NiceInputPassword
												  placeholder="Digite sua senha"
												  InputComponent={TextField}
												  InputComponentProps={{
													onKeyPress:handleKeypress,  
													type:showPassword ? "text":"password", 
													style:{ margin: 0,marginTop:"10px",marginLeft:0,marginRight:0,backgroundColor:"#fff",width:"100%" },
													variant: "outlined",
													InputProps: {
													  startAdornment: <LockIcon style={{marginRight:"10px",color:"#ccc"}} />,
													  endAdornment: <IconButton style={{marginRight:"-10px"}} aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} >
													  {showPassword ? <Visibility /> : <VisibilityOff />}
													  </IconButton>
													}
												  }}
												  showSecurityLevelBar
												  renderLevelBarComponent={CustomLevelBar}
												  onChange={handleChange}
												  value={password}
												  securityLevels={securityLevels}
												/>		
				
										 
										<TextField
										  style={{ margin: 10,marginLeft:0,marginRight:0,backgroundColor:"#fff",width:"100%"}}
										  placeholder="Confirme a senha digitada"
										  variant="outlined"
										  type={showPasswordConfirm ? "text":"password"}
										  onKeyPress={handleKeypress}
										  value={passwordConfirm}
										  onChange={(event) => {
											  setPasswordConfirm(event.target.value)
										  }}										  
										  InputProps={{
											  startAdornment: (
												<InputAdornment position="start">
												  <LockIcon style={{color:"#ccc"}} />
												</InputAdornment>
											  ),
											   endAdornment: (<IconButton style={{marginRight:"-10px"}} aria-label="toggle password visibility" onClick={handleClickShowPasswordConfirm} onMouseDown={handleMouseDownPasswordConfirm} >
											  {showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
											  </IconButton>)
											}}	
										 />
									  
										<div style={{marginTop:"10px",marginBottom:"10px"}}>
										
										      <Checkbox												
												checked={acceptTerms}
												onChange={handleAcceptTerms}
												inputProps={{ 'aria-label': 'primary checkbox' }}
											  />
											  
											  <span>
												Estou de acordo com os <span style={{color:"blue"}} onClick={()=> openTerms() }>Termos de uso</span>
											  </span>										
										
										</div>	 							  

									  
										<Button disabled={buttonLoading} variant="contained" color="primary" size="large" style={{width:"100%",height:"48px",marginTop:"10px",backgroundColor:buttonLoading ? "#AAA":"#555555"}} onClick={()=> nextStepCheck() }>	
										
										{ !buttonLoading &&
										   <div>Finalizar cadastro</div>
										}
										
										{ buttonLoading &&
										   <CircularProgress size={30} color="inherit" />
										}	
										
										</Button>

									</FormControl>									
								  
								</form>
							  
							</Box>
								
						</Box>
						
						<Box style={{backgroundColor:"transparent",display:"flex",width:"100%",alignItems:"center",position:"fixed",bottom:0,height:"80px",backgroundColor:"#ffffff",boxShadow: "0px -10px 15px 0px #0000001A"}} onClick={()=>setDialogHelp(true)}>		

							<Box style={{flex:1,display:"flex",flexDirection:"row",justifyContent:"space-around"}}>
									
								<Box style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>						
										
											<div style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
												<img src={helpIcon} alt="Ajuda" style={{width:"25px",height:"auto"}} />
											</div>
										  
											<div style={{marginLeft:"5px",paddingTop:"3px",fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px",color:"#003cfa",}}>
											  Ajuda
											</div>	
											
						
								</Box>	

							</Box>								
								
						</Box>							
								
				</Box>
			
			</CSSTransition> 
			
	
			{/*<LiveChat license={13444314} />*/}			
			
			<HelpDialog
				dialogHelp={dialogHelp}
				setDialogHelp={setDialogHelp}
			/>						
			
			<AlertDialog 
				dialog={dialog}
				setDialog={setDialog}
				dialogMessage={dialogMessage}
			/>	
			
			<ConfirmDialog 
				dialogConfirm={dialogConfirm}
				setDialogConfirm={setDialogConfirm}
				nextStep={nextStep}
				setButtonLoading={setButtonLoading}
			/>	

			<TermsDialog 
				modal={modal}
				setModal={setModal}
			/>					
				
	</Fragment>
)};

const mapStateToProps = store => ({
  innerLoading:store.appReducer.innerLoading,
  termsContent:store.appReducer.termsContent,
  buttonLoading:store.registerReducer.buttonLoading,
  registerDataStep0:store.registerReducer.registerDataStep0,
  registerDataStep1:store.registerReducer.registerDataStep1,
  registerDataStep2:store.registerReducer.registerDataStep2,
  registerDataStep3:store.registerReducer.registerDataStep3,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AppActions, ...RegisterActions }, dispatch);

// Transition Slide for Dialog SLIDE UP	
const TransitionSlideUp = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;  
});	

/* Extra  Dialog Component START */
const TermsDialog = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {
	
	
const backgroundHeaderModal = {
  backgroundImage: "url(" + backgroundHeaderImage + ")",
  backgroundSize:"cover",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"center",
  display:"flex",
  alignItems:"center",
  justifyContent:"center"
}; 		


const screenStyleDialog = {
  alignItems: "center",
  justifyContent: "top",
  width:"100vw",
  minHeight:"90vh",
  display:"flex",
  flexDirection:"column",
  backgroundColor:"#F0F0F0"
}; 
	
// set classes var
// const classes = useStyles()();	

const { 
	setModal,
	modal,
	termsContent
} = props;	

	return (
	<div>
		  <Dialog fullScreen open={modal} onClose={()=>setModal(false)} TransitionComponent={TransitionSlideUp}>
			<AppBar style={{position: 'fixed',height:'80px',...backgroundHeaderModal}}>
			  <Toolbar style={{width:"100%"}}>
				  <div style={{flex: 1,textAlign:"center",display:"flex",justifyContent:"center",alignItems:"center"}}>
					  <img src={logo} alt="Motuum" style={{marginLeft:"auto",marginRight:"auto",width:"40%",height:"auto"}} />
				  </div>
				</Toolbar>
			</AppBar>
			
			<Box mt={13} mb={1} style={{textAlign:"center"}}>						
				<div style={{fontFamily:"Roboto",fontWeight:"100",fontSize:"42px",color:"#003CFA"}}>
					  TERMOS DE USO
				</div>								 	
			</Box>			
			
			<Box style={{...screenStyleDialog}} m={0} styleE={{overflow:"hidden"}}>
			
				<Box mt={0} ml={0} mr={0} style={{height:"100%"}}>
					
					<Box p={5} pt={0} style={{maxHeight:"80%",overflow:"scroll"}}>

						<div style={{flex:1,display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>	
						
							{!termsContent &&
							<>
							  <CircularProgress style={{color:"#333",width:"25px",height:"25px",marginBottom:"10px"}} /> 
							  <h5 style={{color:"#333",marginTop:"5px"}}>Carregando Termos de uso</h5>
							</>
							}
							
							{termsContent &&
							 <div dangerouslySetInnerHTML={{__html: termsContent}} />
							}
							  
						</div>

					</Box>
						
				<Divider style={{marginBottom:"5px"}} />
					
					<Box style={{backgroundColor:"transparent",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}} ml={0} mr={0} mb={5} mt={2} pl={7} pr={7}>	
						
						<Button color="primary" variant="outlined" style={{width:"100%",height:"54px",borderRadius:"10px",fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px",}} onClick={()=>setModal(false)}>
						 Fechar
						</Button>					
						
					</Box>	
 
			
				</Box>				
			
			</Box>

		  </Dialog>
		</div>
		
	);
	
});

const AlertDialog = (props) => {
	
	return (
		 <Dialog
			open={props.dialog}
			onClose={() => props.setDialog(false)} 
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		  >
		  <DialogTitle id="alert-dialog-title">Verifique as informações</DialogTitle>
			<DialogContent>
			  <DialogContentText id="alert-dialog-description">
			  {props.dialogMessage}
			  </DialogContentText>
			</DialogContent>
			<DialogActions>
			  <Button onClick={() => props.setDialog(false)} color="primary" autoFocus>
				OK
			  </Button>
			</DialogActions>			
		  </Dialog>
	  )
	
	
} // end AlertDialog

const ConfirmDialog = (props) => {
	
	return (
		 <Dialog
			open={props.dialogConfirm}
			onClose={() => {props.setDialogConfirm(false); props.setButtonLoading(false)} } 
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		  >
		  <DialogTitle id="alert-dialog-title">Confirme a ação</DialogTitle>
			<DialogContent>
			  <DialogContentText id="alert-dialog-description">
			  Tem certeza que deseja cadastrar o usuário com os dados informados?
			  </DialogContentText>
			</DialogContent>
			<DialogActions>
			  <Button onClick={() => { props.setDialogConfirm(false); props.setButtonLoading(false) } } color="secondary" autoFocus>
				CANCELAR
			  </Button>
			  <Button onClick={() => { props.nextStep(3);props.setDialogConfirm(false) } } color="primary" autoFocus>
				CONFIRMAR
			  </Button>			  
			</DialogActions>			
		  </Dialog>
	  )
	
} // end AlertDialog

/* Extra  Dialog Component START */
export const HelpDialog = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {
		

const backgroundHeaderModal = {
  backgroundImage: "url(" + backgroundHeaderImage + ")",
  backgroundSize:"cover",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"center",
  display:"flex",
  alignItems:"center",
  justifyContent:"center"
}; 		


const screenStyleDialog = {
  alignItems: "center",
  justifyContent: "top",
  width:"100vw",
  minHeight:"100vh",
  display:"flex",
  flexDirection:"column",
  backgroundColor:"#F0F0F0"
}; 
	
// set classes var
// const classes = useStyles()();	

const { 
	innerLoading,
	setInnerLoading,
	setDialogHelp,
	dialogHelp
} = props;	

	return (
	<div>
		  <Dialog fullScreen open={dialogHelp} onClose={()=>setDialogHelp(false)} TransitionComponent={TransitionSlideUp}>
			  
			<AppBar style={{position: 'fixed',height:'80px',...backgroundHeaderModal}}>
			  <Toolbar style={{width:"100%"}}>
				  <div style={{marginLeft: "10px",flex: 1,textAlign:"center",display:"flex",justifyContent:"center",alignItems:"center"		  }}>
					  <img src={logo} alt="Motuum" style={{marginLeft:"auto",marginRight:"auto",width:"40%",height:"auto"}} />
				  </div>
				</Toolbar>
			</AppBar>

			<Box style={{...screenStyleDialog,backgroundColor:"#FFF",paddingLeft:"20px",paddingRight:"20px"}} m={0}>
			
				<Box mt={10} ml={0} mr={0}>
					
					<Box pt={2}>

						<SupportComponent simple="true" screen="register" setDialog={setDialogHelp}  dialog={dialogHelp} />
						
						<Box style={{backgroundColor:"transparent",display:"flex",flexDirection:"row",justifyContent:"center"}} ml={0} mr={0} mb={5} mt={0} pl={7} pr={7}>						
							
							<Button color="primary" variant="outlined" style={{width:"100%",height:"54px",borderRadius:"10px",fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px",}} onClick={()=>setDialogHelp(false)}>
							 Fechar
							</Button>		
								
						</Box>							

					</Box>
			
				</Box>				
			
			</Box>

		  </Dialog>
		</div>
		
	);
	
});

export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(ProfileStep2);
 