import './Launch.css';
import React,{useState,useEffect} from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import * as config from '../Config/api.js';

import {Routes,Route,useLocation} from 'react-router-dom';
import { StreamChat } from 'stream-chat';
import { Chat, Channel, ChannelHeader, MessageInput, MessageList, Thread, Window } from 'stream-chat-react';
import 'stream-chat-react/dist/css/index.css';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import { Box,Backdrop,CircularProgress } from '@mui/material';
import Slide from '@mui/material/Slide';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import backgroundHeaderImage from '../Assets/Images/backgroundHeader.png'; // Tell Webpack this JS file uses this image
import logo from '../Assets/Images/logo_motuum-white.svg'; // Tell Webpack this JS file uses this image

// Import Screens
import SplashScreen from './SplashScreen/MainSplashScreen';
import OnBoarding from './OnBoarding/MainOnBoarding';

import Login from './Login/MainLogin';
	import ForgotPassword from './Login/MainLoginForgotPassword';
	import ForgotPasswordUpdate from './Login/MainLoginForgotPasswordStep2';
	import ForgotPasswordSuccess from './Login/MainLoginForgotPasswordStep3';

import ProfileRegister from './Profile/MainProfile';
import ProfileManage from './Profile/MainProfileManage';
import ProfileManageUpdate from './Profile/MainProfileManageUpdate';
import ProfileManageUpdatePassword from './Profile/MainProfileManageUpdatePassword';

// Load Views
import ProfileRegisterStep0 from './Profile/MainProfileRegisterStep0';
import ProfileRegisterStep1 from './Profile/MainProfileRegisterStep1';
import ProfileRegisterStep2 from './Profile/MainProfileRegisterStep2';
import ProfileRegisterStep3a from './Profile/MainProfileRegisterStep3-activate-Step1';
import ProfileRegisterStep3b from './Profile/MainProfileRegisterStep3-activate-Step2';
import ProfileRegisterStep4 from './Profile/MainProfileRegisterStep4';


import Home from './Home/MainHome';
import HomeActivateStep1 from './Home/MainHome-activate-Step1';
import HomeActivateStep2 from './Home/MainHome-activate-Step2';
import HomeActivateSuccess from './Home/MainHome-activate-success';

import SearchService from './SearchService/MainSearchService';
import SearchServiceView from './SearchService/MainSearchServiceView';

import HireService from './HireService/MainHireService';
import HireServiceSearching from './HireService/MainHireServiceSearching';
import HireServiceDone from './HireService/MainHireServiceDone';

import ScheduledServices from './ScheduledServices/MainScheduledServices';
import ScheduledServicesView from './ScheduledServices/MainScheduledServicesView';

import OngoingServices from './OngoingServices/MainOngoingServices';
import OngoingServicesView from './OngoingServices/MainOngoingServicesView';

import ExecutedServices from './ExecutedServices/MainExecutedServices';
import ExecutedServicesView from './ExecutedServices/MainExecutedServicesView';
import ReportProblemNew from './ExecutedServices/ReportProblem';
import ReportProblemView from './ExecutedServices/ReportProblemView';

import CreditCards from './CreditCards/MainCreditCards';
import Addresses from './Addresses/MainAddresses';

import Support from './Support/MainSupport';
import SupportFaq from './Support/MainSupportFaq';
import SupportFaqByScreen from './Support/MainSupportFaqScreen';
import SupportComplaint from './Support/MainSupportComplaint';
import SupportReportProblemNew from './Support/ReportProblem';
import SupportReportProblemView from './Support/ReportProblemView';
import SupportTicketNew from './Support/Ticket';
import SupportTicketView from './Support/TicketView';

import About from './About/MainAbout';
import Logoff from './Logoff/MainLogoff';

import * as AppActions from '../Store/Actions/App'

// set CSS
const screenStyle = {
  display: "flex",
  alignItems: "middle",
  justifyContent: "center",
  flex:"1"
};

// set CSS
const screenStyleInner = {
  display: "flex",
  alignItems: "middle",
  justifyContent: "center",
  height:"100vh",
  flex:"1",
  display:"flex",
  flexDirection: "column"
};


const Launch = props => {


const { 
	UserData,
} = props;

		
const location = useLocation();	

const [logged,setLogged] = useState(false);	
const [chatClient,setChatClient] = useState(null);


// Run after mount component
useEffect(() => {
	
		// set stream char client
		const client = StreamChat.getInstance(config.STREAMCHAT_KEY);

		// set chat client
		setChatClient(client);

		// Retrieve user chat token from localStorage
		/*var result = localStorage.getItem("@motuum/userData", undefined,true);
		var userData = JSON.parse(result);  		

		
	  // If UserData is set, its logged. Connect stream chat one.
	  if (UserData && userData) {

		(async () => {	
			
			
			try {
				await chatClient.connectUser(
					{
					id: "CustomerUserId"+UserData.id,
					name: UserData.CustomerProfile.completeName,
					image: 'https://ui-avatars.com/api/?name='+UserData.CustomerProfile.firstName+'+'+UserData.CustomerProfile.lastName+'',
					},
					userData.userChatToken,
				);
			} catch(e) {
				console.log('<<<< STREAM CHAT CONNECT ERROR >>>>'+JSON.stringify(e.message,null,2));
			}

		})(); // end transaction async 			
 
	  } // end if*/


	console.log('MOUNTED LAUNCH COMPONENT.....');

  // Runs when UNMOUNT component
  return () => {
    
	console.log('UNMOUNTED LAUNCH COMPONENT.....');
	
	(async () => {
		if (chatClient) {
			await chatClient.disconnectUser();
		}
	})(); // end transaction async	
	
  };
  
}, []); // end useEffect 

		
return (
	 <div className="launch" style={{...screenStyle}}>

			<Routes location={location}>			
			  <Route path="/" element={<SplashScreen />} />
			  <Route path="/home" element={<Home />} />
			  <Route path="/home/activate/step1" element={<HomeActivateStep1 />} />
			  <Route path="/home/activate/step2" element={<HomeActivateStep2 />} />
			  <Route path="/home/activate/success" element={<HomeActivateSuccess />} />
			  <Route path="/onboarding" element={<OnBoarding />} />
			  <Route path="/login" element={<Login />} />
			  <Route path="/forgotpassword" element={<ForgotPassword />} />
			  <Route path="/forgotpassword/updatePassword" element={<ForgotPasswordUpdate />} />
			  <Route path="/forgotpassword/success" element={<ForgotPasswordSuccess />} />
			  <Route path="/register" element={<ProfileRegister />} />
			  <Route path="/manageProfile" element={<ProfileManage />} />
			  <Route path="/manageProfile/update" element={<ProfileManageUpdate />} />
			  <Route path="/manageProfile/updatePassword" element={<ProfileManageUpdatePassword />} />
			  <Route path="/activateProfile" element={<ProfileRegister />} />
			  <Route path="/searchService" element={<SearchService />} />
			  <Route path="/searchService/view/:id" element={<SearchServiceView />} />
			  <Route path="/hireService" element={<HireService />} />
			  <Route path="/hireService/searching" element={<HireServiceSearching />} />
			  <Route path="/hireService/done" element={<HireServiceDone />} />
			  <Route path="/scheduledServices" element={<ScheduledServices />} />
			  <Route path="/scheduledServices/view/:id" element={<ScheduledServicesView />} />
			  <Route path="/ongoingServices" element={<OngoingServices />} />
			  <Route path="/ongoingServices/view/:id" element={<OngoingServicesView />} />
			  <Route path="/executedServices" element={<ExecutedServices />} />
			  <Route path="/executedServices/view/:id" element={<ExecutedServicesView />} />
			  <Route path="/executedServices/complaint/:id" element={<ReportProblemNew />} />
			  <Route path="/executedServices/complaintview/:id" element={<ReportProblemView />} />
			  <Route path="/creditCards" element={<CreditCards />} />
			  <Route path="/addresses" element={<Addresses />} />
			  <Route path="/support" element={<Support />} />
			  <Route path="/support/faq" element={<SupportFaq />} />
			  <Route path="/support/faqByScreen/:id" element={<SupportFaqByScreen />} />
			  <Route path="/support/complaint" element={<SupportReportProblemNew />} />
			  <Route path="/support/complaintview/:id" element={<SupportReportProblemView />} />
			  <Route path="/support/ticket" element={<SupportTicketNew />} />
			  <Route path="/support/ticketview/:id" element={<SupportTicketView />} />
			  <Route path="/about" element={<About />} />
			  <Route path="/logoff" element={<Logoff />} />
			  
			  <Route path="/register/" element={<ProfileRegisterStep0 />} />
			  <Route path="/register/step1" element={<ProfileRegisterStep1 />} />
			  <Route path="/register/step2" element={<ProfileRegisterStep2 />} />
			  <Route path="/register/step3/activate/step1" element={<ProfileRegisterStep3a />} />
			  <Route path="/register/step3/activate/step2" element={<ProfileRegisterStep3b />} />
			  <Route path="/register/step4" element={<ProfileRegisterStep4 />} />			  
			  
			  <Route path="*" element={<SplashScreen />} />
			</Routes>

			<ChatDialog 
				client={chatClient}
			/>	

		</div>
	)
} ;

// Transition Slide for Dialog SLIDE UP	
const TransitionSlideUp = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;  
  });	
	  

  const mapStateToProps = store => ({
	UserData:store.appReducer.userData,
	dialog:store.appReducer.dialog,
	chatCustomerId:store.appReducer.chatCustomerId,
	chatProviderId:store.appReducer.chatProviderId,
	chatChannelId:store.appReducer.chatChannelId,
	chatChannel:store.appReducer.chatChannel
  });
  
  const mapDispatchToProps = dispatch =>
	bindActionCreators({ ...AppActions }, dispatch);  

/* Chat Component */
export const ChatDialog = connect(
	mapStateToProps,
	mapDispatchToProps
)((props) => {
	
	const backgroundHeaderModal = {
	backgroundImage: "url(" + backgroundHeaderImage + ")",
	backgroundSize:"cover",
	backgroundReepeat:"no-repeat",
	backgroundPosition:"center",
	display:"flex",
	alignItems:"center",
	justifyContent:"center"
	}; 			

	const screenStyleDialog = {
		width:"100vw"
	}; 

	const { 
	setDialog,
	dialog,
	UserData,
	chatData,
	chatCustomerId,
	chatProviderId,
	chatChannelId,
	chatChannel,
	setChatChannel,
	client,
	SelectedScheduledServiceOrder
	} = props;

// After mount component
useEffect(() => {

// execute only if this modal
if (dialog.target == 'openChat' && dialog.status) {
	
	(async () => {

	// Retrieve user chat token from localStorage
	var result = localStorage.getItem("@motuum/userData", undefined,true);
	var userData = JSON.parse(result);  		

		// If UserData is set, its logged. Connect stream chat one.
		if (UserData.CustomerProfile && userData && client) {
				
				try {
					await client.connectUser(
						{
						id: "CustomerUserId"+UserData.id,
						name: UserData.CustomerProfile.completeName,
						image: 'https://ui-avatars.com/api/?name='+UserData.CustomerProfile.firstName+'+'+UserData.CustomerProfile.lastName+'',
						},
						userData.userChatToken,
					);
				} catch(e) {
					console.log('<<<< STREAM CHAT CONNECT ERROR >>>>'+JSON.stringify(e.message,null,2));
				}

		} // end if		

		try {
			// Set channel...
			const channelValue = await client.channel('messaging', chatChannelId, {
			name: 'Chat com profissional',
			members: [chatCustomerId,chatProviderId],
			ServiceOrderId: SelectedScheduledServiceOrder ? SelectedScheduledServiceOrder.id : null,
			});

			setChatChannel(channelValue);
		} catch(e) {
			console.log('Set channel error: '+JSON.stringify(e.message,null,2));
		}

	})(); // end transaction async	


  // Runs when UNMOUNT component
  return () => {
    
	console.log('UNMOUNTED LAUNCH DIALOG COMPONENT.....');
	
	setChatChannel(null); 

	(async () => {
		if (client) {
			await client.disconnectUser();
		}
	})(); // end transaction async	
	
  };	

} // end if 	 
}, [dialog]);

if (!client || !chatChannel) {
	return null;
} // end if

return (
<div>
	  <Dialog fullScreen open={dialog.target == 'openChat' && dialog.status} onClose={()=>setDialog('openChat',false)} TransitionComponent={TransitionSlideUp}>
	  
		<AppBar style={{position: 'fixed',height:'80px',...backgroundHeaderModal	}}>		
		  <Toolbar style={{width:"100%"}}>
			  <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => setDialog('openChat',false)}>
				<CloseIcon style={{width:"30px",height:"auto"}} />
			  </IconButton>				  
			  <div style={{marginLeft: "10px",flex: 1,textAlign:"center",display:"flex",justifyContent:"center",alignItems:"center"}}>
				<img src={logo} alt="Motuum" style={{marginLeft:"auto",marginRight:"auto",width:"50%",height:"auto"}} />
			  </div>
			  <IconButton disabled edge="end" color="inherit" style={{minWidth:"54px"}} />
		 </Toolbar>
		
		</AppBar>

		<Box style={{...screenStyleDialog,paddingTop:"80px"}} m={0}>
		
		{chatChannel &&
		<Chat client={client} >
				<Channel channel={chatChannel}>
				  <Window>
					<MessageList />
					<MessageInput />
				  </Window>
				  <Thread />
				</Channel>
		</Chat>
		}
		
		{!chatChannel &&
		<div>Carregando...</div>
		}
		
		</Box>

	  </Dialog>
	</div>
	
);

});

  
  export default connect(
		  mapStateToProps,
		  mapDispatchToProps
	  )(Launch);
	
