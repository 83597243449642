import * as types from '../actionTypes';

const initialState = { 
	buttonLoading: false,
	registerDataStep0:{},
	registerDataStep1:{},
	registerDataStep2:{},
	registerDataStep3:{},
	UserData:{}
}
 
export const registerReducer = (state = initialState, action) => {
		
    switch (action.type) {
		case types.SET_REGISTER_STEP0:
			return { ...state,registerDataStep0: action.payload }			
        case types.SET_REGISTER_STEP1:
			return { ...state,registerDataStep1: action.payload }			
		case types.SET_REGISTER_STEP2:
			return { ...state,registerDataStep2: action.payload }
		case types.SET_REGISTER_STEP3:
			return { ...state,registerDataStep3: action.payload }					
        case types.BUTTON_LOADING:
            return { ...state, buttonLoading: action.payload }	
        case types.SET_DIALOG:
            return { ...state, dialog: action.payload }	
		case types.SET_USERDATA:
			return { ...state, UserData: action.payload }				
        default:
            return state
    }
}