import './UserDrawer.css';
import React,{useState,useEffect,Fragment} from 'react';

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import {Link} from 'react-router-dom';

import moment from 'moment';
import 'moment/locale/pt-br';

import { Box,Button,IconButton } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import SearchIcon from '@mui/icons-material/Search';
import EventIcon from '@mui/icons-material/Event';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import HistoryIcon from '@mui/icons-material/History';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ContactsIcon from '@mui/icons-material/Contacts';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import InfoIcon from '@mui/icons-material/Info';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from '@mui/icons-material/Warning';
import CloseIcon from '@mui/icons-material/Close'
import iconOngoingBlue from '../../Assets/Images/iconOngoing-blue.svg'; 
import addressIconBlue from '../../Assets/Images/addressIcon-blue.svg'; 
import iconHelpBlue from '../../Assets/Images/iconHelp-blue.svg'; 
import motuumIconBlue from '../../Assets/Images/motuumIcon-blue.svg'; 
import logoffIconBlue from '../../Assets/Images/logoffIcon-blue.svg'; 
import selectIconBlue from '../../Assets/Images/selectIcon-blue.svg'; 


import logo from '../../Assets/Images/logo_motuum.svg'; // Tell Webpack this JS file uses this image
import backgroundHeaderImage from '../../Assets/Images/backgroundHeader.png'; // Tell Webpack this JS file uses this image

// Load Actions
import * as AppActions from '../../Store/Actions/App'


const backgroundHeaderModal = {
  backgroundImage: "url(" + backgroundHeaderImage + ")",
  backgroundSize:"cover",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"center",
  display:"flex",
  alignItems:"center",
  justifyContent:"center",
  height:"200px"
}; 	


const UserDrawer = props => {
	
// Set direction dinamically	
const drawerDirection = props.direction;	
	
// Set var from props
const {
	toggleDrawer,
	UserData	
} = props;
		
const [completeName, setCompleteName] = React.useState(UserData.completeName ? UserData.completeName:"");
const [validated,setValidated] = React.useState(UserData.activationDate ? 1 : 0);


// Run after mount component
useEffect(() => {
	
		// Retrieve User Data Obj. 
	var result = localStorage.getItem("@motuum/userData", undefined,true);
	var userData = JSON.parse(result);  
		
	// Propagate data
	setCompleteName(userData.completeName ? userData.completeName:"");
	setValidated(UserData.activationDate ? 1 : 0);
  
}, []); // end useEffect		 
		 
	return (
	<Fragment>
			
		<Box m={0} style={{backgroundColor:"#FFF",minHeight:"100vh"}}>
		
          <IconButton style={{position:"fixed",left:"20px",top:"5px",color:"#FFFFFF"}} edge="start" color="inherit" aria-label="menu" onClick={() => toggleDrawer(false)}>
            <CloseIcon style={{width:"30px",height:"auto"}} />
          </IconButton>		
			
				<Box className="boxUser" m={0} p={0} style={{color:"#FFFFFF",display:"flex",flexDirection:"column",alignItems:"center",...backgroundHeaderModal}}>

					<Box style={{marginTop:"4px",backgroundColor:"transparent",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}} >
					 
						<Box style={{backgroundColor:"transparent",display:"flex",alignItems:"center",flexDirection:"row"}}>
							
							<Avatar variant="square" style={{margin:"5px",marginTop:"17px",width:"60px",height:"60px",borderRadius:"50px",backgroundColor:"#FFFFFF",color:"#666"}} />	
							
						</Box>
						
						<Box style={{backgroundColor:"transparent",display:"flex",flexDirection:"column",margin:"0px",marginLeft:0,marginBottom:0}} >
							
							<div style={{flex:1,display:"flex",flexDirection:"column",margin:"5px"}}>
								
								<div style={{textAlign:"center",flex:1,fontFamily:"Roboto",fontSize:"16px",color:"#FFFFFF"}}>Olá, <span style={{fontFamily:"Roboto",fontWeight:"bold",fontSize:"16px"}}>{UserData.completeName ? UserData.completeName:completeName ? completeName:""}</span></div>
								<div style={{textAlign:"center",flex:1,color:"#FFFFFF",fontFamily:"Roboto",fontSize:"12px"}}> <small> último acesso em {UserData.lastSeen ? moment(UserData.lastSeen).format("DD/MM/YYYY") : "(sem data)"}</small> </div>
							
							</div>
							
						</Box>

					 
					</Box>
					
					<Box style={{marginTop:"10px",flex:1,display:"flex",alignItems:"flex-start",justifyContent:"center",backgroundColor:"transparent"}} >
					 
					 	<Button variant="outlined" size="small" style={{width:"110px",borderRadius:"15px",color:"#FFFFFF",borderColor:"#FFFFFF"}} component={Link} to="/manageProfile" onClick={() => toggleDrawer(false)}>
							Editar
						</Button>
						
					</Box>	
			{
    
  }
					
				</Box>
			
				<Box m={0} pt={0} p={0} style={{marginLeft:"20px",marginTop:"15px",backgroundColor:"#FFFFFF"}}>
				
					<List component="nav" aria-label="Menu Usuário" style={{width:"80vw",lineHeight:"22px",paddingTop:"5px",maxWidth: "100vw",backgroundColor: "#FFF",}}>
						
						<ListItem className="drawerItem" component={Link} to="/searchService" onClick={() => toggleDrawer(false)}>
						  <ListItemIcon>
							<img src={selectIconBlue}  style={{width:"30px",height:"auto",marginLeft:"2px"}} />	
						  </ListItemIcon>
						  <ListItemText  style={{color:"#636466",fontFamily:"Roboto",fontSize:"14px"}} primary="Solicitar serviço" />
						</ListItem>
						
						<ListItem className="drawerItem" component={Link} to="/scheduledServices" onClick={() => toggleDrawer(false)}>
						  <ListItemIcon>
							<EventIcon style={{width:"30px",height:"auto",color:"#003CFA"}} />
						  </ListItemIcon>
						  <ListItemText style={{color:"#636466",fontFamily:"Roboto",fontSize:"14px"}} primary="Serviços agendados" />
						</ListItem>
						
						<ListItem className="drawerItem" component={Link} to="/ongoingServices" onClick={() => toggleDrawer(false)}>
						  <ListItemIcon>
							<img src={iconOngoingBlue}  style={{width:"30px",height:"auto",color:"#003CFA"}} />	
						  </ListItemIcon>
						  <ListItemText style={{color:"#636466",fontFamily:"Roboto",fontSize:"14px"}} primary="Serviços em andamento" />
						</ListItem>	

						<ListItem className="drawerItem" component={Link} to="/executedServices" onClick={() => toggleDrawer(false)}>
						  <ListItemIcon>
							<HistoryIcon style={{width:"30px",height:"auto",color:"#003CFA"}} />
						  </ListItemIcon>
						  <ListItemText style={{color:"#636466",fontFamily:"Roboto",fontSize:"14px"}} primary="Serviços realizados" />
						</ListItem>		
						
						<ListItem className="drawerItem" component={Link} to="/creditCards" onClick={() => toggleDrawer(false)}>
						  <ListItemIcon>
							<CreditCardIcon style={{width:"30px",height:"auto",color:"#003CFA"}} />
						  </ListItemIcon>
						  <ListItemText style={{color:"#636466",fontFamily:"Roboto",fontSize:"14px"}} primary="Cartões de Crédito" />
						</ListItem>	

						<ListItem className="drawerItem" component={Link} to="/addresses" onClick={() => toggleDrawer(false)}>
						  <ListItemIcon>
							<img src={addressIconBlue}  style={{width:"30px",height:"auto",color:"#003CFA"}} />	
						  </ListItemIcon>
						  <ListItemText style={{color:"#636466",fontFamily:"Roboto",fontSize:"14px"}} primary="Endereços cadastrados" />
						</ListItem>	
												
						<ListItem className="drawerItem" component={Link} to="/support" onClick={() => toggleDrawer(false)}>
						  <ListItemIcon>
							<img src={iconHelpBlue}  style={{width:"30px",height:"auto",color:"#003CFA"}} />	
						  </ListItemIcon>
						  <ListItemText style={{color:"#636466",fontFamily:"Roboto",fontSize:"14px"}} primary="Ajuda" />
						</ListItem>	

						<ListItem className="drawerItem" component={Link} to="/about" onClick={() => toggleDrawer(false)}>
						  <ListItemIcon>
							<img src={motuumIconBlue}  style={{width:"26px",height:"auto",marginLeft:"2px"}} />	
						  </ListItemIcon>
						  <ListItemText style={{color:"#636466",fontFamily:"Roboto",fontSize:"14px"}} primary="Sobre a Motuum" />
						</ListItem>		

						<ListItem className="drawerItem" component={Link} to="/logoff" onClick={() => toggleDrawer(false)}>
						  <ListItemIcon>
							<img src={logoffIconBlue}  style={{width:"30px",height:"auto",marginLeft:"2px"}} />	
						  </ListItemIcon>
						  <ListItemText style={{color:"#636466",fontFamily:"Roboto",fontSize:"14px"}} primary="Sair" />
						</ListItem>		
						
					</List>		
			
				
				</Box>
				
				<Divider orientation="horizontal" variant="middle" style={{marginBottom:"20px",marginTop:"15px",backgroundColor:"#003CFA"}}  />
				
				<Box m={0} p={0} style={{width:"100%",display:"flex",flexDirection:"column",alignItems:"center"}}>
									
					<div>
						<img src={logo} atl="Logomarca Motuum" style={{height:"2vh",width:"auto",marginLeft:"5px"}} />			
					</div>
					<small style={{textAlign:"center",color:"#636466",fontFamily:"Roboto",fontSize:"9px"}}> Versão 0.94 </small>
					
				</Box>		

		  </Box>
	
		
	</Fragment>
)};


// Bind States to Props
const mapStateToProps = store => ({
 UserData:store.registerReducer.UserData
});

// Bind Actions to Propos
const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AppActions }, dispatch);

export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(UserDrawer);

