import './MainProfile.css';
import React,{useState,useEffect,Fragment} from 'react';
import {Link,useNavigate} from 'react-router-dom';

import PropTypes from 'prop-types';
import { CSSTransition } from "react-transition-group";

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

import InputMask from "react-input-mask";

// Material UI Import
import { Box,CircularProgress,Typography,Button,IconButton,TextField,FormControl  } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';

// import LiveChat from 'react-livechat'

import Slide from '@mui/material/Slide';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';


import {useComponentWillMount} from '../../Utils/customFunctions';
import Header from '../../Components/Layout/Header';
import If from '../../Components/Common/If';
import {SupportComponent} from '../Support/SupportComponentHeader';

import logo from '../../Assets/Images/logo_motuum-white.svg'; // Tell Webpack this JS file uses this image
import backgroundHexagon from '../../Assets/Images/backgroundHexagon.png'; // Tell Webpack this JS file uses this image
import backgroundHeaderImage from '../../Assets/Images/backgroundHeader.png'; // Tell Webpack this JS file uses this image
import helpIcon from '../../Assets/Images/help-icon-blue.svg'; // Tell Webpack this JS file uses this image

// Load Actions
import * as AppActions from '../../Store/Actions/App'
import * as RegisterActions from '../../Store/Actions/Register'

const ProfileStep3 = props => {


const screenStyle = {
  alignItems: "stretch",
  justifyContent: "top",
  width:"100vw",
  minHeight:"100vh",
  display:"flex",
  flexDirection:"column",
  backgroundImage: "url(" + backgroundHexagon + ")",
  backgroundSize:"cover",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"center"
}; 

const screenStyleLoading = {
  alignItems: "middle",
  justifyContent: "center",
  width:"100vw",
  height:"100vh",
  display: "flex",
  flexDirection: "column"
}; 

const styles = {
  indicator: {
    backgroundColor: '#A255A0',
  },
};

// Set functions
const handleChange = (event, newValue) => {
	
	setValue(newValue);

	if (newValue == 0) {
		setLoginType("email");
	} // end if
	else {
		setLoginType("mobilePhone");
	} // end else

};


// Set TABPANEL
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
	  hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

// set classes var
// const classes = useStyles()();


// set vars from props
// Remember to add those who come from Redux
const { 
	registerDataStep0,
	registerDataStep1,
	registerDataStep2,
	registerDataStep3,
	innerLoading,
	setInnerLoading,
	buttonLoading,
	setButtonLoading,
	setRegister,
	activateProfileGenerate,
	activateProfileConfirm,
	UserData
} = props;


// set Use of history for PUSH 
let navigate = useNavigate();

// Set internal state
const [contentVisible,setContentState] = useState(false);
const [loadingMessage,setLoadingMessage] = useState("Carregando...");
const [value, setValue] = useState(0); // state for tab

const [dialog, setDialog] = useState(false); // state for tab
const [dialogMessage, setDialogMessage] = useState(""); // state for tab
const [snack,setSnack] = useState(false); // state for tab
const [snackMessage, setSnackMessage] = useState(""); // state for tab

const [email, setEmail] = useState(registerDataStep1.email ? registerDataStep1.email : ""); // state for input fields
const [mobilePhone, setMobilePhone] = useState(registerDataStep1.mobilePhone ? registerDataStep1.mobilePhone : ""); // state for input fields
const [firstName, setFirstName] = React.useState(UserData.firstName ? UserData.firstName:"");

const [dialogHelp, setDialogHelp] = useState(false); // state for tab

const [codeView1, setCodeView1] = React.useState(true);
const [codeView2, setCodeView2] = React.useState(false);
const [code, setCode] = React.useState("");

const [sendingCode, setSendingCode] = React.useState(false);
const [dialogConfirm, setDialogConfirm] = useState(false); // state for tab
const [loginType, setLoginType] = useState("email"); // state for tab


// Confirm email change
const sendCodeCheck = (targetLoginType,value) => {
			
	if (targetLoginType == "email") {
		
		// set scope email
		setEmail(value.email);
	
		// Check if Email is filled
		if (!value.email) {		
			setDialogMessage("Digite o email para prosseguir.");
			setDialog(true);
			setButtonLoading(false);	
			setSendingCode(false);			
			return false;		
		} // end if

		// Check if Email is valid
		let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
		if (reg.test(value.email) === false) {
			setDialogMessage("Digite um email válido para prosseguir.");
			setDialog(true);
			setButtonLoading(false);	
			setSendingCode(false);				
			return false;
		} // end if	
		
	} // end if
	else {
		
		// set scope email
		setMobilePhone(value.mobilePhone);
		setEmail(value.mobilePhone);		

		// set only numbers for mobilePhone
		const mobileNumberClean = value.mobilePhone.replace(/\D/g,"");
		
		if (!value.mobilePhone) {		
			setDialogMessage("Digite o telefone para prosseguir.");
			setDialog(true);
			setButtonLoading(false);	
			setSendingCode(false);			
			return false;		
		} // end if		
		
		// Min 10 numbers 
		if (mobileNumberClean.length < 10) {
			setDialogMessage("Digite um Telefone celular válido para prosseguir.");
			setDialog(true);
			setButtonLoading(false);				
			return false;
		} // end if	

		// Check if DDD is valid
		const dddArray = [11,12,13,14,15,16,17,18,19,21,22,24,27,28,31,32,33,34,35,37,38,41,42,43,44,45,46,47,48,49,51,53,54,55,61,62,63,64,65,66,67,68,69,71,73,74,75,77,79,81,82,83,84,85,86,87,88,89,91,92,93,94,95,96,97,98,99];
		const dddPart = parseInt(mobileNumberClean.substr(0,2));	

		if (!dddArray.includes(dddPart)) {
			setDialogMessage("Digite um DDD válido para prosseguir.");
			setDialog(true);
			setButtonLoading(false);				
			return false;
		} // end if		

		// If number has 11 digits, Check if number star with 9. If not, fail.
		const firstElevenDigitPart = parseInt(mobileNumberClean.substr(2,1));
		if (firstElevenDigitPart != 9 && mobileNumberClean.length == 11) {
			setDialogMessage("Digite um telefone válido para prosseguir. Utilize apenas números. Lembre-se do DDD com 2 dígitos e o telefone em seguida.");
			setDialog(true);
			setButtonLoading(false);				
			return false;
		} // end if		
		
		// If number has 10 digits, check if number start with 6, 7, 8 or 9
		const tenDigitsValid = [6,7,8,9];
		const firstDigitPart = parseInt(mobileNumberClean.substr(2,1));	
		if (!tenDigitsValid.includes(firstDigitPart)) {
			setDialogMessage("Digite um telefone válido para prosseguir. Utilize apenas números. Lembre-se do DDD com 2 dígitos e o telefone em seguida.");
			setDialog(true);
			setButtonLoading(false);				
			return false;
		} // end if		
		
	}		

	// Check if email or mobilePhone changed...
	if ( (value.email != registerDataStep1.email && targetLoginType == "email") || (value.mobilePhone != registerDataStep1.mobilePhone && targetLoginType == "mobilePhone") ) {
		setDialogConfirm(true);	
	} // end if
	else {
		sendCode();
	} // end else
	
} // end const

const sendCode = () => {
	 	  
	setSendingCode(true);
	setCode("");

	// set data
	var data = {
		email:email,
		mobilePhone:mobilePhone,
		loginType:loginType
	};
	 	
	// Save email on store
	setRegister(data,3);
	
	//[ DISPATCH ACTION ] checkEmail(NEXT STEP, DATA ,navigation OBJ);
	var props = {
		navigate:navigate,
		setDialogMessage:setDialogMessage,
		setDialog:setDialog	,
		setSnack:setSnack,
		setSnackMessage:setSnackMessage,
		setCodeView1:setCodeView1,
		setCodeView2:setCodeView2,
		setSendingCode:setSendingCode	
	}		
	
	// Activate Profile Generate
	activateProfileGenerate(data,props);
	
} // end


const activateProfile = () => {
	
	setButtonLoading(true);
	setSendingCode(false);
		
	// Check if Required fields are Filled
	if (!code) {	
		setButtonLoading(false);	
		setDialogMessage('Digite o código recebido por email ou celular para prosseguir.');	
		setDialog(true);		
		return;
	} // end if	

	if (code.length < 6) {	
		setButtonLoading(false);	
		setDialogMessage('O código recebido por email ou celular deve ter 6 dígitos.');
		setDialog(true);		
		return;
	} // end if		
	
	
	// set data
	var data = { 
		code:code,
		email:email,
		originalEmail:registerDataStep1.email
	};
	
	var props = {
		navigate:navigate,
		setDialogMessage:setDialogMessage,
		setDialog:setDialog	,
		setSnack:setSnack,
		setSnackMessage:setSnackMessage,
		setCodeView1:setCodeView1,
		setCodeView2:setCodeView2,
		setSendingCode:setSendingCode	
	}		
	// Activate Profile Generate
	activateProfileConfirm(data,props);
	
} // end


const editEmail = () => {	
		setCodeView1(true);
		setCodeView2(false);		
} // end


// Run after mount component
useEffect(() => {
	
 // Avoid user to go back on browser or hardware android butto
 /* window.navigateState(null, document.title, window.location.href);
  window.addEventListener('popstate', function(event) {
    window.navigateState(null, document.title, window.location.href);
  });*/
  
	// Retrieve User Data Obj. 
	var result = localStorage.getItem("@motuum/userData", undefined,true);
	var userData = JSON.parse(result);  
	
	// Propagate firstName
	if (userData) {
		setFirstName(userData.firstName);
	} // end if

	  // If token dont exist, redirect to onboarding or register
	  if (userData) {
		  
		  if (userData.userStatus > 0) {
			  navigate("/home");
			  return;
		  } // end if  	
		  
	  } // end if 
  
  // Set Backdrop Loading over interface
  //setInnerLoading(true); 
   
  // Show content after load for animation
 // setContentState(true);
 
 // Set Content Loading ON
 //setInnerLoading(true);
 setContentState(true);
 
 // Simulate Delay
 setTimeout(function() {
	 
	  // Set Content Loading OFF
	  //setInnerLoading(false);
	  
	  // Set Content Ready to show
	  //setContentState(true);
	
 },3000);

  // set scroll to top
  window.scrollTo(0, 1);
    
  // Runs when UNMOUNT component
  return () => {
    console.log("Desmontou o componente MainHome...");
  };
  
}, []); // end useEffect


// Will Mount Component HOOK
useComponentWillMount(() => {
	
    // Set session of app
  var sessionCheck = sessionStorage.getItem('@motuum/appLaunched');
  
  // if session is not created, redirect to bootstrap. (avoid direct access using url)
  if (!sessionCheck) {
	  navigate("/");
  } // end if	
      
}, []); // end useComponentWillMount

	return (
	<Fragment>
			
			<Header title="Cadastrar Perfil" buttons={{support:false,menu:false,close:false,back:false}} /> 
			
			<CSSTransition in={contentVisible} timeout={1000} classNames="fade"	unmountOnExit>	
			
				<Box className="mainProfile" style={{...screenStyle}} m={0}>
		
						<Box mt={14} mb={1}>						
							<div style={{fontFamily:"Roboto",fontWeight:"100",fontSize:"42px",color:"#003CFA"}}>
								  ATIVAR PERFIL
							</div>								 	
						</Box>	

						<Box style={{marginBottom:"20px",backgroundColor:"transparent",alignItems:"center",display:"flex",flexDirection:"column",paddingLeft:"45px",paddingRight:"45px"}} mt={1} >
								
								
							  { codeView1 &&
							  <>
								<div style={{fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px",color:"#636466",lineHeight:"22px"}}><span style={{fontFamily:"Roboto",fontWeight:"bold",fontSize:"16px",color:"#003CFA"}}>{UserData.firstName ? UserData.firstName+", a":firstName ? firstName+", a":"A"}tive seu perfil</span>. Para solicitar profissionais, é necessário que ative o seu perfil através da confirmação do seu email ou telefone.</div>
							  </>
							 }							  
							 
							 { codeView2 &&
							  <>
								<div style={{fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px",color:"#636466",lineHeight:"22px"}}><div style={{fontFamily:"Roboto",fontWeight:"bold",fontSize:"16px",color:"#003CFA"}}>Código enviado com sucesso!</div> Aguarde alguns segundos para receber. Verifique sua caixa de e-mail ou mensagens.</div>
							  </>
							 }							 
								
						</Box>
						
						<Box style={{backgroundColor:"transparent",display:"flex",alignItems:"top",paddingLeft:"45px",paddingRight:"45px"}} pb={15}>	
								
							<Box style={{flexGrow: 1,backgroundColor: "transparent" }} style={{flex:1}}>
						
							  { codeView1 &&
							  <>
									<FormEmail
										sendingCode={sendingCode}
										email={email}
										mobilePhone={mobilePhone}
										sendCodeCheck={sendCodeCheck}
										setLoginType={setLoginType}
									/>

							  </>
							 }							  
							 
							 
							 { codeView2 &&
									<>
									<div>
											<TextField
											  label="Email ou Celular"
											  style={{ margin: 10,marginLeft:0,marginRight:0,backgroundColor:"#fff",width:"100%"}}
											  placeholder="Informe o email ou telefone cadastrado"
											  required
											  disabled={true}
											  variant="outlined"
											  type="email"
											  value={email}
											  onChange={(event) => {
												  setEmail(event.target.value)
											  }}	
											/>
												
								  </div>

									<div>							  

										<TextField
										  id="verificationCode"
										  label="Digite os 6 números recebidos"
										  style={{ margin: 10,marginLeft:0,marginRight:0,backgroundColor:"#fff",width:"100%"}}
										  placeholder="Digite os 6 números recebidos"
										  required
										  variant="outlined"
										  inputProps={{ maxLength: 6 }}
											type="text"
											value={code}
											onChange={(event) => {
											  setCode(event.target.value)
											}}										  
										 />

									</div>
							  
									<div style={{display:"flex",flexDirection:"row"}}>
							
									
										<Button color="secondary" style={{width:"38%",height:"54px",marginRight:"20px",marginTop:"10px",backgroundColor:"#D8D8D8",color:"#636466",borderRadius:"10px",fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px"}} component={Link} to="/register/step4">DEPOIS</Button>										
										<Button variant="contained" color="primary" style={{width:"55%",marginTop:"10px",borderRadius:"10px",fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px"}} onClick={() => activateProfile() }>
											{ !buttonLoading &&
											   <div>Ativar Perfil</div>
											}
											
											{ buttonLoading &&
											   <CircularProgress size={30} color="inherit" />
											}	
										</Button>										
										
										
									</div>	
									
									<div style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>	
										<div style={{marginRight:"30px",marginTop:"10px",display:"flex",flexDirection:"column"}}>	
										
										  { loginType == "mobilePhone" &&
											 <Button color="secondary" style={{textTransform:"none",marginTop:"10px",marginBottom:"0px",fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px"}} onClick={() => editEmail() }>Alterar Telefone</Button>
										  }

										  { loginType == "email" &&
											 <Button color="secondary" style={{textTransform:"none",marginTop:"10px",marginBottom:"0px",fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px"}} onClick={() => editEmail() }>Alterar Email</Button>
										  }
																				
										</div>

										<div style={{marginTop:"10px",display:"flex",flexDirection:"column"}}>
											
											<Button color="secondary" style={{textTransform:"none",marginTop:"10px",marginBottom:"0px",fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px",color:"#003CFA"}} onClick={() => sendCode() }>
												{ !sendingCode &&
												   <div>Reenviar código</div>
												}
												
												{ sendingCode &&
												   <CircularProgress size={30} color="inherit" />
												}	
											</Button>										
											
										</div>
									</div>
								</>
							 }
							 
							  
							</Box>
							
								
						</Box>
						

						<Box style={{backgroundColor:"transparent",display:"flex",width:"100%",alignItems:"center",position:"fixed",bottom:0,height:"80px",backgroundColor:"#ffffff",boxShadow: "0px -10px 15px 0px #0000001A"}} onClick={()=>setDialogHelp(true)}>		

							<Box style={{flex:1,display:"flex",flexDirection:"row",justifyContent:"space-around"}}>
									
								<Box style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>						
										
											<div style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
												<img src={helpIcon} alt="Ajuda" style={{width:"25px",height:"auto"}} />
											</div>
										  
											<div style={{marginLeft:"5px",paddingTop:"3px",fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px",color:"#003cfa",}}>
											  Ajuda
											</div>	
											
						
								</Box>	

							</Box>								
								
						</Box>								
						
								
				</Box>
			
			</CSSTransition> 
			
			{/*<LiveChat license={13444314} />*/}			
			
			<HelpDialog
				dialogHelp={dialogHelp}
				setDialogHelp={setDialogHelp}
			/>						
						
			
			<AlertDialog 
				dialog={dialog}
				setDialog={setDialog}
				dialogMessage={dialogMessage}
			/>
			
			<ConfirmDialog 
				dialogConfirm={dialogConfirm}
				setDialogConfirm={setDialogConfirm}
				loginType={loginType}
				sendCode={sendCode}
			/>			

			<SnackNotification 
				snack={snack}
				setSnack={setSnack}
				snackMessage={snackMessage}
			/>
			
				
	</Fragment>
)};

const FormEmail = (props) => {
	
const [email, setEmail] = useState(props.email); // state for input fields

const sendCode = () => {
		
	// cleanPhoneMask
	var value = email.replace("(","").replace(")","").replace("-","").replace(" ","");

	// Check if string only contains numbers
	var isNum = /^\d+$/.test(value);

	// if yes, its a mobilePhone Check integrity. 
	if (isNum) {		
		props.setLoginType("mobilePhone");
		var loginTypeFlag = "mobilePhone";
	} // end if
	// If no, its an email
	else {
		props.setLoginType("email");
		var loginTypeFlag = "email";
	} // end else


    // Send proper login and values
	if (loginTypeFlag == 'email') {
		props.sendCodeCheck("email",{email:email})
	} // end if
	else {
		props.sendCodeCheck("mobilePhone",{mobilePhone:email})
	} // end else	
	

} // end sendCode

	
	return (	
		<form id="formLoginEmail"  noValidate autoComplete="off">

			<TextField
			  id="loginEmail"
			  label="Email ou Celular"
			  style={{ margin: 10,marginLeft:0,marginRight:0,backgroundColor:"#fff",width:"100%"}}
			  placeholder="Informe o email ou telefone"
			  required
			  variant="outlined"
			  type="email"
			  value={email}
			  onChange={(event) => {
				  setEmail(event.target.value)
			  }}									  
			/>
			
			<div style={{display:"flex",flexDiredction:"row",marginTop:"10px"}}>
			
				<Button color="secondary" style={{width:"38%",height:"54px",marginRight:"20px",marginTop:"10px",backgroundColor:"#D8D8D8",color:"#636466",borderRadius:"10px",fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px",}} component={Link} to="/register/step4">DEPOIS</Button>
				
				<Button variant="contained"  color="primary" size="large" style={{width:"58%",height:"54px",marginTop:"10px",backgroundColor:"#555555",color:"#FFF",borderRadius:"10px",fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px"}} onClick={() => sendCode() }>
					{ !props.sendingCode &&
					   <div>Enviar código</div>
					}
					
					{ props.sendingCode &&
					   <CircularProgress size={30} color="inherit" />
					}	
				</Button>

			</div>
		  
		</form>	
	);
	
	
} // end export const

const FormMobilePhone = (props) => {
	
	const [mobilePhone, setMobilePhone] = useState(props.mobilePhone); // state for input fields
	
	return (	
		<form id="formLoginPhone" noValidate autoComplete="off">

			<InputMask
            mask="(99) 99999-9999"
            value={mobilePhone}
			  onChange={(event) => {
				  setMobilePhone(event.target.value)
			  }}
          >
		  {(inputProps) => (
			<TextField
			  label="Telefone Celular"
			  style={{ margin: 10,marginLeft:0,marginRight:0,backgroundColor:"#fff",width:"94%"}}
			  placeholder="Telefone no formato (XX) 9XXXX-XXXX"
			  required
			  size="small"
			  variant="outlined"
			 />
		  )}
        </InputMask>
										
			<div style={{display:"flex",flexDiredction:"row"}}>
			
				<Button color="secondary" style={{width:"45%",height:"48px",marginTop:"10px"}} component={Link} to="/register/step4">Ativar depois</Button>

				<Button variant="contained"  color="primary" size="large" style={{width:"55%",height:"48px",marginTop:"10px",backgroundColor:"#555555",color:"#FFF"}} onClick={() => props.sendCodeCheck("mobilePhone",{mobilePhone:mobilePhone})}>
					{ !props.sendingCode &&
					   <div>Enviar código</div>
					}
					
					{ props.sendingCode &&
					   <CircularProgress size={30} color="inherit" />
					}	
				</Button>

			</div>
			
		  
		</form>	
	);
	
} // end export const


const AlertDialog = (props) => {
	
	return (
		 <Dialog
			open={props.dialog}
			onClose={() => props.setDialog(false)} 
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		  >
		  <DialogTitle id="alert-dialog-title">Verifique as informações</DialogTitle>
			<DialogContent>
			  <DialogContentText id="alert-dialog-description">
			  {props.dialogMessage}
			  </DialogContentText>
			</DialogContent>
			<DialogActions>
			  <Button onClick={() => props.setDialog(false)} color="primary" autoFocus>
				OK
			  </Button>
			</DialogActions>			
		  </Dialog>
	  )
	
	
} // end AlertDialog

const ConfirmDialog = (props) => {
	
	return (
		 <Dialog
			open={props.dialogConfirm}
			onClose={() => {props.setDialogConfirm(false); } } 
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		  >
		  <DialogTitle id="alert-dialog-title">Confirme a ação</DialogTitle>
			<DialogContent>
			  <DialogContentText id="alert-dialog-description">		
			  
			  { props.loginType == "email" &&
			   <span>Você alterou seu email do cadastro que foi realizado. Ao finalizar a ativação seu email do cadastro será atualizado. Confirma?</span>
			  }
			  
			  { props.loginType == "mobilePhone" &&
				<span>Você alterou seu telefone do cadastro que foi realizado. Ao finalizar a ativação seu email do cadastro será atualizado. Confirma?</span>
			  }			  
			  
			  </DialogContentText>
			</DialogContent>
			<DialogActions>
			  <Button onClick={() => { props.setDialogConfirm(false); } } color="secondary" autoFocus>
				CANCELAR
			  </Button>
			  <Button onClick={() => { props.sendCode();props.setDialogConfirm(false) } } color="primary" autoFocus>
				CONFIRMAR
			  </Button>			  
			</DialogActions>			
		  </Dialog>
	  )
	
} // end AlertDialog

const SnackNotification = (props) => {
	
	//props.snackMessage = "Código enviado com sucesso! Aguarde alguns segundos para receber...";

	return (
		<Snackbar
			anchorOrigin={{
			  vertical: 'bottom',
			  horizontal: 'left',
			}}
			open={props.snack}
			autoHideDuration={4000}
			onClose={() => props.setSnack(false)}
		  >
		    <div style={{borderRadius:10,borderWidth:"1px",borderColor:"#222",padding:10,display:"flex",flexDirection:"row",alignItems:"center",flex:1,backgroundColor:"#666"}}>
				
				<div style={{flex:6,fontSize:"14px",textAlign:"left",color:"#fff"}}>{props.snackMessage}</div>
				
				<IconButton style={{flex:1,color:"#fff"}} size="small" aria-label="close" color="inherit" onClick={() => props.setSnack(false)}>
				  <CloseIcon fontSize="small" />
				</IconButton>
			  			
			</div>
		  </Snackbar>
	  )	
	
} // end AlertDialog

 
 
const mapStateToProps = store => ({
  innerLoading:store.appReducer.innerLoading,
  buttonLoading:store.registerReducer.buttonLoading,
  registerDataStep0:store.registerReducer.registerDataStep0,
  registerDataStep1:store.registerReducer.registerDataStep1,
  registerDataStep2:store.registerReducer.registerDataStep2,
  registerDataStep3:store.registerReducer.registerDataStep3,
  UserData:store.registerReducer.UserData
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AppActions, ...RegisterActions }, dispatch);



// Transition Slide for Dialog SLIDE UP	
const TransitionSlideUp = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;  
});	



/* Extra  Dialog Component START */
export const HelpDialog = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {
		

const backgroundHeaderModal = {
  backgroundImage: "url(" + backgroundHeaderImage + ")",
  backgroundSize:"cover",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"center",
  display:"flex",
  alignItems:"center",
  justifyContent:"center"
}; 		
	


const screenStyleDialog = {
  alignItems: "center",
  justifyContent: "top",
  width:"100vw",
  minHeight:"100vh",
  display:"flex",
  flexDirection:"column",
  backgroundColor:"#F0F0F0"
}; 
	
// set classes var
// const classes = useStyles()();	

const { 
	innerLoading,
	setInnerLoading,
	setDialogHelp,
	dialogHelp
} = props;	

	return (
	<div>
		  <Dialog fullScreen open={dialogHelp} onClose={()=>setDialogHelp(false)} TransitionComponent={TransitionSlideUp}>
		  
			<AppBar style={{position: 'fixed',height:'80px',...backgroundHeaderModal}}>
			  <Toolbar style={{width:"100%"}}>
				  <div style={{marginLeft: "10px",flex: 1,textAlign:"center",display:"flex",justifyContent:"center",alignItems:"center"}}>
					  <img src={logo} alt="Motuum" style={{marginLeft:"auto",marginRight:"auto",width:"40%",height:"auto"}} />
				  </div>
				</Toolbar>
			</AppBar>

			<Box style={{...screenStyleDialog,backgroundColor:"#FFF",paddingLeft:"20px",paddingRight:"20px"}} m={0}>
			
				<Box mt={10} ml={0} mr={0}>
					
					<Box pt={2}>

						<SupportComponent simple="true" screen="register" setDialog={setDialogHelp}  dialog={dialogHelp} />
						
						<Box style={{backgroundColor:"transparent",display:"flex",flexDirection:"row",justifyContent:"center"}} ml={0} mr={0} mb={5} mt={0} pl={7} pr={7}>						
							
							<Button color="primary" variant="outlined" style={{width:"100%",height:"54px",borderRadius:"10px",fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px",}} onClick={()=>setDialogHelp(false)}>
							 Fechar
							</Button>		
								
						</Box>							

					</Box>
			
				</Box>				
			
			</Box>

		  </Dialog>
		</div>
		
	);
	
});



export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(ProfileStep3);
 