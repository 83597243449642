import React from 'react';
import axios from 'axios';
import moment from 'moment';
import * as types from '../actionTypes';
import * as config from '../../Config/api.js';
import {
  osVersion,
  osName,
  fullBrowserVersion,
  mobileVendor,
  mobileModel,
  deviceType,
  deviceDetect,
  isBrowser,
  isMobile
} from "react-device-detect";

export const setButtonLoading  = (status) => { 

	return { 
		type: types.BUTTON_LOADING,
		payload: status 
	}
};

export const setRegister = (obj,step) => {
	
	if (step == 0) {
		return {
			type: types.SET_REGISTER_STEP0,
			payload: obj
		}	
	} // end if
	else if (step == 1) {
		return {
			type: types.SET_REGISTER_STEP1,
			payload: obj
		}	
	} // end if	
	else if (step == 2) {
		return {
			type: types.SET_REGISTER_STEP2,
			payload: obj
		}	
	} // end if	
	else if (step == 3) {
		return {
			type: types.SET_REGISTER_STEP3,
			payload: obj
		}	
	} // end if		
};

export const setUserData  = (value) => { 

	return { 
		type: types.SET_USERDATA,
		payload: value 
	}
};

export const processStep  = (nextStep,data,props) => {

		return dispatch => {
			
			// We are on Step 0
			if (nextStep == 1) {
		
				axios.post(config.API_URL+'/user/checkEmail', {
				  email: data.email,
				  mobilePhone: data.mobilePhone,
				  type:data.type
				},{timeout:10000})
				.then((response) => {
					
					if (response.data.exist) {
						
						dispatch(setButtonLoading(false));
						var dialogMessageText = data.type == 'email' ? 
						"O email informado já está vinculado a outro cadastro. Caso seja seu, faça o login." :
						"O telefone informado já está vinculado a outro cadastro. Caso seja seu, faça o login."
						
						props.setDialogMessage(dialogMessageText);
						props.setDialog(true);	
						return;						
												
					} // end if
					else {
							
						// Turn button loading off
						dispatch(setButtonLoading(false));
						
						// Navigate to next step
						props.navigate("/register/step1");
						return;

					} // end else
					
					
				}, (error) => {
					
					dispatch(setButtonLoading(false));
					
					// verify if timeout happened
					var alertMessage = (error.code == "ECONNABORTED") ? 
					"Ocorreu um erro ao se comunicar com a API. Verifique se está com acesso a internet.":
					"Ocorreu um erro ao se comunicar com a API. Verifique os dados informados e tente novamente.";					
					props.setDialogMessage(alertMessage);
					props.setDialog(true);	
					return;
										
				})
				.catch(error=> {
					
					console.log("error: "+JSON.stringify(error.message,null,2));

					dispatch(setButtonLoading(false));
					props.setDialogMessage('Ocorreu um erro geral ao se comunicar com a API. Verifique sua conexão e tente novamente.');
					props.setDialog(true);
					return;					

				});
		
		
			} // end if
			// We are on Step 1
			else if (nextStep == 2) {

				axios.post(config.API_URL+'/user/checkEmail', {
				  email: data.email,
				  mobilePhone: data.mobilePhone,
				  type:"emailPhone"
				})
				.then((response) => {
					
					if (response.data.exist) {
						
					dispatch(setButtonLoading(false));	
						
					// verify if timeout happened
					if (response.data.conflict == "emailAndmobilePhone") {
						var alertMessage = "O Email e o telefone informado já estáo vinculados a outro cadastro. Caso seja seu, faça o login."
					} // end if
					else if (response.data.conflict == "mobilePhone") {
						var alertMessage = "O telefone informado já está vinculado a outro cadastro. Caso seja seu, faça o login."
					} // end else false
					else {
						var alertMessage = "O email informado já está vinculado a outro cadastro. Caso seja seu, faça o login."
					} // end else
					
					// Dispatch alert				
					props.setDialogMessage(alertMessage);
					props.setDialog(true);	
					return;								
						
					} // end if
					else {
						
						// Turn button loading off
						dispatch(setButtonLoading(false));
						
						// Navigate to next step
						props.navigate("/register/step2");

					} // end else					
					
				}, (error) => {
										
					dispatch(setButtonLoading(false));
					props.setDialogMessage('Ocorreu um erro ao se comunicar com o servidor. Tente novamente.');
					props.setDialog(true);
					return;								
										
				})
				.catch(error=> {

					dispatch(setButtonLoading(false));
					props.setDialogMessage('Ocorreu um erro geral ao se comunicar com a API. Verifique sua conexão e tente novamente.');
					props.setDialog(true);
					return;						

				});
		

			} // end else if
			// We are on Step 2
			else if (nextStep == 3) {
				
				// Prepare for user creation
				const preparedData = {};
				
				// Set general data
				preparedData.email = data.email;
				preparedData.password = data.password;
				preparedData.completeName = data.completeName;
				preparedData.mobilePhone =data.mobilePhone
				preparedData.alias = data.alias;
				preparedData.device = data.device;
				
				// Connect to REGISTER Endpoint
				axios({
					   url    : config.API_URL+'/user/register',
					   method : 'POST',
					   data   : preparedData,
					   headers: {
						Accept: 'application/json'
					}
				})				
				
				.then((response) => {
	
				// If an error occured
				if (response.data.error) {					
					dispatch(setButtonLoading(false));
					props.setDialogMessage('Ocorreu um erro ao se comunicar com o servidor. Tente novamente.');
					props.setDialog(true);
					return;			
				} // end if	
	
  
				if (response.data.success) {
					
					dispatch(setButtonLoading(false));
					
					// If company already exist
					if (response.data.exist) {
						props.setDialogMessage('Já existe um usuário cadastrado com os dados enviados.');
						props.setDialog(true);						
					} // end if
					
					
					// If company already exist
					if (response.data.token) {
						
						// Check if there is a local USER OBEJECt with a TOKEN
						var userObj = {
							userToken:response.data.token,
							userChatToken:response.data.chatToken,
							firstName:response.data.firstName,
							completeName:data.completeName,
							userStatus:0,
							validated:0
						}
						const saveObj = JSON.stringify(userObj);
						
						// save User Session Id on Session Storage
						sessionStorage.setItem("@motuum/sessionId",response.data.sessionId);						
						
						// save User Data on Local Storage
						localStorage.setItem("@motuum/userData",saveObj);
						
						// Set User Data
						var userData = {
							firstName:response.data.firstName
						}
						dispatch(setUserData(userData));				
						
						// Navigate to next step
						props.navigate("/register/step3/activate/step1");
						return;
																
					} // end if
					
					
				} // end if
				
				// If any other error, what do do???
				// ...  							
					
				}, (error) => {
															
					dispatch(setButtonLoading(false));
					props.setDialogMessage('Ocorreu um erro ao se comunicar com o servidor. Tente novamente.');
					props.setDialog(true);					
										
				})
				.catch(error=> {
						
					dispatch(setButtonLoading(false));
					props.setDialogMessage('Ocorreu um erro geral ao se comunicar com a API. Verifique sua conexão e tente novamente.');	
					props.setDialog(true);					

				});

			} // end else if
		
		
	} // end dispatch
		
};

export const activateProfileGenerate  = (data,props) => {

		return dispatch => {
		
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = localStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  
					
					// prepare data to send
					var sendData = {
						email: data.email,
						mobilePhone:data.mobilePhone,
						loginType: data.loginType
					} // end if
	
					// Connect to REGISTER Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/user/activateProfileGenerate',
						   method : 'POST',
						   data: sendData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})				
					
					.then((response) => {
						
	
					// If an error occured. Check error is blocking or non-blocking.
					if (response.data.error) {
						
						props.setSendingCode(false);

						if (response.data.notificationSent === false) {							
							props.setDialogMessage('Ocorreu um erro ao enviar o email ou sms. Verifique os dados e tente novamente.');		
							props.setDialog(true);									
							return;
						} // end if

						props.setDialogMessage('Ocorreu um erro ao se comunicar com o servidor. Tente novamente. [1]');		
						props.setDialog(true);							
						return;					
					} // end if	*/
			
					if (response.data.success) {
						
						
						// If company already exist
						if (response.data.notificationSent) {
											
							dispatch(setButtonLoading(false));
							
							// send success message to screen and toeaster
							props.setCodeView1(false);
							props.setCodeView2(true);	
							props.setSendingCode(false);
							
							props.setSnackMessage('Código enviado com sucesso! Aguarde alguns segundos para receber...');		
							props.setSnack(true);														
							return;											
							
						} // end if
						// Could not send notification. Tells to try again
						else {
								props.setSendingCode(false);
								props.setDialogMessage('O código não foi enviado para seu email devido a um problema. Tente reenviar o código. Se o problema persistir, tente alterar o email.');
								props.setDialog(true);									
								return;
						} // end else
						
						
					} // end if
					
					// If any other error, what do do???
					// ...
						
					}, (error) => {
											
						props.setSendingCode(false);
						props.setDialogMessage('Ocorreu um erro ao se comunicar com o servidor. Tente novamente.');
						props.setDialog(true);							
						return;
											
					})
					.catch(error=> {
							
						props.setSendingCode(false);
						props.setDialogMessage('Ocorreu um erro geral ao se comunicar com a API. Verifique sua conexão e tente novamente.');
						props.setDialog(true);									
						return;

					});


		}		
};

export const activateProfileConfirm  = (data,props) => {

		return dispatch => {
		
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = localStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  
					
					// prepare data to send
					var sendData = {
						code: data.code,
						email:data.email,
						originalEmail:data.originalEmail
					} // end if
	
					// Connect to REGISTER Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/user/activateProfileConfirm',
						   method : 'POST',
						   data: sendData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})				
					
					.then((response) => {

					// If an error occured. Check error is blocking or non-blocking.
					if (response.data.error) {
							
						// Code expired...
						if (response.data.errorCode == 9992) {
							dispatch(setButtonLoading(false));
							props.setDialogMessage('O código informado está expirado. Reenvie um novo código e tente novamente. Lembre-se que você tem até 5 minutos para validar o código.');	
							props.setDialog(true);								
							return;								
						} // end if
						
						// Wrong code
						if (response.data.errorCode == 9991) {
							dispatch(setButtonLoading(false));
							props.setDialogMessage('O código informado não é igual ao enviado. Verifique o email ou mensagem recebida e tente novamente. Caso o erro persista, reenvie um novo código e tente novamente.');	
							props.setDialog(true);															
							return;			
						} // end if
						
						// Validation Alread Done...redirect to next screen
						if (response.data.errorCode == 9990) {
							
								// Turn off loading button and send Toast message
								dispatch(setButtonLoading(false));		
								
								// Set Register 3
								var obj = {
									...data,
									userStatus:1,
									validated:1									
								}
								dispatch(setRegister(obj,3));								

								props.setSnackMessage('Perfil ativado com sucesso!');		
								props.setSnack(true);	
	
								// Navigate to next step
								if (props.targetRedirect) {
									props.navigate(props.targetRedirect);
								} // end if
								else {
									props.navigate("/register/step4");
								} // end else
								
								return;
								
						} // end if
						
						dispatch(setButtonLoading(false));
						props.setDialogMessage('Ocorreu um erro ao se comunicar com o servidor. Tente novamente. [1]');	
						props.setDialog(true);								

						return;					
					} // end if	*/
					
					if (response.data.success) {
						
						dispatch(setButtonLoading(false));
							
						// If company already exist
						if (response.data.validated) {
						
							// Check if there is a local USER OBEJECt with a TOKEN
							var newUserObj = {
								...userObj,
								userStatus:1,
								validated:1
							}
							const saveObj = JSON.stringify(newUserObj);
							
							// update / save User Data on Local Storage
							localStorage.setItem("@motuum/userData",saveObj);							
						
								// Turn off loading button and send Toast message
								dispatch(setButtonLoading(false));		

								// Set Register 3
								var obj = {
									...data,
									validated:true 								
								}
								dispatch(setRegister(obj,3));	
							  
								props.setSnackMessage('Perfil ativado com sucesso!');		
								props.setSnack(true);	

								// Navigate to next step
								if (props.targetRedirect) {
									props.navigate(props.targetRedirect);
								} // end if
								else {
									props.navigate("/register/step4");
								} // end else
									
								return;
										
							
							// return to avoid 
							return;											
							
						} // end if
						
						
					} // end if
					
					// If any other error, what do do???
					// ...
						
					}, (error) => {
											
						dispatch(setButtonLoading(false));
						props.setDialogMessage('Ocorreu um erro ao se comunicar com o servidor. Tente novamente.');
						props.setDialog(true);							
						return;
											
					})
					.catch(error=> {

						dispatch(setButtonLoading(false));
						props.setDialogMessage('Ocorreu um erro geral ao se comunicar com a API. Verifique sua conexão e tente novamente.');
						props.setDialog(true);								
						return;

					});
					


		}		
};


