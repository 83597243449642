import './MainSearchService.css';
import React,{useState,useEffect,Fragment} from 'react';
import {Link,useNavigate,useParams} from 'react-router-dom';
import { CSSTransition } from "react-transition-group";

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

import { Box,Backdrop,CircularProgress,Paper,Divider,Typography,Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Fade from '@mui/material/Fade';
import Slide from '@mui/material/Slide';

import AnimateHeight from 'react-animate-height';


import Info from '@mui/icons-material/ErrorOutline';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AccountTreeIcon from '@mui/icons-material/AccountTree'; 
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ScheduleIcon from '@mui/icons-material/Schedule';
import WarningIcon from '@mui/icons-material/Warning';

import {useComponentWillMount} from '../../Utils/customFunctions';
import Header from '../../Components/Layout/Header';
import If from '../../Components/Common/If';
import {ServiceDetailDialog} from './DialogServiceDetail';

import backgroundHexagon from '../../Assets/Images/backgroundHexagon.png';
import headerCategory1 from '../../Assets/Images/headerCategory1.png';
import priceIconBlue from '../../Assets/Images/priceIconBlue.svg'; 
import scheduleIconBlue from '../../Assets/Images/scheduleIconBlue.svg'; 
import plusIconBlue from '../../Assets/Images/plusIconBlue.svg'; 
import lessIconBlue from '../../Assets/Images/lessIconBlue.svg'; 

// Load Actions
import * as AppActions from '../../Store/Actions/App'
import * as ServiceActions from '../../Store/Actions/Service'

const SearchService = props => {

const screenStyle = {
  alignItems: "stretch",
  justifyContent: "top",
  width:"100vw",
  minHeight:"100vh",
  display:"flex",
  flexDirection:"column",
  backgroundImage: "url(" + backgroundHexagon + ")",
  backgroundSize:"cover",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"center"
}; 

const screenStyleLoading = {
  alignItems: "middle",
  justifyContent: "center",
  width:"100vw",
  height:"100vh",
  display: "flex",
  flexDirection: "column"
}; 



// set classes var
// const classes = useStyles()();

// set vars from props
// Remember to add those who come from Redux
const { 
	innerLoading,
	setInnerLoading,
	hireServiceService,
	retrieveCategoryBreadCrumb,
	setDialog
} = props;



// Set internal state
const [contentVisible,setContentState] = useState(false);
const [loadingMessage,setLoadingMessage] = useState("Carregando...");

const [Level,setLevel] = useState(0);
const [SubLevel,setSubLevel] = useState(0);
const [ServicesResult,setServicesResult] = useState(false);
const [ServicesResultSearch,setServicesResultSearch] = useState(false);
const [categoryBreadCrumb,setCategoryBreadCrumb] = useState("carregando...");

const [serviceIconUrl,setServiceIconUrl] = useState(false);
const [heightPanel,setHeightPanel] = useState(0);

// console.log("[hireServiceService]--->"+JSON.stringify(hireServiceService,null,2));


let navigate = useNavigate();

let { id } = useParams();

// Flag to simulated behavior
const ProfileActivated = true;

  
// Run after mount component
useEffect(() => {
  
	// Retrieve Breadcrumb
	/*var data = {
		categoryId: hireServiceService.ServiceHasServiceCategory ? hireServiceService.ServiceHasServiceCategory.ServiceCategoryId : null
	}*/	
	//retrieveCategoryBreadCrumb(data);
	setCategoryBreadCrumb(hireServiceService.breadcrumb ? hireServiceService.breadcrumb : "carregando...");
	
	// set icon
	//setServiceIconUrl("https://media.motuum.com.br/appUser/icons/hardwareIconWhite.svg");
	setServiceIconUrl(hireServiceService.iconHeader);
 
 // Set Content Loading ON
 setContentState(true);
 //setInnerLoading(true);
 

  // set scroll to top
  window.scrollTo(0, 1);
    
  // Runs when UNMOUNT component
  return () => {
    
	
  };
  
}, []); // end useEffect


// Will Mount Component HOOK
useComponentWillMount(() => {
	
  // if session is not created, redirect to bootstrap. (avoid direct access using url)
  if (!hireServiceService.id) {
	  navigate("/searchService");
  } // end if		
	
    // Set session of app
  var sessionCheck = sessionStorage.getItem('@motuum/appLaunched');
  
  // if session is not created, redirect to bootstrap. (avoid direct access using url)
  if (!sessionCheck) {
	  navigate("/");
  } // end if	
  
	// Retrieve User Data Obj. 
	var result = localStorage.getItem("@motuum/userData", undefined,true);
	var userData = JSON.parse(result);  
  
	  // If token dont exist, redirect to onboarding or register
	  if (!userData) {	  
		navigate("/");  
	  } // end if
	  // If token exist, redirect to HOME
	  else {
		  if (!userData.userToken) {
			navigate("/");
		  } // end if
	  } // end else     
      
}, []); // end useComponentWillMount

var formatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});



	return (
	<Fragment>
			
			<Header title={hireServiceService.name} buttons={{support:false,menu:false,close:false,rightFake:true,service:true,serviceIcon:serviceIconUrl,back:true,backUrl:"/searchService"}} />
			
			<Fade in={contentVisible} timeout={1000}  mountOnEnter unmountOnExit>			
			
				<Box className="mainSearchServicesView" style={{...screenStyle}} mt={0}>
							
					<Box style={{marginTop:"185px",paddingLeft:"10px",paddingRight:"10px"}} mb={0}>
					
						<div style={{color:"#636466",fontFamily:"Roboto",fontSize:"16px",fontWeight:"normal",margin:"20px",textAlign:"left"}} onClick={()=>{ navigate("/searchService") } }> 
							{categoryBreadCrumb} 
						</div>
						
					</Box>	
					
					<Box style={{paddingLeft:"20px",paddingRight:"20px",marginBottom:"10px"}}> 

						<Card style={{minWidth: "90%",marginBottom:"20px",minHeight:"154px",width:"100%",marginBottom:"20px",backgroundColor:"#ffffff",boxShadow: "0px 10px 20px 0px #0000002A",borderRadius:"10px"}}>
						
						  <CardContent style={{padding:"25px",paddingBottom:"0px"}}>
							
							<Box >
								
								<Box style={{padding:"0px"}}>
									
										<div style={{textAlign:"left"}}>
											<h3 style={{color:"#003CFA",fontFamily:"Roboto",fontWeight:"bold",fontSize:"16px",marginTop:"0px",marginBottom:"5px",lineHeight:"18px"}}>{hireServiceService.name}</h3>
											<div style={{minHeight:"54px",color:"#636466",fontFamily:"Roboto",fontWeight:"normal",fontSize:"12px",lineHeight:"18px"}}>
												{hireServiceService.description}
											</div>
										</div>											
									
								</Box>	
										
								<AnimateHeight
								  duration={ 500 }
								  height={ heightPanel }
								>								
									<Box style={{padding:"0px",marginTop:"15px"}}>
										
											<div style={{textAlign:"left"}}>
												<h3 style={{color:"#003CFA",fontFamily:"Roboto",fontWeight:"bold",fontSize:"16px",marginTop:"0px",marginBottom:"5px",lineHeight:"18px"}}>Garantia do Serviço</h3>
												<div style={{color:"#636466",fontFamily:"Roboto",fontWeight:"normal",fontSize:"12px",lineHeight:"18px"}}>
													{hireServiceService.warrantyTerm}
												</div>
											</div>											
										
									</Box>									
								</AnimateHeight>
								
							
							</Box>
							
						  </CardContent>
							
							<CardActions style={{display:"flex",flexDirection:"row",backgroundColor:"#003CFA10",padding:"2px",marginTop:"19px"}}>
									  
							  <div style={{flex:1,textAlign:"center"}}>
									
									{heightPanel == 0 &&
									<Button style={{color:"#003CFA",fontFamily:"Roboto",fontWeight:"normal",fontSize:"12px"}} size="small" onClick={()=>setHeightPanel(100)}>										
										<img src={plusIconBlue} style={{width:"18px",height:"18px",marginRight:"5px"}} />
										<div>Ver mais</div>										
									</Button>	
									}									
									
									{heightPanel > 0 &&
									<Button style={{color:"#003CFA",fontFamily:"Roboto",fontWeight:"normal",fontSize:"12px"}} size="small" onClick={()=>setHeightPanel(0)}>										
										<img src={lessIconBlue} style={{width:"18px",height:"18px",marginRight:"5px"}} />
										<div>Ver menos</div>										
									</Button>
									}
									
								</div>
								
							  </CardActions>										

						</Card>	
						
						

					</Box>

					<Divider light style={{marginLeft:"20px",marginRight:"20px"}} />

					<Box mt={1} mb={2} style={{alignItems:"stretch",justifyContent:"center",display:"flex",flexDirection:"column",paddingBottom:"120px",paddingLeft:"20px",paddingRight:"20px"}}>
												
							{/* Time Box */}
							<Box ml={1} mr={1} mb={1} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>
							
								<Box style={{flex:2}}>
								
									<Box style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>    
										<div style={{marginRight:"5px"}}> 
											<img src={scheduleIconBlue} style={{width:"30px",height:"30px"}} />
										</div>
										
										<div> 
											<div style={{fontFamily:"Roboto",fontWeight:"regular",fontSize:"12px",color:"#636466",textTransform:"uppercase"}}>Tempo médio do serviço</div>
										</div>
									</Box>									
								
								</Box>
								
								<Box style={{flex:1}}>								
										<Box style={{fontFamily:"Roboto",fontWeight:"bold",fontSize:"16px",padding:"2px",color:"#003CFA",textAlign:"right"}}>
											{hireServiceService.effort} {hireServiceService.effortUnity}
										</Box>								
								</Box>								
							
							</Box>
							
							<Divider light />		
							
							{/* Price Box */}	
							<Box mt={1} ml={1} mr={1} mb={1} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>
							
								<Box style={{flex:2}}>
								
									<Box style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>    
										<div style={{marginRight:"5px"}}> 
											<img src={priceIconBlue} style={{width:"30px",height:"30px"}} />
										</div>
										
										<div> 
											<div style={{fontFamily:"Roboto",fontWeight:"regular",fontSize:"12px",color:"#636466",textTransform:"uppercase"}}>Valor do serviço</div>
										</div>
									</Box>									
								
								</Box>
								
								<Box style={{flex:1}}>								
										<Box style={{fontFamily:"Roboto",fontWeight:"bold",fontSize:"16px",padding:"2px",color:"#003CFA",textAlign:"right"}}>
											{hireServiceService.Price ? formatter.format(hireServiceService.Price[0].price) : "carregando..."}
										</Box>								
								</Box>								

							</Box>							
						
						<Divider light />					
						
							
						<Box style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row",marginTop:"20px"}}>  

							{/* Profile activated */}
							<If test={ProfileActivated}>						
								
								<div> 
									<div style={{color:"#636466",fontFamily:"Roboto",fontWeight:"normal",fontSize:"12px",textAlign:"center"}}><strong>Esse é o serviço que procura?</strong> Se sim, solicite um profissional selecionando o botão abaixo.</div>
								</div>
							</If>
							
							{/* Profile NOT activated */}
							<If test={!ProfileActivated}>
								<div style={{marginRight:"10px"}}> 
									<WarningIcon style={{fontSize:"2rem",color:"orange"}} />
								</div>
								
								<div> 
									<div style={{fontSize:"0.85rem",color:"#555555",textAlign:"left"}}>O seu perfil não foi ativado. Você só poderá solicitar serviços após ativá-lo.</div>
								</div>
							</If>	
							
						</Box>								
												
					
					</Box>
				
						
					<Box style={{backgroundColor:"transparent",display:"flex",width:"100%",alignItems:"center",position:"fixed",bottom:0,height:"100px",backgroundColor:"#ffffff",boxShadow: "0px -10px 15px 0px #0000001A"}} onClick={()=>console.log(true)}>		

							<Box style={{flex:1,display:"flex",flexDirection:"row",justifyContent:"space-around"}}>
									
								<Box style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>	

									<If test={ProfileActivated}>
										<Button color="secondary" variant="contained" style={{height:"53px",fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px",color:"#FFF",borderRadius:"10px",paddingLeft:"30px",paddingRight:"30px"}} component={Link} to="/hireService">
										  Solicitar profissional
										</Button>	
									</If>
									
									<If test={!ProfileActivated}>
										<Button color="secondary" variant="contained" style={{height:"53px",fontFamily:"Roboto",fontWeight:"normal",fontSize:"16px",color:"#FFF",borderRadius:"10px"}} component={Link} to="/home/activate/step1">
										  Ativar Perfil
										</Button>	
									</If>	

						
								</Box>	

							</Box>								
								
					</Box>							
					
								
				</Box>
			
			</Fade>    
			
			{/* Passinf Service Id Param */}
			<ServiceDetailDialog serviceId={id} />
				
	</Fragment>
)};

const mapStateToProps = store => ({
  innerLoading:store.appReducer.innerLoading,
  hireServiceService:store.serviceReducer.hireServiceService,
  dialog:store.appReducer.dialog
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AppActions, ...ServiceActions }, dispatch);
    

export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(SearchService);
 
