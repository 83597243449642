import * as types from '../actionTypes';

const initialState = { 
	buttonLoading: true,
	hireServiceService:{},
	hireServiceSchedule:{},
	hireServiceAddress:{},
	hireServicePayment:{},
	hireServiceInvoice:{},
	hireServiceExtraInfo:[],
	addressesCards:[],
	creditCards:[],
	invoices:[],
	searchResultItens:[],
	categoryBreadCrumb:"Carregando...",
	categories:[],
	parentCategories:[],
	childCategories:[],
	ScheduledServiceOrders:[],
	ScheduledServiceOrdersCancelled:[],
	ExecutedServiceOrders:[],
	OngoingServiceOrders:[],
	SelectedScheduledServiceOrder:{},
	SelectedExecutedServiceOrder:{},
	SelectedOngoingServiceOrder:{},	
}
 
export const serviceReducer = (state = initialState, action) => {
		
    switch (action.type) {
		case types.SET_SERVICE:
			return { ...state,hireServiceService: action.payload }	
		case types.SET_SCHEDULE:
			return { ...state,hireServiceSchedule: action.payload }	
		case types.SET_ADDRESS:
			return { ...state,hireServiceAddress: action.payload }	
		case types.SET_PAYMENT:
			return { ...state,hireServicePayment: action.payload }	
		case types.SET_INVOICE:
			return { ...state,hireServiceInvoice: action.payload }	
		case types.SET_EXTRAINFO:
			return { ...state,hireServiceExtraInfo: action.payload }	

		case types.SET_SCHEDULEDSERVICES:
			return { ...state,ScheduledServiceOrders: action.payload }	
		case types.SET_SCHEDULEDSERVICES_CANCELLED:
			return { ...state,ScheduledServiceOrdersCancelled: action.payload }				
		case types.SET_EXECUTEDSERVICES:
			return { ...state,ExecutedServiceOrders: action.payload }	
		case types.SET_ONGOINGSERVICES:
			return { ...state,OngoingServiceOrders: action.payload }

		case types.SET_SELECTED_SCHEDULEDSERVICE:
			return { ...state,SelectedScheduledServiceOrder: action.payload }	
		case types.SET_SELECTED_EXECUTEDSERVICE:
			return { ...state,SelectedExecutedServiceOrder: action.payload }	
		case types.SET_SELECTED_ONGOINGSERVICE:
			return { ...state,SelectedOngoingServiceOrder: action.payload }		

		case types.SET_CATEGORY_BREADCRUMB:
			return { ...state,categoryBreadCrumb: action.payload }	
		case types.SET_COUNTERTIMER:
			return { ...state,counterTimer: action.payload }				
			
        case types.BUTTON_LOADING:
            return { ...state, buttonLoading: action.payload }	
        case types.SET_PARENT_CATEGORIES:
            return { ...state, parentCategories: action.payload }
        case types.SET_CHILD_CATEGORIES:
            return { ...state, childCategories: action.payload }
			
        case types.SET_CATEGORIES:
            return { ...state, categories: action.payload }	       

		case types.SET_SEARCH_RESULT:
            return { ...state, searchResultItens: action.payload }		

        case types.SET_ADDRESSES_CARDS:
            return { ...state, addressesCards: action.payload }					
		case types.SET_CREDIT_CARDS:
            return { ...state, creditCards: action.payload }		
		case types.SET_INVOICES:
            return { ...state, invoices: action.payload }	
			
        case types.RESET_SERVICE_HIRE:
            return { ...state,hireServiceService:action.payload,hireServiceSchedule:action.payload,hireServiceAddress:action.payload,hireServicePayment:action.payload,hireServiceInvoice:action.payload,hireServiceExtraInfo:action.payload }			
        default:
            return state
    }
}