import './MainSupport.css';
import React,{useState,useEffect,Fragment} from 'react';
import {Link,useNavigate} from 'react-router-dom';

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

import { Box,Backdrop,CircularProgress,Button,IconButton,Divider,Fade } from '@mui/material';

import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from '@mui/icons-material/Warning';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Badge from '@mui/material/Badge';
import ChatIcon from '@mui/icons-material/Chat';

import helpIcon from '../../Assets/Images/help-icon-blue.svg'; // Tell Webpack this JS file uses this image
import deviceIcon from '../../Assets/Images/deviceIcon.svg'; // Tell Webpack this JS file uses this image
import chatComplain from '../../Assets/Images/iconComplain-blue.svg'; // Tell Webpack this JS file uses this image
import chatIcon from '../../Assets/Images/iconChat-blue.svg'; // Tell Webpack this JS file uses this image
import chatSupportIcon from '../../Assets/Images/chatIcon.svg'; // Tell Webpack this JS file uses this image

import {useComponentWillMount} from '../../Utils/customFunctions';
import Header from '../../Components/Layout/Header';
import If from '../../Components/Common/If';
import NavigationBottom from '../../Components/Layout/NavigationBottom';
import JivoChat from '../../Components/Jivochat'; // Ajuste o caminho conforme necessário

import logo from '../../Assets/Images/logoTypeWOslogan.svg'; // Tell Webpack this JS file uses this image
import backgroundHexagon from '../../Assets/Images/backgroundHexagon.png'; // Tell Webpack this JS file uses this image

// Load Actions
import * as AppActions from '../../Store/Actions/App'

const Support = props => {


const screenStyle = {
  alignItems: "stretch",
  justifyContent: "start",
  width:"100vw",
  minHeight:"100vh",
  display:"flex",
  flexDirection:"column",
  backgroundImage: "url(" + backgroundHexagon + ")",
  backgroundSize:"contain",
  backgroundReepeat:"repeat",
  backgroundPosition:"center"
}; 

const screenStyleLoading = {
  alignItems: "middle",
  justifyContent: "center",
  width:"100vw",
  height:"100vh",
  display: "flex",
  flexDirection: "column"
}; 

// set classes var
// const classes = useStyles()();

// set vars from props
// Remember to add those who come from Redux
const { 
	innerLoading,
	setInnerLoading,
	setDialog
} = props;

// set Use of history for PUSH 
let navigate = useNavigate();

// Set internal state
const [contentVisible,setContentState] = useState(false);
const [loadingMessage,setLoadingMessage] = useState("Carregando...");

// Simulate states
const registerMobile = false;
const registerEmail = true;
const activationDone = false;
  
// Run after mount component
useEffect(() => {
  
  // Set Backdrop Loading over interface
  //setInnerLoading(true); 
   
  // Show content after load for animation
 // setContentState(true);
 
 // Set Content Loading ON
 //setInnerLoading(true);
 setContentState(true);

  // set scroll to top
  window.scrollTo(0, 1);

    // Seleciona todos os elementos que têm uma classe que começa com 'button_'
    const jivoButtons = document.querySelectorAll("[class^='button_']");
    
    // Aplica 'display: none' a cada elemento encontrado
    jivoButtons.forEach(button => {
      button.style.display = 'none';
    });  
    
  // Runs when UNMOUNT component
  return () => {

    console.log("Desmontou o componente Suporte...");

	// Limpando o script do JivoChat quando o componente for desmontado
	const jivoScript = document.querySelector('script[src="//code.jivosite.com/widget/XtvJfKExuq"]');
	if (jivoScript) {
	  document.body.removeChild(jivoScript);
	}

    // Seleciona todos os elementos que têm uma classe que começa com 'button_'
    const jivoButtons = document.querySelectorAll("[class^='button_']");
    
    // Aplica 'display: none' a cada elemento encontrado
    jivoButtons.forEach(button => {
      button.style.display = 'none';
    });  


  };


}, []); // end useEffect


// Will Mount Component HOOK
useComponentWillMount(() => {
	
    // Set session of app
  var sessionCheck = sessionStorage.getItem('@motuum/appLaunched');
  
  // if session is not created, redirect to bootstrap. (avoid direct access using url)
  if (!sessionCheck) {
	  navigate("/");
  } // end if	
  
	// Retrieve User Data Obj. 
	var result = localStorage.getItem("@motuum/userData", undefined,true);
	var userData = JSON.parse(result);  
  
	  // If token dont exist, redirect to onboarding or register
	  if (!userData) {	  
		navigate("/");  
	  } // end if
	  // If token exist, redirect to HOME
	  else {
		  if (!userData.userToken) {
			navigate("/");
		  } // end if
	  } // end else     
      
}, []); // end useComponentWillMount

const handleClose = () => {
	setInnerLoading(false);
};

  // Função para abrir o JivoChat
  const openJivoChat = () => {
    if (window.jivo_api) {
      window.jivo_api.open();
    }
  };

	return (
	<Fragment>
			
			<Header title="Como podemos ajudar?" buttons={{support:false,menu:false,back:true,close:false,rightFake:true}} />

			
			<Fade in={contentVisible} timeout={{ enter: 1000, exit: 0 }} mountOnEnter unmountOnExit>	
			
				<Box className="mainHome" style={{...screenStyle}} mt={10}>
				
						<Box style={{marginBottom:"0px",backgroundColor:"transparent",alignItems:"center",display:"flex",flexDirection:"column"}} pl={5} pr={5}>

								<Box style={{marginTop:"40px",marginBottom:"30px"}}>						
									<div style={{fontFamily:"Roboto",fontWeight:"100",fontSize:"42px",color:"#003CFA",lineHeight:"38px"}}>	
										  COMO PODEMOS AJUDAR?
									</div>								 	
								</Box> 	
								
						</Box>
						
						<Box style={{backgroundColor:"transparent",alignItems:"center",justifyContent:"center",display:"flex",flexDirection:"row",flexWrap:"wrap"}} pl={5} pr={5} mb={2}>			
								
							<div style={{width:"140px",height:"140px",backgroundColor:"#F7F7F7",margin:"10px",borderRadius:"10px",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}} onClick={() => navigate("/support/faq")}>
								
									<div> 
										<img src={deviceIcon} alt="Device" style={{width:"28px",height:"44px"}} />
									</div>
									
									<div style={{color:"#636466",fontFamily:"Roboto",fontWeight:"normal",fontSize:"14px",}}> 
										Dúvidas sobre<br/> o app
									</div>									
									
							</div>

							<div style={{width:"140px",height:"140px",backgroundColor:"#F7F7F7",margin:"10px",borderRadius:"10px",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}} onClick={() => navigate("/support/ticket")}>
								
									<div> 
										<img src={chatSupportIcon} alt="Device" style={{width:"48px",height:"48px"}} />
									</div>
									
									<div style={{color:"#636466",fontFamily:"Roboto",fontWeight:"normal",fontSize:"14px",}}> 
										Abrir chamado<br /> de suporte
									</div>									
									
							</div>
							
							<div style={{width:"140px",height:"140px",backgroundColor:"#F7F7F7",margin:"10px",borderRadius:"10px",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}} onClick={() => navigate("/support/complaint")}>
								
									<div> 
										<img src={chatComplain} alt="Device" style={{width:"48px",height:"48px"}} />
									</div>
									
									<div style={{color:"#636466",fontFamily:"Roboto",fontWeight:"normal",fontSize:"14px",}}> 
										Abrir reclamação<br/> ou denúncia
									</div>									
									
							</div>

							<div style={{width:"140px",height:"140px",backgroundColor:"#F7F7F7",margin:"10px",borderRadius:"10px",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}} onClick={() => {openJivoChat()} }>
								
									<div> 
										<img src={chatIcon} alt="Device" style={{width:"48px",height:"48px"}} />
									</div>
									
									<div style={{color:"#636466",fontFamily:"Roboto",fontWeight:"normal",fontSize:"14px",}}> 
										Abrir chat online<br /> com suporte
									</div>									
									
							</div>							
								
						</Box>	
						
						{/* Bottom Navigation Bar */}
						<NavigationBottom page="help" />						
						
				</Box>
			</Fade> 			
			
			<JivoChat />
				
	</Fragment>
)};

const mapStateToProps = store => ({
  innerLoading:store.appReducer.innerLoading
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AppActions }, dispatch);

export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(Support);
 
