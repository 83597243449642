import React from 'react';
import axios from 'axios';
import moment from 'moment';
import * as types from '../actionTypes';
import * as config from '../../Config/api.js';
import {
  osVersion,
  osName,
  fullBrowserVersion,
  mobileVendor,
  mobileModel,
  deviceType,
  deviceDetect,
  isBrowser,
  isMobile
} from "react-device-detect";


export const updateProfile  = (data,props) => {

		return dispatch => {
		
				// Check if there is a local USER OBJ with a TOKEN
				var result = localStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  
					
					// prepare data to send
					var sendData = {
						email:data.email,
						mobilePhone:data.mobilePhone,
						completeName:data.completeName,
						alias:data.alias,
						currentCompleteName:data.currentCompleteName,
						currentAlias:data.currentAlias,
						currentEmail:data.currentEmail,
						currentMobilePhone:data.currentMobilePhone					
					} // end if
	
	
					// Connect to REGISTER Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/user/updateProfile',
						   method : 'PUT',
						   data: sendData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})				
					
					.then((response) => {
	
					// If an error occured. Check error is blocking or non-blocking.
					if (response.data.error) {
						
						props.setLoading(false);
						props.setDialogTitle("Ops, algo deu errado!");
						props.setDialogMessage('Ocorreu um erro ao atualizar os dados do perfil. Tente novamente. [1]');		
						props.setAlert(true);							
						return;					
					} // end if	*/
			
					if (response.data.success) {
						
							props.setLoading(false);
														
							// set current data
							props.setCurrentEmail(data.email);
							props.setCurrentMobilePhone(data.mobilePhone);
							props.setCurrentCompleteName(data.completeName);
							props.setCurrentAlias(data.alias);
							
							// Set Validation Profile to false
							if (data.needValidation) {
								props.setValidated(0);
								props.setDialogAction(true);
							} // end if
							else {
								props.setDialogAction(false);
							} // end else
							
							props.setDialogTitle("Tudo certo!");
							props.setDialogMessage("Os dados alterados foram gravados com sucesso!");
							props.setAlert(true);																
							return;
						
					} // end if
					
					// If any other error, what do do???
					// ...
						
					}, (error) => {
											
						props.setLoading(false);
						props.setDialogTitle("Ops, algo deu errado!");
						props.setDialogMessage('Ocorreu um erro ao se comunicar com o servidor. Tente novamente.');
						props.setAlert(true);							
						return;
											
					})
					.catch(error=> {
							
						props.setLoading(false);
						props.setDialogTitle("Ops, algo deu errado!");
						props.setDialogMessage('Ocorreu um erro geral ao se comunicar com a API. Verifique sua conexão e tente novamente.');
						props.setAlert(true);									
						return;

					});

		}		
};

export const updateProfilePassword  = (data,props) => {

		return dispatch => {
		
				// Check if there is a local USER OBJ with a TOKEN
				var result = localStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  
					
					// prepare data to send
					var sendData = {
						currentPassword:data.passwordCurrent,
						newPassword:data.password
					} // end if
	
						// Connect to REGISTER Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/user/changePassword',
						   method : 'PUT',
						   data: sendData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})				
					
					.then((response) => {
	
					// If an error occured. Check error is blocking or non-blocking.
					if (response.data.error) {
						
						// Password did not change
						if (response.data.noChange) {						
							props.setLoading(false);
							props.setDialogTitle("Ops, algo deu errado!");
							props.setDialogMessage('A senha atual está igual a nova senha. Nada a fazer. [1]');		
							props.setAlert(true);							
							return;		
						} // end if
						
						// Current Password is wrong
						if (response.data.wrongPass) {						
							props.setLoading(false);
							props.setDialogTitle("Ops, algo deu errado!");
							props.setDialogMessage('A sua senha atual não corresponde a armazenada. Caso não lembre, faça o que logoff e uso o recurso de recuperar senha. [1]');		
							props.setAlert(true);							
							return;		
						} // end if
						
						props.setLoading(false);
						props.setDialogTitle("Ops, algo deu errado!");
						props.setDialogMessage('Ocorreu um erro ao atualizar a senha do perfil. Tente novamente. [1]');		
						props.setAlert(true);							
						return;	
						
					} // end if	*/
			
					if (response.data.success) {
						
							props.setLoading(false);
							
							props.setDialogAction(true);
							props.setDialogTitle("Tudo certo!");
							props.setDialogMessage("A sua senha foi alterada com sucesso! Faça o login no aplicativo usando a nova senha. ");
							props.setAlert(true);	

							// Process LOGOFF
							
							// Set logoff flag
							localStorage.setItem("loggedOff",1);
							
							// Remove userData 
							localStorage.removeItem("@motuum/userData");	
							
							// Remove Session ALSO
							sessionStorage.removeItem("@motuum/searchingProvider");	
							sessionStorage.removeItem("@motuum/serviceOfferId");	
							sessionStorage.removeItem("@motuum/quotationId");	
							sessionStorage.removeItem("@motuum/sessionId");
							sessionStorage.removeItem("@motuum/selectedProvider");
							return;
						
					} // end if

						
					}, (error) => {
											
						props.setLoading(false);
						props.setDialogTitle("Ops, algo deu errado!");
						props.setDialogMessage('Ocorreu um erro ao se comunicar com o servidor. Tente novamente.');
						props.setAlert(true);							
						return;
											
					})
					.catch(error=> {
							
						props.setLoading(false);
						props.setDialogTitle("Ops, algo deu errado!");
						props.setDialogMessage('Ocorreu um erro geral ao se comunicar com a API. Verifique sua conexão e tente novamente.');
						props.setAlert(true);									
						return;

					});

		}		
};


