import {useRef} from 'react';

// Custom Hook to allow use of ComponentWillMounted that is deprecated
export const useComponentWillMount = (func) => {
	
	const willMount = useRef(true);
	
	if (willMount.current) func()
		
	willMount.current = false;	
	
} // end function