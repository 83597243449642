import './MainHome.css';
import React,{useState,useEffect,Fragment} from 'react';
import {Link,useNavigate} from 'react-router-dom';

import PropTypes from 'prop-types';
import { CSSTransition } from "react-transition-group";

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

// Material UI Import
import { Box,CircularProgress,Typography,Button,IconButton,TextField,FormControl  } from '@mui/material';

import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from '@mui/icons-material/Warning';


import {useComponentWillMount} from '../../Utils/customFunctions';
import Header from '../../Components/Layout/Header';
import If from '../../Components/Common/If';

import checked from '../../Assets/Images/checked.svg'; // Tell Webpack this JS file uses this image
import backgroundHexagon from '../../Assets/Images/backgroundHexagon.png'; // Tell Webpack this JS file uses this image


// Load Actions
import * as AppActions from '../../Store/Actions/App'

const ProfileStep1 = props => {


const screenStyle = {
  alignItems: "stretch",
  justifyContent: "top",
  width:"100vw",
  minHeight:"100vh",
  display:"flex",
  flexDirection:"column",
  backgroundImage: "url(" + backgroundHexagon + ")",
  backgroundSize:"cover",
  backgroundReepeat:"no-repeat",
  backgroundPosition:"fixed"
}; 

const screenStyleLoading = {
  alignItems: "middle",
  justifyContent: "center",
  width:"100vw",
  height:"100vh",
  display: "flex",
  flexDirection: "column"
}; 

const styles = {
  indicator: {
    backgroundColor: '#A255A0',
  },
};

// Set functions
const handleChange = (event, newValue) => {
setValue(newValue);
};


// set classes var
// const classes = useStyles()();


// set vars from props
// Remember to add those who come from Redux
const { 
	innerLoading,
	setInnerLoading,
	retrieveUserData
} = props;

// set Use of history for PUSH 
let navigate = useNavigate();

// Set internal state
const [contentVisible,setContentState] = useState(false);
const [loadingMessage,setLoadingMessage] = useState("Carregando...");
const [value, setValue] = useState(0); // state for tab

// Simulate states
const registerMobile = false;
const registerEmail = true;
const activationDone = true;
  
// Run after mount component
useEffect(() => {
	
  console.log("Montou o componente MainHome....");
  
  // Set Backdrop Loading over interface
  //setInnerLoading(true); 
   
  // Show content after load for animation
 // setContentState(true);
 
 // Avoid user to go back on browser or hardware android butto
 /* window.navigateState(null, document.title, window.location.href);
  window.addEventListener('popstate', function(event) {
    window.navigateState(null, document.title, window.location.href);
  });*/

 // Set Content Loading ON
 //setInnerLoading(true);
 setContentState(true);

  // Reload User Data 
  retrieveUserData();				  

  // set scroll to top
  window.scrollTo(0, 1);
    
  // Runs when UNMOUNT component
  return () => {
    console.log("Desmontou o componente MainHome...");
  };
  
}, []); // end useEffect


// Will Mount Component HOOK
useComponentWillMount(() => {
	
    // Set session of app
  var sessionCheck = sessionStorage.getItem('@motuum/appLaunched');
  
  // if session is not created, redirect to bootstrap. (avoid direct access using url)
  if (!sessionCheck) {
	  navigate("/");
  } // end if	
  
	// Retrieve User Data Obj. 
	var result = localStorage.getItem("@motuum/userData", undefined,true);
	var userData = JSON.parse(result);  
  
	  // If token dont exist, redirect to onboarding or register
	  if (!userData) {	  
		navigate("/");  
	  } // end if
	  // If token exist, redirect to HOME
	  else {
		  if (!userData.userToken) {
			navigate("/");
		  } // end if
	  } // end else    
      
}, []); // end useComponentWillMount

const handleClose = () => {
	setInnerLoading(false);
};

	return (
	<Fragment>
			
			<Header title="Perfil ativado" buttons={{support:true,menu:false,close:false,back:false,leftFake:true}} /> 
			
			<CSSTransition in={contentVisible} timeout={1000} classNames="fade"	unmountOnExit>	
			
				<Box className="mainProfile" style={{...screenStyle}} m={0}>
							
						<Box style={{marginBottom:"20px",backgroundColor:"transparent",alignItems:"center",display:"flex",flexDirection:"column"}} mt={12} pl={3} pr={3}>

								<div>
									<img src={checked} atl="Sucesso" style={{marginLeft:"auto",marginRight:"auto",width:"25vw"}} />	
								</div>

								<h5 style={{fontSize:"1rem",color:"#A255A0"}}>Seu perfil foi ativado com sucesso</h5>
								
								<div style={{fontSize:"0.9rem",color:"#555555"}}>Agora você pode prosseguir com a solicitação do profissional para o serviço escolhido.</div>
								
						</Box>
						
						<Box style={{flexBasis:"26vh",backgroundColor:"transparent",alignItems:"center",display:"flex"}} pl={3} pr={3}>			
								
							
								<div style={{flex:1,alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>    
									<div> 
										<CheckIcon style={{fontSize:"3rem",color:"green"}} />
									</div>
									
									<div> 
										<div style={{fontSize:"0.9rem",color:"#555555"}}>O seu perfil foi ativado. Você está habilitado para solicitar profissionais.</div>
									</div>
								</div>	
								
						</Box>						
						
						<Box style={{flexBasis:"20vh",backgroundColor:"transparent",display:"flex",alignItems:"center"}}>							
								
							<div style={{flex:1}}>						
									
									<Button variant="contained" color="primary" size="large" style={{width:"80%",height:"48px",backgroundColor:"#555555"}} component={Link} to="/home">
									  OK
									</Button>	
									
							</div>	
								
						</Box>
														
				</Box>
			
			</CSSTransition> 
			
				
	</Fragment>
)};

const mapStateToProps = store => ({
  innerLoading:store.appReducer.innerLoading
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AppActions }, dispatch);

export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(ProfileStep1);
 
