import './MainProfileManage.css';
import React,{useState,useEffect,Fragment} from 'react';

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

import { Box,Backdrop,CircularProgress,Typography,Button,InputAdornment,IconButton,TextField,Divider } from '@mui/material';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import WarningIcon from '@mui/icons-material/Warning';
import LockIcon from '@mui/icons-material/Lock';
import Slide from '@mui/material/Slide';
import Fade from '@mui/material/Fade';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Danger from '@mui/icons-material/Error';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';

import InputMask from "react-input-mask";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


// Load Actions
import * as AppActions from '../../Store/Actions/App'
import * as ProfileActions from '../../Store/Actions/Profile'

const mapStateToProps = store => ({
  innerLoading:store.appReducer.innerLoading,
  UserData:store.appReducer.userData,
  dialog:store.appReducer.dialog
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AppActions,...ProfileActions }, dispatch);
    
// Transition Slide for Dialog SLIDE UP	
const TransitionSlideUp = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;  
});	
	

/* Extra  Dialog Component START */
export const UserDataForm = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {
		
	
// set classes var
// const classes = useStyles()();

// set vars from props
// Remember to add those who come from Redux
const { 
	setDialog,
	dialog,
	UserData,
	updateProfile,
	retrieveUserData
} = props;	

const [dialogAction, setDialogAction] = useState(false); // state for tab
const [dialogAlert, setAlertDialog] = useState(false); // state for tab
const [dialogMessage, setDialogMessage] = useState(""); // state for tab
const [dialogTitle, setDialogTitle] = useState("Verifique as informações"); // state for tab
const [alert, setAlert] = useState(false); // state for tab
const [confirm, setConfirm] = useState(false); // state for tab
 
 const [email, setEmail] = useState(UserData.email ? UserData.email : "");
const [mobilePhone, setMobilePhone] = useState(UserData.mobilePhone ? UserData.mobilePhone : ""); 
const [completeName, setCompleteName] = useState(UserData.CustomerProfile ? UserData.CustomerProfile.completeName : ""); 
const [alias, setAlias] = useState(UserData.CustomerProfile ? UserData.CustomerProfile.alias : ""); 

const [currentEmail, setCurrentEmail] = useState(UserData.email ? UserData.email : "");
const [currentMobilePhone, setCurrentMobilePhone] = useState(UserData.mobilePhone ? UserData.mobilePhone : ""); 
const [currentCompleteName, setCurrentCompleteName] = useState(UserData.CustomerProfile ? UserData.CustomerProfile.completeName : ""); 
const [currentAlias, setCurrentAlias] = useState(UserData.CustomerProfile ? UserData.CustomerProfile.alias : ""); 
const [loading, setLoading] = useState(false); 
const [validated,setValidated] = React.useState(UserData.activationDate ? 1: 0);
const [needValidation,setNeedValidation] = React.useState(false);


const confirmAction = () => {
		
	if ( (email == currentEmail) && (mobilePhone.replace(/\D/g,"") == currentMobilePhone) && (alias == currentAlias) && (completeName == currentCompleteName) ) {
		setDialogTitle("Verifique as informações");
		setDialogMessage("Você não alterou nenhuma informação. Nada a fazer.");	
		setAlert(true);	
		return false;	
	} // end if		
		
	// Check if terms was accepted
	if (!email) {		
		setDialogTitle("Verifique as informações");
		setDialogMessage("Você deve preencher todos os campos marcados com * para prosseguir");
		setAlert(true);	
		return false;		
	} // end if		
	
	if ( (email != currentEmail) || (mobilePhone.replace(/\D/g,"") != currentMobilePhone) ) {
		setDialogMessage("Tem certeza que deseja prosseguir com a alteração dos dados? Como está alterando email e/ou telefone, será necessário validar o seu perfil novamente.");
		setNeedValidation(true);
	} // end if
	else {
		setDialogMessage("Tem certeza que deseja prosseguir com a alteração dos dados? ");
		setNeedValidation(false);		
	} // end else
	
	// set title
	setDialogTitle("Tem certeza que quer prosseguir?");

	// Open confirmation alert
	//setDialog('confirmUpdate',true);
	setConfirm(true);	
	
}

const saveAction = (props) => {
	
	setLoading(true);
	
	// Retrieve Scheduled Services
	var data = {
		email:email,
		mobilePhone:mobilePhone,
		completeName:completeName,
		alias:alias,
		currentCompleteName:currentCompleteName,
		currentAlias:currentAlias,
		currentEmail:currentEmail,
		currentMobilePhone:currentMobilePhone,
		needValidation:needValidation		
	}
	var props = {
		setAlert:setAlert,
		setDialogMessage:setDialogMessage,
		setDialogTitle:setDialogTitle,
		setLoading:setLoading,
		setCurrentEmail:setCurrentEmail,
		setCurrentMobilePhone:setCurrentMobilePhone,
		setCurrentCompleteName:setCurrentCompleteName,
		setCurrentAlias:setCurrentAlias,
		setValidated:setValidated,
		setDialogAction:setDialogAction
	}	
	updateProfile(data,props);	

}

const handleKeypress = e => {	
	 //it triggers by pressing the enter key  
	if (e.key === "Enter") {
	  saveAction(props);
	}
};	

	return (
	<Fragment>			
		<form id="formLoginEmail"  noValidate autoComplete="off">
							
				<TextField
				  id="loginEmailRegister"
				  label="Email"
				  style={{ margin: 10,marginLeft:0,marginRight:0,backgroundColor:"#fff",width:"100%"}}
				  placeholder="Informe o email cadastrado"
				  required
				  variant="outlined"
				  type="email"
					onKeyPress={handleKeypress}
					value={email}
					  onChange={(event) => {
						  setEmail(event.target.value)
					  }}
				/>
												
				<TextField
				  id="completeName"
				  label="Nome completo"
				  style={{ margin: 10,marginLeft:0,marginRight:0,backgroundColor:"#fff",width:"100%"}}
				  placeholder="Informe seu nome completo"
				  required
				  variant="outlined"
				  type="text"
					onKeyPress={handleKeypress}
					value={completeName}
					  onChange={(event) => {
						  setCompleteName(event.target.value)
					  }}
				 />
				 
				<TextField
				  id="alias"
				  label="Como quer ser chamado?"
				  style={{ margin: 10,marginLeft:0,marginRight:0,backgroundColor:"#fff",width:"100%"}}
				  placeholder="Informe como prefere ser chamado"
				  variant="outlined"
				  type="text"
					onKeyPress={handleKeypress}
					value={alias}
					  onChange={(event) => {
						  setAlias(event.target.value)
					  }}
				 />
			
				<InputMask
					mask="(99) 99999-9999"
					onKeyPress={handleKeypress}
					value={mobilePhone}
					  onChange={(event) => {
						  setMobilePhone(event.target.value)
					  }}
				  >
				  {(inputProps) => (
					<TextField
					  label="Telefone Celular"
					  style={{ margin: 10,marginLeft:0,marginRight:0,backgroundColor:"#fff",width:"100%"}}
					  placeholder="Telefone no formato (XX) 9XXXX-XXXX"
					  required
					  variant="outlined"
					 />
				  )}
				</InputMask>
			
			<Button readonly={loading} variant="contained" color="primary" size="large" style={{width:"100%",height:"53px",marginTop:"10px",borderRadius:"10px"}} onClick={()=>confirmAction()}>
				{ !loading &&
				   <div>Alterar dados</div>
				}
				
				{ loading &&
				<>
				   <CircularProgress size={30} color="inherit" />
				   <span style={{marginLeft:"10px"}}>Alterando...</span>
				</>
				}
			</Button>			
			
		  
		</form>	
		
			<ConfirmDialog 
				saveAction={saveAction}
				dialog={dialog}
				confirm={confirm}
				setConfirm={setConfirm}
				setDialog={setDialog}
				dialogTitle={dialogTitle}
				dialogMessage={dialogMessage}
				setDialogTitle={setDialogTitle}
				setDialogMessage={setDialogMessage}
			/>	

			<AlertDialog 
				alert={alert}
				setAlert={setAlert}
				dialogMessage={dialogMessage}
				dialogTitle={dialogTitle}
				dialogAction={dialogAction}
				retrieveUserData={retrieveUserData}
			/>			  
		
	</Fragment>
	)	
	
});

const AlertDialog = (props) => {
	
	return (
		 <Dialog
			open={props.alert} 
			onClose={!props.dialogAction ? () => { props.setAlert(false) } : () => { props.setAlert(false);props.retrieveUserData(); } }
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		  >
		  <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
			<DialogContent>
			  <DialogContentText id="alert-dialog-description">
			  {props.dialogMessage}
			  </DialogContentText>
			</DialogContent>
			<DialogActions>
			  <Button onClick={!props.dialogAction ? () => { props.setAlert(false) } : () => { props.setAlert(false);props.retrieveUserData(); }  } color="primary" autoFocus>
				OK
			  </Button>
			</DialogActions>			
		  </Dialog>
	  )
	
} // end AlertDialog

const ConfirmDialog = (props) => {
	
	return (
		 <Dialog
			open={props.confirm}
			onClose={ ()=>props.setConfirm(false) } 
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		  >
		  <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
			<DialogContent>
			  <DialogContentText id="alert-dialog-description">
			  {props.dialogMessage}
			  </DialogContentText>
			</DialogContent>
			<DialogActions>

			  <Button onClick={ ()=>props.setConfirm(false) } color="primary" autoFocus>
				ABORTAR
			  </Button>			

			  <Button onClick={ ()=> {props.setConfirm(false);props.saveAction(props); } } color="primary" autoFocus>
				CONFIRMAR 
			  </Button>
				
			</DialogActions>			
		  </Dialog>
	  )
		
} // end AlertDialog





